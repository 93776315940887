
<form (ngSubmit)="saveLocation()" #locationForm="ngForm" class="" autocomplete="off">
    <input type="hidden" value="{{ model._id }}" />

    <div class="form-group">
        <label>Name</label>
	    <div class="">
	        <input id="locationName" type="text" class="form-control" [(ngModel)]="model.name" name="name" required="required" #name="ngModel" initialFocus [disabled]="permissionLock"/>
	        <div class="alert alert-danger" [hidden]="(!locationForm.form.submitted && name.pristine) || name.valid">
	            Name is required
	        </div>
	    </div>
    </div>

    <div class="form-group">
        <label>Main Phone</label>
        <div class="">
            <input type="text" class="form-control" [(ngModel)]="model.main" name="main" #main="ngModel" [imask]="{ mask: '(000) 000-0000 x00000' }" validatePhone [disabled]="permissionLock" />
        </div>
    </div>

    <div class="form-group">
        <label>Fax</label>
        <div class="">
            <input type="text" class="form-control" [(ngModel)]="model.fax" name="fax" #fax="ngModel" [imask]="{ mask: '(000) 000-0000' }" validatePhone [disabled]="permissionLock" />
        </div>
    </div>

    <div class="form-group">
        <label>Address 1</label>
        <div class="">
            <input type="text" class="form-control" [(ngModel)]="model.address.address1" name="address1" required="required" #address1="ngModel" [disabled]="permissionLock" />
            <div class="alert alert-danger" [hidden]="(!locationForm.form.submitted && address1.pristine) || address1.valid">
                Address 1 is required
            </div>
        </div>
    </div>
    <div class="form-group">
        <label>Address 2</label>
        <div class="">
            <input type="text" class="form-control" [(ngModel)]="model.address.address2" name="address2" [disabled]="permissionLock" />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label city-st-zip"><div class="city">City</div><div class="state">State</div><div class="zip">Zip</div></label>
        <div class="">
            <div class="input-group full-width">
                <input type="text" class="form-control city" [(ngModel)]="model.address.city" name="city" required="required" #city="ngModel" [disabled]="permissionLock" />
                <state-selector [(ngModel)]="model.address.state" name="state" required="true" #state="ngModel" [disabled]="permissionLock"></state-selector>
                <input type="text" class="form-control zip" [(ngModel)]="model.address.zip" name="zip" required="required" #zip="ngModel" [imask]="{ mask: '00000-0000' }" [disabled]="permissionLock" />
            </div>

            <div class="alert alert-danger" [hidden]="(!locationForm.form.submitted && city.pristine) || city.valid">
                City is required
            </div>

            <div class="alert alert-danger" [hidden]="(!locationForm.form.submitted && state.pristine) || state.valid">
                State is required
            </div>

            <div class="alert alert-danger" [hidden]="(!locationForm.form.submitted && zip.pristine) || zip.valid">
                Zip is required
            </div>
        </div>
    </div>

    <div class="row mt-3 mb-3" *ngIf="showAlert">
        <div class="col-12">
            <div class="alert alert-danger text-center">
                A location with that name already exists. <button class="btn btn-link pa-0" (click)="goToLocation()" style="font-size:16px; padding:0;">Click to view the location</button>.
            </div>
        </div>
    </div>

    <div>
        <button class="btn btn-primary mr-1" type="submit" [disabled]="!locationForm.form.valid" [disabled]="permissionLock">Save</button>
        <div class="inline-block pseudo-link small" (click)="cancel()">Cancel</div>
    </div>
</form>
