import { Pipe, PipeTransform } from '@angular/core';

export interface IGroupedValues<T> {
    key: string;
    values: Array<T>;
};

@Pipe({
    name: 'groupBy',
    pure: true
})

export class GroupByPipe<T> implements PipeTransform {
    transform(value: Array<T>, field: string): Array<IGroupedValues<T>> {
        var grouper = {};
        var results = new Array<IGroupedValues<T>>();

        for(let item of value) {
            let key = item[field];

            if(!grouper[key]) {
                grouper[key] = new Array<T>();
            }

            grouper[key].push(item);
        }

        for(let key in grouper) {
            results.push({
                key: key,
                values: grouper[key]
            });
        }

        return results;
    }
};
