<section class="documents-section col-xs-12 col-md-8 col-lg-6">
    <div class="screen-subtitle">Associated Documents</div>
    <document-upload 
        type="Invoice" 
        [isDraggable]="true"
        [label]="'Add documents'" 
        [model]="model" 
        [typeId]="_invoiceId" 
        [duplicateRecord]="duplicateRecord" 
        (uploaded)="setMessage($event)" 
        (duplicateFound)="duplicate()" 
        (saveModel)="saveOrder($event)"
    ></document-upload>
</section>
