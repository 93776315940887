import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {map, tap} from 'rxjs/operators';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { SupplierService } from '@/services/supplier.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { LocationService } from '@/services/location.service';
import { DataManipulationService } from '@/services/dataManipulation.service';

import { ICostModel } from '@/models/cost.models';
import { ISupplierModel } from '@/models/supplier.models';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { IInvoiceAutoCalculateTax } from '@/models/invoice.models';

import { CostSortFunctions } from '@/utils/dataManipulationUtils';
import {handleError} from '@/utils/errors';

// todo essentially a copy of purchase order costs; refactor out common cost list component
@Component({
    selector: 'job-costs',
    templateUrl: './jobCosts.template.html',
    host: {'class': 'job-costs-component'}
})
export class JobCostsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
	// todo refactor or rename so public properties aren't prefixed with _
	public _customerId: string;
	public _jobId: string;
	public _suppliers: Observable<Array<ISupplierModel>>;
	public model: Subject<Array<ICostModel>> = new Subject<Array<ICostModel>>();
	public taxRates: IInvoiceAutoCalculateTax = {};
	public useTaxRates: IInvoiceAutoCalculateTax = {};

  // todo refactor or rename so public fields aren't prefixed with _
  public _isTaxExempt: boolean = false;
  public _purchaseOrdersFormatted: Array<Object>;
  public updatedCost: Subject<any> = new Subject();

  private _purchaseOrderId: string = null;
	private _purchaseOrders: Observable<Array<IPurchaseOrderModel>>;
	private showAlert: boolean = false;

	navigationSubscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    public dataManipulationService: DataManipulationService<ICostModel>,
    private locationService: LocationService,
    private purchaseOrderService: PurchaseOrderService,
    private supplierService: SupplierService,
    messageService: MessageService
  ) {
    super(messageService);
    // todo this should be refactored because we shouldn't call ngOnInit directly
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd && !!this._jobId && this._jobId !== routerUtils.getRouteParameter(this.route, 'jobId')) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = null;
    this._suppliers = this.state.getSuppliersList();

    this._purchaseOrders = this.state.getPurchaseOrdersList()
      .pipe(
        map(items => items.sort((a, b) => {
          return (a.number.toLowerCase() > b.number.toLowerCase()) ? 1 : ((b.number.toLowerCase() > a.number.toLowerCase()) ? -1 : 0);
        })),
        tap(items => {
          this._purchaseOrdersFormatted = items.map(PO => {
            let poName = PO.name || ''; // po name is not required
            let supplierName = '';
            // old PO's may not have names
            if (PO.supplier) {
              supplierName = PO.supplier.name;
            }
            return {
              id: PO._id,
              text: PO.number + (poName ? ' - ' + poName : '') + (PO.supplier ? ' - ' + supplierName : ''),
              name: supplierName,
              supplier: PO.supplier
            };
          });
        })
      );
    this._purchaseOrders.subscribe();

    this.dataManipulationService.initialize({}, CostSortFunctions);
    this.dataManipulationService.setSort('date', true);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getCostList(), this.model));

    // load tax rates
    this.watchSubscription(this.state.getActiveCustomer().subscribe((s) => {
      if (!s.location) { return; }
      this.locationService.getTaxRates(s.location as string).then((taxRates) => {
        var processTaxRates = function(taxRates, rateField) {
            for (let taxRate of taxRates) {
                let taxRateName = taxRate.name.toLowerCase() + 'Tax';

                rateField[taxRateName] = taxRate.rate !== null ? taxRate.rate * 100 : null;
            }
        };
        processTaxRates(taxRates.salesTax, this.taxRates);
        processTaxRates(taxRates.useTax, this.useTaxRates);
      });
    }));

    // check for tax exemption
    this.watchSubscription(this.state.getActiveJob().subscribe((s) => {
      this._isTaxExempt = s.isTaxExempt;
    }));

    this.supplierService.getSuppliers(0);
    // hack needed to guarantee purchase orders are loaded.
    this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId).then().catch(handleError);
    this.reloadCosts();
  }

  override ngOnDestroy(): void {
    this.state.setCostList(new Array<ICostModel>());
    super.ngOnDestroy();
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    this.model.unsubscribe();
  }

  private reloadCosts(): void {
    this.jobService.getCostsAndPOCosts(this._customerId, this._jobId);
  }

  public cancel(): void {
    this.reloadCosts();
  }

  public selectPO(value: any): void {
    this._purchaseOrderId = value.id;
  }

  public removePO(value: any): void {
    this._purchaseOrderId = null;
  }

  public saveCost(costObject: any): void {
    const self = this;
    let costCopy = Object.assign({}, costObject);
    // passing cost and jobId together in object as eventemitter accepts only one value
    let cost = costObject.entry || costObject;
    self._purchaseOrderId = costObject._purchaseOrderId;
    let assignedJob = costObject.jobId || self._jobId;

    let savePromise: Promise<ICostModel> = null;

    if (!cost.type || !cost.date || (cost.cost === null)) { return; }

    if (cost.poNumber && cost.poNumber !== 'null' ) {
      let poId = cost.poId;
      delete cost['poId'];
      delete cost['poNumber'];
      savePromise = self.purchaseOrderService.updateCost(self._customerId, assignedJob, poId, cost);
    } else if (self._purchaseOrderId && (self._purchaseOrderId !== 'null')) {
      delete cost['poId'];
      delete cost['poNumber'];
      savePromise = self.purchaseOrderService.updateCost(self._customerId, assignedJob, self._purchaseOrderId, cost);
    } else {
      delete cost['poId'];
      delete cost['poNumber'];
      savePromise = self.jobService.updateCost(self._customerId, assignedJob, cost);
    }

    savePromise
      .then((cost) => {
        this.updatedCost.next(cost);
        self.supplierService.getSuppliers(0);
        self.reloadCosts();
      })
      .catch((err) => {
        this.updatedCost.next(err);
      });
  }

  public deleteCost(cost: ICostModel): void {
    const self = this;
    let delPromise: Promise<ICostModel> = null;

    if (!cost.type || !cost.date || (cost.cost === null)) { return; }

    if (cost.poId && (cost.poId !== 'null')) {
      delPromise = self.purchaseOrderService.deleteCost(self._customerId, self._jobId, cost.poId, cost._id, cost.hideMessage);
    } else {
      delPromise = self.jobService.deleteCost(self._customerId, self._jobId, cost._id, cost.hideMessage);
    }

    delPromise
      .then(() => {
        self.reloadCosts();
      })
      .catch(() => { });
  }

  public moveCosts(moves): void {
    this.jobService.moveCosts(this._customerId, this._jobId, moves).then((costs) => {
      this.supplierService.getSuppliers(0);
      this.reloadCosts();
    });
  }
}
