<div class="container fixed">
	<div class="row">
	    <div class="col-md-10 col-md-offset-1 searchbox" [ngClass]="{'disabled': isLoading}">
			<search [(ngModel)]="searchTerm" large="true" (onAction)="search()" placeholder="Search Jobs" #jobSearch keyboardAccelerator="9" (onKeyboardAction)="search()" [isLoading]="isLoading"></search>
	    </div>
	</div>

	<div class="row filter-row">
	    <div class="col-sm-6 col-md-9">
			<filter-list *ngIf="filterOptions.length" [itemType]="'job'" [activeFilters]="filters" [filterOptions]="filterOptions" (filterValue)="filterTheList($event)"></filter-list>
	    </div>
	    <div class="col-sm-6 col-md-3">
		    <div class="list-actions mt-3">
			    <div class="list-filters">
				    <button type="button" class="btn btn-default" (click)="filterToggle('showClosed')" [ngClass]="{'active btn-primary':showClosed}"><i class="glyphicon glyphicon-lock"></i><span *ngIf="showClosed"> Hide</span><span *ngIf="!showClosed"> Show</span> Closed</button>
			    </div>
		    </div>
	    </div>
	</div>
	<div class="row">
		<div class="col-md-12 mt-1" id="infinite-scroll-list">
			<table id="job-list" class="table table-select-list mb-0">
				<caption class="sr-only">Sort list links</caption>
				<thead>
					<tr>
						<th scope="col" style="width:20%;">
							<infinite-scroll-header class="sortable" label="Job Number" field="number" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
						</th>
						<th scope="col" style="width:35%;">
							<infinite-scroll-header class="sortable" label="Name / Project Manager" field="name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
						</th>
						<th scope="col" style="width:35%;">
							<infinite-scroll-header class="sortable" label="Customer" field="customer.name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
						</th>
						<th scope="col" class="list-actions" style="width:10%;"></th>
					</tr>
				</thead>
			</table>
			<div id="top-of-list-marker"></div>
		</div>
	</div>
</div>
<div class="container">
	<div class="row list-container">
	    <div class="col-md-12 job-list-column">
			<div class="attached infinite-scroll-list"
			infiniteScroll
			[infiniteScrollDistance]="1"
			[infiniteScrollThrottle]="50"
			[scrollWindow]="true"
			[infiniteScrollDisabled]="isLoading"
			(scrolled)="onScroll($event)"
			*ngIf="!isLoading" [@fadeInOut]>
				<table class="table table-select-list">
					<caption class="sr-only">List of jobs</caption>
					<thead class="spacer">
						<th scope="col" style="width:20%;"></th>
						<th scope="col" style="width:35%;"></th>
						<th scope="col" style="width:35%;"></th>
						<th scope="col" style="width:10%;"></th>
					</thead>
					<tbody>
					    <tr class="job-list-item" *ngFor="let job of jobSet" (click)="editJob(job)">
						    <td scope="row">
								<span [innerHtml]="job.isClosed | booleanIcon:'lock'"></span>
							    {{ job.number }}
						    </td>
						    <td scope="row">
								<div>{{ job.name }}</div>
								<div class="pm-name">{{ job.projectManager ? job.projectManager.firstName + ' ' + job.projectManager.lastName : '' }}</div>
						    </td>
							<td scope="row">
							    {{ job.customer.name }}
							</td>
						    <td scope="row" class="list-actions">
								<button class="btn btn-default" type="button">Edit</button>
						    </td>
					    </tr>
				    </tbody>
				</table>
			</div>
			<div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
			<div *ngIf="!isLoading && !jobSet.length" id="loading">No results</div>
		</div>
	</div>
</div>
