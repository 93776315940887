import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { AuthService } from './auth.service';
import { IPurchaseOrderModel, PurchaseOrderModel, IJobBackref } from '@/models/purchaseOrder.models';
import { IPurchaseOrderPartModel } from '@/models/part.models';
import { IReceiptModel, ReceiptModel } from '@/models/receipt.models';
import { ICostModel } from '@/models/cost.models';
import { IPartConnection } from '@/models/part.models';


export interface PurchaseOrderPayable {
  _id: string;
  customerId: string;
  invoiceDate: Date;
  invoiceNumber: string;
  jobId: string;
  amount: number;
}

@Injectable()
export class PurchaseOrderService extends ApiBaseService {
	constructor(
		private _http: HttpClient,
		private state: StateManager,
		private authService: AuthService,
		messageService: MessageService) {
		super(messageService);
		this.baseUrl = 'customers';
	}

	fetch(): Promise<Array<IPurchaseOrderModel>> {
		let result = this._http.get<Array<PurchaseOrderModel>>('purchaseOrders');
		return this.extract(result);
	}

	search(
		limit: number = null,
		searchTerm: string = null,
		sortField: string = null,
		sortDirection: number = null,
		pageNumber: number = null,
		filters: any[] = null
	): Promise<Array<IPurchaseOrderModel>> {
		let queryUrl = 'purchaseOrders';
		let request = {
			limit: limit || 0,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			pageNumber: pageNumber,
			filters: filters
		};

		let result = this._http.post<Array<IPurchaseOrderModel>>(queryUrl, request).pipe(share());

		return this.extract(result, (s) => {
			// we need to lift the customer out of the job
			for (let i = 0, len = s.length; i < len; i++) {
				let p = s[i];
				let job = p.job as IJobBackref;
				if (job) {
					p.customer = job.customer;
				}
			}
			this.state.setPurchaseOrdersList(s);
		});
	}

	getPurchaseOrders(customerId: string, jobId: string): Promise<Array<IPurchaseOrderModel>> {
		let result = this._http.get<Array<PurchaseOrderModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders').pipe(share());
		return this.extract(result, (s) => {
			this.state.setPurchaseOrdersList(s);
		});
	}

	getPurchaseOrder(customerId: string, jobId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId;
		let result = this._http.get<PurchaseOrderModel>(url).pipe(share());
		return this.extract(result, (s) => {
				this.state.setActivePurchaseOrder(s);
		});
	}

	createPurchaseOrder(customerId: string, jobId: string, purchaseOrder: IPurchaseOrderModel): Promise<IPurchaseOrderModel> {
		const url = this.getPurchaseOrderUrl(customerId, jobId, purchaseOrder);
		const req = this._http.post<IPurchaseOrderModel>(url, purchaseOrder).pipe(share());
		return this.savePurchaseOrder(req);
	}

	updatePurchaseOrder(customerId: string, jobId: string, purchaseOrder: IPurchaseOrderModel): Promise<IPurchaseOrderModel> {
		const url = this.getPurchaseOrderUrl(customerId, jobId, purchaseOrder);
		const req = this._http.put<IPurchaseOrderModel>(url, purchaseOrder).pipe(share());
		return this.savePurchaseOrder(req);
	}

	// todo connect to UI
	// deletePurchaseOrder()

	// <editor-fold> Parts

	getParts(customerId: string, jobId: string, purchaseOrderId: string): Promise<Array<IPurchaseOrderPartModel>> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/parts';
		let result = this._http.get<Array<IPurchaseOrderPartModel>>(url).pipe(share());
		return this.extract(result, (s) => {
			this.state.setPurchaseOrderPartsList(s);
		});
	}

	updatePart(customerId: string, jobId: string, purchaseOrderId: string, part: IPurchaseOrderPartModel): Promise<IPurchaseOrderPartModel> {
		let result: Observable<IPurchaseOrderPartModel>;
		let partBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/parts';
		if (part._id) {
			result = this._http.put<IPurchaseOrderPartModel>(partBaseUrl + '/' + part._id, part).pipe(share());
		} else {
			result = this._http.post<IPurchaseOrderPartModel>(partBaseUrl, part).pipe(share());
		}

		let promise = this.extract(result);
		// todo says "Update" even if it is a "create"
		promise.then(() => {
			this.notify('Part updated');
		}).catch(() => {
			this.notify('An error occurred when updating the part', { type: 'error' });
		});

		return promise;
	}

	deletePart(customerId: string, jobId: string, purchaseOrderId: string, partId: string): Promise<any> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/parts/' + partId;
		let result = this._http.delete(url).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Part deleted');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	createPurchaseOrderFromParts(
    customerId: string,
    jobId: string,
    workOrderId: string,
    partIds: Array<string>
	): Promise<IPurchaseOrderModel> {
    let connection = { partIds: partIds } as IPartConnection;
    const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/purchaseOrder';
    const req = this._http.post<IPurchaseOrderModel>(url, connection).pipe(share());
    return this.savePurchaseOrder(req);
	}

	// </editor-fold> Parts

	// <editor-fold> Receipts

	getReceipts(customerId: string, jobId: string, purchaseOrderId: string): Promise<Array<IReceiptModel>> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/receipts';
		let result = this._http.get<Array<IReceiptModel>>(url).pipe(share());
		return this.extract(result, (s) => {
				this.state.setReceiptsList(s);
		});
	}

	updateReceipt(customerId: string, jobId: string, purchaseOrderId: string, receipt: IReceiptModel): Promise<IReceiptModel> {
		let result: Observable<IReceiptModel>;
		let receiptBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/receipts';

		if (receipt._id) {
			result = this._http.put<IReceiptModel>(receiptBaseUrl + '/' + receipt._id, receipt).pipe(share());
		} else {
			result = this._http.post<IReceiptModel>(receiptBaseUrl, receipt).pipe(share());
		}

		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Receipt updated');
		}).catch(() => {
			this.notify('An error occurred when updating the receipt', { type: 'error' });
		});

		return promise;
	}

	deleteReceipt(customerId: string, jobId: string, purchaseOrderId: string, receiptId: string): Promise<any> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/receipts/' + receiptId;
		let result = this._http.delete(url);
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Receipt deleted');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	createReceiptFromPart(customerId: string, jobId: string, purchaseOrderId: string, part: IPurchaseOrderPartModel): Promise<IReceiptModel> {
		let receipt = new ReceiptModel();
		receipt.part = part._id;
		receipt.quantity = part.quantity;
		return this.updateReceipt(customerId, jobId, purchaseOrderId, receipt);
	}

	createReceiptBatch(
		customerId: string,
		jobId: string,
		purchaseOrderId: string,
		receipts: Array<IReceiptModel>
	): Promise<Array<IReceiptModel>> {
		let receiptUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/receipts/batch';

		let result = this._http.post<Array<IReceiptModel>>(receiptUrl, receipts).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Receipts created');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// </editor-fold> Receipts

	// <editor-fold> Costs

	getCosts(customerId: string, jobId: string, purchaseOrderId: string): Promise<Array<ICostModel>> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/costs';
		let result = this._http.get<Array<ICostModel>>(url).pipe(share());
		return this.extract(result, (s) => {
			this.state.setCostList(s);
		});
	}

	updateCost(customerId: string, jobId: string, purchaseOrderId: string, cost: ICostModel): Promise<ICostModel> {
		let result: Observable<ICostModel>;
		let costBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/costs';

		if (cost._id) {
			result = this._http.put<ICostModel>(costBaseUrl + '/' + cost._id, cost).pipe(share());
		} else {
			result = this._http.post<ICostModel>(costBaseUrl, cost).pipe(share());
		}

		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Cost updated');
		})
		.catch((err) => {
			if (err.status === 409) {
				let error = err.error;
				if (error.message === 'Duplicate Record') {
						this.notify('That invoice already exists', { type: 'error' });
						// todo shouldn't be accessing document directly and shouldn't be accessing it at all from a service
						document.getElementById('invoiceNumber').focus();
				} else {
						this.notify('An error occurred when updating the cost', { type: 'error' });
				}
			}
		});

		return promise;
	}

	deleteCost(customerId: string, jobId: string, purchaseOrderId: string, costId: string, hideMessage: boolean): Promise<any> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/costs/' + costId;
		let result = this._http.delete(url);
		let promise = this.extract(result);

		promise.then(() => {
			if (!hideMessage) {
				this.notify('Cost deleted');
			}
		}).catch(() => { /* DO NOTHING */});

		return promise;
	}

	// </editor-fold> Costs

  getPayables(customerId: string, jobId: string, purchaseOrderId: string): Observable<PurchaseOrderPayable[]> {
	  const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/' + purchaseOrderId + '/payables';
	  return this._http.get<PurchaseOrderPayable[]>(url);
  }

	private getPurchaseOrderUrl(customerId: string, jobId: string, purchaseOrder: IPurchaseOrderModel): string {
		let purchaseOrderBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders';
		if (purchaseOrder._id) {
			purchaseOrderBaseUrl += '/' + purchaseOrder._id;
		}
		return purchaseOrderBaseUrl;
	}

	private savePurchaseOrder(req: Observable<IPurchaseOrderModel>): Promise<IPurchaseOrderModel> {
		let promise = this.extract(req, (s) => {
			this.state.setActivePurchaseOrder(s);
		}, (error) => {
			this.notify('An error occurred when updating the purchase order', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Purchase order updated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}
}
