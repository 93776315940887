import { ActionReducer, State, Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IAuthUser } from '@/models/auth.models';

export interface IAuthState {
	currentUser: IAuthUser,
	expiration: Date
}

const defaultState : IAuthState = {
	currentUser : null,
	expiration: null
};

export const AUTH_ACTIONS = {
	SET_CURRENT_USER: 'SET_CURRENT_USER',
	SET_EXPIRATION: 'SET_EXPIRATION'
};

const authReducer = function(state: IAuthState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case AUTH_ACTIONS.SET_CURRENT_USER:
			newState.currentUser = action.payload;
			break;
		case AUTH_ACTIONS.SET_EXPIRATION:
			newState.expiration = action.payload;
			break;
	}

	return newState;
};

export function getCurrentUser() {
	return (state : Store<IAuthState>) => {
		return state.select(s => {
			return s.currentUser;
		});
	};
}

export function getExpiration() {
	return (state : Store<IAuthState>) => {
		return state.select(s => {
			return s.expiration;
		});
	};
}

export function persistStateReducer(_reducer: ActionReducer<IAuthState>) {
	const localStorageKey = '__expiration';
	return (state: IAuthState | undefined, action: Action) => {
		if (state === undefined) {
			const persisted = localStorage.getItem(localStorageKey);
			if (persisted) {
				let parsed = JSON.parse(persisted);
				parsed.expiration = (parsed.expiration) ? new Date(parsed.expiration) : null;
				return parsed;
			} else {
				return _reducer(state, action)
			}
			// return persisted ? JSON.parse(persisted) :  _reducer(state, action);
		}

		const nextState = _reducer(state, action);
		localStorage.setItem(localStorageKey, JSON.stringify(nextState));
		return nextState;
	};
}


export function reducer(state: IAuthState | undefined, action: Action) {
	return persistStateReducer(authReducer)(state, action);
}
