<div class="attached">
    <table class="table table-select-list">
        <caption class="sr-only">List of time entries</caption>
        <thead>
            <tr>
                <th scope="col">
                    <sortable-header field="jobNumber" label="Job #" [sortManager]="dataManipulationService"></sortable-header><span style="padding:0 17px;">/</span>
                    <sortable-header field="jobName" label="Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="staffName" label="Staff Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="rate" label="Rate" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="hours" label="Hours" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="laborCodeText" label="Labor Code" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display">
                    <sortable-header field="total" label="Total" [sortManager]="dataManipulationService"></sortable-header>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let timeEntry of model | async">
                <td scope="row">
                    <span [innerHtml]="timeEntry.job.isClosed | booleanIcon:'lock'"></span>
                    <a (click)="editJob(timeEntry.job)">{{ timeEntry.job.number }} - {{ timeEntry.job.name }}</a>
                </td>
                <td scope="row">
                    <div>{{ timeEntry.staff.name }}</div>
                    <div class="x-small text-uppercase">{{ timeEntry.staff.tradeLevel }}</div>
                </td>
                <td scope="row">
                    {{ timeEntry.rateType }}, {{ timeEntry.rate | currencyFormat }}
                </td>
                <td scope="row">
                    {{ timeEntry.laborType }}, {{ timeEntry.units }} hours
                </td>
                <td scope="row">
                    {{ timeEntry.laborCodeText }}
                </td>
                <td scope="row" class="currency-display">
                    {{ timeEntry.rate * timeEntry.units | currencyFormat }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr class="list-item-group">
                <th scope="col">
                    {{ entryCount }} entries
                </th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">
                    {{ hoursTotal }} hours
                </th>
                <th scope="col"></th>
                <th scope="col" class="currency-display">
                    {{ costTotal | currencyFormat }}
                </th>
                <th scope="col"></th>
            </tr>
        </tfoot>
    </table>
</div>
