import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager }  from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { IUserModel } from '@/models/user.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order-edit',
  templateUrl: './workOrderEdit.template.html',
  host: {'class': 'work-order-edit-component'}
})
export class WorkOrderEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IWorkOrderModel>;
  public users: Observable<Array<IUserModel>>;

  private _customerId: string;
  private _jobId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private workOrderService: WorkOrderService,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveWorkOrder();
    this.users = this.state.getUsersList();

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
  }

  public saveWorkOrder(newModel: IWorkOrderModel): void {
    // remove the docs for the save and then add them back in after so view is updated
    let docs = newModel.documents;
    delete newModel.documents;
    delete newModel.css;

    this.workOrderService.updateWorkOrder(this._customerId, this._jobId, newModel)
        .then((result) => {
          this.workOrderService.getWorkOrders(this._customerId, this._jobId);
          setTimeout(() => {
            if (docs) {
              newModel.documents = docs;
            }
          }, 0);
        })
        .catch(handleError);
  }
}
