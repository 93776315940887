import { Injectable } from '@angular/core';
import { IMessageModel } from './../models/message.models';
import { ToastrService } from 'ngx-toastr';
interface IMessageService {
    send(message : IMessageModel) : any;
    remove(toastId: number): void;
}

// used for injection only
export abstract class MessageService implements IMessageService {
    abstract send(message : IMessageModel) : any;
    abstract remove(toastId: number): void;
}

@Injectable()
export class ToasterMessageService extends MessageService {
    constructor(private toaster : ToastrService) {
        super();
    }

    send(message : IMessageModel) : Object {
        let toastMsg = null;
        switch (message.type) {
            case 'error':
                toastMsg = this.toaster.error(message.title || message.message)
            case 'warning':
                toastMsg = this.toaster.warning(message.title || message.message)
            case 'info':
                toastMsg = this.toaster.info(message.title || message.message)
            default: 
                toastMsg = this.toaster.success(message.title || message.message)
          }
          return toastMsg;
    }

    remove(toastId: number): void {
        this.toaster.remove(toastId);
    }

}
