import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { ILocationModel, LocationModel } from '../../models/location.models';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { StateManager } from './../../state/stateManager';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';
import { UserService } from '@/services/user.service';

@Component({
    selector:'location-list',
    templateUrl: './locationList.template.html',
    host: {'class': 'location-list-component'}
})

export class LocationListComponent extends BaseSmartComponent implements OnInit {
    public model: Array<ILocationModel> = new Array<ILocationModel>();

    constructor(private router: Router, private route : ActivatedRoute, private state: StateManager, private locationService : LocationService, private userService: UserService, messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() {
        const self = this;

        self.watchSubscription(self.state.getLocationsList().subscribe((s) => {
            self.model = Object.assign(self.model, s);
        }));

        self.userService.getUsers();
        self.getLocations();
    }

    public addLocation() : void {
        this.state.setActiveLocation(new LocationModel());

        this.router.navigate(['Add'], { relativeTo: this.route });
    }

    private editLocation(id: string) : void {
        this.router.navigate([id, 'Edit'], { relativeTo: this.route });
    }

    private getLocations() : void {
        this.locationService.getLocations();
    }
};
