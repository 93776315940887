import {Component} from '@angular/core';
/**
 * Created by rrahlf on 8/3/2016.
 */

@Component({
	selector: 'supplier-edit-tabs',
	templateUrl: './supplierEditTabs.template.html',
	host: {'class': 'supplier-edit-tabs-component'}
})
export class SupplierEditTabsComponent {}
