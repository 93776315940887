import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { ICustomerModel } from '@/models/customer.models';

@Component({
    selector: 'customer-documents',
    templateUrl: './customerDocuments.template.html',
    host: { 'class': 'customer-documents-component' }
})
export class CustomerDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    public customerId: string;
    public duplicateRecord: boolean = false;
    public model = new Subject<ICustomerModel>();
    private filesQueued: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private filesService: FilesService,
        messageService: MessageService
    ) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self.customerId = routerUtils.getRouteParameter(self.route, 'customerId');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    public saveOrder(order): void {
        const self = this;
        self.filesService.saveFilesOrder('Customer', self.customerId, order);
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }

    public setMessage(e) {
        this.filesQueued = e;
    }
}
