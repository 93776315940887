import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {StateManager} from '@/state/stateManager';
import {BaseSmartComponent} from '@/components/base.component';
import * as routerUtils from '@/utils/routerUtils';
import {PurchaseOrderFilterFunctions, PurchaseOrderSortFunctions} from '@/utils/dataManipulationUtils';
import {IJobModel} from '@/models/job.models';
import {ICustomerModel} from '@/models/customer.models';
import {IPurchaseOrderModel} from '@/models/purchaseOrder.models';
import {PurchaseOrderWorkflow, WorkflowState, WorkflowStatus} from '@/models/workflow.models';
import * as Utility from '@/models/utility.models';
import {FilterDescription, FilterValue, parseSearchFilterHistory, SortOptions} from '@/models/filter.models';
import {IAuthUser, UserRole} from '@/models/auth.models';
import {MessageService} from '@/services/message.service';
import {PurchaseOrderService} from '@/services/purchaseOrder.service';
import {WorkflowService} from '@/services/workflow.service';
import {DataManipulationService} from '@/services/dataManipulation.service';
import {RouterHistoryService} from '@/services/router-history.service';
import {ListHeaderService} from '@/services/listHeader.service';
import { LocalStorageService } from '@/services/localStorage.service';
import {AuthService} from '@/services/auth.service';
import {handleError} from '@/utils/errors';

@Component({
  selector: 'purchaseorder-search',
  templateUrl: './purchaseOrderSearch.template.html',
  host: { 'class': 'purchase-order-search-component' },
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class PurchaseOrderSearchComponent extends BaseSmartComponent implements OnInit {
  @ViewChild('purchaseOrderSearch', {static: true})
  purchaseOrderSearch: Utility.IFocusable;

  @ViewChild('batchStatus', {static: true})
  batchStatus: ElementRef;

  public showClosed: boolean = false;
  public searchTerm: string = '';
  public poSet = [];
  public poSetFull = [];
  public sortDirection: number = 1;
  public currentSort: string = 'number';
  public isLoading: boolean = true;
  public isAdmin = false;
  public filterOptions: FilterDescription[];
  public statusOptions: PurchaseOrderWorkflow = new PurchaseOrderWorkflow();
  public filters: FilterValue[] = [];
  public statusList: any[];
  public selectAll: boolean = false;
  public selectedPOs: any = [];
  public updatedStatus: string = '';
  public user: IAuthUser;
  public userSub: Subscription;

  private _currentUser: any;
  private model: Subject<Array<IPurchaseOrderModel>> = new Subject<Array<IPurchaseOrderModel>>();
  private lastSearchTerm: string = '';
  private pageNumber: number = 0;
  private sortField: string = 'number';
  private scrollPosition: number = 0;
  private searchFields: string[] = ['name', 'number', 'date', 'job.name', 'supplier.name', 'job.customer.name'];
  private sortFields: string[] = ['number', 'job.customer.name', 'job.name', 'name', 'status', 'date'];
  private locationSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    private dataManipulationService: DataManipulationService<IPurchaseOrderModel>,
    messageService: MessageService,
    protected workFlowService: WorkflowService,
    private history: RouterHistoryService,
    private listHeaderService: ListHeaderService,
		private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    super(messageService);
  }

  override ngOnDestroy(): void {
    this.userSub.unsubscribe();
    this.locationSub.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.history.currentState$.pipe(take(1))
      .subscribe(s => {
        if (s) {
          const result = parseSearchFilterHistory(s);
          this.filters = result.filters;
          if (result.searchTerm) {
            this.searchTerm = result.searchTerm;
          }

          if (result.showClosed !== undefined) {
            this.showClosed = result.showClosed;
          }
          this.initializeSort(result.sort);
        }
        this.reloadList(this.filters);
      });

    // don't include 'Unassigned' or 'Rejected' as options
    this.statusList = this.statusOptions.states.filter(function (obj) {
      return obj.value !== null && obj.value !== 'rejected' && obj.value !== 'on_hold';
    });
    // get user's role
    this.userSub = this.authService.currentUser.subscribe(s => {
      if (s) {
        this.user = s;
        // only admins are allowed to use batch status feature
        this.isAdmin = (this.user.role === UserRole.Admin);
      }
    });

    this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
      this.poSet = [];
      this.reloadList(this.filters);
    });


    this.setFilterOptions();
    this.dataManipulationService.initialize(PurchaseOrderFilterFunctions, PurchaseOrderSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getPurchaseOrdersList(), this.model));

    this.dataManipulationService.setFilter('text', '');
  }

  public search(): void {
    this.isLoading = true;
    if (this.searchTerm === '') {
      this.searchTerm = null;
    }

    if (this.searchTerm !== this.lastSearchTerm) {
      this.searchList();
      this.lastSearchTerm = this.searchTerm;
    } else {
      this.lastSearchTerm = this.searchTerm;
      this.isLoading = false;
    }
  }

  public onScroll(e: any): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadList(this.filters);
      }
    }
  }

  public filterToggle(): void {
    this.showClosed = !this.showClosed;
    this.resetList();
    this.reloadList(this.filters);
  }

  public sortList(e: any): void {
    this.isLoading = true;
    this.sortField = e.field;
    this.sortDirection = e.sortDirection;
    this.currentSort = e.currentSort;
    this.resetList();
    this.reloadList(this.filters);
  }

  public filterTheList(filters: FilterValue[]): void {
    this.isLoading = true;
    this.filters = filters;
    this.resetList();
    this.reloadList(this.filters);
  }

  statusClass(workflowState: WorkflowState): string {
    if (!workflowState) {
      return 'black';
    }
    return this.workFlowService.getStatusClass(workflowState);
  }

  public editEntity(purchaseOrder: IPurchaseOrderModel): void {
    let job = purchaseOrder.job as IJobModel;
    let customer = job.customer as ICustomerModel;

    this.router.navigate(
      ['/Customers', customer._id.toString(), 'Job', job._id, 'PurchaseOrders', purchaseOrder._id],
      { relativeTo: routerUtils.getParentRoute(this.route) }
    );
  }

  protected initializeSort(sort: SortOptions): any {
    if (sort) {
      this.sortDirection = sort.direction;
      if (this.sortFields.includes(sort.field)) {
        this.sortField = sort.field;
      }
    }
    this.currentSort = this.sortField;
  }

  private reloadList(filters: FilterValue[]): void {
    const extendedFilters: any[] = [...filters];
    extendedFilters.push({ filter: 'includeClosedJobsFilter', id: this.showClosed });
		// pre-set pm filter to return correct results
		if(this._currentUser?.role === 10){
			extendedFilters.push({ filter: 'projectManagerFilter', id: this._currentUser?.userId });
		}
    this.history.changeState([
      { filter: 'searchTerm', id: this.searchTerm },
      { filter: 'sort', id: this.sortField, direction: this.sortDirection },
      ...extendedFilters
    ]);
    this.purchaseOrderService.search(30, this.searchTerm, this.sortField, this.sortDirection, this.pageNumber, extendedFilters)
      .then(pos => {
        pos.forEach(po => {
          // @ts-ignore
          const workflowState = this.statusState(po.status);
          po.css = this.statusClass(workflowState);
          po.status = (workflowState) ? workflowState.label : 'Unassigned';
          if (po.job == null) {
            // @ts-ignore
            po.job = {
              name: '',
            // @ts-ignore
              customer: { name: '' }
            };
          }
          if (po.supplier == null) {
            // @ts-ignore
            po.supplier = { name: '' };
          }
          this.poSet.push(po);
        });
        setTimeout(() => {
          this.listHeaderService.setListTop();
          this.isLoading = false;
        }, 250);
      })
      .catch(handleError);
  }

  private resetList(): void {
    this.selectedPOs = [];
    this.selectAll = false;
    this.scrollPosition = 0;
    this.pageNumber = 0;
    this.poSet = JSON.parse(JSON.stringify(this.poSetFull));
  }

  private searchList(): void {
    if (this.searchTerm === '') {
      this.searchTerm = null;
    }

    if (this.searchTerm !== this.lastSearchTerm) {
      this.resetList();
      this.reloadList(this.filters);
      this.lastSearchTerm = this.searchTerm;
    }
  }

  private setFilterOptions(): void {
    this.filterOptions = [
      { type: 'projectManager', label: 'Project Manager/Ordered By', options: null },
      { type: 'status', label: 'Status', options: null },
      { type: 'job', label: 'Job', options: null }
    ];
  }

  private statusState(status: WorkflowStatus): WorkflowState {
    if (!status) {
      return undefined;
    }
    return this.statusOptions.states.find((descr: WorkflowState) => status.status === descr.value);
  }

  // Batch status
  // select/deselect all payables
  public toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    this.poSet.forEach(po => {
      po.selected = this.selectAll;
      this.updateSelected(po);
    });
    if (!this.selectAll) { this.selectedPOs = [] }
  }

  public updateSelected(po): void {
    const indexPosition = this.selectedPOs.indexOf(po);

    if (indexPosition === -1) {
      this.selectedPOs.push(po);
    } else if (indexPosition > -1) {
      this.selectedPOs.splice(indexPosition, 1);
    }
  }

  // update all of the selected POs to the status selected
  public updateStatus(): void {
    if (confirm('Are you sure you want to update all selected purchase orders?')) {
      let items = this.selectedPOs.map(po => {
        po.selected = false;
        return po._id;
      });
      this.selectAll = false;

      // clear selected POs
      this.workFlowService.updateWorkflowBatch('purchaseOrders', { items, status: this.updatedStatus })
        .subscribe(res => {
          if (res === items.length) {
            this.sendMessage({ title: `Successfully updated ${items.length} purchase order statuses`, type: 'success', options: {}, message: '' });
          } else {
            this.sendMessage({ title: `Updated ${res} of ${items.length} purchase order statuses`, type: 'error', options: {}, message: '' });
          }
          this.resetList();
          this.reloadList(this.filters);
        });
    }
  }
}

