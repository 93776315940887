import { Injectable } from '@angular/core';

@Injectable()
export class ListHeaderService {
    constructor(){}

    // multiple status filters require the list to be repositioned for the fixed header
    setListTop(): void {
        let filterList = document.getElementById('filter-list');
        if (filterList) {
            let marker = document.getElementById('top-of-list-marker');
            let markerPosition = marker.getBoundingClientRect();
            let header = document.getElementById('header');
            let listOffset = (markerPosition.top - header.offsetHeight) + 'px';
            (document.querySelector('.list-container') as HTMLElement).style.marginTop = listOffset;
        }
    }
}
