import * as Utility from './utility.models';

export interface IPMNoteModel extends Utility.BaseModel {
    title : string;
    note : string;
    notes : string;
};

export class PMNoteModel extends Utility.BaseModel implements IPMNoteModel {
    title : string = null;
    note : string = null;
    notes : string = null;
};
