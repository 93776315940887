import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { constructSearchQuery } from '@/utils/queryUtils';
import { ISupplierModel } from '@/models/supplier.models';
import { IContactModel, ContactModel } from '@/models/contact.models';

@Injectable()
export class SupplierService extends ApiBaseService {
  constructor(private _http: HttpClient, private stateManager: StateManager, messageService: MessageService) {
    super(messageService);
    this.baseUrl = 'suppliers';
  }

  getSuppliers(
    limit: number = null,
    getInactive: boolean = false,
    searchTerm: string = null,
    sortField: string = null,
    sortDirection: number = null,
    pageNumber: number = null,
    statuses: string[] = []
  ): Promise<Array<ISupplierModel>> {
    const statusp = (statuses.length > 0) ? [`statuses=${statuses.join(',')}`] : [];
    let queryUrl = constructSearchQuery(this.baseUrl, getInactive, searchTerm, sortField, sortDirection, pageNumber, limit, statusp);
    let result = this._http.get<ISupplierModel[]>(queryUrl).pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setSuppliersList(s);
    });
  }

  getSupplier(id: string): Promise<ISupplierModel> {
    let result = this._http.get<ISupplierModel>(this.baseUrl + '/' + id).pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setActiveSupplier(s);
    });
  }

  updateSupplier(supplier: ISupplierModel): Promise<ISupplierModel> {
    let req: Observable<ISupplierModel>;
    if (supplier._id) {
      req = this._http.put<ISupplierModel>(this.baseUrl + '/' + supplier._id, supplier);
    } else {
      req = this._http.post<ISupplierModel>(this.baseUrl, supplier);
    }

    let result: Observable<ISupplierModel> = req.pipe(share());
    let promise = this.extract(result, (s) => {
      this.stateManager.setActiveSupplier(s);
    }, (error) => {
      this.notify('An error occurred when updating the supplier', { type: 'error' });
    });

    promise.then(() => {
      this.notify('Supplier updated.');
    }).catch(() => { /* DO NOTHING */ });

    return promise;
  }

  getContacts(id: string): Promise<Array<IContactModel>> {
    let result = this._http.get<Array<ContactModel>>(this.baseUrl + '/' + id + '/contacts').pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setContactsList(s);
    });
  }

  getContact(id: string, contactId: string): Promise<IContactModel> {
    let result = this._http.get<ContactModel>(this.baseUrl + '/' + id + '/contacts/' + contactId).pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setActiveContact(s);
    });
  }

  updateContact(id: string, contact: IContactModel): Promise<IContactModel> {
    let result: Observable<IContactModel>;
    let contactBaseUrl = this.baseUrl + '/' + id + '/contacts';
    if (contact._id) {
      result = this._http.put<IContactModel>(contactBaseUrl + '/' + contact._id, contact).pipe(share());
    } else {
      result = this._http.post<IContactModel>(contactBaseUrl, contact).pipe(share());
    }

    let promise = this.extract(result, (s) => {
      this.stateManager.setActiveContact(s);
    }, (error) => {
      this.notify('An error occurred when updating the contact', { type: 'error' });
    });

    promise.then(() => {
      this.notify('Contact updated');
    }).catch(() => { /* DO NOTHING */ });

    return promise;
  }

  deleteContact(id: string, contactId: string): Promise<Object> {
    let result = this._http.delete(this.baseUrl + '/' + id + '/contacts/' + contactId).pipe(share());
    let promise = this.extract(result);

    promise.then(() => {
      this.notify('Contact deleted');
    }).catch(() => { /* DO NOTHING */ });

    return promise;
  }
}
