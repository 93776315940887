import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ExportDocumentService } from '../../services/exportDocument.service';
import { IPrintOptions } from '../../utils/printable.component';
import { IPurchaseOrderModel } from '../../models/purchaseOrder.models';
import { StateManager } from '../../state/stateManager';

@Component({
  selector: 'export',
  templateUrl: './exportPurchaseOrder.template.html'
})
export class ExportComponent implements OnInit {
  @Input()
  exportOptions: IPrintOptions;

  @Output()
  reload: EventEmitter<void> = new EventEmitter();

  public includeDocs: boolean = false;

  private model: IPurchaseOrderModel;

  constructor(private state: StateManager, private exportDocumentService: ExportDocumentService) { }

  ngOnInit(): void {
    this.state.getActivePurchaseOrder().subscribe(po => this.model = po);
    this.reset();
  }

  /**
   * If any of the copies are enabled, returns true. Otherwise, false
   */
  public canExport(): boolean {
    return !!this.exportOptions.copies.find(copy => copy.enabled);
  }

  public export(): void {
    let enabledCopies = this.exportOptions.copies.filter(c => c.enabled).map(c => c.label);
    this.exportDocumentService
      .saveDocument(this.model._id, enabledCopies, this.includeDocs)
      .subscribe(doc => {
        this.model.exportedKey = doc.exportUrl;
      });
    this.reset();
  }

  public toggleAttachments(): void {
    this.includeDocs = !this.includeDocs;
  }

  private reset(): void {
    for (let copy of this.exportOptions.copies) {
      copy.enabled = false;
    }
    this.includeDocs = false;
  }
}
