import { Pipe, PipeTransform } from '@angular/core';
import { Summary } from '@/models/summary.models';

@Pipe({
    name: 'statusFilter',
    pure: true
})
export class StatusFilterPipe implements PipeTransform {
    transform(value: Array<Summary>, field: string, field2: string) {
        let result = new Array<Summary>();
        if (value && value.length > 0) {
            for (let i = 0; i < value.length; i += 1) {
                if ((value[i].label != field) && (value[i].label != field2)) {
                    result.push(value[i]);
                }
            }
        }

        return result;
    }
};
