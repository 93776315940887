import { Routes } from '@angular/router';

import { ADMIN_ROUTES } from './components/AdminComponent/admin.routes';
import { CUSTOMER_ROUTES } from './components/CustomerComponent/customer.routes';
import { LOGIN_ROUTES } from './components/LoginComponent/login.routes';
import { PROFILE_ROUTES } from './components/ProfileComponent/profile.routes';
import { REPORT_ROUTES } from './components/ReportComponent/report.routes';

import { JobListComponent } from './components/JobComponent/jobList.component';
import { LaborCodesComponent } from './components/JobComponent/laborCodes.component';
import { PurchaseOrderSearchComponent } from "./components/PurchaseOrderComponent/purchaseOrderSearch.component";
import { WorkOrderSearchComponent } from './components/WorkOrderComponent/workOrderSearch.component';
import { InvoiceSearchComponent } from "./components/InvoiceComponent/invoiceSearch.component";
import { PayableSearchComponent } from "./components/PayableComponent/payableSearch.component";
import { DashboardComponent } from "./components/Dashboard/dashboard.component";
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

export const APP_ROUTES: Routes = [
  ...LOGIN_ROUTES,
  {
    path: '',
    pathMatch: 'prefix',
    children: [
      ...ADMIN_ROUTES,
      ...CUSTOMER_ROUTES,
      ...PROFILE_ROUTES,
      ...REPORT_ROUTES,
      {
        path: 'Dashboard',
        component: DashboardComponent,
        canActivate: [MsalGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'Jobs',
        component: JobListComponent,
        canActivate: [MsalGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'PurchaseOrders',
        component: PurchaseOrderSearchComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'WorkOrders',
        component: WorkOrderSearchComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'Invoices',
        component: InvoiceSearchComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'Payables',
        component: PayableSearchComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'LaborCodes',
        component: LaborCodesComponent,
        canActivate: [MsalGuard],
      },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
    ],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];
