<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title">
    Job Outcome Report
</div>

<div class="row report-controls print-hide">
	<div class="col-xs-8 col-md-4">
		<div class="pm-selector form-horizontal">
			<label class="control-label col-sm-2">PM</label>
			<div class="col-sm-10">
				<select 
                    class="form-control {{(currentUser.role === 10) ? 'disabled' : ''}}" 
                    name="pm" 
                    [(ngModel)]="pm.userId" 
                    (ngModelChange)="markDirty()" 
                    initialFocus
                >
					<option [ngValue]="null">All Project Managers</option>
					<option *ngFor="let user of pmReportListUsers" 
                        [ngValue]="user._id"
                    >
                        {{user.firstName}} {{user.lastName}}
                    </option>
				</select>
			</div>
		</div>
	</div>
	<div class="col-xs-12 col-md-6">
        <div class="number-selector">
            <label class="control-label" style="margin-right:5px;">Month</label>
            <input autocomplete="off" class="form-control" name="month" [ngModel]="month" (ngModelChange)="month = $event; markDirty()" type="number" min="1" max="12" />
        </div>
        <div class="number-selector">
            <label class="control-label" style="margin-right:5px;">Year</label>
            <input autocomplete="off" class="form-control" name="year" [ngModel]="year" (ngModelChange)="year = $event; markDirty()" type="number" min="1970" max="2200" />
        </div>
    </div>
    <div class="col-xs-12 col-md-2 actions">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty">Run Report</button>
    </div>
</div>

<div id="reportPane" class="outcome-report" [ngClass]="{'dirty': dirty}">
    <table class="table table-select-list">
        <caption>
            Report for {{ month }} / {{ year }}
        </caption>
        <thead class="spacer">
            <th scope="col"></th>
        </thead>
    </table>

    <div class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">Sort list links</caption>
            <thead>
                <tr class="print-hide">
                    <th scope="col">
                        <sortable-header field="number" label="Job #" [sortManager]="dataManipulationService"></sortable-header><span style="padding:0 17px;">/</span>
                        <sortable-header field="name" label="Name" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="contractAmount" label="Contract" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="totalInvoices" label="Invoice" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="totalCosts" label="Costs" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="totalGp" label="GP%" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="backlog" label="Backlog" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col">
                        <sortable-header field="projectManagerInitials" label="PM" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col">
                        Start Year
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="nonCurrentInvoices" [label]="'Amt not Invoiced ' + year" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="nonCurrentCosts" [label]="'Costs not Incurred ' + year" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="currentCosts" [label]="year + ' Costs'" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="currentInvoices" [label]="year + ' Invoices'" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="currentGp" [label]="year + ' GP%'" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col" class="currency-display">
                        <sortable-header field="currentGpAmount" [label]="year + ' GP$'" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                </tr>
                <tr class="print-only">
                    <th scope="col">Job # / Name</th>
                    <th scope="col" class="currency-display">Contract</th>
                    <th scope="col" class="currency-display">Invoice</th>
                    <th scope="col" class="currency-display">Costs</th>
                    <th scope="col" class="currency-display">GP%</th>
                    <th scope="col" class="currency-display">Backlog</th>
                    <th scope="col">PM</th>
                    <th scope="col">Start Year</th>
                    <th scope="col" class="currency-display">Amt not Invoiced {{ year }}</th>
                    <th scope="col" class="currency-display">Costs not incurred {{ year }}</th>
                    <th scope="col" class="currency-display">{{ year }} Costs</th>
                    <th scope="col" class="currency-display">{{ year }} Invoices</th>
                    <th scope="col" class="currency-display">{{ year }} GP%</th>
                    <th scope="col" class="currency-display">{{ year }} GP$</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let job of model | async" [@fadeInOut]>
                    <td scope="row">
                        <a (click)="editJob(job)">{{ job.number }} - {{ job.name }}</a>
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.contractAmount | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.totalInvoices | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.totalCosts | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ (job.totalGp * 100).toFixed(2) }}%
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.backlog | currencyFormat }}
                    </td>
                    <td scope="row">
                        {{ pmInitials(job.projectManager) }}
                    </td>
                    <td scope="row">
                        {{ job.startDate ? sanitizeDate(job.startDate).getFullYear() : '' }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.nonCurrentInvoices | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.nonCurrentCosts | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.currentCosts | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.currentInvoices | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ (job.currentGp * 100).toFixed(2) }}%
                    </td>
                    <td scope="row" class="currency-display no-wrap">
                        {{ job.currentGpAmount | currencyFormat }}
                    </td>
                </tr>
            </tbody>
            <tfoot *ngIf="totals">
                <tr class="list-item-group">
                    <th scope="col">Totals</th>
                    <th scope="col" class="currency-display">
                        {{ totals.contractAmount | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.totalInvoices | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.totalCosts | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ (totals.totalGp * 100).toFixed(2) }}%
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.backlog | currencyFormat }}
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" class="currency-display">
                        {{ totals.nonCurrentInvoices | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.nonCurrentCosts | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.currentCosts | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.currentInvoices | currencyFormat }}
                    </th>
                    <th scope="col" class="currency-display">
                        {{ (totals.currentGp * 100).toFixed(2) }}%
                    </th>
                    <th scope="col" class="currency-display">
                        {{ totals.currentGpAmount | currencyFormat }}
                    </th>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
