import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { IUserModel, UserModel } from '@/models/user.models';
import { AuthService } from '@/services/auth.service';
import { IAuthUser, UserRole } from '@/models/auth.models';
import { DataManipulationService, DataManipulationServiceFactory } from '@/services/dataManipulation.service';
import { UserSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';
import {UserService} from '@/services/user.service';

@Component({
  selector: 'user-list',
  templateUrl: './userList.template.html',
  host: {'class': 'user-list-component'}
})
export class UserListComponent extends BaseSmartComponent implements OnInit {
  public model: Subject<Array<IUserModel>> = new Subject<Array<IUserModel>>();
  public userManager: DataManipulationService<IUserModel>;
	public showInactive: boolean = false;
	public user: Object;
	public userSub: Subscription;
	public isAdmin: boolean = false;
	public role: number;
	public permissionLock: boolean = false;

  private _locationId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private locationService: LocationService,
    private userService: UserService,
    dataManipulationServiceFactory: DataManipulationServiceFactory,
		private authService: AuthService,
    messageService: MessageService
	) {
    super(messageService);
    this.userManager = dataManipulationServiceFactory.create<IUserModel>();
  }

  ngOnInit(): void {
    this.userManager.initialize({}, UserSortFunctions);
    this.userManager.setSort('lastName', false);
    this.watchSubscription(this.userManager.connectDataSource(this.state.getLocationUsersList(), this.model));
    this._locationId = routerUtils.getRouteParameter(this.route, 'locationId');
    this.locationService.getUsers(this._locationId);
    // get user's role
    this.userSub = this.authService.currentUser.subscribe(s => {
      if (s) {
        this.user = s;
        this.role = s.role;
          if (this.role === UserRole.Admin) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
            this.permissionLock = true;
          }
      }
    });
  }

  public addUser(): void {
    this.state.setLocationSelectedUser(new UserModel());
    this.router.navigate([{ outlets: { users: ['User', 'Add'] } } ], { relativeTo: this.route } );
  }

  public roleDisplayName(id: number): string {
    return this.userService.getRole(id);
  }

  public editUser(id: string): void {
    this.router.navigate([{ outlets: { users: ['User', id] } } ], { relativeTo: this.route } );
  }

  public deleteUser(user: IUserModel, event: Event): void {
    if (this.warnOnDelete('user')) {
      this.locationService.deleteUser(this._locationId, user._id)
        .then(() => this.locationService.getUsers(this._locationId))
        .catch(handleError);
    }
    event.stopPropagation();
  }

  private deactivateUser(user: IUserModel, event: Event): void {
    this.locationService.deactivateUser(this._locationId, user._id)
      .then(() => this.locationService.getUsers(this._locationId))
      .catch(handleError);
    event.stopPropagation();
  }

  private activateUser(user: IUserModel, event: Event): void {
    this.locationService.activateUser(this._locationId, user._id)
      .then(() => this.locationService.getUsers(this._locationId))
      .catch(handleError);
    event.stopPropagation();
  }

  public filterToggle(){
    this.showInactive = !this.showInactive;
  }
}

