import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseSmartComponent } from '@/components/base.component';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { IChangeOrderModel } from '@/models/changeOrder.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'change-order',
  templateUrl: './changeOrder.template.html',
  host: {'class': 'change-order-component'}
})
export class ChangeOrderComponent extends BaseSmartComponent implements OnInit, OnDestroy {

  private _customerId: string;
  private _jobId: string;
  private _changeOrderId: string;
  private model: IChangeOrderModel = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private jobService: JobService,
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    const editRoute = routerUtils.getRouteParameterObservable(this.route, 'changeOrderId');
    if (editRoute) {
      this.watchSubscription(editRoute.subscribe((s) => {
        if (s['changeOrderId']) {
          this._changeOrderId = s['changeOrderId'];
          this.jobService.getChangeOrder(this._customerId, this._jobId, this._changeOrderId)
            .then(changeOrder => {
              this.model = changeOrder;
            })
            .catch(handleError);
        }
      }));
    }
  }

  override ngOnDestroy(): void {
    this.state.setActiveChangeOrder(null);
    super.ngOnDestroy();
  }
  
}
