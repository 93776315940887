import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StateManager } from './../../state/stateManager';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';

import { PurchaseOrderService } from './../../services/purchaseOrder.service';

import { IPurchaseOrderModel, PurchaseOrderModel } from './../../models/purchaseOrder.models';

import { DataManipulationService } from './../../services/dataManipulation.service';

@Component({
    selector: 'job-purchase-order-list',
    templateUrl: './jobPurchaseOrderList.template.html',
    host: {'class': 'job-purchase-order-list-component'}
})

export class JobPurchaseOrderListComponent extends BaseSmartComponent implements OnInit {
    public filterTerm: string = '';

    @Input()
    model : BehaviorSubject<Array<IPurchaseOrderModel>>;

    @Input()
    dataManager : DataManipulationService<IPurchaseOrderModel>;

    @Output()
    public addClicked = new EventEmitter();

    @Output()
    public itemSelected = new EventEmitter();

    public selectedPurchaseOrderId : string = null;

    constructor(private state : StateManager, messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.watchSubscription(self.state.getActivePurchaseOrder().subscribe((s) => {
            if(s) {
                self.selectedPurchaseOrderId = s._id;
            }
            else {
                self.selectedPurchaseOrderId = null;
            }
        }));
    }
}
