import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import {
	updateLifecycle,
	WorkflowStatus,
	WorkflowState,
	Workflow,
	WorkflowableItem,
	WorkflowDisplayStatus
} from '@/models/workflow.models';
import { IMessageModel } from '@/models/message.models';
import { WorkflowService } from '@/services/workflow.service';
import { MessageService } from '@/services/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRole } from '@/models/auth.models';

@Component({
	selector: 'workflow',
	templateUrl: 'workflow.component.html',
    host: {'class': 'workflow-component'}
})
export class WorkflowComponent {
	@Input()
	set item(value: WorkflowableItem) {
		this._item = value;
		this.workflow = this.service.getByItem(value);
		this.workflowStatus = this.defaultWorkflow();
		this.createDisplayableLifecycle();
	}

	@Input()
	priority: string;

	@Input()
	updatePriority: boolean;

	@Input()
	role: number;

	@Input()
	printed: boolean;

	@Input()
	restricted: boolean;

	@Input()
	type: number;
	// 0 = work order, 1 = purchase order, 2 = change order, 3 = receivable/invoice, 4 = payable

	@Output()
	onUpdate: EventEmitter<string> = new EventEmitter<string>();

	get item(): WorkflowableItem {
		this.disableStatus = this.checkPermissions();
		return this._item;
	}

	get lifecycle(): WorkflowDisplayStatus[] {
		return this._lifecycle;
	}

	public workflow: Workflow;

	public workflowStatus: WorkflowStatus;

	public showNotes: boolean = false;

	public disableStatus: boolean = false;

	public uploadErrors: Subject<string> = new Subject<string>();

	public isFilesValid: boolean = true;

	public saved: Subject<any> = new Subject();

	private _item: WorkflowableItem;

	private _lifecycle: WorkflowDisplayStatus[];

	constructor(protected service: WorkflowService, private messageService: MessageService) {}

	ngOnInit(): void {
		this.disableStatus = this.checkPermissions();
	}

	/**
	 * Adds a class style to the workflow status based on the status name
	 * @param {string} workflowStateValue
	 * @return {string[]}
	 */
	public getWorkflowStatusFromValue(workflowStateValue: string): WorkflowState {
		return this.workflow.states.find((s: WorkflowState) => s.value === workflowStateValue);
	}

	/**
	 * Adds a class style to the workflow status based on the status name
	 * @param {WorkflowState} workflowState
	 * @return {string[]}
	 */
	public statusClass(workflowState: WorkflowState): string {
		let string = this.service.getStatusClass(workflowState);
		// add class to disabled options
		if (this.disabledState(workflowState)) {
			string = string + ' locked';
		}
		return string;
	}
	// disable options based on role's
	public disabledState(workflowState: WorkflowState):boolean {
		let disabled = false;
		let currentStatus = '';
		if(this._item.lifecycle && this._item.lifecycle.length){
			currentStatus = this._item.lifecycle[this._item.lifecycle.length -1].status
		}
		// Shop Foreman can only set to submitted
		
		if (this.type === 1 && workflowState && this.role === 15 && (
			workflowState.value === 'new' || 
			workflowState.value === 'acknowledged' || 
			workflowState.value === 'fulfilled' || 
			workflowState.value === 'partial_ready' || 
			workflowState.value === 'partial_notready' ||
			workflowState.value === 'hold' ||
			workflowState.value === 'paid'
			)) {
			disabled = true;
		};
		// only admins can set payables to posted, sent_to_accounting, rejected, printed or void
		if (this.type === 4 && workflowState && this.role !== 0 && (
			workflowState.value === 'posted' || 
			workflowState.value === 'sent_to_accounting' || 
			workflowState.value === 'printed' || 
			workflowState.value === 'void' || 
			workflowState.value === 'rejected')) {
			disabled = true;
		};
		if(workflowState.value != 'finalized'){
			// disable options for receivables based on priority
			if(this.type === 3 && 
				(this.priority === 'None' && (workflowState.value === 'pending_supplies' || workflowState.value === 'ready_full' || workflowState.value === 'scheduled' || workflowState.value === 'completed'))
				|| ((this.priority === '1' || this.priority === '5') 
					&& (currentStatus != 'completed' && currentStatus != 'pending' && currentStatus != 'submitted' && currentStatus != 'approved' && currentStatus != 'rejected') 
					&& (workflowState.value === 'new' || workflowState.value === 'pending' || workflowState.value === 'submitted' || workflowState.value === 'approved' || workflowState.value === 'rejected'))
			){
				disabled = true;
			};
		} else {
			// only admins can set receivables to finalized
			if (this.type === 3 && workflowState && this.role !== 0 && (workflowState.value === 'finalized')) {
				disabled = true;
			};
		}
		return disabled;
	}

	// auto-set status based on change to priority
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['priority']) {
			if (this.priority === '5') {
				let newStatus = this.type === 0 ? 'pending' : 'pending_supplies'; // for WOs, pending = 'pending supplies'. For receivables pending = 'pending approval'
				this.workflowStatus = {
					status: newStatus,
					note: 'Priority set to 5',
					timestamp: new Date().toISOString(),
					attachments: null
				};
			} else if (this.priority === '1') {
				this.workflowStatus = {
					status: 'ready_full',
					note: 'Priority set to 1',
					timestamp: new Date().toISOString(),
					attachments: null
				};
			}
			this.updatePriority = false;
		}
		if (changes['updatePriority']) {
			if (this.updatePriority) {
				this.update();
			}
		}
		if (changes['printed']) {
			if (this.printed) {
				this.onUpdate.emit(this.workflowStatus.status);
			}
		}
	}

	public update(): void {
		const newStatus = this.workflowStatus;
		newStatus.timestamp = new Date().toISOString();

		this.service.updateWorkflow(this.item, newStatus)
			.subscribe(
				(response: any)  => {
					if (!response.lifecycle) {
						this.notify(response.message, { type: 'error' });
					} else {
						if (response.lifecycle.length > 0) {
							const savedResponse = response.lifecycle[response.lifecycle.length - 1];
							if (savedResponse.status === newStatus.status) {
								updateLifecycle(this.item, savedResponse);
								setTimeout(() => {
									this.workflowStatus = this.defaultWorkflow();
									this.createDisplayableLifecycle();
									if (this.updatePriority) {
										this.onUpdate.emit(this.workflowStatus.status);
									}
								}, 0);
							}
						}
					}
				},
				(err: any) => this.onError(err));
	}

	public cancel(): void {
		this.workflowStatus = this.defaultWorkflow();
	}

	public getDisplayLifecycle(lifecycle: WorkflowStatus[]): WorkflowDisplayStatus[] {
		return lifecycle.map((status: WorkflowStatus) => {
			const displayStatus = this.getWorkflowStatusFromValue(status.status);
			return {
				status: displayStatus,
				note: status.note,
				timestamp: status.timestamp,
				attachments: status.attachments
			};
		});
	}

	public setAttachments(files: File[]): void {
		this.workflowStatus.attachments = [];
		files.forEach(file => {
			this.workflowStatus.attachments.push({file: file, name: 'atf'});
		});
	}

	public invalidFiles(uploaderValid: boolean): void {
	  this.isFilesValid = uploaderValid;
  }

	public checkPermissions(): boolean {
		return (this.role === UserRole.ProjectManager && this.workflowStatus.status === 'on_hold');
	}

	public toggleNotes(): void {
		this.showNotes = true;
	}

	notify(message: string, options: any = {}): void {
    this.messageService.send({ message: message, type: options.type } as IMessageModel);
  }

	private defaultWorkflow(): WorkflowStatus {
		this.showNotes = false;
		let defaultWorkflow = this.workflow.defaultWorkflow();

		if (this.item.lifecycle != null && this.item.lifecycle.length > 0) {
			defaultWorkflow.status = this.item.lifecycle[this.item.lifecycle.length - 1].status;
		} else {
			defaultWorkflow.status = null;
		}
		return defaultWorkflow;
	}

	private createDisplayableLifecycle(): void {
		const statuses = this.item.lifecycle || [];
		this._lifecycle = this.getDisplayLifecycle(statuses);
	}

	private onError(error: HttpErrorResponse): void {
		if (error.status === 413 /** Payload too large */) {
      this.uploadErrors.next('payload_too_large');
    } else if (error.error instanceof ProgressEvent) {
		  // progress error occurs because the AWS api gateway returns a 413 without Access-Control-Allow-Origin header
      // and https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-known-issues.html says that
      // gateway currently doesn't support customizing the 413 response so we can't fix that. So the caught error is
      // a progress event
      this.uploadErrors.next('progress_error');
    } else {
		  // todo should add better handling for other server errors besides just file errors
      this.cancel();
    }
	}
}
