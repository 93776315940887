import { Renderer2, Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
    selector: 'input[initialFocus],select[initialFocus]'
})
export class InitialFocusDirective implements AfterViewInit {
    constructor(private renderer : Renderer2, private elementRef : ElementRef) {}

    ngAfterViewInit() : void {
        const self = this;
        self.elementRef.nativeElement.focus()
        // ensure field contents selected after view has settled
        // setTimeout(() => {
        //     let method = 'select';

        //     // drop-downs / multi-select don't have text selection
        //     if((self.elementRef.nativeElement.type === 'select-one') || (self.elementRef.nativeElement.type === 'select-multiple')) {
        //         method = 'focus';
        //     }
        //     self.renderer.invokeElementMethod(self.elementRef.nativeElement, method, []);
        // }, 0);
    }
};
