import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IDataFilterProvider } from './dataManipulationUtils';

@Directive({
    selector: '[ngModel][filterData]'
})
export class FilterDataDirective implements OnInit, OnDestroy {
    private _subscription: Subscription;

    constructor(private _model: NgModel) {}

    @Input()
    filterManager: IDataFilterProvider;

    @Input()
    filterData: string;

    ngOnInit() : void {
        const self = this;

        if(!self._model) { return; }

        self._subscription = self._model.control.valueChanges.subscribe((s) => {
            self.filterManager.setFilter(self.filterData, s);
        });
    }

    ngOnDestroy() : void {
        this._subscription.unsubscribe();
    }
};
