import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { IUserModel } from '@/models/user.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'user-edit',
  templateUrl: './userEdit.template.html',
  host: {'class': 'user-edit-component'}
})
export class UserEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IUserModel>;

	private _id: string;
	private _locationId: string;
	private _parentRoute: ActivatedRoute;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private locationService: LocationService,
		private state: StateManager,
		messageService: MessageService
	) {
		super(messageService);
	}

	ngOnInit(): void {
    this.model = this.state.getLocationSelectedUser();

	this._id = routerUtils.getRouteParameter(this.route, 'userId');
    this._locationId = routerUtils.getRouteParameter(this.route, 'locationId');
    this._parentRoute = routerUtils.getParentRoute(this.route, 1);

    if (this._id) {
      this.locationService.getUser(this._locationId, this._id);
    }
	}

	public saveUser(newModel: IUserModel): void {
    this.locationService.updateUser(this._locationId, newModel)
			.then(() => this.router.navigate(['.'], { relativeTo: this._parentRoute }))
			.catch(handleError);
	}

	public cancel(): void {
	  this.router.navigate(['.'], { relativeTo: this._parentRoute });
	}
}
