import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IContactModel, ContactModel } from '@/models/contact.models';

export interface IContactState {
	contacts: Array<IContactModel>,
	activeContact: IContactModel
}

const defaultState : IContactState = {
	contacts: new Array<IContactModel>(),
	activeContact: new ContactModel()
};

export const CONTACT_ACTIONS = {
	SET_CONTACTS_LIST: 'SET_CONTACTS_LIST',
	SET_ACTIVE_CONTACT: 'SET_ACTIVE_CONTACT'
};

const contactReducer = function(state: IContactState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case CONTACT_ACTIONS.SET_CONTACTS_LIST:
			newState.contacts = action.payload;
			break;
		case CONTACT_ACTIONS.SET_ACTIVE_CONTACT:
			newState.activeContact = action.payload;
			break;
	}

	return newState;
};

export function getContactsList() {
	return (state : Store<IContactState>) => {
		return state.select(s => {
			return s.contacts;
		});
	};
}

export function getActiveContact() {
	return (state : Store<IContactState>) => {
		return state.select(s => s.activeContact)
	};
}

export function reducer(state: IContactState | undefined, action: Action) {
	return contactReducer(state, action);
}
