import { Component } from '@angular/core';

@Component({
    selector: 'print-button',
    template: `
<nav>
    <ul class="nav nav-tabs">
        <li class="pull-right">
            <a (click)="print()">
                Print
            </a>
        </li>
    </ul>
</nav>
    `,
    host: {'class': 'print-button-component'}
})
export class PrintButtonComponent {
    public print() {
        window.print();
    }
};
