import { Directive, Output, EventEmitter, HostBinding, HostListener, Renderer2, ElementRef } from '@angular/core';

/**
 * Directive created based on:
 * https://medium.com/@mariemchabeni/angular-7-drag-and-drop-simple-file-uploadin-in-less-than-5-minutes-d57eb010c0dc
 * https://stackoverflow.com/questions/41520681/how-to-add-remove-class-from-directive
 */
@Directive({
	selector: '[fileDnD]'
})
export class DragDropDirective {

	@Output() onFileDropped: EventEmitter<any> = new EventEmitter<any>();

	@HostBinding('style.background-color') private background: string = '#f5fcff';
	@HostBinding('style.opacity') private opacity: string = '1';

	constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

	@HostListener('dragenter', ['$event']) onDragEnter(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.renderer.addClass(this.elementRef.nativeElement, 'dnd-hover');
	}

	@HostListener('dragover', ['$event']) onDragOver(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.renderer.addClass(this.elementRef.nativeElement, 'dnd-hover');
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.renderer.removeClass(this.elementRef.nativeElement, 'dnd-hover');
	}

	@HostListener('drop', ['$event']) public onDrop(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.renderer.removeClass(this.elementRef.nativeElement, 'dnd-hover');

		let files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.onFileDropped.emit(files);
		}
	}
}
