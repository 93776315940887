import { Routes } from '@angular/router';

import { WorkOrderComponent } from './workOrder.component';
import { WorkOrderAddComponent } from './workOrderAdd.component';
import { WorkOrderEditComponent } from './workOrderEdit.component';
import { WorkOrderConnectionComponent } from './workOrderConnection.component';
import { WorkOrderPMNotesComponent } from './workOrderPMNotes.component';
import { WorkOrderPartsComponent } from './workOrderParts.component';
import { WorkOrderDocumentsComponent } from './workOrderDocuments.component';
import { MsalGuard } from '@azure/msal-angular';

export const WORKORDER_ROUTES : Routes = [
    {path: 'Add', component: WorkOrderAddComponent},
    {path: ':workOrderId', canActivate: [ MsalGuard ], component: WorkOrderComponent, children: [
        {path: '', redirectTo: 'Edit', pathMatch: 'prefix'},
        {path: 'Edit', component: WorkOrderEditComponent},
        {path: 'PurchaseOrders', component: WorkOrderConnectionComponent},
        {path: 'Parts', component: WorkOrderPartsComponent},
        {path: 'PMNotes', component: WorkOrderPMNotesComponent}, 
        {path: 'Documents', component: WorkOrderDocumentsComponent}
    ]}
];
