import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilityModule } from './../../utils/utility.module';
import { StaffModule } from './../StaffComponent/staff.module';
import {IMaskModule} from 'angular-imask';
import { LocationComponent } from './location.component';
import { LocationListComponent } from './locationList.component';
import { LocationEditComponent } from './locationEdit.component';
import { LocationEditFormComponent } from './locationEditForm.component';

@NgModule({
    declarations: [
        LocationComponent,
        LocationListComponent,
        LocationEditComponent,
        LocationEditFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        UtilityModule,
        StaffModule,
        IMaskModule
    ],
    exports: [
        LocationComponent,
        LocationListComponent,
        LocationEditComponent,
    ]
})
export class LocationModule {}
