import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IJobModel, JobModel, ILaborCodeModel } from '@/models/job.models';

export interface IJobState {
    jobs: Array<IJobModel>,
    activeJob: IJobModel
}

const defaultState = {
    jobs: new Array<IJobModel>(),
    activeJob: new JobModel()
};

export interface ILaborCodeState {
    laborCodes: Array<ILaborCodeModel>,
}

export const JOB_ACTIONS = {
    SET_JOBS_LIST: 'SET_JOBS_LIST',
    SET_ACTIVE_JOB: 'SET_ACTIVE_JOB'
};

const jobReducer = function(state: IJobState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case JOB_ACTIONS.SET_JOBS_LIST:
			newState.jobs = action.payload;
			break;
		case JOB_ACTIONS.SET_ACTIVE_JOB:
			newState.activeJob = action.payload;
			break;
	}

	return newState;
};

export function getJobsList() {
	return (state : Store<IJobState>) => {
		return state.select(s => {
			return s.jobs;
		});
	};
}

export function getActiveJob() {
	return (state : Store<IJobState>) => {
		return state.select(s => s.activeJob)
	};
}

export function getLaborCodesList() {
	return (state : Store<ILaborCodeState>) => {
		return state.select(s => {
			return s.laborCodes;
		});
	};
}

export function reducer(state: IJobState | undefined, action: Action) {
	return jobReducer(state, action);
}
