import * as Utility from './utility.models';
import * as Staff from './staff.models';
import { LaborCodeModel } from './job.models';
import * as dataUtils from './../utils/dataUtils';

export const TimeEntryRateTypes: Utility.Enum<string> = {
    Regular: 'Regular',
    Overtime: 'Overtime',
    RideTime: 'Ride time',
    DoubleTime: 'Double time'
};

export const TimeEntryLaborTypes: Utility.Enum<string> = {
    DirectLabor: 'Field Labor',
    ShopLabor: 'Shop Labor'
};

export interface ITimeEntryModel extends Utility.BaseModel {
    date: Date;
    staff: Utility.IBackReference | string;
    rate: number;
    rateType: string;
    units: number;
    laborType: string;
    laborCodeId: string;
    laborCodeText: string;
    workOrder: Utility.IBackReference | string;
    timestamp: Date;
    jobView: boolean;
    notes: string;
};

export interface IMoveTimeEntry {
    date: Date,
    jobId: string;
    notes: string;
    entries: Array<string>;
}

export class TimeEntryModel extends Utility.BaseModel implements ITimeEntryModel {
    date: Date = dataUtils.sanitizeDate();
    staff: Utility.IBackReference | string = null;
    rate: number = null;
    rateType: string = null;
    units: number = null;
    laborType: string = null;
    laborCodeId: string = null;
    laborCodeText: string = null;
    workOrder: Utility.IBackReference | string = Utility.EmptyBackReference;
    override timestamp: Date = null;
    jobView: boolean = false;
    notes: string = null;
};
