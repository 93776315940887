import { Store } from '@ngrx/store';
import { PayloadAction as Action } from './../../utils/storeUtils';

import { IWorkOrderModel } from './../../models/workOrder.models';

export interface IWorkOrderState {
	workOrders: Array<IWorkOrderModel>,
	activeWorkOrder: IWorkOrderModel
}

const defaultState = {
	workOrders: new Array<IWorkOrderModel>(),
	activeWorkOrder: null
};

export const WORKORDER_ACTIONS = {
	SET_WORKORDERS_LIST: 'SET_WORKORDERS_LIST',
	SET_ACTIVE_WORKORDER: 'SET_ACTIVE_WORKORDER'
};

const workOrderReducer = function(state: IWorkOrderState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case WORKORDER_ACTIONS.SET_WORKORDERS_LIST:
			newState.workOrders = action.payload;
			break;
		case WORKORDER_ACTIONS.SET_ACTIVE_WORKORDER:
			newState.activeWorkOrder = action.payload;
			break;
	}

	return newState;
};

export function getWorkOrdersList() {
    return (state : Store<IWorkOrderState>) => {
        return state.select(s => {
            return s.workOrders;
        });
    };
}

export function getActiveWorkOrder() {
    return (state : Store<IWorkOrderState>) => {
        return state.select(s => s.activeWorkOrder)
    };
}

export function reducer(state: IWorkOrderState | undefined, action: Action) {
	return workOrderReducer(state, action);
}
