import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { IJobModel } from '@/models/job.models';
import { LocationService } from '@/services/location.service';
import * as Utility from '@/models/utility.models';
import * as routerUtils from '@/utils/routerUtils';
import { handleError } from '@/utils/errors';
import { FilterValue } from '@/models/filter.models';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'job-search',
  templateUrl: './jobSearch.template.html',
  host: {'class': 'job-search-component'}
})
export class JobSearchComponent extends BaseSmartComponent implements OnInit, OnDestroy {
  @ViewChild('jobSearch', {static: true})
  jobSearch: Utility.IFocusable;

  @ViewChild('jobResultList', {static: true})
  jobResultList: ElementRef;

  @Input()
  isLoading: boolean;

  public jobNumber: string;
  public jobResults: Array<IJobModel>;
  public filters: FilterValue[] = [];

  private lastJobNumber: string;
  private _customer: Utility.IBackReference;
  private _job: IJobModel;
  private _locationId: string;
  private _currentUser: any;
	private _location: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobService,
    private locationService: LocationService,
    private state: StateManager,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void { 
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this._currentUser?.locationId || environment.MADISON_ID;
    this.filters.push({ filter: 'locationFilter', id: this._location });
  }

  override ngOnDestroy(): void { }

  // requires one and only one - search over job number *only*
  public searchJobs(): void {
    if (this.jobNumber === '') {
      this.jobNumber = null;
    }

    if (this.jobNumber === this.lastJobNumber) {
        return;
    }

    this.lastJobNumber = this.jobNumber;
    this._job = null;
    this.jobResults = null;

    // search cleared
    if (!this.jobNumber) {
        return;
    }

    // require most of the job number before executing the search
    if (this.jobNumber.length < 4) { return; }

    this.jobService.getJobs(0, true, this.jobNumber, 'date', -1, 1, this.filters)
      .then(jobs => {
        if (jobs.length === 1) {
          // found exactly one job - use this
          this.goToJob(jobs[0]);
          this.state.setJobsList(new Array<IJobModel>());
        } else {
          this.jobResults = jobs;

          if (this.jobResultList) {
            // previous tab-out takes effect before resetting focus
            setTimeout(() => {
              this.jobResultList.nativeElement.focus();
            }, 0);
          }
        }
      })
      .catch(handleError);
  }

  private selectJob(job: IJobModel): void {
    // show selected job info
    this._job = job;
    this.jobNumber = this._job.number;
    this.jobResults = null;
  }

  private selectJobSearch(): void {
    if (this.jobSearch) {
      this.jobSearch.focus();
    }
  }

  private goToJob(job: IJobModel): void {
    let currentView = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    let customer = job.customer as Utility.IBackReference;
    if (currentView === 'TimeEntry') {
      this.router.navigate(
        ['/Customers', customer._id.toString(), 'Job', job._id, 'TimeEntry'],
        { relativeTo: routerUtils.getParentRoute(this.route) }
      );
    } else if (currentView === 'Costs') {
      this.router.navigate(
        ['/Customers', customer._id.toString(), 'Job', job._id, 'Costs'],
        { relativeTo: routerUtils.getParentRoute(this.route) }
      );
    } else {
      this.router.navigate(
        ['/Customers', customer._id.toString(), 'Job', job._id, 'Edit'],
        { relativeTo: routerUtils.getParentRoute(this.route )}
      );
    }
    this.jobNumber = null;
    this.jobResults = null;
  }
}
