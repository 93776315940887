/**
 * Created by rrahlf on 4/10/2017.
 */
import { Routes } from '@angular/router';

import { ProfileComponent } from './profile.component';
import { ProfileEditComponent } from './profileEdit.component';
import { MsalGuard } from '@azure/msal-angular';

export const PROFILE_ROUTES: Routes = [
  {
    path: 'Profile',
    canActivate: [MsalGuard],
    component: ProfileComponent,
    children: [
      {
        path: '',
        component: ProfileEditComponent,
      },
    ],
  },
];