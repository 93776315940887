import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { IReportJournalCostResult } from '@/models/report.models';
import { ReportService } from '@/services/report.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ReportJournalCostSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'report-journal-cost',
  templateUrl: './reportJournalCost.template.html',
  host: {'class': 'report-journal-cost-component'}
})
export class ReportJournalCostComponent extends BaseSmartComponent implements OnInit {
  public date: Date = this.sanitizeDate();
  public dirty: boolean = true;
  public model: Subject<Array<IReportJournalCostResult>> = new Subject<Array<IReportJournalCostResult>>();
  public reportType: string = 'materials';
  public includeClosed: boolean = false;
  public cityTaxTotal: number = 0;
  public countyTaxTotal: number = 0;
  public mkecountyTaxTotal: number = 0;
  public stadiumTaxTotal: number = 0;
  public stateTaxTotal: number = 0;
  public grandTotal: number = 0;
  public entryCount: number = 0;
  public costTotal: number = 0;
  public hasStadiumTax: boolean = false;
  private _rootRoute: ActivatedRoute;
  private _location: string;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    public dataManipulationService: DataManipulationService<IReportJournalCostResult>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.watchSubscription(this.route.queryParams.subscribe((s) => {
      if (s['reportType']) {
        switch (s['reportType']) {
          case 'outsideServices':
            this.reportType = 'outsideServices';
            break;
          case 'materials':
          default:
            this.reportType = 'materials';
            break;
        }
        this.markDirty();
      }
    }));

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = currentUser.locationId;
    this._rootRoute = routerUtils.getParentRoute(this.route, 4);
    this.dataManipulationService.initialize({}, ReportJournalCostSortFunctions);
    this.dataManipulationService.setSort('timestamp', false);
  }

  public runReport(): void {
    // stadium tax was discontinued as of 4/1/2020 but we need to display for jobs before that date
    const stadiumTaxEnded = new Date('3/31/2020');
    this.hasStadiumTax = (this.date < stadiumTaxEnded);

    let results = this.reportService.getJournalCostReport(this.reportType, new Date(this.date), this.includeClosed, this._location);
    this.watchSubscription(this.dataManipulationService.connectDataSource(results, this.model));

    results.toPromise()
      .then((results) => {
        this.entryCount = results.length;
        this.costTotal = 0;
        this.cityTaxTotal = 0;
        this.countyTaxTotal = 0;
        this.mkecountyTaxTotal = 0;
        this.stadiumTaxTotal = 0;
        this.stateTaxTotal = 0;

        for (let result of results) {
          this.costTotal += result.cost;
          this.cityTaxTotal += result.cityTax;
          this.countyTaxTotal += result.countyTax;
          this.mkecountyTaxTotal += result.mkecountyTax;
          this.stadiumTaxTotal += result.stadiumTax;
          this.stateTaxTotal += result.stateTax;
        }

        this.grandTotal = this.costTotal + (this.cityTaxTotal ? this.cityTaxTotal : 0) + this.countyTaxTotal + this.mkecountyTaxTotal + this.stadiumTaxTotal + this.stateTaxTotal;
        this.dirty = false;
      })
      .catch(handleError);
  }

  public toggleIncludeClosed(): void {
    this.includeClosed = !this.includeClosed;
    this.markDirty();
  }

  public markDirty(): void {
    this.dirty = true;
  }

  private editJob(cost: IReportJournalCostResult): void {
    this.router.navigate(
      ['/Customers', cost.customerId, 'Job', cost.jobId, 'Edit'],
      { relativeTo: routerUtils.getParentRoute(this.route) }
      );
  }

  private editPurchaseOrder(cost: IReportJournalCostResult): void {
    if (cost.purchaseOrderId !== null) {
      this.router.navigate(
        ['/Customers', cost.customerId, 'Job', cost.jobId, 'PurchaseOrders', cost.purchaseOrderId, 'Edit'],
        { relativeTo: routerUtils.getParentRoute(this.route) }
        );
    } else {
      this.router.navigate(
        ['/Customers', cost.customerId, 'Job', cost.jobId, 'Costs'],
        { relativeTo: routerUtils.getParentRoute(this.route) }
        );
    }
  }
}
