import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
	InvoiceWorkflow,
	PurchaseOrderWorkflow,
	Workflow, WorkflowProgress,
	WorkflowableItem, WorkflowStatus, WorkflowState,
	WorkOrderWorkflow,
	PayableWorkflow,
	BatchWorkflowRequest
} from '@/models/workflow.models';
import { isIPayableModel } from '@/models/payable.models';
import { isIPurchaseOrder } from '@/models/purchaseOrder.models';
import { isIWorkOrder } from '@/models/workOrder.models';
import { isIInvoiceModel } from '@/models/invoice.models';

@Injectable()
export class WorkflowService {
	private readonly purchaseOrderWorkflow: Workflow = new PurchaseOrderWorkflow();
	private readonly workOrderWorkflow: Workflow = new WorkOrderWorkflow();
	private readonly invoiceWorkflow: Workflow = new InvoiceWorkflow();
	private readonly payableWorkflow: Workflow = new PayableWorkflow();

	constructor(private _http: HttpClient) {}

	/**
	 * Gets the workflow that corresponds to an item based on the item's type
	 * @param {WorkflowableItem} item
	 * @return {Workflow}
	 */
	getByItem(item: WorkflowableItem): Workflow {
		if (isIPurchaseOrder(item)) {
			return this.purchaseOrderWorkflow;
		} else if (isIWorkOrder(item)) {
			return this.workOrderWorkflow;
		} else if (isIInvoiceModel(item)) {
			return this.invoiceWorkflow;
		} else if (isIPayableModel(item)) {
			return this.payableWorkflow;
		}
		throw Error('Item does not have a workflow');
	}

	updateWorkflow(item: WorkflowableItem, status: WorkflowStatus): Observable<WorkflowableItem> {
		const workflow = this.getByItem(item);
		const path = workflow.path(item) + '/lifecycle';

		if (status.attachments && status.attachments.length) {
			const files = status.attachments;
			let formData = new FormData();
			files.forEach(file => {
				formData.append('file', file.file, file.file.name);
			});
			formData.append('status', status.status);
			if (status.note) {
				formData.append('note', status.note);
			}
			formData.append('timestamp', status.timestamp);
			return this._http.post<WorkflowableItem>(path, formData);
		} else {
			return this._http.post<WorkflowableItem>(path, status);
		}
	}

	getStatusClass(workflowState: WorkflowState): string {
		let style: string = '';
		if (workflowState) {
			switch (workflowState.severity) {
				case 0:
				case WorkflowProgress.INITIAL:
					style = 'gray'; break;
				case 1:
				case WorkflowProgress.RISING:
					style = 'blue'; break;
				case 2:
				case WorkflowProgress.BUDDING:
					style = 'orange'; break;
				case 3:
				case WorkflowProgress.BLOOMING:
					style = 'green'; break;
				case 4:
				case WorkflowProgress.BLOOMED:
					style = 'bright-green'; break;
				case 5:
				case WorkflowProgress.ENDANGER:
					style = 'red'; break;
				default: /* do nothing */ break;
			}
			return style;
		}
	}

	updateWorkflowBatch(workflowType: string, batchWorkflow: BatchWorkflowRequest): Observable<Number> {
		const path = workflowType + '/batchLifecycle';
		return this._http.post<Number>(path, batchWorkflow);
	}

}
