import { Component, ViewChild } from '@angular/core';
import { IAuthUser } from '@/models/auth.models';
import { AuthService } from '@/services/auth.service';
import { UserService } from './services/user.service';
import { AppVersionService } from './services/appVersion.service';
import { environment } from './../environments/environment';
import { filter, tap } from 'rxjs/operators';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'my-app',
  templateUrl: './app.template.html',
  host: {'class': 'my-app-component'}
})

export class AppComponent {
  lastPing?: Date = null;
	user: IAuthUser;
  public isLoggedIn: any;

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  // see app.module for Toastr config

	constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastrService: ToastrService,
    private versionService: AppVersionService,
    private router: Router
    ) {
      this.authService.currentUser
        .subscribe((user) => {
        if (!user) return;

        this.isLoggedIn = true;
      });
  }

	ngOnInit(): void {
    console.info('env: ' + environment.SENTRY_ENVIRONMENT);
    this.toastrService.overlayContainer = this.toastContainer;
    this.versionService.enableUpdates();

    this.authService.initialize();
    this.authService.currentUser
      .pipe(
        filter(user => !!user),
        tap(() => this.userService.getUsers())
      )
      .subscribe(user => {
        this.user = user;
      });

    // scroll to top of page on all route changes
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
          return;
      }
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0);
    });
  }
}
