import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { IWorkOrderModel } from '@/models/workOrder.models';

@Component({
    selector: 'work-order-documents',
    templateUrl: './workOrderDocuments.template.html',
    host: {'class': 'work-order-documents-component'}
})
export class WorkOrderDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    private _customerId: string = '';
    private _jobId: string = '';
		// todo refactor or rename so public property isn't prefixed with an _
    public _workOrderId: string = '';
    public duplicateRecord: boolean = false;
    public model = new Subject<IWorkOrderModel>();
    private currentWO: any;
    private filesQueued: boolean = false;

    constructor(private route: ActivatedRoute, private workOrderService: WorkOrderService, messageService: MessageService, private filesService: FilesService, private state: StateManager) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
        self._workOrderId = routerUtils.getRouteParameter(self.route, 'workOrderId');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    public saveOrder(order: string[]): void {
        const self = this;
        self.filesService.saveFilesOrder('WO', self._workOrderId, order);
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }

    public setMessage(e: boolean) {
        this.filesQueued = e;
    }
}
