import * as Utility from './utility.models';

export interface IUserLocation {
  id: string;
  name : string;
  hasAccess : boolean;
  isDefault : boolean;
  isActive : boolean;
};
export interface IUserModel extends Utility.BaseModel, Utility.IActivateable {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  role: number;
  password: string;
  locations: Array<IUserLocation>
}

// <editor-fold> Document models

export class UserModel extends Utility.BaseModel implements IUserModel {
  username: string = null;
  email: string = null;
  firstName: string = null;
  lastName: string = null;
  role: number = null;
  password: string = null;
  isActive: boolean = true;
  locations: Array<IUserLocation> = null
}

// </editor-fold>
