<div id="file-uploader" fileDnD (onFileDropped)="uploadFile($event)">
  <div class="row mb-2">
    <div *ngIf="!file" class="col-xs-12">
      <div class="well my-drop-zone">
        <div>Drop files to upload</div>
        <div class="small">or</div>
        <button type="button" class="btn btn-outline" style="margin-top:6px;">
          <label for="fileUploader">
            Select Files
            <input hidden id="fileUploader" type="file" #fileInput (change)="uploadFile($event.target.files)" multiple style="display: none;">
          </label>
        </button>
      </div>
        <div *ngFor="let error of fileUploadErrors" class="alert alert-danger text-center">{{error.message}}</div>
    </div>
  </div>
  <div *ngIf="nonUniqueFilename" class="row">Filenames must be unique</div>
  <div class="row">
    <div class="col-xs-12">
      <table class="table uploader" aria-describedby="fileUploaderLabel">
        <thead>
          <tr>
            <th class="pl-0" scope="col" id="fileUploaderLabel">New Documents</th>
            <th colspan="3" class="pl-0" scope="col">Size</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of files; index as i" class="docs-list">
            <td scope="row" class="pl-0">{{ file.name }}</td>
            <td scope="row" nowrap>{{ file.size/1024/1024 | number:'.2' }} MB</td>
            <td  scope="row"class="text-center">
            </td>
            <td scope="row" nowrap class="text-right pr-1">
              <button type="button" class="btn btn-danger" (click)="deleteAttachment(i)"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
