import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { IInvoiceModel } from '@/models/invoice.models';
import { PrintService } from '@/services/print.service';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'invoice',
  templateUrl: './invoice.template.html',
  host: {'class': 'invoice-component'}
})
export class InvoiceComponent extends PrintableComponent implements OnInit, OnDestroy {
  public model: IInvoiceModel = null;

  // todo validate this should always be false. It was missing
  public formChanged: boolean = false;

  private _customerId: string;
  private _jobId: string;
  private _invoiceId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private jobService: JobService,
    printService: PrintService
  ) {
    super(router, printService, messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    let editRoute = routerUtils.getRouteParameterObservable(this.route, 'invoiceId');
    if (editRoute) {
      this.watchSubscription(editRoute.subscribe((s) => {
        if (s['invoiceId']) {
          this._invoiceId = s['invoiceId'];
          this.jobService.getInvoice(this._customerId, this._jobId, this._invoiceId)
            .then(invoice => this.model = invoice)
            .catch(handleError);
          this.printUrlBase = '/jobs/' + this._jobId + '/invoices/' + this._invoiceId;
        } else {
          //TODO: reroute to somewhere else
        }
      }));
    }
    this.printOptions.copies = [
      { index: 'projectManager', label: 'Control', enabled: false },
      { index: 'accounting', label: 'Accounting', enabled: false },
      { index: 'customer', label: 'Customer', enabled: false },
      { index: 'orderAndFile', label: 'Order and File', enabled: false },
      { index: 'woField', label: 'WO Field', enabled: false },
      { index: 'woTaggingAndDelivery', label: 'WO Tagging and Delivery', enabled: false }
    ];
  }

  override ngOnDestroy(): void {
    this.state.setActiveWorkOrder(null);
    super.ngOnDestroy();
  }
}
