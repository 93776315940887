import { Component, OnInit, ViewChild, ViewChildren, ElementRef, Input, Output, EventEmitter, QueryList } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {Subscription} from 'rxjs';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '../../services/message.service';
import { ToggleViewService } from '../../services/toggleView.service';
import {AngularMyDatePickerDirective, DefaultView, IAngularMyDpOptions, IMyCalendarViewChanged, IMyDate, 
    IMyDateModel, IMyDefaultMonth, IMyInputFieldChanged, IMyMarkedDate, IMyRangeDateSelection, CalAnimation, ActiveView} from 'angular-mydatepicker-ivy';

@Component({
    selector: 'filter-entries',
    templateUrl: './filterEntries.template.html',
    host: {'class': 'filter-entries-component'},
    animations: [
       trigger('fadeInOut', [
           state('void', style({
               opacity: 0,
               height:0,
               overflow:'hidden'
           })),
             transition('void <=> *', animate(250)),
         ]),
    ]
})

export class FilterEntriesComponent extends BaseSmartComponent implements OnInit {

    @ViewChildren('selectField') selectComponents: QueryList<any>;

    @ViewChild('angular-mydatepicker', {static: true})
    dp: ElementRef;
  
    @Input()
    filterOptions;

    @Output()
    filterValue: EventEmitter<Object> = new EventEmitter<Object>();

    @Output()
    clearAll: EventEmitter<boolean> = new EventEmitter<boolean>();

    public viewFilters = false;
    public viewTab = true;
    private filters: any=[];
    private subscription: Subscription;
    private viewState: any;
    public myDpOptions: IAngularMyDpOptions = {
        dateRange: true,
        dateFormat: 'mm/dd/yyyy',
        firstDayOfWeek: 'su',
        sunHighlight: false,
        openSelectorTopOfInput: false,
        calendarAnimation: {in: CalAnimation.Fade, out: CalAnimation.Fade},
      };
    public dateRange: IMyDateModel = null;

    constructor(messageService : MessageService, private toggleView: ToggleViewService) {
        super(messageService);
    }

    ngOnInit() {
        const self = this;
        this.initFilters();
        this.toggleView.updateView(false, false);

        // watch for changes to filter view
        this.subscription = this.toggleView.viewState
       .subscribe(item => {
            this.viewState = item;
            if(this.viewState.move) {
                this.viewFilters = false
                this.viewTab = false
            } else {
                this.viewTab = true
            }
        })
    }
    override ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    public toggleFilterView() {
        this.viewFilters = !this.viewFilters;
        this.toggleView.updateView(this.viewFilters, false);
    }
    private initFilters() {
        this.filterOptions.forEach(option => {
            this.filters.push({type:'', term:''});
        });
        this.filters.hasFilter = true;
        this.dateRange = null;
    }
    public filterOn(e, type, field, index, clear){
        if(field==='text'){
            let filter = {type: type, term: e.target.value}
            this.filters[index] = filter;
        } else if(field==='select'){
            if(clear) {
                let filter = {type: '', term: ''}
                this.filters[index] = filter;
            } else {
                let filter = {type: type, term: e}
                this.filters[index] = filter;
            }
        } else if (field === 'dateRange') {
            const range = e.dateRange;
            if (range.beginJsDate != null && range.endJsDate != null) {
                this.filters[index].type = type.type;
                range.beginJsDate.setHours(-6,0,0,0);
                range.endJsDate.setHours(-6,0,0,0);
                this.filters[index].startDate = range.beginJsDate.toISOString();
                this.filters[index].endDate = range.endJsDate.toISOString();
            } else {
                this.filters[index].type = '';
                this.filters[index].startDate = '';
                this.filters[index].endDate = '';
            }
        }
        this.filterValue.emit(this.filters);
    }
    public clearFilters() {
        let i = 0;
        this.filterOptions.forEach(field => {
            let input = (<HTMLInputElement>document.getElementById('inputField'+i));
            if(input){
                input.value = '';
            }
            i++
        });
        this.selectComponents.forEach(select => {
            if(select.active) {
                select.active.length = 0;
            }
        });
        this.filters.forEach(filter => {
            filter.type = '';
            filter.term = '';
            filter.startDate = '';
            filter.endDate = '';
        });
        this.initFilters();
        this.clearAll.emit(true);
    }
}
