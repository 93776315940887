<div *ngIf="user && isLoggedIn && !isLoading" class="container dashboard">
	<div class="row" [@fadeInOut]>
		<div class="col-xs-12">
      <job-search [isLoading]="false"></job-search>
    </div>
	</div>
	<div class="row" [@fadeInOut]>
		<div class="col-md-12">
            <div class="row mb-1 mt-2" style="display:flex">
                <div class="col-xs-12 col-md-6 message">
                    <h1 *ngIf="user?.firstName">Hi {{user?.firstName}},</h1>
                </div>
                <div class="col-xs-12 col-md-6 tools text-right">
                    <a [routerLink]="['../Customers/Add']" class="add-on">
                        <div class="add-icon">+</div><span>Add a customer</span>
                    </a>
                    <a [routerLink]="['../Admin/Suppliers/Add']" class="add-on ml-2">
                        <div class="add-icon">+</div><span>Add a supplier</span>
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="card mb-2">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="total">
                                    <!--
                                      only show the count up animation on inital view load
                                      todo if this doesn't work on update, can add onComplete handler to change the behavior after initial count
                                    -->
                                    <div class="number inline-block mr-2" [countUp]="actionItemsDashboard.count" [options]="countOptions">0</div>
                                    <div class="inline-block">
                                        items need my review
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row entity-row">
                            <entity-full *ngIf="actionItemsDashboard.receivablesActionItems?.items.length"
                                         class="col-xs-12 col-md-6 entity-container"
                                         [user]="user"
                                         [actionItems]="actionItemsDashboard.receivablesActionItems"
                                         [@fadeInOut]>
                            </entity-full>
                            <entity-full *ngIf="actionItemsDashboard.payablesActionItems?.items.length"
                                         class="col-xs-12 col-md-6 entity-container"
                                         [user]="user"
                                         [actionItems]="actionItemsDashboard.payablesActionItems"
                                         [@fadeInOut]>
                            </entity-full>
                            <entity-full *ngIf="actionItemsDashboard.purchaseOrderActionItems?.items.length"
                                         class="col-xs-12 col-md-6 entity-container"
                                         [user]="user"
                                         [actionItems]="actionItemsDashboard.purchaseOrderActionItems"
                                         [@fadeInOut]>
                            </entity-full>
                            <entity-full *ngIf="isShopForeman && actionItemsDashboard.workOrderActionItems?.items.length" 
                                        class="col-xs-12 col-md-6 entity-container" 
                                        [user]="user"
                                        [actionItems]="actionItemsDashboard.workOrderActionItems"
                                        [@fadeInOut]
                                        >
                            </entity-full>
                        </div>
                    </div>
                </div>
                <div *ngIf="summary.jobsSummary" class="col-xs-12 col-md-6 mb-1" [@fadeInOut]>
                    <entity-summary [user]="user" [summary]="summary.jobsSummary"></entity-summary>
                </div>
                <div *ngIf="summary.purchaseOrderSummary" class="col-xs-12 col-md-6 mb-1" [@fadeInOut]>
                  <entity-summary [user]="user" [summary]="summary.purchaseOrderSummary"></entity-summary>
                </div>
                <div *ngIf="summary.workOrderSummary" class="col-xs-12 col-md-6 mb-1" [@fadeInOut]>
                    <entity-summary [user]="user" [summary]="summary.workOrderSummary"></entity-summary>
                </div>
                <div *ngIf="summary.receivablesSummary" class="col-xs-12 col-md-6 mb-1" [@fadeInOut]>
                    <entity-summary [user]="user" [summary]="summary.receivablesSummary"></entity-summary>
                </div>
                <div *ngIf="summary.payablesSummary" class="col-xs-12 col-md-6 mb-1" [@fadeInOut]>
                    <entity-summary [user]="user" [summary]="summary.payablesSummary"></entity-summary>
                </div>
            </div>
		</div>
	</div>
</div>
<div *ngIf="!isAllowed && !user && isLoggedIn && !isLoading" class="container dashboard text-center pt-2">
    <h3>You have been logged out or your current Azure Active Directory user does not exist in the Klein Dickert Costing Software.</h3>
    <p class="mt-2">Please <a (click)="processLogout()" style="cursor:pointer;">logout</a> and log back in with a different Azure Active Directory user.</p>
    <a (click)="processLogout()" class="btn btn-primary mt-2 mb-2">Logout</a>
</div>
<div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
