import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {BaseSmartComponent} from '@/components/base.component';
import {MessageService} from '@/services/message.service';
import {IReportJob, IReportJobOutcomeResult} from '@/models/report.models';
import {ReportService} from '@/services/report.service';
import {DataManipulationService} from '@/services/dataManipulation.service';
import {ReportJobOutcomeSortFunctions} from '@/utils/dataManipulationUtils';
import {UserService} from '@/services/user.service';
import {IUserModel} from '@/models/user.models';
import {handleError} from '@/utils/errors';
import {map, tap} from 'rxjs/operators';
import {UserRole} from '@/models/auth.models';
import { isConstructorDeclaration } from 'typescript';

@Component({
  selector: 'report-job-outcome',
  templateUrl: './reportJobOutcome.template.html',
  host: {'class': 'report-job-outcome-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class ReportJobOutcomeComponent extends BaseSmartComponent implements OnInit {
	public year: number = this.sanitizeDate().getFullYear();
	public month: number = (new Date().getMonth()) + 1; // adjust current month to display correctly in the input
	public projectManagers: Array<IUserModel>;
	public pm: any;
  public pmReportListUsers: Array<IUserModel>;

	public totals: IReportJobOutcomeResult;

	public dirty: boolean = true;

	public model: Subject<Array<IReportJobOutcomeResult>> = new Subject<Array<IReportJobOutcomeResult>>();

	private _location: string;
  public currentUser: any;

	constructor(
		private reportService: ReportService,
		private route: ActivatedRoute,
		private router: Router,
		public dataManipulationService: DataManipulationService<IReportJobOutcomeResult>,
		private userService: UserService,
		messageService: MessageService
	) {
    super(messageService);
	}

	ngOnInit(): void {
    this.dataManipulationService.initialize({}, ReportJobOutcomeSortFunctions);
    this.dataManipulationService.setSort('number', true);

		// todo: clean this up (create PMSelector component - see Job List Report as well)
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this.currentUser?.locationId;
    if(this.currentUser?.role === 10 || this.currentUser?.role === 20){
      this.pm = this.currentUser;
    } else {
      this.pm = {userId:null};
    }
    this.userService.getUsers().then(user => {
      this.pmReportListUsers = user;
      for (var i = 0; i < this.pmReportListUsers.length; i++){ 
        if (this.pmReportListUsers[i].role !== UserRole.President && this.pmReportListUsers[i].role !== UserRole.Admin && this.pmReportListUsers[i].role !== UserRole.ProjectManager && this.pmReportListUsers[i].role !== UserRole.VicePresident)  { 
            this.pmReportListUsers.splice(i, 1); 
            i--; 
        }
      }
      this.pmReportListUsers.sort((a, b) => {
        if (a.lastName > b.lastName) { return 1; }
        if (a.lastName < b.lastName) { return -1; }
        return 0;
      });
    })
    .catch(handleError);
	}

  public runReport(): void {
    let results =
      this.reportService.getJobOutcomeReport(this.year, this.month, this.pm?.userId || 'all', this._location)
        .pipe(
          tap(report => this.totals = report.totals),
          map(report => report.results),
          tap(() => this.dirty = false)
        );

    this.watchSubscription(this.dataManipulationService.connectDataSource(results, this.model));
  }

  public markDirty(): void {
    this.dirty = true;
  }

  public pmInitials(projectManager: { _id: string; firstName: string; lastName: string; }): string {
	  if (projectManager && projectManager._id) {
	    return projectManager.firstName[0] + projectManager.lastName[0];
    }
	  return '??';
  }

  private editJob(job: IReportJob): void {
    this.router.navigate(['Customers', job.customerId, 'Job', job._id, 'Edit'], { relativeTo: this.route.root });
  }
}
