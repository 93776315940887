export interface IAddress {
    address1 : string;
    address2 : string;
    city : string;
    state : string;
    zip : string;
    county : string;
    attn : string;
};

export class AddressModel implements IAddress {
    address1: string = null;
    address2: string = null;
    city: string = null;
    state: string = null;
    zip: string = null;
    county: string = null;
    attn: string = null;
};

export interface ISupplierAddress {
    country : string;
    address1 : string;
    address2 : string;
    city : string;
    state : string;
    zip : string;
    county : string;
    attn : string;
};

export class SupplierAddressModel implements IAddress {
    country : string = null;
    address1: string = null;
    address2: string = null;
    city: string = null;
    state: string = null;
    zip: string = null;
    county: string = null;
    attn: string = null;
};