<div id="loading" class="data-loading" *ngIf="!model">Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>

<form class="" (ngSubmit)="savePurchaseOrder(true)" #purchaseOrderForm="ngForm" *ngIf="model" [@fadeInOut]>
    <input name="id" type="hidden" value="{{ model._id }}" />
    <div *ngIf="!showCancel" [@fadeInOut]>
        <div class="row">
            <div class="col-xs-8">
                <div class="screen-title">Purchase Order #{{ model._id ? model.number : 'Automatic' }}</div>
            </div>
            <div class="col-xs-4 text-right">
                <button type="button" class="btn btn-default btn-small btn-checkbox disabled" [ngClass]="{ 'active btn-primary': model.printed }" style="margin-right:4px;"><i class="glyphicon glyphicon-ok"></i></button><span class="small">Printed</span>
            </div>
        </div>

        <workflow [item]="model" [type]="1" [role]="role" [printed]="setFlagByPrinting" (onUpdate)="savePurchaseOrder()"></workflow>
    </div>


    <div class="row">
        <div class="col-xs-12 col-lg-6">

            <fieldset>
                <legend>
                    General information
                </legend>
                <div class="">
                    <div *ngIf="showCancel" class="form-group">
                        <label class="control-label">Number</label>
                        <input type="text" class="form-control" name="number" disabled placeholder="Auto-generated" />
                    </div>
                    <div *ngIf="!showCancel" class="form-group" style="margin-bottom:30px;">
                        <label class="control-label">Number</label>
                        <div class="font-bold">{{ model.number }}</div>
                    </div>

                    <div class="form-group">
                        <label class="control-label">PO Title</label>
                        <div class="">
                            <input type="text" class="form-control" [(ngModel)]="model.name" name="name" #name="ngModel" maxlength="100" autocomplete="off" initialFocus/>
                        </div>
                    </div>

                    <div class="form-group" style="position: relative;">
                        <label class="control-label">Supplier</label>
                        <div class="">
                            <input 
                                class="form-control typeahead supplier-status {{model.supplier.status}}" 
                                [ngClass]="{'ng-invalid':!model.supplier || invalidSupplier}"
                                [typeahead]="suppliers" 
                                [typeaheadOptionField]="'name'" 
                                [typeaheadScrollable]="true"
                                [typeaheadOptionsInScrollableView]="15"
                                [(ngModel)]="tempSupplierName" 
                                [disabled]="!suppliers || (suppliers.length < 1)"
                                (typeaheadOnSelect)="selectSupplier($event)"						
                                data-keyboard-exclude 
                                #supplierField="ngModel"
                                name="supplierField"
                                autocomplete="off" 
                                required
                            />
                            <div class="alert alert-danger" [hidden]="(!purchaseOrderForm.form.submitted && supplierField.pristine) || (model.supplier.name)">
                                Supplier is required
                            </div>
                            <div class="alert alert-danger" *ngIf="invalidSupplier">
                                Invalid supplier
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div (click)="openModal()" class="add-on" style="margin-top:5px;">
                            <div class="add-icon">+</div><span>Add a new supplier</span>
                        </div>
                    </div>
    

                    <div class="form-group">
                        <label class="control-label">Date</label>
                        <div class="">
                            <input type="date" class="form-control width-auto" [ngModel]="model.date | dateFormat" (ngModelChange)="model.date = sanitizeDate($event)" name="date" required="required" #date="ngModel" autocomplete="off" />
                            <div class="alert alert-danger" [hidden]="(!purchaseOrderForm.form.submitted && date.pristine) || date.valid">
                                Date is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label">Ordered By</label>
                        <div class="">
                            <select class="form-control" [(ngModel)]="model.orderedBy" name="orderedBy" required="required" #orderedBy (change)="validateOrderedBy()">
                                <option [value]="" disabled>-- Select --</option>
                                <option *ngFor="let user of users" [value]="user._id">
                                    {{ user.firstName }} {{ user.lastName }}
                                </option>
                            </select>
                            <div class="alert alert-danger" [hidden]="!invalidOrderedBy">
                                Selecet an option
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label">Notes</label>
                        <div class="">
                            <notes-field [(model)]="model.notes" [saved]="saved"></notes-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Associated Documents</label>
                        <document-upload
                            type="PO"
                            [isDraggable]="true"
                            [label]="'Add documents'"
                            [model]="model"
                            [typeId]="uuid"
                            [duplicateRecord]="duplicateRecord"
                            (queued)="setMessage($event)"
                            (duplicateFound)="duplicate()"
                            (saveModel)="savePurchaseOrder()"
                        ></document-upload>
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="col-xs-12 col-lg-6">
            <fieldset>
                <legend>
                    Shipping information
                </legend>

                <div class="mt-1" style="margin-bottom:1.35rem;">
                    <button class="btn btn-outline mr-1" type="button" (click)="copyLocationAddress()">Copy from location address</button>
                    <button class="btn btn-outline" type="button" (click)="copyJobAddress()">Copy from job address</button>
                </div>

                <div class="form-group">
                    <label class="control-label">Company</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.shipping.address.company" name="company" #company="ngModel" autocomplete="off" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Address 1</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.shipping.address.address1" name="address1" required="required" #address1="ngModel" autocomplete="off" />
                        <div class="alert alert-danger" [hidden]="(!purchaseOrderForm.form.submitted && address1.pristine) || address1.valid">
                            Address 1 is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Address 2</label>
                    <div class="">
                        <input class="alert alert-danger" type="text" class="form-control" [(ngModel)]="model.shipping.address.address2" name="address2" autocomplete="off" />
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label city-st-zip"><div class="city">City</div><div class="state">State</div><div class="zip">Zip</div></label>
                    <div class="">
                        <div class="input-group full-width">
                            <input type="text" class="form-control city" [(ngModel)]="model.shipping.address.city" name="city" required="required" #city="ngModel" autocomplete="off" />
                            <state-selector [(ngModel)]="model.shipping.address.state" name="state" required="true" #state="ngModel"></state-selector>
                            <input type="text" class="form-control zip" [(ngModel)]="model.shipping.address.zip" name="zip" required="required" #zip="ngModel" [imask]="{ mask: '00000-0000' }" autocomplete="off" />
                        </div>

                        <div class="alert alert-danger" [hidden]="(!purchaseOrderForm.form.submitted && city.pristine) || city.valid">
                            City is required
                        </div>

                        <div class="alert alert-danger" [hidden]="(!purchaseOrderForm.form.submitted && state.pristine) || state.valid">
                            State is required
                        </div>

                        <div class="alert alert-danger" [hidden]="(!purchaseOrderForm.form.submitted && zip.pristine) || zip.valid">
                            Zip is required
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Wanted</label>
                    <div class="date-button">
                        <div class="input-group">
                            <div class="input-group-btn">
                                <button class="btn btn-outline" type="button" (click)="model.shipping.wanted = 'ASAP'" [ngClass]="{ 'btn-warning active': model.shipping.wanted === 'ASAP' }">ASAP</button>
                            </div>
                            <input class="form-control combo-date" type="text" [(ngModel)]="model.shipping.wanted" name="wanted" #wanted="ngModel" autocomplete="off" />
                        </div>
                        <div class="date-selector-overlay" style="position: relative;">
                            <input class="form-control" type="date" [ngModel]="wantedDate" (ngModelChange)="wantedDate = $event;setWantedDate()" name="wantedDate" autocomplete="off" />
                            <i class="glyphicon glyphicon-calendar"></i>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Ship Via</label>
                    <div class="">
                        <input class="form-control" type="text" class="form-control" [(ngModel)]="model.shipping.shipVia" name="shipVia" #shipVia="ngModel" autocomplete="on"/>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">FOB</label>
                    <div class="">
                        <input class="form-control" type="text" class="form-control" [(ngModel)]="model.shipping.fob" name="fob" #fob="ngModel" autocomplete="on" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Terms</label>
                    <div class="">
                        <input class="form-control" type="text" class="form-control" [(ngModel)]="model.shipping.terms" name="terms" #terms="ngModel" autocomplete="on" />
                    </div>
                </div>
                <div *ngIf="model.exportedKey" class="form-group">
                    <label class="control-label">Exported PDF Version</label>
                    <div class="mb-2">
                        <a (click)="openExportedPDf()" class="toggle-control">{{exportedPdf}}</a>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <p *ngIf="filesQueued" class="error-msg small">Please upload files before saving.</p>
            <button type="submit" class="btn btn-primary mr-1" [disabled]="!purchaseOrderForm.form.valid || filesQueued || invalidSupplier || invalidOrderedBy"><span *ngIf="showCancel">Create</span><span *ngIf="!showCancel">Save Changes</span></button>
            <div class="inline-block pseudo-link small capitalize" *ngIf="showCancel" (click)="cancel()">Cancel</div>
        </div>
    </div>
</form>
<shared-modal [@fadeInOut] [display]="display" [title]="'Add a new supplier'" (onClose)="display='none'" (onSave)="setNewSupplier($event)" [type]="'supplier'" [model]="supplierModel" [showAlert]="showAlert"></shared-modal>
