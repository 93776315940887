import { Component, OnInit, ViewChild, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, Subject, from, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { StateManager } from '@/state/stateManager';

import { BaseSmartComponent } from '@/components/base.component';
import { PaginationComponent } from '@/utils/pagination.component';

import { ITimeEntryModel, TimeEntryModel, TimeEntryRateTypes, TimeEntryLaborTypes } from '@/models/timeEntry.models';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { IStaffTypeaheadModel, IStaffRate } from '@/models/staff.models';
import { IJobModel, LaborCodeModel } from '@/models/job.models';
import * as Utility from '@/models/utility.models';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { CustomerService } from '@/services/customer.service';
import { LocationService } from '@/services/location.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { Filter } from '@/services/filterList.service';
import { AppSettingsService } from '@/services/appSettings.service';

import { TimeEntrySortFunctions } from '@/utils/dataManipulationUtils';
import * as routerUtils from '@/utils/routerUtils';
import { handleError } from '@/utils/errors';
import { TimeEntryFilters } from './time-entry-filters';

export type SelectableTimeEntry = ITimeEntryModel & { selected?: boolean };
function extendEntry(entry: ITimeEntryModel): SelectableTimeEntry {
    let selectableEntry = entry as SelectableTimeEntry;
    selectableEntry.selected = false;
    return selectableEntry;
}

@Component({
  selector: 'job-time-entry',
  templateUrl: './jobTimeEntry.template.html',
  host: {'class': 'job-time-entry-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0,
        overflow: 'hidden'
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class JobTimeEntryComponent extends BaseSmartComponent implements OnInit {
  @ViewChild('newStaffSelector', {static: false})
  newStaffSelector: ElementRef;

  @ViewChild('date', {static: false})
  date: ElementRef;

  @ViewChild('editDate', {static: false})
  editDate: ElementRef;

  @ViewChild(PaginationComponent, {static: true}) pager: PaginationComponent;

  public filteredEntries: SelectableTimeEntry[];

  // todo refactor or rename so public fields aren't prefixed with _
  public _customerId: string;
  public _jobId: string;
  public newTimeEntries: any = [];
  public sortOptions: any;
  public filterOptions: Array<Object>;
  public showCreateEntry: boolean = true;
  public storedFilter: Filter[] = [];
  public selectedEntries: any = [];
  public entriesCopy: any = [];
  public selectAll: boolean = false;
  public hasLaborCodes: boolean = false;
  public laborCode: any = [];
  public submitted: boolean = false;
  public filters: any[] = [];
	public appSettings: Object;
  public validLaborDate: boolean = true;
  public validEditLaborDate: boolean = true;
  navigationSubscription;

	private _currentUser: any;
  private _location: string;
  private _staff: Array<IStaffTypeaheadModel> = [];
  private _workOrders: Observable<Array<IWorkOrderModel>>;
  private _rateTypes: Array<string> = Utility.enumToArray<string>(TimeEntryRateTypes);
  private _laborTypes: Array<string> = Utility.enumToArray<string>(TimeEntryLaborTypes);
  private yesterday: Date = new Date(moment().add(-1, 'days').format('YYYY-MM-DD'));
  private model: Subject<Array<ITimeEntryModel>> = new Subject<Array<ITimeEntryModel>>();
  private newTimeEntry: ITimeEntryModel = null;
  private editingTimeEntryId: string = null;
  private tempStaffName: string = null;
  private entries: ITimeEntryModel[];
  private formattedCodes: any = [];
  private customerJobs: any = [];
  private saved: Subject<any> = new Subject();
  private pageSettings: any = {};

  public get totalEntries(): number {
    if (this.filteredEntries) {
      return this.filteredEntries.length;
    }
    return 0;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private workOrderService: WorkOrderService,
    private locationService: LocationService,
    private customerService: CustomerService,
    private settingsService: AppSettingsService,
    private renderer: Renderer2,
    public dataManipulationService: DataManipulationService<ITimeEntryModel>,
    messageService: MessageService
  ) {
    super(messageService);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd && !!this._jobId && this._jobId !== routerUtils.getRouteParameter(this.route, 'jobId')) {
        this.ngOnInit();
      }
    });
    this.model.subscribe((entries)=>{
      if (this.hasLaborCodes) {
        setTimeout(() => {
          this.entries = entries.map(entry => {
            this.formattedCodes.forEach(code => {
              if (code._id === entry.laborCodeId) {
                entry.laborCodeText = code;
              }
            });
            return entry;
          });
          this.filterEntries();
        }, 0);
      } else {
        setTimeout(() => {
          this.entries = entries;
          this.filterEntries();
        }, 0);
      }
    })

		this.appSettings = this.settingsService.getSettings();
  }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this._currentUser.locationId;
    this.setFilterOptions();

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._workOrders = this.state.getWorkOrdersList();

    this.dataManipulationService.initialize({}, TimeEntrySortFunctions);
    this.dataManipulationService.setSort('timestamp', false);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getTimeEntriesList(), this.model));

    // defaults for new time entry
    this.newTimeEntries = [];
    this.addTimeEntry(false, this.yesterday);

    this.reloadTimeEntries(true);
    this.workOrderService.getWorkOrders(this._customerId, this._jobId);

    this.customerService.getCustomer(this._customerId)
      .then(customer => {
        let locationId = customer.location as string;
        this.locationService.getAllStaff(locationId)
          .then(staff => {
            this._staff = new Array<IStaffTypeaheadModel>();
            for (let staffItem of staff) {
              // only active staff members are available for time entries
              if (staffItem.isActive) {
                this._staff.push({
                  _id: staffItem._id,
                  name: staffItem.firstName + ' ' + staffItem.lastName,
                  rates: Object.assign(new Array<IStaffRate>(), staffItem.rates)
                });
              }
            }

            setTimeout(() => {
              if (this.newStaffSelector) {
                this.newStaffSelector.nativeElement.focus();
              }
            }, 500);
          })
          .catch(handleError);
      })
      .catch(handleError);

    this.jobService.getJobs(0, false, null, 'number', 1, 1, this.filters)
      .then(jobs => {
        jobs.forEach(job => {
          let option = { id: job._id, text: job.number + ' - ' + job.name};
          this.customerJobs.push(option);
        });
    })
    .catch(handleError);

    const code$ =
      from(this.jobService.getJob(this._customerId, this._jobId))
        .pipe(tap((job: IJobModel) => {
          this.hasLaborCodes = job.hasLaborCodes;
          let allCodes = [];
          if (this.hasLaborCodes) {
            allCodes =
              job.laborCodes.map((code: LaborCodeModel) => { return { id: code.id, text: code.id + ' - ' + code.name, _id:code._id}; });
          }
          this.formattedCodes = allCodes;
        }));

    this.saved.next('false');
    this.checkLaborDate();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    this.model.unsubscribe();
  }

  public checkLaborDate(): void {
    if ( Object.prototype.toString.call(this.newTimeEntry.date) === "[object Date]" ) {
      if ( !isNaN(this.newTimeEntry.date.getTime()) ) {
        setTimeout(() => {
          this.validLaborDate = true;
          this.renderer.removeClass(this.date.nativeElement, 'unmatched');
        }, 0);
      } else {
        setTimeout(() => {
          this.validLaborDate = false;
          this.renderer.addClass(this.date.nativeElement, 'unmatched');
        }, 0);
      }
    } else {
      setTimeout(() => {
        this.validLaborDate = false;
        this.renderer.addClass(this.date.nativeElement, 'unmatched');
      }, 0);
    }
  }

  public checkEditLaborDate(ev): void {
    console.log(ev)
    let editedDate = new Date(ev);
    if ( Object.prototype.toString.call(editedDate) === "[object Date]" ) {
      if ( !isNaN(editedDate.getTime()) ) {
        setTimeout(() => {
          this.validEditLaborDate = true;
          this.renderer.removeClass(this.editDate.nativeElement, 'unmatched');
        }, 0);
      } else {
        setTimeout(() => {
          this.validEditLaborDate = false;
          this.renderer.addClass(this.editDate.nativeElement, 'unmatched');
        }, 0);
      }
    } else {
      setTimeout(() => {
        this.validEditLaborDate = false;
        this.renderer.addClass(this.editDate.nativeElement, 'unmatched');
      }, 0);
    }
  }

  public addTimeEntry(setFocus, date): void {
    this.resetNewTimeEntry(date);
    if (this.newTimeEntries.length) {
      this.newTimeEntry.staff = this.newTimeEntries[0].staff;
      this.newTimeEntry.rateType = TimeEntryRateTypes['Regular'];
      this.setStaffRate(this.newTimeEntry);
      this.newTimeEntry.jobView = false;
      this.newTimeEntry.date = this.newTimeEntries[this.newTimeEntries.length -1].date;
    }

    this.newTimeEntries.push(this.newTimeEntry);
    // set focus on date field when adding a new entry
    if (setFocus) {
      setTimeout(() => {
        document.getElementById('laborDate' + (this.newTimeEntries.length - 1)).focus();
      }, 0);
    }
  }

  public removeEntry(entry): void {
    let indexPosition = this.newTimeEntries.indexOf(entry);
    this.newTimeEntries.splice(indexPosition, 1);
  }

  public toggleJobView(entry): void {
    entry.jobView = true;
  }

  public cancelOtherJob(entry): void {
    entry._jobId = this._jobId;
    entry.jobView = false;
  }

  public setOtherJob(e, entry): void {
    entry._jobId = e?.id;
  }

  public clearEntryFields(): void {
    this.newTimeEntries.forEach(entry => {
      entry.date = '';
      entry.rateType = 'Regular';
      entry.rate = 0;
      entry.laborType = '';
      entry.units = 0;
    });
    this.tempStaffName = null;
  }

  public filterEntries(filters: Filter[] = []): void {
    const filtered =
      this.entries
        .filter(TimeEntryFilters.and(filters))
        .sort((a: ITimeEntryModel, b: ITimeEntryModel) => {
          const aDate = a.date != null ? new Date(a.date).getTime() : 0;
          const bDate = b.date != null ? new Date(b.date).getTime() : 0;
          const dateDiff = bDate - aDate;
          // sort by date first
          if (dateDiff !== 0) {
            return dateDiff;
          }
          // sort all negative and positive together
          const aIsNeg = a.units < 0;
          const bIsNeg = b.units < 0;
          // if a is positive or b is negative, keep existing order. Otherwise, switch
          return (!aIsNeg || bIsNeg) ? -1 : 1;
        })
        .map(entry => extendEntry(entry));
    this.filteredEntries = filtered;
  }

  /**
   * Converts the filter object to an array of filter types that can be applied
   * @param filters
   */
  public updateFilter(filters: any): void {
    this.storedFilter = TimeEntryFilters.convertFilters(filters);
    this.pager.reset();
    this.filterEntries(this.storedFilter);
  }

  public clearFilters(): void {
    this.pager.reset();
    this.storedFilter = [];
    this.filterEntries(this.storedFilter);
  }

  public toggleCreateEntry(): void {
    this.showCreateEntry = !this.showCreateEntry;
  }

  // move entries
  public moveEntries(e): void {
    var moves = {
      date: e.postingDate,
      jobId: e._jobId || this._jobId,
      notes: e.note,
      entries: []
    };
    this.filteredEntries.forEach(entry => {
      if (entry.selected) {
        moves.entries.push(entry._id);
      }
    });
    this.jobService.moveTimeEntries(this._customerId, this._jobId, moves)
      .then((entries) => {
        this.reloadTimeEntries(false);
        this.filterEntries(this.storedFilter);
      });
  }

  // select/deselect all entries
  public toggleSelectAll(): void {
    this.selectAll = ! this.selectAll;
    this.filteredEntries.forEach(entry => {
      entry.selected = this.selectAll;
      this.updateSelected(entry);
    });
  }

  // track selected entries to disable/enable "move selected" element
  public updateSelected(entry): void {
    let indexPosition = this.selectedEntries.indexOf(entry);

    if (indexPosition === -1) {
      this.selectedEntries.push(entry);
    } else if(indexPosition > -1) {
      this.selectedEntries.splice(indexPosition, 1);
    }
    // make a copy to pass to move component to force ngChanges to fire
    this.entriesCopy = this.selectedEntries.map(x => Object.assign({}, x));
  }

  // labor Codes
  public setLaborCode(e, entry): void {
    if(e && e._id){
      entry.laborCodeId = e._id;
    } else {
      entry.laborCodeId = null;
    }
  }

  trackById(index, timeEntry): void {
    return timeEntry._id;
  }

  private reloadTimeEntries(setFocus): void {
    this.editingTimeEntryId = null;
    this.jobService.getTimeEntries(this._customerId, this._jobId)
      .then(() => {
        // set focus back to staff selector
        if (this.newStaffSelector && setFocus) {
          this.newStaffSelector.nativeElement.focus();
        }
      })
      .catch(handleError);
  }

  private cancel(event: Event): void {
    this.reloadTimeEntries(false);
    event.stopPropagation();
  }

  private editTimeEntry(timeEntry: ITimeEntryModel, event: Event): void {
    this.editingTimeEntryId = timeEntry._id;
    if (!timeEntry.workOrder) {
      timeEntry.workOrder = Utility.EmptyBackReference;
    }

    if (event) {
      event.stopPropagation();
    }
  }

  private saveNewEntries(): void {
    let staff = this.newTimeEntries[0].staff;
    let saveDate = this.newTimeEntries[this.newTimeEntries.length - 1].date;
    sessionStorage.setItem('laborDate', saveDate);
    this.newTimeEntries.forEach(entry => {
      entry.staff = staff;
      let newEntry = entry;
      let jobId = entry._jobId || this._jobId;
      delete newEntry._jobId;
      this.saveTimeEntry(newEntry, jobId);
    });

    this.newTimeEntries = [];
    this.tempStaffName = null;
    this.addTimeEntry(false, saveDate);
  }

  private saveTimeEntry(timeEntry, jobId: string): void {
    // jobId is passed in only when entry is being moved between jobs
    let assignedJob = jobId || this._jobId;

    if (!timeEntry.date || !timeEntry.staff || !timeEntry.rate || !timeEntry.rateType || !timeEntry.units || !timeEntry.laborType) {
      this.submitted = true;
      return;
    }

    delete timeEntry.jobView;
    delete timeEntry.laborCodeText;
    this.jobService.updateTimeEntry(this._customerId, assignedJob, timeEntry)
      .then(() => {
        this.reloadTimeEntries(true);

        if (timeEntry === this.newTimeEntry) {
          this.resetNewTimeEntry(this.newTimeEntry.date);
        }
        this.submitted = false;
      })
      .catch(handleError);
    this.saved.next('true');
    setTimeout(() => {
      this.renderer.removeClass(this.date.nativeElement, 'unmatched');
    }, 0);
  }

  private deleteTimeEntry(timeEntry: ITimeEntryModel, event: Event): void {
    if (this.warnOnDelete('time entry')) {
      this.jobService.deleteTimeEntry(this._customerId, this._jobId, timeEntry._id)
        .then(() => {
          this.reloadTimeEntries(false);
        });
    }
    event.stopPropagation();
  }

  private resetNewTimeEntry(date: Date): void {
    this.newTimeEntry = new TimeEntryModel();
    let savedLaborDate = sessionStorage.getItem('laborDate');
    if (savedLaborDate) {
      date = new Date(savedLaborDate);
    }

    this.newTimeEntry.date =  date;
    this.newTimeEntry.rateType = TimeEntryRateTypes['Regular'];
  }

  private setStaffRate(timeEntry: ITimeEntryModel): void {
    // this.tempStaffName = this._staff.name;
    if (!timeEntry.staff) { return; }

    let selectedStaffId = (typeof timeEntry.staff === 'object' ? timeEntry.staff['_id'] : timeEntry.staff);

    let staff = this._staff.find((staff) => { return staff._id === selectedStaffId; });
    if (staff) {
      let rate = staff.rates.find((rate) => { return rate.name === timeEntry.rateType; });
      if (rate) {
        timeEntry.rate = rate.rate;
      }
    }
  }

  private setFilterOptions(): void {
    this.filterOptions = [
      {
        input: 'date',
        label: 'Posting date range',
        size: '3',
        options: '',
        type: 'dateRange',
        startDate: '',
        endDate:  new Date().setHours(0,0,0,0)
      },
      {
        input: 'text',
        label: 'Staff member',
        size: '3',
        options: null,
        type: 'staff'
      },
      {
        input: 'select',
        label: 'Rate Type',
        size: '3',
        options: ['Regular', 'Overtime', 'Ride time', 'Double time'],
        type: 'rateType'
      },
      {
        input: 'select',
        label: 'Hours Type',
        size: '3',
        options: ['Field Labor', 'Shop Labor'],
        type: 'laborType'
      }
    ];
  }
}
