<div class="container mb-2">
  <job-search></job-search>
</div>

<div class="layout layout-sidebar">
  <div class="sidebar sidebar-wide">
    <router-outlet name="sidebar"></router-outlet>
  </div>
  <div class="sidebar-main sidebar-wide-main">
    <router-outlet></router-outlet>
  </div>
</div>
