<div id="list-container" class="container">
	<div class="row">
		<div class=" col-md-6 screen-title">
			Job Costs
		</div>

		<div class="col-md-6">
		</div>
	</div>
	<costs-list [model]="model"
		[dataManipulationService]="dataManipulationService"
		[salesTaxRates]="taxRates"
		[useTaxRates]="useTaxRates"
		[suppliers]="_suppliers | async"
		[showSuppliers]="true"
		[isTaxExempt]="_isTaxExempt"
		(onSave)="saveCost($event)" (onDelete)="deleteCost($event)" (onCancel)="cancel()" (onRemove)="removePO($event)" (onMove)="moveCosts($event)"
		[_customerId]="_customerId"
		[_jobId]="_jobId"
		[_purchaseOrdersFormatted]="_purchaseOrdersFormatted"
		[updatedCost]="updatedCost"
		>
	</costs-list>
</div>
