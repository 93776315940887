import { Component, Input, Output, EventEmitter, OnInit, forwardRef, ViewChild, ElementRef, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFocusable } from './../models/utility.models';

@Component({
    selector: 'search',
    template: `
    <form (submit)="search()" autocomplete="off">
        <div class="input-group input-entry" [ngClass]="{ 'actionable': showSearchButton }">
            <input id="searchField" type="text" class="form-control" [ngClass]="{ 'input-lg': large, 'active': model }" [placeholder]="placeholder" name="searchTerm" [(ngModel)]="model" #searchField [disabled]="_isLoading" />

            <span class="input-group-btn">
                <button type="button" class="btn btn-default" [ngClass]="{ 'input-lg': large }" [disabled]="!model || _isLoading" (click)="clear()">
                    <i class="glyphicon glyphicon-remove"></i>
                </button>
            </span>
        </div>

        <div class="input-action" *ngIf="showSearchButton">
            <button type="submit" class="btn btn-default" [ngClass]="{ 'btn-lg': large }" [disabled]="_isLoading">Search</button>
        </div>
    </form>
    `,
    host: {'class': 'search-component'},
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SearchComponent),
        multi: true
    }]
})
export class SearchComponent implements OnInit, IFocusable, OnChanges {
    public showSearchButton: boolean = false;
    // todo refactor or rename so public property isn't prefixed with _
    public _isLoading: boolean = false;

    @ViewChild('searchField', {static: true})
    private searchField: ElementRef;

    @Input()
    _model: string = '';

    @Input()
    large: boolean = false;

    @Input()
    isLoading: boolean = false;

    @Input()
    placeholder: string = 'Search';

    @Input()
    setFocus: boolean = true;

    @Output()
    onAction = new EventEmitter<null>();

    get model() {
        return this._model;
    }

    set model(newState : string) {
        this._model = newState;
        this.propogateChange(this._model);
    }

    propogateChange = (_: any) => {};

    registerOnChange(fn) : void {
        this.propogateChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) : void {
        this._model = value;
    }

    ngOnInit() : void {
        if(this.onAction.observers.length > 0) {
            this.showSearchButton = true;
        }
        setTimeout(() => {
            if(!this._isLoading && this.setFocus) {
                this.searchField.nativeElement.focus();
            }
        }, 0);
    }

    ngOnChanges(changes: any) {
        if(changes.isLoading){
            const isLoading: SimpleChange = changes.isLoading;
            this._isLoading = isLoading.currentValue || null;
            setTimeout(() => {
                if(!this._isLoading && this.setFocus) {
                    this.searchField.nativeElement.focus();
                }
            }, 0);
        }
    }

    public search() : void {
        this.onAction.emit();
    }

    public clear() : void {
        this.model = '';
        this.search();
    }

    focus(): void {
        if(this.searchField) {
            this.searchField.nativeElement.focus();
        }
    }
};
