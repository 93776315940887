import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilityModule } from '../../utils/utility.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ExportComponent } from './export.component';
import { ExportDocumentService } from '@/services/exportDocument.service';

@NgModule({
  declarations: [ ExportComponent ],
  imports: [
    CommonModule,
    UtilityModule,
    NgbModule
  ],
  providers: [
    ExportDocumentService
  ],
  exports: [ ExportComponent ]
})
export class ExportModule {}
