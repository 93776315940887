import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { IPMNoteModel, PMNoteModel } from '@/models/pmNote.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { PMNoteSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order-pm-notes',
  templateUrl: './workOrderPMNotes.template.html',
  host: {'class': 'work-order-pm-notes-component'},
  animations: [
   trigger('fadeInOut', [
     state('void', style({
         opacity: 0,
         height: 0
     })),
     transition('void <=> *', animate(150)),
   ]),
  ]
})
export class WorkOrderPMNotesComponent extends BaseSmartComponent implements OnInit {
  public model: Subject<Array<IPMNoteModel>> = new Subject<Array<IPMNoteModel>>();
  public newNote: IPMNoteModel = new PMNoteModel();

  private _customerId: string;
  private _jobId: string;
  private _workOrderId: string;
  private editingNoteId: string;
	private saved: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private workOrderService: WorkOrderService,
    private dataManipulationService: DataManipulationService<IPMNoteModel>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._workOrderId = routerUtils.getRouteParameter(this.route, 'workOrderId');

    this.dataManipulationService.initialize({}, PMNoteSortFunctions);
    this.dataManipulationService.setSort('title', false);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getPMNotesList(), this.model));
    this.reloadNotes();
    this.saved.next('false');
  }

  private reloadNotes(): void {
    this.editingNoteId = null;
    this.workOrderService.getPMNotes(this._customerId, this._jobId, this._workOrderId)
      .then(notes => {
          notes.forEach(note => {
            note.notes = note.note;
          });
        })
        .catch(handleError);
    this.saved.next('true');
  }

  private cancel(event: Event): void {
    this.reloadNotes();
    event.stopPropagation();
  }

  private editNote(note: IPMNoteModel, event: Event): void {
    this.editingNoteId = note._id;
    if (event) {
      event.stopPropagation();
    }
  }

  public saveNote(note: IPMNoteModel, isNew: boolean): void {
    if (!isNew) {
      note.note = note.notes;
    }
    this.workOrderService.updatePMNote(this._customerId, this._jobId, this._workOrderId, note)
      .then(() => {
        this.reloadNotes();
        if (note === this.newNote) {
          this.newNote = new PMNoteModel();
        }
      })
      .catch(handleError);
  }

  private deleteNote(note: IPMNoteModel, event: Event): void {
    if (this.warnOnDelete('PM note')) {
      this.workOrderService.deletePMNote(this._customerId, this._jobId, this._workOrderId, note._id)
        .then(() => this.reloadNotes())
        .catch(handleError);
    }
    event.stopPropagation();
  }

  private createPurchaseOrder(event: Event): void {
    event.stopPropagation();
  }
}
