<nav class="navbar navbar-default">
	<div class="">
		<!-- Brand and toggle get grouped for better mobile display -->
		<div class="navbar-header">
			<button type="button" (click)="toggleMenu()" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
				<span class="sr-only">Toggle navigation</span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
			</button>
		</div>
	
		<!-- Collect the nav links, forms, and other content for toggling -->
		<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" [ngClass]="{'menu-open':viewMenu}">
			<ul class="nav navbar-nav">
				<li>
					<a [routerLink]="['Customers']" [ngClass]="{'active':area == 'Customers'}" title="Customers"><i class="glyphicon glyphicon-user"></i><span>Customers</span></a>
				</li>
				<li>
					<a [routerLink]="['Jobs']" [ngClass]="{'active':area == 'Jobs'}" title="Jobs"><i class="glyphicon glyphicon-briefcase active"></i><span>Jobs</span></a>
				</li>
				<li>
					<a [routerLink]="['WorkOrders']" [ngClass]="{'active':area == 'WorkOrders'}" title="Work Orders"><i class="glyphicon glyphicon-inbox"></i><span>Work Orders</span></a>
				</li>
				<li>
					<a [routerLink]="['PurchaseOrders']" [ngClass]="{'active':area == 'PurchaseOrders'}" title="Purchase Orders"><i class="glyphicon glyphicon-barcode"></i><span>Purchase Orders</span></a>
				</li>
				<li>
					<a [routerLink]="['Invoices']" [ngClass]="{'active':area == 'Invoices'}" title="Receivables"><img src="../../../assets/img/receivables_icon.svg" alt="Receivables" style="position:relative; top:-5px;"/><span>Receivables</span></a>
				</li>
				<li>
					<a [routerLink]="['Payables']" [ngClass]="{'active':area == 'Payables'}" title="Payables"><img src="../../../assets/img/payables_icon.svg" alt="Payables" style="position:relative; top:-5px;"/><span>Payables</span></a>
				</li>
				<li *ngIf="showReports">
					<a [routerLink]="['Reports']" [ngClass]="{'active':area == 'Reports'}" title="Reports"><i class="glyphicon glyphicon-list-alt"></i><span>Reports</span></a>
				</li>
				<li>
					<a [routerLink]="['Admin', 'Locations']" [ngClass]="{'active':area == 'Locations'}" title="Locations"><i class="glyphicon glyphicon-map-marker"></i><span>Locations</span></a>
				</li>
				<li>
					<a [routerLink]="['Admin', 'Suppliers']" [ngClass]="{'active':area == 'Suppliers'}" title="Suppliers"><i class="glyphicon glyphicon-shopping-cart"></i><span>Suppliers</span></a>
				</li>
			</ul>
			<div class="account-container">
				<account-info></account-info>
			</div>
		</div><!-- /.navbar-collapse -->
	</div><!-- /.container-fluid -->
</nav>

