import { Observable, throwError } from 'rxjs';
import { MessageService } from './message.service';
import { IMessageModel } from '@/models/message.models';

interface ITransformFunction<T> {
    (source: T): any;
}

interface IErrorFunction {
    (error: any): void;
}

export abstract class ApiBaseService {
  protected baseUrl: string;

  constructor(protected messageService: MessageService) { }

  extract<T>(source: Observable<T>, transform?: ITransformFunction<T>, error?: IErrorFunction): Promise<T> {
    const self = this;

    if (transform) {
      error = error || self.defaultError;
      source.subscribe(transform, error);
    }

    // todo convert this into using the observables directly
    let promise = source.toPromise();
    promise.catch((err) => {
      if (err.status === 403) {
        self.notify('You are not authorized to perform this action', { type: 'error' });
      }
    });

    return promise;
  }

  notify(message: string, options: any = {}): void {
    this.messageService.send({ message: message, type: options.type } as IMessageModel);
  }

  private defaultError = (err: any) => {
    return throwError(() => new Error(err)); 
    // return Observable.throw(err);
  }
}
