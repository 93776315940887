<form class="pb-3" (ngSubmit)="saveStaff()" #staffForm="ngForm" autocomplete="off">
    <input type="hidden" value="{{ model._id }}" />

    <fieldset>
        <legend class="sr-only">
            User Information
        </legend>

        <div class="form-group">
            <label>First Name</label>
            <div class="">
                <input class="form-control" type="text" [(ngModel)]="model.firstName" name="firstName" required="required" #firstName="ngModel" />
                <div class="alert alert-danger" [hidden]="(!staffForm.form.submitted && firstName.pristine) || firstName.valid">
                    First name is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Last Name</label>
            <div class="">
                <input class="form-control" type="text" [(ngModel)]="model.lastName" name="lastName" required="required" #lastName="ngModel" />
                <div class="alert alert-danger" [hidden]="(!staffForm.form.submitted && lastName.pristine) || lastName.valid">
                    Last name is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Position</label>
            <div class="">
                <input class="form-control" type="text" [(ngModel)]="model.position" name="position" required="required" #position="ngModel" />
                <div class="alert alert-danger" [hidden]="(!staffForm.form.submitted && position.pristine) || position.valid">
                    Position is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Trade Level</label>
            <div>
                <select class="form-control" [(ngModel)]="model.tradeLevel" name="tradeLevel" #tradeLevel="ngModel">
                    <option value='Apprentice'>Apprentice</option>
                    <option value='Journeyman'>Journeyman</option>
                </select>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend>
            Rates
        </legend>

        <table class="table">
            <caption class="sr-only">Labor rates</caption>
            <thead>
                <tr>
                    <th scope="col" style="width:33%">
                        Type
                    </th>
                    <th scope="col" style="width:66%">
                        Rate
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rate of model.rates; let i = index">
                    <td scope="row">
                        {{ rate.name }}
                    </td>
                    <td scope="row">
                        <div class="input-group">
                            <span class="input-group-addon">$</span>
                            <input class="form-control" [name]="'rateRate' + i" [(ngModel)]="rate.rate" enforceDecimal placeholder="0.00" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </fieldset>

    <div>
        <button class="btn btn-primary mr-1" type="submit" [disabled]="!staffForm.form.valid">Save</button>
        <div class="inline-block pseudo-link small" (click)="cancel()">Cancel</div>
    </div>
</form>
