import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilityModule } from './../../utils/utility.module';

import { StaffAddComponent } from './staffAdd.component';
import { StaffEditComponent } from './staffEdit.component';
import { StaffEditFormComponent } from './staffEditForm.component';
import { StaffListComponent } from './staffList.component';

@NgModule({
    declarations: [
        StaffAddComponent,
        StaffEditComponent,
        StaffEditFormComponent,
        StaffListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UtilityModule
    ],
    exports: [
        StaffAddComponent,
        StaffEditComponent,
        StaffListComponent
    ]
})
export class StaffModule {}
