import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { JobService } from '@/services/job.service';
import { IInvoiceModel } from '@/models/invoice.models';

@Component({
    selector: 'invoice-documents',
    templateUrl: './invoiceDocuments.template.html',
    host: {'class': 'invoice-documents-component'}
})
export class InvoiceDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    private _customerId: string;
    private _jobId: string;
    // todo refactor or rename so public property isn't prefixed with _
    public _invoiceId: string;
    public duplicateRecord: boolean = false;
    public model = new Subject<IInvoiceModel>();
    private currentInvoice: any;
    private filesQueued: boolean = false;

    constructor(private route: ActivatedRoute, private jobService: JobService, messageService: MessageService, private filesService: FilesService, private state: StateManager) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
        self._invoiceId = routerUtils.getRouteParameter(self.route, 'invoiceId');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    public saveOrder(order): void {
        const self = this;
        self.filesService.saveFilesOrder('Invoice', self._invoiceId, order);
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }

    public setMessage(e) {
        this.filesQueued = e;
    }
}
