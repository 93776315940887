
<table class="table table-select-list" [ngClass]="{'with-indicator':showIndicator}" aria-describedby="receivablesLabel">
	<thead>
		<tr class="small">
			<th scope="col" class="list-item-date">
				Date
			</th>
			<th scope="col" class="list-item-number">
				Invoice #
			</th>
			<th scope="col" class="list-item-code">
				Order #
			</th>
			<th scope="col" class="currency-display">
				Amount
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let invoice of model" (click)="selectInvoice(invoice)" [ngClass]="{ 'active': invoice._id === selectedInvoiceId }">
			<td scope="row" class="list-item-date">
				{{ invoice.invoiceDate | dateFormat:'MMM DD, YYYY' }}
			</td>
			<td scope="row" class="list-item-number">
				{{ invoice.invoiceNumber }}
			</td>
			<td scope="row" class="list-item-code">
				{{ invoice.orderNumber }}
			</td>
			<td scope="row" class="currency-display">
				{{ invoice.amountThisInvoice | currencyFormat }}
			</td>
		</tr>
	</tbody>
</table>
