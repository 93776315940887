import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from '@/services/message.service';
import { CustomerService } from '@/services/customer.service';

import { IContactModel } from '@/models/contact.models';

@Component({
    selector: 'customer-contact-add',
    templateUrl: './customerContactAdd.template.html',
    host: {'class': 'customer-contact-add-component'}
})

export class CustomerContactAddComponent extends BaseSmartComponent implements OnInit {
    private _customerId : string;
    private _customerRoute : ActivatedRoute;
    public model : Observable<IContactModel>;
    public isNew = true;
    public showAlert = false;
    private _contactsList :Array<any>;
    private existingContact : String = "";

    constructor(private router: Router, private route: ActivatedRoute, private state : StateManager, messageService : MessageService, private customerService : CustomerService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.model = self.state.getActiveContact();
        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._customerRoute = routerUtils.getParentRoute(self.route);
        this.customerService.getContacts(this._customerId).then(contacts => { 
            this._contactsList = contacts; 
        });
    }

    public saveContact(newModel : IContactModel) : void {
        const self = this;

        let validContact = this.customerService.validateContact(this._contactsList, newModel);
        if(validContact==="valid"){
            self.customerService.updateContact(self._customerId, newModel)
            .then(() => {
                self.router.navigate(['.'], { relativeTo: self._customerRoute });
            })
            .catch(() => {});
            this.showAlert = false;
        } else {
            this.showAlert = true;
            this.existingContact = validContact;
        }
    }

    public cancel() : void {
        this.router.navigate(['.'], { relativeTo: this._customerRoute });
    }

    public goToContact(){
        this.router.navigate([this.existingContact], { relativeTo: this._customerRoute });
    }
}
