/**
 * Created by rrahlf on 4/10/2017.
 */
import { Component } from '@angular/core';
@Component({
	selector:'profile-component',
	templateUrl: './profile.template.html',
    host: {'class': 'profile-component'}
})

export class ProfileComponent {
	constructor() { }
};