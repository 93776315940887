import { Observable } from 'rxjs';
import * as Utility from './utility.models';
import { IAddress } from './address.models';

import { ICustomerModel, CustomerModel } from './customer.models';
import { IWorkOrderModel } from './workOrder.models';
import { IPurchaseOrderModel } from './purchaseOrder.models';
import { IChangeOrderModel } from './changeOrder.models';
import { IInvoiceModel } from './invoice.models';
import { ITimeEntryModel } from './timeEntry.models';
import { IPayableModel } from './payable.models';

export interface IJobAddress extends IAddress {
    county : string;
};

export interface IJobBids {
    shopLabor : string;
    fabLabor : string;
    fieldLabor : string;
    materialCosts : IMaterialsBid;
    otherCosts : IOtherBid;
};
interface IMaterialsBid {
    aluminum: number,
    glass: number,
    sealants: number,
    brakeMetal: number,
    other: number
  }
  interface IOtherBid {
    equipment: number,
    engineering: number,
    subcontractor: number,
    other: number
  }
interface IJobWarranty {
    substantialCompletionDate : Date;
    installerDate : Date;
    metalDate : Date;
    metalFinishDate : Date;
    glassIGUDate : Date;
    glassLaminatedDate : Date;
    glassSpandrelDate : Date;
    glassMonolithicDate : Date;
    notes : string;
    attachments : Array<Utility.IFileReference>;
};

export interface IJobChangeOrderSummaryModel {
    originalContract : number;
    changeOrderAmount : number;
};

// <editor-fold> Document models

export interface IJobSearchRequest extends Utility.ISearchRequest {
    includeClosed: boolean;
};

export interface IJobModel extends Utility.BaseModel {
    name : string;
    number : string;
    address : IJobAddress;
    contractAmount : number;
    bidAmounts : IJobBids;
    contractNumber : string;
    date : string|Date;
    startDate : string;
    isWhite : boolean;
    isClosed : boolean;
    isTaxExempt : boolean;
    notes : string;
    hasLaborCodes : boolean;
    laborCodes: Array<LaborCodeModel>;
    completedDate : string;
    projectManager : string | Utility.IBackReference;
    location: string;
    customer : string | Utility.IBackReference;
    customerContact : string;
    warranty : IJobWarranty;
    changeOrders : Array<IChangeOrderModel>;
    invoices : Array<IInvoiceModel>;
    payables : Array<IPayableModel>;
    timeEntries : Array<ITimeEntryModel>;
    documents : Object;
};

export class JobModel extends Utility.BaseModel implements IJobModel {
    name : string = null;
    number : string = null;
    address : IJobAddress = {
        address1 : null,
        address2 : null,
        city : null,
        state : null,
        zip: null,
        county : null,
        attn: null
    };
    contractAmount : number = null;
    bidAmounts : IJobBids = {
        shopLabor : null,
        fabLabor : null,
        fieldLabor : null,
        materialCosts : {
            aluminum : null,
            glass : null,
            sealants : null,
            brakeMetal : null,
            other : null
        },
        otherCosts : {
            equipment : null,
            engineering : null,
            subcontractor : null,
            other : null
        }
    };
    contractNumber : string = null;
    date : string = null;
    startDate : string = null;
    completedDate : string = null;
    isWhite : boolean = false;
    isClosed : boolean = false;
    isTaxExempt : boolean = false;
    hasLaborCodes : boolean = false;
    laborCodes: Array<LaborCodeModel> = new Array<LaborCodeModel>();
    notes : string = null;
    projectManager : Utility.IBackReference = Utility.EmptyBackReference;
    location : string = null;
    customer : Utility.IBackReference = Utility.EmptyBackReference;
    customerContact : string = null;
    changeOrders : Array<IChangeOrderModel> = new Array<IChangeOrderModel>();
    invoices : Array<IInvoiceModel> = new Array<IInvoiceModel>();
    payables: Array<IPayableModel> = new Array<IPayableModel>();
    timeEntries : Array<ITimeEntryModel> = new Array<ITimeEntryModel>();
    warranty : IJobWarranty = {
        substantialCompletionDate : null,
        installerDate : null,
        metalDate : null,
        metalFinishDate : null,
        glassIGUDate : null,
        glassLaminatedDate : null,
        glassSpandrelDate : null,
        glassMonolithicDate : null,
        notes : null,
        attachments : new Array<Utility.IFileReference>()
    };
    documents : Object;
};

// </editor-fold>

export class JobSummaryModel {
    job : IJobModel = new JobModel();
    customer : ICustomerModel = new CustomerModel();
    workOrders : Observable<Array<IWorkOrderModel>>;
    purchaseOrders : Observable<Array<IPurchaseOrderModel>>;
    contactName: string = null;
    projectManagerName: string = null;
};

// connecting a purchase order to a work order, from either side of the connection
export class JobOrderConnectionModel {
    workOrderId : string = null;
    purchaseOrderId : string = null;
};

export class JobInvoiceConnectionModel {
    invoiceId : string = null;
    purchaseOrderId : string = null;
};

export class JobPayableConnectionModel {
    payableId : string = null;
    purchaseOrderId : string = null;
};

export interface ILaborCodeModel extends Utility.BaseModel {
    _id : string ;
    id: string;
    name : string;
};

export class LaborCodeModel {
    _id : string = null;
    id: string = null;
    name : string = null;
};
