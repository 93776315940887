import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from '@/services/message.service';

@Component({
	selector: 'report-sidebar',
	template: `
    <header><h2>Reports</h2></header>

    <div class="report-group-header">Job List</div>
    <ul class="report-list">
        <li><a (click)="navigateToReport('JobList', 'all')" [ngClass]="{ 'active report-active': reportType === 'all' }">All-time</a></li>
    </ul>

    <div class="report-group-header">Journals</div>
    <ul class="report-list">
        <li><a (click)="navigateToReport('TimeEntry', 'TimeEntry')" [ngClass]="{ 'active report-active': reportType === 'TimeEntry' }">Daily Time Entry</a></li>
        <li><a (click)="navigateToReport('Cost', 'materials')" [ngClass]="{ 'active report-active': reportType === 'materials' }">Daily Material Costs</a></li>
        <li><a (click)="navigateToReport('Cost', 'outsideServices')" [ngClass]="{ 'active report-active': reportType === 'outsideServices' }">Daily Outside Services Costs</a></li>
    </ul>

    <div class="report-group-header">Other</div>
    <ul class="report-list">
        <li><a (click)="navigateToReport('JobOutcome', 'JobOutcome')" [ngClass]="{ 'active report-active': reportType === 'JobOutcome' }">Job Outcome</a></li>
        <li><a (click)="navigateToReport('MonthlyCosts', 'MonthlyCosts')" [ngClass]="{ 'active report-active': reportType === 'MonthlyCosts' }">Monthly Costs</a></li>
        <li><a (click)="navigateToReport('JobDetail', 'JobDetail')" [ngClass]="{ 'active report-active': reportType === 'JobDetail' }">Job Detail</a></li>
        <li><a (click)="navigateToReport('JobSummary', 'JobSummary')" [ngClass]="{ 'active report-active': reportType === 'JobSummary' }">Job Summary</a></li>
        <li><a (click)="navigateToReport('InvoiceSummary', 'InvoiceSummary')" [ngClass]="{ 'active report-active': reportType === 'InvoiceSummary' }">Receivables Summary</a></li>
    </ul>
    `,
    host: {'class': 'report-sidebar-component'}
})
export class ReportSidebarComponent extends BaseSmartComponent implements OnInit {
	public reportType: string;
	public reportUrl: String = 'Reports';

	constructor(
        private route: ActivatedRoute,
        private router: Router,
        messageService: MessageService
    ) {
		super(messageService);
	}

	ngOnInit(): void {
		const self = this;
        self.reportType = 'all';
	}

    // routerLink stopped working with Angular 16, so hacking together the full url and setting active tab manually.
    public navigateToReport(url: string, reportType: string): void {
        this.reportType = reportType;
        if(url==='TimeEntry' || url==='Cost'){
            if(url==='Cost'){
                this.router.navigate(['Reports', 'Journal', url], {queryParams: {reportType: reportType}} );
            } else {
                this.router.navigate(['Reports', 'Journal', url], );
            }
        } else if(url==='JobList') {
            this.router.navigate(['Reports', url], {queryParams: {reportType: reportType}} );
        } else {
            this.router.navigate(['Reports', url]);
        }
	}
};
