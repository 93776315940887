import { Injectable, Injector } from '@angular/core';
import { RouterHistoryService } from '@/services/router-history.service';

// used as an APP_INITIALIZER service based on https://stackoverflow.com/q/48450229
// initializes router-history so it catches all router events. Previously, it was losing the
// first navigation start event on the first page that used the history so the state wouldn't
// get used.
@Injectable()
export class StartupService {
  constructor(private injector: Injector) {}

  load(): Promise<boolean> {
    let history = this.injector.get(RouterHistoryService);
    return Promise.resolve(history !== null);
  }
}
