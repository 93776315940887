import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from './../base.component';
import * as routerUtils from '@/utils/routerUtils';
import { JobSortFunctions } from '@/utils/dataManipulationUtils';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { DataManipulationService } from '@/services/dataManipulation.service';

import { IJobModel, JobModel } from '@/models/job.models';


@Component({
    selector: 'customer-job-list',
    templateUrl: './customerJobList.template.html',
    host: {'class': 'customer-job-list-component'}
})

export class CustomerJobListComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    private _id : string;
    private _baseCustomerRoute : ActivatedRoute;

    public model = new Subject<Array<IJobModel>>();
    public showClosed : boolean = false;

    constructor(
    	private router: Router,
			private route: ActivatedRoute,
			private state : StateManager,
			private jobService : JobService,
			public dataManipulationService : DataManipulationService<IJobModel>,
			messageService : MessageService
		) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;
        self.dataManipulationService.initialize({}, JobSortFunctions);
        self.dataManipulationService.setSort('number', false);
        self.watchSubscription(self.dataManipulationService.connectDataSource(self.state.getJobsList(), self.model));

        self._id = routerUtils.getRouteParameter(self.route, 'customerId');

        // base customer route from here is the great-grandparent route
        self._baseCustomerRoute = routerUtils.getParentRoute(self.route, 3);

        self.reloadJobList();
    }

    override ngOnDestroy(): void {
        const self = this;

        self.state.setJobsList(new Array<IJobModel>());
    }

    addJob() : void {
        this.state.setActiveJob(new JobModel());
        this.router.navigate(['Add'], { relativeTo: this.route });
    }

    editJob(id : string) : void {
        this.router.navigate(['..', 'Job', id, 'Edit'], { relativeTo: this._baseCustomerRoute });
    }

    reloadJobList() : void {
        this.jobService.getCustomerJobs(this._id, this.showClosed);
    }

    filterToggle(filter:string){
        switch(filter){
            case 'showClosed':
                this.showClosed = !this.showClosed;
                break;
            default:
                break;
        }

        this.reloadJobList();
    }
}
