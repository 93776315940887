<div class="layout layout-sidebar narrow" [ngClass]="{'hidden-sidebar': hideSidebar}">
	<button type="button" (click)="toggleSidebar()" class="sidebar-toggler">
		<i *ngIf="hideSidebar" class="glyphicon glyphicon-triangle-right"></i>
		<i *ngIf="!hideSidebar" class="glyphicon glyphicon-triangle-left"></i>
	</button>
	<div class="sidebar sidebar-narrow">
		<router-outlet name="sidebar"></router-outlet>
	</div>
	<div class="sidebar-main sidebar-narrow-main">
		<router-outlet></router-outlet>
	</div>
</div>