import { InvoiceWorkflow, PayableWorkflow, PurchaseOrderWorkflow, Workflow, WorkflowState, WorkOrderWorkflow } from '@/models/workflow.models';

export interface Aggregate {
	label: string;
	count: number;

	// the display key
	key?: string;
}

export interface Summary {
	item: string;
	data: Aggregate[];

	// the label to display on widgets
	label?: string;

	// the route name for the item
	route?: string;
}

/**
 * Extends a summary with a route, keys, and when a value isn't defined in the summary but is
 * defined in the Workflow statuses, adds 0 for that value.
 */
export class SummaryDecorator {
	protected componentLabel: string;

	protected routeName: string;

	protected workflow: Workflow;

	constructor(componentLabel: string, routeName: string, workflow: Workflow) {
		this.componentLabel = componentLabel;
		this.routeName = routeName;
		this.workflow = workflow;
	}

	public extendSummary(summary: Summary): Summary {
		summary.label = this.componentLabel;
		summary.route = this.routeName;
		summary.data =
			this.workflow.states.map(state => {
				let agg = summary.data.find(this.findAggregate(state));
				if (!agg) {
					agg = { label: state.value, count: 0 };
				}
				agg.key = state.label;
				return agg;
			});
		return summary;
	}

	findAggregate(state: WorkflowState): (Aggregate) => boolean {
		return (d: Aggregate) => {
			return ((state.value === null && d.label === 'unassigned') || (state.value === d.label))
		};
	}
}

/**
 * Extends a summary with a route, keys, and when a value isn't defined in the summary. Jobs
 * don't have workflows so it has a separate decorator
 */
export class JobsSummaryDecorator {
	protected componentLabel: string;

	protected routeName: string;

	constructor(componentLabel: string, routeName: string) {
		this.componentLabel = componentLabel;
		this.routeName = routeName;
	}

	public extendSummary(summary: Summary): Summary {
		summary.label = this.componentLabel;
		summary.route = this.routeName;
		if (summary.data.length > 0) {
			summary.data = summary.data.map(d => { d.key = 'active'; return d; });
		} else {
			summary.data = [{
				label: 'active',
				count: 0,
				key: 'active jobs'
			}];
		}

		return summary;
	}
}

export class DashboardSummary {
	public jobsSummary: Summary = null;
	public purchaseOrderSummary: Summary = null;
	public workOrderSummary: Summary = null;
	public payablesSummary: Summary = null;
	public receivablesSummary: Summary = null;

	private readonly _jobsDecorator = new JobsSummaryDecorator('Jobs', 'Jobs');
	private readonly _purchaseOrderDecorator = new SummaryDecorator('Purchase Orders', 'PurchaseOrders', new PurchaseOrderWorkflow());
	private readonly _workOrderDecorator = new SummaryDecorator('Work Orders', 'WorkOrders', new WorkOrderWorkflow());
	private readonly _payablesDecorator = new SummaryDecorator('Payables', 'Payables', new PayableWorkflow());
	private readonly _receivablesDecorator = new SummaryDecorator('Receivables', 'Invoices', new InvoiceWorkflow());

	set(summaries: Summary[]): void {
		for (let summary of summaries) {
			if (summary.item === 'Jobs') {
				this.jobsSummary = this._jobsDecorator.extendSummary(summary);
			} else if (summary.item === 'PurchaseOrders') {
				this.purchaseOrderSummary = this._purchaseOrderDecorator.extendSummary(summary);
			} else if (summary.item === 'WorkOrders') {
				this.workOrderSummary = this._workOrderDecorator.extendSummary(summary);
			} else if (summary.item === 'Payables') {
				this.payablesSummary = this._payablesDecorator.extendSummary(summary);
			} else if (summary.item === 'Receivables') {
				this.receivablesSummary = this._receivablesDecorator.extendSummary(summary)
			}
		}
	}
}
