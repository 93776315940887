import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { EnvironmentService } from '@/services/EnvironmentService';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { IUserModel, UserModel } from '@/models/user.models';
import { handleError } from '@/utils/errors';
import { isConstructorDeclaration } from 'typescript';

@Component({
  selector: 'user-add',
  templateUrl: './userAdd.template.html',
  host: {'class': 'user-add-component'}
})
export class UserAddComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IUserModel>;

  public _locationId: string;
  private _parentRoute: ActivatedRoute;
  private userModel: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private state: StateManager,
    private env: EnvironmentService,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getLocationSelectedUser();
    this._locationId = routerUtils.getRouteParameter(this.route, 'locationId');
    this._parentRoute = routerUtils.getParentRoute(this.route, 1);
    let newUser = new UserModel();
    this.state.setLocationSelectedUser(newUser);
    this.getLocations();
  }

  override ngOnDestroy() {
    this.userModel.unsubscribe();
  }
 
  public saveUser(newModel: IUserModel): void {

    this.locationService.updateUser(this._locationId, newModel)
      .then(() => this.router.navigate(['.'], { relativeTo: this._parentRoute }))
      .catch(handleError);
  }

  public cancel(): void {
    this.router.navigate(['.'], { relativeTo: this._parentRoute });
  }

  private getLocations() : void {
    this.locationService.getLocations().then(locations => {
      this.userModel = this.model.subscribe((value) => {
        value.locations = [];
        locations.forEach(location => {
          let userLocation = {
            id:location._id,
            name: location.name,
            hasAccess: true,
            isDefault: (this._locationId === location._id) ? true : false,
            isActive: true
          }
          value.locations.push(userLocation);
        });
      });
    });
  }
}
