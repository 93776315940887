import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { IReportMonthlyCostsResult } from '@/models/report.models';
import { ReportService } from '@/services/report.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ReportMonthlyCostsSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'report-monthly-costs',
  templateUrl: './reportMonthlyCosts.template.html',
  host: {'class': 'report-monthly-costs-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
          opacity: 0,
          height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class ReportMonthlyCostsComponent extends BaseSmartComponent implements OnInit {
  public totals = {
    projectManagerCount: 0,
    costs: 0
  };
  public dirty: boolean = true;
  public model: Subject<Array<IReportMonthlyCostsResult>> = new Subject<Array<IReportMonthlyCostsResult>>();

  // the selected date
  private today = this.sanitizeDate();
  public year: number = this.today.getFullYear();
  public month: number = this.today.getMonth() + 1;
  //for setting max limits on inputs
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth() + 1;

  private _location: string;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    private dataManipulationService: DataManipulationService<IReportMonthlyCostsResult>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = currentUser.locationId;
    this.dataManipulationService.initialize({}, ReportMonthlyCostsSortFunctions);
  }

  public runReport(): void {
    let results = this.reportService.getMonthlyCostsReport(this.year, this.month, this._location);

    this.resetTotals();
    this.watchSubscription(this.dataManipulationService.connectDataSource(results, this.model));

    results.toPromise()
      .then(results => {
        for (let result of results) {
          this.totals.projectManagerCount += 1;
          this.totals.costs += result.costs;
            // sort within project manager
          result.jobs.sort((a, b) => {
            return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
          });
        }
        this.dirty = false;
      })
      .catch(handleError);
  }

  public markDirty(): void {
    this.dirty = true;
  }

  private resetTotals(): void {
    this.totals = {
      projectManagerCount: 0,
      costs: 0
    };
  }
  public setMaxMonth(e) {
    this.year = e;
    if(e===this.currentYear){
      this.month = this.currentMonth;
    }
  }
  public maxMonth(){
    let max = 12;
    if(this.year === this.currentYear){
      max = this.currentMonth;
    }
    return max;
  }
}
