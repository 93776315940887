import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { IReportJournalTimeEntryResult } from '@/models/report.models';
import { ReportService } from '@/services/report.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ReportJournalTimeEntrySortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'report-time-entry',
  templateUrl: './reportTimeEntry.template.html',
  host: {'class': 'report-time-entry-component'}
})
export class ReportTimeEntryComponent extends BaseSmartComponent implements OnInit {
  public date: Date = this.sanitizeDate();
  public includeClosed: boolean = false;
  public dirty: boolean = true;
  public model: Subject<Array<IReportJournalTimeEntryResult>> = new Subject<Array<IReportJournalTimeEntryResult>>();

  private entryCount: number = 0;
  private hoursTotal: number = 0;
  private costTotal: number = 0;
  private _location: string;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    public dataManipulationService: DataManipulationService<IReportJournalTimeEntryResult>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = currentUser.locationId;
    this.dataManipulationService.initialize({}, ReportJournalTimeEntrySortFunctions);
    this.dataManipulationService.setSort('timestamp', false);
  }

  public runReport(): void {
    let results = this.reportService.getTimeEntryReport(new Date(this.date), this.includeClosed, this._location);

    this.watchSubscription(this.dataManipulationService.connectDataSource(results, this.model));

    results.toPromise()
      .then((reportResults) => {
        this.dirty = false;
        this.getLaborCodeText(reportResults);
      })
      .catch(handleError);
  }

  public toggleIncludeClosed(): void {
    this.includeClosed = !this.includeClosed;
    this.markDirty();
  }

  public markDirty(): void {
    this.dirty = true;
  }

  private getLaborCodeText(entries): void {
    entries.forEach(entry => {
      if (entry.job.hasLaborCodes) {
        for (let i = 0; i < entry.job.laborCodes.length; i++) {
          if (entry.laborCodeId === entry.job.laborCodes[i]._id) {
            entry.laborCodeText = entry.job.laborCodes[i].id + ' - ' + entry.job.laborCodes[i].name;
          }
        }
      }
    });
  }
}
