import { Pipe, PipeTransform } from '@angular/core';
import { IActivateable } from './../models/utility.models';

@Pipe({
    name: 'active',
    pure: true
})
export class ActivePipe implements PipeTransform {
    transform(value: Array<IActivateable>, active: boolean = true) {
        let result = new Array<IActivateable>();

        if(value && value.length > 0) {
            for(let i = 0; i < value.length; i += 1) {
                if(value[i].isActive === active) {
                    result.push(value[i]);
                }
            }
        }

        return result;
    }
};
