import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { SupplierService } from '@/services/supplier.service';
import { IContactModel } from '@/models/contact.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'supplier-contact-add',
  templateUrl: './supplierContactAdd.template.html',
  host: {'class': 'supplier-contact-add-component'}
})
export class SupplierContactAddComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IContactModel>;
  private _supplierId: string;
  private _supplierRoute: ActivatedRoute;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private supplierService: SupplierService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveContact();
    this._supplierId = routerUtils.getRouteParameter(this.route, 'supplierId');
    this._supplierRoute = routerUtils.getParentRoute(this.route);
  }

  public saveContact(newModel: IContactModel): void {
    this.supplierService.updateContact(this._supplierId, newModel)
      .then(() => this.router.navigate(['.'], { relativeTo: this._supplierRoute }))
      .catch(handleError);
  }

  public cancel(): void {
    this.router.navigate(['.'], { relativeTo: this._supplierRoute });
  }
}
