import { ITimeEntryModel } from '@/models/timeEntry.models';
import { FilterListService } from '@/services/filterList.service';

type TimeEntryFilter = (cost: ITimeEntryModel) => boolean;

//           // time entries
//           if(this.filteredEntries.length > 0 && filter.type == 'staff' && filter.term) {
//               this.filteredEntries = this.filteredEntries.filter(entry => {
//                   if(entry.staff && entry.staff.name){
//                       let name = entry.staff.name.toLowerCase();
//                       return name.includes(filter.term.toLowerCase())
//                   }
//               });
//           }

/**
 * Builds a filter that accepts entries that match the rate type
 * @param type
 */
export function filterByRateType(type: string): TimeEntryFilter {
  return (timeEntry: ITimeEntryModel) => {
    return timeEntry.rateType === type;
  };
}

/**
 * Builds a filter that accepts entries that match the labor type
 * @param type
 */
export function filterByLaborType(type: string): TimeEntryFilter {
  return (timeEntry: ITimeEntryModel) => {
    return timeEntry.laborType === type;
  };
}

/**
 * Builds a filter that accepts entries that match the invoice number
 * @param invoiceNumber
 */
export function filterByStaff(staffName: string): TimeEntryFilter {
  const staffNameLower = staffName.toLowerCase();
  return (entry: ITimeEntryModel) => {
    // @ts-ignore
    return entry.staff && entry.staff.name && entry.staff.name.toLowerCase().includes(staffNameLower);
  };
}

/**
 * Builds a filter that accepts costs that match the supplier
 * @param supplier
 */
export function filterBySupplier(supplier: string): TimeEntryFilter {
  return (cost: ITimeEntryModel) => {
    // @ts-ignore
    return cost.supplier && (cost.supplier === supplier || cost.supplier._id === supplier);
  };
}

const TimeEntryFiltersMap: Map<string, (term: string) => TimeEntryFilter> = new Map<string, (term: string) => TimeEntryFilter>();
TimeEntryFiltersMap.set('rateType', filterByRateType);
TimeEntryFiltersMap.set('laborType', filterByLaborType);
TimeEntryFiltersMap.set('staff', filterByStaff);

export const TimeEntryFilters: FilterListService<ITimeEntryModel> = new FilterListService<ITimeEntryModel>(TimeEntryFiltersMap);
