import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ChangeOrderComponent } from './changeOrder.component';
import { ChangeOrderEditComponent } from './changeOrderEdit.component';
import { ChangeOrderListComponent } from './changeOrderList.component';
import { ChangeOrderDocumentsComponent } from './changeOrderDocuments.component';

import { UtilityModule } from '@/utils/utility.module';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';

@NgModule({
    declarations: [
        ChangeOrderComponent,
        ChangeOrderEditComponent,
        ChangeOrderListComponent,
        ChangeOrderDocumentsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UtilityModule,
        DocumentUploadModule,
        RouterModule
    ],
    exports: [
        ChangeOrderComponent,
        ChangeOrderEditComponent,
        ChangeOrderListComponent,
        ChangeOrderDocumentsComponent
    ]
})
export class ChangeOrderModule {}
