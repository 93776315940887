import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IPayableModel } from './../../models/payable.models';

@Component({
    selector: 'payable-list',
    templateUrl: './payableList.template.html',
    host: {'class': 'payable-list-component', id: 'sidebar-content'}
})

export class PayableListComponent {
    @Input()
    model = new Array<IPayableModel>();

    @Input()
    selectedPayableId : string = null;

    @Input()
	showIndicator: boolean = false;

    @Output()
    onPayableSelect = new EventEmitter<IPayableModel>();

    selectPayable(payable: IPayableModel) {
        this.onPayableSelect.emit(payable);
    }
}
