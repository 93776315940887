import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ToggleViewService {
    private viewObject = {filter: false, move: false}
    private viewSource = new BehaviorSubject<Object>(this.viewObject);
    viewState = this.viewSource.asObservable();
    updateView(filter, move) {

        this.viewObject.filter = filter;
        this.viewObject.move = move;

        this.viewSource.next(this.viewObject);
    }
};
