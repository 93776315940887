import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { SupplierSortFunctions } from '@/utils/dataManipulationUtils';
import { BaseSmartComponent } from './../base.component';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { UUIDService } from '@/services/uuid.service';
import { SupplierService } from '@/services/supplier.service';
import { DataManipulationService } from '@/services/dataManipulation.service';

import { ISupplierModel } from '@/models/supplier.models';
import { IPayableModel, PayableModel } from '@/models/payable.models';
import { WorkflowStatus } from '@/models/workflow.models';
@Component({
	selector: 'job-payable-add',
	templateUrl: './jobPayableAdd.template.html',
	host: {'class': 'job-payable-add-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})

export class JobPayableAddComponent extends BaseSmartComponent implements OnInit {
	private _customerId: string;
	private _jobId: string;
	public model: Observable<IPayableModel>;
	public uuid: string;
	state$: Observable<object>;
	public suppliers = new Subject<Array<ISupplierModel>>();
	private newLifecycle: Array<WorkflowStatus> = [];
	public isNew: boolean = true;
	public showAlert: boolean = false;
	private duplicatePayable: IPayableModel;

	// todo validate this type is correct
	public suppliersCopy: Array<ISupplierModel>;

	constructor(
		private router: Router, 
		private route: ActivatedRoute, 
		private state: StateManager, 
		private jobService: JobService, 
		private uuidService: UUIDService,
		private supplierService: SupplierService,
		private supplierManager: DataManipulationService<ISupplierModel>,
		messageService: MessageService
		) {
		super(messageService);
	}

	ngOnInit(): void {
		const self = this;
		self.model = self.state.getActivePayable();

		self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
		self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
		self.uuid = self.uuidService.generateUUID();

		self.state$ = this.route.paramMap.pipe(map(() => window.history.state));

		let newPayable = new PayableModel();
		newPayable.supplier = { _id: null, name: null, status: null, type: null, category: null, location: null};

		self.state.setActivePayable(newPayable);

		self.supplierManager.initialize({}, SupplierSortFunctions);
		self.watchSubscription(self.supplierManager.connectDataSource(self.state.getSuppliersList(), self.suppliers));
		self.supplierManager.setSort('name', false);
		//@ts-ignore
		self.supplierService.getSuppliers(0).then(data =>{this.suppliersCopy = JSON.parse(JSON.stringify( data ));});
	}

	public cancel(): void {
		this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
	}

	public savePayable(newModel: IPayableModel): void {
		const self = this;
		// remove the docs for the save and then add them back in after so view is updated
		let docs = newModel.documents;
		delete newModel.documents;

		newModel._id = self.uuid;

		// set initial status for new Payables
		if(!newModel.lifecycle || !newModel.lifecycle.length) {
			newModel.lifecycle = this.newLifecycle;
			newModel.lifecycle.push({
				status: 'pending_review',
				note: null,
				timestamp: new Date().toISOString()
			});
		}

		self.jobService.createPayable(self._customerId, self._jobId, newModel)
			.then((result) => {
				this.showAlert = false;
				self.jobService.getPayables(self._customerId, self._jobId);
                setTimeout(() => {
                    if(docs) {
                        newModel.documents = docs;
                    }
                }, 0);
				self.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(self.route, 1) });
			})
			.catch((err) => {
				this.showAlert = true;
				let error = err.error;
				this.duplicatePayable = error.record;
			});
	}

	public goToPayable() {
		this.router.navigate([this.duplicatePayable._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route) });
	}

};
