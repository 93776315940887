<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title">
    Daily Time Entry Journal
</div>

<div class="row print-hide report-controls">
    <div class="col-md-9">
        <input autocomplete="off" class="form-control mr-1" type="date" [ngModel]="date | dateFormat" (ngModelChange)="date = sanitizeDate($event); markDirty()" required="required" #reportDate="ngModel" style="display: inline-block;" initialFocus />
        <button class="btn btn-default mt-0" [ngClass]="{ 'active btn-primary': includeClosed }" (click)="toggleIncludeClosed()"><i class="glyphicon glyphicon-lock"></i> Include closed jobs</button>
    </div>
    <div class="col-md-3 actions">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty && reportDate">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <table>
        <caption>
            Daily time entries for {{ date | dateFormat:'MMM DD, YYYY' }}
        </caption>
        <thead class="spacer"><th scope="col"></th></thead>
    </table>

    <time-entry-list [model]="model" [dataManipulationService]="dataManipulationService"></time-entry-list>
</div>
