import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { WorkOrderSortFunctions, WorkOrderFilterFunctions } from '@/utils/dataManipulationUtils';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { JobService } from '@/services/job.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'purchase-order-connection',
  templateUrl: './purchaseOrderConnection.template.html',
  host: {'class': 'purchase-order-connection-component'}
})
export class PurchaseOrderConnectionComponent extends BaseSmartComponent implements OnInit {
  // todo refactor or rename so public property isn't prefixed with _
  public _purchaseOrderId: string;
  public attachedWorkOrder: IWorkOrderModel = null;

  private _customerId: string;
  private _jobId: string;
  private model: Observable<IPurchaseOrderModel>;
  private filterTerm: string = null;
  private workOrders: Subject<Array<IWorkOrderModel>> = new Subject<Array<IWorkOrderModel>>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private workOrderService: WorkOrderService,
    private dataManipulationService: DataManipulationService<IWorkOrderModel>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActivePurchaseOrder();

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');

    this.watchSubscription(this.model.subscribe((s) => {
      if (s && s.workOrder) {
        this.loadAttachedWorkOrder(s.workOrder as string);
      } else {
        this.attachedWorkOrder = null;
      }
    }));

    this.dataManipulationService.initialize(WorkOrderFilterFunctions, WorkOrderSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getWorkOrdersList(), this.workOrders));
    this.workOrderService.getWorkOrders(this._customerId, this._jobId);
  }

  private attachToWorkOrder(workOrder: IWorkOrderModel, event: Event): void {
    this.jobService.connectOrders(this._customerId, this._jobId, workOrder._id, this._purchaseOrderId)
      .then(() => this.loadAttachedWorkOrder(workOrder._id))
      .catch(handleError);
    event.stopPropagation();
  }

  private detachFromWorkOrder(event: Event): void {
    this.jobService.disconnectOrders(this._customerId, this._jobId, this.attachedWorkOrder._id, this._purchaseOrderId)
        .then(() => this.attachedWorkOrder = null)
        .catch(handleError);
    event.stopPropagation();
  }

  private goToWorkOrder(workOrderId: string): void {
    this.router.navigate(
      [ 'WorkOrders', workOrderId ],
      { relativeTo: routerUtils.getParentRoute(this.route, 3) }
      );
  }

  private loadAttachedWorkOrder(workOrderId: string): void {
    this.workOrderService.getWorkOrder(this._customerId, this._jobId, workOrderId, true)
      .then((workOrder) => this.attachedWorkOrder = workOrder)
      .catch(handleError);
  }
}
