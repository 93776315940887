<table class="table table-select-list" [ngClass]="{'with-indicator':showIndicator}" aria-describedby="purchaseOrdersLabel">
	<thead>
		<tr class="small">
			<th scope="col" class="list-item-date">
				Date
			</th>
			<th scope="col" class="list-item-number">
				Number
			</th>
			<th scope="col" class="list-item-name">
				Title
            </th>
			<th colspan="2" scope="col" class="list-item-supplierName" style="padding-left:24px;">
				Supplier
			</th>
		</tr>
    </thead>
    <tbody>
        <tr *ngFor="let purchaseOrder of model" (click)="selectPurchaseOrder(purchaseOrder)" [ngClass]="{ 'active' : purchaseOrder._id === selectedPurchaseOrderId }">
            <td scope="row" class="list-item-date">
                {{ purchaseOrder.date | dateFormat:'MMM DD, YYYY' }}
            </td>
            <td scope="row" class="list-item-number">
                {{ purchaseOrder.number }}
            </td>
            <td scope="row" class="list-item-name">
                {{ purchaseOrder.name }}
            </td>
            <td scope="row" class="supplier-status-container">
                <span *ngIf="purchaseOrder.supplier?.status" class="supplier-status-icon {{purchaseOrder.supplier?.status}}" title="{{purchaseOrder.supplier?.status}}"></span>
            </td>
            <td scope="row" class="list-item-supplierName">
                {{ purchaseOrder.supplier?.name }}
            </td>
        </tr>
    </tbody>
</table>
