import { Directive, ElementRef, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as dataUtils from './dataUtils';

@Directive({
    selector: 'input[enforceDecimal][ngModel]'
})

export class EnforceDecimalDirective implements OnInit, OnDestroy {
    private _subscription : Subscription;
    private _isFocused : boolean;
    private _element : HTMLInputElement;

    constructor(element : ElementRef, private _model: NgModel) {
        this._element = element.nativeElement;
    }

    ngOnInit() : void {
        const self = this;

        if(!self._model) { return; }

        self._subscription = self._model.control.valueChanges.subscribe((s) => {
            if(!self._isFocused) {
                // only update format directly if not typing in the field
                self.updateFormat(s);
            }
            else {
                // model stores numeric value, not string
                let numericValue = this.sanitizeValue(s);

                if(this.nullOnZero && (numericValue === 0)) {
                    self._model.viewToModelUpdate(null);
                }
                else {
                    self._model.viewToModelUpdate(numericValue);
                }
            }
        })
    }

    ngOnDestroy() : void {
        if(this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    @HostListener('focus')
    onFocus() : void {
        this._isFocused = true;
    }

    @HostListener('blur')
    onBlur() : void {
        this._isFocused = false;
    }

    @HostListener('change')
    onChange() : void {
		this.updateFormat(this._element.value);
    }

    @Input('enforceDecimal')
    nullOnZero:boolean = false;

    @Input('zeroOnNull')
	zeroOnNull:boolean = true;

    @Input('figureCount')
	figureCount:number = 2;

    @Input('comma')
	comma:boolean = false;

    private updateFormat(value : number | string) : void {
        let numericValue = this.sanitizeValue(value);

        if(this.nullOnZero && (numericValue === 0)) {
            this._element.value = null;
            this._model.viewToModelUpdate(null);
        }
        else {
        	if(numericValue !== null && numericValue !== 0) {
				//this._element.value = dataUtils.formatCurrency(numericValue).replace('$', '');  // remove currency symbol from field entry
				this._element.value = dataUtils.formatDecimal(numericValue, this.figureCount, this.comma);
			}else{
        		this._element.value = null;
			}
            this._model.viewToModelUpdate(numericValue);    // model stores numeric value, not string
        }
    }

    private sanitizeValue(value : number | string) : number {
        // remove anything that's not a decimal, number, or negation first
        if(typeof value === 'string') {
            value = value.replace(/[^0-9\.\-]/g, '');
        }


		let numericValue:number = null;
		if(value === '' || value === null){
			if(this.zeroOnNull){
				numericValue = 0;
			}
		}else{
			numericValue = Number(value);
			if(isNaN(numericValue)) {
				numericValue = 0;
			}
		}

		return numericValue;

    }
};
