import packageJson from '../../package.json';

export const environment = {
	SENTRY_ENVIRONMENT: '#{env}#',
  DSN_KEY: 'https://859c18de0bfe4a5281bde55d67b50385@sentry.io/1470893',
  MADISON_ID: 'a8f5999e-85dd-46e5-89d6-cc52f03afc27',
  PEWAUKEE_ID: 'd2e6b65d-3ece-4ef5-9993-b74144c58d09',
	PACKAGE_NAME: packageJson.name,
	PACKAGE_VERSION: packageJson.version,
	//the following are stored and read from Azure DevOps Pipeline Variables
  APIBASEURL: '#{apibaseurl}#',
	APPBASEURL: '#{appbaseurl}#',
  AZURE_CLIENT_ID: '#{AZURE_CLIENT_ID}#',
  AZURE_AUTHORITY:'#{AZURE_AUTHORITY}#'
};