import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilityModule } from './../../utils/utility.module';

import { UserAddComponent } from './userAdd.component';
import { UserEditComponent } from './userEdit.component';
import { UserEditFormComponent } from './userEditForm.component';
import { UserListComponent } from './userList.component';

@NgModule({
    declarations: [
        UserAddComponent,
        UserEditComponent,
        UserEditFormComponent,
        UserListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UtilityModule
    ],
    exports: [
        UserAddComponent,
        UserEditComponent,
        UserListComponent
    ]
})
export class UserModule {}
