import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseSmartComponent } from './../base.component';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { DataManipulationService } from '@/services/dataManipulation.service';

import { IJobModel, ILaborCodeModel, LaborCodeModel } from '@/models/job.models';
interface ISelectedJob {
    id : string;
    name : string;
}

@Component({
  selector: 'labor-codes',
  templateUrl: './laborCodes.template.html',
  host: {'class': 'labor-codes-component'},
  animations: [
      trigger('fadeInOut', [
          state('void', style({
              opacity: 0,
              height:0,
              overflow:'hidden'
          })),
            transition('void <=> *', animate(300)),
        ]),
  ]
})
export class LaborCodesComponent extends BaseSmartComponent implements OnInit {

    private _customerId: string;
    private _jobId: string;

    private model = new Subject<Array<ILaborCodeModel>>();
    public newEntries: any = [];
    private newEntry: ILaborCodeModel = null;
    private editingEntryId: string = null;

    public pastEntries: any = [];
    public sortDirection: boolean = false;
    public showCreateEntry: boolean = true;
    public selectedEntries: any = [];
    public entriesCopy: any = [];
    public selectAll: boolean = false;
    public showCopy: boolean = false;
    public formattedJobs: Array<Object> = [];
    public selectedJobs: Array<Object> = [];
    public jobList: number = 0;
    private job: IJobModel;
    public showError: boolean = false;
    public errorMsg: string = '';

    constructor(private router: Router, private route: ActivatedRoute, private state: StateManager, private jobService: JobService, private dataManipulationService: DataManipulationService<ILaborCodeModel>, messageService: MessageService) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;

        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
        
        // defaults for new entry
        self.reloadEntries(true);
        self.addEntry();
        self.getJobs();
    }

    private reloadEntries(doSort): void {
        this.editingEntryId = null;
        this.pastEntries = [];
        this.jobService.getJob(this._customerId, this._jobId)
        .then((job) => {
            this.job = job;
            if(!job.laborCodes) {
                this.job.laborCodes = [];
            }
            job.laborCodes.forEach(code => {
            this.pastEntries.push(code);
            });
            if(doSort){
                this.sortEntries('id');
            }
        });

    }

    // using this local sort rather than dataManipulationService because title is not a property of the labor code model
    // files needed to use the data service instead have been updated if this needs to be moved in the future
    public sortEntries(value: string) {
        this.sortDirection = !this.sortDirection;
        if(this.sortDirection) {
            if(value=='id') {
                this.pastEntries.sort((a, b) => 0 - (a.id > b.id ? -1 : 1));
            } else if (value == 'name') {
                this.pastEntries.sort((a, b) => 0 - (a.name > b.name ? -1 : 1));
            } else if (value == 'title') {
                this.pastEntries.sort((a, b) => 0 - (a.title > b.title ? -1 : 1));
            }
        } else {
            if(value=='id') {
                this.pastEntries.sort((a, b) => 0 - (a.id > b.id ? 1 : -1));
            } else if (value == 'name') {
                this.pastEntries.sort((a, b) => 0 - (a.name > b.name ? 1 : -1));
            } else if (value == 'title') {
                this.pastEntries.sort((a, b) => 0 - (a.title > b.title ? 1 : -1));
            }
        }
    }

    private cancel(event: Event): void {
        this.reloadEntries(false);
        event.stopPropagation();
    }

    private editEntry(entry: ILaborCodeModel, event: Event): void {
        this.editingEntryId = entry._id;

        if (event) {
            event.stopPropagation();
        }
    }
    private saveNewEntries() {
        const self = this;
        let valid=true;
        self.showError = false;
        self.newEntries.forEach(entry => {
            if (!entry.id || !entry.name) {
                self.errorMsg = 'All Code ID and Code Name fields are required.';
                valid = false;
                return
            } else {
                self.pastEntries.forEach(code => {
                    if(entry.id === code.id) {
                        self.errorMsg = 'Code ID must be unique to this job.';
                        valid = false;
                        return
                    } 
                });
                if(valid) {
                    delete entry.title;
                    self.job.laborCodes.push(entry);
                }
            }
        });
        if(valid) {
            self.saveJob();
            self.newEntries = [];
            self.addEntry();
            self.showError = false;
            self.errorMsg = '';
        } else {
            self.showError = true;
        }
    }

    private saveEntry(entry) {
        const self = this;
        if (!entry.id || !entry.name) {
            return;
        }
        delete entry.title;
        this.saveJob();
    }
    private saveJob() {
        const self = this;
        self.job.laborCodes.forEach(code => {
            delete code['selected'];
        });

        self.jobService.updateJob('customerId', self.job, false)
			.then(() => {
                self.selectedEntries = [];
                self.selectAll = false;
                self.reloadEntries(false);
			})
			.catch(() => { });
    }
    public addEntry() {
        let newEntry = new LaborCodeModel()
        this.newEntries.push(newEntry);
    }
    public removeEntry(entry) {
        let indexPosition = this.newEntries.indexOf(entry);
        this.newEntries.splice(indexPosition, 1);
    }
    public clearEntryFields() {
        this.newEntries.forEach(entry => {
            entry.id = '';
            entry.name = '';
        });
    }
    public toggleCreateEntry() {
        this.showCreateEntry = !this.showCreateEntry;
    }

    // move entries
    public copyEntries(e) {
        const self = this;
        let copied = [];
        self.selectedJobs.forEach((job : ISelectedJob) => {
            self.jobService.getJob('customerId', job.id, false)
            .then((jobObject) => {
                self.pastEntries.forEach(entry => {
                    if(entry.selected) {
                        let copiedEntry = Object.assign({}, entry);
                        delete copiedEntry['selected'];
                        jobObject.laborCodes.push(copiedEntry);
                        entry.selected = false;
                    }
                });
                self.jobService.updateJob('customerId', jobObject, false);
                self.selectedJobs = [];
                self.selectedEntries = [];
                self.selectAll = false;

            });
        });
        this.toggleCopyView();
    }

    public cancelMove() {
        this.showCopy = false;
        this.selectedJobs = [];
    }

    // select/deselect all entries
    public toggleSelectAll() {
        this.selectAll = ! this.selectAll;
        this.pastEntries.forEach(entry => {
            entry.selected = this.selectAll;
            this.updateSelected(entry)
        });
    }

    // track selected entries to disable/enable "move selected" element
    public updateSelected(entry) {
        let indexPosition = this.selectedEntries.indexOf(entry);

        if(indexPosition === -1) {
            this.selectedEntries.push(entry);
        }
        else if(indexPosition > -1) {
            this.selectedEntries.splice(indexPosition, 1);
        }
        // make a copy to pass to move component to force ngChanges to fire
        this.entriesCopy = this.selectedEntries.map(x => Object.assign({}, x));
        if(!this.selectedEntries.length) {
            this.cancelMove();
        }
    }

    public toggleCopyView() {
        this.showCopy = !this.showCopy;
    }

    private getJobs() {
        this.jobService.getJobs(0, true, null, 'number', 1, 1).then(jobs => {
            jobs.forEach(job => {
                // remove the job currently being viewed from the list of jobs to move to and format for dropdown
                let status = '';
                if(job.isClosed) {status = ' (closed)'};
                if(job._id != this._jobId) {
                    let option = {id:job._id, text:job.number + ' - ' + job.name + status}
                    this.formattedJobs.push(option);
                }
            });
        });
    }

    public setJob(e){
        let indexPosition = this.selectedJobs.indexOf(e);

        if(indexPosition === -1) {
            this.selectedJobs.push(e);
        }
        else if(indexPosition > -1) {
            this.selectedJobs.splice(indexPosition, 1);
        }
    }

    public noSpaces(e) {
        if (e.which == 32)
            return false;
    }
    trackById(index, entry) {
        return entry._id;
    }
    // deleting labor codes is not implemented yet
    // private deleteEntry(entry: ILaborCodeModel, event: Event): void {
    //     const self = this;

    //     if (self.warnOnDelete('labor code')) {
    //         console.log('delete entry');
    //         self.jobService.deleteLaborCode(entry._id)
	// 		.then(() => {
	// 			self.reloadEntries();
	// 		})
	// 		.catch(() => { });
    //     }

    //     event.stopPropagation();
    // }
    // private onScroll() {
    //     console.log('scrolled!!');
    //     this.reloadEntries(false);
    // }

};
