<div id="loading" class="data-loading" *ngIf="!model" [@fadeInOut]>Loading...</div>

<form class="" #payableForm="ngForm" (ngSubmit)="savePayable()" *ngIf="model" autocomplete="off" [@fadeInOut]
    (keydown.enter)="$event.target.id != 'addNewCategory'">
    <div *ngIf="!isNew" [@fadeInOut]>
        <div class="row">
            <div class="col-xs-12">
                <div class="screen-title">Invoice #{{ model._id ? model.invoiceNumber : 'Automatic' }}</div>
            </div>
        </div>
        <workflow [item]="model" [type]="4" [role]="role" [restricted]="true" (onUpdate)="setPostedDate($event)">
        </workflow>
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-6">
            <fieldset>
                <legend>General Information</legend>
                <div class="form-group">
                    <div>
                        <label>Assigned to</label><span *ngIf="!permissionLock" class="pseudo-link small pull-right"
                            (click)="resetPM()" style="text-transform: none;">reset</span>
                        <select class="form-control" [(ngModel)]="model.projectManager" name="projectManager"
                            #userField="ngModel" tabindex="1" required [disabled]="permissionLock">
                            <option *ngFor="let user of usersFormatted" [value]="user._id">
                                {{ user.name }}
                            </option>
                        </select>
                        <div class="alert alert-danger"
                            [hidden]="(!payableForm.form.submitted && userField.pristine) || (model.projectManager)">
                            Valid Project Manager is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                        <label>Supplier</label>
                        <input class="form-control typeahead supplier-status {{model.supplier.status}}"
                            [ngClass]="{'ng-invalid': invalidSupplier}" [typeahead]="suppliers"
                            [typeaheadOptionField]="'name'" [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="15" [(ngModel)]="tempSupplierName"
                            [disabled]="(!suppliers || (suppliers.length < 1)) || permissionLock"
                            (typeaheadOnSelect)="selectSupplier($event)" #supplierField="ngModel"
                            name="supplierNameTypeIn" tabindex="2" autocomplete="off" required />
                        <div class="alert alert-danger"
                            [hidden]="(!payableForm.form.submitted && supplierField.pristine) || (model.supplier)">
                            Valid supplier is required
                        </div>
                        <div class="alert alert-danger" *ngIf="invalidSupplier">
                            Invalid supplier
                        </div>
                    </div>
                    <div *ngIf="!permissionLock" class="">
                        <div (click)="openModal()" class="add-on mt-1">
                            <div class="add-icon">+</div><span>Add a new supplier</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Supplier invoice number</label>
                    <input class="form-control" type="text" [(ngModel)]="model.invoiceNumber" name="invoiceNumber"
                        #invoiceNumber="ngModel" required="required" tabindex="10" [disabled]="permissionLock" />
                    <div class="alert alert-danger"
                        [hidden]="(!payableForm.form.submitted && invoiceNumber.pristine) || invoiceNumber.valid">
                        Invoice number is required
                    </div>
                </div>
                <div class="form-group">
                    <label>Date of receipt</label>
                    <input class="form-control width-auto" type="date" [ngModel]="model.invoiceDate | dateFormat"
                        (ngModelChange)="model.invoiceDate = sanitizeDate($event)" name="invoiceDate"
                        required="required" [disabled]="permissionLock" #invoiceDate="ngModel" tabindex="15" />
                    <div class="alert alert-danger"
                        [hidden]="(!payableForm.form.submitted && invoiceDate.pristine) || invoiceDate.valid">
                        Date of Receipt is required
                    </div>
                </div>
                <div class="form-group">
                    <label>Invoice date</label>
                    <input class="form-control width-auto" type="date" [ngModel]="model.invoiceDueDate | dateFormat"
                        (ngModelChange)="model.invoiceDueDate = sanitizeDate($event)" name="invoiceDueDate"
                        required="required" [disabled]="permissionLock" #invoiceDueDate="ngModel" tabindex="20" />
                </div>
                <div class="form-group month-field">
                    <label>Month costed</label>
                    <div>
                        <monthpicker (setMonth)="setMonth($event)" [month]="month" [year]="year" [id]="model._id"
                            [disabled]="permissionLock"></monthpicker>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" style="display:block;">Associated Documents<span
                            class="optional-tag error-msg">required</span></label>
                    <document-upload type="Payable" [isDraggable]="false" [label]="'Add documents'" [model]="model"
                        [typeId]="uuid" [duplicateRecord]="duplicateRecord" [limit]="1" [disabled]="permissionLock"
                        (queued)="setMessage($event)" (duplicateFound)="duplicate()" (saveModel)="savePayable()"
                        (uploaded)="checkDocuments()"></document-upload>
                </div>
                <div class="form-group">
                    <label>
                        Notes
                    </label>
                    <div class="form-group row">
                        <div class="col-xs-12">
                            <notes-field [(model)]="model.notes" [saved]="saved" [tabindex]="30"
                                [disabled]="permissionLock"></notes-field>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xs-12 col-lg-6">
            <fieldset>
                <legend>Invoice Details</legend>
            </fieldset>
            <div class="form-group">
                <label>Category</label>

                <ng-select class="ng-select-component" *ngIf="categoryList.length>0" [(ngModel)]="selectedCategories"
                    [clearable]="false" [multiple]="true" [items]="categoryList" bindLabel="text"
                    [disabled]="permissionLock" (add)="selectCategory($event)" (remove)="removeCategory($event)"
                    placeholder="Select" id="categoryList" tabindex="33" name="categorySelect" #selectField>
                </ng-select>

                <div class="form-group mt-2">
                    <div class="input-group">
                        <input class="form-control" id="addNewCategory" placeholder="New Category" type="text"
                            name="newcat" [(ngModel)]="newCategory" [disabled]="permissionLock"
                            (keyup.enter)="$event.preventDefault(); addNewCat(newCategory)" #newCategoryField />
                        <span class="input-group-btn"><button class="btn btn-default active btn-success" type="button"
                                name="addCat" [disabled]="permissionLock" (click)="addNewCat(newCategory)"><span>Add
                                    Category</span></button></span>
                    </div>
                    <div class="alert alert-danger" [hidden]="!textRequired">
                        Category name is required
                    </div>
                </div>
                <ul class="unstyled mt-2">
                    <li *ngFor="let cat of model.categories" class="row mb-1 align-center" [@fadeInOut]>
                        <div class="col-xs-6">
                            <span>{{cat.text}}</span>
                        </div>
                        <div class="col-xs-6">
                            <div class="input-group" style="display: flex; justify-content: flex-end;">
                                <span class="input-group-addon">$</span>
                                <input class="form-control" style="max-width:100px;" type="text" [(ngModel)]="cat.total"
                                    [ngModelOptions]="{standalone: true}" name="catTotal" enforceDecimal comma="true"
                                    placeholder="0.00" required="required" (focusout)="getInvoiceTotal()"
                                    [ngClass]="{'ng-invalid': cat.total == '0' || !cat.total}"
                                    [disabled]="permissionLock" tabindex="35" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-xs-12 text-right">
                        <label class="mr-2">Invoice total</label><span>{{ model.amount|| 0 | currencyFormat}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="mb-1">Purchase orders on this invoice</label>
                <div class="pull-right mb-1">
                    <button *ngIf="isAdmin && !showEditPo && !permissionLock" type="button"
                        class="btn btn-outline btn-small ml-2" (click)="editPO()">Edit</button>
                    <button *ngIf="showEditPo" type="button" class="btn btn-primary btn-small ml-2 mr-1"
                        (click)="saveEditedPO()">Save</button>
                    <div *ngIf="showEditPo" class="inline-block pseudo-link small capitalize" (click)="cancelPOEdit()">
                        Cancel</div>
                </div>
                <div *ngIf="model.purchaseOrders && model.purchaseOrders.length" [@fadeInOut]>
                    <div *ngFor="let po of model.purchaseOrders" class="mb-1">
                        <div class="row">
                            <div *ngIf="!showEditPo" class="col-xs-6" [@fadeInOut]>
                                {{ getPoName(po._id) }}
                            </div>
                            <div *ngIf="!showEditPo" class="col-xs-6 text-right" [@fadeInOut]>
                                {{po.total | currencyFormat}}
                            </div>
                            <div *ngIf="showEditPo" class="col-xs-12">
                                <input class="form-control" autocomplete="off" [typeahead]="purchaseOrdersFormatted"
                                    typeaheadOptionField="name" [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="15" (typeaheadOnSelect)="po._id=$event.item._id"
                                    required="required" [(ngModel)]="po.name"
                                    [disabled]="!purchaseOrdersFormatted || (purchaseOrdersFormatted.length < 1)"
                                    tabindex="38" [ngClass]="{'error':!validPO}" [ngModelOptions]="{standalone: true}"
                                    name="poName" />
                                <div class="input-group mt-1 mb-2">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" style="max-width:100px;" type="text"
                                        [(ngModel)]="po.total" [ngModelOptions]="{standalone: true}" name="editPOtotal"
                                        enforceDecimal comma="true" placeholder="0.00" tabindex="43" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!showPO && !permissionLock" (click)="showPOFields()" class="add-on mt-1 mb-2">
                    <div class="add-icon">+</div><span>Add another purchase order to this invoice</span>
                </div>
                <div *ngIf="showPO" [@fadeInOut]>
                    <input class="form-control" autocomplete="off" name="newPOTypeaheadField"
                        [typeahead]="purchaseOrdersFormatted" typeaheadOptionField="name" [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="15" (typeaheadOnSelect)="newPO._id=$event.item._id"
                        required="required" [(ngModel)]="purchaseOrderName"
                        [disabled]="!purchaseOrdersFormatted || (purchaseOrdersFormatted.length < 1)" tabindex="35"
                        [ngClass]="{'error':!validPO}" />
                    <div class="alert alert-danger mt-1 mb-1" [hidden]="validPO">Invalid Purchase Order</div>
                    <div class="input-group mt-1">
                        <span class="input-group-addon">$</span>
                        <input class="form-control" style="max-width:100px;" type="text" [(ngModel)]="newPO.total"
                            name="newPOtotal" #newPOamount="ngModel" enforceDecimal comma="true" placeholder="0.00"
                            tabindex="40" />
                    </div>
                    <div class="mt-1">
                        <button type="button" [disabled]="!newPO._id" class="btn btn-primary mr-1" (click)="addPO()"
                            tabindex="45">Add</button>
                        <div class="inline-block pseudo-link small capitalize" (click)="cancelPO()" tabindex="50">Cancel
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div *ngIf="model.exportedKey" class="form-group">
                    <label class="control-label">Exported PDF Version</label>
                    <div class="">
                        <a (click)="openExportedPdf()" class="toggle-control">{{exportedPdf}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <p *ngIf="filesQueued" class="error-msg small" [@fadeInOut]>Please upload files before saving.</p>
        <button class="btn btn-primary mr-1" type="submit"
            [disabled]="!payableForm.form.valid || !model.purchaseOrders.length || !fileUploaded || !validTotals || showEditPo || invalidSupplier || permissionLock || disableSubmit"
            tabindex="100"><span *ngIf="isNew">Create</span><span *ngIf="!isNew">Save Changes</span></button>
        <div class="inline-block pseudo-link small capitalize" *ngIf="isNew" (click)="cancel()" tabindex="105">Cancel
        </div>
    </div>
    <div class="row mt-3 mb-3" *ngIf="showAlert">
        <div class="col-12">
            <div class="alert alert-danger text-center">
                A payable with that invoice number already exists. <button type="button" class="btn btn-link pa-0"
                    (click)="goToPayable()" style="font-size:16px; padding:0;">Click to view the payable</button>.
            </div>
        </div>
    </div>
</form>
<shared-modal [@fadeInOut] [display]="display" [title]="'Add a new supplier'" (onClose)="display='none'"
    (onSave)="setNewSupplier($event)" [type]="'supplier'" [model]="supplierModel"></shared-modal>