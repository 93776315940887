<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title">
    Monthly Costs Report
</div>

<div class="row report-controls print-hide">
    <div class="col-md-10">
        <div class="number-selector">
            <label class="control-label" style="margin-right:5px;">Month</label>
            <input autocomplete="off" class="form-control" name="month" [ngModel]="month" (ngModelChange)="month = $event; markDirty()" type="number" min="1" max="{{maxMonth()}}" initialFocus />
        </div>
        <div class="number-selector">
            <label class="control-label" style="margin-right:5px;">Year</label>
            <input autocomplete="off" class="form-control" name="year" [ngModel]="year" (ngModelChange)="setMaxMonth($event); markDirty()" type="number" min="1970" max="{{currentYear}}" />
        </div>
    </div>
    <div class="col-md-2 actions">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <table class="table table-select-list">
        <caption>
            Report for {{ month }} / {{ year }}
        </caption>
        <tbody *ngFor="let projectManager of model | async | sort:'projectManagerLastName'" [@fadeInOut]>
            <tr class="list-item-group">
                <th scope="col" colspan="3">
                    {{ projectManager.projectManagerFirstName }} {{ projectManager.projectManagerLastName }}
                </th>
            </tr>
            <tr>
                <th scope="col">
                    Job # / Name
                </th>
                <th scope="col">
                    Customer
                </th>
                <th scope="col" class="currency-display">
                    Current Costs
                </th>
            </tr>
            <tr *ngFor="let job of projectManager.jobs">
                <td scope="row">
                    {{ job.number }} - {{ job.name }}
                </td>
                <td scope="row">
                    {{ job.customerName }}
                </td>
                <td scope="row" class="currency-display">
                    {{ job.costs | currencyFormat }}
                </td>
            </tr>
            <tr>
                <th scope="col" colspan="3" class="currency-display">
                    Total - {{ projectManager.projectManagerFirstName }} {{ projectManager.projectManagerLastName }}: {{ projectManager.costs | currencyFormat }}
                </th>
            </tr>
        </tbody>
        <tfoot>
            <tr class="list-item-group">
                <th scope="col" colspan="2">
                    Project managers in report:  {{ totals.projectManagerCount }}
                </th>
                <th scope="col" class="currency-display">
                    Total costs:  {{ totals.costs | currencyFormat }}
                </th>
            </tr>
        </tfoot>
    </table>
</div>
