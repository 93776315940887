import { Component, OnDestroy } from '@angular/core';
import { StateManager } from './../../state/stateManager';

import { LocationModel } from './../../models/location.models';

@Component({
    selector:'location-component',
    templateUrl: './location.template.html',
    host: {'class': 'location-component'}
})

export class LocationComponent implements OnDestroy {
    constructor(private state : StateManager) { }

    ngOnDestroy() : void {
        this.state.setActiveLocation(new LocationModel());
    }
};
