import { HttpErrorResponse } from '@angular/common/http';

function handleHttpErrorResponse(error: HttpErrorResponse): string|null {
  if (error.status === 401 || error.status === 403 || error.status === 413) {
    // statuses: Unauthorized (401), Forbidden (403), and Payload Too Large (413) are expected and handled
    return null;
  }
  return `Error Code: ${error.status}\nMessage: ${error.message}`;
}

export const handleError = (error: any): void => {
  let errorMessage = null;
  if (error instanceof HttpErrorResponse) {
    errorMessage = handleHttpErrorResponse(error);
  } else if (error.error instanceof ErrorEvent) {
    errorMessage = `Error: ${error.error.message}`;
  }

  if (errorMessage) {
    console.error(errorMessage);
  }
};
