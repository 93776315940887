import {
  Component,
  OnInit,
  ViewChildren,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  QueryList,
  HostListener
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as moment from 'moment';

import { Workflow, PurchaseOrderWorkflow, WorkOrderWorkflow, InvoiceWorkflow, PayableWorkflow } from '@/models/workflow.models';
import { SupplierStatus, supplierLabel } from '@/models/supplier.models';
import { UserService } from '@/services/user.service';
import { JobService } from '@/services/job.service';
import { ListHeaderService } from '@/services/listHeader.service';
import { FilterDescription, FilterOption, FilterValue } from '@/models/filter.models';
import { handleError } from '@/utils/errors';
import { StateManager } from '@/state/stateManager';
import { IUserModel } from '@/models/user.models';
import { select } from '@ngrx/store';

@Component({
  selector: 'filter-list',
  templateUrl: './filterList.template.html',
  host: { 'class': 'filter-list-component' },
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0,
        overflow: 'hidden'
      })),
      transition('void <=> *', animate(250)),
    ]),
  ]
})
export class FilterListComponent implements OnInit {

  @ViewChildren('selectField') selectComponents: QueryList<any>;
  @ViewChild('dateFilter', {static: false}) dateFilter: ElementRef;

  @Input()
  filterOptions: FilterDescription[];

  @Input()
  activeFilters: FilterValue[];

  @Input()
  itemType: string;

  @Output()
  filterValue: EventEmitter<FilterValue[]> = new EventEmitter<FilterValue[]>();

  public filters: FilterValue[] = [];
  private users: IUserModel[];
  public statusFilterOptions: Workflow;
  public listOffset: string;
  public filterWidth: string;
  public userRole: number;
  private _currentUser: any;
	private _location: string;
  private listFilters: any = [];
  supplierLabel: (status: SupplierStatus) => string = supplierLabel;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setListTop();
  }

  constructor(private userService: UserService, private jobService: JobService, private listHeaderService: ListHeaderService, private state: StateManager,) { }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = JSON.parse(localStorage.getItem('jobSource'));
    this.userRole = this._currentUser?.role;

    if (!this._location) {
      this._location = this._currentUser.locationId;
    }

    this.initFilters();
    setTimeout(() => {
      this.selectComponents.forEach(select => {
        if(!select.multiple){
          this.listFilters.push({
            filter: select.element.id,
            id: ((this._currentUser?.role === 10) && select.element.id === "projectManagerFilter") ? this._currentUser.userId : null
          });
        }
      });
    }, 0);
  }

  public filterOn(e, id, isMultiple): void {
    let filterId = id + 'Filter';
    let filterIndex = null;
    this.listFilters = this.activeFilters;
    if(!e){
      this.removeFilter(e, id);
    } else {
      if(isMultiple){
        this.listFilters = this.listFilters.filter(function(obj){
          return obj.filter !== filterId;
        });
        e.forEach(selectedfilter => {
          this.listFilters.push({filter: filterId, id: selectedfilter.id});
        });
      } else {
        // see if this filter is already in use
        this.listFilters.forEach((filter, index)=>{
          if(filter.filter===filterId){
            filterIndex=index;
          } 
        });
        // then update or add it
        if(filterIndex){
          this.listFilters[filterIndex].id = e.id;
        } else {
          this.listFilters.push({
            filter: filterId,
            id: e.id
          })
        }
      }
      this.filterValue.emit(this.listFilters);
    };
  }

  public removeFilter(e, id): void {
    if(e){
      this.listFilters = this.listFilters.filter(function(obj){
        return obj.id !== e.value.id;
      });
    } else {
      let filterIndex = null;
      this.listFilters.forEach((filter,index) => {
        if(filter.filter === (id + 'Filter')){
          filterIndex = index;
        }
      });
      this.listFilters.splice(filterIndex, 1);
    }
    this.filterValue.emit(this.listFilters);
  }

  public datePostedFilter(): void {
    let minDate = new Date('01/01/1970'); // arbitrary date, just want to only filter once the year is filled properly
    let inputDate = new Date(this.dateFilter.nativeElement.value);
    if (this.dateFilter && this.dateFilter.nativeElement.value && (inputDate > minDate)) {
      this.listFilters = this.listFilters.filter(function(obj){
        return obj.id !== "dateFilter";
      });
      this.listFilters.push({          
        filter: 'dateFilter',
        id: new Date(this.dateFilter.nativeElement.value).toISOString() || null
      });
      this.filterValue.emit(this.listFilters);
    }
  }
  public clearDate(): void { // clear the date on focus so filter isn't triggered until the user finishes entering their date
    if (this.dateFilter && this.dateFilter.nativeElement.value) {
      this.dateFilter.nativeElement.value = null;
      this.listFilters.forEach(filter => {
        if(filter.filter==='dateFilter'){
          filter.id = null;
        }
      });
      this.filterValue.emit(this.listFilters);
    }
  }

  private async initFilters(): Promise<void> {
    this.filterOptions.forEach(option => {
      if (option.type === 'projectManager') {
        this.initializeProjectManagers().then(userFilters => {
          if (userFilters.length) {
            option.options = userFilters;
            const prefilter = this.preFilter(option.type, option.options);
            if (prefilter && prefilter.length && ((this.userRole == 10) || (this.userRole==20) || (this.userRole==5))) {
              // arriving from dashboard
                setTimeout(() => { option.preFilter = prefilter; }, 0);
            } else {
              // navigating through tabs
              if(this.userRole == 10){
                setTimeout(() => { option.preFilter = [{id:this._currentUser?.id, text: this._currentUser?.firstName + ' ' + this._currentUser?.lastName}]; }, 0);
              }
            }
          } else {
            // page refresh
            if(this.userRole == 10){
              setTimeout(() => { option.preFilter = [{id:this._currentUser?.id, text: this._currentUser?.firstName + ' ' + this._currentUser?.lastName}]; }, 0);
            }
          }
        });
      } else if (option.type === 'status') {
        option.options = this.initializeStatusFilters();
        const prefilter = this.preFilter(option.type, option.options);
        if (prefilter && prefilter.length) {
          setTimeout(() => { option.preFilter = prefilter; }, 0);
        }
      } else if (option.type === 'job') {
        this.initializeJobs().then(jobFilters => {
          if (jobFilters.length) {
            option.options = jobFilters;
            const prefilter = this.preFilter(option.type, option.options);
            if (prefilter && prefilter.length) {
              setTimeout(() => { option.preFilter = prefilter; }, 0);
            }
          }
        });
      } else if (option.type === 'priority') {
        option.options = [
          { text: 'None', id: 'none' },
          { text: '1', id: '1' },
          { text: '5', id: '5' }
        ];
        const prefilter = this.preFilter(option.type, option.options);
        if (prefilter) {
          setTimeout(() => { option.preFilter = prefilter; }, 0);
        }
      } else if (option.type === 'date') {
        const prefilter = this.activeFilters.filter(af => af.filter.startsWith(option.type));
        if (prefilter && prefilter.length > 0) {
          setTimeout(
            () => {
              // simple hack to drop timezone. Works because we are only dealing with days rather than times anyway
              let date = moment(prefilter[0].id.replace('Z', ''));
              option.preFilter = date.format('YYYY-MM-DD');
            },
            0);
        }
      }
    });
    switch (this.filterOptions.length) {
      case 3:
        this.filterWidth = 'col-lg-4';
        break;
      case 4:
        this.filterWidth = 'col-lg-3';
        break;
      default:
        this.filterWidth = 'col-lg-6';
        break;
    }
  }

  private initializeStatusFilters(): any {
    let statusFilters = [];
    if (this.itemType === 'purchaseOrder') {
      this.statusFilterOptions = new PurchaseOrderWorkflow();
    } else if (this.itemType === 'workOrder') {
      this.statusFilterOptions = new WorkOrderWorkflow();
    } else if (this.itemType === 'receivable') {
      this.statusFilterOptions = new InvoiceWorkflow();
    } else if (this.itemType === 'payable') {
      this.statusFilterOptions = new PayableWorkflow();
    } else if (this.itemType === 'supplier') {
      statusFilters = this.filterOptions[0].options;
      this.statusFilterOptions = null;
    }
    if (this.statusFilterOptions) {
      this.statusFilterOptions.states.forEach(status => {
        // setting id for unassigned status because it won't show up in dropdown without it.
        // need to equate this to null when returning results
        let id = (status.value === null) ? 'unassigned' : status.value;
        statusFilters.push({ text: status.label, id: id });
      });
    }

    return statusFilters;
  }

  private async initializeJobs(): Promise<FilterOption[]> {
    try {
    // this.localStorageService.getItem('jobSource', JSON.stringify({ name: src, id: this.locationIdFromName(src) }));
    // let jobsource = 
      this.filters.push({ filter: 'locationFilter', id: this._location });
      const jobs = await this.jobService.getJobs(0, true, null, 'number', 1, 1, this.filters);
      return jobs.map(job => {
        return {
          text: job.number + ' - ' + job.name,
          id: job._id
        };
      });
    } catch (e) {
      handleError(e);
    }
    return [];
  }

  private async initializeProjectManagers(): Promise<FilterOption[]> {
    try {
      this.state.getUsersList().subscribe(users => this.users = users);

      // filter out the inactive users
      this.users = this.users.filter(user => {
        if (user.isActive) {
          return user;
        }
      });
      // sort users by last name
      this.users.sort((a, b) => {
        if (a.lastName > b.lastName) { return 1; }
        if (a.lastName < b.lastName) { return -1; }
        return 0;
      });
      // build the dropdown options
      return this.users.map(user => {
        return {
          text: user.firstName + ' ' + user.lastName,
          id: user._id
        };
      });
    } catch (e) {
      handleError(e);
    }
    return [];
  }

  private setListTop(): void {
    this.listHeaderService.setListTop();
  }

  private preFilter(field: string, options: FilterOption[]): any {
    const filters = this.activeFilters.filter(af => af.filter.startsWith(field));
    return this.getPreFilterValues(filters, options);
  }

  private getPreFilterValues(activeFilters: FilterValue[], allOptions: FilterOption[]): any {
    let filterValue = [];
    if (activeFilters && allOptions) {
      activeFilters.forEach(filter => {
        allOptions.forEach(option => {
          if (filter.id === option.id) {
            filterValue.push(option);
          }
        });
      });
    }
    return filterValue;
  }
}
