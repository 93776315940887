import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DragulaService } from 'ng2-dragula';
import { FileUploader, FileItem, ParsedResponseHeaders, FileUploaderOptions } from 'ng2-file-upload';
import { Subscription } from 'rxjs';

import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { EnvironmentService } from '@/services/EnvironmentService';
import { FilesService } from '@/services/files.service';
import { AuthService } from '@/services/auth.service';

@Component({
	selector: 'document-upload',
	templateUrl: './documentUpload.template.html',
	host: {'class': 'document-upload-component'},
	animations: [
		trigger('fadeInOut', [
			 state('void', style({
					 opacity: 0,
					 height: 0
			 })),
			 transition('void <=> *', animate(500)),
		 ]),
   ]
})
export class DocumentUploadComponent extends BaseSmartComponent implements OnInit {
	@Input() model: any;
	@Input() label: string = '';
	@Input() type = 'unknown'; // job or po
	@Input() typeId = 'unknown'; // uuid
	@Input() duplicateRecord: boolean = false;
	@Input() fileSizeError: boolean = false;
	@Input() fileUploadError: boolean = false; // for any errors returned from the ng2-file-upload module
	@Input() isDraggable: boolean = true;
	@Input() disabled: boolean = false;
	@Input() limit: number = 0;

	@Output() queued: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() uploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() duplicateFound: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() saveModel: EventEmitter<any> = new EventEmitter<any>();
	@Output() refreshDocs: EventEmitter<boolean> = new EventEmitter<boolean>();

	public readonly Url: string;
	public hasBaseDropZoneOver = false;
	public hasAnotherDropZoneOver = false;
	public uploader!: FileUploader;
	public response: string = ''; // response from uploader not currently used
	public documentsSorted = 'documentsSorted';
	public maxFileMBs: number = 10; // for displaying in the error message
	private maxFileSize: number = 0;

	public get canUpload(): boolean {
	  return !this.disabled && (this.limit === 0 || (this.model.documents && this.model.documents.length < this.limit));
  }

	private docSubscription: Subscription = new Subscription();

	constructor(
		messageService: MessageService,
		environment: EnvironmentService,
		private dragulaService: DragulaService,
		private filesService: FilesService,
		private authService: AuthService
	) {
		super(messageService);
		// todo move this to service
		this.Url = environment.APIBASEURL + '/files/upload?ngsw-bypass';

		// todo is this functional? does it do anything?
		this.docSubscription
			.add(dragulaService.dropModel(this.documentsSorted)
			.subscribe(({el, target, source, sourceModel, targetModel, item}) => {
				setTimeout(() => {
					this.save(targetModel);
				},0);
			})
		);
	}

	ngOnInit(): void {
		this.authService.currentUser.subscribe(() => this.resetUploader());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['typeId']) {
			this.resetUploader();
		}
		if (changes['model']) {
			this.getDocuments();
		}
	}

	override ngOnDestroy(): void {
		this.docSubscription.unsubscribe();
	}

	private resetUploader(): void {
		const uploaderOptions = this.buildUploaderOptions();
		this.maxFileSize = this.maxFileMBs * 1024 * 1024;
		this.uploader = new FileUploader(uploaderOptions);
		this.uploader.onErrorItem = (item, response, status, headers) => this.onfileUploadError(item, response, status, headers);
		this.uploader.onAfterAddingAll = (items) => this.onAddingFiles(items);

		this.duplicateRecord = false;
		this.subscribeToUploader();
	}

  private onfileUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		let error = JSON.parse(response);
		console.error('File upload error:');
		console.error(error);
		this.fileUploadError = true;
	}

	// validate all files after being added to the queue
  private onAddingFiles(fileItems: []): void {
		this.fileSizeError = false;
		fileItems.forEach(item => {
			let itemObj = {file:{size:0}};
			itemObj = item;
			if (this.maxFileSize && (itemObj.file.size > this.maxFileSize)) {
					this.fileSizeError = true;
				return;
			}
		});
	}

	public onFileSelected(event: EventEmitter<File[]>) {
		if (!this.fileSizeError) {
			this.uploader.uploadAll();
			this.fileSizeError = false;
			this.fileUploadError = false;
		} else {
			this.resetUploader();
		}
	}

	private subscribeToUploader() {
		this.uploader.response.subscribe( res => {
			var response = JSON.parse(res);
			// this.model.documents = this.uploader.queue;
			if (response.message == "Duplicate Record") {
				this.resetUploader();
				this.uploaded.emit(false);
				this.queued.emit(false);
				this.duplicateFound.emit(true);
				this.duplicateRecord = true;
				response = null;
			} else {
				if (this.uploader.progress === 100) {
					this.resetUploader();
					this.getDocuments();
				}
			}
		});
	}

	public setMessage() {
		this.duplicateRecord = false;
		if (this.uploader.queue.length) {
				this.queued.emit(true);
		} else {
				this.queued.emit(false);
		}
	}

	public fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	// hack prevent deleting acknowledgements until those can be handled better
	public isDeletable(file: any): boolean {
		return file.url.indexOf('/acknowledgements/') === -1;
	}

	public removeDocument(file: any): void {
		if (this.warnOnDelete('file')) {
			const self = this;
			if(self.model){ var id = self.model._id || self.typeId; }
			self.filesService.deleteFile(self.type, id, file.name).then(() => {
				this.duplicateRecord = false;
				self.getDocuments();
				this.refreshDocs.emit(true);
			});
		}
	}

	private getDocuments(): void {
		const self = this;
		this.duplicateRecord = false;
		if(self.model){var id = self.model._id || self.typeId;}
		self.filesService.getFiles(self.type, id).then((files) => {
			if (self.model) {
				setTimeout(() => {
					self.model.documents = files;
					this.uploaded.emit(true);
					this.queued.emit(false);
				}, 0);
			}
		});
	}

	save(order: Object): void {
		this.saveModel.emit(order);
	}

	private buildUploaderOptions(): FileUploaderOptions {
		const authToken = this.authService.accessToken.getValue();
    let opts: FileUploaderOptions = {
      url: this.Url,
      authToken: `Bearer ${authToken}`,
      parametersBeforeFiles: true,
      additionalParameter: {
        type: this.type, typeId: this.typeId,
        autoUpload: true,
        maxFileSize: this.maxFileSize
      },
	//   headers: [{ name: 'ngsw-bypass', value: '' }]
    };

    if (this.limit === 1) {
      opts.queueLimit = 1;
    }

    return opts;
  }
}
