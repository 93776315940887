import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';

export interface IPurchaseOrderState {
	purchaseOrders: Array<IPurchaseOrderModel>;
	activePurchaseOrder: IPurchaseOrderModel;
}

const defaultState: IPurchaseOrderState = {
	purchaseOrders: new Array<IPurchaseOrderModel>(),
	activePurchaseOrder: null
};

export const PURCHASEORDER_ACTIONS = {
	SET_PURCHASEORDERS_LIST: 'SET_PURCHASEORDERS_LIST',
	SET_ACTIVE_PURCHASEORDER: 'SET_ACTIVE_PURCHASEORDER'
};

const purchaseOrderReducer = function(state: IPurchaseOrderState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch (action.type) {
		case PURCHASEORDER_ACTIONS.SET_PURCHASEORDERS_LIST:
			newState.purchaseOrders = action.payload;
			break;
		case PURCHASEORDER_ACTIONS.SET_ACTIVE_PURCHASEORDER:
			newState.activePurchaseOrder = action.payload;
			break;
	}

	return newState;
};

export function getPurchaseOrdersList() {
	return (state: Store<IPurchaseOrderState>) => {
		return state.select(s => {
			return s.purchaseOrders;
		});
	};
}

export function getActivePurchaseOrder() {
	return (state: Store<IPurchaseOrderState>) => {
		return state.select(s => {
			return s.activePurchaseOrder;
		});
	};
}

export function reducer(state: IPurchaseOrderState | undefined, action: Action) {
	return purchaseOrderReducer(state, action);
}
