import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AttachedToWorkOrderPipe } from '@/utils/attachedToWorkOrder.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { WorkOrderSearchComponent } from './workOrderSearch.component';
import { WorkOrderComponent } from './workOrder.component';
import { WorkOrderAddComponent } from './workOrderAdd.component';
import { WorkOrderConnectionComponent } from './workOrderConnection.component';
import { WorkOrderEditComponent } from './workOrderEdit.component';
import { WorkOrderEditFormComponent } from './workOrderEditForm.component';
import { WorkOrderListComponent } from './workOrderList.component';
import { WorkOrderPartsComponent } from './workOrderParts.component';
import { WorkOrderPMNotesComponent } from './workOrderPMNotes.component';
import { WorkOrderDocumentsComponent } from './workOrderDocuments.component';

import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { WorkflowModule } from '@/components/Workflow/workflow.module';
import { FileUploadModule } from '@/components/FileUploadComponent/file-upload.module';
import { FilterListModule } from '@/components/FilterListComponent/filterList.module';
import { UtilityModule } from '@/utils/utility.module';

@NgModule({
    declarations: [
    	WorkOrderSearchComponent,
		WorkOrderComponent,
		WorkOrderAddComponent,
		WorkOrderConnectionComponent,
		WorkOrderEditComponent,
		WorkOrderEditFormComponent,
		WorkOrderListComponent,
		WorkOrderPartsComponent,
		WorkOrderPMNotesComponent,
		AttachedToWorkOrderPipe,
		WorkOrderDocumentsComponent
    ],
    imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		InfiniteScrollModule,
		DocumentUploadModule,
		WorkflowModule,
		FileUploadModule,
		FilterListModule,
		UtilityModule
    ],
    exports: [
    	WorkOrderSearchComponent,
		WorkOrderComponent,
		WorkOrderAddComponent,
		WorkOrderEditComponent,
		WorkOrderListComponent,
		WorkOrderConnectionComponent,
		WorkOrderPartsComponent,
		WorkOrderPMNotesComponent,
		WorkOrderDocumentsComponent
    ]
})
export class WorkOrderModule {}
