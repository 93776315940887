<div class="screen-title">
	Warranty
</div>

<form #warrantyForm="ngForm" class="form-horizontal" (submit)="saveJob()" autocomplete="off">
    <fieldset>
        <legend>Warranty Information</legend>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group row">
                    <div class="col-xs-12">
                        <label class="">Substantial Completion</label>
                    </div>
                    <div class="col-xs-5 col-md-4 col-lg-3">
                        <input type="date" class="form-control" [ngModel]="model.warranty.substantialCompletionDate | dateFormat" (ngModelChange)="model.warranty.substantialCompletionDate = sanitizeDate($event); updateSubstantialCompletionDate()" name="substantialCompletionDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Installer Warranty</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="installerYears" (change)="recalculateDateFromBase('installerDate')" [(ngModel)]="_years.installerDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>
                            Ends
                        </label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.installerDate | dateFormat" (ngModelChange)="model.warranty.installerDate = sanitizeDate($event); recalculateYearsFromBase('installerDate')" name="installerDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Metal Warranty</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="metalYears" (change)="recalculateDateFromBase('metalDate')" [(ngModel)]="_years.metalDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Ends</label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.metalDate | dateFormat" (ngModelChange)="model.warranty.metalDate = sanitizeDate($event); recalculateYearsFromBase('metalDate')" name="metalDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Metal Finish Warranty</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="metalFinishYears" (change)="recalculateDateFromBase('metalFinishDate')" [(ngModel)]="_years.metalFinishDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Ends</label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.metalFinishDate | dateFormat" (ngModelChange)="model.warranty.metalFinishDate = sanitizeDate($event); recalculateYearsFromBase('metalFinishDate')" name="metalFinishDate" />
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset class="mt-2">
        <legend>Glass Warranty</legend>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>IGU</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="glassIGUYears" (change)="recalculateDateFromBase('glassIGUDate')" [(ngModel)]="_years.glassIGUDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Ends</label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.glassIGUDate | dateFormat" (ngModelChange)="model.warranty.glassIGUDate = sanitizeDate($event); recalculateYearsFromBase('glassIGUDate')" name="glassIGUDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Laminated</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="glassLaminatedYears" (change)="recalculateDateFromBase('glassLaminatedDate')" [(ngModel)]="_years.glassLaminatedDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Ends</label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.glassLaminatedDate | dateFormat" (ngModelChange)="model.warranty.glassLaminatedDate = sanitizeDate($event); recalculateYearsFromBase('glassLaminatedDate')" name="glassLaminatedDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Spandrel</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="glassSpandrelYears" (change)="recalculateDateFromBase('glassSpandrelDate')" [(ngModel)]="_years.glassSpandrelDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Ends</label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.glassSpandrelDate | dateFormat" (ngModelChange)="model.warranty.glassSpandrelDate = sanitizeDate($event); recalculateYearsFromBase('glassSpandrelDate')" name="glassSpandrelDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Monolithic</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" max="20" step="any" name="glassMonolithicYears" (change)="recalculateDateFromBase('glassMonolithicDate')" [(ngModel)]="_years.glassMonolithicDate" />
                            <div class="input-group-addon">
                                years
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-md-4 col-lg-3">
                        <label>Ends</label>
                        <input type="date" class="form-control" [ngModel]="model.warranty.glassMonolithicDate | dateFormat" (ngModelChange)="model.warranty.glassMonolithicDate = sanitizeDate($event); recalculateYearsFromBase('glassMonolithicDate')" name="glassMonolithicDate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <div class="col-sm-12 col-md-8 col-lg-6">
                        <label>Notes</label>
                        <notes-field [(model)]="model.warranty.notes" [saved]="saved"></notes-field>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <div class="row">
        <div class="col-xs-12">
            <button type="submit" class="btn btn-primary">Save Warranty</button>
        </div>
    </div>
</form>
