<form (ngSubmit)="saveCustomer()" #customerForm="ngForm" class="" autocomplete="off">
    <input name="id" type="hidden" value="{{ model._id }}" />

    <div class="row">
        <div class="col-sm-12 col-md-6">
            <fieldset>
                <legend>
                    General Information
                </legend>

                <div class="form-group">
                    <label>Name</label>
                    <div class="">
	                    <div class="input-group">
	                        <input id="customerName" type="text" class="form-control" [(ngModel)]="model.name" name="name" required="required" #name="ngModel" initialFocus/>
		                    <span class="input-group-btn">
			                    <button class="btn btn-default" [ngClass]="model.isActive ? 'active btn-success' : 'inactive btn-danger'" (click)="toggleActive()" name="isActive">
				                    <span [hidden]="!model.isActive">Active</span>
				                    <span [hidden]="model.isActive">Inactive</span>
			                    </button>
		                    </span>
	                    </div>
                        <div class="alert alert-danger" [hidden]="(!customerForm.form.submitted && name.pristine) || name.valid">
                            Name is required
                        </div>
                    </div>
                </div>

	            <div class="form-group">
		            <label>Website</label>
		            <div class="">
			            <div class="input-group">
				            <span class="input-group-addon">http://</span>
				            <input type="text" class="form-control" [(ngModel)]="model.website" name="website" #website="ngModel" />
				            <div class="input-group-btn">
					            <a href="http://{{model.website}}" target="_blank" class="btn btn-default">Go</a>
				            </div>
			            </div>
		            </div>
	            </div>

                <div class="form-group">
                    <label>Main Phone</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.main" name="main" #main="ngModel" [imask]="{ mask: '(000) 000-0000 x00000' }" validatePhone />
                    </div>
                </div>

                <div class="form-group">
                    <label>Fax</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.fax" name="fax" #fax="ngModel" [imask]="{ mask: '(000) 000-0000' }" validatePhone />
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="col-sm-12 col-md-6">
            <fieldset>
                <legend>
                    Address
                </legend>

                <div class="form-group">
                    <label>Address 1</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.address.address1" name="address1" required="required" #address1="ngModel" />
                        <div class="alert alert-danger" [hidden]="(!customerForm.form.submitted && address1.pristine) || address1.valid">
                            Address 1 is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Address 2</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.address.address2" name="address2" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label city-st-zip"><div class="city">City</div><div class="state">State</div><div class="zip">Zip</div></label>
                    <div class="">
                        <div class="input-group full-width">
                            <input type="text" class="form-control city" [(ngModel)]="model.address.city" name="city" required="required" #city="ngModel" />
                            <state-selector [(ngModel)]="model.address.state" name="state" required="true" #state="ngModel"></state-selector>
                            <input type="text" class="form-control zip" [(ngModel)]="model.address.zip" name="zip" required="required" #zip="ngModel" [imask]="{ mask: '00000-0000' }" />
                        </div>

                        <div class="alert alert-danger" [hidden]="(!customerForm.form.submitted && city.pristine) || city.valid">
                            City is required
                        </div>

                        <div class="alert alert-danger" [hidden]="(!customerForm.form.submitted && state.pristine) || state.valid">
                            State is required
                        </div>

                        <div class="alert alert-danger" [hidden]="(!customerForm.form.submitted && zip.pristine) || zip.valid">
                            Zip is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Notes</label>
                    <notes-field [(model)]="model.notes" [saved]="saved"></notes-field>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row mt-3 mb-3" *ngIf="showAlert">
        <div class="col-12">
            <div class="alert alert-danger text-center">
                A customer with that name already exists. <button type="button" class="btn btn-link pa-0" (click)="goToCustomer()" style="font-size:16px; padding:0;">Click to view the customer</button>.
            </div>
        </div>
    </div>
    <div class="">
        <button type="submit" class="btn btn-primary mr-1" [disabled]="!customerForm.form.valid">Save</button>
        <div *ngIf="showCancel" class="inline-block pseudo-link small capitalize" (click)="cancel()">Cancel</div>
    </div>
</form>
