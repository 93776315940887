import { Routes } from '@angular/router';

import { ChangeOrderComponent } from './changeOrder.component';
import { JobChangeOrderAddComponent } from '../JobComponent/jobChangeOrderAdd.component';
import { JobChangeOrderEditComponent } from '../JobComponent/jobChangeOrderEdit.component';
import { ChangeOrderDocumentsComponent } from './changeOrderDocuments.component';
import { MsalGuard } from '@azure/msal-angular';

export const CHANGEORDER_ROUTES: Routes = [
  { path: 'Add', component: JobChangeOrderAddComponent },
  {
    path: ':changeOrderId',
    canActivate: [MsalGuard],
    component: ChangeOrderComponent,
    children: [
      { path: '', redirectTo: 'Edit', pathMatch: 'prefix' },
      { path: 'Edit', component: JobChangeOrderEditComponent },
      { path: 'Documents', component: ChangeOrderDocumentsComponent },
    ],
  },
];
