import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { ICustomerModel, CustomerModel } from '@/models/customer.models';
import { IContactModel, ContactModel  } from '@/models/contact.models';
import { constructSearchQuery } from '@/utils/queryUtils';

@Injectable()
export class CustomerService extends ApiBaseService {
  constructor(private _http: HttpClient, private stateManager: StateManager, messageService: MessageService) {
    super(messageService);
    this.baseUrl = 'customers';
  }

  getCustomers(
    getInactive: boolean = false,
    getAll: boolean = false, // not used
    searchTerm: string = null,
    sortField: string = null,
    sortDirection: number = null,
    pageNumber: number = null
  ): Promise<Array<ICustomerModel>> {
    let queryUrl = constructSearchQuery(this.baseUrl, getInactive, searchTerm, sortField, sortDirection, pageNumber);

    let result = this._http.get<Array<CustomerModel>>(queryUrl).pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setCustomersList(s);
    });
  }

  getCustomer(id: string): Promise<ICustomerModel> {
    let result = this._http.get<CustomerModel>(this.baseUrl + '/' + id).pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setActiveCustomer(s);
    });
  }

  updateCustomer(customer: ICustomerModel): Promise<ICustomerModel> {
    let result: Observable<ICustomerModel>;

    if (customer._id) {
      result = this._http.put<ICustomerModel>(this.baseUrl + '/' + customer._id, customer).pipe(share());
    } else {
      result = this._http.post<ICustomerModel>(this.baseUrl, customer).pipe(share());
    }

    let promise = this.extract(result, (s) => {
      this.stateManager.setActiveCustomer(s);
    }, (error) => {
        this.notify('An error occurred when updating the customer', { type: 'error' });
    });

    promise.then(() => {
      this.notify('Customer updated');
    }).catch(() => { /* DO NOTHING */ });

    return promise;
  }

  getContacts(id: string): Promise<Array<IContactModel>> {
    let result = this._http.get<Array<ContactModel>>(this.baseUrl + '/' + id + '/contacts').pipe(share());
    return this.extract(result, (s) => {
      this.stateManager.setContactsList(s);
    });
  }

  getContact(id: string, contactId: string): Promise<IContactModel> {
    let result = this._http.get<ContactModel>(this.baseUrl + '/' + id + '/contacts/' + contactId).pipe(share());
    return this.extract(result, (s) => {
        this.stateManager.setActiveContact(s);
    });
  }

  updateContact(id: string, contact: IContactModel): Promise<IContactModel> {
    let result: Observable<IContactModel>;
    let contactBaseUrl = this.baseUrl + '/' + id + '/contacts';

    if (contact._id) {
      result = this._http.put<IContactModel>(contactBaseUrl + '/' + contact._id, contact).pipe(share());
    } else {
      result = this._http.post<IContactModel>(contactBaseUrl, contact).pipe(share());
    }

    let promise = this.extract(result, (s) => {
        this.stateManager.setActiveContact(s);
    }, (error) => {
        this.notify('An error occurred when updating the contact', { type: 'error' });
    });

    promise.then(() => {
      this.notify('Contact updated');
    }).catch(() => { /* DO NOTHING */ });

    return promise;
  }

  deleteContact(id: string, contactId: string): Promise<Object> {
    let result = this._http.delete(this.baseUrl + '/' + id + '/contacts/' + contactId).pipe(share());
    let promise = this.extract(result);
    promise.then(() => {
      this.notify('Contact deleted');
    }).catch(() => { /* DO NOTHING */ });

    return promise;
  }

  validateContact(contacts, newContact): String {
    let valid = 'valid';
    if (!newContact.firstName || !newContact.lastName) {
      this.notify('First or last name fields cannot be blank.');
      return 'invalid';
    }
    const newContactName = newContact.firstName.toLowerCase() + newContact.lastName.toLowerCase();
    contacts.forEach(contact => {
      if (newContactName === (contact.firstName.toLowerCase() + contact.lastName.toLowerCase())) {
        valid = contact._id;
        return valid;
      }
    });
    return valid;
  }
}
