import {IAppConfiguration} from '@/models/appConfig.models';
import { environment } from './../../environments/environment';

/**
 * Environment service provider created using: https://www.jvandemo.com/how-to-use-environment-variables-to-configure-your-angular-application-without-a-rebuild/
 */
export class EnvironmentService implements IAppConfiguration {
	public APIBASEURL: string;
	public DSN_KEY?: string;
	public MADISON_ID: string;
	public PEWAUKEE_ID: string;
	private root: string;

	constructor() {
		this.APIBASEURL = environment.APIBASEURL;
		this.MADISON_ID = environment.MADISON_ID;
		this.PEWAUKEE_ID = environment.PEWAUKEE_ID;
		this.DSN_KEY = environment.DSN_KEY;
	}

}

export const EnvironmentServiceFactory = () => {
	// Create env
	const env = new EnvironmentService();

	// Read environment variables from browser window
	const browserWindow = window || {};
	const browserWindowEnv = browserWindow['__env'] || {};

	// Assign environment variables from browser window to env
	// In the current implementation, properties from env.js overwrite defaults from the EnvService.
	// If needed, a deep merge can be performed here to merge properties instead of overwriting them.
	for (const key in browserWindowEnv) {
		if (browserWindowEnv.hasOwnProperty(key)) {
			env[key] = window['__env'][key];
		}
	}

	return env;
};

export const EnvironmentServiceProvider = {
	provide: EnvironmentService,
	useFactory: EnvironmentServiceFactory,
	deps: [],
};
