import { Component, OnInit } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { IChangeOrderModel } from '@/models/changeOrder.models';

@Component({
    selector: 'job-change-order-edit',
    templateUrl: './jobChangeOrderEdit.template.html',
    host: {'class': 'job-change-order-edit-component'}
})
export class JobChangeOrderEditComponent extends BaseSmartComponent implements OnInit {
  public model$: Observable<IChangeOrderModel>;

  private _customerId: string;
  private _jobId: string;
  private _changeOrderId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private jobService: JobService
  ) {
    super(messageService);
  }

  ngOnInit(): void {

    this.model$ = this.state.getActiveChangeOrder().pipe(filter((co: IChangeOrderModel) => !!co && !!co._id));

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    let editRoute = routerUtils.getRouteParameterObservable(this.route, 'changeOrderId');
    if (editRoute) {
      this.watchSubscription(editRoute.subscribe((s) => {
        this._changeOrderId = s['changeOrderId'];
        if (this._changeOrderId) {
          this.jobService.getChangeOrder(this._customerId, this._jobId, this._changeOrderId);
        }
      }));
    }
  }

  public saveChangeOrder(newModel: IChangeOrderModel): void {
		// remove the docs for the save and then add them back in after so view is updated
		const docs = newModel.documents;
		delete newModel.documents;

		this.jobService.updateChangeOrder(this._customerId, this._jobId, newModel)
      .then((result) => {
        this.jobService.getJob(this._customerId, this._jobId);
        this.jobService.getChangeOrders(this._customerId, this._jobId);
        setTimeout(() => {
          if (docs) {
            newModel.documents = docs;
          }
        }, 0);
      });
  }

  public cancel(): void {
    this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
  }

  public deleteChangeOrder(id: string): void {
    this.jobService.deleteChangeOrder(this._customerId, this._jobId, id)
      .then((result) => {
        this.jobService.getJob(this._customerId, this._jobId);
        this.jobService.getChangeOrders(this._customerId, this._jobId);
        this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
      });
  }
}
