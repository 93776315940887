import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CountUpModule } from 'ngx-countup';

import { DashboardComponent } from './dashboard.component';
import { EntitySummaryComponent } from './entitySummary.component';
import { EntityFullComponent } from './entityFull.component';

import { UtilityModule } from '@/utils/utility.module';
import { JobModule } from '@/components/JobComponent/job.module';

@NgModule({
    declarations: [
        DashboardComponent,
        EntitySummaryComponent,
        EntityFullComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UtilityModule,
        TypeaheadModule.forRoot(),
        JobModule,
        CountUpModule
    ],
    exports: [
        DashboardComponent,
        EntitySummaryComponent,
        EntityFullComponent
    ]
})
export class DashboardModule {}
