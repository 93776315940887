import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import * as routerUtils from '@/utils/routerUtils';
import { WorkOrderSortFunctions, WorkOrderFilterFunctions } from '@/utils/dataManipulationUtils';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { Workflow, WorkflowState, WorkflowStatus, WorkOrderWorkflow } from '@/models/workflow.models';
import { IJobModel } from '@/models/job.models';
import { ICustomerModel } from '@/models/customer.models';
import * as Utility from '@/models/utility.models';
import { MessageService } from '@/services/message.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { WorkflowService } from '@/services/workflow.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ListHeaderService } from '@/services/listHeader.service';
import { LocalStorageService } from '@/services/localStorage.service';
import { FilterValue, parseSearchFilterHistory, SortOptions } from '@/models/filter.models';
import { RouterHistoryService } from '@/services/router-history.service';
import { handleError } from '@/utils/errors';
import {Subscription} from 'rxjs';

@Component({
	selector: 'workorder-search',
	templateUrl: './workOrderSearch.template.html',
  host: {'class': 'work-order-search-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0
			})),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class WorkOrderSearchComponent extends BaseSmartComponent implements OnInit {
	@ViewChild('workOrderSearch', {static: true})
	workOrderSearch: Utility.IFocusable;

  public searchTerm: string = '';
  public showClosed: boolean = false;
  public workOrderSet = [];
  public sortDirection: number = 1;
  public currentSort: string = 'number';
  public isLoading: boolean = true;
  public filterOptions: Array<Object> = [];
  public statusOptions: Workflow = new WorkOrderWorkflow();
  public filters: FilterValue[] = [];
  public location: string = '';

  private _currentUser: any;
	private model: Subject<Array<IWorkOrderModel>> = new Subject<Array<IWorkOrderModel>>();
  private lastSearchTerm: string = '';
	private workOrderSetFull = [];
	private pageNumber: number = 0;
	private sortField: string = 'number';
	private scrollPosition: number = 0;
	private searchFields = ['number', 'name', 'date', 'job.name'];
  private sortFields: string[] = ['number', 'job.number', 'job.name', 'datePromised', 'priority', 'status'];
  private locationSub: Subscription;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		private workOrderService: WorkOrderService,
		private dataManipulationService: DataManipulationService<IWorkOrderModel>,
		messageService: MessageService,
		protected workFlowService: WorkflowService,
    private history: RouterHistoryService,
    private listHeaderService: ListHeaderService,
		private localStorageService: LocalStorageService
	) {
		super(messageService);
	}

	ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.history.currentState$.pipe(take(1))
      .subscribe(s => {
        if (s) {
          const result = parseSearchFilterHistory(s);
          this.filters = result.filters;
          if (result.searchTerm) {
            this.searchTerm = result.searchTerm;
          }

          if (result.showClosed !== undefined) {
            this.showClosed = result.showClosed;
          }

          this.initializeSort(result.sort);
        }
        this.reloadList(this.filters);
      });

      this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
        this.workOrderSet = [];
        this.reloadList(this.filters);
      });
      this.setFilterOptions();
    this.dataManipulationService.initialize(WorkOrderFilterFunctions, WorkOrderSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getWorkOrdersList(), this.model));

    this.dataManipulationService.setFilter('text', '');
	}

  override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
    super.ngOnDestroy();
  }

  public search(): void {
    this.isLoading = true;

    if (this.searchTerm === '') {
      this.searchTerm = '';
		}

    if (this.searchTerm !== this.lastSearchTerm) {
      this.searchList();
      this.lastSearchTerm = this.searchTerm;
		} else {
      this.lastSearchTerm = this.searchTerm;
      this.isLoading = false;
		}
	}

  public sortList(e): void {
    this.isLoading = true;
    this.sortField = e.field;
    this.sortDirection = e.sortDirection;
    this.currentSort = e.currentSort;
    this.resetList();
    this.reloadList(this.filters);
  }

  public filterTheList(filters: FilterValue[]): void {
    this.isLoading = true;
    this.filters = filters;
    this.resetList();
    this.reloadList(this.filters);
  }

  public filterToggle(): void {
    this.showClosed = !this.showClosed;
    this.resetList();
    this.reloadList(this.filters);
  }

  statusClass(workflowState: WorkflowState): string {
    if (!workflowState || !workflowState.severity) {
      return 'black';
    }
    return this.workFlowService.getStatusClass(workflowState);
  }

	public editEntity(workOrder: IWorkOrderModel): void {
		let job = workOrder.job as IJobModel;
		let customer = job.customer as ICustomerModel;
		this.router.navigate(
		  ['/Customers', customer._id.toString(), 'Job', job._id, 'WorkOrders', workOrder._id],
    { relativeTo: routerUtils.getParentRoute(this.route) }
      );
	}

  public onScroll(e): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadList(this.filters);
      }
    }
  }

  protected initializeSort(sort: SortOptions): any {
    if (sort) {
      this.sortDirection = sort.direction;
      if (this.sortFields.includes(sort.field)) {
        this.sortField = sort.field;
      }
    }
    this.currentSort = this.sortField;
  }

	private reloadList(filters: FilterValue[]): void {
		const extendedFilters: any[] = [...filters];
		extendedFilters.push({ filter: 'includeClosedJobsFilter', id: this.showClosed });
		// pre-set pm filter to return correct results
		if(this._currentUser?.role === 10){
			extendedFilters.push({ filter: 'projectManagerFilter', id: this._currentUser?.userId });
		}
		this.history.changeState([
		  { filter: 'searchTerm', id: this.searchTerm },
      { filter: 'sort', id: this.sortField, direction: this.sortDirection },
      ...extendedFilters
    ]);
		this.workOrderService.search(30, this.searchTerm, this.sortField, this.sortDirection, this.pageNumber, extendedFilters)
      .then((workOrders) => {
				workOrders.forEach(wo => {
					const workflowState = this.statusState(wo.status);
					wo.css = this.statusClass(workflowState);
          if(wo.status) {
          } else {
            wo.status = {status: '', timestamp:'', note:''}
          }
          wo.status.status = (workflowState) ? workflowState.label : 'Unassigned';
					this.workOrderSet.push(wo);
				});
        setTimeout(() => {
          this.listHeaderService.setListTop();
          this.isLoading = false;
        },250);
      })
			.catch(handleError);
	}

	private resetList(): void {
	  this.scrollPosition = 0;
	  this.pageNumber = 0;
	  this.workOrderSet = JSON.parse(JSON.stringify(this.workOrderSetFull));
	}

	// private doSortList(): void {
	// 	this.scrollPosition = 0;
	// 	this.workOrderSet = GeneralSortFunction(this.workOrderSet, this.sortField, this.sortDirection);
	// 	this.isLoading = false;
	// }

	private searchList(): void {
		if (this.searchTerm === '') {
			this.searchTerm = '';
		}

		if (this.searchTerm !== this.lastSearchTerm) {
			this.resetList();
			this.reloadList(this.filters);
			this.lastSearchTerm = this.searchTerm;
		}
	}

	private setFilterOptions(): void {
	  this.filterOptions = [
	    {
	      type: 'projectManager',
        label: 'Project Manager',
        options: null,
      },
      {
        type: 'status',
        label: 'Status',
        options: null
      },
      {
        type: 'priority',
        label: 'Priority',
        options: null
      },
      {
        type: 'job',
        label: 'Job',
        options: null
      }];
	}

	private statusState(status: WorkflowStatus): WorkflowState {
		if (!status) {
			return undefined;
		}
		return this.statusOptions.states.find((descr: WorkflowState) => status.status === descr.value);
	}
}
