import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { DataCopyService } from '@/services/dataCopy.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPurchaseOrderModel, PurchaseOrderModel } from '@/models/purchaseOrder.models';
import { UUIDService } from '@/services/uuid.service';
import { IUserModel } from '@/models/user.models';
import { SupplierService } from '@/services/supplier.service';
import { ISupplierModel } from '@/models/supplier.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { SupplierSortFunctions } from '@/utils/dataManipulationUtils';
import { WorkflowStatus } from '@/models/workflow.models';

@Component({
	selector: 'purchase-order-add',
	templateUrl: './purchaseOrderAdd.template.html',
    host: {'class': 'purchase-order-add-component'}
})
export class PurchaseOrderAddComponent extends BaseSmartComponent implements OnInit {
	// todo refactor or rename so public property isn't prefixed with _
	public _purchaseOrderId: string;
	public model: Observable<IPurchaseOrderModel>;
	public users: Observable<Array<IUserModel>>;
	public suppliers: Subject<Array<ISupplierModel>> = new Subject<Array<ISupplierModel>>();

  // todo validate this type is correct
  public suppliersCopy: Array<ISupplierModel>;

  private _customerId: string;
  private _jobId: string;
	private newLifecycle: Array<WorkflowStatus> = [];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		private purchaseOrderService: PurchaseOrderService,
		private supplierService: SupplierService,
		private dataCopyService: DataCopyService,
		private supplierManager: DataManipulationService<ISupplierModel>,
		messageService: MessageService,
		private filesService: FilesService,
		private uuidService: UUIDService
	) {
			super(messageService);
	}

	ngOnInit(): void {
	  this.model = this.state.getActivePurchaseOrder();
	  this.users = this.state.getUsersList();

	  this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
	  this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
	  this._purchaseOrderId = this.uuidService.generateUUID();

	  this.supplierManager.initialize({}, SupplierSortFunctions);
	  this.watchSubscription(this.supplierManager.connectDataSource(this.state.getSuppliersList(), this.suppliers));
	  this.supplierManager.setSort('name', false);

	  this.supplierService.getSuppliers(0)
      .then(data => { this.suppliersCopy = JSON.parse(JSON.stringify( data )); });

	  let newPurchaseOrder = new PurchaseOrderModel();
    // hack matching copy location address
	  newPurchaseOrder.shipping.address.company = 'Klein-Dickert Milwaukee';
	  newPurchaseOrder.date = this.sanitizeDate(moment().format('YYYY-MM-DD'));
	  newPurchaseOrder.supplier = { _id: null, name: null, status: null, type: null, category:null, location: null};
	  this.state.setActivePurchaseOrder(newPurchaseOrder);

	  this.model.subscribe((s) => {
				this.dataCopyService.copyActiveLocationAddress(s.shipping.address);
		}).unsubscribe();
	}

	public savePurchaseOrder(newModel: IPurchaseOrderModel): void {
		// remove the docs for the save and then add them back in after so view is updated
		let docs = newModel.documents;
		delete newModel.documents;
		delete newModel.css;
		newModel._id = this._purchaseOrderId;
		this.purchaseOrderService.createPurchaseOrder(this._customerId, this._jobId, newModel)
			.then((result) => {
				this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId);
				newModel.documents = docs;
				this.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 1) });

				if (!this._purchaseOrderId) {
					this.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 1) });
				}
			});
	}

	public cancel(): void {
		this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
	}
}
