<table class="table table-select-list" [ngClass]="{'with-indicator':showIndicator}" aria-describedby="workOrdersLabel">
	<thead>
		<tr class="small">
			<th scope="col" class="list-item-date">
				Date
			</th>
			<th scope="col" class="list-item-number">
				Number
			</th>
			<th scope="col" class="list-item-name">
				Name
			</th>
		</tr>
	</thead>
    <tbody>
        <tr *ngFor="let workOrder of model" (click)="selectWorkOrder(workOrder)" [ngClass]="{ 'active' : selectedWorkOrderId === workOrder._id }">
            <td scope="row" class="list-item-date">
                {{ workOrder.date | dateFormat:'MMM DD, YYYY' }}
            </td>
            <td scope="row" class="list-item-number">
                {{ workOrder.number }}
            </td>
            <td scope="row" class="list-item-name">
                {{ workOrder.name }}
            </td>
        </tr>
    </tbody>
</table>
