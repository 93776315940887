import * as Utility from './utility.models';

export interface IWorkOrderPartModel extends Utility.BaseModel {
  quantity: number;
  unit: string;
  cost: number;
  description: string;
  sortOrder: number;
}

export class WorkOrderPartModel extends Utility.BaseModel implements IWorkOrderPartModel {
  quantity: number = null;
  unit: string = null;
  cost: number = null;
  description: string = null;
  sortOrder: number;
}

export interface IPurchaseOrderPartModel extends Utility.BaseModel {
  quantity: number;
  unit: string;
  description: string;
  cost: number;
  sortOrder: number;
}

export class PurchaseOrderPartModel extends Utility.BaseModel implements IPurchaseOrderPartModel {
  quantity: number = null;
  unit: string = null;
  description: string = null;
  cost: number = null;
  sortOrder: number = null;
}

export interface INonContractPartModel {
  sortOrder: number;
  quantity: number;
  unit: string;
  description: string;
  cost: number;
}

export class NonContractPartModel implements INonContractPartModel {
  sortOrder: number = null;
  quantity: number = null;
  unit: string = null;
  description: string = null;
  cost: number = null;

  constructor(index: number, pipeArray: string) {
    let s = pipeArray.split('|');
    this.sortOrder = index;
    this.quantity = Number.parseFloat(s[0]);
    this.unit = s[1];
    this.description = s[2];
    this.cost = Number.parseFloat(s[3]);
  }
}

export interface IPartConnection {
  partIds: Array<string>;
}
