import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { SupplierService } from '@/services/supplier.service';
import { ISupplierModel } from '@/models/supplier.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'supplier-edit',
  templateUrl: './supplierEdit.template.html',
  host: {'class': 'supplier-edit-component'}
})
export class SupplierEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<ISupplierModel>;
  public showAlert: boolean = false;

  private _id: string;
  private _supplierListRoute: ActivatedRoute;
  private duplicateSupplier: ISupplierModel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private state: StateManager,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveSupplier();
    this._id = routerUtils.getRouteParameter(this.route, 'supplierId');

    if (this._id) {
      this.supplierService.getSupplier(this._id)
        .then(supplier => {
          if (!supplier.address.country) {
            supplier.address.country = 'United States';
          }
        })
        .catch(handleError);
    }

    this._supplierListRoute = routerUtils.getParentRoute(this.route, 2);
  }

  public saveSupplier(newModel: ISupplierModel): void {
    this.supplierService.updateSupplier(newModel)
      .then(() => {
        this.showAlert = false;
      })
      .catch((err) => {
        this.showAlert = true;
        let error = err.error;
        this.duplicateSupplier = error.record;
      });
  }

  public cancel(): void {
    this.router.navigate(['/Admin', 'Suppliers']);
  }

  public goToSupplier(): void {
    this.router.navigate(
      ['/Admin', 'Suppliers', this.duplicateSupplier._id, 'Edit', 'Details'],
      { relativeTo: routerUtils.getParentRoute(this.route) }
      );
  }
}
