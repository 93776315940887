<div class="connection-section" *ngIf="!attachedInvoice && _purchaseOrderId">
    <div class="screen-subtitle">Attach to invoice</div>

    <table class="table table-select-list mb-0">
        <caption>
            <div class="searchbox searchbox-fixed">
                <search placeholder="Filter invoices" [(ngModel)]="filterTerm" filterData="text" [filterManager]="dataManipulationService"></search>
            </div>
        </caption>
        <thead>
            <tr>
                <th scope="col" class="date">
                    <sortable-header field="invoiceDate" label="Date" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="number">
                    <sortable-header field="invoiceNumber" label="Number" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="number">
                    <sortable-header field="orderNumber" label="Order Number" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="connection-actions"></th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>

    <div class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">List of receivables</caption>
            <thead class="column-spacer">
                <tr>
                    <th scope="col" class="date"></th>
                    <th scope="col" class="number"></th>
                    <th scope="col" class="number"></th>
                    <th scope="col" class="connection-actions"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of (invoices | async)" (click)="goToInvoice(invoice._id)">
                    <td scope="row" class="date">
                        {{ invoice.invoiceDate | dateFormat:'MMM DD, YYYY' }}
                    </td>
                    <td scope="row" class="number">
                        {{ invoice.invoiceNumber }}
                    </td>
                    <td scope="row" class="number">
                        {{ invoice.orderNumber }}
                    </td>
                    <td scope="row" class="connection-actions">
                        <button type="button" class="btn btn-default" (click)="attachToInvoice(invoice, $event)">Attach</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="connection-section" *ngIf="attachedInvoice && _purchaseOrderId">

	<div>
		<div class="screen-subtitle">Attached to invoice</div>

		<div class="row">
			<div class="col-sm-4 col-md-3 col-lg-2">
				<i class="glyphicon glyphicon-file" style="font-size:7rem;color:#dbdbe8"></i>
			</div>
			<div class="col-sm-8 col-md-9 col-lg-10">
				<div class="wo-attachment-summary">
					<p class="number" (click)="goToInvoice(attachedInvoice._id)" style="text-decoration:underline;cursor:pointer;">
						Invoice# {{ attachedInvoice.invoiceNumber }}
					</p>
					<p class="name">
						{{ attachedInvoice.orderNumber }}
					</p>
					<p class="date">
						{{ attachedInvoice.invoiceDate | dateFormat:'MMM DD, YYYY' }}
					</p>
				</div>

				<button type="button" class="btn btn-default" (click)="detachFromInvoice($event)">Detach</button>
			</div>
		</div>



	</div>


</div>
