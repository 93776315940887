import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilityModule } from './../../utils/utility.module';
import {IMaskModule} from 'angular-imask';
import { ContactEditComponent } from './contactEdit.component';
import { ContactListComponent } from './contactList.component';

@NgModule({
    declarations: [
        ContactEditComponent,
        ContactListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UtilityModule,
        IMaskModule
    ],
    exports: [
        ContactEditComponent,
        ContactListComponent
    ]
})
export class ContactModule {}
