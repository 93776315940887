<div id="list-container" class="container">
<div class="row">
    <div class="col-xs-12">
        <div class="screen-title">
            Labor Codes
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-6">
        <div id="addLaborCodeLabel" class="screen-subtitle pointer" (click)="toggleCreateEntry()">
            Add a labor code
        </div>
    </div>
    <div class="col-xs-6 text-right">
        <div class="toggle" (click)="toggleCreateEntry()">
            <div *ngIf="!showCreateEntry">
                show section <div class="toggle-icon">+</div>
            </div>
            <div *ngIf="showCreateEntry">
                hide section <div class="toggle-icon">-</div>
            </div>
        </div>
    </div>
</div>
<section class="labor-codes">
    <div class="row" *ngIf="showCreateEntry" [@fadeInOut]>
        <div class="col-xs-12 pl-0">
            <table class="table table-select-list no-border no-hover" aria-describedby="addLaborCodeLabel">
                <thead class="spacer">
                    <th scope="col"></th>
					<th scope="col"></th>
					<th scope="col"></th>
                </thead>
                <tbody>
                    <tr [@fadeInOut] *ngFor="let newEntry of newEntries; let i = index" class="new-entry" keyboardAccelerator="13" (onKeyboardAction)="saveEntry(model)">
                        <td scope="row">
                            <label>Code ID</label>
                            <input type="text" class="form-control text-uppercase" [(ngModel)]="newEntry.id" required="required" (keydown)="noSpaces($event)" initialFocus />
                        </td>
                        <td scope="row" class="">
                            <label>Code name</label>
                            <input type="text" class="form-control" required="required" [(ngModel)]="newEntry.name" />
                        </td>
                        <td scope="row">
                            <button *ngIf="i != 0" type="button" class="btn btn-danger" (click)="removeEntry(newEntry)" style="margin-top:26px;"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-xs-12">
            <div (click)="addEntry()" class="add-on">
                <div class="add-icon">+</div><span>Add another labor code</span>
            </div>
            <div class="mt-2 mb-2">
                <button type="button" class="btn btn-primary mr-1" (click)="saveNewEntries()" data-keyboard-exclude>Create code</button><div class="inline-block pseudo-link small capitalize" (click)="clearEntryFields()">Clear fields</div>
                <div *ngIf="showError" class="error-msg mt-2 small">
                    {{errorMsg}}
                </div>
            </div>
        </div>
    </div>
    <div class="hr mt-1 mb-2"></div>
    <div class="sticky-header">
        <div class="screen-subtitle">Labor Codes</div>

        <div class="form-group entry-controls mb-0">
            <div class="row mb-1 entry-control-tab">
                <div class="col-xs-12 small">
                    <span (click)="toggleCopyView()" class="toggle-control" [ngClass]="{'disabled': selectedEntries.length < 1}"><i class="glyphicon glyphicon-circle-arrow-left"></i> Copy selected to other job</span>         
                </div>
            </div>
            <div *ngIf="showCopy && selectedEntries.length > 0" class="entry-control" [@fadeInOut]>
                <label>Job name</label>
                <div class="mb-1">
                    <ng-select 
                        class="ng-select-component select-required"
                        [clearable]="faslse"
                        [items]="formattedJobs"
                        [disabled]="disabled"
                        [ngModel]="selectedJobs"
                        [ngClass]="{'error': invalid && required}"
                        [multiple]=true
                        placeholder="Select"
                        bindLabel="text"
                        (remove)="setJob($event)"
                        (add)="setJob($event)"
                        #selectField
                        required>
                    </ng-select>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <button (click)="copyEntries()" class="btn btn-primary mr-1 mb-1" [ngClass]="{'disabled': selectedJobs.length < 1}">Copy</button>
                        <div class="inline-block pseudo-link small capitalize" (click)="cancelMove()">Cancel</div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="attached">
        <table *ngIf="pastEntries && pastEntries.length > 0" class="table table-select-list no-border no-hover">
            <caption class="sr-only">List of labor codes</caption>
            <thead>
                <tr class="shaded">
                    <th scope="col" class="checkbox-container">
                        <label class="custom-checkbox" style="top:-6px;">
                            <input type="checkbox" [ngModel]="selectAll" (change)="toggleSelectAll()">
                            <span class="checkmark"></span>
                        </label>
                    </th>
                    <th scope="col" class="code-id">
                        <sortable-header field="id" label="Code ID" [sortManager]="dataManipulationService" [useLocalSort]="true" [sortDescending]="sortDirection" (onSort)="sortEntries($event)" ></sortable-header>
                    </th>
                    <th scope="col" class="code-name">
                        <sortable-header field="name" label="Code Name" [sortManager]="dataManipulationService" [useLocalSort]="true" [sortDescending]="sortDirection" (onSort)="sortEntries($event)"></sortable-header>
                    </th>
                    <th scope="col" class="code-title">
                        <sortable-header field="title" label="Code Title" [sortManager]="dataManipulationService" [useLocalSort]="true" [sortDescending]="sortDirection" (onSort)="sortEntries($event)"></sortable-header>
                    </th>
                    <th scope="col" class="buttons"></th>
                </tr>
            </thead>
            <tbody *ngFor="let entry of pastEntries;trackBy: trackById">
                <ng-template [ngIf]="entry._id === editingEntryId">
                    <tr>
                        <td scope="row" class="checkbox-container">
                            <label class="custom-checkbox" style="top:-4px;">
                                <input type="checkbox" [(ngModel)]="entry.selected" [checked]="entry.selected" (change)="updateSelected(entry)">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td scope="row" class="code-id">
                            <input type="text" class="form-control"  [(ngModel)]="entry.id" required="required" />
                        </td>
                        <td scope="row" class="code-name">
                            <input type="text" class="form-control" [(ngModel)]="entry.name" required="required" />
                        </td>
                        <td scope="row" class="code-title">
                            <div>{{entry.id}} - {{entry.name}}</div>
                        </td>
                        <td scope="row" class="buttons">
                            <button type="button" class="btn btn-primary mb-1 save" [disabled]="!entry.id || !entry.name" (click)="saveEntry(entry)">Save</button>
                            <div class="inline-block pseudo-link small ml-1" (click)="cancel($event)" data-keyboard-exclude>Cancel</div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="entry._id !== editingEntryId">
                    <tr>
                        <td scope="row" class="checkbox-container">
                            <label class="custom-checkbox" style="top:-4px;">
                                <input type="checkbox" [(ngModel)]="entry.selected" [checked]="entry.selected" (change)="updateSelected(entry)">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td scope="row" class="code-id" (click)="editTimeEntry(entry, $event)">
                            {{ entry.id }}
                        </td>
                        <td scope="row" class="code-name" (click)="editEntry(entry, $event)">
                            {{ entry.name }}
                        </td>
                        <td scope="row" class="code-title" (click)="editEntry(entry, $event)">
                            {{entry.id}} - {{entry.name}}
                        </td>
                        <td scope="row" class="actions buttons">
                            <button type="button" class="btn btn-default" (click)="editEntry(entry, $event)">Edit</button>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
        <div *ngIf="!pastEntries || pastEntries.length < 1" class="text-center large-text light mt-3">
                No results
        </div>
    </div>
</section>
</div>
