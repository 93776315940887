import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from './../base.component';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';

import { IJobChangeOrderSummaryModel } from '@/models/job.models';
@Component({
    selector: 'job-change-order-summary',
    templateUrl: './jobChangeOrderSummary.template.html',
    host: {'class': 'job-change-order-summary-component'}
})

export class JobChangeOrderSummaryComponent extends BaseSmartComponent implements OnInit {
    public model : IJobChangeOrderSummaryModel;

    constructor(private state : StateManager, private jobService : JobService, private route : ActivatedRoute, messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.watchSubscription(self.state.getActiveJob().subscribe((s) => {
            let changeOrderTotal = 0;

            for(let changeOrder of s.changeOrders) {
                changeOrderTotal += changeOrder.amount;
            }

            self.model = {
                originalContract: s.contractAmount,
                changeOrderAmount: changeOrderTotal
            } as IJobChangeOrderSummaryModel;
        }));
    }
};
