import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, from, merge } from 'rxjs';
import { filter, scan } from 'rxjs/operators';
import { Location } from '@angular/common';

export type NavigationTrigger = 'imperative' | 'popstate' | 'hashchange';

interface HistoryEntry {
  id: number;
  url: string;
  state: any;
}

interface RouterHistory {
  history: HistoryEntry[];
  currentIndex: number;

  event: NavigationStart | NavigationEnd;
  trigger: NavigationTrigger;
  id: number;
  idToRestore: number;
}

class ChangeState {
  state: any;
}

/**
 * Based on https://semanticbits.com/route-history-service-in-angular/
 */
@Injectable({ providedIn: 'root' })
export class RouterHistoryService {
  public currentState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // emit changes to the history to merge with the navigation events. This avoids an issue with the extra events clearing history
  protected changeEmitter: EventEmitter<ChangeState> = new EventEmitter<ChangeState>();

  constructor(protected router: Router, protected location: Location) {
    merge(router.events, from(this.changeEmitter))
      .pipe(
        // only include NavigationStart and NavigationEnd events
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof ChangeState),
        scan<NavigationStart | NavigationEnd | ChangeState, RouterHistory>(
          (acc, event) => {
            if (event instanceof NavigationStart) {
              return this.processNavigationStart(acc, event);
            } else if (event instanceof ChangeState) {
              if (acc.currentIndex < acc.history.length) {
                acc.history[acc.currentIndex].state = event.state;
              }
              return acc;
            }

            // on NavigationEnd events
            const history = [...acc.history];
            let currentIndex = acc.currentIndex;
            let state = window.history.state;

            // router events are imperative (router.navigate or routerLink)
            if (acc.trigger === 'imperative' || !acc.trigger) {
              // remove all events in history that come after the current index
              history.splice(currentIndex + 1);

              // add the new event to the end of the history and set that as our current index
              history.push({ id: acc.id, url: event.urlAfterRedirects, state: state });
              currentIndex = history.length - 1;
            } else if (acc.trigger === 'popstate') { // browser events (back/forward) are popstate events
              // get the history item that references the idToRestore
              const idx = history.findIndex(x => x.id === acc.idToRestore);

              // if found, set the current index to that history item and update the id
              if (idx > -1) {
                currentIndex = idx;
                history[idx].id = acc.id;
              } else {
                currentIndex = 0;
              }
            }

            return {
              ...acc,
              event,
              history,
              currentIndex
            };
          },
          {
            event: null,
            history: [],
            trigger: null,
            id: 0,
            idToRestore: 0,
            currentIndex: 0
          }
        ),
        // filter out so we only act when navigation is done
        filter(({ event, trigger }) => event instanceof NavigationEnd && !!trigger)
      )
      .subscribe(({ history, currentIndex }) => {
        const current = history[currentIndex];
        this.currentState$.next(current?.state);
      });
  }

  /**
   * Updates the state on the current url.
   * @param state
   */
  public changeState(state: any): void {
    const ev: ChangeState = new ChangeState();
    ev.state = state;
    this.changeEmitter.emit(ev);
  }

  private processNavigationStart(acc: RouterHistory, event: NavigationStart): RouterHistory {
    if (acc.history[acc.currentIndex] && acc.history[acc.currentIndex].url === event.url) {
      // handle reloaded navigation that happens when onSameUrlNavigation: 'reload'
      acc.history[acc.currentIndex].id = event.id;
      acc.id = event.id;
      // acc.idToRestore = (event.restoredState && event.restoredState.navigationId) || undefined;
      return acc;
    }

    // we need to track the trigger, id, and idToRestore from the NavigationStart events
    return {
      ...acc,
      event,
      trigger: event.navigationTrigger,
      id: event.id,
      idToRestore: (event.restoredState && event.restoredState.navigationId) || undefined
    };
  }
}
