<form class="pb-3" (ngSubmit)="saveUser()" #userForm="ngForm" autocomplete="off">
    <input type="hidden" value="{{ model._id }}" />

    <fieldset>
        <legend class="sr-only">
            User Information
        </legend>
        
        <div class="form-group">
            <label>User Name</label>
            <div *ngIf="model._id" class="control-label">
                {{ model.username }}
            </div>
            <div *ngIf="!model._id">
                <input class="form-control" type="text" [(ngModel)]="model.username" name="username" required="required" #username="ngModel" />
                <div class="alert alert-danger" [hidden]="(!userForm.form.submitted && username.pristine) || username.valid">
                    User name is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>First Name</label>
            <div class="">
                <input class="form-control" type="text" [(ngModel)]="model.firstName" name="firstName" required="required" #firstName="ngModel" />
                <div class="alert alert-danger" [hidden]="(!userForm.form.submitted && firstName.pristine) || firstName.valid">
                    First name is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Last Name</label>
            <div class="">
                <input class="form-control" type="text" [(ngModel)]="model.lastName" name="lastName" required="required" #lastName="ngModel" />
                <div class="alert alert-danger" [hidden]="(!userForm.form.submitted && lastName.pristine) || lastName.valid">
                    Last name is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Role</label>
            <div class="">
                <select class="form-control" [(ngModel)]="model.role" name="roles" required="required" #roles="ngModel">
                    <option *ngFor="let role of userRoles" value="{{role.id}}">{{role.label}}</option>
                </select>
                <div class="alert alert-danger" [hidden]="(!userForm.form.submitted && roles.pristine) || roles.valid">
                    Role is required
                </div>
            </div>
        </div>

        <div class="form-group">
            <table *ngIf="model.locations && model.locations.length > 0">
                <thead>
                    <tr>
                        <th scope="col">
                            <label>Location</label>
                        </th>
                        <th scope="col">
                            <label *ngIf="model.locations.length > 1">Default</label>
                        </th>
                        <th scope="col">
                            <label>Active</label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let location of model.locations">
                        <td scope="row">
                            <label class="custom-checkbox" *ngIf="model.locations.length > 1">
                                <input type="checkbox" name="access-{{location.id}}" [checked]="location.hasAccess" [(ngModel)]="location.hasAccess"/> {{location.name}}
                                <span class="checkmark"></span>
                            </label>
                            <label *ngIf="model.locations.length === 1">{{location.name}}</label>
                        </td>
                        <td scope="row">
                            <label class="custom-radio" *ngIf="model.locations.length > 1">
                                <input type="radio" name="default" [checked]="location.isDefault" [value]="location.id"  [(ngModel)]="defaultLocation" (change)="setDefault(location.id)">
                                <span class="radiobtn"></span>
                            </label>
                        </td>
                        <td scope="row">
                            <label class="custom-checkbox">
                                <input type="checkbox" name="active-{{location.id}}" [checked]="location.isActive" [(ngModel)]="location.isActive"/>
                                <span class="checkmark"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="form-group">
            <label>Password</label>
            <div class="">
                <input class="form-control" type="password" [(ngModel)]="model.password" name="password" />
            </div>
        </div>
    </fieldset>

    <div>
        <button class="btn btn-primary mr-1" type="submit" [disabled]="!userForm.form.valid">Save</button>
        <div class="inline-block pseudo-link small" (click)="cancel()">Cancel</div>
    </div>
</form>
