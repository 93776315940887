import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

export interface IDebugState {
	header : string;
}

const defaultState = {
	header: null as string
};

export const DEBUG_ACTIONS = {
	SET_DEBUG_HEADER: 'SET_DEBUG_HEADER'
};

const debugReducer = function(state: IDebugState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case DEBUG_ACTIONS.SET_DEBUG_HEADER:
			newState.header = action.payload;
			break;
	}

	return newState;
};

export function getDebugHeader() {
	return (state : Store<IDebugState>) => {
		return state.select(s => {
			return s.header;
		});
	};
}

export function reducer(state: IDebugState | undefined, action: Action) {
	return debugReducer(state, action);
}
