import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {IMaskModule} from 'angular-imask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { InvoiceComponent } from './invoice.component';
import { InvoiceConnectionComponent } from './invoiceConnection.component';
import { InvoiceEditComponent } from './invoiceEdit.component';
import { InvoiceListComponent } from './invoiceList.component';
import { InvoiceSearchComponent } from './invoiceSearch.component';
import { InvoiceDocumentsComponent } from './invoiceDocuments.component';

import { AttachedToInvoicePipe } from './../../utils/attachedToInvoice.pipe';

import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { UtilityModule } from '@/utils/utility.module';
import { WorkflowModule } from '@/components/Workflow/workflow.module';
import { FilterListModule } from '@/components/FilterListComponent/filterList.module';

@NgModule({
	declarations: [
		InvoiceComponent,
		InvoiceConnectionComponent,
		InvoiceEditComponent,
		InvoiceListComponent,
		InvoiceSearchComponent,
		AttachedToInvoicePipe,
		InvoiceDocumentsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		UtilityModule,
		InfiniteScrollModule,
		RouterModule,
		DocumentUploadModule,
		WorkflowModule,
		FilterListModule,
		IMaskModule
	],
	exports: [
		InvoiceComponent,
		InvoiceConnectionComponent,
		InvoiceEditComponent,
		InvoiceListComponent,
		InvoiceSearchComponent,
		InvoiceDocumentsComponent
	]
})
export class InvoiceModule {}
