import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { WorkflowDisplayStatus, WorkflowFile } from '@/models/workflow.models';
import { EnvironmentService } from '@/services/EnvironmentService';
import { ExportDocumentService } from '@/services/exportDocument.service';
import { FilesService } from '@/services/files.service';

@Component({
	selector: 'status-log',
	template: `
	<div *ngIf="lifecycle.length">
		<label class="control-label">Status Log</label>
	    <div class="row log-note small" *ngFor="let status of lifecycle">
			<div class="col-xs-4 font-bold">{{status.status.label}}</div>
			<div class="col-xs-4">
				<div>
					{{status.note}}
				</div>
				<div *ngIf="status.attachments">
					<div *ngFor="let fileLink of status.attachments">
						<a style="cursor: pointer;" (click)="openExportedPDf(fileLink)">{{fileLink.filename}}</a>
					</div>
				</div>
			</div>
			<div class="col-xs-4 text-right timestamp">{{status.timestamp | dateFormat:'MMM DD, YYYY' }}</div>
		</div>
	</div>
	`,
    host: {'class': 'status-log-component'}
})
export class StatusLogComponent {
	@Input()
	set lifecycle(value: WorkflowDisplayStatus[]) {
		this._lifecycle = this.sortLog(value, true);
		this._lifecycle = value;
	}

	get lifecycle(): WorkflowDisplayStatus[] {
		if (this._lifecycle != null) {
			return this._lifecycle;
		}
		return [];
	}

	private _lifecycle: WorkflowDisplayStatus[];

	constructor(
		private env: EnvironmentService, 
		public exportService: ExportDocumentService,
		public filesService: FilesService) { }

  public openExportedPDf(attachment: WorkflowFile): void {
    this.exportService.getExportedFile(this.env.APIBASEURL + '/files/' + attachment.path)
			.subscribe((res) => {
        if (res.status === 200) {
          const filename = attachment.filename;
          const file = new File([res.body], filename, {
            type: 'application/pdf',
          });
          const fileUrl = URL.createObjectURL(file);
					const link = document.createElement('a');
					document.body.appendChild(link);
					link.style.display = 'none';
					link.href = fileUrl;
					link.target = '_blank';
					link.click();
					window.URL.revokeObjectURL(fileUrl);
        }
      });
  }

	private sortLog(lifecycle: WorkflowDisplayStatus[], desc: boolean): WorkflowDisplayStatus[] {
		if (lifecycle == null || lifecycle.length === 0) {
			return [];
		}

		const sorted = lifecycle.sort((a, b) => a.timestamp > b.timestamp ? 1 : a.timestamp === b.timestamp ? 0 : -1);
		// asc/desc
		if (desc) { sorted.reverse(); }
		return sorted;
	}
}
