<div id="filter-list">
    <div class="row filters entry-control-content form-group mb-0" [@fadeInOut]>
        <div *ngFor="let option of filterOptions; let i = index" class="col-xs-12 col-sm-6 col-md-6 {{filterWidth}}">
            <label>{{option.label}}</label>
            <div>
                <ng-select
                    class="ng-select-component hasmodel {{option.type}} {{option.type==='projectManager' && (userRole === 10) ? 'disabled' : ''}}"
                    *ngIf="option.preFilter && (option.type != 'date')"
                    [clearable]="option.label!='Status'"
                    [multiple]="option.label=='Status'"
                    [items]="option.options"
                    [(ngModel)]="((option.type==='projectManager') && (userRole === 10 || userRole === 20 || userRole === 5)) ? option.preFilter[0] : option.preFilter"
                    bindLabel="text"
                    (change)="filterOn($event, option.type, option.label=='Status')"
                    placeholder="Select"
                    id="{{option.type}}Filter"
                    #selectField
                    >
                </ng-select>
                <ng-select
                    class="ng-select-component nomodel {{option.type}} {{option.type==='projectManager' && (userRole === 10) ? 'disabled' : ''}}"
                    *ngIf="!option.preFilter && (option.type != 'date')"
                    [clearable]="option.label!='Status'"
                    [multiple]="option.label=='Status'"
                    [items]="option.options"
                    bindLabel="text"
                    (change)="filterOn($event, option.type, option.label=='Status')"
                    placeholder="Select"
                    id="{{option.type}}Filter"
                    #selectField
                    >
                </ng-select>
                <input *ngIf="option.type === 'date'"
                    class="form-control" 
                    type="date"
                    [value]="option.preFilter ? option.preFilter : null"
                    (change)="datePostedFilter();"
                    (focus)="clearDate()"
                    id="{{option.type}}Filter"
                    #dateFilter 
                />
            </div>
        </div>
    </div>
</div>
