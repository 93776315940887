<form class="" (ngSubmit)="saveUser()" #userForm="ngForm" autocomplete="off">
	<input type="hidden" value="{{ model._id }}" />

	<fieldset>
		<legend>
			Your Profile
		</legend>

		<div class="form-group">
			<label>User Name</label>
			<div class="" *ngIf="model._id" style="text-align:left;">
				{{ model.username }}
			</div>
		</div>

		<div class="form-group">
			<label>First Name</label>
			<div class="">
				<input class="form-control" type="text" [(ngModel)]="model.firstName" name="firstName" required="required" #firstName="ngModel" />
				<div class="alert alert-danger" [hidden]="(!userForm.form.submitted && firstName.pristine) || firstName.valid">
					First name is required
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Last Name</label>
			<div class="">
				<input class="form-control" type="text" [(ngModel)]="model.lastName" name="lastName" required="required" #lastName="ngModel" />
				<div class="alert alert-danger" [hidden]="(!userForm.form.submitted && lastName.pristine) || lastName.valid">
					Last name is required
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Password</label>
			<div class="">
				<input class="form-control" type="password" [(ngModel)]="model.password" name="password" />
				<div class="note" style="font-size:0.7em;color:orange; margin-top:5px;">(Leave blank if you don't want to change your password)</div>
			</div>
		</div>
	</fieldset>

	<div class="">
		<button class="btn btn-primary" type="submit" [disabled]="!userForm.form.valid">Save</button>
		<div class="inline-block pseudo-link small capitalize ml-2" (click)="cancel()">Cancel</div>
	</div>
</form>
