/**
 * Created by rrahlf on 7/22/2016.
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@/services/auth.service';
import { LocationService } from '@/services/location.service';
import { MessageService } from '@/services/message.service';
import { LocalStorageService } from '@/services/localStorage.service';
import { EnvironmentService } from '@/services/EnvironmentService';
import { IAuthUser, UserRole } from '@/models/auth.models';
import { BaseSmartComponent } from './../base.component';

@Component({
	selector: 'account-info',
	templateUrl: './accountInfo.template.html',
	host: {'class': 'account-info-component'}
})
export class AccountInfoComponent extends BaseSmartComponent {
	public model: IAuthUser;
	public toastId: number;
	public jobSource: string;
	public isShopForeman: Boolean = false;
	public isAdmin: Boolean = false;
	public isPresident: Boolean = false;
	public isVicePresident: Boolean = false;

	constructor(
		private authService: AuthService,
		private router: Router,
		messageService: MessageService,
		private locationService: LocationService,
		private localStorageService: LocalStorageService,
		private env: EnvironmentService,
	) {
		super(messageService);
		this.authService.currentUser
			.subscribe((user) => this.setUser(user));
	}

	logout(): void {
		this.authService.logout();
	}

	setUser(user: IAuthUser): void {
		this.model = user;
		if (this.model) {
			// set default job source to user's location
			this.setJobSource((this.model.locationId === this.env.MADISON_ID ? 'Madison' : 'Pewaukee'));
			this.isShopForeman = this.model.role === UserRole.ShopForeman;
			this.isAdmin = this.model.role === UserRole.Admin;
			this.isPresident = this.model.role === UserRole.President;
			this.isVicePresident = this.model.role === UserRole.VicePresident;
		}
	}

  async changeJobSource(src: string): Promise<void> {
    const locationId = this.locationIdFromName(src);
		await this.authService.switchLocation(locationId);

		if (this.router.url.includes('Customers') && this.router.url.includes('/Job/')) {
			this.router.navigate(['/Jobs']);
		}
  }

	private setJobSource(src: string): void {
    this.localStorageService.setItem('jobSource', JSON.stringify({ name: src, id: this.locationIdFromName(src) }));
    this.jobSource = src;
    if (this.isAdmin || this.isPresident || this.isVicePresident) {
      this.locationService.notifyLocationChanged('Now viewing ' + src + ' jobs.');
    }
	}

  private locationIdFromName(name: string): string {
    return (name  === 'Madison' ? this.env.MADISON_ID : this.env.PEWAUKEE_ID);
  }
}
