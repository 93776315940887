import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IPurchaseOrderModel } from './../../models/purchaseOrder.models';

@Component({
    selector: 'purchase-order-list',
    templateUrl: './purchaseOrderList.template.html',
    host: {'class': 'purchase-order-list-component', id: 'sidebar-content'}
})

export class PurchaseOrderListComponent {
    @Input()
    model = new Array<IPurchaseOrderModel>();

    @Input()
    selectedPurchaseOrderId : string = null;

    @Input()
	showIndicator: boolean = false;

    @Output()
    onPurchaseOrderSelect = new EventEmitter<IPurchaseOrderModel>();

    selectPurchaseOrder(purchaseOrder: IPurchaseOrderModel) {
        this.onPurchaseOrderSelect.emit(purchaseOrder);
    }
}
