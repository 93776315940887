

<section class="form-add">
	<div class="form-horizontal">
		<div class="form-group">
			<div class="col-xs-12 col-md-8">
				<label>Title</label>
				<div class="">
					<input autocomplete="off" class="form-control" type="text" #title="ngModel" [(ngModel)]="newNote.title" required="required" />
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="col-xs-12 col-md-8">
				<label>Instructions</label>
				<div class="">
					<textarea rows="3" class="form-control" #note="ngModel" [(ngModel)]="newNote.note" required="required"></textarea>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-8">
				<button type="button" class="btn btn-primary" [disabled]="!title.valid || !note.valid" (click)="saveNote(newNote, true)">Create</button>
			</div>
		</div>
	</div>
</section>

<hr>

<section class="pm-notes-list-container row" style="max-width:1000px">
	<ul class="pm-notes-list col-xs-12">
		<li class="pm-note" *ngFor="let note of model | async" (click)="editNote(note)" keyboardAccelerator="13" style="position: relative;">
			<div class="form-horizontal col-xs-12" *ngIf="note._id === editingNoteId" [@fadeInOut] style="position: relative;">
				<div class="form-group">
					<div class="col-xs-12 col-md-8">
						<label>Title</label>
						<div class="">
							<input autocomplete="off" class="form-control" type="text" #editTitle="ngModel" [(ngModel)]="note.title" required="required" />
						</div>
					</div>

				</div>
				<div class="form-group">
					<div class="col-xs-12 col-md-8">
						<label>Notes</label>
						<div class="">
							<notes-field [(model)]="note.notes" [saved]="saved" [required]="true"></notes-field>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12 col-md-8 text-right">
						<button type="button" class="btn btn-primary mr-1" [disabled]="!editTitle.valid || !note.notes" (click)="saveNote(note, false)" data-keyboard-exclude>Save</button>
						<div class="inline-block pseudo-link small capitalize" (click)="cancel($event)" data-keyboard-exclude>Cancel</div>
					</div>
				</div>
			</div>

			<div *ngIf="note._id !== editingNoteId" [@fadeInOut] style="position: relative;">
				<div class="col-xs-12 col-sm-11">
					<div class="pm-note-title">
						{{ note.title }}
					</div>
					<div class="actions">
						<button type="button" class="btn btn-danger mr-1" (click)="deleteNote(note, $event)"><i class="glyphicon glyphicon-trash" title="Delete" data-keyboard-exclude></i></button>
						<button type="button" class="btn btn-default" (click)="editNote(note, $event)">Edit</button>
					</div>
					<div class="pm-note-body">
						<pre class="pa-2" [innerHTML]="note.note"></pre>
					</div>
				</div>
			</div>
		</li>
	</ul>
</section>
