import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { BaseSmartComponent } from './../base.component';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';

import { MessageService } from '@/services/message.service';
import { AuthService } from '@/services/auth.service';
import { LocationService } from '@/services/location.service';

import { UserRole } from '@/models/auth.models';
import { ILocationModel } from '@/models/location.models';
@Component({
    selector:'location-edit',
    templateUrl: './locationEdit.template.html',
    host: {'class': 'location-edit-component'}
})

export class LocationEditComponent extends BaseSmartComponent implements OnInit {
    private _id : string;
    private _locationListRoute : ActivatedRoute;
    public model : Observable<ILocationModel>;
    public showAlert: boolean = false;
    private duplicateLocation: ILocationModel;

	public user: Object;
	public userSub: Subscription;
	public isAdmin: boolean = false;
	public role: number;
	public permissionLock: boolean = false;

    constructor(
        messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private locationService : LocationService,
        private state: StateManager,
		private authService: AuthService
		) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.model = self.state.getActiveLocation();
        self._id = routerUtils.getRouteParameter(self.route, 'locationId');

        if(self._id) {
            self.locationService.getLocation(self._id);
        }

        self._locationListRoute = routerUtils.getParentRoute(self.route);

        // get user's role
        self.userSub = self.authService.currentUser.subscribe(s => {
            if (s) {
                self.user = s;
                self.role = s.role;
                if (self.role === UserRole.Admin) {
                    self.isAdmin = true;
                } else {
                    self.isAdmin = false;
                    self.permissionLock = true;
                }
            }
        });
    }

    public saveLocation(newModel: ILocationModel) : void {
        const self = this;

        self.locationService.updateLocation(newModel)
            .then(() => {
                this.showAlert = false;
                self.router.navigate(['.'], { relativeTo: self._locationListRoute });
            })
            .catch((err) => {
                this.showAlert = true;
                let error = err.error;
                this.duplicateLocation = error.record;
            });
    }

    public cancel() : void {
        this.router.navigate(['.'], { relativeTo: this._locationListRoute });
    }

    public goToLocation(): void {
        this.router.navigate(
          ['/Admin', 'Locations', this.duplicateLocation._id, 'Edit'],
          { relativeTo: routerUtils.getParentRoute(this.route) }
          );
          // angular2 router does not reload components when using same route with different params so reload the page
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
}
