import { Injectable, NgZone } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ReducerObservable, Store, StoreModule } from '@ngrx/store';
import { compose } from '@ngrx/store';
// import 'rxjs/add/operator/let';

import '@/utils/rxjs-operators';

import { DEBUG_ACTIONS, IDebugState, getDebugHeader, reducer as debugReducer } from './reducers/debug.reducer';

import { AUTH_ACTIONS, IAuthState, getExpiration, getCurrentUser, reducer as authReducer } from './reducers/auth.reducer';
import { IAuthUser } from '@/models/auth.models';

import { LOCATION_ACTIONS, ILocationState, getActiveLocation, getLocationsList, reducer as locationReducer } from './reducers/location.reducer';
import { ILocationModel } from '@/models/location.models';

import { CUSTOMER_ACTIONS, ICustomerState, getCustomersList, getActiveCustomer, reducer as customerReducer } from './reducers/customer.reducer';
import { ICustomerModel } from '@/models/customer.models';

import { SUPPLIER_ACTIONS, ISupplierState, getActiveSupplier, getSuppliersList, reducer as supplierReducer } from './reducers/supplier.reducer';
import { ISupplierModel } from '@/models/supplier.models';

import { CONTACT_ACTIONS, IContactState, getActiveContact, getContactsList, reducer as contactReducer } from './reducers/contact.reducer';
import { IContactModel } from '@/models/contact.models';

import { STAFF_ACTIONS, IStaffState, getActiveStaff, getStaffList, reducer as staffReducer } from './reducers/staff.reducer';
import { IStaffModel } from '@/models/staff.models';

import { USER_ACTIONS, IUserState, getLocationSelectedUser, getLocationUsersList, getPMList, getSelectedUser, getUsersList, reducer as usersReducer } from './reducers/users.reducer';
import { IUserModel } from '@/models/user.models';

import { JOB_ACTIONS, IJobState, ILaborCodeState, getActiveJob, getJobsList, getLaborCodesList, reducer as jobReducer } from './reducers/job.reducer';
import { IJobModel, ILaborCodeModel } from '@/models/job.models';

import { WORKORDER_ACTIONS, IWorkOrderState, getActiveWorkOrder, getWorkOrdersList, reducer as workOrderReducer } from './reducers/workOrder.reducer';
import { IWorkOrderModel } from '@/models/workOrder.models';

import { PURCHASEORDER_ACTIONS, IPurchaseOrderState, getActivePurchaseOrder, getPurchaseOrdersList, reducer as purchaseOrderReducer } from './reducers/purchaseOrder.reducer';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';

import { CHANGEORDER_ACTIONS, IChangeOrderState, getActiveChangeOrder, getChangeOrdersList, reducer as changeOrderReducer } from './reducers/changeOrder.reducer';
import { IChangeOrderModel } from '@/models/changeOrder.models';

import { INVOICE_ACTIONS, IInvoiceState, getActiveInvoice, getInvoicesList, getInvoiceSummaryList, reducer as invoiceReducer } from './reducers/invoice.reducer';
import { IInvoiceModel, IInvoiceSummary } from '@/models/invoice.models';

import { PAYABLE_ACTIONS, IPayableState, getActivePayable, getPayablesList, getPayableSummaryList, reducer as payableReducer } from './reducers/payable.reducer';
import { IPayableModel, IPayableSummary } from '@/models/payable.models';

import { PMNOTE_ACTIONS, IPMNoteState, getActivePMNote, getPMNotesList, reducer as pmNoteReducer } from './reducers/pmNote.reducer';
import { IPMNoteModel } from '@/models/pmNote.models';

import { PART_ACTIONS, IPartState, getPurchaseOrderPartsList, getWorkOrderPartsList, reducer as partReducer } from './reducers/part.reducer';
import { IWorkOrderPartModel, IPurchaseOrderPartModel } from '@/models/part.models';

import { COST_ACTIONS, ICostState, getCostsList, reducer as costReducer } from './reducers/cost.reducer';
import { ICostModel } from '@/models/cost.models';

import { RECEIPT_ACTIONS, IReceiptState, getReceiptsList, reducer as receiptReducer } from './reducers/receipt.reducer';
import { IReceiptModel } from '@/models/receipt.models';

import { TIMEENTRY_ACTIONS, ITimeEntryState, getActiveTimeEntry, getTimeEntriesList, reducer as timeEntryReducer } from './reducers/timeEntry.reducer';
import { ITimeEntryModel } from '@/models/timeEntry.models';

interface IAppState {
	debug: IDebugState,
	auth: IAuthState,
	location: ILocationState,
	customer: ICustomerState,
	supplier: ISupplierState,
	contact: IContactState,
	staff: IStaffState,
	users: IUserState,
	job: IJobState,
	laborCode: ILaborCodeState,
	workOrder: IWorkOrderState,
	purchaseOrder: IPurchaseOrderState,
	changeOrder: IChangeOrderState,
	invoice: IInvoiceState,
	payable: IPayableState,
	pmNote: IPMNoteState,
	part: IPartState,
	receipt: IReceiptState,
	cost: ICostState,
	timeEntry: ITimeEntryState,
}

export const STORE_PROVIDER = StoreModule.forRoot({
	debug: debugReducer,
	auth: authReducer,
	location: locationReducer,
	customer: customerReducer,
	supplier: supplierReducer,
	contact: contactReducer,
	staff: staffReducer,
	users: usersReducer,
	job: jobReducer,
	laborCode: jobReducer,
	workOrder: workOrderReducer,
	purchaseOrder: purchaseOrderReducer,
	changeOrder: changeOrderReducer,
	invoice: invoiceReducer,
	payable: payableReducer,
	pmNote: pmNoteReducer,
	part: partReducer,
	receipt: receiptReducer,
	cost: costReducer,
	timeEntry: timeEntryReducer,
}, 
{
	runtimeChecks: {
		strictStateImmutability: false,
		strictActionImmutability: false,
	}
});

@Injectable()
export class StateManager {
	private bc: BroadcastChannel;

	constructor(private store: Store<IAppState>, private ngZone: NgZone) {
		this.bc = new BroadcastChannel('test_channel');
		this.bc.onmessage = (exp) => ngZone.run(() => this.setExpiration(exp.data, true));
	}

	// returns _observable_ of state object
	select<R>(pathOrMapFn: any, ...paths: string[]): Observable<R> {
		// TODO Confirm ...paths refactor works after angular cli switch
		return this.store.pipe.apply((map(pathOrMapFn, (paths)))) as Observable<R>;
	}

	// <editor-fold> Authentication

	getCurrentUser(): Observable<IAuthUser> {
	return this.store.pipe(compose(getCurrentUser(), getAuthState()));
	}

	getExpiration(): Observable<Date> {
		return this.store.pipe(compose(getExpiration(), getAuthState()));
	}

	setCurrentUser(user: IAuthUser): void {
		this.store.dispatch({ type: AUTH_ACTIONS.SET_CURRENT_USER, payload: user });
	}

	setExpiration(expire: Date, fromBroadcast?: boolean): void {
		if (!fromBroadcast) {
			this.bc.postMessage(expire);
		}

		this.store.dispatch({ type: AUTH_ACTIONS.SET_EXPIRATION, payload: expire });
	}

	// </editor-fold>

	// <editor-fold> Locations

	getLocationsList() : Observable<Array<ILocationModel>> {
		return this.store.pipe(compose(getLocationsList(), getLocationState()));
	}

	getActiveLocation() : Observable<ILocationModel> {
		return this.store.pipe(compose(getActiveLocation(), getLocationState()));
	}

	setLocationsList(locations: Array<ILocationModel>) : void {
		this.store.dispatch({ type: LOCATION_ACTIONS.SET_LOCATIONS_LIST, payload: locations });
	}

	setActiveLocation(location: ILocationModel) : void {
		this.store.dispatch({ type: LOCATION_ACTIONS.SET_ACTIVE_LOCATION, payload: location });
	}

	// </editor-fold>

	// <editor-fold> Customers

	getCustomersList() : Observable<Array<ICustomerModel>> {
		return this.store.pipe(compose(getCustomersList(), getCustomerState()));
	}

	getActiveCustomer() : Observable<ICustomerModel> {
		return this.store.pipe(compose(getActiveCustomer(), getCustomerState()));
	}

	setCustomersList(customers: Array<ICustomerModel>) : void {
		this.store.dispatch({ type: CUSTOMER_ACTIONS.SET_CUSTOMERS_LIST, payload: customers });
	}

	setActiveCustomer(customer: ICustomerModel) : void {
		this.store.dispatch({ type: CUSTOMER_ACTIONS.SET_ACTIVE_CUSTOMER, payload: customer });
	}

	// </editor-fold>

	// <editor-fold> Suppliers

	getSuppliersList() : Observable<Array<ISupplierModel>> {
		return this.store.pipe(compose(getSuppliersList(), getSupplierState()));
	}

	getActiveSupplier() : Observable<ISupplierModel> {
		return this.store.pipe(compose(getActiveSupplier(), getSupplierState()));
	}

	setSuppliersList(suppliers: Array<ISupplierModel>) : void {
		this.store.dispatch({ type: SUPPLIER_ACTIONS.SET_SUPPLIERS_LIST, payload: suppliers });
	}

	setActiveSupplier(supplier: ISupplierModel) : void {
		this.store.dispatch({ type: SUPPLIER_ACTIONS.SET_ACTIVE_SUPPLIER, payload: supplier });
	}

	// </editor-fold>

	// <editor-fold> Contacts

	getContactsList() : Observable<Array<IContactModel>> {
		return this.store.pipe(compose(getContactsList(), getContactState()));
	}

	getActiveContact() : Observable<IContactModel> {
		return this.store.pipe(compose(getActiveContact(), getContactState()));
	}

	setContactsList(contacts: Array<IContactModel>) : void {
		this.store.dispatch({ type: CONTACT_ACTIONS.SET_CONTACTS_LIST, payload: contacts });
	}

	setActiveContact(contact: IContactModel) : void {
		this.store.dispatch({ type: CONTACT_ACTIONS.SET_ACTIVE_CONTACT, payload: contact });
	}

	// </editor-fold>

	// <editor-fold> Staff

	getStaffList() : Observable<Array<IStaffModel>> {
		return this.store.pipe(compose(getStaffList(), getStaffState()));
	}

	getActiveStaff() : Observable<IStaffModel> {
		return this.store.pipe(compose(getActiveStaff(), getStaffState()));
	}

	setStaffList(staff: Array<IStaffModel>) : void {
		this.store.dispatch({ type: STAFF_ACTIONS.SET_STAFF_LIST, payload: staff });
	}

	setActiveStaff(staff: IStaffModel) : void {
		this.store.dispatch({ type: STAFF_ACTIONS.SET_ACTIVE_STAFF, payload: staff });
	}

	// </editor-fold>

	// <editor-fold> Users

	getUsersList() : Observable<Array<IUserModel>> {
		return this.store.pipe(compose(getUsersList(), getUserState()));
	}

	getSelectedUser() : Observable<IUserModel> {
		return this.store.pipe(compose(getSelectedUser(), getUserState()));
	}

	getLocationUsersList() : Observable<Array<IUserModel>> {
		return this.store.pipe(compose(getLocationUsersList(), getUserState()));
	}

	getLocationSelectedUser() : Observable<IUserModel> {
		return this.store.pipe(compose(getLocationSelectedUser(), getUserState()));
	}

	setUsersList(users:Array<IUserModel>) : void {
		this.store.dispatch({ type: USER_ACTIONS.SET_USERS_LIST, payload: users });
	}

	setSelectedUser(user: IUserModel) : void {
		this.store.dispatch({ type: USER_ACTIONS.SET_SELECTED_USER, payload: user });
	}

	setLocationUsersList(users: Array<IUserModel>) : void {
		this.store.dispatch({ type: USER_ACTIONS.SET_LOCATION_USERS_LIST, payload: users });
	}

	setLocationSelectedUser(user: IUserModel) : void {
		this.store.dispatch({ type: USER_ACTIONS.SET_LOCATION_SELECTED_USER, payload: user });
	}

	setPMList(users:Array<IUserModel>) : void {
		this.store.dispatch({ type: USER_ACTIONS.SET_LOCATION_PROJECT_MANAGERS, payload:users})
	}

	getPMList() : Observable<Array<IUserModel>> {
		return this.store.pipe(compose(getPMList(), getUserState()));
	}
	// </editor-fold>

	// <editor-fold> Jobs

	getJobsList() : Observable<Array<IJobModel>> {
		return this.store.pipe(compose(getJobsList(), getJobState()));
	}

	getActiveJob() : Observable<IJobModel> {
		return this.store.pipe(compose(getActiveJob(), getJobState()));
	}

	setJobsList(jobs: Array<IJobModel>) : void {
		this.store.dispatch({ type: JOB_ACTIONS.SET_JOBS_LIST, payload: jobs });
	}

	setActiveJob(job: IJobModel) : void {
		this.store.dispatch({ type: JOB_ACTIONS.SET_ACTIVE_JOB, payload: job });
	}

	getLaborCodesList() : Observable<Array<ILaborCodeModel>> {
		return this.store.pipe(compose(getLaborCodesList(), getLaborCodeState()));
	}

	// </editor-fold>

	// <editor-fold> Work Orders

	getWorkOrdersList() : Observable<Array<IWorkOrderModel>> {
		return this.store.pipe(compose(getWorkOrdersList(), getWorkOrderState()));
	}

	getActiveWorkOrder() : Observable<IWorkOrderModel> {
		return this.store.pipe(compose(getActiveWorkOrder(), getWorkOrderState()));
	}

	setWorkOrdersList(workOrders: Array<IWorkOrderModel>) : void {
		this.store.dispatch({ type: WORKORDER_ACTIONS.SET_WORKORDERS_LIST, payload: workOrders });
	}

	setActiveWorkOrder(workOrder: IWorkOrderModel) : void {
		this.store.dispatch({ type: WORKORDER_ACTIONS.SET_ACTIVE_WORKORDER, payload: workOrder });
	}

	// </editor-fold>

	// <editor-fold> Purchase Orders

	getPurchaseOrdersList() : Observable<Array<IPurchaseOrderModel>> {
		return this.store.pipe(compose(getPurchaseOrdersList(), getPurchaseOrderState()));
	}

	getActivePurchaseOrder() : Observable<IPurchaseOrderModel> {
		return this.store.pipe(compose(getActivePurchaseOrder(), getPurchaseOrderState()));
	}

	setPurchaseOrdersList(purchaseOrders: Array<IPurchaseOrderModel>) : void {
		this.store.dispatch({ type: PURCHASEORDER_ACTIONS.SET_PURCHASEORDERS_LIST, payload: purchaseOrders });
	}

	setActivePurchaseOrder(purchaseOrder: IPurchaseOrderModel) : void {
		this.store.dispatch({ type: PURCHASEORDER_ACTIONS.SET_ACTIVE_PURCHASEORDER, payload: purchaseOrder });
	}

	// </editor-fold>

	// <editor-fold> Change Order

	getChangeOrdersList() : Observable<Array<IChangeOrderModel>> {
		return this.store.pipe(compose(getChangeOrdersList(), getChangeOrderState()));
	}

	getActiveChangeOrder() : Observable<IChangeOrderModel> {
		return this.store.pipe(compose(getActiveChangeOrder(), getChangeOrderState()));
	}

	setChangeOrdersList(changeOrders: Array<IChangeOrderModel>) : void {
		this.store.dispatch({ type: CHANGEORDER_ACTIONS.SET_CHANGEORDERS_LIST, payload: changeOrders });
	}

	setActiveChangeOrder(changeOrder: IChangeOrderModel) : void {
		this.store.dispatch({ type: CHANGEORDER_ACTIONS.SET_ACTIVE_CHANGEORDER, payload: changeOrder });
	}

	// </editor-fold>

	// <editor-fold> Invoice (Job)

	getInvoicesList() : Observable<Array<IInvoiceModel>> {
		return this.store.pipe(compose(getInvoicesList(), getInvoiceState()));
	}

	getActiveInvoice() : Observable<IInvoiceModel> {
		return this.store.pipe(compose(getActiveInvoice(), getInvoiceState()));
	}

	setInvoicesList(invoices: Array<IInvoiceModel>) : void {
		this.store.dispatch({ type: INVOICE_ACTIONS.SET_INVOICES_LIST, payload: invoices });
	}

	setActiveInvoice(invoice: IInvoiceModel) : void {
		this.store.dispatch({ type: INVOICE_ACTIONS.SET_ACTIVE_INVOICE, payload: invoice });
	}

	getInvoiceSummaryList() : Observable<Array<IInvoiceSummary>> {
		return this.store.pipe(compose(getInvoiceSummaryList(), getInvoiceState()));
	}

	setInvoiceSummaryList(invoices: Array<IInvoiceSummary>) : void {
		this.store.dispatch({ type: INVOICE_ACTIONS.SET_INVOICE_SUMMARY_LIST, payload: invoices })
	}

	// </editor-fold>

	// <editor-fold> Payable (Job)

	getPayablesList() : Observable<Array<IPayableModel>> {
		return this.store.pipe(compose(getPayablesList(), getPayableState()));
	}

	getActivePayable() : Observable<IPayableModel> {
		return this.store.pipe(compose(getActivePayable(), getPayableState()));
	}

	setPayablesList(payables: Array<IPayableModel>) : void {
		this.store.dispatch({ type: PAYABLE_ACTIONS.SET_PAYABLES_LIST, payload: payables });
	}

	setActivePayable(payable: IPayableModel) : void {
		this.store.dispatch({ type: PAYABLE_ACTIONS.SET_ACTIVE_PAYABLE, payload: payable });
	}

	getPayableSummaryList() : Observable<Array<IPayableSummary>> {
		return this.store.pipe(compose(getPayableSummaryList(), getPayableState()));
	}

	setPayableSummaryList(payables: Array<IPayableSummary>) : void {
		this.store.dispatch({ type: PAYABLE_ACTIONS.SET_PAYABLE_SUMMARY_LIST, payload: payables })
	}

	// </editor-fold>

	// <editor-fold> PM note

	getPMNotesList() : Observable<Array<IPMNoteModel>> {
		return this.store.pipe(compose(getPMNotesList(), getPMNoteState()));
	}

	getActivePMNote() : Observable<IPMNoteModel> {
		return this.store.pipe(compose(getActivePMNote(), getPMNoteState()));
	}

	setPMNotesList(pmNotes: Array<IPMNoteModel>) : void {
		this.store.dispatch({ type: PMNOTE_ACTIONS.SET_PMNOTES_LIST, payload: pmNotes });
	}

	setActivePMNote(pmNote: IPMNoteModel) : void {
		this.store.dispatch({ type: PMNOTE_ACTIONS.SET_ACTIVE_PMNOTE, payload: pmNote });
	}

	// </editor-fold>

	// <editor-fold> Part

	getWorkOrderPartsList() : Observable<Array<IWorkOrderPartModel>> {
		return this.store.pipe(compose(getWorkOrderPartsList(), getPartState()));
	}

	setWorkOrderPartsList(parts: Array<IWorkOrderPartModel>) : void {
		this.store.dispatch({ type: PART_ACTIONS.SET_WORKORDER_PARTS_LIST, payload: parts });
	}

	getPurchaseOrderPartsList() : Observable<Array<IPurchaseOrderPartModel>> {
		return this.store.pipe(compose(getPurchaseOrderPartsList(), getPartState()));
	}

	setPurchaseOrderPartsList(parts: Array<IPurchaseOrderPartModel>) : void {
		this.store.dispatch({ type: PART_ACTIONS.SET_PURCHASEORDER_PARTS_LIST, payload: parts });
	}

	// </editor-fold>

	// <editor-fold> Receipts

	getReceiptsList() : Observable<Array<IReceiptModel>> {
		return this.store.pipe(compose(getReceiptsList(), getReceiptState()));
	}

	setReceiptsList(receipts: Array<IReceiptModel>) : void {
		this.store.dispatch({ type: RECEIPT_ACTIONS.SET_RECEIPTS_LIST, payload: receipts });
	}

	// </editor-fold>

	// <editor-fold> Purchase Order Invoices

	getCostList() : Observable<Array<ICostModel>> {
		return this.store.pipe(compose(getCostsList(), getCostState()));
	}

	setCostList(costs: Array<ICostModel>) : void {
		this.store.dispatch({ type: COST_ACTIONS.SET_COSTS_LIST, payload: costs });
	}

	// </editor-fold>

	// <editor-fold> Time entry

	getTimeEntriesList() : Observable<Array<ITimeEntryModel>> {
		return this.store.pipe(compose(getTimeEntriesList(), getTimeEntryState()));
	}

	getActiveTimeEntry() : Observable<ITimeEntryModel> {
		return this.store.pipe(compose(getActiveTimeEntry(), getTimeEntryState()));
	}

	setTimeEntriesList(timeEntries: Array<ITimeEntryModel>) : void {
		this.store.dispatch({ type: TIMEENTRY_ACTIONS.SET_TIMEENTRIES_LIST, payload: timeEntries });
	}

	setActiveTimeEntry(timeEntry: ITimeEntryModel) : void {
		this.store.dispatch({ type: TIMEENTRY_ACTIONS.SET_ACTIVE_TIMEENTRY, payload: timeEntry });
	}

	// </editor-fold>

	// <editor-fold> Debug

	getDebugHeader() : Observable<string> {
		return this.store.pipe(compose(getDebugHeader(), getDebugState()));
	}

	setDebugHeader(header: string = '200') : void {
		this.store.dispatch({ type: DEBUG_ACTIONS.SET_DEBUG_HEADER, payload: header });
	}

	// </editor-fold>
}

// <editor-fold> State functions

export function getAuthState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.auth);
	};
}

export function getUserState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.users);
	};
}

export function getContactState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.contact);
	};
}

export function getCustomerState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.customer);
	};
}

export function getDebugState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.debug);
	};
}

export function getJobState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.job);
	};
}

export function getLocationState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.location);
	};
}

export function getStaffState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.staff);
	};
}

export function getSupplierState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.supplier);
	};
}

export function getWorkOrderState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.workOrder);
	};
}

export function getPurchaseOrderState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.purchaseOrder);
	};
}

export function getChangeOrderState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.changeOrder);
	};
}

export function getInvoiceState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.invoice);
	};
}

export function getPayableState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.payable);
	};
}

export function getPMNoteState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.pmNote);
	};
}

export function getPartState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.part);
	};
}

export function getReceiptState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.receipt);
	};
}

export function getCostState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.cost);
	};
}

export function getTimeEntryState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.timeEntry);
	};
}

export function getLaborCodeState() {
	return (state : Store<IAppState>) => {
		return state.select(s => s.laborCode);
	};
}

// </editor-fold>
