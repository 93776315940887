<section *ngIf="receiptsLoaded" class="po-receipts-add">



    <div class="receipt-actions row">
        <div class="form-group receipt-actions-date col-xs-6">
            <label>Date</label>
            <input autocomplete="off" class="form-control" type="date" [ngModel]="receiptDate | dateFormat"
                (ngModelChange)="receiptDate = sanitizeDate($event)" required="required" #date="ngModel"
                style="max-width:170px;" />
            <div class="alert alert-danger" [hidden]="date.valid || date.pristine">
                Date is required
            </div>
        </div>

        <div class="receipt-actions-add pt-2 col-xs-6 text-right">
            <button type="button" class="btn btn-primary" [disabled]="!date.valid"
                (click)="createReceipts()">Receive</button>
        </div>
    </div>

    <table class="table table-select-list">
        <caption class="sr-only">List of parts</caption>
        <thead>
            <tr>
                <th scope="col" class="currency-display quantity">Qty</th>
                <th scope="col" class="unit">Unit</th>
                <th scope="col" class="currency-display" style="width:100px;">Backorder</th>
                <th scope="col" class="currency-display recd">Recv'd</th>
                <th scope="col" class="desc">Description</th>
                <th scope="col" class="location">Location</th>
                <th scope="col" class="damaged">Damaged?</th>
                <th scope="col" class="note">Notes</th>
            </tr>
        </thead>
        <tbody>
            <tr class="no-hover" *ngFor="let part of parts">
                <td scope="row" class="currency-display">
                    {{ part.quantityOrdered }}
                </td>
                <td scope="row">
                    {{ part.unit }}
                </td>
                <td scope="row" class="currency-display">
                    {{ part.quantityBackorder }}
                </td>
                <td scope="row">
                    <input autocomplete="off" class="form-control" type="number" min="0" [max]="part.quantityBackorder"
                        #quantity="ngModel" [(ngModel)]="part.quantityReceived" required="required"
                        [disabled]="part.quantityBackorder < 1" />
                </td>
                <td scope="row">
                    <div class="readonly-multiline">{{ part.description }}</div>
                </td>
                <td scope="row">
                    <input autocomplete="off" class="form-control" type="text" #location="ngModel"
                        [(ngModel)]="part.location" [disabled]="part.quantityBackorder < 1" />
                </td>
                <td scope="row">
                    <button type="button" class="btn btn-default btn-checkbox"
                        (click)="part.isDamaged = !part.isDamaged; $event.stopPropagation()"
                        [ngClass]="{ 'active btn-warning': part.isDamaged }" [disabled]="part.quantityBackorder < 1"><i
                            class="glyphicon glyphicon-ok"></i></button>
                </td>
                <td scope="row">
                    <notes-field [(model)]="part.notes" [saved]="saved" [disabled]="part.quantityBackorder < 1">
                    </notes-field>
                </td>
            </tr>
        </tbody>
    </table>


</section>

<section *ngIf="receiptsLoaded" class="po-receipts-container">

    <div class="screen-subtitle" id="pastReceiptsLabel">Past Receipts</div>

    <table class="table table-select-list" aria-describedby="pastReceiptsLabel">
        <thead>
            <tr>
                <th scope="col" class="currency-display quantity">Qty</th>
                <th scope="col" class="unit">Unit</th>
                <th scope="col" class="currency-display recd">Recv'd</th>
                <th scope="col" class="desc">Description</th>
                <th scope="col" class="location">Location</th>
                <th scope="col" class="damaged">Damaged?</th>
                <th scope="col" class="note">Notes</th>
                <th scope="col" class="buttons">&nbsp;</th>
            </tr>
        </thead>
        <tbody *ngFor="let day of (receipts | async | groupBy:'date')">

            <tr>
                <td scope="row" colspan="8" class="caption">{{ day.key | dateFormat:'MM/DD/YYYY' }}</td>
            </tr>
            <tr *ngFor="let receipt of day.values" (click)="editReceipt(receipt)">
                <ng-template [ngIf]="receipt._id === editingReceiptId">
                    <td scope="row" class="currency-display quantity">
                        {{ getPartById(receipt.part).quantityOrdered }}
                    </td>
                    <td scope="row" class="unit">
                        {{ getPartById(receipt.part).unit }}
                    </td>
                    <td scope="row" class="recd">
                        <input autocomplete="off" class="form-control" type="number" min="1" #editQuantity="ngModel"
                            [(ngModel)]="receipt.quantity" required="required" />
                    </td>
                    <td scope="row" class="desc">
                        <div class="readonly-multiline">{{ getPartById(receipt.part).description }}</div>
                    </td>
                    <td scope="row" class="location">
                        <input autocomplete="off" class="form-control" type="text" #editLocation="ngModel"
                            [(ngModel)]="receipt.location" />
                    </td>
                    <td scope="row" class="damaged">
                        <button type="button" class="btn btn-default btn-checkbox"
                            (click)="receipt.isDamaged = !receipt.isDamaged; $event.stopPropagation()"
                            [ngClass]="{ 'active btn-warning': receipt.isDamaged }"><i
                                class="glyphicon glyphicon-ok"></i></button>
                    </td>
                    <td scope="row" class="notes">
                        <notes-field [(model)]="receipt.notes" [saved]="saved"></notes-field>
                    </td>
                    <td scope="row" class="actions buttons">
                        <button type="button" class="btn btn-danger" (click)="deleteReceipt(receipt, $event)"><i
                                class="glyphicon glyphicon-trash" title="Delete"></i></button>
                        <button type="button" class="btn btn-default" (click)="cancel($event)">Cancel</button>
                        <button type="button" class="btn btn-primary"
                            [disabled]="!editQuantity.valid || !editLocation.valid"
                            (click)="saveReceipt(receipt)">Save</button>
                    </td>
                </ng-template>
                <ng-template [ngIf]="receipt._id !== editingReceiptId">
                    <td scope="row" class="currency-display quantity">
                        {{ getPartById(receipt.part).quantityOrdered }}
                    </td>
                    <td scope="row" class="unit">
                        {{ getPartById(receipt.part).unit }}
                    </td>
                    <td scope="row" class="currency-display recd">
                        {{ receipt.quantity }}
                    </td>
                    <td scope="row" class="desc">
                        <div class="readonly-multiline">{{ getPartById(receipt.part).description }}</div>
                    </td>
                    <td scope="row" class="location">
                        {{ receipt.location }}
                    </td>
                    <td scope="row" [innerHtml]="receipt.isDamaged | booleanIcon:'ok'" class="damaged"></td>
                    <td scope="row" class="notes">
                        <div class="readonly-multiline">{{ receipt.notes }}</div>
                    </td>
                    <td scope="row" class="actions buttons">
                        <button type="button" class="btn btn-default" (click)="editReceipt(receipt)">Edit</button>
                    </td>
                </ng-template>
            </tr>
        </tbody>
    </table>
</section>