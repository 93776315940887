<div class="actions mt-3">
	<div class="container-fluid">
		<div class="row">
			<div class="col-xs-12 col-md-8 mb-2 pl-0">

				<div class="input-group">
					<select class="form-control" [(ngModel)]="_selectedPurchaseOrderId">
						<option  selected="selected" value="null" disabled>-- Select a Purchase Order --</option>
						<option *ngFor="let purchaseOrder of poList" [value]="purchaseOrder._id">
							{{ (purchaseOrder.date | dateFormat:'DD/MM/YYYY') + ' - ' + purchaseOrder.number + ' - ' + (purchaseOrder.supplier ? purchaseOrder.supplier.name : 'NO SUPPLIER') }}
						</option>
					</select>

					<span class="input-group-btn">
		                <button class="btn btn-default" (click)="attachPurchaseOrder(_selectedPurchaseOrderId)">Attach purchase order</button>
		            </span>
				</div>

			</div>
			<div class="col-xs-12 col-md-4 pr-0">
				<button class="btn btn-primary" (click)="createPurchaseOrder()">Create Purchase Order</button>
			</div>
		</div>
	</div>
</div>

<hr>

<div class="connection-section">
	<div class="workorder-area">
		<div class="purchase-order mb-3" *ngFor="let purchaseOrder of attachedPOs">
			<div class="purchase-order-info">
				<span class="purchase-order-info-number" (click)="goToPurchaseOrder(purchaseOrder._id, $event)">
					PO# {{ purchaseOrder.number }}
				</span>
				<span class="purchase-order-info-supplier" (click)="goToPurchaseOrder(purchaseOrder._id, $event)">
					<span class="supplier-status-container">
						<span *ngIf="purchaseOrder.supplier?.status" class="supplier-status-icon {{purchaseOrder.supplier?.status}}"></span>
					</span>
					{{ purchaseOrder.supplier?.name }}
				</span>

				<div class="purchase-order-actions">
					<button class="btn btn-default" (click)="detachPurchaseOrder(purchaseOrder)">Detach</button>
					<button class="btn btn-default" (click)="goToPurchaseOrder(purchaseOrder._id, $event)">Edit</button>
				</div>

				<div class="purchase-order-name" (click)="goToPurchaseOrder(purchaseOrder._id, $event)">
					{{ purchaseOrder.name ? purchaseOrder.name : '&nbsp;' }}
				</div>

			<table class="table" (click)="goToPurchaseOrder(purchaseOrder._id, $event)">
				<caption class="sr-only">List of purchase orders</caption>
				<thead>
					<tr>
						<th scope="col">Qty</th>
						<th scope="col">Unit</th>
						<th scope="col">Recvd</th>
						<th scope="col">Description</th>
						<th scope="col">Date Recvd</th>
						<th scope="col">Location</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let part of (purchaseOrder.parts | receiptAggregate:purchaseOrder.receipts)">
						<td scope="row">{{ part.quantityOrdered }}</td>
						<td scope="row">{{ part.unit }}</td>
						<td scope="row">{{ part.quantityReceived }}</td>
						<td scope="row">
							<div class="readonly-multiline">{{ part.description }}</div>
						</td>
						<td scope="row">{{ part.date | dateFormat:'DD/MM/YYYY' }}</td>
						<td scope="row">{{ part.location }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
