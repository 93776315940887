import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IPMNoteModel, PMNoteModel } from '@/models/pmNote.models';

export interface IPMNoteState {
    pmNotes: Array<IPMNoteModel>,
    activePMNote: IPMNoteModel
}

const defaultState : IPMNoteState = {
    pmNotes: new Array<IPMNoteModel>(),
    activePMNote: new PMNoteModel()
};

export const PMNOTE_ACTIONS = {
    SET_PMNOTES_LIST: 'SET_PMNOTES_LIST',
    SET_ACTIVE_PMNOTE: 'SET_ACTIVE_PMNOTE'
};

const pmNoteReducer = function(state: IPMNoteState = defaultState, action: Action) {
    let newState = Object.assign({}, state);

    switch(action.type) {
        case PMNOTE_ACTIONS.SET_PMNOTES_LIST:
            newState.pmNotes = action.payload;
            break;
        case PMNOTE_ACTIONS.SET_ACTIVE_PMNOTE:
            newState.activePMNote = action.payload;
            break;
    }

    return newState;
};

export function getPMNotesList() {
	return (state : Store<IPMNoteState>) => {
		return state.select(s => {
			return s.pmNotes;
		});
	};
}

export function getActivePMNote() {
	return (state : Store<IPMNoteState>) => {
		return state.select(s => {
			return s.activePMNote;
		});
	};
}

export function reducer(state: IPMNoteState | undefined, action: Action) {
	return pmNoteReducer(state, action);
}
