/**
 * Created by rrahlf on 4/10/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilityModule } from './../../utils/utility.module';
import { RouterModule } from '@angular/router';

import { ProfileComponent } from './profile.component';
import { ProfileEditComponent } from './profileEdit.component';
import { ProfileEditFormComponent } from './profileEditForm.component';

@NgModule({
	declarations: [
		ProfileComponent,
		ProfileEditComponent,
		ProfileEditFormComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		UtilityModule,
		RouterModule
	],
	exports: [
		ProfileComponent,
		ProfileEditComponent,
		ProfileEditFormComponent
	]
})
export class ProfileModule {}
