import { Component, Input, Output, EventEmitter, SimpleChanges  } from '@angular/core';
import { Subject } from 'rxjs';
import { UI_MASKS } from '@/utils/uiMasks';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from './../base.component';
import * as routerUtils from '@/utils/routerUtils';

import { DataCopyService } from '@/services/dataCopy.service';
import { CustomerService } from '@/services/customer.service';
import { JobService } from '@/services/job.service';
import { EnvironmentService } from '@/services/EnvironmentService';

import { IJobModel } from '@/models/job.models';
import { IContactModel } from '@/models/contact.models';
import { IUserModel } from '@/models/user.models';
@Component({
	selector: 'job-edit-form',
	templateUrl: './jobEditForm.template.html',
	host: {'class': 'job-edit-form-component'}
})

export class JobEditFormComponent extends BaseComponent {
	@Input()
	model: IJobModel;

	@Input()
	users: Array<IUserModel>;

	@Input()
	showCancel: boolean = false;

	@Input()
	appSettings: any;

	@Input()
	view: string;

	@Output()
	onSave = new EventEmitter<IJobModel>();

	@Output()
	onCancel = new EventEmitter<void>();

	public display: string = 'none';
	public contactModel : any = {};
    private _customerId : string;
	public saved : Subject<any> = new Subject();
    public displayedContacts : Array<IContactModel>;
	public showAlert = false;
    private existingContact : String = "";
	private _customerRoute: ActivatedRoute;
	public contacts: Array<IContactModel>;
	public requireAmount: Boolean = false;

	constructor(
		public uiMasks: UI_MASKS, 
		private dataCopyService: DataCopyService, 
		private customerService : CustomerService, 
		private router: Router, 
		private route: ActivatedRoute, 
		private jobService: JobService,
		private env: EnvironmentService
		) {
		super();
	}

	ngOnInit() {
		this.resetContact(); // clears modal form
		this.updateCustomer();
		this._customerRoute = routerUtils.getParentRoute(this.route);
		this.saved.next('false');
	}

	ngAfterViewInit() {
		// lame hack for new jobs to fix selects that show as valid when actually empty
		setTimeout(() => {
			if(this.showCancel) {
				this.model.customerContact = null;
				this.model.projectManager = null;
			}
		}, 0);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['model']) {
			this.updateCustomer();
		}
	}
	saveJob(): void {
		// moment is validating a 5 digit year as valid so doing an extra check here. 5+ digit year breaks job search
		let year = new Date(this.model.date).getFullYear();
		if(year.toString().length == 4) {
			this.onSave.emit(this.model);
			this.jobService.saveSidebarData(this.model.bidAmounts, this.model.notes);
			this.saved.next('true');
		} else {
			alert('Please enter a valid Contract date');
		}
	}

	cancel(): void {
		this.onCancel.emit();
		this.saved.next('true');
	}

	flagToggle(flag: string): void {
		this.model[flag] = !this.model[flag];
	}

	copyCustomerAddress(): void {
		this.dataCopyService.copyActiveCustomerAddress(this.model.address);
	}

	// add a supplier 
	openModal(){
		this.display='block'; 
	}
	
	public setNewContact(newContact : IContactModel) {
		newContact.timestamp = new Date();
		let validContact = this.customerService.validateContact(this.contacts, newContact);
		if(validContact==="valid"){
			this.customerService.updateContact(this._customerId, newContact)
			.then((contact) => {
				this.showAlert = false;
				this.contacts.push(contact)
				//set new contact to active
				this.model.customerContact = contact._id;
				this.resetContact();
				this.filterContacts();
			})
			.catch(() => {});
		} else {
			this.existingContact = validContact;
			this.showAlert = true;
		}
    }

	resetContact() {
		this.display = 'none';
		this.contactModel = {
			firstName : null,
			lastName : null,
			phone : null,
			email : null,
			title : null,
			salutation : null,
			timestamp: null
		};
	}
	// Show only active contacts. 
	private filterContacts(){
		if(this.contacts){
			this.displayedContacts = [];
			this.contacts.forEach(contact => {
				if(contact.isActive || (contact._id === this.model.customerContact)){ // If current contact is deactivated, it is the selected option but disabled
					this.displayedContacts.push(contact);
				}
			});
			this.displayedContacts.sort((a, b) => {
				if (a.lastName > b.lastName) { return 1; }
				if (a.lastName < b.lastName) { return -1; }
				return 0;
			});
		}
	}

	public goToExistingContact(){
        this.router.navigate(['Customers', this._customerId, 'Edit', 'Contacts', this.existingContact]);
	}

	private updateCustomer(): void {
        this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
		let id = (this.view==='edit' && typeof this.model.customer==='string') ? this.model.customer : (this._customerId && this.view=='add' ? this._customerId : null);
		if(id){
			this.customerService.getCustomer(id).then((customer) => {
				setTimeout(() => {
					this.contacts = customer.contacts;
					this.filterContacts();
					if(customer.location == this.env.PEWAUKEE_ID) {
						this.requireAmount = false;                   
					} else {
						this.requireAmount = true;                   
					}
				}, 0);
			});
		}
	}    

}
