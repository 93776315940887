<div class="form-group" *ngIf="purchaseOrder">
	<div class="col-sm-10">
		<label>Notes</label>
		<notes-field [(model)]="purchaseOrder.notes" [saved]="saved"></notes-field>
	</div>
	<div class="col-sm-2 text-right pt-2">
		<button type="button" class="btn btn-primary" (click)="saveNotes(notes, $event)">Save</button>
	</div>
</div>
<table class="table table-select-list mb-0">
	<caption class="sr-only">Sort list links</caption>
	<thead>
		<tr>
			<th scope="col" style="width:100px;">
				<sortable-header field="quantity" label="Qty" [sortManager]="dataManipulationService"></sortable-header>
			</th>
			<th scope="col" style="width:100px;">
				<sortable-header field="unit" label="Unit" [sortManager]="dataManipulationService"></sortable-header>
			</th>
			<th scope="col">
				<sortable-header field="description" label="Description" [sortManager]="dataManipulationService">
				</sortable-header>
			</th>
			<th scope="col" style="width:160px;" class="text-right">
				<sortable-header field="cost" label="Cost" [sortManager]="dataManipulationService"></sortable-header>
			</th>
			<th scope="col" style="width:200px;"></th>
		</tr>
	</thead>
	<tbody>
		<tr class="no-hover" keyboardAccelerator="13" (onKeyboardAction)="savePart(newPart)">
			<td scope="row">
				<input autocomplete="off" class="form-control" type="number" min="0" [(ngModel)]="newPart.quantity" />
			</td>
			<td scope="row">
				<input autocomplete="off" class="form-control" type="text" [(ngModel)]="newPart.unit" />
			</td>
			<td scope="row">
				<textarea rows="3" class="form-control" #description="ngModel" [(ngModel)]="newPart.description"
					required="required" data-keyboard-exclude></textarea>
			</td>
			<td scope="row">
				<div class="input-group pull-right">
					<span class="input-group-addon">$</span>
					<input autocomplete="off" class="form-control" type="text" #cost="ngModel"
						[(ngModel)]="newPart.cost" [enforceDecimal]="false" [zeroOnNull]="false" placeholder="0.00" />
				</div>
			</td>
			<td scope="row" class="actions">
				<button type="button" class="btn btn-primary" [disabled]="!description.valid"
					(click)="savePart(newPart)" data-keyboard-exclude>Create</button>
			</td>
		</tr>
	</tbody>
</table>

<div class="attached">
	<table class="table table-select-list scrollable">
		<caption class="sr-only">List of parts</caption>
		<thead>
			<tr>
				<th scope="col" class="pa-0" style="width:100px;"></th>
				<th scope="col" class="pa-0" style="width:100px;"></th>
				<th scope="col" class="pa-0"></th>
				<th scope="col" class="pa-0" style="width:160px;"></th>
				<th scope="col" class="pa-0" style="width:200px;"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let part of model | async; let partIndex = index" (click)="editPart(part)"
				keyboardAccelerator="13" (onKeyboardAction)="savePart(part)">
				<ng-template [ngIf]="part._id === editingPartId">
					<td scope="row">
						<input autocomplete="off" class="form-control" type="number" min="0"
							[(ngModel)]="part.quantity" />
					</td>
					<td scope="row">
						<input autocomplete="off" class="form-control" type="text" [(ngModel)]="part.unit" />
					</td>
					<td scope="row">
						<textarea rows="3" autocomplete="off" class="form-control" #editDescription="ngModel"
							[(ngModel)]="part.description" required="required" data-keyboard-exclude></textarea>
					</td>
					<td scope="row">
						<div class="input-group">
							<span class="input-group-addon">$</span>
							<input autocomplete="off" class="form-control" type="text" [(ngModel)]="part.cost"
								[enforceDecimal]="false" [zeroOnNull]="false" placeholder="0.00" />
						</div>
					</td>
					<td scope="row" class="actions">
						<button type="button" class="btn btn-danger mb-1" (click)="deletePart(part, $event)"
							data-keyboard-exclude><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
						<button type="button" class="btn btn-default mb-1 ml-1" (click)="cancel($event)"
							data-keyboard-exclude>Cancel</button>
						<button type="button" class="btn btn-primary mb-1 ml-1" [disabled]="!editDescription.valid"
							(click)="savePart(part)" data-keyboard-exclude>Save</button>
					</td>
				</ng-template>
				<ng-template [ngIf]="part._id !== editingPartId">
					<td scope="row" class="text-right">
						{{ part.quantity }}
					</td>
					<td scope="row">
						{{ part.unit }}
					</td>
					<td scope="row">
						<div class="readonly-multiline">{{ part.description }}</div>
					</td>
					<td scope="row" class="currency-display">
						{{ part.cost | currencyFormat:false:false }}
					</td>
					<td class="actions">
						<button type="button" class="btn btn-default" (click)="editPart(part, $event)">Edit</button>
					</td>
				</ng-template>
			</tr>
		</tbody>
	</table>
</div>