import { Injectable } from '@angular/core';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

export type ExportedDocument = { exportUrl: string };

@Injectable()
export class ExportDocumentService extends ApiBaseService {
    constructor(protected http: HttpClient, protected override messageService: MessageService) {
        super(messageService);
    }

    saveDocument(purchaseOrderId: string, copies: string[], includeAttachements: boolean): Observable<ExportedDocument> {
        let path = `export/purchaseOrders/${purchaseOrderId}?copies=${copies.join(',')}&attachments=${includeAttachements ? '1' : '0'}`;
        return this.http.get<ExportedDocument>(path)
          .pipe(tap(() => {
              this.messageService.send({ options: {}, type: 'success', title: '', message: 'Successfully exported PDF'});
            }, () => {
             this.messageService.send({ options: {}, type: 'error', title: '', message: 'Failed to export PDF' });
            }));
    }

    getExportedPO(customerId: string, jobId: string, purchaseOrderId: string): Observable<HttpResponse<Blob>> {
        let path = `customers/${customerId}/jobs/${jobId}/purchaseOrders/${purchaseOrderId}/export`;
        return this.http.get(path, { responseType: 'blob', observe: 'response', withCredentials: true });
    }

    getExportedFile(path: string): Observable<HttpResponse<Blob>> {
        return this.http.get(path, { responseType: 'blob', observe: 'response', withCredentials: true });
    }
}
