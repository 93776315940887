import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IStaffModel, StaffModel } from '@/models/staff.models';

export interface IStaffState {
    staff: Array<IStaffModel>,
    activeStaff: IStaffModel
}

const defaultState = {
	staff: new Array<IStaffModel>(),
	activeStaff: new StaffModel()
};

export const STAFF_ACTIONS = {
	SET_STAFF_LIST: 'SET_STAFF_LIST',
	SET_ACTIVE_STAFF: 'SET_ACTIVE_STAFF'
};

const staffReducer = function (state: IStaffState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case STAFF_ACTIONS.SET_STAFF_LIST:
			newState.staff = action.payload;
			break;
		case STAFF_ACTIONS.SET_ACTIVE_STAFF:
			newState.activeStaff = action.payload;
			break;
	}

	return newState;
};

export function getStaffList() {
	return (state : Store<IStaffState>) => {
		return state.select(s => {
			return s.staff;
		});
	};
}

export function getActiveStaff() {
	return (state : Store<IStaffState>) => {
		return state.select(s => s.activeStaff)
	};
}

export function reducer(state: IStaffState | undefined, action: Action) {
	return staffReducer(state, action);
}
