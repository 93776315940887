import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'zipFormat',
    pure : true
})

export class ZipFormatPipe implements PipeTransform {
    transform(value : string) : string {
        if(!value || (value.length < 5)) {
            return '';
        }

        let main = value.substring(0, 5);
        let extended = value.substring(5);

        return main + (extended.length === 4 ? '-' + extended : '');
    }
};
