<div id="loading" class="data-loading" *ngIf="!model" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>

<form class="form-horizontal" (ngSubmit)="saveChangeOrder()" #changeOrderForm="ngForm" *ngIf="model" [@fadeInOut]>
    <input name="id" type="hidden" value="{{ model._id }}" />

	<div class="form-group">
		<div class="col-xs-12 col-md-6">
			<label>Number</label>
			<div class="">
				<input type="text" autocomplete="off" class="form-control" [(ngModel)]="model.number" name="number"  #number="ngModel" [disabled]="permissionLock" />
			</div>
		</div>
		<div class="col-xs-12 col-md-6">
			<label>Date</label>
			<div class="">
				<input autocomplete="off" class="form-control" type="date" [ngModel]="model.date | dateFormat" (ngModelChange)="model.date = sanitizeDate($event)" name="date" required="required" #date="ngModel" [disabled]="permissionLock" />
				<div [hidden]="(!changeOrderForm.form.submitted && date.pristine) || date.valid">
					Date is required
				</div>
			</div>
		</div>
	</div>

	<div class="form-group">
		<div class="col-xs-12">
			<label>Description</label>
			<div class="">
				<input autocomplete="off" type="text" class="form-control" [(ngModel)]="model.description" name="description" #name="ngModel" [disabled]="permissionLock" />
			</div>
		</div>
	</div>

    <div class="form-group">
		<div class="col-xs-12 col-md-6">
			<label>Amount</label>
			<div class="">
				<div class="input-group">
					<span class="input-group-addon">$</span>
					<input autocomplete="off" class="form-control" type="text" [(ngModel)]="model.amount" name="amount" #amount="ngModel" enforceDecimal [comma]="true" placeholder="0.00" [disabled]="permissionLock"/>
				</div>
				<div [hidden]="(!changeOrderForm.form.submitted && amount.pristine) || amount.valid">
					Amount is required
				</div>
			</div>
		</div>
	</div>

	<div class="form-group">
		<div class="col-xs-12 col-md-6">
			<label class="control-label">Associated Documents</label>
			<document-upload
				type="CO"
				[isDraggable]="false"
				[label]="'Add documents'"
				[model]="model"
				[typeId]="model._id"
				[duplicateRecord]="duplicateRecord"
				(duplicateFound)="duplicate()"
				(saveModel)="saveChangeOrder()"
				[disabled]="permissionLock"
			></document-upload>
	</div>
	</div>
	<div class="form-group">
		<div class="col-xs-6">
			<button class="btn btn-primary mr-1" type="submit" [disabled]="!changeOrderForm.form.valid || permissionLock">Save</button>
			<div *ngIf="showCancel" class="inline-block pseudo-link small capitalize" (click)="cancel()">Cancel</div>
		</div>
		<div class="col-xs-6">
			<div class="actions">
				<button *ngIf="!showCancel" type="button" class="btn btn-danger" (click)="deleteChangeOrder(model._id)" [disabled]="permissionLock"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
			</div>
		</div>
	</div>
</form>
