<header>
	<div class="row">
	    <div class="col-xs-12 col-md-8">
		    <search [(ngModel)]="filterTerm" placeholder="Filter purchase orders" filterData="text" [filterManager]="dataManager"></search>
	    </div>
	    <div class="col-xs-12 col-md-4">
	        <button type="button" class="btn btn-block btn-default btn-condensed" (click)="addClicked.emit()">Add purchase order</button>
	    </div>
	</div>
</header>

<purchase-order-list [model]="model | async" [showIndicator]="true" (onPurchaseOrderSelect)="itemSelected.emit($event)" [selectedPurchaseOrderId]="selectedPurchaseOrderId"></purchase-order-list>
