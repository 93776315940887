import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import * as dataUtils from '@/utils/dataUtils';
import { BaseComponent } from '@/components/base.component';
import { ICostModel, CostModel } from '@/models/cost.models';
import { ISupplierModel, SupplierModel } from '@/models/supplier.models';
import { IInvoiceAutoCalculateTax } from '@/models/invoice.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { Filter } from '@/services/filterList.service';
import { JobService } from '@/services/job.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { SupplierService } from '@/services/supplier.service';
import { CustomerService } from '@/services/customer.service';
import { EnvironmentService } from '@/services/EnvironmentService';
import { CostFilters } from './cost-filters';
import { PaginationComponent } from '@/utils/pagination.component';
import { handleError } from '@/utils/errors';
import { ActivatedRoute } from '@angular/router';

export type SelectableCost = ICostModel & { selected: boolean, showAlert: boolean };
function extendCost(cost: ICostModel): SelectableCost {
  let selectableCost = cost as SelectableCost;
  selectableCost.selected = false;
  selectableCost.showAlert = false;
  return selectableCost;
}

@Component({
  selector: 'costs-list',
  templateUrl: './costsList.template.html',
  host: {'class': 'costs-list-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class CostsListComponent extends BaseComponent implements OnInit {

  public filteredEntries: SelectableCost[];
  public filterOptions: Array<Object>;
  public showCreateEntry: boolean = true;
  public storedFilter: Filter[] = [];
  public selectedEntries: any = [];
  public entriesCopy: any = [];
  public selectAll: boolean = false;
  public formattedPo: any = {};
  public newEntryValidForm: boolean = true;
  public display: string = 'none';
  public selectedPO: Array<any> = [];
  public supplierModel: ISupplierModel = new SupplierModel;
  public selectedSupplier: Array<any> = [];
  public selectedSupplierEdit: any = {};
  public showAlert: boolean = false;

  public get totalEntries(): number {
    if (this.filteredEntries) {
      return this.filteredEntries.length;
    }
    return 0;
  }

  @ViewChildren('selectField') selectField: QueryList<any>;

  @ViewChild(PaginationComponent, {static: true}) pager: PaginationComponent;

  @ViewChild('supplierField', {static: false}) supplierField;

  @ViewChild('selectFieldEdit', {static: false}) selectFieldEdit;

  @ViewChild('supplierFieldEdit', {static: false}) supplierFieldEdit;

  @Input()
  model: Subject<Array<ICostModel>>;

  @Input()
  dataManipulationService: DataManipulationService<ICostModel>;

  @Input()
  salesTaxRates: IInvoiceAutoCalculateTax;

  @Input()
  useTaxRates: IInvoiceAutoCalculateTax;

  @Input()
  isTaxExempt: boolean = false;

  @Input()
  suppliers: Array<ISupplierModel>;

  @Input()
  _customerId: string;

  @Input()
  _jobId: string;

  @Input()
  _purchaseOrdersFormatted: any = [];

  @Input()
  showSuppliers: boolean = false;

  @Input()
  updatedCost: Subject<any>;

  @Output()
  onSave: EventEmitter<Object> = new EventEmitter<Object>();

  @Output()
  onDelete: EventEmitter<ICostModel> = new EventEmitter<ICostModel>();

  @Output()
  onCancel: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  onRemove: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  onMove: EventEmitter<Object> = new EventEmitter<Object>();

  private newCost: ICostModel = new CostModel();
  private editingCostId: string;
  private _purchaseOrderId: string = null;
  private _suppliersFormatted: Array<Object>;
  private entries: ICostModel[];
  private newEntries: Array<Object>;
  private poChanged: boolean = false;
  private entryToDelete: ICostModel = new CostModel();
  private savedCostDate: Date;
  private customerLocation: any;
  private saved: Subject<any> = new Subject();
  private taxRates: IInvoiceAutoCalculateTax = {};

  constructor(
    // private filterList: FilterListService,
    private route: ActivatedRoute,
    private jobService: JobService,
    private purchaseOrderService: PurchaseOrderService,
    private supplierService: SupplierService,
    private customerService: CustomerService,
    private env: EnvironmentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.updatedCost.subscribe(event => {
      if (event.status === 409) {
        this.showAlert = true;
        console.error(event.message);
      } else {
        this.clearEntryFields(this.newCost);
        this.showAlert = false;
      }
    });

    this.setFilterOptions();

    this.model.subscribe((value) => {
      setTimeout(() => {
        this.entries = value;
        this.filterEntries();
      }, 0);
    });

    this.newCost.date = JSON.parse(sessionStorage.getItem('costDate')) || new Date();
    let customerLocation = this.customerService.getCustomer(this._customerId)
      .then(customer => {
          this.customerLocation = customer.location;
      })
      .catch(handleError);
    this.saved.next('false');
    // set the tabindex for the po and supplier selects because the component attribute doesn't work
    setTimeout(() => {
      this.setTabIndexes();
    }, 100);
  }

  ngOnDestroy(): void {
    this.updatedCost.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['suppliers']) {
      this._suppliersFormatted = [];
      changes['suppliers'].currentValue.forEach(supplier => {
        this._suppliersFormatted.push({
          id: supplier._id,
          text: supplier.name,
          type: supplier.type,
          category: supplier.category
        });
      });
      this.setFilterOptions();
    }
  }

  public setNewSupplier(newSupplier): void {
    if (newSupplier.isActive) {
      let formattedNewSupplier = {
        id: newSupplier._id,
        text: newSupplier.name,
        type: newSupplier.type,
        category: newSupplier.category
    };
      this._suppliersFormatted.push(formattedNewSupplier);
      this.supplierField.items = this._suppliersFormatted;
      this.supplierField.active = [formattedNewSupplier];
      this.selectSupplier(formattedNewSupplier, this.newCost);
    }
  }

  public selectPO(value: any, cost: ICostModel): void {
    if (value) {
      this._purchaseOrderId = value.id;
      this.selectedPO = value;
      let supplier = [];
      this._purchaseOrdersFormatted.forEach(po => {
        if (po.id === value.id) {
          supplier.push({id: po.supplier._id, text: po.supplier.name, type: po.supplier.type, category: po.supplier.category});
          setTimeout(() => {
            this.selectedSupplier = supplier[0];
            cost.supplier = po.supplier;
            this.matchType(cost, this.selectedSupplier);
          }, 0);
          return;
        }
      });
    }
  }

  public clearEntryFields(cost: ICostModel): void {
    cost.date = JSON.parse(sessionStorage.getItem('costDate')) || new Date();
    cost.cost = 0;
    cost.cityTax = 0;
    cost.countyTax = 0;
    cost.mkecountyTax = 0;
    cost.stateTax = 0;
    cost.notes = '';
    cost.poNumber = null;
    cost.invoiceNumber = null;
    cost.supplier = null;
    cost.type = null;
    cost.category = "other";
    cost.disableCat = false;
    this.selectedSupplier = null;
    this.selectedPO = null;
    this.calculateTaxAmount('cityTax', cost, null);
    this.calculateTaxAmount('countyTax', cost, null);
    this.calculateTaxAmount('mkecountyTax', cost, null);
    this.calculateTaxAmount('stateTax', cost, null);
    this.selectField.forEach(select => {
      if(select.active) {
          select.active.length = 0;
      }
    });
    this.saved.next('true');
  }

  public toggleCreateEntry(): void {
    this.showCreateEntry = !this.showCreateEntry;
    if(this.showCreateEntry){
        // set the tabindex for the po and supplier selects because the component attribute doesn't work
      setTimeout(() => {
        this.setTabIndexes();
      }, 100);
    }
  }

  /**
   * Applies filters and maps the costs to selectable cost models
   * @param filters
   */
  public filterEntries(filters: Filter[] = []): void {
    const filtered =
      this.entries
        .filter(CostFilters.and(filters))
        .map(entry => extendCost(entry));
    this.filteredEntries = filtered;
  }

  /**
   * Converts the filter object to an array of filter types that can be applied
   * @param filters
   */
  public updateFilter(filters: any): void {
    this.storedFilter = CostFilters.convertFilters(filters);
    this.pager.reset();
    this.filterEntries(this.storedFilter);
  }

  public clearFilters(): void {
    // reset to the first page
    this.pager.reset();
    this.storedFilter = [];
    this.filterEntries(this.storedFilter);
  }

  // move entries
  public moveEntries(e): void {
    let moves = {
      date: e.postingDate,
      jobId: e._jobId || this._jobId,
      notes: e.note,
      costs: []
    };
    this.filteredEntries.forEach(entry => {
      if (entry.selected) {
        moves.costs.push(entry._id);
      }
    });
    this.onMove.emit(moves);
    this.filterEntries(this.storedFilter);
  }

  // select/deselect all entries
  public toggleSelectAll(): void {
    this.selectAll = ! this.selectAll;
    this.filteredEntries.forEach(cost => {
      cost.selected = this.selectAll;
      this.updateSelected(cost);
    });
  }

  // track selected entries to disable/enable "move selected" element
  public updateSelected(cost): void {
    let indexPosition = this.selectedEntries.indexOf(cost);

    if (indexPosition === -1) {
      this.selectedEntries.push(cost);
    } else if (indexPosition > -1) {
      this.selectedEntries.splice(indexPosition, 1);
    }
    // make a copy to pass to move component to force ngChanges to fire
    this.entriesCopy = this.selectedEntries.map(x => Object.assign({}, x));
  }

  // add a supplier
  openModal(): void {
    this.display = 'block';
  }

  openDropdown(i) {
    let poDropdown = document.getElementsByClassName('ui-select-toggle')[i];
    if (poDropdown) {
      poDropdown.dispatchEvent(new Event('click'));
      return false;
    }
  }

  public removePO(): void {
    this.onRemove.emit();
  }

  trackById(index, cost) {
    return cost._id;
  }

  private selectSupplier(value: any, cost: ICostModel): void {
    if (value) {
      this.selectedSupplier = value;
      this.matchType(cost, this.selectedSupplier);

      this.supplierField.items = this._suppliersFormatted;
      if (cost) {
          cost.supplier = value.id;
      } else {
          this.newCost.supplier = value.id;
      }
    }
  }
  private matchType(cost, selectedSupplier){
    let entry = cost || this.newCost;
    this.suppliers.forEach(supplier => {
      if(selectedSupplier && (selectedSupplier.id === supplier._id)){
        if(supplier.category){
          entry.disableCat = true;
        } else {
          entry.disableCat = false;
        }
        selectedSupplier.type = supplier.type;
        selectedSupplier.category = supplier.category;
        entry.type = supplier.type;
        entry.category = supplier.category;
      }
    });
  }
  private editSupplier(value: any, cost: ICostModel): void {
    this.selectedSupplierEdit = value;
    this.matchType(cost, this.selectedSupplierEdit);
    this.supplierFieldEdit.items = this._suppliersFormatted;
    cost.supplier = value.id;
    this.supplierFieldEdit.active = [];
    this.supplierFieldEdit.active.push({id: value.id, text: value.text});
    let ngselect = 3
    if(!this.showCreateEntry){
      ngselect = 1
    }
  }

  private removeSupplier(value: any, cost: ICostModel): void {
    if (cost) {
      this.selectedSupplierEdit = null;
      cost.supplier = null;
      cost.category = 'other';
      cost.disableCat = false;
    } else {
      this.selectedSupplier = null;
      this.newCost.supplier = null;
      this.newCost.category = 'other';
      this.newCost.disableCat = false;
    }
  }

  private setCostType(cost: ICostModel, type: string, isNew: boolean): void {
    cost.type = type;
    cost.category = 'other';
    cost.disableCat = false;
    this.taxRates = this.useTaxRates;

    // Remove city tax if job is not in Milwaukee - removed per KD request 1/4/24
    // this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
		// this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    // this.jobService.getJob(this._customerId, this._jobId).then((job)=> {
		// 	if (job.address.city !== "Milwaukee") {
		// 		delete this.taxRates['cityTax'];
		// 	}
		// });
  }

  private calculateAllTaxAmounts(destination: ICostModel, editing: boolean): void {
    // do not calculate tax for Pewaukee Outside Services
    if (this.customerLocation === this.env.PEWAUKEE_ID && destination.type === 'Outside Services') {
      return;
    } else {
      let rates = this.taxRates;
      for (let rate in rates) {
        let taxRate = null;
        if (editing) {
          taxRate = rate + 'Rate';
        }
        this.calculateTaxAmount(rate, destination, taxRate);
      }
    }
  }

  private calculateTaxAmount(rateName: string, destination: ICostModel, taxRate: string): void {
    if (this.isTaxExempt) {
      destination[rateName] = 0;
    } else {
      let rates = this.taxRates;
      if (taxRate) {
        destination[rateName] = dataUtils.sanitizeNumber(destination.cost * (destination[taxRate] / 100), 2);
      } else {
        destination[rateName] = dataUtils.sanitizeNumber(destination.cost * (rates[rateName] / 100), 2);
      }
    }
  }

  private calculateAllTaxRates(destination: ICostModel): void {
    let rates = this.taxRates;
    for (let rate in rates) {
      let taxRate = rate + 'Rate';
      this.calculateTaxRate(taxRate, rate, destination);
    }
  }

  private calculateTaxRate(rateName: string, taxName: string, destination: ICostModel): void {
    setTimeout(() => {
      if (this.isTaxExempt) {
        destination[rateName] = 0;
      } else {
        destination[rateName] = dataUtils.sanitizeNumber((destination[taxName] / destination.cost) * 100);
      }
    },         0);
  }

  private editCost(cost: ICostModel, event: Event): void {
    this.formattedPo = null;
    if (cost.supplier != null) {
      this.suppliers.forEach(supplier => {
        if(cost.supplier._id === supplier._id){
          if(supplier.category){
            cost.disableCat = true;
          } else {
            cost.disableCat = false;
          }
        }
      });
      setTimeout(() => {
        let supplierFormatted = {id: cost.supplier._id, text: cost.supplier.name, type: cost.supplier.type, category: cost.supplier.category};
        this.selectedSupplierEdit = supplierFormatted;
      },0);
    }
    this.calculateAllTaxRates(cost);
    this.editingCostId = cost._id;
    if (cost.poId) {
      this._purchaseOrdersFormatted.forEach(po => {
        if (po.id === cost.poId) {
          let activePO = {id: cost.poId, text: po.text}
          this.formattedPo = activePO;
          return;
        }
      });
    } else {
      this.formattedPo = null;
    }

    if (event) {
      event.stopPropagation();
    }
    setTimeout(() => {
      document.getElementById('setEditFocus').focus();
      // set the tabindex for the po and supplier selects because the component attribute doesn't work
      let attachPODropdownEditInput = document.getElementById('poDropdownEdit').getElementsByTagName('input')[0];
      attachPODropdownEditInput.setAttribute('tabindex', '30')
      let supplierDropdownEditInput = document.getElementById('supplierDropdownEdit').getElementsByTagName('input')[0];
      supplierDropdownEditInput.setAttribute('tabindex', '31')
    }, 0);
  }

  private editPO(event: any, cost: ICostModel): void {
    this.poChanged = true;
    if (event && (cost.poId !== event.id)) {
      // copy the cost so it can be deleted on save
      this.entryToDelete = Object.assign({}, cost);
      cost.poId = event.id;
    }
    let ngselect = 2
    if(!this.showCreateEntry){
      ngselect = 0
    }
    this._purchaseOrdersFormatted.forEach(po => {
      if (event && (po.id === event.id)) {
        let supplierFormatted = {id: po.supplier._id, text: po.supplier.name, type: po.supplier.type, category: po.supplier.category};
        this.selectedSupplierEdit = supplierFormatted;
        cost.supplier = po.supplier;
        this.matchType(cost, supplierFormatted);
        return;
      }
    });
  }

  private saveCost(cost: ICostModel, event: Event): void {
    if(cost){
      delete cost['showAlert'];
      delete cost['cityTaxRate'];
      delete cost['countyTaxRate'];
      delete cost['stadiumTaxRate'];
      delete cost['mkecountyTaxRate'];
      delete cost['stateTaxRate'];
      delete cost['disableCat'];
    }

    // save date for restting afetr save
    sessionStorage.setItem('costDate', JSON.stringify(cost.date));

    // delete the old cost and make a new one with updated po
    if (this.poChanged) {
      this.entryToDelete.hideMessage = true;
      this.onDelete.emit(this.entryToDelete);
      this.entryToDelete = new CostModel();
      delete cost['_id'];
      this.poChanged = false;
    }

    if (!cost.date || !cost.type) {
      this.newEntryValidForm = false;
    } else {
      this.newEntryValidForm = true;
      delete cost['selected'];
      delete cost['hideMessage'];
      let costObject = {
        _purchaseOrderId: cost.poId || this._purchaseOrderId || null,
        entry: cost
      };
      this.onSave.emit(costObject);
      this.editingCostId = null;
      this.saved.next('true');
      if (event) {
          event.stopPropagation();
      }
    }
    this.selectedPO = [];
    this._purchaseOrderId = null;
    if(this.showCreateEntry){
      document.getElementById('setFocus').focus();
    }
  }

  private deleteCost(cost: ICostModel, event: Event): void {
    if (this.warnOnDelete('cost')) {
      this.onDelete.emit(cost);
      this.editingCostId = null;
    }

    if (event) {
      event.stopPropagation();
    }
  }

  private cancel(event: Event): void {
    this.onCancel.emit();
    this.editingCostId = null;
    this.clearFilters();
    if (event) {
      event.stopPropagation();
    }
  }

  private setFilterOptions(): void {
    this.filterOptions = [
      {
        input: 'select',
        label: 'Type',
        size: '2',
        options: ['Materials', 'Outside Services'],
        type: 'type'
      },
      {
        input: 'date',
        label: 'Posting date range',
        size: '3',
        options: '',
        type: 'dateRange',
        startDate: '',
        endDate:  new Date().setHours(0, 0, 0, 0)
      },
      {
        input: 'text',
        label: 'Invoice #',
        size: '2',
        options: null,
        type: 'invoiceNumber'
      },
      {
        input: 'text',
        label: 'Purchase order',
        size: '2',
        options: null,
        type: 'poNumber'
      },
      {
        input: 'select',
        label: 'Supplier',
        size: '3',
        options: this._suppliersFormatted,
        type: 'supplier'
      }
    ];
  }
  private setTabIndexes():void {
    let attachPODropdownInput = document.getElementById('attachPODropdown').getElementsByTagName('input')[0];
    attachPODropdownInput.setAttribute('tabindex', '9')
    let supplierDropdownInput = document.getElementById('supplierDropdown').getElementsByTagName('input')[0];
    supplierDropdownInput.setAttribute('tabindex', '10')
  }
}
