import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, map } from 'rxjs';

interface FileUploadError {
  name: string;
  message: string;
}

@Component({
	selector: 'file-uploader',
	templateUrl: './file-upload.component.html',
	host: {'class': 'file-uploader-component'}
})
export class FileUploadComponent {

	files: File[] = [];

  public fileUploadErrors: FileUploadError[] = [];

	nonUniqueFilename: boolean = false;

	@Input()
	file: File;

	@Input()
  set errors(obs: Observable<string>) {
	  obs.pipe(map(k => {
      if (k === 'payload_too_large') {
        return { name: 'max_size_server', message: `File sizes exceeds server limit.`};
      }
      return { name: 'server_error', message: `Upload failed. Unknown cause.` };
    })).subscribe(s => {
      if (this.fileUploadErrors.findIndex(e => e.name !== s.name) === -1) {
        this.fileUploadErrors.push(s);
      }
    });
  }

	// enables two-way binding on the model
	@Output() fileChange: EventEmitter<File[]> = new EventEmitter<File[]>();

	@Output()
  isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  // the AWS API Gateway sets a 10 MB hard limit on uploads so hard for now
	readonly MAX__SIZE_MB: number = 10;
	readonly MAX_SIZE: number = this.MAX__SIZE_MB * 1024 * 1024;

	private totalSize: number = 0;

  uploadFile(newFiles: FileList): void {
    // clear current errors on upload
    this.fileUploadErrors = [];

    for (let index = 0; index < newFiles.length; index++) {
			const element = newFiles[index];
			if (!this.files.find((file: File) => file.name === element.name)) {
				this.files.push(element);
			} else {
				this.nonUniqueFilename = true;
			}
		}

    this.totalSize = this.files.reduce((acc: number, currentValue: File) => acc + currentValue.size, 0);
    if (this.totalSize > this.MAX_SIZE) {
      this.fileUploadErrors.push({ name: 'max_size', message: `File exceeds size limit (${this.MAX__SIZE_MB}MB).`});
    }

    this.isValid.emit(this.fileUploadErrors.length === 0);
    this.fileChange.emit(this.files);
	}

	deleteAttachment(index: number): void {
		const delFiles: File[] = this.files.splice(index, 1);
		this.fileChange.emit(this.files);
		if (delFiles.length === 0) {
		  // something went wrong, so return
      return;
    }

		const delFile: File = delFiles[0];
		if (this.totalSize > this.MAX_SIZE && (this.totalSize - delFile.size) < this.MAX_SIZE) {
      this.fileUploadErrors = this.fileUploadErrors.filter(e => e.name !== 'max_size');
    }
		this.totalSize = this.totalSize - delFile.size;
		this.isValid.emit(this.fileUploadErrors.length === 0);
	}

	clear(): void {
		this.files = [];
		this.fileUploadErrors = [];
	}
}
