<section id="login">

	<div *ngIf="isAuthenticated">
		<p>Hello {{ model.username }}</p>
		<br/>
		<a href="/users/id">View ID token claims</a>
		<br/>
		<a href="/auth/acquireToken">Acquire a token to call the Microsoft Graph API</a>
		<br/>
		<a href="/auth/signout" class="btn btn-primary">Log out</a>
	</div>
	<div *ngIf="!isAuthenticated" class="text-center">
		<p class="font-bold">Klein Dickert Costing Software</p>
		<p>Please sign in using your Azure Active Directory credentials</p>
		<a (click)="processLogin()" class="btn btn-primary mt-2">Log in to Klein Dickert Job Costing</a>
	</div>

</section>
