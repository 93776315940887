<!-- Margin-right is a hack because payables doesn't have a print button -->
<nav style="position:relative; left: -1rem; margin-right: -2rem;">
    <ul class="nav nav-tabs">
        <li>
            <a [routerLink]="['Edit']" routerLinkActive="active">Details</a>
        </li>
		<li>
            <a [routerLink]="['Documents']" routerLinkActive="active">Documents</a>
        </li>
        <li ngbDropdown class="d-inline-block export-toggle print-menu-component" autoClose="outside">
            <span href id="exportDropdown" ngbDropdownToggle>Export</span>
            <ul class="print-menu" ngbDropdownMenu aria-labelledby="exportDropdown" style="margin-top:0;">
                <li class="print-menu-option" ngbDropdownItem>
                    <button type="button" class="dropdown-item btn btn-default btn-checkbox"
                            [ngClass]="{ 'active btn-primary': stampOnNew }"
                            (click)="stampOnNew = !stampOnNew;  $event.stopPropagation();">
                        <i class="glyphicon glyphicon-ok"></i>
                    </button>Place stamp on new page
                </li>
                <li role="separator" class="divider" style="padding:0;"></li>
                <li class="print-menu-execute" ngbDropdownItem>
                    <button type="button" class="dropdown-item btn btn-primary" (click)="stamp(); $event.stopPropagation();" ngbDropdownToggle>Export</button>
                </li>
            </ul>
        </li>
    </ul>
</nav>

<router-outlet></router-outlet>
