import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IChangeOrderModel } from './../../models/changeOrder.models';

@Component({
   selector: 'change-order-list',
   templateUrl: './changeOrderList.template.html',
   host: {'class': 'change-order-list-component', id: 'sidebar-content'}
})

export class ChangeOrderListComponent {
    @Input()
    model = new Array<IChangeOrderModel>();

    @Input()
    selectedChangeOrderId : string = null;

    @Input()
	showIndicator: boolean = false;

    @Output()
    onChangeOrderSelect = new EventEmitter<IChangeOrderModel>();

    selectChangeOrder(changeOrder: IChangeOrderModel) {
        this.onChangeOrderSelect.emit(changeOrder);
    }
}
