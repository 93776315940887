import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IStaffModel } from './../../models/staff.models';

@Component({
    selector: 'staff-edit-form',
    templateUrl: './staffEditForm.template.html',
    host: {'class': 'staff-edit-form-component'}
})

export class StaffEditFormComponent {
    @Input()
    model : IStaffModel;

    @Output()
    onSave = new EventEmitter<IStaffModel>();

    @Output()
    onCancel = new EventEmitter();

    saveStaff() : void {
        this.onSave.emit(this.model);
    }

    cancel() : void {
        this.onCancel.emit(null);
    }
}
