import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, filter, tap } from 'rxjs';
import * as moment from 'moment';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { IWorkOrderModel, WorkOrderModel } from '@/models/workOrder.models';
import { JobService } from '@/services/job.service';
import { UUIDService } from '@/services/uuid.service';
import { IUserModel } from '@/models/user.models';
import { WorkflowStatus } from '@/models/workflow.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order-add',
  templateUrl: './workOrderAdd.template.html',
  host: {'class': 'work-order-add-component'}
})
export class WorkOrderAddComponent extends BaseSmartComponent implements OnInit {
	public model: Observable<IWorkOrderModel>;
	public users: Observable<Array<IUserModel>>;

  private _customerId: string;
  private _jobId: string;
	private job: any = {};
	private newLifecycle: Array<WorkflowStatus> = [];
	private initialStatus: WorkflowStatus;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		private workOrderService: WorkOrderService,
		private jobService: JobService,
		messageService: MessageService,
		private uuidService: UUIDService
	) {
		super(messageService);
	}

  ngOnInit(): void {
    this.model = this.state.getActiveWorkOrder()
      .pipe(filter(workOrder => !!workOrder && !!workOrder._id))
      .pipe(tap(workOrder => {
        this.jobService.getJob(this._customerId, this._jobId)
          .then(job => {
            workOrder.name = job.name;
            workOrder.projectManager = job.projectManager;
            this.job = {
              customer: this._customerId,
              name: job.name,
              _id: this._jobId
            };
          })
          .catch(handleError);
      }));
    this.users = this.state.getUsersList();

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    let newWorkOrder = new WorkOrderModel();
    newWorkOrder._id = this.uuidService.generateUUID();
    newWorkOrder.date = this.sanitizeDate(moment().format('YYYY-MM-DD'));

    this.state.setActiveWorkOrder(newWorkOrder);
  }

	public saveWorkOrder(newModel: IWorkOrderModel): void {
	  // remove the docs for the save and then add them back in after so view is updated
    let docs = newModel.documents;
    delete newModel.documents;
    delete newModel.css;
    newModel.job = this.job;

		// set initial status for new WOs
    if (!newModel.lifecycle || !newModel.lifecycle.length) {
      newModel.lifecycle = this.newLifecycle;
      if (newModel.priority === '1') {
        this.initialStatus = {
					status: 'ready_full',
					note: 'Priority set to 1',
					timestamp: new Date().toISOString()
				};
			} else {
				this.initialStatus = {
					status: 'pending',
					note: null,
					timestamp: new Date().toISOString()
				};
			}
      newModel.lifecycle.push(this.initialStatus);
		}

    this.workOrderService.createWorkOrder(this._customerId, this._jobId, newModel)
			.then(result => {
        this.workOrderService.getWorkOrders(this._customerId, this._jobId);
        newModel.documents = docs;
        this.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 1) });
			})
			.catch(handleError);
	}

  public cancel(): void {
    this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
  }
}
