import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { IUserModel } from '@/models/user.models';
import { handleError } from '@/utils/errors';

@Component({
	selector: 'profile-edit',
	templateUrl: './profileEdit.template.html',
  host: {'class': 'profile-edit-component'}
})
export class ProfileEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IUserModel>;

	private _userId: string;
	private _currentUser: any;
	private _locationId: string;
	private _parentRoute: ActivatedRoute;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private locationService: LocationService,
		private state: StateManager,
		messageService: MessageService
	) {
		super(messageService);
	}

	ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._userId = this._currentUser.userId;
    this._locationId = this._currentUser.locationId;
    this.model = this.state.getLocationSelectedUser();

    if (this._userId) {
      this.locationService.getUser(this._locationId, this._userId);
		}
	}

	public saveUser(newModel: IUserModel): void {
    this.locationService.updateUser(this._locationId, newModel)
			.then(() => {
        this.router.navigate(['.'], { relativeTo: this._parentRoute });
			})
			.catch(handleError);
	}

	public cancel(): void {
		this.router.navigate(['.'], { relativeTo: this._parentRoute });
	}
}
