import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable, Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {StateManager} from '@/state/stateManager';
import {BaseSmartComponent} from '@/components/base.component';
import * as routerUtils from '@/utils/routerUtils';
import {InvoiceSummaryFilterFunctions, InvoiceSummarySortFunctions} from '@/utils/dataManipulationUtils';
import * as Utility from '@/models/utility.models';
import {IAuthUser, UserRole} from '@/models/auth.models';
import {IInvoiceSummary} from '@/models/invoice.models';
import {InvoiceWorkflow, Workflow, WorkflowState, WorkflowStatus} from '@/models/workflow.models';
import {MessageService} from '@/services/message.service';
import {JobService} from '@/services/job.service';
import {WorkflowService} from '@/services/workflow.service';
import {DataManipulationService} from '@/services/dataManipulation.service';
import {RouterHistoryService} from '@/services/router-history.service';
import {AuthService} from '@/services/auth.service';
import {ListHeaderService} from '@/services/listHeader.service';
import { LocalStorageService } from '@/services/localStorage.service';
import {FilterValue, parseSearchFilterHistory, SortOptions} from '@/models/filter.models';
import {handleError} from '@/utils/errors';

@Component({
	selector: 'invoice-search',
	templateUrl: './invoiceSearch.template.html',
	host: {'class': 'invoice-search-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0
			})),
      transition('void <=> *', animate(300)),
    ]),
	]
})
export class InvoiceSearchComponent extends BaseSmartComponent implements OnInit {
	@ViewChild('invoiceSearch', {static: true})
	invoiceSearch: Utility.IFocusable;

  public searchTerm: string = '';
  public showClosed: boolean = false;
  public invoiceSet = [];
  public sortDirection: number = 1;
  public currentSort: string = 'invoiceNumber';
  public isLoading: boolean = true;
  public filterOptions: Array<Object>;
  public statusOptions: Workflow = new InvoiceWorkflow();
	public statusList: any []; // this one is for populating the list of statuses without 'unassigned'
  public filters: FilterValue[] = [];
	state$: Observable<object>;
	public selectAll: boolean = false;
	public selectedInvoices: any = [];
	public updatedStatus: string = '';
	public user: IAuthUser;
	public userSub: Subscription;
  public isAdmin: boolean = false;

  private _currentUser: any;
	private model: Subject<Array<IInvoiceSummary>> = new Subject<Array<IInvoiceSummary>>();
	private lastSearchTerm: string = '';
  private pageNumber: number = 0;
  private sortField: string = 'invoiceNumber';
  private scrollPosition: number = 0;
  private sortFields: string[] = [
    'invoiceNumber',
    'invoiceDate',
    'job.customer.name',
    'job.name',
    'status',
    'amountThisInvoice'
  ];
  private locationSub: Subscription;

  constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		private jobService: JobService,
		private dataManipulationService: DataManipulationService<IInvoiceSummary>,
		messageService: MessageService,
		protected workFlowService: WorkflowService,
		private authService: AuthService,
    private history: RouterHistoryService,
    private listHeaderService: ListHeaderService,
		private localStorageService: LocalStorageService
	) {
		super(messageService);
	}

	ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.history.currentState$.pipe(take(1))
      .subscribe(s => {
        if (s) {
          const result = parseSearchFilterHistory(s);
          this.filters = result.filters;
          if (result.searchTerm) {
            this.searchTerm = result.searchTerm;
          }

          if (result.showClosed !== undefined) {
            this.showClosed = result.showClosed;
          }
          this.initializeSort(result.sort);
        }
        this.reloadList(this.filters);
      });
    // don't include 'Unassigned' or 'Rejected' as options
    this.statusList = this.statusOptions.states.filter(function( obj ) {
			return obj.value !== null;
		});

    // get user's role
    this.userSub = this.authService.currentUser.subscribe(s => {
      if (s) {
        this.user = s;
        this.isAdmin = (this.user.role === UserRole.Admin);
      }
    });

    this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
      this.invoiceSet = [];
      this.reloadList(this.filters);
    });

    this.dataManipulationService.initialize(InvoiceSummaryFilterFunctions, InvoiceSummarySortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getInvoiceSummaryList(), this.model));
    this.dataManipulationService.setFilter('text', '');

    if (this.invoiceSearch) {
      this.invoiceSearch.focus();
		}
    this.setFilterOptions();
  }

  override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
    this.userSub.unsubscribe();
    super.ngOnDestroy();
  }

	public search(): void {
		if (this.searchTerm === '') {
      this.searchTerm = null;
		}

		if (this.searchTerm !== this.lastSearchTerm) {
      this.resetList();
      this.reloadList(this.filters);
      this.lastSearchTerm = this.searchTerm;
		}
	}

  public sortList(e): void {
    this.sortField = e.field;
    this.sortDirection = e.sortDirection;
    this.currentSort = e.currentSort;
    this.resetList();
    this.reloadList(this.filters);
  }

  public filterTheList(filters: FilterValue[]): void {
    this.isLoading = true;
    this.filters = filters;
    this.resetList();
    this.reloadList(this.filters);
  }

  public filterToggle(): void {
    this.showClosed = !this.showClosed;
    this.resetList();
    this.reloadList(this.filters);
  }

  statusClass(workflowState: WorkflowState): string {
    if (!workflowState) {
      return 'black';
    }
    return this.workFlowService.getStatusClass(workflowState);
  }

	// select/deselect all invoices
	public toggleSelectAll(): void {
		this.selectAll = ! this.selectAll;
		this.invoiceSet.forEach(invoice => {
			invoice.selected = this.selectAll;
			this.updateSelected(invoice);
		});
	}

	public updateSelected(invoice): void {
		const indexPosition = this.selectedInvoices.indexOf(invoice);

		if (indexPosition === -1) {
			this.selectedInvoices.push(invoice);
		} else if (indexPosition > -1) {
			this.selectedInvoices.splice(indexPosition, 1);
		}
	}

	// update all of the selected invoices to the status selected
	public updateStatus(): void {
		if (confirm('Are you sure you want to update all selected receivables?')) {
			let items = this.selectedInvoices.map(invoice => {
				invoice.selected = false;
				return invoice._id;
			});
			this.selectAll = false;

			// clear selected invoices
			this.workFlowService.updateWorkflowBatch('invoices', { items, status: this.updatedStatus })
				.subscribe(res => {
					if (res === items.length) {
						this.sendMessage({ title: `Successfully updated ${items.length} receivable statuses`, type: 'success', options: {}, message: '' });
					} else {
						this.sendMessage({ title: `Updated ${res} of ${items.length} receivable statuses`, type: 'error', options: {}, message: '' });
					}
					this.resetList();
					this.reloadList(this.filters);
				});
		}
	}

	public editEntity(invoice: IInvoiceSummary): void {
		let customerId = invoice.job.customer._id;
		let jobId = invoice.job._id;
		this.router.navigate(
		  ['/Customers', customerId.toString(), 'Job', jobId.toString(), 'Invoices', invoice._id, 'Edit'],
    { relativeTo: routerUtils.getParentRoute(this.route) }
    );
	}

  public onScroll(e): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadList(this.filters);
      }
    }
  }

  protected initializeSort(sort: SortOptions): any {
    if (sort) {
      this.sortDirection = sort.direction;
      if (this.sortFields.includes(sort.field)) {
        this.sortField = sort.field;
      }
    }
    this.currentSort = this.sortField;
  }

	private reloadList(filters: FilterValue[]): void {
		const extendedFilters: any[] = [...filters];
		extendedFilters.push({ filter: 'includeClosedJobsFilter', id: this.showClosed });
		// pre-set pm filter to return correct results
		if(this._currentUser?.role === 10){
			extendedFilters.push({ filter: 'projectManagerFilter', id: this._currentUser?.userId });
		}
		this.history.changeState([
		  { filter: 'searchTerm', id: this.searchTerm },
      { filter: 'sort', id: this.sortField, direction: this.sortDirection },
      ...extendedFilters
    ]);
		this.jobService.searchInvoices(30, this.searchTerm, this.sortField, this.sortDirection, this.pageNumber, extendedFilters)
			.then(invoices => {
				invoices.forEach(invoice => {
					// @ts-ignore
					const workflowState = this.statusState(invoice.status);
					invoice.css = this.statusClass(workflowState);
					invoice.status = (workflowState) ? workflowState.label : 'Unassigned';
					this.invoiceSet.push(invoice);
				});
        setTimeout(() => {
          this.listHeaderService.setListTop();
          this.isLoading = false;
        },250);
      })
			.catch(handleError);
	}

	private resetList(): void {
    this.scrollPosition = 0;
    this.pageNumber = 0;
    this.invoiceSet = [];
	  this.selectedInvoices = [];
    this.selectAll = false;
    this.isLoading = true;
	}

	private setFilterOptions(): void {
	  this.filterOptions = [
	    {
	      type: 'projectManager',
        label: 'Project Manager',
				options: null,
				preFilter: ''
			},
			{
			  type: 'status',
        label: 'Status',
        options: null,
				preFilter: ''
			}];
	}

	private statusState(status: WorkflowStatus): WorkflowState {
		if (!status) {
			return undefined;
		}
		return this.statusOptions.states.find((descr: WorkflowState) => status.status === descr.value);
	}
}
