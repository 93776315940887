import { Routes } from '@angular/router';

import { InvoiceComponent } from '../InvoiceComponent/invoice.component';
import { JobInvoiceAddComponent } from '../JobComponent/jobInvoiceAdd.component';
import { JobInvoiceEditComponent } from '../JobComponent/jobInvoiceEdit.component';
import { InvoiceConnectionComponent } from './invoiceConnection.component';
import { InvoiceDocumentsComponent } from './invoiceDocuments.component';
import { MsalGuard } from '@azure/msal-angular';

export const INVOICE_ROUTES : Routes = [
    {path: 'Add', component: JobInvoiceAddComponent},
    {path: ':invoiceId', canActivate: [ MsalGuard ], component: InvoiceComponent, children: [
        {path: '', redirectTo: 'Edit', pathMatch: 'prefix'},
        {path: 'Edit', component: JobInvoiceEditComponent},
        {path: 'PurchaseOrders', component: InvoiceConnectionComponent}, 
        {path: 'Documents', component: InvoiceDocumentsComponent}
    ]}
];
