import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilityModule } from './../../utils/utility.module';
import { ContactModule } from './../ContactComponent/contact.module';
import {IMaskModule} from 'angular-imask';
import { AddSupplierComponent } from './addSupplier.component';

@NgModule({
    declarations: [
        AddSupplierComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UtilityModule,
        ContactModule,
        IMaskModule
    ],
    exports: [
        AddSupplierComponent
    ]
})
export class AddSupplierModule {}
