<purchase-order-edit-form *ngIf="model" 
    [model]="model | async" 
    [users]="users | async | active" 
    [suppliers]="suppliers | async"
    [suppliersCopy]="suppliersCopy" 
    [newPO]="false" 
    [showCancel]="false"
    [uuid]="_purchaseOrderId"
    (onSave)="savePurchaseOrder($event)" 
></purchase-order-edit-form>
