import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'phoneFormat',
    pure : true
})

export class PhoneFormatPipe implements PipeTransform {
    transform(value : string) : string {
        if(!value) {
            return null;
        }

        let result : string = null;

        if(value.length > 10) {
            // assuming extra numbers are extension
            result = value.replace(/^(\d{3})(\d{3})(\d{4})(\d{1,})$/, '($1) $2-$3 x$4');
        }
        else {
            result = value.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        }
        
        return result;
    }
};
