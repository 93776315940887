import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'infinite-scroll-header',
    template: `
    <div *ngIf="currentSort" (click)="sort(field,sortDirection)" [ngClass]="{ 'active': currentSort === field }">
        {{ label }}
        <span *ngIf="currentSort === field">
            <i *ngIf="sortDirection == 1" class="glyphicon glyphicon-chevron-up"></i>
            <i *ngIf="sortDirection == -1" class="glyphicon glyphicon-chevron-down"></i>
        </span>
    </div>
    `,
    host: {'class': 'infinite-scroll-header-component'}
})
export class InfiniteScrollHeaderComponent {
    @Input()
    label: string = '';

    @Input()
    field: string = '';

    @Input()
    sortDirection: number = null;

    @Input() 
    currentSort: string = '';

    @Output()
    onSort = new EventEmitter<Object>();

    public sort(field: string, sortDirection: number) : void {
        if(sortDirection == 1) {
            sortDirection = -1;
        } else {
            sortDirection = 1;
        }
        let sort = {field: field, sortDirection: sortDirection, currentSort: field};
        this.onSort.emit(sort);
    }
};
