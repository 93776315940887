import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilityModule } from './../../utils/utility.module';
import { TimeEntryListComponent } from './timeEntryList.component';

@NgModule({
    declarations: [
        TimeEntryListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        UtilityModule
    ],
    exports: [
        TimeEntryListComponent
    ]
})
export class TimeEntryModule {};
