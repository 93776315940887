import { InvoiceWorkflow, PayableWorkflow, PurchaseOrderWorkflow, Workflow, WorkflowState, WorkOrderWorkflow } from '@/models/workflow.models';

export interface ActionItem {
	isLate: boolean;
	number: number;
	job: string;
	date: string;
	css: string;
	status: string;

	// added to the field
	workflowState?: WorkflowState;
}

export interface ActionItems {
	type: string;
	items: ActionItem[];

	// the label to display on widgets
	label?: string;

	// the route name for the item
	route?: string;

	// some of the styles used a number for each type but this might benefit from replacing
	typeId?: number;

	// sort order for the item on the dashboard
	sortOrder?: string;
}

/**
 * Extends an action items collection with a route, keys, and when a value isn't defined in the summary but is
 * defined in the Workflow statuses, adds 0 for that value.
 */
export class ActionItemsDecorator {
	protected componentLabel: string;

	protected routeName: string;

	protected workflow: Workflow;

	protected typeId: number;

	constructor(componentLabel: string, routeName: string, workflow: Workflow, typeId: number) {
		this.componentLabel = componentLabel;
		this.routeName = routeName;
		this.workflow = workflow;
		this.typeId = typeId;
	}

	public extendActionItems(actionItems: ActionItems): ActionItems {
		actionItems.label = this.componentLabel;
		actionItems.route = this.routeName;
		actionItems.typeId = this.typeId;
		actionItems.items =
			actionItems.items.map(item => {
				item.workflowState = this.workflow.states.find((descr: WorkflowState) => item.status === descr.value);
				return item;
			});
		return actionItems;
	}
}

export class ActionItemsDashboard {
	public purchaseOrderActionItems: ActionItems = null;
	public workOrderActionItems: ActionItems = null;
	public payablesActionItems: ActionItems = null;
	public receivablesActionItems: ActionItems = null;

	public get count(): number {
		return this._totalCount;
	}

	private _totalCount: number = 0;

	// Jobs is typeId = 10
	private readonly _purchaseOrderDecorator = new ActionItemsDecorator('Purchase Orders', 'PurchaseOrders', new PurchaseOrderWorkflow(), 3);
	private readonly _workOrderDecorator = new ActionItemsDecorator('Work Orders', 'WorkOrders', new WorkOrderWorkflow(), 4);
	private readonly _payablesDecorator = new ActionItemsDecorator('Payables', 'Payables', new PayableWorkflow(), 2);
	private readonly _receivablesDecorator = new ActionItemsDecorator('Invoices', 'Invoices', new InvoiceWorkflow(), 1);

	set(actionItems: ActionItems[]): void {
		for (let ai of actionItems) {
			if (ai.type === 'PurchaseOrders') {
				this.purchaseOrderActionItems = this._purchaseOrderDecorator.extendActionItems(ai);
			} else if (ai.type === 'WorkOrders') {
				this.workOrderActionItems = this._workOrderDecorator.extendActionItems(ai);
			} else if (ai.type === 'Payables') {
				this.payablesActionItems = this._payablesDecorator.extendActionItems(ai);
			} else if (ai.type === 'Receivables') {
				this.receivablesActionItems = this._receivablesDecorator.extendActionItems(ai)
			}
		}
		this._totalCount = this.calculateTotalCount();
	}

	/**
	 * Generates a total count for all action items
	 *
	 * Currently workorders not included in count because work orders are not displayed on page
	 * @return {number} The total number of action items across all components
	 */
	private calculateTotalCount(): number {
		let count = 0;
		if (this.purchaseOrderActionItems) {
			count += this.purchaseOrderActionItems.items.length;
		}

		if (this.payablesActionItems) {
			count += this.payablesActionItems.items.length;
		}

		if (this.receivablesActionItems) {
			count += this.receivablesActionItems.items.length;
		}

		return count;
	}
}
