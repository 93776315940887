<print-menu [printOptions]="printOptions" (onPrint)="print()" *ngIf="!dirty"></print-menu>

<div class="screen-title">
    Job List Report
</div>

<style>
	@media screen {
		.ascii { display:none;}
	}
</style>

<div class="row report-controls">
	<div class="col-sm-12 col-md-5">
		<div class="pm-selector form-horizontal row">
			<label class="control-label col-sm-2">PM</label>
			<div class="col-sm-10">
			<select 
                class="form-control {{(currentUser.role === 10) ? 'disabled' : ''}}" 
                [(ngModel)]="pm.userId" 
                name="pm" 
                (ngModelChange)="markDirty()" 
                initialFocus
            >
                <option [ngValue]="null">All Project Managers</option>
                <option *ngFor="let user of pmReportListUsers" 
                    [ngValue]="user._id" 
                >
                    {{user.firstName}} {{user.lastName}}
                </option>
			</select>
			</div>
		</div>
	</div>
    <div class="col-sm-6 col-md-5">
        <div class="number-selector" *ngIf="reportType === 'month'">
            <label class="control-label">Month</label>
            <input autocomplete="off" class="form-control" name="month" [ngModel]="month" (ngModelChange)="month = $event; markDirty()" type="number" min="1" max="12" />
        </div>

        <div class="number-selector" *ngIf="(reportType === 'month') || (reportType === 'year')">
            <label class="control-label">Year</label>
            <input autocomplete="off" class="form-control" name="year" [ngModel]="year" (ngModelChange)="year = $event; markDirty()" type="number" min="1970" max="2200" />
        </div>

        <button class="btn btn-default" [ngClass]="{ 'active btn-primary': includeClosed }" (click)="toggleIncludeClosed()"><i class="glyphicon glyphicon-lock"></i> Include closed</button>
        <button class="btn btn-default" *ngIf="reportType === 'month'" [ngClass]="{ 'active btn-primary': includeInvoiced }" (click)="toggleIncludeInvoiced()"><i class="glyphicon glyphicon-usd"></i> Include invoiced</button>
    </div>
    <div class="col-sm-6 col-md-2 actions">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <table class="table table-select-list mb-0">
        <caption>

            <span *ngIf="reportType === 'year'">
                Report for year-end of {{ year }}
            </span>

            <span *ngIf="reportType === 'month'">
                Report for month-end of {{ month }}/{{ year }}
            </span>

            <span *ngIf="includeClosed">
                Including closed jobs
            </span>

            <span *ngIf="(reportType === 'month') && includeInvoiced">
                Including invoiced jobs
            </span>

            <span *ngIf="groupByPM">
                - grouped by Project Manager
            </span>
        </caption>
        <thead>
            <tr>
                <th scope="col" class="list-item-flag"></th>
				<th scope="col" class="list-item-flag"></th>
				<th scope="col" class="list-item-flag"></th>
				<th scope="col" class="list-item-flag"></th>
                <th scope="col" style="width:30%;">
                    <sortable-header field="number" label="Job Number" [sortManager]="dataManipulationService"></sortable-header><span style="padding:0 17px;">/</span><sortable-header field="name" label="Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" style="width:15%;">
                    <sortable-header field="projectManagerLastName" label="Project Manager" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:20%;">
                    <sortable-header field="costs" label="Total Costs" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:20%;">
                    <sortable-header field="invoices" label="Total Invoices" [sortManager]="dataManipulationService"></sortable-header>
                </th>
            </tr>
        </thead>
    </table>

    <div class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">List of jobs</caption>
            <thead class="column-spacer">
                <th scope="col" class="list-item-flag"></th>
                <th scope="col" class="list-item-flag"></th>
				<th scope="col" class="list-item-flag"></th>
				<th scope="col" class="list-item-flag"></th>
                <th scope="col" style="width:30%;"></th>
                <th scope="col" style="width:15%;"></th>
                <th scope="col" style="width:20%;"></th>
                <th scope="col" style="width:20%;"></th>
            </thead>
            <tbody>
                <tr *ngFor="let job of model | async" [hidden]="(reportType === 'month') && (job.invoiceTotal > 0) && !includeInvoiced" [@fadeInOut]>
					<td scope="row" class="list-item-flag" [ngClass]="{'icon-closed':job.isClosed}">
						<span [innerHtml]="job.isClosed | booleanIcon:'lock'" title="Closed"></span>
						<span class="ascii" *ngIf="job.isClosed" title="Closed">&empty;</span>
					</td>
					<td scope="row" class="list-item-flag" [ngClass]="{'icon-invoiced':(job.invoiceTotal > 0)}">
						<span [innerHtml]="job.invoiceTotal > 0 | booleanIcon:'send'" title="Invoiced"></span>
						<span class="ascii" *ngIf="job.invoiceTotal > 0" title="Invoiced">&uArr;</span>
					</td>
					<td scope="row" class="list-item-flag" [ngClass]="{'icon-white':job.isWhite}">
						<span [innerHtml]="job.isWhite | booleanIcon:'asterisk'" title="White"></span>
						<span class="ascii" *ngIf="job.isWhite" title="White">&lowast;</span>
					</td>
					<td scope="row" class="list-item-flag" [ngClass]="{'icon-exempt':job.isTaxExempt}">
						<span [innerHtml]="job.isTaxExempt | booleanIcon:'usd'" title="Tax Exempt"></span>
						<span class="ascii" *ngIf="job.isTaxExempt" title="Tax Exempt">$</span>
					</td>


                    <td scope="row">
                        <a (click)="editJob(job)">{{ job.number }} - {{ job.name }}</a>
                    </td>
                    <td scope="row">
                        {{ job.projectManagerFirstName }} {{ job.projectManagerLastName }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.costs | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ job.invoiceTotal | currencyFormat }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
