import { BaseModel, ISupplierReference } from './utility.models';
import { WorkflowStatus } from '@/models/workflow.models';
import * as Utility from './utility.models';


export interface IPayablePO {
	_id: string;
	name: string;
	total: number;
	number: string;
}
export interface IPayableCategory {
	id: number;
	text: string;
	total: number;
}

export interface IPayableSearchRequest {
	searchTerm: string;
}

export interface IPayableSummary extends BaseModel {
	invoiceDate: Date;
	invoiceDueDate: Date;
	invoicePostedDate: Date;
	invoiceNumber: string;
  supplier: { _id: string; name: string };
	amountThisInvoice: number;
  job: {
    _id: string;
    name: string;
    customer: {
      _id: string;
      name: string;
    };
    projectManager: {
      _id: string;
      firstName: string;
      lastName: string;
    };
  };
	status: string;
	css: string;
}

export interface PayablesSearchResult {
  total?: number;
  payables: IPayableSummary[];
}

export interface IPayableModel extends BaseModel {
	_id: string;
	invoiceDate: Date;
	invoiceDueDate: Date;
	invoicePostedDate: Date;
	invoiceNumber: string;
	supplier: ISupplierReference;
	categories: Array<IPayableCategory>;
	amount: number;
	purchaseOrders: Array<IPayablePO>;
	notes: string;
	lifecycle: Array<WorkflowStatus>;
	documents: Object;
	status: string;
	monthCosted: Date;
	exportedKey: string;
	projectManager: string | Utility.IBackReference;
}

// <editor-fold> Document models

export class PayableModel extends BaseModel implements IPayableModel {
	override _id: string = null;
	invoiceDate: Date = null;
	invoiceDueDate: Date = null;
	invoicePostedDate: Date = null;
	invoiceNumber: string = null;
	supplier: ISupplierReference = null;
	categories: Array<IPayableCategory> = null;
	amount: number = null;
	purchaseOrders: Array<IPayablePO> = [];
	notes: string = null;
	lifecycle: Array<WorkflowStatus> = [];
	documents: Object;
	status: string = '';
	monthCosted: Date = null;
	exportedKey: string = null;
	projectManager: string | Utility.IBackReference;
}

export function isIPayableModel(obj: any): obj is IPayableModel {
	return 'invoiceDate' in obj &&
		'invoiceNumber' in obj &&
		'supplier' in obj;
}

// </editor-fold>
