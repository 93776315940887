import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject, Subscription } from 'rxjs';
import { BaseComponent } from '@/components/base.component';
import { DataCopyService } from '@/services/dataCopy.service';
import { FilesService } from '@/services/files.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { PrintService } from '@/services/print.service';
import { AuthService } from '@/services/auth.service';
import * as routerUtils from '@/utils/routerUtils';
import { UI_MASKS } from '@/utils/uiMasks';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { IUserModel } from '@/models/user.models';
import { IAuthUser, UserRole } from '@/models/auth.models';
import { ISupplierModel, SupplierModel } from '@/models/supplier.models';
import * as dataUtils from '@/utils/dataUtils';
import { ExportDocumentService } from '@/services/exportDocument.service';
import { ISupplierReference } from '@/models/utility.models';

@Component({
	selector: 'purchase-order-edit-form',
	templateUrl: './purchaseOrderEditForm.template.html',
  host: {'class': 'purchase-order-edit-form-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
        opacity: 0,
        height: 0,
        overflow: 'hidden'
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})
export class PurchaseOrderEditFormComponent extends BaseComponent {

	@ViewChild('supplierField', {static: false})
	supplierField: ElementRef;

	@ViewChild('orderedBy', {static: false})
	orderedBy: ElementRef;

  	@Input()
	model: IPurchaseOrderModel;

	@Input()
	users: Array<IUserModel> = new Array<IUserModel>();

	@Input()
	suppliers: Array<ISupplierModel>;

	@Input()
	suppliersCopy: Array<ISupplierModel>; // the typeahead directive automatically adds what you type to the suppliers list which allows you to select invalid suppliers. This copy is used to compare the user's entry to valid suppliers

	@Input()
	showCancel: boolean; // todo: see if this is being used for anything other than indicating if PO is being added (true) or edited (false).
	// if not, just use newPO and remove showCancel
	@Input()
	newPO: boolean = false;

	@Input()
	uuid: string = '';

	@Input()
	printed: boolean;

	@Output()
	onSave: EventEmitter<IPurchaseOrderModel> = new EventEmitter<IPurchaseOrderModel>();

	@Output()
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	public afs: any[];
	public invalidSupplier: boolean = false;
	public invalidOrderedBy: boolean = false;
	public duplicateRecord: boolean = false;
	public setFlagByPrinting: boolean = false;
	public exportedFile: any;
	public supplierStatus: string = '';

	private _customerId: string;
	private _jobId: string;
	private _purchaseOrderId: string;
	private wantedDate: Date;
	private filesQueued: boolean = false;
	private saved: Subject<any> = new Subject();
	private poPrinted: string = null;
    public display: string = 'none';
    public supplierModel: ISupplierModel = new SupplierModel;
    public showAlert: boolean = false;
	public user: IAuthUser;
	public userSub: Subscription;
	public isAdmin: boolean = false;
	public role: number;
	public permissionLock: boolean = false;
	public tempSupplierName: String = null;
	public get exportedPdf(): string|null {
		if (this.model && this.model.exportedKey) {
				return this.model.exportedKey.replace('PurchaseOrders/', '');
		}
		return null;
	}

	constructor(
		private uiMasks: UI_MASKS,
		private dataCopyService: DataCopyService,
		private filesService: FilesService,
		private exportService: ExportDocumentService,
		private route: ActivatedRoute,
		private poService: PurchaseOrderService,
		private authService: AuthService,
		private renderer: Renderer2,
		private printService: PrintService
	) {
		super();
		this.printService.itemPrinted.subscribe((data: any) => {
			this.checkPrintedStatus();
		})
	}

	ngOnInit() {
		const self = this;
		self.parseRouteParams();
		self.saved.next('false');
		// get user's role
		self.userSub = self.authService.currentUser.subscribe(s => {
			if (s) {
				self.user = s;
				self.role = s.role;
				if (self.role === UserRole.ShopForeman) self.permissionLock = true;
				if (self.role === UserRole.Admin) {
					self.isAdmin = true;
				} else {
					self.isAdmin = false;
				}
			}
		});
		
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['model']) {
			if(this.model && this.model.supplier){
				this.tempSupplierName = this.model.supplier.name;
			}
			if (changes['model'].currentValue && changes['model'].currentValue._id) {
        		this.parseRouteParams();
			}
		}
	}

	savePurchaseOrder(): void {
		// remove if they exist on old POs
		// @ts-ignore
		delete this.model.payable;
		// @ts-ignore
		delete this.model.payables;
		this.invalidSupplier = this.validateSupplier(this.model.supplier);
		if (!this.invalidSupplier) {
			this.onSave.emit(this.model);
			this.saved.next('true');
		} else {
			this.poService.notify('Please select a valid supplier from the list', { type: 'error' });
		}
	}

	cancel(): void {
		this.onCancel.emit(null);
		this.saved.next('true');
	}

	copyJobAddress(): void {
		this.dataCopyService.copyActiveJobAddress(this.model.shipping.address);
	}

	copyLocationAddress(): void {
			this.dataCopyService.copyActiveLocationAddress(this.model.shipping.address);

			// hack: insert Klein-Dickert Milwaukee when copying location address
			this.model.shipping.address.company = 'Klein-Dickert Milwaukee';
	}

	public setMessage(e): void {
		this.filesQueued = e;
	}

	public duplicate(): void {
		this.duplicateRecord = true;
	}

	public selectSupplier(e: any): void {
		this.invalidSupplier = this.validateSupplier(e.item);
	} 

	public validateSupplier(supplier: ISupplierReference): boolean {
		let invalidSupplier = true;
		if (supplier.name && this.suppliersCopy) {
			for (let i = 0; i < this.suppliersCopy.length; i++) {
				// @ts-ignore
				if (this.suppliersCopy[i].name.toLowerCase() === supplier.name.toLowerCase()) {
					invalidSupplier = false;
					// set selected supplier to the matching supplier in case the name was typed in and not selected from the list - id and status would be missing
					this.model.supplier = this.suppliersCopy[i];
					this.tempSupplierName = this.model.supplier.name;
					break;
				}
			}
		}
		return invalidSupplier;
	}

  	public setNewSupplier(newSupplier): void {
		setTimeout(() => {
			this.suppliersCopy.push(newSupplier);
			this.selectSupplier({item:newSupplier});
			this.tempSupplierName = newSupplier.name;
		},0);
	}

	// add a supplier
  openModal(): void {
    this.display = 'block';
  }

	public validateOrderedBy(): void {
		if (this.model.orderedBy !== '-- Select --') {
			this.invalidOrderedBy = false;
			this.renderer.addClass(this.orderedBy.nativeElement, 'ng-valid');
			this.renderer.removeClass(this.orderedBy.nativeElement, 'ng-invalid');
		} else {
			this.renderer.removeClass(this.orderedBy.nativeElement, 'ng-valid');
			this.renderer.addClass(this.orderedBy.nativeElement, 'ng-invalid');
			this.invalidOrderedBy = true;
		}
	}

  public openExportedPDf(): void {
    this.exportService.getExportedPO(this._customerId, this._jobId, this._purchaseOrderId).subscribe(res => {
      if (res.status === 200) {
        const filename = res.headers.get('Content-Disposition').split('=')[1].replace(/"/g, '');
        const file = new File([res.body], filename, { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl, '_blank');
      }
    });
  }

  private parseRouteParams(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');
  }

	private saveOrder(order): void {
		this.filesService.saveFilesOrder('PO', this.model._id, order);
	}

	private setWantedDate(): void {
		this.model.shipping.wanted = dataUtils.formatDate(this.wantedDate, 'MM/DD/YYYY');
	}

	private checkPrintedStatus() {
		// on the first printing of the PO, set the status to submitted
		if (this.model && !this.model.printed) {
			this.model.printed = true;
			this.setFlagByPrinting = true;
		}
	}
}
