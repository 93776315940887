//TODO: this assumes using Bootstrap / Glyphicons for icons

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'booleanIcon',
    pure: false
})

export class BooleanIconPipe implements PipeTransform {
    constructor(private sanitizer : DomSanitizer) {}

    transform(value : boolean, activeIcon : string, inactiveIcon : string = null) : SafeHtml {
        let iconClass : string = null;

        if(value === true) {
            iconClass = activeIcon;
        }
        else {
            if(inactiveIcon) {
                iconClass = inactiveIcon;
            }
        }

        if(!iconClass) {
            return '';
        }

        return this.sanitizer.bypassSecurityTrustHtml('<span class="glyphicon glyphicon-' + this.sanitizer.sanitize(SecurityContext.HTML, iconClass) + '" aria-hidden="true"></span>');
    }
};
