import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@/services/EnvironmentService';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {
	private readonly baseUrl: string;

	constructor(private httpClient: HttpClient, private environment: EnvironmentService) {
		this.baseUrl = environment.APIBASEURL;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.startsWith('http')) {
			req = req.clone({ url: `${this.baseUrl}/${req.url}` });
		}
		return next.handle(req);
	}
}
