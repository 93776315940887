<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title" id="jobDetailReportLabel">
    Job Detail Report
</div>

<style>
    tr.caption td {
        padding: 1em 8px 8px 0 !important;
        font-weight: bold;
        border-bottom: 1px solid #b3b3b3;
    }

</style>

<div class="row report-controls print-hide">
    <div class="col-xs-12 col-lg-5">
        <div class="job-selector form-group">
            <label>Job number or job name</label>
            <search #jobSearch [(ngModel)]="_jobNumber" (onAction)="searchJobs()" placeholder="Select a job" keyboardAccelerator="9" (onKeyboardAction)="searchJobs()" [setFocus]="true"></search>

            <div class="job-info">
                <div *ngIf="!_job">
                    No job selected
                </div>

                <div *ngIf="_job">
                    {{ _job.number }} - {{ _job.name }}
                </div>
            </div>

            <div class="job-results" [hidden]="!_jobResults" #jobResultList tabIndex="-1">
                <ng-template [ngIf]="_jobResults?.length < 21">
                    <div class="job-results-item" *ngFor="let jobResult of _jobResults">
                        <div class="job-result">{{ jobResult.number }} - {{ jobResult.name }}</div>
                        <button class="btn btn-default" (click)="selectJob(jobResult)">Select</button>
                    </div>
                </ng-template>

                <div class="job-results-item" *ngIf="_jobResults?.length === 0">
                    No jobs found; please try a different search
                </div>

                <div class="job-results-item" *ngIf="_jobResults?.length > 20">
                    Too many jobs found; please refine your search and try again
                </div>
            </div>

        </div>

    </div>
    <div class="col-xs-12 col-md-6 col-lg-2">
        <div class="form-group">
            <div class="date-selector">
                <label>Start Date</label>
                <input autocomplete="off" class="form-control" name="startDate" [ngModel]="startDate | dateFormat" (ngModelChange)="startDate = sanitizeDate($event); markDirty()" type="date" />
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-2">
        <div class="form-group">
            <div class="date-selector">
                <label>End Date</label>
                <input autocomplete="off" class="form-control" name="endDate" [ngModel]="endDate | dateFormat" (ngModelChange)="endDate = sanitizeDate($event); markDirty()" type="date" />
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-lg-3 actions mt-2 form-group">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty || !_job">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <table class="table" aria-describedby="jobDetailReportLabel">
        <thead *ngIf="model" [@fadeInOut]>
            <tr>
                <th scope="col" colspan="2" style="padding:0;">
                    <caption style="display:inline-block;">
                        Report for {{ model?.number }} - {{ model?.name }}<span *ngIf="year">, {{ month }} / {{ year }}</span>
                    </caption>
                </th>
                <th scope="col" colspan="2" style="padding:0;">
                    <caption *ngIf="isTaxExempt" style="display:block;" class="text-center">
                        Tax Exempt
                    </caption>
                </th>
                <th scope="col" colspan="2" style="padding:0;" class="text-right">
                    <div class="anchors">
                        <ul>
                            <li>Job Totals:</li>
                            <li class="pseudo-link" (click)="navigate('current')">Current</li>
                            <!-- future features requested in B-91028
                                                <li class="pseudo-link" (click)="navigate('month')">Month Costs</li>
                                                <li class="pseudo-link" (click)="navigate('other')">Other</li>
                                                <li class="pseudo-link" (click)="navigate('toDate')">To Date</li> 
                                                <li class="pseudo-link" (click)="navigate('cos')">Change Orders</li>
                                            -->
                        </ul>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="model" [@fadeInOut]>
            <tr class="shaded">
                <th scope="col">
                    Job # / Name
                </th>
                <th scope="col" class="currency-display">
                    Contract
                </th>
                <th scope="col" class="currency-display">
                    Invoiced
                </th>
                <th scope="col" class="currency-display">
                    Costs
                </th>
                <th scope="col" class="currency-display">
                    GP
                </th>
                <th scope="col" class="currency-display">
                    GP %
                </th>
            </tr>
            <tr>
                <td scope="row">
                    {{ model.name }} - {{ model?.number }}
                </td>
                <td scope="row" class="currency-display">
                    {{ model.contractAmount | currencyFormat }}
                </td>
                <td scope="row" class="currency-display">
                    {{ model.invoices | currencyFormat }}
                </td>
                <td scope="row" class="currency-display">
                    {{ model.totalsToDate.subtotal + (model.totalsToDate.cityTax ? model.totalsToDate.cityTax : 0) + model.totalsToDate.countyTax + model.totalsToDate.mkecountyTax + model.totalsToDate.stateTax + model.totalsToDate.stadiumTax | currencyFormat }}
                </td>
                <td scope="row" class="currency-display">
                    {{ totals.gpAmount | currencyFormat }}
                </td>
                <td scope="row" class="currency-display">
                    {{ (totals.gp * 100).toFixed(2) }}%
                </td>
            </tr>
            <tr class="shaded">
                <th scope="col" colspan="3">
                    Customer
                </th>
                <th scope="col" colspan="3">
                    Project Manager
                </th>
            </tr>
            <tr>
                <td scope="row" colspan="3">
                    {{ model.customerName }}
                </td>
                <td scope="row" colspan="3">
                    {{ model.projectManagerFirstName }} {{ model.projectManagerLastName }}
                </td>
            </tr>
        </tbody>
    </table>

    <hr />

    <table class="table table-select-list border-right" *ngIf="model" [@fadeInOut]>
        <caption id="month">
            Month Costs
        </caption>
        <thead>
            <tr class="shaded">
                <th scope="col">
                    Post Date
                </th>
                <th scope="col">
                    Description
                </th>
                <th scope="col" *ngIf="appSettings?.showLaborCodes && model.hasLaborCodes">
                    Labor Code
                </th>
                <th scope="col" class="currency-display">
                    Billed
                </th>
                <th scope="col" class="currency-display">
                    Labor
                </th>
                <th scope="col" class="currency-display">
                    Material Subtotal
                </th>
                <th scope="col" class="currency-display">
                    Material Tax
                </th>
                <th scope="col" class="currency-display">
                    Outside Services
                </th>
                <th scope="col" class="currency-display">
                    Outside Services Tax
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cost of costs | async">
                <td scope="row">
                    {{ cost.date | dateFormat:'MM/DD/YYYY' }}
                </td>
                <td scope="row">
                    <div>
                        {{ cost.description }} <span *ngIf="cost.supplier">{{ cost.supplier.name }}</span>
                        <div *ngIf="cost.tradeLevel" class="text-uppercase x-small">{{ cost.tradeLevel }}</div>
                    </div>
                    <div *ngIf="cost.invoiceNumber">
                        Invoice number: {{ cost.invoiceNumber }}
                    </div>
                    <div *ngIf="cost.notes" class="readonly-multiline">{{ cost.notes }}</div>
                </td>
                <td scope="row" *ngIf="appSettings?.showLaborCodes && model.hasLaborCodes">
                    {{ cost.laborCodeText }}
                </td>
                <td scope="row" class="currency-display">
                    {{ cost.billed ? cost.billed.toFixed(2) : '' }}
                </td>
                <td scope="row" class="currency-display">
                    {{ keepEmpty(cost.labor) }}
                </td>
                <td scope="row" class="currency-display">
                    {{ keepEmpty(cost.cost) }}
                </td>
                <td scope="row" class="currency-display">
                    {{ keepEmpty(cost.tax) }}
                </td>
                <td scope="row" class="currency-display">
                    {{ keepEmpty(cost.other) }}
                </td>
                <td scope="row" class="currency-display">
                    {{ keepEmpty(cost.otherTax) }}
                </td>
            </tr>
        </tbody>
    </table>


    <table class="table" *ngIf="model" [@fadeInOut]>
        <caption>
            Job Totals
        </caption>
        <thead>
            <tr class="shaded">
                <th scope="col" colspan="2">
                    Post Type
                </th>
                <th scope="col" class="currency-display text-right">
                    Hours
                </th>
                <th scope="col" class="currency-display">
                    Subtotal
                </th>
                <th scope="col" class="currency-display">
                    State Tax
                </th>
                <th scope="col" class="currency-display">
                    County Tax
                </th>
                <th scope="col" class="currency-display">
                    Milwaukee<br/>County Tax
                </th>
                <th scope="col" class="currency-display">
                    City Tax
                </th>
                <th scope="col" *ngIf="hasStadiumTax" class="currency-display">
                    Stadium Tax
                </th>
                 <th scope="col" class="currency-display">
                    Total
                </th>
            </tr>
        </thead>
        <tbody>
<!-- Other -->
            <tr class="caption" id="other">
                <td scope="row" colspan="100">
                    Other
                </td>
            </tr>
            <tr *ngFor="let labor of getKeys(model.totalsOther.labor)">
                <td scope="row">
                    <div class="font-bold">{{ labor }}</div>
                </td>
                <td scope="row" class="pr-0">
                    <div class="table-subhead">Rate Type</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Regular']">Regular</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Overtime']">Overtime</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Ride time']">Ride time</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsOther.labor[labor].rateTypes['Double time']">Double time</div>
                    <div class="table-total text-right">
                        Subtotal
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsOther.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">Labor Code</div>
                        <div *ngFor="let code of model.totalsOther.labor[labor].laborCodes" class="no-wrap">
                            {{code.label}}
                        </div>
                    </div>
                </td>
                <td scope="row" class="text-right pl-0">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Regular']" class="item-total">{{model.totalsOther.labor[labor].rateTypes['Regular'].hours}}</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Overtime']" class="item-total">{{model.totalsOther.labor[labor].rateTypes['Overtime'].hours}}</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Ride time']" class="item-total">{{model.totalsOther.labor[labor].rateTypes['Ride time'].hours}}</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsOther.labor[labor].rateTypes['Double time']" class="item-total">{{model.totalsOther.labor[labor].rateTypes['Double time'].hours}}</div>
                    <div class="table-total text-right item-total">
                        {{ model.totalsOther.labor[labor].hours }}
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsOther.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">&nbsp;</div>
                        <div *ngFor="let code of model.totalsOther.labor[labor].laborCodes" class="item-total">
                            {{code.hours}}
                        </div>
                    </div>
                </td>
                <td scope="row" class="currency-display">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Regular']">{{model.totalsOther.labor[labor].rateTypes['Regular'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Overtime']">{{model.totalsOther.labor[labor].rateTypes['Overtime'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Ride time']">{{model.totalsOther.labor[labor].rateTypes['Ride time'].subtotal | currencyFormat}}</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsOther.labor[labor].rateTypes['Double time']">{{model.totalsOther.labor[labor].rateTypes['Double time'].subtotal | currencyFormat}}</div>
                    <div class="table-total text-right">
                        {{ model.totalsOther.labor[labor].subtotal | currencyFormat }}
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsOther.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">&nbsp;</div>
                        <div *ngFor="let code of model.totalsOther.labor[labor].laborCodes">
                            {{code.subtotal | currencyFormat}}
                        </div>
                    </div>
                </td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row" *ngIf="hasStadiumTax"></td>
                <td scope="row" colspan="2">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Regular']">&nbsp;</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Overtime']">&nbsp;</div>
                    <div *ngIf="model.totalsOther.labor[labor].rateTypes['Ride time']">&nbsp;</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsOther.labor[labor].rateTypes['Double time']">&nbsp;</div>
                    <div class="text-right" style="margin-top:8px;">
                        {{ model.totalsOther.labor[labor].subtotal | currencyFormat }}
                    </div>
                </td>
            </tr>
            <tr *ngIf="model.totalsOther.labor.length && model.totalsOther.labor[labor].hours">
                <td scope="row"></td>
                <td scope="row" class="table-total text-right">
                    Subtotal
                </td>
                <td scope="row" class="table-total text-right">
                    {{ model.totalsOther.labor[labor].hours }}
                </td>
                <td scope="row" class="table-total currency-display">
                    {{ model.totalsOther.labor.subtotal | currencyFormat }}
                </td>
                <td scope="row" *ngIf="hasStadiumTax"></td>
                <td scope="row" colspan="5" class="currency-display">
                    {{ model.totalsOther.labor.subtotal | currencyFormat }}
                </td>
            </tr>
            <tr *ngFor="let cost of getKeys(model.totalsOther.costs)">
                <td scope="row" class="pt-0 pb-0" colspan="2">
                    <div class="bid-number-spacer">{{ cost }}</div>
                </td>
                <td scope="row" class="text-right pt-0 pb-0"></td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsOther.costs[cost].subtotal | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsOther.costs[cost].stateTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsOther.costs[cost].countyTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsOther.costs[cost].mkecountyTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsOther.costs[cost].cityTax | currencyFormat }}</div>
                </td>
                <td scope="row" *ngIf="hasStadiumTax" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsOther.costs[cost].stadiumTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer pr-0">{{ model.totalsOther.costs[cost].subtotal + (model.totalsOther.costs[cost].cityTax ? model.totalsOther.costs[cost].cityTax : 0) + model.totalsOther.costs[cost].countyTax + model.totalsOther.costs[cost].mkecountyTax + model.totalsOther.costs[cost].stateTax + model.totalsOther.costs[cost].stadiumTax | currencyFormat }}</div>
                </td>
            </tr>
            <tr>
                <th scope="col" colspan="2">
                    Other Grand Totals
                </th>
                <th scope="col" class="currency-display">
                    <div class="item-total">{{ model.totalsOther.hours ? model.totalsOther.hours.toFixed(2) : '' }}</div>
                </th>
                <th scope="col" class="currency-display">
                    <div>{{ model.totalsOther.subtotal | currencyFormat }}</div>
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsOther.stateTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsOther.countyTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsOther.mkecountyTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsOther.cityTax | currencyFormat }}
                </th>
                <th scope="col" *ngIf="hasStadiumTax" class="currency-display">
                    {{ model.totalsOther.stadiumTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsOther.subtotal + (model.totalsOther.cityTax ? model.totalsOther.cityTax : 0) + model.totalsOther.countyTax + model.totalsOther.mkecountyTax + model.totalsOther.stateTax + model.totalsOther.stadiumTax | currencyFormat }}
                </th>
            </tr>

<!-- Current -->
            <tr class="caption" id="current">
                <td scope="row" colspan="100">
                    Current
                </td>
            </tr>
            <tr *ngFor="let labor of getKeys(model.totalsCurrent.labor)">
                <td scope="row" class="font-bold">
                    {{ labor }}
                </td>
                <td scope="row" class="pr-0">
                    <div class="table-subhead">Rate Type</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Regular']">Regular</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Overtime']">Overtime</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Ride time']">Ride time</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsCurrent.labor[labor].rateTypes['Double time']">Double time</div>
                    <div class="table-total text-right">
                        Subtotal
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsCurrent.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">Labor Code</div>
                        <div *ngFor="let code of model.totalsCurrent.labor[labor].laborCodes" class="no-wrap">
                            {{code.label}}
                        </div>
                    </div>

                </td>
                <td scope="row" class="text-right pl-0">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Regular']" class="item-total">{{model.totalsCurrent.labor[labor].rateTypes['Regular'].hours}}</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Overtime']" class="item-total">{{model.totalsCurrent.labor[labor].rateTypes['Overtime'].hours}}</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Ride time']" class="item-total">{{model.totalsCurrent.labor[labor].rateTypes['Ride time'].hours}}</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsCurrent.labor[labor].rateTypes['Double time']" class="item-total">{{model.totalsCurrent.labor[labor].rateTypes['Double time'].hours}}</div>
                    <div class="table-total text-right item-total">
                        {{ model.totalsCurrent.labor[labor].hours }}
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsCurrent.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">&nbsp;</div>
                        <div *ngFor="let code of model.totalsCurrent.labor[labor].laborCodes" class="item-total">
                            {{code.hours}}
                        </div>
                    </div>
                </td>
                <td scope="row" class="currency-display">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Regular']">{{model.totalsCurrent.labor[labor].rateTypes['Regular'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Overtime']">{{model.totalsCurrent.labor[labor].rateTypes['Overtime'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Ride time']">{{model.totalsCurrent.labor[labor].rateTypes['Ride time'].subtotal | currencyFormat}}</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsCurrent.labor[labor].rateTypes['Double time']">{{model.totalsCurrent.labor[labor].rateTypes['Double time'].subtotal | currencyFormat}}</div>
                    <div class="table-total text-right">
                        {{ model.totalsCurrent.labor[labor].subtotal | currencyFormat }}
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsCurrent.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">&nbsp;</div>
                        <div *ngFor="let code of model.totalsCurrent.labor[labor].laborCodes">
                            {{code.subtotal | currencyFormat}}
                        </div>
                    </div>
                </td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row" *ngIf="hasStadiumTax"></td>
                <td scope="row" colspan="2">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Regular']">&nbsp;</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Overtime']">&nbsp;</div>
                    <div *ngIf="model.totalsCurrent.labor[labor].rateTypes['Ride time']">&nbsp;</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsCurrent.labor[labor].rateTypes['Double time']">&nbsp;</div>
                    <div class="text-right" style="margin-top:8px;">
                        {{ model.totalsCurrent.labor[labor].subtotal | currencyFormat }}
                    </div>
                </td>
            </tr>
            <tr *ngFor="let cost of getKeys(model.totalsCurrent.costs)">
                <td scope="row" class="pt-0 pb-0" colspan="2">
                    <div class="bid-number-spacer">{{ cost }}</div>
                </td>
                <td scope="row" class="text-right pt-0 pb-0">
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsCurrent.costs[cost].subtotal | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsCurrent.costs[cost].stateTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsCurrent.costs[cost].countyTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsCurrent.costs[cost].mkecountyTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsCurrent.costs[cost].cityTax | currencyFormat }}</div>
                </td>
                <td scope="row" *ngIf="hasStadiumTax" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer"> {{ model.totalsCurrent.costs[cost].stadiumTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer pr-0">{{ model.totalsCurrent.costs[cost].subtotal + (model.totalsCurrent.costs[cost].cityTax ? model.totalsCurrent.costs[cost].cityTax : 0) + model.totalsCurrent.costs[cost].countyTax + model.totalsCurrent.costs[cost].mkecountyTax + model.totalsCurrent.costs[cost].stateTax + model.totalsCurrent.costs[cost].stadiumTax | currencyFormat }}</div>
                </td>
            </tr>
            <tr>
                <th scope="col" colspan="2">
                    Current Grand Totals
                </th>
                <th scope="col" class="currency-display">
                    <div class="item-total">{{ model.totalsCurrent.hours ? model.totalsCurrent.hours.toFixed(2) : '' }}</div>
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsCurrent.subtotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsCurrent.stateTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsCurrent.countyTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsCurrent.mkecountyTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsCurrent.cityTax | currencyFormat }}
                </th>
                <th scope="col" *ngIf="hasStadiumTax" class="currency-display">
                    {{ model.totalsCurrent.stadiumTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsCurrent.subtotal + (model.totalsCurrent.cityTax ? model.totalsCurrent.cityTax : 0) + model.totalsCurrent.countyTax + model.totalsCurrent.mkecountyTax + model.totalsCurrent.stateTax + model.totalsCurrent.stadiumTax | currencyFormat }}
                </th>
            </tr>

<!-- To Date -->
            <tr class="caption" id="toDate">
                <td scope="row" colspan="100">
                    To Date
                </td>
            </tr>
            <tr *ngFor="let labor of getKeys(model.totalsToDate.labor)">
                <td scope="row" class="font-bold">
                    {{ labor }}
                </td>
                <td scope="row" class="pr-0">
                    <div class="table-subhead">Rate Type</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Regular']">Regular</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Overtime']">Overtime</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Ride time']">Ride time</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsToDate.labor[labor].rateTypes['Double time']">Double time</div>
                    <div *ngIf="model.bidAmounts" class="bid-number text-right pr-0">
                        <span *ngIf="labor == 'Field Labor'">Field</span><span *ngIf="labor == 'Shop Labor'">Shop</span> labor bid:
                    </div>
                    <div class="table-total text-right">
                        Subtotal
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsToDate.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">Labor Code</div>
                        <div *ngFor="let code of model.totalsToDate.labor[labor].laborCodes" class="no-wrap">
                            {{code.label}}
                        </div>
                    </div>
                </td>
                <td scope="row" class="text-right pl-0">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Regular']" class="item-total">{{model.totalsToDate.labor[labor].rateTypes['Regular'].hours}}</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Overtime']" class="item-total">{{model.totalsToDate.labor[labor].rateTypes['Overtime'].hours}}</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Ride time']" class="item-total">{{model.totalsToDate.labor[labor].rateTypes['Ride time'].hours}}</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsToDate.labor[labor].rateTypes['Double time']" class="item-total">{{model.totalsToDate.labor[labor].rateTypes['Double time'].hours}}</div>
                    <div *ngIf="model.bidAmounts" class="bid-number text-right pl-0">
                        <span *ngIf="labor == 'Field Labor'">{{model.bidAmounts.fieldLabor }}</span><span *ngIf="labor == 'Shop Labor'">{{model.bidAmounts.shopLabor }}</span>
                    </div>
                    <div class="table-total text-right item-total">
                        {{ model.totalsToDate.labor[labor].hours }}
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsToDate.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">&nbsp;</div>
                        <div *ngFor="let code of model.totalsToDate.labor[labor].laborCodes" class="item-total">
                            {{code.hours}}
                        </div>
                    </div>
                </td>
                <td scope="row" class="currency-display">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Regular']">{{model.totalsToDate.labor[labor].rateTypes['Regular'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Overtime']">{{model.totalsToDate.labor[labor].rateTypes['Overtime'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Ride time']">{{model.totalsToDate.labor[labor].rateTypes['Ride time'].subtotal | currencyFormat}}</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsToDate.labor[labor].rateTypes['Double time']">{{model.totalsToDate.labor[labor].rateTypes['Double time'].subtotal | currencyFormat}}</div>
                    <div *ngIf="model.bidAmounts" class="bid-number-spacer">&nbsp;</div>
                    <div class="table-total text-right">
                        {{ model.totalsToDate.labor[labor].subtotal | currencyFormat }}
                    </div>
                    <div *ngIf="appSettings?.showLaborCodes && model.totalsToDate.labor[labor].laborCodes && model.hasLaborCodes">
                        <div class="table-subhead">&nbsp;</div>
                        <div *ngFor="let code of model.totalsToDate.labor[labor].laborCodes">
                            {{code.subtotal | currencyFormat}}
                        </div>
                    </div>
                </td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row" *ngIf="hasStadiumTax"></td>
                <td scope="row" colspan="2">
                    <div class="table-subhead">&nbsp;</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Regular']">&nbsp;</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Overtime']">&nbsp;</div>
                    <div *ngIf="model.totalsToDate.labor[labor].rateTypes['Ride time']">&nbsp;</div>
                    <div *ngIf="labor=='Field Labor' && model.totalsToDate.labor[labor].rateTypes['Double time']">&nbsp;</div>
                    <div *ngIf="model.bidAmounts" class="bid-number-spacer">&nbsp;</div>
                    <div class="text-right" style="margin-top:8px;">
                        {{ model.totalsToDate.labor[labor].subtotal | currencyFormat }}
                    </div>
                </td>
            </tr>
            <tr *ngFor="let cost of getKeys(model.totalsToDate.costs)">
                <td scope="row" class="pt-0 pb-0">
                    <div class="bid-number-spacer">{{ cost }}</div>
                </td>
                <td scope="row" class="pt-0 pb-0 pr-0">
                    <div class="bid-number font-bold text-right pr-0 pb-0">Overall {{cost}} bid:</div>
                    <div *ngIf="cost == 'Materials'">
                        <div *ngIf="model.bidAmounts.materialCosts.aluminum || model.totalsToDate.costs[cost].aluminum" class="bid-number tight text-right pr-0">Aluminum bid:</div>
                        <div *ngIf="model.bidAmounts.materialCosts.glass || model.totalsToDate.costs[cost].glass" class="bid-number tight text-right pr-0">Glass bid:</div>
                        <div *ngIf="model.bidAmounts.materialCosts.sealants || model.totalsToDate.costs[cost].sealants" class="bid-number tight text-right pr-0">Sealants bid:</div>
                        <div *ngIf="model.bidAmounts.materialCosts.brakeMetal || model.totalsToDate.costs[cost].brakeMetal" class="bid-number tight text-right pr-0">Brake metal bid:</div>
                        <div *ngIf="model.bidAmounts.materialCosts.other || model.totalsToDate.costs[cost].other" class="bid-number tight text-right pr-0">Other <span>{{cost}}</span> bid:</div>
                    </div>
                    <div *ngIf="cost == 'Outside Services'">
                        <div *ngIf="model.bidAmounts.otherCosts.equipment || model.totalsToDate.costs[cost].equipment" class="bid-number tight text-right pr-0">Equipment bid:</div>
                        <div *ngIf="model.bidAmounts.otherCosts.engineering || model.totalsToDate.costs[cost].engineering" class="bid-number tight text-right pr-0">Engineering bid:</div>
                        <div *ngIf="model.bidAmounts.otherCosts.subcontractor || model.totalsToDate.costs[cost].subcontractor" class="bid-number tight text-right pr-0">Subcontractor bid:</div>
                        <div *ngIf="model.bidAmounts.otherCosts.other || model.totalsToDate.costs[cost].other" class="bid-number tight text-right pr-0">Other <span>{{cost}}</span> bid:</div>
                    </div>
                </td>
                <td scope="row" class="text-right pl-0 pt-0 pb-0">
                    <div *ngIf="cost==='Materials'" class="bid-number font-bold pb-0">{{ ((model.bidAmounts.materialCosts.aluminum + model.bidAmounts.materialCosts.glass + model.bidAmounts.materialCosts.sealants + model.bidAmounts.materialCosts.brakeMetal + model.bidAmounts.materialCosts.other) || 0) | currencyFormat }}</div>
                    <div *ngIf="cost!='Materials'" class="bid-number font-bold pb-0">{{ ((model.bidAmounts.otherCosts.equipment + model.bidAmounts.otherCosts.engineering + model.bidAmounts.otherCosts.subcontractor + model.bidAmounts.otherCosts.other) || 0) | currencyFormat }}</div>
                    <div *ngIf="cost == 'Materials'">
                        <div *ngIf="model.bidAmounts.materialCosts.aluminum || model.totalsToDate.costs[cost].aluminum" class="bid-number tight text-right">{{ model.bidAmounts.materialCosts.aluminum | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.glass || model.totalsToDate.costs[cost].glass" class="bid-number tight text-right">{{ model.bidAmounts.materialCosts.glass | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.sealants || model.totalsToDate.costs[cost].sealants" class="bid-number tight text-right">{{ model.bidAmounts.materialCosts.sealants | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.brakeMetal || model.totalsToDate.costs[cost].brakeMetal" class="bid-number tight text-right">{{ model.bidAmounts.materialCosts.brakeMetal | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.other || model.totalsToDate.costs[cost].other" class="bid-number tight text-right">{{ model.bidAmounts.materialCosts.other | currencyFormat }}</div>
                        </div>
                    <div *ngIf="cost == 'Outside Services'">
                        <div *ngIf="model.bidAmounts.otherCosts.equipment || model.totalsToDate.costs[cost].equipment" class="bid-number tight text-right">{{ model.bidAmounts.otherCosts.equipment | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.otherCosts.engineering || model.totalsToDate.costs[cost].engineering" class="bid-number tight text-right">{{ model.bidAmounts.otherCosts.engineering | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.otherCosts.subcontractor || model.totalsToDate.costs[cost].subcontractor" class="bid-number tight text-right">{{ model.bidAmounts.otherCosts.subcontractor | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.otherCosts.other || model.totalsToDate.costs[cost].other" class="bid-number tight text-right">{{ model.bidAmounts.otherCosts.other | currencyFormat }}</div>
                    </div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer pb-0">{{ model.totalsToDate.costs[cost].subtotal | currencyFormat }}</div>
                    <div *ngIf="cost == 'Materials'">
                        <div *ngIf="model.bidAmounts.materialCosts.aluminum || model.totalsToDate.costs[cost].aluminum" class="">{{ model.totalsToDate.costs[cost].aluminum | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.glass || model.totalsToDate.costs[cost].glass" class="">{{ model.totalsToDate.costs[cost].glass | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.sealants || model.totalsToDate.costs[cost].sealants" class="">{{ model.totalsToDate.costs[cost].sealants | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.brakeMetal || model.totalsToDate.costs[cost].brakeMetal" class="">{{ model.totalsToDate.costs[cost].brakeMetal | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.materialCosts.other || model.totalsToDate.costs[cost].other" class="">{{ model.totalsToDate.costs[cost].other | currencyFormat }}</div>
                        </div>
                    <div *ngIf="cost == 'Outside Services'">
                        <div *ngIf="model.bidAmounts.otherCosts.equipment || model.totalsToDate.costs[cost].equipment" class="">{{ model.totalsToDate.costs[cost].equipment | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.otherCosts.engineering || model.totalsToDate.costs[cost].engineering" class="">{{ model.totalsToDate.costs[cost].engineering | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.otherCosts.subcontractor || model.totalsToDate.costs[cost].subcontractor" class="">{{ model.totalsToDate.costs[cost].subcontractor | currencyFormat }}</div>
                        <div *ngIf="model.bidAmounts.otherCosts.other || model.totalsToDate.costs[cost].other" class="">{{ model.totalsToDate.costs[cost].other | currencyFormat }}</div>
                    </div>

                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsToDate.costs[cost].stateTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsToDate.costs[cost].countyTax| currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsToDate.costs[cost].mkecountyTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer">{{ model.totalsToDate.costs[cost].cityTax | currencyFormat }}</div>
                </td>
                <td scope="row" *ngIf="hasStadiumTax" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer"> {{ model.totalsToDate.costs[cost].stadiumTax | currencyFormat }}</div>
                </td>
                <td scope="row" class="currency-display pt-0 pb-0">
                    <div class="bid-number-spacer pr-0">{{ model.totalsToDate.costs[cost].subtotal + (model.totalsToDate.costs[cost].cityTax ? model.totalsToDate.costs[cost].cityTax : 0) + model.totalsToDate.costs[cost].countyTax + model.totalsToDate.costs[cost].mkecountyTax + model.totalsToDate.costs[cost].stateTax + model.totalsToDate.costs[cost].stadiumTax | currencyFormat }}</div>
                </td>
            </tr>
            <tr>
                <th scope="col" colspan="2">
                    To Date Grand Totals
                </th>
                <th scope="col" class="currency-display">
                    <div class="item-total">{{ model.totalsToDate.hours ? model.totalsToDate.hours.toFixed(2) : '' }}</div>
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsToDate.subtotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsToDate.stateTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsToDate.countyTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsToDate.mkecountyTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsToDate.cityTax | currencyFormat }}
                </th>
                <th scope="col" *ngIf="hasStadiumTax" class="currency-display">
                    {{ model.totalsToDate.stadiumTax | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ model.totalsToDate.subtotal + (model.totalsToDate.cityTax ? model.totalsToDate.cityTax : 0 ) + model.totalsToDate.countyTax + model.totalsToDate.mkecountyTax + model.totalsToDate.stateTax + model.totalsToDate.stadiumTax | currencyFormat }}
                </th>
            </tr>

        </tbody>
    </table>

    <table class="table table-select-list" *ngIf="model" [@fadeInOut]>
        <caption id="cos">
            Change Orders
        </caption>
        <thead>
            <tr class="shaded">
                <th scope="col" style="width:180px;">
                    Change Order Date
                </th>
                <th scope="col" class="currency-display" style="width:220px;">
                    Amount
                </th>
                <th scope="col">
                    Description
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let changeOrder of model.changeOrders">
                <td scope="row">
                    {{ changeOrder.date | dateFormat:'MM/DD/YYYY' }}
                </td>
                <td scope="row" class="currency-display">
                    {{ changeOrder.amount | currencyFormat }}
                </td>
                <td scope="row">
                    {{ changeOrder.number }} - {{ changeOrder.description }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th scope="col">
                    Total
                </th>
                <th scope="col" class="currency-display">
                    {{ model.changeOrderTotal | currencyFormat }}
                </th>
                <th scope="col"></th>
            </tr>
        </tfoot>
    </table>
</div>
