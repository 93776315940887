import { ICostModel } from '@/models/cost.models';
import { FilterListService } from '@/services/filterList.service';

type CostModelFilter = (cost: ICostModel) => boolean;

/**
 * Builds a filter that accepts costs that match the type
 * @param type
 */
export function filterByType(type: string): CostModelFilter {
  return (cost: ICostModel) => {
    return cost.type === type;
  };
}

/**
 * Builds a filter that accepts costs that match the invoice number
 * @param invoiceNumber
 */
export function filterByInvoice(invoiceNumber: string): CostModelFilter {
  return (cost: ICostModel) => {
    return cost.invoiceNumber && cost.invoiceNumber.includes(invoiceNumber);
  };
}

/**
 * Builds a filter that accepts costs that match the purchase order number
 * @param poNumber
 */
export function filterByPurchaseOrder(poNumber: string): CostModelFilter {
  return (cost: ICostModel) => {
    return cost.poNumber && cost.poNumber.includes(poNumber);
  };
}

/**
 * Builds a filter that accepts costs that match the supplier
 * @param supplier
 */
export function filterBySupplier(supplier: string): CostModelFilter {
  return (cost: ICostModel) => {
    // @ts-ignore
    return cost.supplier && (cost.supplier === supplier || cost.supplier._id === supplier.id);
  };
}

const CostFiltersMap: Map<string, (term: string) => CostModelFilter> = new Map<string, (term: string) => CostModelFilter>();
CostFiltersMap.set('type', filterByType);
CostFiltersMap.set('invoiceNumber', filterByInvoice);
CostFiltersMap.set('poNumber', filterByPurchaseOrder);
CostFiltersMap.set('supplier', filterBySupplier);

export const CostFilters: FilterListService<ICostModel> = new FilterListService<ICostModel>(CostFiltersMap);
