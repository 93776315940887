<div id="filter-entries">
    <div *ngIf="viewTab" class="row mb-2 entry-control-tab" [@fadeInOut]>
        <div class="col-sm-12 small">
            <span (click)="toggleFilterView()" class="toggle-control"><i class="glyphicon glyphicon-filter"></i> Filter entries</span><span *ngIf="viewFilters" class="text-divider">|</span><span *ngIf="viewFilters" (click)="clearFilters()" class="pseudo-link clear">Clear</span>            
        </div>
    </div>
    <div *ngIf="viewFilters" class="row filters entry-control-content form-group" [@fadeInOut]>
        <div *ngFor="let field of filterOptions; let i = index" class="col-xs-12 col-md-6 mt-1" [ngClass]="{'col-lg-2': field.size == 2,'col-lg-3': field.size == 3}">
            <label>{{field.label}}</label>
            <div *ngIf="field.input == 'text'">
                <input id="inputField{{i}}" type="text" value="" class="form-control" (input)="filterOn($event, field.type, 'text', i)"/>
            </div>
            <div *ngIf="field.input == 'select'">
                <ng-select 
                    class="ng-select-component"
                    [clearable]="true"
                    [items]="field.options"
                    [disabled]="disabled"
                    bindLabel="text"
                    (change)="filterOn($event, field.type, 'select', i, false)"
                    (remove)="filterOn($event, field.type, 'select', i, true)"
                    placeholder="Select"
                    #selectField>
                </ng-select>
            </div>
            <div *ngIf="field.input == 'date'" style="line-height: 0; position:relative; z-index:10">
                <input 
                    class="my-date-range-picker-component" 
                    placeholder="" 
                    angular-mydatepicker 
                    name="mydaterange" 
                    (click)="dp.toggleCalendar()" 
                    [(ngModel)]="dateRange" 
                    [options]="myDpOptions" 
                    #dp="angular-mydatepicker" 
                    (dateChanged)="filterOn($event, field, 'dateRange', i)"
                    autocomplete="off"
                />
            </div>
        </div>
    </div>
</div>
