import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UI_MASKS } from './../../utils/uiMasks';

import { ILocationModel } from './../../models/location.models';

@Component({
    selector: 'location-edit-form',
    templateUrl: './locationEditForm.template.html',
    host: {'class': 'location-edit-form-component'}
})

export class LocationEditFormComponent {
    @Input()
    model : ILocationModel;

    @Input()
	showAlert : boolean = false;

	@Input()
	permissionLock: boolean;

    @Output()
    onSave = new EventEmitter<ILocationModel>();

    @Output()
    onGoToLocation: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    onCancel = new EventEmitter();

    constructor(public uiMasks : UI_MASKS) {}

    saveLocation() : void {
        this.onSave.emit(this.model);
    }

    goToLocation(): void {
        this.onGoToLocation.emit(null);
    }

    cancel() : void {
        this.onCancel.emit(null);
    }
}
