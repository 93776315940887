import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { AppVersionService } from '@/services/appVersion.service';

declare var APP_VERSION: string;

@Component({
	selector: 'app-version',
	templateUrl: './appVersion.template.html'
})
export class AppVersionComponent implements OnInit {
	public appVersion: string = '0';
	public updateActive: boolean = false;

	constructor (
		private versionService: AppVersionService
	) {}

	ngOnInit(): void {
		this.appVersion = environment.PACKAGE_VERSION;

		this.versionService.updateNotification.subscribe( status => {
			// { needsUpdate: boolean, previousHash: string, currentHash: string }
			this.updateActive = status.needsUpdate;
		});
	}

	refreshApp( event ): void {
		event.preventDefault();

		window.location.reload();
	}
}
