<article class="">
    <div class="container">
        <div class="mb-2">
            <h2>Add a new customer</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <customer-edit-form [model]="model | async" (onSave)="saveCustomer($event)" (onCancel)="cancel()" [showCancel]="true" [showAlert]="showAlert" (onGoToCust)="goToCustomer()"></customer-edit-form>
            </div>
        </div>
    </div>
</article>
