import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileUploadComponent } from '@/components/FileUploadComponent/file-upload.component';
import { DragDropDirective } from '@/components/FileUploadComponent/file-drag-drop.directive';

@NgModule({
	declarations: [
		FileUploadComponent,
		DragDropDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		FileUploadComponent,
		DragDropDirective
	]
})
export class FileUploadModule {}
