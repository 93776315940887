<div class="container fixed">
    <div class="row">
        <div class="col-md-10 col-md-offset-1 searchbox" [ngClass]="{'disabled': isLoading}">
            <search [(ngModel)]="searchTerm" large="true" (onAction)="search()" #supplierSearch placeholder="Search Suppliers" [isLoading]="isLoading"></search>
        </div>
    </div>
	<div class="row filter-row">
		<div class="col-xs-12 col-sm-4 col-md-3">
			<filter-list *ngIf="filterOptions.length" [itemType]="'supplier'" [activeFilters]="filters" [filterOptions]="filterOptions" (filterValue)="filterTheList($event)"></filter-list>
		</div>
		<div class="col-xs-12 col-sm-8 col-md-9 text-right">
            <div class="inline-block supplier-status-container color-key">
                <span class="supplier-status-icon preferred"></span><span>Preferred</span>
                <span class="supplier-status-icon neutral ml-2"></span><span>Neutral</span>
                <span class="supplier-status-icon onwatch ml-2"></span><span>On Watch</span>
            </div>
            <div class="inline-block list-actions ml-3">
                <button type="button" class="btn btn-default mr-1" (click)="filterToggle('showInactive')" [ngClass]="{'active btn-primary':showInactive}"><i class="glyphicon glyphicon-lock"></i><span *ngIf="showInactive"> Hide</span><span *ngIf="!showInactive"> Show</span> inactive</button>

                <button type="button" class="btn btn-cta" (click)="addSupplier()">Add supplier</button>
            </div>
		</div>
	</div>

    <div class="row">
        <div class="col-md-12 mt-1" id="infinite-scroll-list">
            <table id="wo-list" class="table table-select-list mb-0">
				<caption class="sr-only">Sort list links</caption>
                <thead>
                    <tr>
                        <th scope="col" style="width:45%">
                            <infinite-scroll-header class="sortable" label="Name" field="name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
                        </th>
                        <th scope="col" style="width:20%">
                            <infinite-scroll-header class="sortable" label="Status" field="status" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
                        </th>
                        <th scope="col" style="width:20%">Phone</th>
                        <th scope="col" class="list-actions" style="width:15%"></th>
                    </tr>
                </thead>
            </table>
			<div id="top-of-list-marker"></div>
        </div>
    </div>
</div>
<div class="container list-container">
    <div class="attached"		
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="true"
        (scrolled)="onScroll($event)">
        <div class="attached infinite-scroll-list" *ngIf="!isLoading" [@fadeInOut]>
            <table id="supplier-list" class="table table-select-list">
            <caption class="sr-only">List of suppliers</caption>
            <thead>
                <tr>
                    <th scope="col" style="width:45%"></th>
                    <th scope="col" style="width:20%"></th>
                    <th scope="col" style="width:20%"></th>
                    <th scope="col" style="width:15%"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let supplier of supplierSet" (click)="editSupplier(supplier._id)" [ngClass]="supplier.status" >
                    <td scope="row">
                        <span [innerHtml]="!supplier.isActive | booleanIcon:'lock'"></span> {{ supplier.name }}
                    </td>
                    <td scope="row">
                        <div *ngIf="supplier.status">{{ supplierLabel(supplier.status) }}</div>
                    </td>
                    <td scope="row">
                        {{ supplier.main | phoneFormat }}
                    </td>
                    <td scope="row" class="list-actions">
                        <button class="btn btn-default" type="button" (click)="editSupplier(supplier._id)">Edit</button>
                    </td>
                </tr>
            </tbody>
            </table>
        </div>
        <div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
    </div>
</div>
