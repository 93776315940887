import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, Subject, filter } from 'rxjs';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { IUserModel } from '@/models/user.models';
import { SupplierService } from '@/services/supplier.service';
import { ISupplierModel } from '@/models/supplier.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { SupplierSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'purchase-order-edit',
  templateUrl: './purchaseOrderEdit.template.html',
  host: {'class': 'purchase-order-edit-component'}
})
export class PurchaseOrderEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IPurchaseOrderModel>;
  private _customerId: string;
  private _jobId: string;
  private _purchaseOrderId: string;
  private users: Observable<Array<IUserModel>>;
  private suppliers: Subject<Array<ISupplierModel>> = new Subject<Array<ISupplierModel>>();
  private suppliersCopy: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    private supplierService: SupplierService,
    private supplierManager: DataManipulationService<ISupplierModel>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActivePurchaseOrder();
    this.users = this.state.getUsersList();

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');

    // todo should be able to subscribe to activated route events but paramMap, params, and queryMap aren't firing so using this for now.
    // todo route.url is firing but only for the "Edit" portion of the field so maybe how the route is navigated is the issue
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');
      });

    this.supplierManager.initialize({}, SupplierSortFunctions);
    this.watchSubscription(this.supplierManager.connectDataSource(this.state.getSuppliersList(), this.suppliers));
    this.supplierManager.setSort('name', false);

    this.supplierService.getSuppliers(0)
      .then(data => this.suppliersCopy = JSON.parse(JSON.stringify(data)));
  }

  private savePurchaseOrder(newModel: IPurchaseOrderModel): void {
    // remove the docs for the save and then add them back in after so view is updated
    let docs = newModel.documents;
    delete newModel.documents;
    delete newModel.css;

    this.purchaseOrderService.updatePurchaseOrder(this._customerId, this._jobId, newModel)
      .then((result) => {
          this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId);
          setTimeout(() => {
            if (docs) {
              newModel.documents = docs;
            }
            console.info('PO saved');
          }, 0);
      })
      .catch(handleError);
  }
}
