import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiBaseService} from './apiBase.service';
import {MessageService} from './message.service';
import { EnvironmentService } from './EnvironmentService';
import {StateManager} from '@/state/stateManager';
import {IUserModel, UserModel} from '@/models/user.models';
import {IBackReference} from '@/models/utility.models';
import {IAuthUser, UserRole} from '@/models/auth.models';
import { share } from 'rxjs';

export type Role = {
  id: UserRole,
  label: string,
};

@Injectable()
export class UserService extends ApiBaseService {
  private roles: Role[] = [
    { id: UserRole.Admin,	label: 'Admin' },
    { id: UserRole.President,	label: 'President' },
    { id: UserRole.ProjectManager,	label: 'Project Manager' },
    { id: UserRole.ShopForeman,	label: 'Shop Foreman' },
    { id: UserRole.VicePresident,	label: 'Vice President' }
  ];

	constructor 
	(private _http: HttpClient, 
		private state: StateManager, 
		messageService: MessageService,
		private env: EnvironmentService
	) {
		super(messageService);
		this.baseUrl = 'users';
	}

	getCurrentUser(): Promise<IAuthUser> {
		let result = this._http.get<IAuthUser>('/me');
		return this.extract(result, (s) => {
			this.state.setCurrentUser(s);
		});
	}

	getUsers(): Promise<Array<IUserModel>> {
		const self = this;
		let result = self._http.get<Array<UserModel>>(this.baseUrl);
		return self.extract(result, (s) => {
			s.sort((a, b) => {
				if (a.lastName > b.lastName) { return 1; }
				if (a.lastName < b.lastName) { return -1; }
				return 0;
			});
			self.state.setUsersList(s);
		});
	}

	getByRole(locationId: string, role: UserRole): Promise<Array<IUserModel>> {
		const request = {
			locationId: locationId,
			role: role
		};

		let result = this._http.post<Array<UserModel>>(this.baseUrl + '/byRole', request).pipe(share());
		return this.extract(result, (s) => {
		  this.state.setPMList(s);
		});
	}

	checkLocation(): string {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser.locationId === this.env.MADISON_ID) {
			return 'madison';
		} else {
			return 'pewaukee';
		}
	}

	switchLocation(locationId: string): Promise<IAuthUser> {
		let result = this._http.post<IAuthUser>('/me/switchLocations', { locationId }).pipe(share());
		return this.extract(result, (s) => {
			this.state.setCurrentUser(s);
		});
	}

	getUserByID(id, users): IBackReference {
		let result = null;
		users.forEach(user => {
			if (id === user._id) {
				result = user;
			}
		});
		return result;
	}

	getRoles(): Role[] {
		return this.roles;
	}

	getRole(id: UserRole|number): string {
	  const role = this.roles.find(r => r.id === id);
	  return (role) ? role.label : '';
	}
}
