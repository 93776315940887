import { BaseModel, IBackReference, ISearchRequest, ISupplierReference } from './utility.models';
import { WorkflowStatus } from '@/models/workflow.models';
import * as Part from './part.models';
import * as Receipt from './receipt.models';
import { IAddress } from './address.models';

export interface IJobBackref extends IBackReference {
	customer: IBackReference;
}

interface IPurchaseOrderShipToAddress extends IAddress {
	company: string;
}

export interface IPurchaseOrderShipping {
	address: IPurchaseOrderShipToAddress;
	wanted: string;
	shipVia: string;
	fob: string;
	terms: string;
}

export interface IPurchaseOrderSearchRequest extends ISearchRequest { }

export interface IPurchaseOrderModel extends BaseModel {
	_id: string;
	number: string;
	name: string;
	date: Date;
	pendingCost: number;
	notes: string;
	exportedKey: string;
	shipping: IPurchaseOrderShipping;
	parts: Array<Part.IPurchaseOrderPartModel>;
	receipts: Array<Receipt.IReceiptModel>;
	job: IJobBackref | string;
	customer: IBackReference | string;
	workOrder: IBackReference | string;
	invoice: IBackReference | string;
	supplier: ISupplierReference;
	orderedBy: string;
	approvedBy: string;
	isComplete: boolean;
	documents: Object;
	printed: boolean;
	lifecycle: Array<WorkflowStatus>;
	status: string;
	css: string;
}

export interface UploadedDocument extends BaseModel {
	url: string;
	name: string;
}

// <editor-fold> Document models

export class PurchaseOrderModel extends BaseModel implements IPurchaseOrderModel {
	override _id: string = null;
	number: string = null;
	name: string = null;
	date: Date = null;
	pendingCost: number = null;
	notes: string = null;
	shipping: IPurchaseOrderShipping = {
		address: {
			company : null,
			address1 : null,
			address2 : null,
			city : null,
			state : null,
			zip : null,
			county: null,
			attn: null
		},
		wanted : null,
		shipVia : null,
		fob : null,
		terms : null
	};
	parts: Array<Part.IPurchaseOrderPartModel> = new Array<Part.IPurchaseOrderPartModel>();
	receipts: Array<Receipt.IReceiptModel> = new Array<Receipt.IReceiptModel>();
	job: IJobBackref | string = null;
	customer: IBackReference | string = null;
	workOrder: IBackReference | string = null;
	invoice: IBackReference | string = null;
	supplier: ISupplierReference;
	orderedBy: string = null;
	approvedBy: string = null;
	isComplete: boolean;
	documents: Object;
	printed: boolean;
	exportedKey: string;
	lifecycle: Array<WorkflowStatus>;
	status: string = 'Unassigned';
	css: string;
	constructor() {
		super();
		// trim readonly properties
		delete this.customer;
		delete this.job;
	}
}

export function isIPurchaseOrder(obj: any): obj is IPurchaseOrderModel {
	return 'shipping' in obj &&
		'parts'  in obj &&
		'receipts' in obj &&
		'supplier' in obj &&
		'orderedBy' in obj;
}

// </editor-fold>
