import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';
import { UI_MASKS } from './../../utils/uiMasks';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'shared-modal',
   templateUrl: './modal.template.html',
   host: {'class': 'shared-modal-component'},
   animations: [
    trigger('generate', [
      state('none', style({
        transform: 'scale(0)',
        opacity:0
      })),
      state('block', style({
        transform: 'scale(1)',
        opacity:1
      })),
      transition('block=>none', animate('100ms')),
      transition('none=>block', animate('250ms'))
    ]),
    trigger('fadeIn', [
      state('none', style({
        opacity:0
      })),
      state('block', style({
        opacity:1
      })),
      transition('block=>none', animate('100ms')),
      transition('none=>block', animate('250ms'))
    ]),
   ]
})

export class ModalComponent extends BaseSmartComponent implements OnInit {
    @ViewChild('childModal', {static: true}) public childModal:ModalDirective;

    @Input()
    model : any;

    @Input() title:string;

    @Input()
    display:string = 'none';

    @Input()
    type:string;

    @Input()
    showAlert : boolean = false;

    @Output()
    onClose = new EventEmitter<String>();

    @Output()
    onSave = new EventEmitter<any>();

    @Output()
    onNavigate = new EventEmitter<any>();

    public isNew = true;

    constructor(messageService : MessageService,private uiMasks : UI_MASKS) {
    super(messageService);
  }
  ngOnInit() {
  }
  show(){
    this.childModal.show();
  }
  hide(){
    this.childModal.hide();
  }
  onCloseHandled(){
    this.display='none';
    this.onClose.emit(this.display);
  }
  saveModel(model){
    this.onSave.emit(model);
  }

  goToContact(){
    this.onNavigate.emit(null);
  }

}
