<div id="list-container" class="container">
	<div class="row">
		<div class="col-sm-12">
			<div class="screen-title">
				Time Entry
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<div class="screen-subtitle pointer" (click)="toggleCreateEntry()">
				Create a labor entry
			</div>
		</div>
		<div class="col-sm-6 text-right">
			<div class="toggle" (click)="toggleCreateEntry()">
				<div *ngIf="!showCreateEntry">
					show section <div class="toggle-icon">+</div>
				</div>
				<div *ngIf="showCreateEntry">
					hide section <div class="toggle-icon">-</div>
				</div>
			</div>
		</div>
	</div>
	<section class="time-entry">
		<form *ngIf="showCreateEntry" (ngSubmit)="saveNewEntries()" #timeEntryForm="ngForm" [@fadeInOut]>
			<table class="table table-select-list no-border no-hover mb-0">
				<caption class="sr-only">Select a staff member</caption>
				<thead class="spacer">
					<th scope="col"></th>
					<th scope="col"></th>
				</thead>
				<tbody>
					<tr keyboardAccelerator="13" (onKeyboardAction)="saveTimeEntry(newTimeEntry)">
						<td scope="row" colspan="2" class="pl-0">
							<label>Staff member</label>
							<input class="form-control" required="required" autocomplete="off" 
								[typeahead]="_staff" 
								[typeaheadOptionField]="'name'" 
								[typeaheadScrollable]="true"
								[typeaheadOptionsInScrollableView]="15"
								autoFocus 
								[(ngModel)]="tempStaffName" 
								(typeaheadOnSelect)="newTimeEntry.staff = $event.item._id; setStaffRate(newTimeEntry)"						
								[disabled]="!_staff || (_staff.length < 1)" 
								data-keyboard-exclude 
								#newStaffSelector
								name="newStaffSelector"
								style="max-width:375px;" />
						</td>
						<td scope="row" colspan="5" class="error-msg small text-right" style="padding-top:2rem;">
						</td>
					</tr>
				</tbody>
			</table>
			<div *ngFor="let newTimeEntry of newTimeEntries; let i = index" class="row new-entry form-group pt-2 mb-0" [@fadeInOut]>
				<div class="col-xs-12 col-md-2 no-padding">
					<label>Date of labor</label>
					<input 
						[attr.id]="'laborDate' + i" 
						autocomplete="off" 
						class="form-control" 
						type="date" 
						[ngModel]="newTimeEntry.date | dateFormat" 
						(ngModelChange)="[newTimeEntry.date = sanitizeDate($event), checkLaborDate()]" 
						required="required" 
						name="date{{i}}" 
						#date
						[ngClass]="{ 'unmatched': newTimeEntry.date.toString() != yesterday.toString() }"
					/>
				</div>
				<div class="col-xs-6 col-md-3 pr-0">
					<label>Rate type</label>
					<select autocomplete="off" class="form-control" 
					[ngModel]="newTimeEntry.rateType" 
					(ngModelChange)="newTimeEntry.rateType = $event; setStaffRate(newTimeEntry)" 
					required="required" 
					name="newRateType{{i}}"
					#newRateType="ngModel">
						<option *ngFor="let rateType of _rateTypes" [value]="rateType">
							{{ rateType }}
						</option>
					</select>
				</div>
				<div class="col-xs-6 col-md-2 col-lg-2 no-padding">
					<label>Rate</label>
					<div class="input-group">
						<span class="input-group-addon">$</span>
						<input autocomplete="off" class="form-control mb-0" type="text" [(ngModel)]="newTimeEntry.rate" required="required" name="rate{{i}}" #rate="ngModel" enforceDecimal placeholder="0.00" />
					</div>
					<div *ngIf="newTimeEntry.rate < 0" class="error-msg x-small">Rate cannot be less than 0</div>
				</div>
				<div class="col-xs-6 col-md-3 pr-0">
					<label>Hours type</label>
					<select class="form-control" [(ngModel)]="newTimeEntry.laborType" required="required" name="newLaborType{{i}}" #newLaborType="ngModel">
						<option *ngFor="let laborType of _laborTypes" [value]="laborType">
							{{ laborType }}
						</option>
					</select>
				</div>
				<div class="col-xs-6 col-md-2">
					<label>Hours</label>
					<input autocomplete="off" class="form-control" type="text" [(ngModel)]="newTimeEntry.units" required="required" name="units{{i}}" #units="ngModel" enforceDecimal placeholder="0.00" />
				</div>
				<div class="clearfix"></div>
				<div class="col-xs-12 col-sm-9 pb-1 no-padding">
					<div class="row">
						<div class="col-xs-7 mb-1" *ngIf="appSettings?.showLaborCodes && hasLaborCodes">
							<label>Labor code</label>
							<ng-select 
								class="ng-select-component"
								[clearable]="true"
								[items]="formattedCodes"
								bindLabel="text"
								(change)="setLaborCode($event, newTimeEntry)"
								placeholder="Select"
								[ngClass]="{'error': invalid && required}"
								#selectField
								>
							</ng-select>
						</div>
						<div class="col-xs-5">
							<label>Notes</label>
							<notes-field [(model)]="newTimeEntry.notes" [saved]="saved"></notes-field>
						</div>
					</div>
					<div class="row">
						<div  style="position:relative; min-height:18px;">
							<div *ngIf="!newTimeEntry.jobView" class="col-xs-12 pseudo-link text-uppercase small mb-1 mt-1" (click)="toggleJobView(newTimeEntry)" [@fadeInOut]>
								Post to Other Job
							</div>
							<div *ngIf="newTimeEntry.jobView" class="col-xs-9" [@fadeInOut] style="position:relative;">
								<label>Job</label>
								<ng-select
									class="ng-select-component mb-1"
									[clearable]="true"
									[items]="customerJobs"
									bindLabel="text"
									(change)="setOtherJob($event, newTimeEntry)"
									placeholder="Select"
									[ngClass]="{'error': invalid && required}"
									#selectField
									required
									>
								</ng-select>
							</div>
							<div *ngIf="newTimeEntry.jobView" class="col-xs-3 pseudo-link small" style="padding-top:33px;" (click)="cancelOtherJob(newTimeEntry)" data-keyboard-exclude>
								Cancel
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-9 col-sm-2 mt-1 pb-1 pr-0 currency-display text-right">
					<div style="margin:24px 7px 0 0;">
						<span class="font-bold mr-1">Total</span>{{ (newTimeEntry.units * newTimeEntry.rate) | currencyFormat }}
					</div>
				</div>
				<div class="col-xs-12 col-xs-3 col-md-1 text-right">
					<button *ngIf="i!=0" type="button" class="btn btn-danger" (click)="removeEntry(newTimeEntry)" style="margin-top:30px;"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
				</div>
				<div class="clearfix"></div>
			</div>
			<div (click)="addTimeEntry(true)" class="add-on mt-2" [ngClass]="{'disabled': !tempStaffName}">
				<div class="add-icon">+</div><span>Add labor</span>
			</div>
			<div class="mt-2 mb-2">
				<button type="submit" class="btn btn-primary mr-1" data-keyboard-exclude [disabled]="!timeEntryForm.valid || !newTimeEntry.staff || (newTimeEntry.rate < 0) || !validLaborDate">Post entry</button><div class="inline-block pseudo-link small capitalize" (click)="clearEntryFields()">Clear fields</div>
			</div>
		</form>
		<div class="hr mt-1 mb-2"></div>
		<div class="sticky-header">
			<div class="screen-subtitle">Past Entries</div>
			<div class="entry-controls">
				<div class="entry-control">
					<filter-entries *ngIf="filterOptions.length" [filterOptions]="filterOptions" (filterValue)="updateFilter($event)" (clearAll)="clearFilters()"></filter-entries>
				</div>
				<div class="entry-control">
					<move-entry (save)="moveEntries($event)" [selectedEntries]="entriesCopy" [_customerId]="_customerId" [_jobId]="_jobId" [entryType]="'labor'"></move-entry>
				</div>
				<div class="clearfix"></div>
			</div>
			<pagination-component [totalItems]="totalEntries"></pagination-component>
			<table class="table table-select-list mb-0 no-border">
				<caption class="sr-only">Sort list links</caption>
				<thead>
					<tr class="shaded">
						<th scope="col" class="checkbox-container">
							<label class="custom-checkbox mb-0" style="position: relative; top:8px; left:1px;">
								<input type="checkbox" [ngModel]="selectAll" (change)="toggleSelectAll()">
								<span class="checkmark"></span>
							</label>
						</th>
						<th scope="col" class="date-staff">
							<sortable-header field="date" label="Date" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
						</th>
						<th scope="col" class="date-staff">
							<sortable-header field="staffName" label="Staff member" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
						</th>
						<th scope="col" class="rate">
							<sortable-header field="rate" label="Rate" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
						</th>
						<th scope="col" class="hours">
							<sortable-header field="hours" label="Hours" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
						</th>
						<th scope="col" class="total currency-display">
							<sortable-header field="total" label="Total" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
						</th>
						<th scope="col" class="buttons"></th>
					</tr>
				</thead>
			</table>
		</div>
			
		<div class="attached">
			<div class="table-select-list no-border no-hover">
				<div *ngFor="let timeEntry of filteredEntries | slice: (pager.currentPage - 1) * pager.itemsPerPage : (pager.currentPage - 1) * pager.itemsPerPage + pager.itemsPerPage; trackBy: trackById" keyboardAccelerator="13" class="row" [ngClass]="{'pt-1': timeEntry._id === editingTimeEntryId}">
					<div [@fadeInOut] *ngIf="timeEntry._id === editingTimeEntryId">
						<div class="checkbox-column">
							<div class="checkbox-container">
								<label class="custom-checkbox" style="left:-1px; top:-4px;">
									<input type="checkbox" [(ngModel)]="timeEntry.selected" [checked]="timeEntry.selected" (change)="updateSelected(timeEntry)">
									<span class="checkmark"></span>
								</label>
							</div>
						</div>
						<div class="data-entry-column" style="margin-top:-4px;">
							<div class="row mb-2 edit-view form-group">
								<div class="col-xs-6 col-lg-2 pr-0">
									<label>Posting date</label>
									<input 
										autocomplete="off" 
										class="form-control" 
										type="date" 
										[ngModel]="timeEntry.date | dateFormat" 
										(ngModelChange)="[timeEntry.date = sanitizeDate($event), checkEditLaborDate($event)]" 
										required="required" 
										#editDate="ngModel"
									/>
								</div>
								<div class="col-xs-6 col-lg-2 no-padding">
									<label>Staff member</label>
									<input 
										autocomplete="off" 
										class="form-control" 
										required="required" 
										autocomplete="off" 
										[typeahead]="_staff" 
										[typeaheadOptionField]="'name'" 
										[typeaheadScrollable]="true"
										[typeaheadOptionsInScrollableView]="15"
										[ngModel]="timeEntry.staff.name" 
										(ngModelChange)="timeEntry.staff._id = null" 
										(typeaheadOnSelect)="timeEntry.staff._id = $event.item._id; setStaffRate(timeEntry)" 
										[disabled]="!_staff || (_staff.length < 1)" 
										data-keyboard-exclude 
									/>
								</div>
								<div class="col-xs-6 col-lg-2 pr-0">
									<label>Rate type</label>
									<select class="form-control" [ngModel]="timeEntry.rateType" (ngModelChange)="timeEntry.rateType = $event; setStaffRate(timeEntry)" required="required" #editRateType="ngModel">
										<option *ngFor="let rateType of _rateTypes" [value]="rateType">
											{{ rateType }}
										</option>
									</select>
								</div>
								<div class="col-xs-6 col-lg-2 no-padding">
									<label>Rate</label>
									<div class="input-group">
										<span class="input-group-addon">$</span>
										<input autocomplete="off" class="form-control mb-0" type="text" [(ngModel)]="timeEntry.rate" required="required" #editRate="ngModel" enforceDecimal placeholder="0.00" />
									</div>
								</div>
								<div class="col-xs-6 col-lg-2 pr-0">
									<label>Hours type</label>
									<select class="form-control" [(ngModel)]="timeEntry.laborType" required="required" #editLaborType="ngModel">
										<option *ngFor="let laborType of _laborTypes" [value]="laborType">
											{{ laborType }}
										</option>
									</select>
								</div>
								<div class="col-xs-6 col-lg-2 no-padding">
									<label>Hours</label>
									<input autocomplete="off" class="form-control" type="text" [(ngModel)]="timeEntry.units" required="required" #editUnits="ngModel" enforceDecimal placeholder="0.00" />
								</div>
								<div class="col-sm-9 pb-1 pr-0">
									<div class="row">
										<div class="col-sm-7 mb-1" *ngIf="appSettings?.showLaborCodes && hasLaborCodes">
											<label class="control-label">Labor code</label>
											<ng-select 
												class="ng-select-component"
												[clearable]="true"
												[items]="formattedCodes"
												[(ngModel)]="timeEntry.laborCodeText"
												bindLabel="text"
												(change)="setLaborCode($event, timeEntry)"
												placeholder="Select"
												[ngClass]="{'error': invalid && required}"
												#selectField>
											</ng-select>
										</div>
										<div class="col-sm-5">
											<label class="control-label">Notes</label>
											<notes-field [(model)]="timeEntry.notes" [saved]="saved"></notes-field>
										</div>
									</div>
								</div>
								<div class="col-sm-3 mt-1 pb-1 currency-display text-right">
									<div style="margin:24px 7px 0 0;">
										<span class="font-bold mr-1">Total</span>{{ (timeEntry.units * timeEntry.rate) | currencyFormat }}
									</div>
								</div>
								<div class="col-xs-9 align-center">
									<button type="button" class="btn btn-primary mr-1 mb-0" [disabled]="!timeEntry.staff._id || !editRate.valid || !editRateType.valid || !editUnits.valid || !editLaborType.valid || !validEditLaborDate" (click)="saveTimeEntry(timeEntry)" data-keyboard-exclude>Save entry</button>
									<div class="inline-block pseudo-link small" (click)="cancel($event)" data-keyboard-exclude>Cancel</div>
								</div>
								<div class="col-xs-3 text-right">
									<button type="button" class="btn btn-danger" (click)="deleteTimeEntry(timeEntry, $event)"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
								</div>
								<div class="clearfix"></div>
							</div>
						</div>
					</div>

					<div [@fadeInOut] *ngIf="timeEntry._id !== editingTimeEntryId">
						<div class="col-xs-12">
							<table class="table table-select-list no-border no-hover border-bottom">
								<caption class="sr-only">List of time entries</caption>
								<thead class="spacer">
									<th scope="col"></th>
									<th scope="col"></th>
									<th scope="col"></th>
									<th scope="col"></th>
									<th scope="col"></th>
									<th scope="col"></th>
									<th scope="col"></th>
								</thead>
								<tbody>
									<tr>
										<td scope="row" class="checkbox-container">
											<label class="custom-checkbox">
												<input type="checkbox" [(ngModel)]="timeEntry.selected" [checked]="timeEntry.selected" (change)="updateSelected(timeEntry)">
												<span class="checkmark"></span>
											</label>
										</td>
										<td scope="row" class="date-staff" (click)="editTimeEntry(timeEntry, $event)">
											{{ timeEntry.date | dateFormat:'MMM DD, YYYY' }}
										</td>
										<td scope="row" class="date-staff" (click)="editTimeEntry(timeEntry, $event)">
												{{ timeEntry.staff.name }}
										</td>
										<td scope="row" class="rate" (click)="editTimeEntry(timeEntry, $event)">
											<div class="entry-label">{{ timeEntry.rateType }}</div>
											{{ timeEntry.rate | currencyFormat }}
										</td>
										<td scope="row" class="hours" (click)="editTimeEntry(timeEntry, $event)">
											<div class="entry-label">{{ timeEntry.laborType }}</div>
											{{ timeEntry.units }} hours
										</td>
										<td scope="row" class="currency-display total" (click)="editTimeEntry(timeEntry, $event)">
											{{ (timeEntry.units * timeEntry.rate) | currencyFormat }}
										</td>
										<td scope="row" class="actions buttons" (click)="editTimeEntry(timeEntry, $event)">
											<button type="button" class="btn btn-default" (click)="editTimeEntry(timeEntry, $event)">Edit</button>
										</td>
									</tr>
									<tr *ngIf="timeEntry.notes || timeEntry.laborCode">
										<td scope="row" class="checkbox-container">
										</td>
										<td scope="row" colspan="3" *ngIf="appSettings?.showLaborCodes && hasLaborCodes" (click)="editTimeEntry(timeEntry, $event)">
											<div *ngIf="timeEntry.laborCode">
												<div class="entry-label">Labor Code</div>
												<div>{{ timeEntry.laborCodeText }}</div>
											</div>
										</td>
										<td scope="row" colspan="3" *ngIf="!appSettings?.showLaborCodes || !hasLaborCodes">
										</td>
										<td scope="row" colspan="4" (click)="editTimeEntry(timeEntry, $event)">
											<div *ngIf="timeEntry.notes" >
												<div class="entry-label">
													Notes
												</div>
												<div class="readonly-multiline">{{ timeEntry.notes }}</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!filteredEntries || filteredEntries.length < 1" class="text-center large-text light mt-3">
					No results
			</div>
		</div>
	</section>
</div>
