import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { IMaskModule } from 'angular-imask';
import { StateManager, STORE_PROVIDER } from './state/stateManager';
import { ErrorHandlerProvider } from './handler/SentryErrorHandler';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app.component';
import { SiteAreaComponent } from '@/components/Navigation/siteArea.component';
import { AccountInfoComponent } from '@/components/AccountComponent/accountInfo.component';
import { AppVersionComponent } from '@/components/AppVersion/appVersion.component';


// service injection
import { MessageService, ToasterMessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { AuthService } from '@/services/auth.service';
import { LocationService } from '@/services/location.service';
import { CustomerService } from '@/services/customer.service';
import { SupplierService } from '@/services/supplier.service';
import { JobService } from '@/services/job.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { UserService } from '@/services/user.service';
import { PrintService } from '@/services/print.service';
import { DataCopyService } from '@/services/dataCopy.service';
import { ReportService } from '@/services/report.service';
import { DataManipulationService, DataManipulationServiceFactory } from '@/services/dataManipulation.service';
import { ToggleViewService } from '@/services/toggleView.service';
import { UUIDService } from '@/services/uuid.service';
import { ListHeaderService } from '@/services/listHeader.service';
import { LocalStorageService } from '@/services/localStorage.service';
import { AppVersionService } from '@/services/appVersion.service';
import { EnvironmentServiceProvider} from '@/services/EnvironmentService';
import { WorkflowService } from '@/services/workflow.service';
import { DashboardService } from '@/services/dashboard.service';
import { StartupService } from '@/services/startup.service';

// modules
import { LoginModule } from '@/components/LoginComponent/login.module';
import { ProfileModule } from '@/components/ProfileComponent/profile.module';
import { AdminModule } from '@/components/AdminComponent/admin.module';
import { LocationModule } from '@/components/LocationComponent/location.module';
import { SupplierModule } from '@/components/SupplierComponent/supplier.module';
import { CustomerModule } from '@/components/CustomerComponent/customer.module';
import { JobModule } from '@/components/JobComponent/job.module';
import { WorkOrderModule } from '@/components/WorkOrderComponent/workOrder.module';
import { PurchaseOrderModule } from '@/components/PurchaseOrderComponent/purchaseOrder.module';
import { ContactModule } from '@/components/ContactComponent/contact.module';
import { StaffModule } from '@/components/StaffComponent/staff.module';
import { UserModule } from '@/components/UsersComponent/user.module';
import { ReportModule } from '@/components/ReportComponent/report.module';
import { TimeEntryModule } from '@/components/TimeEntryComponent/timeEntry.module';
import { CostsModule } from '@/components/CostsComponent/costs.module';
import { FilterEntriesModule } from '@/components/FilterEntriesComponent/filterEntries.module';
import { FilterListModule } from '@/components/FilterListComponent/filterList.module';
import { MoveEntryModule } from '@/components/MoveEntryComponent/moveEntry.module';
import { ModalModule } from '@/components/ModalComponent/modal.module';
import { AddSupplierModule } from '@/components/AddSupplierComponent/addSupplier.module';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { DashboardModule } from '@/components/Dashboard/dashboard.module';
import { ExportModule } from '@/components/ExportComponent/export.module';
import { UtilityModule } from '@/utils/utility.module';
import { APP_ROUTES } from './app.routes';
import { httpInterceptorProviders } from '@/interceptors';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MsalRedirectComponent, MsalInterceptor, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { msalConfig } from './auth-config';
import { environment } from 'src/environments/environment';

@NgModule({
	declarations: [
		AppComponent,
		AccountInfoComponent,
		SiteAreaComponent,
		AppVersionComponent
	],
	imports: [
		BrowserAnimationsModule,
		STORE_PROVIDER,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		UtilityModule,
		RouterModule.forRoot(APP_ROUTES, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }),
		ToastrModule.forRoot({
			tapToDismiss: true,
			maxOpened: 1,
			preventDuplicates: true,
			positionClass: 'toast-top-right'
		  }),
		ToastContainerDirective,
		LoginModule,
		ProfileModule,
		AdminModule,
		LocationModule,
		SupplierModule,
		CustomerModule,
		JobModule,
		WorkOrderModule,
		PurchaseOrderModule,
		ContactModule,
		StaffModule,
		UserModule,
		ReportModule,
		TimeEntryModule,
		CostsModule,
		FilterEntriesModule,
		FilterListModule,
		MoveEntryModule,
		ModalModule,
		AddSupplierModule,
		DocumentUploadModule,
		NgbModule,
		BsDropdownModule.forRoot(),
		PaginationModule.forRoot(),
		DashboardModule,
		ExportModule,
		ServiceWorkerModule.register( '/ngsw-worker.js?v=2', { enabled: window.location.origin !== 'http://localhost:8081/v1' } ),
		IMaskModule,
		MsalModule.forRoot(
			new PublicClientApplication(msalConfig), 
			{
				interactionType: InteractionType.Redirect,
			}, 
			{
				interactionType: InteractionType.Redirect,
				protectedResourceMap: new Map([
					[environment.APIBASEURL, [`api://${environment.AZURE_CLIENT_ID}/kd-costing`]],
				])
			} as MsalInterceptorConfiguration,
		)
	],
	providers: [
		// globally provided services
		ToastrService,
		ErrorHandlerProvider,
		{ provide: MessageService, useClass: ToasterMessageService },
		httpInterceptorProviders,
		FilesService,
		AuthService,
		LocationService,
		CustomerService,
		StateManager,
		SupplierService,
		JobService,
		WorkOrderService,
		PurchaseOrderService,
		UserService,
		PrintService,
		DataCopyService,
		ReportService,
		DataManipulationServiceFactory,
		DataManipulationService,
		DashboardService,
		ToggleViewService,
		UUIDService,
		ListHeaderService,
		EnvironmentServiceProvider,
		WorkflowService,
		StartupService,
		LocalStorageService,
		AppVersionService,
		SwUpdate,
		{
			provide: APP_INITIALIZER,
			useFactory: (startup: StartupService) => () => startup.load(),
			deps: [StartupService],
			multi: true
		},
		{
			provide: ErrorHandlerProvider,
			useValue: Sentry.createErrorHandler({
			 showDialog: false,
			}),
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService
	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
