<div class="row border-bottom mb-2">
  <div class="col-xs-12 col-md-6">
    <div class="form-group">
      <select class="form-control status-options"
              name="status"
              [(ngModel)]="workflowStatus.status"
              [ngClass]="statusClass(getWorkflowStatusFromValue(workflowStatus.status))"
              (change)="toggleNotes()"
              [disabled]="disableStatus"
      >
        <option *ngFor="let status of workflow.states" [value]="status.value" [ngClass]="statusClass(status)" [disabled]="disabledState(status) || !status.value">
          {{ status.label }}
        </option>
      </select>
    </div>
    <div class="form-group" *ngIf="showNotes">
      <label class="control-label">Notes</label>
      <div class="mb-2">
        <notes-field [(model)]="workflowStatus.note" [saved]="saved"
          [required]="workflowStatus.status==='rejected' || workflowStatus.status==='on_hold' || workflowStatus.status==='void'">
        </notes-field>
      </div>
      <file-uploader *ngIf="workflowStatus.attachments" (fileChange)="setAttachments($event)"
                     [errors]="uploadErrors" (isValid)="invalidFiles($event)">
      </file-uploader>
      <div class="">
        <button type="button" class="btn btn-primary mr-1" (click)="update()"
                [disabled]="!isFilesValid || ((workflowStatus.status=='rejected' || workflowStatus.status=='on_hold' || workflowStatus.status==='void') && !workflowStatus.note)">Save Status</button>
        <div class="inline-block pseudo-link small capitalize" (click)="cancel()">Cancel</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <div class="form-group">
      <status-log [lifecycle]="lifecycle"></status-log>
    </div>
  </div>
</div>
