import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { JobService } from '@/services/job.service';
import { AuthService } from '@/services/auth.service';
import { IPayableModel } from '@/models/payable.models';
import { IAuthUser, UserRole } from '@/models/auth.models';

@Component({
  selector: 'payable-documents',
  templateUrl: './payableDocuments.template.html',
  host: {'class': 'payable-documents-component'}
})
export class PayableDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {

  // todo refactor or rename so public property isn't prefixed with _
  public _payableId: string;
  public duplicateRecord: boolean = false;
  public model: Subject<IPayableModel> = new Subject<IPayableModel>();
  public user: IAuthUser;
  public userSub: Subscription;
  public isAdmin: boolean = false;
  public role: number;
  public permissionLock: boolean = false;

  private _customerId: string;
  private _jobId: string;
  private currentPayable: any;
  private filesQueued: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private jobService: JobService,
    messageService: MessageService,
    private filesService: FilesService,
    private state: StateManager,
    private authService: AuthService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._payableId = routerUtils.getRouteParameter(this.route, 'payableId');
		// get user's role
    this.userSub = this.authService.currentUser.subscribe(s => {
		  if (s) {
        this.user = s;
        this.role = s.role;

        if (this.role === 10) {
				  this.permissionLock = true;
        }

        if (this.user) {
          if (UserRole.Admin) {
            this.isAdmin = true;
          }
				} else {
          this.isAdmin = false;
				}
		  }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  public saveOrder(order): void {
    this.filesService.saveFilesOrder('Payable', this._payableId, order);
  }

  public duplicate(): void {
    this.duplicateRecord = true;
  }

  public setMessage(e): void {
    this.filesQueued = e;
  }
}
