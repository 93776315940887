<nav style="position:relative; left: -1rem;">
    <ul class="nav nav-tabs">
        <li>
            <a [routerLink]="['Edit']" routerLinkActive="active">Details</a>
        </li>
		<li>
            <a [routerLink]="['Documents']" routerLinkActive="active">Documents</a>
        </li>
    </ul>
</nav>

<router-outlet></router-outlet>
