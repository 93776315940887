<!-- see css - text shows in header menu, notice shows in footer --> 
<div class="app-version">
	<div class="app-version__text">
		v{{ appVersion }}
	</div>

	<div class="app-version__notice" *ngIf="updateActive">
		A new version of the app is available. Please <a href="#" (click)="refreshApp($event)">refresh to load the latest version</a>.
	</div>
</div>
