import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { IInvoiceModel } from '@/models/invoice.models';
import { IJobModel } from '@/models/job.models';
import { IChangeOrderModel } from '@/models/changeOrder.models';
import * as routerUtils from '@/utils/routerUtils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'job-invoice-list',
  templateUrl: './jobInvoiceList.template.html',
  host: {'class': 'job-invoice-list-component'}
})
export class JobInvoiceListComponent extends BaseSmartComponent implements OnInit {
  public filterTerm: string = '';
  private _jobId : string;
  private _customerId : string;

  @Input()
  model: Observable<Array<IInvoiceModel>>;

  @Input()
  dataManager: DataManipulationService<IInvoiceModel>;

  @Input()
  projectManager: string;

  @Input()
  job: IJobModel;

  @Output()
  public addClicked: EventEmitter<void> = new EventEmitter();

  @Output()
  public itemSelected: EventEmitter<any> = new EventEmitter();

  public selectedInvoiceId: string = null;
  public amountRemaining: number;

  private listSubscription: Subscription;

  constructor(
    private state: StateManager, 
    private route : ActivatedRoute,
    messageService: MessageService,
    private jobService : JobService,
    ) {
    super(messageService);
  }

  ngOnInit(): void {
    //grab entity query data
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this.jobService.getChangeOrders(this._customerId, this._jobId);
    
    this.watchSubscription(this.state.getActiveInvoice().subscribe((s) => {
      setTimeout(() => {
        if (s) {
          // autofill Sold By
          if (!s.soldBy) {
            s.soldBy = this.projectManager || null;
          }
          this.selectedInvoiceId = s._id;
        } else {
          this.selectedInvoiceId = null;
        }
      });
    }));

    this.listSubscription = this.state.getChangeOrdersList().subscribe((changeOrders: IChangeOrderModel[]) => {
      const contractAmount = this.totalContractAmount(this.job, changeOrders);
      this.model.subscribe((receivables: IInvoiceModel[]) => {
        let invoicedAmount = receivables.reduce((invoicedTotal: number, invoice: IInvoiceModel) => invoicedTotal + invoice.amountThisInvoice, 0);
        this.amountRemaining = contractAmount - invoicedAmount;
      })
    })
  }

  override  ngOnDestroy(): void {
    this.listSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  private totalContractAmount(job: IJobModel, changeOrders: IChangeOrderModel[]): number {
    return job.contractAmount + changeOrders.reduce((amt: number, curr: IChangeOrderModel) => amt + curr.amount, 0);
  }
}
