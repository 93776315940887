import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IInvoiceModel, IInvoiceSummary } from '@/models/invoice.models';

export interface IInvoiceState {
	invoices: Array<IInvoiceModel>,
	activeInvoice: IInvoiceModel,
	invoiceSummaries: Array<IInvoiceSummary>
}

const defaultState : IInvoiceState = {
	invoices: new Array<IInvoiceModel>(),
	activeInvoice: null,
	invoiceSummaries: new Array<IInvoiceSummary>()
};

export const INVOICE_ACTIONS = {
	SET_INVOICES_LIST: 'SET_INVOICES_LIST',
	SET_ACTIVE_INVOICE: 'SET_ACTIVE_INVOICE',
	SET_INVOICE_SUMMARY_LIST: 'SET_INVOICE_SUMMARY_LIST'
};

const invoiceReducer = function(state: IInvoiceState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case INVOICE_ACTIONS.SET_INVOICES_LIST:
			newState.invoices = action.payload;
			break;
		case INVOICE_ACTIONS.SET_ACTIVE_INVOICE:
			newState.activeInvoice = action.payload;
			break;
		case INVOICE_ACTIONS.SET_INVOICE_SUMMARY_LIST:
			newState.invoiceSummaries = action.payload;
			break;
    }

    return newState;
};

export function getInvoicesList() {
	return (state : Store<IInvoiceState>) => {
		return state.select(s => {
			return s.invoices;
		});
	};
}

export function getActiveInvoice() {
	return (state : Store<IInvoiceState>) => {
		return state.select(s => {
			return s.activeInvoice;
		});
	};
}

export function getInvoiceSummaryList() {
	return (state : Store<IInvoiceState>) => {
		return state.select(s => {
			return s.invoiceSummaries;
		})
	}
}

export function reducer(state: IInvoiceState | undefined, action: Action) {
	return invoiceReducer(state, action);
}
