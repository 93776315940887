<h2 class="text-center">Edit Location</h2>
<div class="container">
	<div class="row">
		<div class="col-md-6 pr-3">
			<h3>Details</h3>
			<location-edit-form [model]="model | async" [permissionLock]="permissionLock" (onSave)="saveLocation($event)" [showAlert]="showAlert" (onCancel)="cancel()" (onGoToLocation)="goToLocation()"></location-edit-form>
            <div style="margin-top:2rem;">
                <router-outlet name="users"></router-outlet>
            </div>
		</div>

		<div class="col-md-6 pl-3">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
