import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'state-selector',
    template:`
    <select class="form-control state" [(ngModel)]="model" name="state" [required]="required" [disabled]="disabled">
        <option *ngFor="let state of states" [value]="state">
            {{ state }}
        </option>
    </select>
    `,
    host: {'class': 'state-selector-component'},
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => StateSelectorComponent),
        multi: true
    }]
})
export class StateSelectorComponent implements ControlValueAccessor {
    public states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    @Input()
    _model : string = '';

    @Input()
    required : boolean = false;

    @Input()
    disabled : boolean = false;

    get model() {
        return this._model;
    }

    set model(newState : string) {
        this._model = newState;
        this.propogateChange(this._model);
    }

    propogateChange = (_: any) => {};

    registerOnChange(fn:any) : void {
        this.propogateChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) : void {
        this._model = value;
    }
};
