export const constructSearchQuery = (
  baseUrl: string,
  getInactive: boolean,
  searchTerm: string,
  sortField: string,
  sortDirection: number,
  pageNumber: number,
  limit?: number,
  custom?: string[],
) => {
    let conds: string[] = [];
    if (getInactive) {
      conds.push('getInactive=true');
    }

    if (searchTerm) {
      conds.push('searchTerm=' + encodeURIComponent(searchTerm));
    }

    if (sortField) {
      conds.push('sortField=' + sortField);
    }

    if (sortDirection) {
      conds.push('sortDirection=' + sortDirection);
    }

    if (pageNumber) {
      conds.push('pageNumber=' + pageNumber);
    }

    if (limit || limit === 0) {
      conds.push('limit=' + limit);
    }

    if (custom && custom.length > 0) {
      conds.push(...custom);
    }

    let queryUrl = baseUrl;
    if (conds.length > 0) {
      queryUrl += '?' + conds.join('&');
    }
    return queryUrl;
};
