import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BaseComponent } from '@/components/base.component';
import * as dataUtils from '@/utils/dataUtils';
import * as Utility from '@/models/utility.models';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { ActivatedRoute } from '@angular/router';
import { DataCopyService } from '@/services/dataCopy.service';
import { FilesService } from '@/services/files.service';
import { JobService } from '@/services/job.service';
import { AuthService } from '@/services/auth.service';
import { AppSettingsService } from '@/services/appSettings.service';

import {
	IInvoiceModel,
	InvoiceModel,
	InvoicePayTypes,
	IInvoiceAutofillResponse,
	IInvoiceAutoCalculateTax
} from '@/models/invoice.models';
import { PurchaseOrderPartModel } from '@/models/part.models';
import { IUserModel } from '@/models/user.models';
import { IAuthUser } from '@/models/auth.models';
import { ITaxes } from '@/models/utility.models';

import { UI_MASKS } from '@/utils/uiMasks';
import { Subject, Subscription } from 'rxjs';

@Component({
	selector: 'invoice-edit',
	templateUrl: './invoiceEdit.template.html',
	host: {'class': 'invoice-edit-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})
export class InvoiceEditComponent extends BaseComponent implements OnDestroy, OnInit {
	@ViewChild('invoiceForm', {static: false}) invoiceForm;

	@Input()
	salesTaxRates: IInvoiceAutoCalculateTax = {};

	@Input()
	useTaxRates: IInvoiceAutoCalculateTax = {};

  // tslint:disable-next-line:max-line-length
  // todo Never set. Read to determine if stadiumTax should display (always null, so no) and read in update function that doesn't do anything. Remove.
	@Input()
	taxRates: IInvoiceAutoCalculateTax = {};

	@Input()
	model: IInvoiceModel = new InvoiceModel();

	@Input()
	users: Array<IUserModel> = new Array<IUserModel>();

	@Input()
	autofill: IInvoiceAutofillResponse = {} as IInvoiceAutofillResponse;

	@Input()
	isTaxExempt: boolean = false;

	@Input()
	isNew: boolean;

	@Input()
	jobInfo: any;

	@Output()
	onSave: EventEmitter<IInvoiceModel> = new EventEmitter<IInvoiceModel>();

	@Output()
	onCancel: EventEmitter<null> = new EventEmitter<null>();

	@Output()
	onAutofillGet: EventEmitter<Date> = new EventEmitter<Date>();

	@Output()
	formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	uuid: string = '';

	public billingOptions: any;
	public deliveryType: string = '';
	public formChangesSubscription: any;
	public viaOpen: boolean = false;
	public pmName: string;
	public tempParts = [];
	public duplicateRecord: boolean = false;
	public updatePriority: boolean = false;
	public priorityChanged: boolean = false;
	public initialPriority: string = 'None';
	public disableSubmit: boolean = false;
	public user: IAuthUser;
	public userSub: Subscription;
	public role: number;
	public permissionLock: boolean = false;
	public appSettings: Object;
	public jobCity: string;

	private payTypes: Array<string> = Utility.enumToArray<string>(InvoicePayTypes);
	private useTaxes: ITaxes = {} as ITaxes;
	private datePromised: Date;
	private saved: Subject<any> = new Subject();
	private _customerId: string;
	private _jobId: string;
	private statusCopy: any;
	private docSubscription = new Subscription();
	private filesQueued: boolean = false;

	constructor(
		private uiMasks: UI_MASKS,
		private state: StateManager,
		private dataCopyService: DataCopyService,
		private route: ActivatedRoute,
		private jobService: JobService,
		private filesService: FilesService,
		private authService: AuthService,
		private settingsService: AppSettingsService,
		) {
		super();
		this.appSettings = this.settingsService.getSettings();

	}

	private setDatePromised(e): void {
		this.model.datePromised = dataUtils.formatDate(e, 'MM/DD/YYYY');
	}

	ngOnInit() {
		this.billingOptions = [
			{
				id: 'Customer',
				label: 'Customer'
			},
			{
				id: 'Job',
				label: 'Job c/o Customer'
			}
		];
		// get user's role
		this.userSub = this.authService.currentUser.subscribe(s => {
			if (s) {
				this.user = s;
				this.role = s.role;
				if (this.role === 10) this.permissionLock = true;
			}
		});
		setTimeout(() => {
			this.checkFormChanges();
			if (this.model) {
				this.getTotalContractAmount();
				this.getAmountRemaining();
				this.getCostTotal();
			}
		}, 1000);
		setTimeout(() => {
			if(this.model) {
				// only autofill new invoices
				if (this.isNew) {
					this.model._id = this.uuid;
					this.model.priority = 'None';
					this.model.invoiceDate = new Date();
				    // Remove city tax if job is not in Milwaukee - removed per KD request 1/4/24
					// check location of job for citytax
					// this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
					// this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
					// this.jobService.getJob(this._customerId, this._jobId).then((job)=> {
					// 	if (job.address.city !== "Milwaukee") {
					// 		delete this.salesTaxRates['cityTax'];
					// 	}
					// });
					
				}
				if(this.jobInfo && this.jobInfo.orderNumber) {
					this.model.orderNumber = this.jobInfo.orderNumber;
				} else {
					// if the page is refreshed, get the job and contract #
					this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
					this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
					this.jobService.getJob(this._customerId, this._jobId).then((job)=> {
						this.model.orderNumber = job.contractNumber;
					});
				}
			}
		}, 0);
		this.saved.next('false');
	}

	ngOnChanges(changes: SimpleChanges) {
		// select radio buttons once model is loaded
		if (changes['model']) {
			this.checkDeliveryType();
			// default billTo option
			if (this.model && !this.model.billTo) {
				this.model.billTo = 'Customer';
			}
			this.saved.next('true');
		}
		if(changes['autofill'] && this.isNew){
			this.model.changeOrdersToDate = this.autofill.changeOrdersToDate;
		}
	}

	checkFormChanges() {
		// Remove city tax if job is not in Milwaukee - removed per KD request 1/4/24
		// this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
		// this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
		// this.jobService.getJob(this._customerId, this._jobId).then((job)=> {
		// 	if (job.address.city !== "Milwaukee") {
		// 		delete this.salesTaxRates['cityTax'];
		// 	}
		// });
		
		if(this.invoiceForm){
			this.invoiceForm.valueChanges.subscribe(data =>{
				if(this.invoiceForm.dirty) {
					this.formStatus.emit(true);
				}
			});
		}
	}

	checkDeliveryType() {
		if (this.model && this.model.willCall) {
			this.deliveryType = 'willCall';
		} else if(this.model && this.model.workLocation) {
			this.deliveryType = 'workLocation';
		} else {
			this.deliveryType = 'deliverTo';
		}
	}

	setDeliveryType() {
		if (this.model) {
			this.model.willCall = false;
			this.model.workLocation = false;
			this.model.deliverTo = false;
			if (this.deliveryType == 'willCall') {
				this.model.willCall = true;
			} else if(this.deliveryType == 'workLocation') {
				this.model.workLocation = true;
			} else if(this.deliveryType == 'deliverTo') {
				this.model.deliverTo = true;
			}
		}
	}

	ngOnDestroy(): void {
		this.state.setActiveInvoice(null);
	}

	cancel(): void {
		this.onCancel.emit();
	}

	startSave() {
		if(this.isNew){
			// status will be set for new receivables when it is saved
			this.saveInvoice();
		} else {
			// update status log if priority is changed to 1 or 5 but don't add to the status log if priority was changed back and forth before saving
			if (this.priorityChanged && (this.model.priority !== this.initialPriority)) {
				this.updatePriority = true;
			} else {
				// priority was changed to 'None' so don't update the status log
				this.saveInvoice();
			}
		}
	}

	saveInvoice(): void {
		this.disableSubmit = true;
		const promise = new Promise((resolve, reject) => {
			if (this.removeEmptyParts()) {
				resolve(true);
			}
		}).then(() => {
			this.onSave.emit(this.model);
			// simply resetting the form causes dates to not be displayed correctly
			Object.keys(this.invoiceForm.controls).forEach(control => {
				this.invoiceForm.controls[control].markAsPristine();
			});
			this.formStatus.emit(false);
			this.isNew = false;
			this.saved.next('true');
			this.updatePriority = false;
			setTimeout(() => {
				this.disableSubmit = false;
			}, 1000);
		});
	}
	setPriority(priority: string): void {
		if (this.model) {
			this.model.priority = priority;
			if (priority !== 'None') {
				this.priorityChanged = true;
				this.updatePriority = false;
			}
		}
	}

	copyJobAddress(): void {
		this.model.workLocation = true;
		this.deliveryType = 'workLocation';
		this.dataCopyService.copyActiveJobAddress(this.model.deliveryAddress);
	}

	copyCustomerAddress(): void {
		this.dataCopyService.copyActiveCustomerAddress(this.model.deliveryAddress);
	}

	getAutofillValues(): void {
		let invoiceDate: Date = null;

		try {
			if (this.model.invoiceDate) {
				invoiceDate = this.sanitizeDate(this.adjustForTimezone(this.model.invoiceDate));
			} else {
				invoiceDate = this.sanitizeDate();
			}
		} catch (ex) {
			invoiceDate = this.sanitizeDate();
		}

		this.onAutofillGet.emit(invoiceDate);
	}

	adjustForTimezone(date: Date): Date {
		var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
		date.setTime(date.getTime() + timeOffsetInMS);
		return date
	}

	updateAutofillValues(): void {
		// update autofill values when created date is changed
		this.getAutofillValues();
	}

	copyAllAutofillValues(): void {
		this.copyAutofillValue('changeOrdersToDate');
		this.copyAutofillValue('laborDirect');
		this.copyAutofillValue('laborShop');
		this.copyAutofillValue('materials');
		this.copyAutofillValue('outsideServices');

		this.copySellTaxAmount('stateTax');
		this.copySellTaxAmount('countyTax');
		this.copySellTaxAmount('mkecountyTax');
		this.copySellTaxAmount('stadiumTax');
		this.copySellTaxAmount('cityTax');

		this.copyUseTaxAmount('stateTax');
		this.copyUseTaxAmount('countyTax');
		this.copyUseTaxAmount('mkecountyTax');
		this.copyUseTaxAmount('stadiumTax');
		this.copyUseTaxAmount('cityTax');
	}

	copyAutofillValue(field: string): void {
		let value = this.autofill[field] || 0;

		this.model[field] = Number(value.toFixed(2));
	}

	copySellTaxAmount(field: string): void {
		this.model.sellTax[field] = this.getSellTaxAmount(field);
	}

	copyUseTaxAmount(field: string): void {
		this.model.costTax[field] = this.getUseTaxAmount(field);
	}

	getSubtotal(): number {
		return (Number(this.model.laborDirect) +
			Number(this.model.laborShop) +
			Number(this.model.materials) +
			Number(this.model.outsideServices));
	}

	getCostTotal(): number {
		const stadiumValue = (this.model.costTax.stadiumTax) ? Number(this.model.costTax.stadiumTax) : 0;
		const cityValue = (this.model.costTax.cityTax) ? Number(this.model.costTax.cityTax) : 0;
		return (Number(this.model.laborDirect) +
			Number(this.model.laborShop) +
			Number(this.model.materials) +
			Number(this.model.outsideServices) +
			Number(this.model.costTax.stateTax) +
			Number(this.model.costTax.countyTax) +
			Number(this.model.costTax.mkecountyTax) +
			cityValue + 
			stadiumValue
		);
	}
	getTotalContractAmount(): number {
		return (Number(this.autofill.originalContract) + Number(this.model.changeOrdersToDate))
	}
	// getNonContractAmt(): number {
	// 	if (this.model.nonContract) {
	// 		return Number(this.model.amountThisInvoice);
	// 	}
	// 	return 0;
	// }

	getSellTaxAmount(field: string): number {
		return dataUtils.sanitizeNumber(this.model.amountThisInvoice * (this.salesTaxRates[field] / 100), 2);
	}

	getUseTaxAmount(field: string): number {
		return dataUtils.sanitizeNumber(this.model.materials * (this.useTaxRates[field] / 100), 4);
	}

	// todo since this.taxRates is never set, this never does anything. Should remove unless this is a bug.
	updateUseTax(): void {
		const self = this;
		for (let taxRate in self.taxRates) {
			self.useTaxes[taxRate] = self.model.materials * (self.useTaxRates[taxRate] / 100);
		}
	}

	getAmountRemaining(): number {
		const self = this;

		return (Number(self.autofill.originalContract) + Number(self.model.changeOrdersToDate) - Number(self.autofill.amountPreviouslyInvoiced) - Number(self.model.amountThisInvoice))
	}

	toggleBilled(): void {
		this.model.notBilled = !this.model.notBilled;
	}

	toggleVia() {
		this.viaOpen = !this.viaOpen;
	}

	private selectVia(value: string): void {
		this.model.via = value;
		this.viaOpen = false;
	}

	// public updateRequired() {
	// 	if (this.model.willCall) {
	// 		document.getElementsByName('address1').clearValidators();
	// 		this.form.get('title').updateValueAndValidity();
	// 	} else {
	// 		this.form.get('title').setValidators([Validators.required, Validators.minLength(3)]);
	// 		this.form.get('title').updateValueAndValidity();
	// 	}
	// }
	calcInvoiceAmt(): void {}
	// calcInvoiceAmt(): void {
	// 	const self = this;
	// 	if (self.model.nonContract) {
	// 		self.model.amountThisInvoice = (
	// 			(self.model.part1Qty * self.model.part1Cost) +
	// 			(self.model.part2Qty * self.model.part2Cost) +
	// 			(self.model.part3Qty * self.model.part3Cost) +
	// 			(self.model.part4Qty * self.model.part4Cost) +
	// 			(self.model.part5Qty * self.model.part5Cost));
	// 	}
	// }

	// getNonContractItems(): Array<NonContractPartModel> {
	// 	const self = this;

	// 	console.log('getNonContractItems()');
	// 	if (self == null) { console.log('self is null'); }
	// 	if (self != null && self.model == null) { console.log('self.model is null'); }
	// 	if (self != null && self.model == null && self.model.notes == null) { console.log('self.model.notes is null'); }

	// 	let notes = self.model.notes;
	// 	let items = new Array<NonContractPartModel>();
	// 	if (notes != null) {
	// 		notes.split('\n').forEach(function (val, index) {
	// 			console.log('val: ' + val);
	// 			let part = new NonContractPartModel(index, val);
	// 			items.push(part);
	// 		});
	// 	}
	// 	return items;
	// }

	toggleParts(): void {
		this.model.nonContract = !this.model.nonContract;
		if(this.model.nonContract) {
			let emptyParts = 10 - this.model.parts.length;
			var i;
			for (i = 0; i < emptyParts; i++) {
				this.newPart();
			}
		} else {
			this.model.parts = [];
		}
	}
	addPart(event): void {
		this.newPart();
		if (event != null) {
			setTimeout(() => {
				document.getElementById('part'+(this.model.parts.length - 1)).focus();
			}, 0);
			event.stopPropagation();
		}
	}
	newPart(): void {
		let p = new PurchaseOrderPartModel();
		this.model.parts.push(p);
	}
	deletePart(part): void {
		let indexPosition = this.model.parts.indexOf(part);
		this.model.parts.splice(indexPosition, 1);
	}

	// remove any empty parts from the list
	removeEmptyParts(): boolean {
		let tempParts = [];
		this.model.parts.forEach(part => {
			if (part.quantity || part.description || part.cost) {
				tempParts.push(part);
			}
		});
		this.model.parts = Object.assign([], tempParts);

		return true;
	}

	// /**
	//  * Adds a class style to the workflow status based on the status name
	//  * @param {string} statusName
	//  * @return {string[]}
	//  */
	// public statusClass(statusName: string): string[] {
	// 	let styles: string[] = [];
	// 	switch (statusName) {
	// 		case 'Approved': styles.push('green'); break;
	// 		case 'Pending approval': styles.push('orange'); break;
	// 		case 'Submitted': styles.push('blue'); break;
	// 		case 'Paid': styles.push('bright-green'); break;
	// 		case 'Rejected': styles.push('red'); break;
	// 		default: /* do nothing */ break;
	// 	}
	// 	return styles;
	// }

	public setMessage(e) {
		this.filesQueued = e;
	}
}
