import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowService } from '@/services/workflow.service';
import { WorkflowState } from '@/models/workflow.models';
import { ActionItem, ActionItems } from '@/models/actionItems.model';
import { IAuthUser, UserRole } from '@/models/auth.models';

@Component({
    selector: 'entity-full',
    template: `
        <div *ngIf="user" class="card entity-full">
            <div class="row">
                <div class="col-xs-8">
                    <div class="title">
                        <span class="entity-color entity-type{{actionItems.typeId}}"></span> <span class="entity-label">{{actionItems.label}}</span>
                    </div>
                </div>
                <div class="col-xs-4 text-right">
                    <div class="secondary-label">Showing 5 {{sortedBy}}</div>
                </div>
            </div>
            <div class="row entity-header">
                <div class="col-xs-2">
                    ID #
                </div>
                <div class="col-xs-4">
                    Job
                </div>
                <div class="col-xs-4">
                    Date / Status
                </div>
                <div class="col-xs-2">
                </div>
            </div>
            <div *ngFor="let item of actionItems.items | slice:0:5; let i=index" class="row item" (click)="routeToItem(item)" [ngClass]="{'late':item.isLate}">
                <div class="col-xs-2">{{item.number}}</div>
                <div class="col-xs-4">{{item.job}}</div>
                <div class="col-xs-4">
                    <div>{{item.date | dateFormat:'M/D/YYYY'}}<i class="glyphicon glyphicon-time ml-1" title="Late"></i></div>
                    <div class="item-status status-options {{item.css}}"><span *ngIf="item.status">{{item.workflowState.label}}</span></div>
                </div>
                <div class="col-xs-2 text-right pseudo-link">View</div>
            </div>
            <div class="row mt-2">
                <div class="col-xs-12">
                    <div class="pseudo-link nav-link" (click)="routeToFilter()">View all {{actionItems.label}} needing review</div>
                </div>
            </div>
        </div>
    `,
    host: {'class': 'entity-full-component'}
})
export class EntityFullComponent {
	@Input()
	user: IAuthUser;

	@Input()
	actionItems: ActionItems;

	public sortedBy: string = '';
	public displayItems: ActionItem[] = [];

	constructor(private router: Router, protected workFlowService: WorkflowService) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['actionItems']) {
			this.formatData();
		}
	}

	formatData(): void {
		this.sortedBy = (this.actionItems.typeId === 2) ? 'oldest' : 'most recent';
		this.displayItems = this.actionItems.items.slice(0, 5);
		if (this.actionItems.label === 'Invoices') {
		  this.actionItems.label = 'Receivables';
		}
		// format displayed items
		this.actionItems.items.forEach(item => {
			item.isLate = false;
			let currentDate = new Date().getTime();
			let date = new Date(item.date).getTime();
			let hourDiff = (currentDate - date) / 1000;
			if (hourDiff > 86400 &&
				 ((this.user.role === UserRole.President && item.status === 'pending_approval') ||
				  ((this.user.role === UserRole.ProjectManager || this.user.role === UserRole.VicePresident) && item.status === 'pending_review'))) {
				item.isLate = true;
			}

			item.status = (item.workflowState) ? item.workflowState.label : 'Unassigned';
			item.css = this.statusClass(item.workflowState);
		});
	}

	routeToItem(item): void {
		this.router.navigate(['Customers', item.customer, 'Job', item.jobId, this.actionItems.route, item.id, 'Edit']);
	}

	routeToFilter(): void {
		let label = this.actionItems.type;
		let filter = [];
		if (this.user.role === UserRole.Admin || this.user.role === UserRole.ShopForeman) {
		  if (label === 'PurchaseOrders') {
        if (this.user.role === UserRole.ShopForeman) {
          filter.push({filter: 'projectManagerFilter', id: this.user.userId});
          filter.push({filter: 'statusFilter', id: 'new'});
          filter.push({filter: 'statusFilter', id: 'hold'});
          filter.push({filter: 'statusFilter', id: 'submitted'});
        } else {
          filter.push({filter: 'statusFilter', id: 'fulfilled'});
          filter.push({filter: 'statusFilter', id: 'partial_ready'});
        }
		  } else if (label === 'Receivables') {
        if (this.user.role === UserRole.ShopForeman) {
          filter.push({filter: 'statusFilter', id: 'new'});
          filter.push({filter: 'statusFilter', id: 'pending_supplies'});
          filter.push({filter: 'statusFilter', id: 'ready_full'});
        } else {
          filter.push({filter: 'statusFilter', id: 'new'});
          filter.push({filter: 'statusFilter', id: 'submitted'});
          filter.push({filter: 'statusFilter', id: 'approved'});
          filter.push({filter: 'statusFilter', id: 'rejected'});
        }
        filter.push({filter: 'sort', id: 'invoices.invoiceDate', direction: 1 });
		  } else if (label === 'Payables') {
        if (this.user.role === UserRole.ShopForeman) {
          filter.push({filter: 'projectManagerFilter', id: this.user.userId});
          filter.push({filter: 'statusFilter', id: 'pending_review'});
        } else {
          filter.push({filter: 'statusFilter', id: 'approved'});
          filter.push({filter: 'statusFilter', id: 'rejected'});
          filter.push({filter: 'statusFilter', id: 'on_hold'});
        }
        filter.push({filter: 'sort', id: 'payables.invoiceDate', direction: 1 });
		  } else if (label === 'WorkOrders') {
        if (this.user.role === UserRole.ShopForeman) {
          filter.push({filter: 'statusFilter', id: 'pending'});
          filter.push({filter: 'statusFilter', id: 'ready_full'});
        }
        filter.push({filter: 'sort', id: 'workOrders.number', direction: 1 });
		  }
    } else if (this.user.role === UserRole.ProjectManager || this.user.role === UserRole.VicePresident) {
        filter.push({filter: 'projectManagerFilter', id: this.user.userId});
        if (label === 'PurchaseOrders') {
          filter.push({filter: 'statusFilter', id: 'new'});
          filter.push({filter: 'statusFilter', id: 'hold'});
        } else if (label === 'Receivables') {
          filter.push({filter: 'statusFilter', id: 'rejected'});
          filter.push({filter: 'sort', id: 'invoices.invoiceDate', direction: 1});
        } else if (label === 'Payables') {
          filter.push({filter: 'statusFilter', id: 'pending_review'});
          filter.push({filter: 'statusFilter', id: 'on_hold'});
          filter.push({filter: 'sort', id: 'payables.invoiceDate', direction: 1});
        }
    } else if (this.user.role === UserRole.President) {
      // president filters - Payables and POs filters updated 7/19/23 to not include the PM filter. POs show new status, Payables show pending review and pending approval (B-106737).
      filter.push({filter: 'projectManagerFilter', id: this.user.userId}); // updated 4/9/24 pm filter should pre-filter by president id in list views
      if (label === 'PurchaseOrders') {
        filter.push({filter: 'statusFilter', id: 'new'});
      } else if (label === 'Receivables') {
        filter.push({filter: 'statusFilter', id: 'pending'});
      } else if (label === 'Payables') {
        filter.push({filter: 'statusFilter', id: 'pending_review'});
        filter.push({filter: 'statusFilter', id: 'pending_approval'});
      }
    }
		this.router.navigateByUrl('/' + this.actionItems.route, {state: filter});
	}

	private statusClass(workflowState: WorkflowState): string {
		return (workflowState) ? this.workFlowService.getStatusClass(workflowState) : 'black';
	}
}
