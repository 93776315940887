import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from '@/services/message.service';
import { AuthService } from '@/services/auth.service';
import { CustomerService } from '@/services/customer.service';
import { ICustomerModel, CustomerModel } from '@/models/customer.models';
@Component({
    selector: 'customer-add',
    templateUrl: './customerAdd.template.html',
    host: {'class': 'customer-add-component'}
})

export class CustomerAddComponent extends BaseSmartComponent implements OnInit {
    public model : Observable<ICustomerModel>;
    public showAlert: boolean = false;
    private duplicateCustomer : ICustomerModel;

    constructor(
        private router: Router, 
        private route: ActivatedRoute, 
        private state : StateManager, 
        messageService : MessageService, 
        private customerService : CustomerService, 
        private authService : AuthService,
        private location : Location
        ) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.model = self.state.getActiveCustomer();
        self.state.setActiveCustomer(new CustomerModel());
    }

    public saveCustomer(newModel : ICustomerModel) : void {
        const self = this;

        self.authService.currentUser.subscribe((s) => {
            newModel.location = s.locationId;

            self.customerService.updateCustomer(newModel)
            .then((saved) => {
                this.showAlert = false;
                self.router.navigate([saved._id, 'Edit', 'Details'], { relativeTo: routerUtils.getParentRoute(self.route) });
            })
            .catch((err) => {
                this.showAlert = true;
                let error = err.error;
                this.duplicateCustomer = error.record;
            });
        })
        .unsubscribe();
    }

    public cancel() {
        this.location.back();
    }

    public goToCustomer() {
        this.router.navigate([this.duplicateCustomer._id, 'Edit', 'Details'], { relativeTo: routerUtils.getParentRoute(this.route) });
    }
}
