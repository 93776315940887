export function copyToExisting<T>(source : any, destination : T) : T {
    for(let key in source) {
        if(destination.hasOwnProperty(key) && source.hasOwnProperty(key)) {
            if(typeof key !== 'object') {
                destination[key] = source[key];
            }
            else {
                destination = copyToExisting(source[key as string], destination[key as string]);
            }
        }
    }

    return destination;
};
