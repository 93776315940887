import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { PayableComponent } from './payable.component';
import { PayableEditComponent } from './payableEdit.component';
import { PayableListComponent } from './payableList.component';
import { PayableSearchComponent } from './payableSearch.component';
import { PayableDocumentsComponent } from './payableDocuments.component';

import { UtilityModule } from '@/utils/utility.module';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { WorkflowModule } from '@/components/Workflow/workflow.module';
import { ModalModule } from '@/components/ModalComponent/modal.module';
import { FilterListModule } from '@/components/FilterListComponent/filterList.module';

@NgModule({
	declarations: [
		PayableComponent,
		PayableEditComponent,
		PayableListComponent,
		PayableSearchComponent,
		PayableDocumentsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UtilityModule,
		InfiniteScrollModule,
		RouterModule,
		DocumentUploadModule,
		WorkflowModule,
		ModalModule,
		FilterListModule,
        TypeaheadModule.forRoot(),
	    NgbModule,
		NgSelectModule
	],
	exports: [
		PayableComponent,
		PayableEditComponent,
		PayableListComponent,
		PayableSearchComponent,
		PayableDocumentsComponent,
	]
})
export class PayableModule {}
