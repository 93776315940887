import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { IInvoiceModel } from '@/models/invoice.models';
import { IPayableModel } from './payable.models';

export enum WorkflowProgress {
	INITIAL,
	RISING,
	BUDDING,
	BLOOMING,
	BLOOMED,
	ENDANGER
}

export interface WorkflowState {
	label: string;
	value: string;
	severity: WorkflowProgress;
}

export class BatchWorkflowRequest {
	items: string[];
	status: string;
}

export abstract class Workflow {
	states: WorkflowState[];
	default: WorkflowState;
	abstract path(item: WorkflowableItem): string;

	public defaultWorkflow(): WorkflowStatus {
		let currentStatus = this.default.value;
		return {
			status: currentStatus,
			note: null,
			timestamp: null,
			attachments: null
		};
	}
}

export interface WorkflowFile {
	name: string;
	file: File;
	path?: string;
	filename?: string;
}

export interface IWorkflowStatus {
	status: string;
	note: string|null;
	timestamp: string;
	attachment?: Array<WorkflowFile>;
}
export abstract class WorkflowStatus {
	status: string;
	note: string|null;
	timestamp: string = new Date().toISOString();
	attachments?: Array<WorkflowFile>;
}

/**
 * A displayable version of WorkflowStatus
 */
export class WorkflowDisplayStatus {
	status: WorkflowState;
	note: string|null;
	timestamp: string = new Date().toISOString();
	attachments?: Array<WorkflowFile>;
}

export type WorkflowableItem = { lifecycle: WorkflowStatus[]; };

export function updateLifecycle(obj: WorkflowableItem, newStatus: WorkflowStatus): void {
	newStatus.timestamp = new Date().toISOString();
	if (obj.lifecycle == null) {
		obj.lifecycle = [];
	}
	obj.lifecycle.push(newStatus);
}

export class PurchaseOrderWorkflow extends Workflow {
	override states: WorkflowState[] = [
		{ label: 'Unassigned',  value: null, severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'New',  value: 'new', severity: WorkflowProgress.BLOOMING /* green */ },
		{ label: 'Submitted',  value: 'submitted', severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'Acknowledged',  value: 'acknowledged', severity: WorkflowProgress.RISING /* blue */ },
		{ label: 'Fulfilled',  value: 'fulfilled', severity: WorkflowProgress.BLOOMING /* green */ },
		{ label: 'Partial in, ready to pay',  value: 'partial_ready', severity: WorkflowProgress.BLOOMED /* bright-green */ },
		{ label: 'Partial in, not ready',  value: 'partial_notready', severity: WorkflowProgress.BUDDING /* orange */ },
		{ label: 'Hold',  value: 'hold', severity: WorkflowProgress.ENDANGER /* red */ },
		{ label: 'Paid', value: 'paid', severity: WorkflowProgress.BLOOMED /* bright-green */ }
	];

	override default: WorkflowState = this.states[2]; /* Acknowledged */

	path(item: WorkflowableItem): string {
		const po = item as IPurchaseOrderModel;
		return 'purchaseOrders/' + po._id;
	}

	public override defaultWorkflow(): WorkflowStatus {
		let defaultWorkflow = super.defaultWorkflow();
		defaultWorkflow.attachments = [];
		return defaultWorkflow;
	}
}

export class InvoiceWorkflow extends Workflow {
	override states: WorkflowState[] = [
		{ label: 'Unassigned',  value: null, severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'New',  value: 'new', severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'Pending Supplies',  value: 'pending_supplies', severity: WorkflowProgress.BUDDING /* orange */ },
		{ label: 'Ready to Schedule',  value: 'ready_full', severity: WorkflowProgress.RISING /* blue */ },
		{ label: 'Scheduled',  value: 'scheduled', severity: WorkflowProgress.BLOOMING /* green */ },
		{ label: 'Completed',  value: 'completed', severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'Pending approval',  value: 'pending', severity: WorkflowProgress.BUDDING /* orange */ },
		{ label: 'Submitted',  value: 'submitted', severity: WorkflowProgress.RISING /* blue */ },
		{ label: 'Approved',  value: 'approved', severity: WorkflowProgress.BLOOMING /* green */ },
		{ label: 'Rejected',  value: 'rejected', severity: WorkflowProgress.ENDANGER /* red */ },
		{ label: 'Finalized',  value: 'finalized', severity: WorkflowProgress.INITIAL /* gray */ },
	];

	override default: WorkflowState = this.states[1]; /* New */

	path(item: WorkflowableItem): string {
		const invoice = item as IInvoiceModel;
		return 'invoices/' + invoice._id;
	}
}

export class WorkOrderWorkflow extends Workflow {
	override states: WorkflowState[] = [
		{ label: 'Unassigned',  value: null, severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'Pending supplies',  value: 'pending', severity: WorkflowProgress.BUDDING /* orange */ },
		{ label: 'Ready to schedule',  value: 'ready_full', severity: WorkflowProgress.RISING /* blue */ },
		{ label: 'Scheduled',  value: 'scheduled', severity: WorkflowProgress.BLOOMING /* green */ },
		{ label: 'Completed',  value: 'completed', severity: WorkflowProgress.INITIAL  /* gray */ },
	];

	override default: WorkflowState = this.states[1]; /* Pending supplies */

	path(item: WorkflowableItem): string {
		const wo = item as IWorkOrderModel;
		return 'workOrders/' + wo._id;
	}
}


export class PayableWorkflow extends Workflow {
	override states: WorkflowState[] = [
		{ label: 'Unassigned', value: null, severity: WorkflowProgress.INITIAL /* gray */ },
		{ label: 'Pending review', value: 'pending_review', severity: WorkflowProgress.BUDDING /* orange */ },
		{ label: 'Pending approval', value: 'pending_approval', severity: WorkflowProgress.BUDDING /* orange */ },
		{ label: 'Approved', value: 'approved', severity: WorkflowProgress.BLOOMING /* green */ },
		{ label: 'Posted', value: 'posted', severity: WorkflowProgress.RISING /* blue */ },
		{ label: 'Sent to Accounting', value: 'sent_to_accounting', severity: WorkflowProgress.BLOOMED /* green */ },
		{ label: 'Printed', value: 'printed', severity: WorkflowProgress.BLOOMED /* bright-green */ },
		{ label: 'On Hold', value: 'on_hold', severity: WorkflowProgress.ENDANGER /* red */ },
		{ label: 'Rejected', value: 'rejected', severity: WorkflowProgress.ENDANGER /* red */ },
		{ label: 'Void', value: 'void', severity: WorkflowProgress.INITIAL /* gray */ },
	];

	override default: WorkflowState = this.states[0]; 

	path(item: WorkflowableItem): string {
		const payable = item as IPayableModel;
		return 'payables/' + payable._id;
	}
}
