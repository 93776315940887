import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { InvoiceFilterFunctions, InvoiceSortFunctions } from '@/utils/dataManipulationUtils';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { JobService } from '@/services/job.service';
import { IInvoiceModel } from '@/models/invoice.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'purchase-order-invoice-connection',
  templateUrl: './purchaseOrderInvoiceConnection.template.html',
  host: {'class': 'purchase-order-invoice-connection-component'}
})
export class PurchaseOrderInvoiceConnectionComponent extends BaseSmartComponent implements OnInit {
  // todo refactor or rename so public property isn't prefixed with _
  public _purchaseOrderId: string;
  public attachedInvoice: IInvoiceModel = null;

  private _customerId: string;
  private _jobId: string;
  private model: Observable<IPurchaseOrderModel>;
  private filterTerm: string = null;
  private invoices: Subject<Array<IInvoiceModel>> = new Subject<Array<IInvoiceModel>>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private dataManipulationService: DataManipulationService<IInvoiceModel>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActivePurchaseOrder();
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');

    this.watchSubscription(this.model.subscribe((s) => {
      if (s && s.invoice) {
        this.loadAttachedInvoice(s.invoice as string);
      } else {
        this.attachedInvoice = null;
      }
    }));

    this.dataManipulationService.initialize(InvoiceFilterFunctions, InvoiceSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getInvoicesList(), this.invoices));
    this.jobService.getInvoices(this._customerId, this._jobId).catch(handleError);
  }

  private attachToInvoice(invoice: IInvoiceModel, event: Event): void {
    this.jobService.connectInvoices(this._customerId, this._jobId, invoice._id, this._purchaseOrderId)
      .then(() => this.loadAttachedInvoice(invoice._id))
      .catch(handleError);
    event.stopPropagation();
  }

  private detachFromInvoice(event: Event): void {
    this.jobService.disconnectInvoices(this._customerId, this._jobId, this.attachedInvoice._id, this._purchaseOrderId)
      .then(() => this.attachedInvoice = null)
      .catch(handleError);
    event.stopPropagation();
  }

  private goToInvoice(InvoiceId: string): void {
    this.router.navigate([ 'Invoices', InvoiceId ], { relativeTo: routerUtils.getParentRoute(this.route, 3) });
  }

  private loadAttachedInvoice(InvoiceId: string): void {
    this.jobService.getInvoice(this._customerId, this._jobId, InvoiceId, true)
      .then((invoice) => this.attachedInvoice = invoice)
      .catch(handleError);
  }
}
