import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IWorkOrderPartModel, IPurchaseOrderPartModel } from '@/models/part.models';

export interface IPartState {
    workOrderParts: Array<IWorkOrderPartModel>,
    purchaseOrderParts : Array<IPurchaseOrderPartModel>
}

const defaultState : IPartState = {
    workOrderParts: new Array<IWorkOrderPartModel>(),
    purchaseOrderParts: new Array<IPurchaseOrderPartModel>()
};

export const PART_ACTIONS = {
    SET_WORKORDER_PARTS_LIST: 'SET_WORKORDER_PARTS_LIST',
    SET_PURCHASEORDER_PARTS_LIST: 'SET_PURCHASEORDER_PARTS_LIST'
};

const partReducer = function(state: IPartState = defaultState, action: Action) {
    let newState = Object.assign({}, state);

    switch(action.type) {
        case PART_ACTIONS.SET_WORKORDER_PARTS_LIST:
            newState.workOrderParts = action.payload;
            break;
        case PART_ACTIONS.SET_PURCHASEORDER_PARTS_LIST:
            newState.purchaseOrderParts = action.payload;
            break;
    }

    return newState;
};

export function getWorkOrderPartsList() {
	return (state : Store<IPartState>) => {
		return state.select(s => {
				return s.workOrderParts;
		});
	};
}

export function getPurchaseOrderPartsList() {
	return (state : Store<IPartState>) => {
		return state.select(s => {
				return s.purchaseOrderParts;
		});
	};
}

export function reducer(state: IPartState | undefined, action: Action) {
	return partReducer(state, action);
}
