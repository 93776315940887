import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'priority-component',
	template: `
    <label>Scheduling Priority</label><span (click)="togglePriority()" class="pseudo-link small capitalize ml-2"><span *ngIf="!showPriority">Change</span><span *ngIf="showPriority">Close</span></span>
    <div *ngIf="!showPriority" [@fadeInOut] style="position: absolute;">
        <span class="font-bold">{{model.priority}}</span>
    </div>
    <div *ngIf="showPriority" [@fadeInOut] style="position: relative;">
        <label class="custom-radio"><span class="custom-label">None</span>
            <input type="radio" name="priority" value="None" [(ngModel)]="model.priority" (ngModelChange)="setPriority('None')">
            <span class="radiobtn"></span>
        </label>
        <label class="custom-radio"><span class="custom-label">1</span>
            <input type="radio" name="priority" value="1" [(ngModel)]="model.priority" (ngModelChange)="setPriority('1')">
            <span class="radiobtn"></span>
        </label>
        <label class="custom-radio" style="margin-bottom:0;"><span class="custom-label">5</span>
            <input type="radio" name="priority" value="5" [(ngModel)]="model.priority" (ngModelChange)="setPriority('5')">
            <span class="radiobtn"></span>
        </label>
    </div>
    `,
    host: {'class': 'priority-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0,
				height: 0,
				overflow: 'hidden'
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})
export class PriorityComponent {
    @Input()
    model : any;

    @Input()
    isNew : boolean;

    @Input()
    priorityChanged : boolean;

    @Output()
    changedPriority = new EventEmitter<string>();

	public showPriority: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
		if (changes['model']) {
            this.showPriority = this.isNew;
		}
    }
    
	setPriority(priority: string): void {
		this.changedPriority.emit(priority);
	}

	togglePriority(): void {
		this.showPriority = !this.showPriority;
	}
}
