<div class="text-center">
    <h2>Locations</h2>

    <button type="button" class="btn btn-cta" (click)="addLocation()">Add location</button>

    <ul class="list-unstyled" id="location-list">
        <li *ngFor="let location of model" (click)="editLocation(location._id)">
            {{ location.name }}
        </li>
    </ul>
</div>
