import { Injectable } from '@angular/core';
import { Summary } from '@/models/summary.models';
import { Observable } from 'rxjs';
import { ActionItems } from '@/models/actionItems.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService {
	constructor(protected http: HttpClient) { }

	get(): Observable<Array<Summary>> {
		return this.http.get<Summary[]>('dashboard');
	}

	getActionItems(): Observable<Array<ActionItems>> {
		return this.http.get<ActionItems[]>('dashboard/actionitems');
	}
}
