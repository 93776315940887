<article class="container mb-2">
    <div class="col-md-12">
        <h2>Add a new supplier</h2>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <supplier-edit-form [model]="model | async" (onSave)="saveSupplier($event)" (onCancel)="cancel()" [showAlert]="showAlert" [isNew]="true" (onGoToSupplier)="goToSupplier()"></supplier-edit-form>
            </div>
        </div>
    </div>
</article>
