<div id="loading" class="data-loading" *ngIf="!model">Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>

<form class="" (ngSubmit)="startSave()" #workOrderForm="ngForm" *ngIf="model" [@fadeInOut]>
    <input autocomplete="off" name="id" type="hidden" value="{{ model._id }}" [@fadeInOut] />
	<div  *ngIf="!isNew" [@fadeInOut]>
		<div class="row">
			<div class="col-xs-12">
				<div class="form-group">
					<div class="screen-title">Work Order #{{ model._id ? model.number : 'Automatic' }}</div>
				</div>
			</div>
		</div>
    <workflow [item]="model" [type]="0" [priority]="model.priority" [updatePriority]="updatePriority" (onUpdate)="saveWorkOrder()"></workflow>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-6">
			<div *ngIf="isNew" class="form-group">
				<label>ID #</label>
				<input type="text" class="form-control" name="ID" disabled placeholder="Auto-generated" />
			</div>
			<div *ngIf="isNew" class="form-group">
				<label>Date Created</label>
				<div class="">
					<input autocomplete="off" type="date" class="form-control width-auto" [ngModel]="model.date | dateFormat" (ngModelChange)="model.date = $event" name="date" required="required" #date="ngModel" />
					<div class="alert alert-danger" [hidden]="(!workOrderForm.form.submitted && date.pristine) || date.valid">
						Date is required
					</div>
				</div>
			</div>
			<div *ngIf="!isNew" class="form-group">
				<label>Date Created</label>
				<div>
					{{model.date | dateFormat:'MMM DD, YYYY' }}
				</div>
			</div>
			<div class="form-group">
				<label>Date Promised</label>
				<div class="">
					<input autocomplete="off" type="date" class="form-control width-auto" [ngModel]="model.datePromised | dateFormat" (ngModelChange)="model.datePromised = $event" name="datePromised" #datePromised="ngModel" />
				</div>
			</div>
			<div class="form-group" style="position: relative">
				<priority-component [model]="model" [isNew]="isNew" (changedPriority)="setPriority($event)"></priority-component>
			</div>
		</div>
		<div class="col-xs-12 col-md-6">
			<div class="form-group">
				<label>Name</label>
				<input autocomplete="off" type="text" class="form-control" [(ngModel)]="model.name" name="name" required="required" #name="ngModel" />
				<div class="alert alert-danger" [hidden]="(!workOrderForm.form.submitted && name.pristine) || name.valid">
					Name is required
				</div>
			</div>
			<div class="form-group">
				<label>Project Manager</label>
				<div class="">
					<select class="form-control" [(ngModel)]="model.projectManager" name="projectManager" #projectManager="ngModel" required="required">
						<option *ngFor="let user of users" [value]="user._id">
							{{ user.firstName }} {{ user.lastName }}
						</option>
					</select>
					<div class="alert alert-danger" [hidden]="(!workOrderForm.form.submitted && projectManager.pristine) || projectManager.valid">
						Project manager is required
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row" style="margin-top:20px;">
		<div class="col-xs-12 col-md-6">
			<div class="form-group">
				<label class="control-label">Associated Documents</label>
				<document-upload
					type="WO"
					[isDraggable]="false"
					[label]="'Add documents'"
					[model]="model"
					[typeId]="model._id"
					[duplicateRecord]="duplicateRecord"
					(queued)="setMessage($event)"
					(duplicateFound)="duplicate()"
					(saveModel)="saveWorkOrder()"
				></document-upload>
			</div>
		</div>
		<div class="col-xs-12 col-md-6">
			<div class="form-group">
				<label>Notes</label>
				<div class="">
					<notes-field [(model)]="model.notes" [saved]="saved"></notes-field>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12">
			<p *ngIf="filesQueued" class="error-msg small">Please upload files before saving.</p>
			<button type="submit" class="btn btn-primary mr-1" [disabled]="!workOrderForm.form.valid || filesQueued"><span *ngIf="isNew">Create</span><span *ngIf="!isNew">Save Changes</span></button>
			<div class="inline-block pseudo-link small capitalize" *ngIf="isNew" (click)="cancel()">Cancel</div>
		</div>
	</div>

</form>
