<form (ngSubmit)="saveContact()" #contactForm="ngForm" class="">
    <input name="id" type="hidden" value="{{ model._id }}" />

	<div class="form-group row">
		<div class="col-sm-12 col-md-6 col-lg-4">
            <label>Salutation</label>
            <div>
                <div class="btn-group">
                    <button type="button" class="btn btn-default" (click)="setSalutation('Ms.')" [ngClass]="{'active':model.salutation == 'Ms.'}">Ms.</button>
                    <button type="button" class="btn btn-default" (click)="setSalutation('Mrs.')" [ngClass]="{'active':model.salutation == 'Mrs.'}">Mrs.</button>
                    <button type="button" class="btn btn-default" (click)="setSalutation('Mr.')" [ngClass]="{'active':model.salutation == 'Mr.'}">Mr.</button>
                    <button type="button" class="btn btn-default" (click)="setSalutation('')" [ngClass]="{'active':model.salutation == ''}">None</button>
                </div>
            </div>
		</div>

        <div class="col-sm-12 col-md-6 col-lg-4">
            <label>Job Title</label>
            <input id="title" autocomplete="off" type="text" class="form-control" [(ngModel)]="model.title" name="title" #title="ngModel" initialFocus/>
        </div>
        <div class="clearfix"></div>
	</div>

    <div class="form-group row">
        <div class="col-sm-12 col-md-6 col-lg-4">
            <label>First Name</label>
            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="model.firstName" name="firstName" required="required" #firstName="ngModel" />
            <div class="alert alert-danger" [hidden]="(!contactForm.form.submitted && firstName.pristine) || firstName.valid">
                First name is required
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
            <label>Last Name</label>
            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="model.lastName" name="lastName" required="required" #lastName="ngModel" />
            <div class="alert alert-danger" [hidden]="(!contactForm.form.submitted && lastName.pristine) || lastName.valid">
                Last name is required
            </div>
        </div>
        <div class="clearfix"></div>
    </div>


    <div class="form-group row">
        <div class="col-sm-12 col-md-6 col-lg-4">
            <label>Phone Number</label>
            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="model.phone" name="phone" #phone="ngModel" [imask]="{ mask: '(000) 000-0000 x00000' }" validatePhone />
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
            <label>Email</label>
            <input autocomplete="off" type="email" class="form-control" [(ngModel)]="model.email" name="email" #email="ngModel" />
        </div>
        <div class="clearfix"></div>
    </div>

	<div class="row mt-3">
		<div class="col-sm-12 col-md-12 col-lg-8">
            <button type="submit" class="btn btn-primary mr-1" [disabled]="!contactForm.form.valid || !validate()">Save contact</button>
            <div class="inline-block pseudo-link small capitalize" (click)="cancel()">Cancel</div>
		</div>
	</div>
</form>
<div class="row mt-3 mb-3" *ngIf="showAlert">
    <div class="col-12">
        <div class="alert alert-danger text-center">
            A contact with that name already exists. <button type="button" class="btn btn-link pa-0" (click)="goToExisting()" style="font-size:16px; padding:0;">Click to view the contact</button>.
        </div>
    </div>
</div>
