import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { ILocationModel, LocationModel } from '@/models/location.models';

export interface ILocationState {
	locations: Array<ILocationModel>,
	activeLocation: ILocationModel
}

const defaultState : ILocationState = {
	locations: new Array<ILocationModel>(),
	activeLocation: new LocationModel()
};

export const LOCATION_ACTIONS = {
	SET_LOCATIONS_LIST: 'SET_LOCATIONS_LIST',
	SET_ACTIVE_LOCATION: 'SET_ACTIVE_LOCATION'
};

const locationReducer = function(state: ILocationState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case LOCATION_ACTIONS.SET_LOCATIONS_LIST:
			newState.locations = action.payload;
			break;
		case LOCATION_ACTIONS.SET_ACTIVE_LOCATION:
			newState.activeLocation = action.payload;
			break;
	}

	return newState;
};

export function getLocationsList() {
	return (state : Store<ILocationState>) => {
		return state.select(s => {
			return s.locations;
		});
	};
}

export function getActiveLocation() {
	return (state : Store<ILocationState>) => {
		return state.select(s => s.activeLocation)
	};
}

export function reducer(state: ILocationState | undefined, action: Action) {
	return locationReducer(state, action);
}
