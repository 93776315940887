<div class="container fixed">
	<div class="row">
		<div class="col-md-10 col-md-offset-1 searchbox" [ngClass]="{'disabled': isLoading}">
			<search [(ngModel)]="searchTerm" large="true" (onAction)="search()" placeholder="Search Receivables" #invoiceSearch keyboardAccelerator="9" (onKeyboardAction)="search()" [isLoading]="isLoading"></search>
		</div>
	</div>
	<div class="row filter-row" style="align-items:flex-start">
		<div class="" [ngClass]="{'col-sm-5 col-md-7': isAdmin, 'col-sm-9 col-md-8': !isAdmin}">
			<filter-list *ngIf="filterOptions.length" [itemType]="'receivable'" [activeFilters]="filters" [filterOptions]="filterOptions" (filterValue)="filterTheList($event)"></filter-list>
		</div>
		<div *ngIf="isAdmin" class="col-sm-4 col-md-3 border-left-md">
			<div class="form-group mb-0">
				<label>Update Status</label>
				<div class="input-group">
					<select class="form-control" [(ngModel)]="updatedStatus">
						<option value='' selected disabled>-- Select --</option>
						<option *ngFor="let option of statusList" [value]="option.value" #batchStatus>{{option.label}}</option>
					</select>
					<span class="input-group-btn">
						<button type="button" class="btn btn-default" (click)="updateStatus()" [disabled]="!selectedInvoices.length || !updatedStatus">Apply</button>
					</span>
				</div>
			</div>
		</div>
		<div [ngClass]="{'col-sm-3 col-md-2': isAdmin, 'col-sm-3 col-md-4': !isAdmin}">
			<div class="text-right mt-2">
				<div class="list-filters">
					<button type="button" class="btn btn-default" (click)="filterToggle()" [ngClass]="{'active btn-primary':showClosed}" style="position: relative; top:2px;">
						<i class="glyphicon glyphicon-lock"></i><span *ngIf="showClosed"> Hide</span><span *ngIf="!showClosed"> Show</span> Closed
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12 wo-list-column mt-1" id="infinite-scroll-list">
			<table id="wo-list" class="table table-select-list mb-0" cellspacing=0 cellpadding=0>
				<caption class="sr-only">Sort list links</caption>
				<thead>
				<tr>
					<th *ngIf="isAdmin" scope="col" class="check-col">
						<label class="custom-checkbox large" style="position: relative; top: -6px;">
							<input type="checkbox" [ngModel]="selectAll" (change)="toggleSelectAll()">
							<span class="checkmark"></span>
						</label>
					</th>
					<th scope="col" class="invoice-col">
						<infinite-scroll-header class="sortable" label="Invoice Number" field="invoiceNumber" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="date-col">
						<infinite-scroll-header class="sortable" label="Date" field="invoiceDate" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="customer-col">
						<infinite-scroll-header class="sortable" label="Customer" field="job.customer.name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" [ngClass]="{'job-col': isAdmin, 'job-col-lg': !isAdmin}">
						<infinite-scroll-header class="sortable" label="Job / Project Manager" field="job.name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="status-col">
						<infinite-scroll-header class="sortable" label="Status" field="status.status" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="amount-col text-right">
						<infinite-scroll-header class="sortable" label="Amount" field="amountThisInvoice" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="list-actions"></th>
				</tr>
				</thead>
			</table>
			<div id="top-of-list-marker"></div>
		</div>
	</div>
</div>
<div class="container list-container">
	<div class="attached infinite-scroll-list"
		infiniteScroll
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		[scrollWindow]="true"
		(scrolled)="onScroll($event)"
		*ngIf="!isLoading" [@fadeInOut]>
		<table class="table table-select-list">
			<caption class="sr-only">List of Receivables</caption>
			<thead class="spacer">
				<tr>
					<th *ngIf="isAdmin" scope="col" class="check-col"></th>
					<th scope="col" class="invoice-col"></th>
					<th scope="col" class="date-col"></th>
					<th scope="col" class="customer-col"></th>
					<th scope="col" [ngClass]="{'job-col': isAdmin, 'job-col-lg': !isAdmin}"></th>
					<th scope="col" class="status-col"></th>
					<th scope="col" class="amount-col"></th>
					<th scope="col" class="list-actions"></th>
				</tr>
			</thead>
			<tbody>
			<tr class="wo-list-item" *ngFor="let i of invoiceSet">
				<td *ngIf="isAdmin" scope="row">
					<label class="custom-checkbox">
						<input type="checkbox" [(ngModel)]="i.selected" [checked]="i.selected" (change)="updateSelected(i)">
						<span class="checkmark"></span>
					</label>
				</td>
				<td scope="row" (click)="editEntity(i)">
					{{ i.invoiceNumber }}
				</td>
				<td scope="row" (click)="editEntity(i)">
					{{ i.invoiceDate | dateFormat: 'MM/DD/YYYY' }}
				</td>
				<td scope="row" (click)="editEntity(i)">
					{{ i.job.customer.name }}
				</td>
				<td scope="row" (click)="editEntity(i)">
					<div>{{ i.job.name }}</div>
					<div class="pm-name">{{ i.job.projectManager ? i.job.projectManager.firstName + ' ' + i.job.projectManager.lastName : '' }}</div>
				</td>
				<td scope="row" (click)="editEntity(i)">
					<div class="status-options {{i.css}}">{{ i.status || '' }}</div>
				</td>
				<td scope="row" (click)="editEntity(i)" class="text-right">
					{{ i.amountThisInvoice | currencyFormat }}
				</td>
				<td scope="row" class="list-actions" (click)="editEntity(i)">
					<button class="btn btn-default" type="button" (click)="editEntity(i)">Edit</button>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
	<div *ngIf="!isLoading && !invoiceSet.length" id="loading">No results</div>
</div>
