import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

export function getRouteParameter(route : ActivatedRoute, parameter : string) : string {
    let currentRoute = route;

    while(currentRoute && !currentRoute.snapshot.params[parameter]) {
        currentRoute = getParentRoute(currentRoute);
    }

    return currentRoute ? currentRoute.snapshot.params[parameter] : null;
};

export function getRouteParameterObservable(route : ActivatedRoute, parameter : string) : Observable<{ [key : string] : any }> {
    let currentRoute = route;

    while(currentRoute && !currentRoute.snapshot.params[parameter]) {
        currentRoute = getParentRoute(currentRoute);
    }

    return currentRoute ? currentRoute.params : null;
};

export function getParentRoute(route : ActivatedRoute, depth : number = 1) : ActivatedRoute {
    let currentRoute = route;
    let parentRoute : ActivatedRoute;

    for(let i = 0; i < depth; i += 1) {
        if(currentRoute !== null) {
            parentRoute = currentRoute.parent;
        }
        currentRoute = parentRoute;
    }

    return parentRoute;
};

//HACK: sometimes the route hierarchy is not available to us (like in sidebar components)
export function getRouteParameterByIndex(router : Router, index : number) : string {
    let routeTokens = router.routerState.snapshot.url.split('/');

    return routeTokens[index] ? routeTokens[index] : null;
};
