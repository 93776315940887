import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from './../base.component';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { FilesService } from '@/services/files.service';

import { IJobModel } from '@/models/job.models';
interface ISelectedJob {
    id : string;
    name : string;
}

@Component({
    selector: 'job-documents',
    templateUrl: './jobDocuments.template.html',
    host: {'class': 'job-documents-component'}
})
export class JobDocumentsComponent extends BaseSmartComponent implements OnInit {

    private _customerId: string;
    // todo refactor or rename so public field isn't prefixed with an _
    public _jobId: string;

    private model = new Subject<IJobModel>();
    public duplicateRecord: boolean = false;
    private filesQueued: boolean = false;
    public currentJob: any = {};

    constructor(private router: Router, private route: ActivatedRoute, private jobService: JobService, messageService: MessageService, private filesService: FilesService) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;

        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
    }

    public setMessage(e) {
        this.filesQueued = e;
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }
};
