<header>
    <div class="row">
        <div class="col-xs-12 col-md-8">
            <search [(ngModel)]="filterTerm" placeholder="Filter work orders" filterData="text" [filterManager]="dataManager"></search>
        </div>
        <div class="col-xs-12 col-md-4">
            <button type="button" class="btn btn-block btn-default" (click)="addClicked.emit()">Add work order</button>
        </div>
    </div>
</header>

<work-order-list class="component" [showIndicator]="true" [model]="model | async" (onWorkOrderSelect)="itemSelected.emit($event)" [selectedWorkOrderId]="selectedWorkOrderId"></work-order-list>
