import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { CustomerService } from '@/services/customer.service';
import { ICustomerModel } from '@/models/customer.models';

@Component({
  selector: 'customer-edit-details',
  templateUrl: './customerEditDetails.template.html',
  host: {'class': 'customer-edit-details-component'}
})
export class CustomerEditDetailsComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<ICustomerModel>;
  public showAlert: boolean = false;
  private duplicateCustomer: ICustomerModel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private customerService: CustomerService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveCustomer();
  }

  public saveCustomer(newModel: ICustomerModel): void {
    this.customerService.updateCustomer(newModel)
      .then((saved) => {
        this.showAlert = false;
      })
      .catch((err) => {
        this.showAlert = true;
        let error = err.error;
        this.duplicateCustomer = error.record;
      });
  }

  public goToCustomer(): void {
    this.router.navigate(
      ['/Customers', this.duplicateCustomer._id, 'Edit', 'Details'],
      { relativeTo: routerUtils.getParentRoute(this.route) }
      );
  }
}
