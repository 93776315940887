import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UI_MASKS } from './../../utils/uiMasks';

import { IContactModel, ContactModel } from './../../models/contact.models';

@Component({
   selector: 'contact-edit',
   templateUrl: './contactEdit.template.html',
   host: {'class': 'contact-edit-component'}
})

export class ContactEditComponent {
    @ViewChild('contactForm', {static: true}) contactForm;

    @Input()
    model : IContactModel = new ContactModel();

    @Input()
    isNew : boolean = true;

    @Input()
    showAlert : boolean = false;

    @Output()
    onSave = new EventEmitter<IContactModel>();

    @Output()
    onCancel = new EventEmitter<void>();

    @Output()
    goToContact = new EventEmitter<void>();

    constructor(public uiMasks : UI_MASKS) {}

    saveContact() : void {
        if(this.isNew){
            this.model.isActive = true;
        }
        // Using spread operator to avoid model getting reset before promise finished
        this.onSave.emit({...this.model});
        this.contactForm.reset();
    }

    goToExisting() : void {
        this.goToContact.emit(null);
    }

    cancel() : void {
        this.contactForm.reset();
        this.onCancel.emit(null);
    }

    setSalutation(value:string): void {
        this.model.salutation = value;
    }

    // custom validation
    validate() : boolean {
        return !this.model.phone || (this.model.phone.length === 0) || (this.model.phone.length > 9);
    }
}
