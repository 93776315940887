import { Directive, HostListener, EventEmitter, Output, Input } from '@angular/core';

//TODO: naive implementation
// - doesn't support multiple accelerators per element
// - doesn't support key modifiers (alt, shift, ctrl)
@Directive({
    selector: '[keyboardAccelerator]'
})
export class KeyboardAcceleratorDirective {
    @Input()
    keyboardAccelerator: string = null;

    @Output()
    onKeyboardAction = new EventEmitter<Event>();

    @HostListener('keyup', ['$event'])
    executeAction(event : Event) : void {
        let keyCode = this.keyboardAccelerator;

        if(keyCode) {
            let keyEvent = event as KeyboardEvent;

            // only trigger linked event if it matches our defined code
            // Autofill doesnt sent keycode on event
            if(keyEvent.keyCode && keyEvent.keyCode.toString() === keyCode) {
                // don't (double-)trigger event on excluded elements id (i.e. submit button)
                if((event.srcElement as HTMLElement).getAttribute('data-keyboard-exclude') !== null) {
                    return;
                }

                this.onKeyboardAction.emit(event);

                event.stopPropagation();
            }
        }
    }
};
