import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { IUserModel } from '@/models/user.models';
import { Role, UserService } from '@/services/user.service';
import { MessageService } from './../../services/message.service';
import { BaseSmartComponent } from './../base.component';


@Component({
  selector: 'user-edit-form',
  templateUrl: './userEditForm.template.html',
  host: {'class': 'user-edit-form-component'}
})
export class UserEditFormComponent extends BaseSmartComponent implements OnInit {
  @Input()
  model: IUserModel;

  @Input()
  locationId: String;

  @Output()
  onSave: EventEmitter<IUserModel> = new EventEmitter<IUserModel>();

  @Output()
  onCancel: EventEmitter<unknown> = new EventEmitter();

  public userRoles: Role[];
  public defaultLocation: String;
  constructor(
    private userService: UserService, 
    messageService : MessageService
    ) {
      super(messageService);

    }

  ngOnInit(): void {
    this.userRoles = this.userService.getRoles();
    this.setDefaultDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.setDefaultDefault();
    }
  }

  saveUser(): void {
    this.onSave.emit(this.model);
  }

  cancel(): void {
    this.onCancel.emit(null);
  }

  setDefaultDefault():void {
    if(this.locationId){
      this.defaultLocation = this.locationId;
    } else {
      if(this.model.locations){
        this.model.locations.forEach(location => {
          if(location.isDefault){
            this.defaultLocation = location.id;
          };
        });
      }
    }
  }

  setDefault(id: string): void {
    this.model.locations.forEach(location => {
      location.isDefault = false;
    });
    this.model.locations.forEach(location => {
      if (location.id === id) {
        location.isDefault = true;
        this.defaultLocation = location.id;
      }
    });
  }
}
