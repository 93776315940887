import { IUserModel } from '@/models/user.models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inactiveUser',
    pure: true
})
export class InactiveUserPipe implements PipeTransform {
    transform(value: Array<IUserModel>, showInactive: boolean = true) {
        let result = new Array<IUserModel>();

        if(value && value.length > 0) {
            for(let i = 0; i < value.length; i += 1) {
                if(showInactive){
                    result.push(value[i]);
                } else {
                    if(value[i].isActive) {
                        result.push(value[i]);
                    }
                }
            }
        }

        return result;
    }
};
