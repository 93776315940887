<div class="breadcrumbs-container">
<div class="breadcrumbs">
    <!-- added index to breadcrumbs for easier tracking in Sentry -->
    <div class="breadcrumb-item item{{i}}" *ngFor="let breadcrumb of breadcrumbs; let i=index" [ngClass]="{'navigable': breadcrumb.route }" (click)="navigateToBreadcrumb(breadcrumb)">
        {{ breadcrumb.label }}
    </div>
</div>
</div>

<router-outlet></router-outlet>
