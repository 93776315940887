//TODO: many of the sort functions can be refactored into more generic versions to reduce amount of code
import {orderBy} from 'lodash';

import { IJobModel, ILaborCodeModel } from './../models/job.models';
import { IStaffModel } from './../models/staff.models';
import { IUserModel } from './../models/user.models';
import { ICustomerModel } from './../models/customer.models';
import { ISupplierModel } from './../models/supplier.models';
import { ITimeEntryModel } from './../models/timeEntry.models';
import { IWorkOrderModel } from './../models/workOrder.models';
import { IPurchaseOrderModel } from './../models/purchaseOrder.models';
import { IChangeOrderModel } from './../models/changeOrder.models';
import { IInvoiceModel, IInvoiceSummary } from './../models/invoice.models';
import { IPayableModel, IPayableSummary } from './../models/payable.models';
import { IWorkOrderPartModel, IPurchaseOrderPartModel } from './../models/part.models';
import { IPurchaseOrderPartPrintingModel } from './../models/print.models';
import { IPMNoteModel } from './../models/pmNote.models';
import { IReceiptModel } from './../models/receipt.models';
import { ICostModel } from './../models/cost.models';
import {
  IReportJobListResult,
  IReportJournalCostResult,
  IReportJournalTimeEntryResult,
  IReportJobOutcomeResult,
  IReportMonthlyCostsResult,
  IReportInvoiceSummaryResult,
  IReportJobDetailCost
} from './../models/report.models';
import * as Utility from './../models/utility.models';
import * as dataUtils from './dataUtils';
import {PurchaseOrderPayable} from '@/services/purchaseOrder.service';


export interface IDataSortProvider {
	currentSort: string;
	descending: boolean;

	setSort: (type: string) => void;
};

export interface IDataFilterProvider {
	filterTerm: string;

	setFilter: (type: string, term: string) => void;
}

export interface ISortFunction<T> {
	(source: Array<T>, order: number): Array<T>;    // 1 for ascending, -1 for descending
};

export interface IFilterFunction<T> {
	(source: Array<T>, filterTerm: string): Array<T>;
};

export interface ISortFunctionHash<T> {
	[key: string]: ISortFunction<T>;
};

export interface IFilterFunctionHash<T> {
	[key: string]: IFilterFunction<T>;
};

const compare = (a: number | string, b: number | string, isAsc: boolean): number => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

//General search and sort functions to handle Work order and Purchase order frontend

export const GeneralSortFunction = (source, field, direction) => {
	var prop = field;
	prop = prop.split('.');
	var len = prop.length;
	var sG = direction == 1 ? 1 : -1;
	var sL = direction == 1 ? -1 : 1;
	return source.sort( (a, b) => {
		var i = 0;
		while( a && b && i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
		a = a ? a.toLowerCase() : '';
		b = b ? b.toLowerCase() : '';
		if (a === null || a === '') {
			return 1;
		}
		else if (b === null || b === '') {
			return -1;
		}
		else if (a === b) {
			return 0;
		}
		else if (a > b) {
			return sG;
		}
		else if (a < b) {
			return sL;
		}

	});
}

export const GeneralSearchFunction = (source, searchTerm, searchFields) => {
	if(searchTerm != null){
		var isDate = dataUtils.validDate(searchTerm);
		if(isDate && searchTerm.indexOf('/') != -1){
			let tmp = searchTerm.split('/');
			if(tmp.length == 3){
				searchTerm = tmp[2] + '-' + tmp[0] + '-' + tmp[1];
			}
		}
		var search = RegExp('.*' + searchTerm.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') + '.*', 'ig');
		return source.filter(row => {
			return searchFields.some(prop => {
				var i = 0;
				var field = row;
				prop = prop.split('.');
				var len = prop.length;
				while(field && i < len ) { field = field[prop[i]]; i++; }
				if(field){
					if(field.toString().search(search) >= 0){
						return true;
					}
					return false;
				}
				return false;
			});
		});
	}
	return source;
}

// <editor-fold> Jobs

export const JobSortFunctions: ISortFunctionHash<IJobModel> = {
	'name': (source: Array<IJobModel>, order: number): Array<IJobModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}
			else if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IJobModel>, order: number): Array<IJobModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}
			else if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'customerName': (source: Array<IJobModel>, order: number): Array<IJobModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aCustomer = a.customer as Utility.IBackReference;
			let bCustomer = b.customer as Utility.IBackReference;

			if (aCustomer.name > bCustomer.name) {
				return 1 * order;
			}
			else if (aCustomer.name < bCustomer.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'projectManagerName': (source: Array<IJobModel>, order: number): Array<IJobModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aProjectManager = a.projectManager as Utility.IBackReference;
			let bProjectManager = b.projectManager as Utility.IBackReference;

			// always sort empty to either side
			if (!a.projectManager) { return -1 * order; }
			if (!b.projectManager) { return 1 * order; }

			if (aProjectManager.name > bProjectManager.name) {
				return 1 * order;
			}
			else if (aProjectManager.name < bProjectManager.name) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const JobFilterFunctions: IFilterFunctionHash<IJobModel> = {
	'text': (source: Array<IJobModel>, term: string): Array<IJobModel> => {
		return source.filter((job) => {
			let customer = job.customer as Utility.IBackReference;

			return (job.name.toLowerCase().indexOf(term) > -1)
				|| (job.number.toLowerCase().indexOf(term) > -1)
				|| (customer.name.toLowerCase().indexOf(term) > -1);
		});
	}
};

// </editor-fold>

// <editor-fold> Staff

export const StaffSortFunctions: ISortFunctionHash<IStaffModel> = {
	'firstName': (source: Array<IStaffModel>, order: number): Array<IStaffModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.firstName > b.firstName) {
				return 1 * order;
			}
			else if (a.firstName < b.firstName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'lastName': (source: Array<IStaffModel>, order: number): Array<IStaffModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.lastName > b.lastName) {
				return 1 * order;
			}
			else if (a.lastName < b.lastName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'position': (source: Array<IStaffModel>, order: number): Array<IStaffModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.position > b.position) {
				return 1 * order;
			}
			else if (a.position < b.position) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>

// <editor-fold> Users

export const UserSortFunctions: ISortFunctionHash<IUserModel> = {
	'firstName': (source: Array<IUserModel>, order: number): Array<IUserModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.firstName > b.firstName) {
				return 1 * order;
			}
			else if (a.firstName < b.firstName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'lastName': (source: Array<IUserModel>, order: number): Array<IUserModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.lastName > b.lastName) {
				return 1 * order;
			}
			else if (a.lastName < b.lastName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'roles': (source: Array<IUserModel>, order: number): Array<IUserModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.role > b.role) {
				return 1 * order;
			}
			else if (a.role < b.role) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>

// <editor-fold> Customers

export const CustomerSortFunctions: ISortFunctionHash<ICustomerModel> = {
	'name': (source: Array<ICustomerModel>, order: number): Array<ICustomerModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}
			else if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold> Customers

// <editor-fold> Suppliers

export const SupplierSortFunctions: ISortFunctionHash<ISupplierModel> = {
	'name': (source: Array<ISupplierModel>, order: number): Array<ISupplierModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}
			else if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>

// <editor-fold> Work Orders

export const WorkOrderSortFunctions: ISortFunctionHash<IWorkOrderModel> = {
	'date': (source: Array<IWorkOrderModel>, order: number): Array<IWorkOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}
			else if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IWorkOrderModel>, order: number): Array<IWorkOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}
			else if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'name': (source: Array<IWorkOrderModel>, order: number): Array<IWorkOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}
			else if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'status': (source: Array<IWorkOrderModel>, order: number): Array<IWorkOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.status > b.status) {
				return 1 * order;
			}
			else if (a.status < b.status) {
				return -1 * order;
			}

			return 0;
		});
	},
	'priority': (source: Array<IWorkOrderModel>, order: number): Array<IWorkOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.priority > b.priority) {
				return 1 * order;
			}
			else if (a.priority < b.priority) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const WorkOrderFilterFunctions: IFilterFunctionHash<IWorkOrderModel> = {
	'text': (source: Array<IWorkOrderModel>, term: string): Array<IWorkOrderModel> => {
		return source.filter((workOrder) => {
			return (workOrder.number ? workOrder.number.toLowerCase().indexOf(term) > - 1 : false)
				|| (workOrder.name ? workOrder.name.toLowerCase().indexOf(term) > - 1 : false);
		});
	}
};

// </editor-fold> Work Orders

// <editor-fold> Purchase Orders

export const PurchaseOrderSortFunctions: ISortFunctionHash<IPurchaseOrderModel> = {
	//TODO: why is this the only date sort that is reversed from all others?
	'date': (source: Array<IPurchaseOrderModel>, order: number): Array<IPurchaseOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return -1 * order;
			}
			else if (a.date < b.date) {
				return 1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IPurchaseOrderModel>, order: number): Array<IPurchaseOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}
			else if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'supplier': (source: Array<IPurchaseOrderModel>, order: number): Array<IPurchaseOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aSupplier = a.supplier as Utility.IBackReference;
			let bSupplier = b.supplier as Utility.IBackReference;

			if (aSupplier.name > bSupplier.name) {
				return 1 * order;
			}
			else if (aSupplier.name < bSupplier.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobname': (source: Array<IPurchaseOrderModel>, order: number): Array<IPurchaseOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aJob = a.job as Utility.IBackReference;
			let bJob = b.job as Utility.IBackReference;

			if (aJob.name > bJob.name) {
				return 1 * order;
			} else if (aJob.name < bJob.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobcustomername': (source: Array<IPurchaseOrderModel>, order: number): Array<IPurchaseOrderModel> => {
        return source.sort((a, b) => {
            if (!a || !b) { return 0; }

            let aCustomer = a.customer as Utility.IBackReference;
            let bCustomer = b.customer as Utility.IBackReference;

            if (aCustomer.name > bCustomer.name) {
                return 1 * order;
            } else if (aCustomer.name < bCustomer.name) {
                return -1 * order;
            }

            return 0;
        });
    },
	'status': (source: Array<IPurchaseOrderModel>, order: number): Array<IPurchaseOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.status > b.status) {
				return 1 * order;
			}
			else if (a.status < b.status) {
				return -1 * order;
			}

			return 0;
		});
    }
};

export const PurchaseOrderFilterFunctions: IFilterFunctionHash<IPurchaseOrderModel> = {
	'text': (source: Array<IPurchaseOrderModel>, term: string): Array<IPurchaseOrderModel> => {
		return source.filter((purchaseOrder) => {
			let supplier = purchaseOrder.supplier as Utility.IBackReference;

			return (purchaseOrder.number.toLowerCase().indexOf(term) > -1)
				|| (purchaseOrder.name && (purchaseOrder.name.toLowerCase().indexOf(term) > -1))
				|| (supplier && (supplier.name.toLowerCase().indexOf(term) > -1));
		});
	}
};

// </editor-fold> Purchase Orders

// <editor-fold> Change Orders

export const ChangeOrderSortFunctions: ISortFunctionHash<IChangeOrderModel> = {
	'date': (source: Array<IChangeOrderModel>, order: number): Array<IChangeOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}
			else if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IChangeOrderModel>, order: number): Array<IChangeOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}
			else if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'amount': (source: Array<IChangeOrderModel>, order: number): Array<IChangeOrderModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.amount > b.amount) {
				return 1 * order;
			}
			else if (a.amount < b.amount) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const ChangeOrderFilterFunctions: IFilterFunctionHash<IChangeOrderModel> = {
	'text': (source: Array<IChangeOrderModel>, term: string): Array<IChangeOrderModel> => {
		return source.filter((changeOrder) => {
			return changeOrder.number
				? changeOrder.number.toLowerCase().indexOf(term) > - 1
				: false;
		});
	}
};

// </editor-fold> Change Orders

// <editor-fold> Invoices

export const InvoiceSortFunctions: ISortFunctionHash<IInvoiceModel> = {
	'invoiceDate': (source: Array<IInvoiceModel>, order: number): Array<IInvoiceModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceDate > b.invoiceDate) {
				return 1 * order;
			}
			else if (a.invoiceDate < b.invoiceDate) {
				return -1 * order;
			}

			return 0;
		});
	},
	'invoiceNumber': (source: Array<IInvoiceModel>, order: number): Array<IInvoiceModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceNumber > b.invoiceNumber) {
				return 1 * order;
			}
			else if (a.invoiceNumber < b.invoiceNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'orderNumber': (source: Array<IInvoiceModel>, order: number): Array<IInvoiceModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.orderNumber > b.orderNumber) {
				return 1 * order;
			}
			else if (a.orderNumber < b.orderNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'amountThisInvoice': (source: Array<IInvoiceModel>, order: number): Array<IInvoiceModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.amountThisInvoice > b.amountThisInvoice) {
				return 1 * order;
			}
			else if (a.amountThisInvoice < b.amountThisInvoice) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const InvoiceSummarySortFunctions: ISortFunctionHash<IInvoiceSummary> = {
	'invoiceDate': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceDate > b.invoiceDate) {
				return 1 * order;
			}
			else if (a.invoiceDate < b.invoiceDate) {
				return -1 * order;
			}

			return 0;
		});
	},
	'invoiceNumber': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceNumber > b.invoiceNumber) {
				return 1 * order;
			}
			else if (a.invoiceNumber < b.invoiceNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'orderNumber': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.orderNumber > b.orderNumber) {
				return 1 * order;
			}
			else if (a.orderNumber < b.orderNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'amountThisInvoice': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.amountThisInvoice > b.amountThisInvoice) {
				return 1 * order;
			}
			else if (a.amountThisInvoice < b.amountThisInvoice) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobName': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.job.name > b.job.name) {
				return 1 * order;
			}
			else if (a.job.name < b.job.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'customerName': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.job.customer.name > b.job.customer.name) {
				return 1 * order;
			}
			else if (a.job.customer.name < b.job.customer.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'status': (source: Array<IInvoiceSummary>, order: number): Array<IInvoiceSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.status > b.status) {
				return 1 * order;
			}
			else if (a.status < b.status) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const InvoiceFilterFunctions: IFilterFunctionHash<IInvoiceModel> = {
	'text': (source: Array<IInvoiceModel>, term: string): Array<IInvoiceModel> => {
		return source.filter((invoice) => {
			return (invoice.invoiceNumber.toLowerCase().indexOf(term) > -1)
				|| (invoice.orderNumber && (invoice.orderNumber.toLowerCase().indexOf(term) > -1));
		});
	}
};



export const InvoiceSummaryFilterFunctions: IFilterFunctionHash<IInvoiceSummary> = {
	//intentionally blank
};

// </editor-fold> Invoices

// <editor-fold> Payables

export const PayableSortFunctions: ISortFunctionHash<IPayableModel> = {
	'invoiceDate': (source: Array<IPayableModel>, order: number): Array<IPayableModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceDate > b.invoiceDate) {
				return 1 * order;
			}
			else if (a.invoiceDate < b.invoiceDate) {
				return -1 * order;
			}

			return 0;
		});
	},
	'invoiceNumber': (source: Array<IPayableModel>, order: number): Array<IPayableModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceNumber > b.invoiceNumber) {
				return 1 * order;
			}
			else if (a.invoiceNumber < b.invoiceNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'amountThisInvoice': (source: Array<IPayableModel>, order: number): Array<IPayableModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.amount > b.amount) {
				return 1 * order;
			}
			else if (a.amount < b.amount) {
				return -1 * order;
			}

			return 0;
		});
	},
	// TODO not sure why amount/amountThisInvoice is inconsistent. Can likely be updated to be the same everywhere
	'amount': (source: Array<IPayableModel>, order: number): Array<IPayableModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.amount > b.amount) {
				return 1 * order;
			}
			else if (a.amount < b.amount) {
				return -1 * order;
			}

			return 0;
		});
	},
	'status': (source: Array<IPayableModel>, order: number): Array<IPayableModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.status > b.status) {
				return 1 * order;
			}
			else if (a.status < b.status) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const PurchaseOrderPayableSortFunctions: ISortFunctionHash<PurchaseOrderPayable> = {
  'invoiceDate': (source: Array<PurchaseOrderPayable>, order: number): Array<PurchaseOrderPayable> => {
    return orderBy(source, [val => val.invoiceDate], order > 0 ? ['asc'] : ['desc']);
  },
  'invoiceNumber': (source: Array<PurchaseOrderPayable>, order: number): Array<PurchaseOrderPayable> => {
    return source.sort((a, b) => {
      return compare(a.invoiceNumber, b.invoiceNumber, order > 0);
    });
  },
  'amount': (source: Array<PurchaseOrderPayable>, order: number): Array<PurchaseOrderPayable> => {
    return source.sort((a, b) => {
      return compare(a.amount, b.amount, order > 0);
    });
  },
};

export const PayableSummarySortFunctions: ISortFunctionHash<IPayableSummary> = {
	'invoiceDate': (source: Array<IPayableSummary>, order: number): Array<IPayableSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceDate > b.invoiceDate) {
				return 1 * order;
			}
			else if (a.invoiceDate < b.invoiceDate) {
				return -1 * order;
			}

			return 0;
		});
	},
	'invoiceNumber': (source: Array<IPayableSummary>, order: number): Array<IPayableSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceNumber > b.invoiceNumber) {
				return 1 * order;
			}
			else if (a.invoiceNumber < b.invoiceNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'amountThisInvoice': (source: Array<IPayableSummary>, order: number): Array<IPayableSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.amountThisInvoice > b.amountThisInvoice) {
				return 1 * order;
			}
			else if (a.amountThisInvoice < b.amountThisInvoice) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobName': (source: Array<IPayableSummary>, order: number): Array<IPayableSummary> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.job.name > b.job.name) {
				return 1 * order;
			}
			else if (a.job.name < b.job.name) {
				return -1 * order;
			}

			return 0;
		});
	}
};

export const PayableFilterFunctions: IFilterFunctionHash<IPayableModel> = {
	'text': (source: Array<IPayableModel>, term: string): Array<IPayableModel> => {
		return source.filter((payable) => {
			return (payable.invoiceNumber.toLowerCase().indexOf(term) > -1) || (payable.supplier.name.toLowerCase().indexOf(term) > -1) ||
				(payable.purchaseOrders.filter(po =>
					(po.number && po.number.toLowerCase().indexOf(term) > -1) || (po.name && po.name.toLowerCase().indexOf(term) > -1)).length > 0)
		});
	}
};

export const PurchaseOrderPayableFilterFunctions: IFilterFunctionHash<PurchaseOrderPayable> = {
  'text': (source: Array<PurchaseOrderPayable>, term: string): Array<PurchaseOrderPayable> => {
    return source.filter((payable) => {
      return payable.invoiceNumber.toLowerCase().indexOf(term) > -1;
    });
  }
};


export const PayableSummaryFilterFunctions: IFilterFunctionHash<IPayableSummary> = {
	//intentionally blank
};

// </editor-fold> Payables

// <editor-fold> Parts

export const WorkOrderPartSortFunctions: ISortFunctionHash<IWorkOrderPartModel> = {
	'quantity': (source: Array<IWorkOrderPartModel>, order: number): Array<IWorkOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if ((a.quantity === undefined) || (a.quantity === null)) { return -1 * order; }
			if ((b.quantity === undefined) || (b.quantity === null)) { return 1 * order; }

			if (a.quantity > b.quantity) {
				return 1 * order;
			}
			else if (a.quantity < b.quantity) {
				return -1 * order;
			}

			return 0;
		});
	},
	'unit': (source: Array<IWorkOrderPartModel>, order: number): Array<IWorkOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (!a.unit) { return -1 * order; }
			if (!b.unit) { return 1 * order; }

			if (a.unit > b.unit) {
				return 1 * order;
			}
			else if (a.unit < b.unit) {
				return -1 * order;
			}

			return 0;
		});
	},
	'cost': (source: Array<IWorkOrderPartModel>, order: number): Array<IWorkOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if ((a.cost === undefined) || (a.cost === null)) { return -1 * order; }
			if ((b.cost === undefined) || (b.cost === null)) { return 1 * order; }

			if (a.cost > b.cost) {
				return 1 * order;
			}
			else if (a.cost < b.cost) {
				return -1 * order;
			}

			return 0;
		});
	},
	'description': (source: Array<IWorkOrderPartModel>, order: number): Array<IWorkOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.description > b.description) {
				return 1 * order;
			}
			else if (a.description < b.description) {
				return -1 * order;
			}

			return 0;
		});
	},
	'sortOrder': (source: Array<IWorkOrderPartModel>, order: number): Array<IWorkOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) {
				return 0;
			}

			if (a.sortOrder == null || b.sortOrder == null) { //ALSO covers undefined
				let dateA = new Date(a.timestamp), dateB = new Date(b.timestamp);
				return dateA < dateB ? -1 : (dateA > dateB ? 1 : 0);
			}

			if (a.sortOrder > b.sortOrder) {
				return 1 * order;
			}
			else if (a.sortOrder < b.sortOrder) {
				return -1 * order;
			}

			return 0;
		})
	}
};

export const PurchaseOrderPartSortFunctions: ISortFunctionHash<IPurchaseOrderPartModel> = {
	'quantity': (source: Array<IPurchaseOrderPartModel>, order: number): Array<IPurchaseOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if ((a.quantity === undefined) || (a.quantity === null)) { return -1 * order; }
			if ((b.quantity === undefined) || (b.quantity === null)) { return 1 * order; }

			if (a.quantity > b.quantity) {
				return 1 * order;
			}
			else if (a.quantity < b.quantity) {
				return -1 * order;
			}

			return 0;
		});
	},
	'unit': (source: Array<IPurchaseOrderPartModel>, order: number): Array<IPurchaseOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (!a.unit) { return -1 * order; }
			if (!b.unit) { return 1 * order; }

			if (a.unit > b.unit) {
				return 1 * order;
			}
			else if (a.unit < b.unit) {
				return -1 * order;
			}

			return 0;
		});
	},
	'cost': (source: Array<IPurchaseOrderPartModel>, order: number): Array<IPurchaseOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if ((a.cost === undefined) || (a.cost === null)) { return -1 * order; }
			if ((b.cost === undefined) || (b.cost === null)) { return 1 * order; }

			if (a.cost > b.cost) {
				return 1 * order;
			}
			else if (a.cost < b.cost) {
				return -1 * order;
			}

			return 0;
		});
	},
	'description': (source: Array<IPurchaseOrderPartModel>, order: number): Array<IPurchaseOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.description > b.description) {
				return 1 * order;
			}
			else if (a.description < b.description) {
				return -1 * order;
			}

			return 0;
		});
	},
	'sortOrder': (source: Array<IPurchaseOrderPartModel>, order: number): Array<IPurchaseOrderPartModel> => {
		return source.sort((a, b) => {
			if (!a || !b) {
				return 0;
			}

			// changing this to use timestamp if available to match receipts view
			if (a.timestamp == null || b.timestamp == null) { //ALSO covers undefined
				if (a.sortOrder > b.sortOrder) {
					return 1 * order;
				}
				else if (a.sortOrder < b.sortOrder) {
					return -1 * order;
				}

				return 0;
			}

			let dateA = new Date(a.timestamp), dateB = new Date(b.timestamp);
			return dateA < dateB ? -1 : (dateA > dateB ? 1 : 0);

		})
	}
};

export const PurchaseOrderPartPrintSortFunctions: ISortFunctionHash<IPurchaseOrderPartPrintingModel> = {
	'sortOrder': (source: Array<IPurchaseOrderPartPrintingModel>, order: number): Array<IPurchaseOrderPartPrintingModel> => {
		return source.sort((a, b) => {
			if (!a || !b) {
				return 0;
			}

			if (a.sortOrder == null || b.sortOrder == null) { //ALSO covers undefined
				let dateA = new Date(a.timestamp), dateB = new Date(b.timestamp);
				return dateA < dateB ? -1 : (dateA > dateB ? 1 : 0);
			}

			if (a.sortOrder > b.sortOrder) {
				return 1 * order;
			}
			else if (a.sortOrder < b.sortOrder) {
				return -1 * order;
			}

			return 0;
		})
	}
};

// </editor-fold> Parts

// <editor-fold> Receipts

export const ReceiptSortFunctions: ISortFunctionHash<IReceiptModel> = {
	'date': (source: Array<IReceiptModel>, order: number): Array<IReceiptModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}
			else if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	},
	'quantity': (source: Array<IReceiptModel>, order: number): Array<IReceiptModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if ((a.quantity === undefined) || (a.quantity === null)) { return -1 * order; }
			if ((b.quantity === undefined) || (b.quantity === null)) { return 1 * order; }

			if (a.quantity > b.quantity) {
				return 1 * order;
			}
			else if (a.quantity < b.quantity) {
				return -1 * order;
			}

			return 0;
		});
	},
	//TODO: populate parts on backend
	// 'part': (source : Array<IReceiptModel>, order: number) : Array<IReceiptModel> => {
	//     return source.sort((a, b) => {
	//         let aPart = a.part as Utility.IBackReference;
	//         let bPart = a.part as Utility.IBackReference;
	//
	//         if(aPart.name > bPart.name) {
	//             return 1 * order;
	//         }
	//         else if(aPart.name < bPart.name) {
	//             return -1 * order;
	//         }
	//
	//         return 0;
	//     });
	// },
	'location': (source: Array<IReceiptModel>, order: number): Array<IReceiptModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.location > b.location) {
				return 1 * order;
			}
			else if (a.location < b.location) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold> Receipts

// <editor-fold> Purchase Order Costs

export const CostSortFunctions: ISortFunctionHash<ICostModel> = {
	'timestamp': (source: Array<ICostModel>, order: number): Array<ICostModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.timestamp > b.timestamp) {
				return 1 * order;
			}
			else if (a.timestamp < b.timestamp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'type': (source: Array<ICostModel>, order: number): Array<ICostModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.type > b.type) {
				return 1 * order;
			}
			else if (a.type < b.type) {
				return -1 * order;
			}

			return 0;
		});
	},
	'date': (source: Array<ICostModel>, order: number): Array<ICostModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}
			else if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	},
	'cost': (source: Array<ICostModel>, order: number): Array<ICostModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aCost = a.cost + a.countyTax + a.mkecountyTax + a.stateTax + a.cityTax;
			let bCost = b.cost + b.countyTax + b.mkecountyTax + b.stateTax + b.cityTax;

			if (aCost > bCost) {
				return 1 * order;
			}
			else if (aCost < bCost) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold> Purchase Order Costs

// <editor-fold> PM Notes

export const PMNoteSortFunctions: ISortFunctionHash<IPMNoteModel> = {
	'title': (source: Array<IPMNoteModel>, order: number): Array<IPMNoteModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.title > b.title) {
				return 1 * order;
			}
			else if (a.title < b.title) {
				return -1 * order;
			}

			return 0;
		});
	}
}

// </editor-fold> PM Notes

// <editor-fold> Time Entry

export const TimeEntrySortFunctions: ISortFunctionHash<ITimeEntryModel> = {
	'timestamp': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.timestamp > b.timestamp) {
				return 1 * order;
			}
			else if (a.timestamp < b.timestamp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'date': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}
			else if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	},
	'staffName': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aStaff = a.staff as Utility.IBackReference;
			let bStaff = b.staff as Utility.IBackReference;

			if (aStaff.name > bStaff.name) {
				return 1 * order;
			}
			else if (aStaff.name < bStaff.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'rate': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.rate > b.rate) {
				return 1 * order;
			}
			else if (a.rate < b.rate) {
				return -1 * order;
			}

			return 0;
		});
	},
	'hours': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.units > b.units) {
				return 1 * order;
			}
			else if (a.units < b.units) {
				return -1 * order;
			}

			return 0;
		});
	},
	'total': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aTotal = a.rate * a.units;
			let bTotal = b.rate * b.units;

			if (aTotal > bTotal) {
				return 1 * order;
			}
			else if (aTotal < bTotal) {
				return -1 * order;
			}

			return 0;
		});
	},
	'workOrder': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (!a.workOrder) { return -1 * order; }
			if (!b.workOrder) { return 1 * order; }

			let aWorkOrder = a.workOrder as Utility.IBackReference;
			let bWorkOrder = b.workOrder as Utility.IBackReference;

			if (aWorkOrder.name > bWorkOrder.name) {
				return 1 * order;
			}
			else if (aWorkOrder.name < bWorkOrder.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'laborCodeText': (source: Array<ITimeEntryModel>, order: number): Array<ITimeEntryModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.laborCodeText > b.laborCodeText) {
				return 1 * order;
			}
			else if (a.laborCodeText < b.laborCodeText) {
				return -1 * order;
			}

			return 0;
		});
	},

};
export const LaborCodeSortFunctions: ISortFunctionHash<ILaborCodeModel> = {
	'id': (source: Array<ILaborCodeModel>, order: number): Array<ILaborCodeModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.id > b.id) {
				return 1 * order;
			}
			else if (a.id < b.id) {
				return -1 * order;
			}

			return 0;
		});
	},
	'name': (source: Array<ILaborCodeModel>, order: number): Array<ILaborCodeModel> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}
			else if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	 }
	// 'title': (source: Array<ILaborCodeModel>, order: number): Array<ILaborCodeModel> => {
	// 	return source.sort((a, b) => {
	// 		if (!a || !b) { return 0; }

	// 		if (a.title > b.title) {
	// 			return 1 * order;
	// 		}
	// 		else if (a.title < b.title) {
	// 			return -1 * order;
	// 		}

	// 		return 0;
	// 	});
	// }
};

// </editor-fold> Time Entry

// <editor-fold> Report - Job List

export const ReportJobListSortFunctions: ISortFunctionHash<IReportJobListResult> = {
	'name': (source: Array<IReportJobListResult>, order: number): Array<IReportJobListResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}
			else if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IReportJobListResult>, order: number): Array<IReportJobListResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}
			else if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'projectManagerName': (source: Array<IReportJobListResult>, order: number): Array<IReportJobListResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			// always sort empty to either side
			if (!a.projectManagerName) { return -1 * order; }
			if (!b.projectManagerName) { return 1 * order; }

			if (a.projectManagerName > b.projectManagerName) {
				return 1 * order;
			}
			else if (a.projectManagerName < b.projectManagerName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'projectManagerLastName': (source: Array<IReportJobListResult>, order: number): Array<IReportJobListResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			// always sort empty to either side
			if (!a.projectManagerLastName) { return 1; }
			if (!b.projectManagerLastName) { return -1; }

			if (a.projectManagerLastName > b.projectManagerLastName) {
				return 1 * order;
			}
			else if (a.projectManagerLastName < b.projectManagerLastName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'costs': (source: Array<IReportJobListResult>, order: number): Array<IReportJobListResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.costs > b.costs) {
				return 1 * order;
			} else if (a.costs < b.costs) {
				return -1 * order;
			}

			return 0;
		});
	},
	'invoices': (source: Array<IReportJobListResult>, order: number): Array<IReportJobListResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.invoiceTotal > b.invoiceTotal) {
				return 1 * order;
			}
			else if (a.invoiceTotal < b.invoiceTotal) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold> Report - Job List

// <editor-fold> Report - Journal Cost

export const ReportJournalCostSortFunctions: ISortFunctionHash<IReportJournalCostResult> = {
	'timestamp': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a.timestamp || !b.timestamp) { return 0; }

			if (a.timestamp > b.timestamp) {
				return 1 * order;
			}
			else if (a.timestamp < b.timestamp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobNumber': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a.jobNumber || !b.jobNumber) { return 0; }

			if (a.jobNumber > b.jobNumber) {
				return 1 * order;
			}
			else if (a.jobNumber < b.jobNumber) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobName': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a.jobName || !b.jobName) { return 0; }

			if (a.jobName > b.jobName) {
				return 1 * order;
			}
			else if (a.jobName < b.jobName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'cost': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.cost > b.cost) {
				return 1 * order;
			}
			else if (a.cost < b.cost) {
				return -1 * order;
			}

			return 0;
		});
	},
	'stateTax': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.stateTax > b.stateTax) {
				return 1 * order;
			}
			else if (a.stateTax < b.stateTax) {
				return -1 * order;
			}

			return 0;
		});
	},
	'countyTax': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.countyTax > b.countyTax) {
				return 1 * order;
			}
			else if (a.countyTax < b.countyTax) {
				return -1 * order;
			}

			return 0;
		});
	},
	'mkecountyTax': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.mkecountyTax > b.mkecountyTax) {
				return 1 * order;
			}
			else if (a.mkecountyTax < b.mkecountyTax) {
				return -1 * order;
			}

			return 0;
		});
	},
	'cityTax': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.cityTax > b.cityTax) {
				return 1 * order;
			}
			else if (a.cityTax < b.cityTax) {
				return -1 * order;
			}

			return 0;
		});
	},
	'stadiumTax': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.stadiumTax > b.stadiumTax) {
				return 1 * order;
			}
			else if (a.stadiumTax < b.stadiumTax) {
				return -1 * order;
			}

			return 0;
		});
	},
	'total': (source: Array<IReportJournalCostResult>, order: number): Array<IReportJournalCostResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aTotal = a.cost + a.stateTax + a.countyTax + a.mkecountyTax + a.stadiumTax + a.cityTax;
			let bTotal = b.cost + b.stateTax + b.countyTax + b.mkecountyTax + b.stadiumTax + b.cityTax;

			if (aTotal > bTotal) {
				return 1 * order;
			}
			else if (aTotal < bTotal) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold> Report - Journal Cost

// <editor-fold> Report - Time Entry

export const ReportJournalTimeEntrySortFunctions: ISortFunctionHash<IReportJournalTimeEntryResult> = {
	'timestamp': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.timestamp > b.timestamp) {
				return 1 * order;
			}
			else if (a.timestamp < b.timestamp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobName': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b || !a.job || !b.job) { return 0; }

			if (a.job.name > b.job.name) {
				return 1 * order;
			}
			else if (a.job.name < b.job.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobNumber': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b || !a.job || !b.job) { return 0; }

			if (a.job.number > b.job.number) {
				return 1 * order;
			}
			else if (a.job.number < b.job.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'staffName': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b || !a.staff || !b.staff) { return 0; }

			if (a.staff.name > b.staff.name) {
				return 1 * order;
			}
			else if (a.staff.name < b.staff.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'rate': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.rate > b.rate) {
				return 1 * order;
			}
			else if (a.rate < b.rate) {
				return -1 * order;
			}

			return 0;
		});
	},
	'hours': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.units > b.units) {
				return 1 * order;
			}
			else if (a.units < b.units) {
				return -1 * order;
			}

			return 0;
		});
	},
	'laborCodeText': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.laborCodeText > b.laborCodeText) {
				return 1 * order;
			}
			else if (a.laborCodeText < b.laborCodeText) {
				return -1 * order;
			}

			return 0;
		});
	},
	'total': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			let aTotal = a.rate * a.units;
			let bTotal = b.rate * b.units;

			if (aTotal > bTotal) {
				return 1 * order;
			}
			else if (aTotal < bTotal) {
				return -1 * order;
			}

			return 0;
		});
	},
	'workOrder': (source: Array<IReportJournalTimeEntryResult>, order: number): Array<IReportJournalTimeEntryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (!a.workOrder) { return -1 * order; }
			if (!b.workOrder) { return 1 * order; }

			let aInfo = a.workOrder.number + ' - ' + a.workOrder.name;
			let bInfo = b.workOrder.number + ' - ' + b.workOrder.name;

			if (aInfo > bInfo) {
				return 1 * order;
			}
			else if (aInfo < bInfo) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold> Report - Time Entry

// <editor-fold> Report - Job Outcome

export const ReportJobOutcomeSortFunctions: ISortFunctionHash<IReportJobOutcomeResult> = {
	'name': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.name > b.name) {
				return 1 * order;
			}

			if (a.name < b.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}

			if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'contractAmount': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.contractAmount > b.contractAmount) {
				return 1 * order;
			}

			if (a.contractAmount < b.contractAmount) {
				return -1 * order;
			}

			return 0;
		});
	},
	'totalInvoices': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.totalInvoices > b.totalInvoices) {
				return 1 * order;
			}

			if (a.totalInvoices < b.totalInvoices) {
				return -1 * order;
			}

			return 0;
		});
	},
	'totalCosts': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.totalCosts > b.totalCosts) {
				return 1 * order;
			}

			if (a.totalCosts < b.totalCosts) {
				return -1 * order;
			}

			return 0;
		});
	},
	'totalGp': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.totalGp > b.totalGp) {
				return 1 * order;
			}

			if (a.totalGp < b.totalGp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'backlog': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.backlog > b.backlog) {
				return 1 * order;
			}

			if (a.backlog < b.backlog) {
				return -1 * order;
			}

			return 0;
		});
	},
	'projectManagerInitials': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b || a.projectManager || b.projectManager) { return 0; }

			const aInitials = a.projectManager.firstName[0] + a.projectManager.lastName[0];
			const bInitials = b.projectManager.firstName[0] + b.projectManager.lastName[0];

			if (aInitials > bInitials) {
				return 1 * order;
			}

			if (aInitials < bInitials) {
				return -1 * order;
			}

			return 0;
		});
	},
	'nonCurrentInvoices': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.nonCurrentInvoices > b.nonCurrentInvoices) {
				return 1 * order;
			}

			if (a.nonCurrentInvoices < b.nonCurrentInvoices) {
				return -1 * order;
			}

			return 0;
		});
	},
	'nonCurrentCosts': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.nonCurrentCosts > b.nonCurrentCosts) {
				return 1 * order;
			}

			if (a.nonCurrentCosts < b.nonCurrentCosts) {
				return -1 * order;
			}

			return 0;
		});
	},
	'currentCosts': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.currentCosts > b.currentCosts) {
				return 1 * order;
			}

			if (a.currentCosts < b.currentCosts) {
				return -1 * order;
			}

			return 0;
		});
	},
	'currentInvoices': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.currentInvoices > b.currentInvoices) {
				return 1 * order;
			}

			if (a.currentInvoices < b.currentInvoices) {
				return -1 * order;
			}

			return 0;
		});
	},
	'currentGp': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.currentGp > b.currentGp) {
				return 1 * order;
			}

			if (a.currentGp < b.currentGp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'currentGpAmount': (source: Array<IReportJobOutcomeResult>, order: number): Array<IReportJobOutcomeResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.currentGpAmount > b.currentGpAmount) {
				return 1 * order;
			}

			if (a.currentGpAmount < b.currentGpAmount) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>

// <editor-fold> Report - Monthly Costs

export const ReportMonthlyCostsSortFunctions: ISortFunctionHash<IReportMonthlyCostsResult> = {
	'projectManagerFirstName': (source: Array<IReportMonthlyCostsResult>, order: number): Array<IReportMonthlyCostsResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.projectManagerFirstName > b.projectManagerFirstName) {
				return 1 * order;
			}

			if (a.projectManagerFirstName < b.projectManagerFirstName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'projectManagerLastName': (source: Array<IReportMonthlyCostsResult>, order: number): Array<IReportMonthlyCostsResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.projectManagerLastName > b.projectManagerLastName) {
				return 1 * order;
			}

			if (a.projectManagerLastName < b.projectManagerLastName) {
				return -1 * order;
			}

			return 0;
		});
	},
	'costs': (source: Array<IReportMonthlyCostsResult>, order: number): Array<IReportMonthlyCostsResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.costs > b.costs) {
				return 1 * order;
			}

			if (a.costs < b.costs) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>

// <editor-fold> Report - Receivables Summary

export const ReportInvoiceSummarySortFunctions: ISortFunctionHash<IReportInvoiceSummaryResult> = {
	'date': (source: Array<IReportInvoiceSummaryResult>, order: number): Array<IReportInvoiceSummaryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}

			if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	},
	'number': (source: Array<IReportInvoiceSummaryResult>, order: number): Array<IReportInvoiceSummaryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.number > b.number) {
				return 1 * order;
			}

			if (a.number < b.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'customerName': (source: Array<IReportInvoiceSummaryResult>, order: number): Array<IReportInvoiceSummaryResult> => {
		return source.sort((a, b) => {
			if (!a || !b || !a.customer || !b.customer) { return 0; }

			if (a.customer.name > b.customer.name) {
				return 1 * order;
			}

			if (a.customer.name < b.customer.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobNumber': (source: Array<IReportInvoiceSummaryResult>, order: number): Array<IReportInvoiceSummaryResult> => {
		return source.sort((a, b) => {
			if (!a || !b || !a.job || !b.job) { return 0; }

			if (a.job.number > b.job.number) {
				return 1 * order;
			}

			if (a.job.number < b.job.number) {
				return -1 * order;
			}

			return 0;
		});
	},
	'jobName': (source: Array<IReportInvoiceSummaryResult>, order: number): Array<IReportInvoiceSummaryResult> => {
		return source.sort((a, b) => {
			if (!a || !b || !a.job || !b.job) { return 0; }

			if (a.job.name > b.job.name) {
				return 1 * order;
			}

			if (a.job.name < b.job.name) {
				return -1 * order;
			}

			return 0;
		});
	},
	'total': (source: Array<IReportInvoiceSummaryResult>, order: number): Array<IReportInvoiceSummaryResult> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.total > b.total) {
				return 1 * order;
			}

			if (a.total < b.total) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>

// <editor-fold> Report - Job Detail

export const ReportJobDetailSortFunctions: ISortFunctionHash<IReportJobDetailCost> = {
	'timestamp': (source: Array<IReportJobDetailCost>, order: number): Array<IReportJobDetailCost> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.timestamp > b.timestamp) {
				return 1 * order;
			}

			if (a.timestamp < b.timestamp) {
				return -1 * order;
			}

			return 0;
		});
	},
	'date': (source: Array<IReportJobDetailCost>, order: number): Array<IReportJobDetailCost> => {
		return source.sort((a, b) => {
			if (!a || !b) { return 0; }

			if (a.date > b.date) {
				return 1 * order;
			}

			if (a.date < b.date) {
				return -1 * order;
			}

			return 0;
		});
	}
};

// </editor-fold>
