import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';

import { IContactModel } from './../../models/contact.models';

import { DataManipulationService } from './../../services/dataManipulation.service';

@Component({
    selector: 'contact-list',
    templateUrl: './contactList.template.html',
    host: {'class': 'contact-list-component'}
})

export class ContactListComponent extends BaseSmartComponent implements OnInit{
    public model = new Subject<Array<IContactModel>>();

    @Input()
    source: Observable<Array<IContactModel>>;

    @Output()
    onContactSelect = new EventEmitter<IContactModel>();

    @Output()
    onContactDelete = new EventEmitter<IContactModel>();

    @Output()
    onToggleStatus = new EventEmitter<IContactModel>();

    constructor(public dataManipulationService : DataManipulationService<IContactModel>, messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.dataManipulationService.initialize(
            {},
            {
                'lastName': (source: Array<IContactModel>, order: number): Array<IContactModel> => {
                    return source.sort((a, b) => {
                        if(a.lastName > b.lastName) {
                            return 1 * order;
                        }
                        else if(a.lastName < b.lastName) {
                            return -1 * order;
                        }

                        return 0;
                    });
                },
                'firstName': (source: Array<IContactModel>, order: number): Array<IContactModel> => {
                    return source.sort((a, b) => {
                        if(a.firstName > b.firstName) {
                            return 1 * order;
                        }
                        else if(a.firstName < b.firstName) {
                            return -1 * order;
                        }

                        return 0;
                    });
                },
                'title': (source: Array<IContactModel>, order: number): Array<IContactModel> => {
                    return source.sort((a, b) => {
                        if(a.title > b.title) {
                            return 1 * order;
                        }
                        else if(a.title < b.title) {
                            return -1 * order;
                        }

                        return 0;
                    });
                },
                'email': (source: Array<IContactModel>, order: number): Array<IContactModel> => {
                    return source.sort((a, b) => {
                        if(a.email > b.email) {
                            return 1 * order;
                        }
                        else if(a.email < b.email) {
                            return -1 * order;
                        }

                        return 0;
                    });
                }
            }
        );

        self.watchSubscription(self.dataManipulationService.connectDataSource(self.source, self.model));

        self.dataManipulationService.setSort('lastName', false);
    }

    selectContact(contact: IContactModel) {
        this.onContactSelect.emit(contact);
    }

    deleteContact(contact : IContactModel, event : Event) {
        if(this.warnOnDelete('contact')) {
            this.onContactDelete.emit(contact);
        }

        event.stopPropagation();
    }

    toggleContactStatus(contact : IContactModel, event : Event){
        contact.isActive = !contact.isActive;
        this.onToggleStatus.emit(contact);
    }
}
