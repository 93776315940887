import { BaseModel } from './utility.models';

export interface IContactModel extends BaseModel {
    firstName : string;
    lastName : string;
    phone : string;
    email : string;
    title : string;
    salutation : string;
    isActive : boolean;
};

// <editor-fold> Document models

export class ContactModel extends BaseModel implements IContactModel {
    firstName : string = null;
    lastName : string = null;
    phone : string = null;
    email : string = null;
    title : string = null;
    salutation : string = null;
    isActive : boolean = true;
};

// </editor-fold>
