import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { PurchaseOrderSortFunctions } from '@/utils/dataManipulationUtils';
import { JobService } from '@/services/job.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order-connection',
  templateUrl: './workOrderConnection.template.html',
  host: {'class': 'work-order-connection-component'}
})
export class WorkOrderConnectionComponent extends BaseSmartComponent implements OnInit {
  // todo refactor or rename so public property isn't prefixed with _
  public _workOrderId: string;
  // purchase order attachment
  public _selectedPurchaseOrderId : string;
  public model: Subject<Array<IPurchaseOrderModel>> = new Subject<Array<IPurchaseOrderModel>>();

  private _customerId: string;
  private _jobId: string;
  private _jobBaseRoute: ActivatedRoute;
  private filterTerm: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private purchaseOrderService: PurchaseOrderService,
    private dataManipulationService: DataManipulationService<IPurchaseOrderModel>,
    messageService: MessageService
  ) {
    super(messageService);
    this.model.subscribe(POs => {
       this.sortParts(POs);
    });
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._workOrderId = routerUtils.getRouteParameter(this.route, 'workOrderId');

    this.dataManipulationService.initialize({}, PurchaseOrderSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getPurchaseOrdersList(), this.model));
    setTimeout(() => {
      this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId);
    });

    // purchase order listing
    this._jobBaseRoute = routerUtils.getParentRoute(this.route, 3);
  }

	public attachPurchaseOrder(event: Event, selectedPurchaseOrderId): void {
    this.jobService.connectOrders(this._customerId, this._jobId, this._workOrderId, selectedPurchaseOrderId)
      .then(() => this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId))
      .catch(handleError);
    event.stopPropagation();
	}

  public createPurchaseOrder(): void {
    this.router.navigate(['PurchaseOrders', 'Add'], { relativeTo: this._jobBaseRoute });
  }

  private detachPurchaseOrder(purchaseOrder: IPurchaseOrderModel, event: Event): void {
    this.jobService.disconnectOrders(this._customerId, this._jobId, this._workOrderId, purchaseOrder._id)
      .then(() => this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId))
      .catch(handleError);
    event.stopPropagation();
  }

  private goToPurchaseOrder(purchaseOrderId: string): void {
    this.router.navigate(['PurchaseOrders', purchaseOrderId, 'Parts'], { relativeTo: this._jobBaseRoute });
  }

  private sortParts(POs): void {
    POs.forEach(po => {
      po.parts.sort(function(a, b) {
        return (a.timestamp < b.timestamp) ? -1 : ((a.timestamp > b.timestamp) ? 1 : 0);
      });
    });
  }
}
