import { BaseModel, IBackReference, Enum, ITaxes } from './utility.models';
import { WorkflowStatus } from '@/models/workflow.models';
import { IAddress, AddressModel } from './address.models';
import * as Part from './part.models';

export const InvoicePayTypes: Enum<string> = {
	Cash: 'Cash',
	Charge: 'Charge'
};

export interface IInvoiceAutofillRequest {
	date: Date;
	invoiceId: string;
}

export interface IInvoiceAutofillResponse {
	originalContract: number;
	changeOrdersToDate: number;
	amountPreviouslyInvoiced: number;
	laborDirect: number;
	laborShop: number;
	materials: number;
	outsideServices: number;
	costTax: ITaxes;
}

export interface IInvoiceSearchRequest {
	searchTerm: string;
}

export interface IInvoiceAutoCalculateTax {
	[key: string]: number;
}

export interface IInvoiceSummary extends BaseModel {
	invoiceDate: Date;
	invoiceNumber: string;
	orderNumber: string;
	amountThisInvoice: number;
  job: {
    _id: string;
    name: string;
    customer: {
      _id: string;
      name: string;
    };
    projectManager: {
      _id: string;
      firstName: string;
      lastName: string;
    };
  };
	status: string;
	css: string;

}

export interface IInvoiceModel extends BaseModel {
	_id: string;
	invoiceDate: Date;
	invoiceNumber: string;
	dateOfOrder: Date;
	soldBy: IBackReference | string;
	payType: string;
	orderNumber: string;
	datePromised: string;
	via: string;
	billTo: string;
	deliveryAddress: IAddress;
	deliveryHomePhone: string;
	deliveryWorkPhone: string;
	originalContract: number;
	changeOrdersToDate: number;
	totalContractToDate: number;
	amountPreviouslyInvoiced: number;
	amountThisInvoice: number;
	amountRemaining: number;
	laborDirect: number;
	laborShop: number;
	materials: number;
	outsideServices: number;
	costTax: ITaxes;
	sellTax: ITaxes;
	deliverTo: boolean;
	workLocation: boolean;
	willCall: boolean;
	notes: string;
	notBilled: boolean;
	nonContract: boolean;
	lifecycle: Array<WorkflowStatus>;
	priority: string;
	documents: Object;
	parts: Array<Part.IPurchaseOrderPartModel>;
	part1: Part.IPurchaseOrderPartModel;

	part1Qty: number;
	part1Unit: string;
	part1Description: string;
	part1Cost: number;

	part2Qty: number;
	part2Unit: string;
	part2Description: string;
	part2Cost: number;

	part3Qty: number;
	part3Unit: string;
	part3Description: string;
	part3Cost: number;

	part4Qty: number;
	part4Unit: string;
	part4Description: string;
	part4Cost: number;

	part5Qty: number;
	part5Unit: string;
	part5Description: string;
	part5Cost: number;
	// item3: NonContractItem;
	// item4: NonContractItem;
	// item5: NonContractItem;
}

// <editor-fold> Document models

export class InvoiceModel extends BaseModel implements IInvoiceModel {
	override _id: string = null;
	invoiceDate: Date = null;
	invoiceNumber: string = null;
	dateOfOrder: Date = null;
	soldBy: IBackReference = null;
	payType: string = null;
	orderNumber: string = null;
	datePromised: string = null;
	via: string = null;
	billTo: string;
	deliveryAddress: IAddress = new AddressModel();
	deliveryHomePhone: string = null;
	deliveryWorkPhone: string = null;
	originalContract: number = null;
	changeOrdersToDate: number = null;
	totalContractToDate: number = null;
	amountPreviouslyInvoiced: number = null;
	amountThisInvoice: number = null;
	amountRemaining: number = null;
	laborDirect: number = null;
	laborShop: number = null;
	materials: number = null;
	outsideServices: number = null;
	costTax: ITaxes = {
		stateTax: null,
		countyTax: null,
		mkecountyTax: null,
		stadiumTax: null,
		cityTax: null
	};
	sellTax: ITaxes = {
		stateTax: null,
		countyTax: null,
		mkecountyTax: null,
		stadiumTax: null,
		cityTax: null
	};
	deliverTo: boolean = false;
	workLocation: boolean = false;
	willCall: boolean = false;
	notes: string = null;
	notBilled: boolean = false;
	nonContract: boolean = false;
	lifecycle: [];
	priority: string = 'None';
	documents: Object;
	parts: Array<Part.IPurchaseOrderPartModel> = new Array<Part.IPurchaseOrderPartModel>();
	part1: Part.IPurchaseOrderPartModel;

	part1Qty: number = null;
	part1Unit: string = null;
	part1Description: string = null;
	part1Cost: number = null;

	part2Qty: number = null;
	part2Unit: string = null;
	part2Description: string = null;
	part2Cost: number = null;

	part3Qty: number = null;
	part3Unit: string = null;
	part3Description: string = null;
	part3Cost: number = null;

	part4Qty: number = null;
	part4Unit: string = null;
	part4Description: string = null;
	part4Cost: number = null;

	part5Qty: number = null;
	part5Unit: string = null;
	part5Description: string = null;
	part5Cost: number = null;
	// item3: NonContractItem;
	// item4: NonContractItem;
	// item5: NonContractItem;
}

export function isIInvoiceModel(obj: any): obj is IInvoiceModel {
	return 'invoiceDate' in obj &&
		'invoiceNumber' in obj &&
		'payType' in obj &&
		'orderNumber' in obj;
}

// </editor-fold>
