<div class="list-actions">
    <div class="btn-group list-filters">
        <button type="button" class="btn btn-default" (click)="filterToggle('showClosed')" [ngClass]="{'active btn-primary':showClosed}" style="border-radius:4px;"><i class="glyphicon glyphicon-lock"></i><span *ngIf="showClosed"> Hide</span><span *ngIf="!showClosed"> Show</span> Closed</button>
    </div>
    <button class="btn btn-cta ml-1" (click)="addJob()">Add Job</button>
</div>
<div class="row">
    <div class="col-md-12">
        <table class="table table-select-list" id="job-list" aria-describedby="jobsLabel">
            <thead>
                <tr>
                    <th scope="col">
                        <sortable-header field="name" label="Job Name" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col">
                        <sortable-header field="number" label="Job #" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th scope="col">
                        <sortable-header field="projectManagerName" label="Project Manager" [sortManager]="dataManipulationService"></sortable-header>
                    </th>
                    <th class="text-center" scope="col">White?</th>
                    <th class="text-center" scope="col">Tax Exempt?</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let job of model | async" (click)="editJob(job._id)">
                    <td scope="row">
                        <span [innerHtml]="job.isClosed | booleanIcon:'lock'"></span>
                        {{ job.name }}
                    </td>
                    <td scope="row">{{ job.number }}</td>
                    <td scope="row">{{ job.projectManagerName.name }}</td>
                    <td class="text-center" [innerHtml]="job.isWhite | booleanIcon:'warning-sign'" scope="row"></td>
                    <td class="text-center" [innerHtml]="job.isTaxExempt | booleanIcon:'usd'" scope="row"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
