import { Routes } from '@angular/router';

import { PayableComponent } from '../PayableComponent/payable.component';
import { JobPayableAddComponent } from '../JobComponent/jobPayableAdd.component';
import { JobPayableEditComponent } from '../JobComponent/jobPayableEdit.component';
import { PayableDocumentsComponent } from './payableDocuments.component';
import { MsalGuard } from '@azure/msal-angular';

export const PAYABLE_ROUTES: Routes = [
  { path: 'Add', component: JobPayableAddComponent },
  {
    path: ':payableId',
    canActivate: [MsalGuard],
    component: PayableComponent,
    children: [
      { path: '', redirectTo: 'Edit', pathMatch: 'prefix' },
      { path: 'Edit', component: JobPayableEditComponent },
      { path: 'Documents', component: PayableDocumentsComponent },
    ],
  },
];
