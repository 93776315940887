import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import {ISupplierModel} from '@/models/supplier.models';

@Component({
    selector: 'supplier-documents',
    templateUrl: './supplierDocuments.template.html',
    host: { 'class': 'supplier-documents-component' }
})
export class SupplierDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    public supplierId: string;
    public duplicateRecord: boolean = false;
    public model = new Subject<ISupplierModel>();
    private filesQueued: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private filesService: FilesService,
        messageService: MessageService
    ) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self.supplierId = routerUtils.getRouteParameter(self.route, 'supplierId');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    public saveOrder({order}: { order: any }): void {
        const self = this;
        self.filesService.saveFilesOrder('Supplier', self.supplierId, order);
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }

    public setMessage(e) {
        this.filesQueued = e;
    }
}
