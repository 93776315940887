import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

import { ISupplierModel } from '@/models/supplier.models';
import { SupplierService } from '@/services/supplier.service';
import { UI_MASKS } from '@/utils/uiMasks';

@Component({
  selector: 'add-supplier',
  templateUrl: './addSupplier.template.html',
  host: {'class': 'add-supplier-component'}
})
export class AddSupplierComponent implements OnInit {
  @Input()
  model: ISupplierModel;

  @Input()
  showAlert: boolean = false;

  @Output()
  onSave: EventEmitter<ISupplierModel> = new EventEmitter<ISupplierModel>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  public saved: Subject<any> = new Subject();
  private duplicateSupplier: ISupplierModel;
  private usState: string;
  private usZip: string;
  private canState: string;
  private canZip: string;

  constructor(public uiMasks: UI_MASKS, private supplierService: SupplierService) {}

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		let currentLocation = currentUser.locationId;

    this.model.isActive = true;
    this.model.address.country = 'United States';
    this.model.notes = null;
    this.model.status = 'neutral';
    this.model.type = 'Materials';
    this.model.category = 'other';
    this.model.location = currentLocation;
    this.saved.next('false');
  }

  saveSupplier(newModel: ISupplierModel, supplierForm: NgForm): void {
    delete newModel._id;
    this.supplierService.updateSupplier(newModel)
      .then((data) => {
        this.showAlert = false;
        this.onSave.emit(data);
        this.cancel(supplierForm);
        this.saved.next('true');
        newModel.notes = null;
      })
      .catch((err) => {
        this.showAlert = true;
        let error = err.error;
        this.duplicateSupplier = error.record;
      });
  }

  cancel(supplierForm: NgForm): void {
    this.onCancel.emit(null);
    this.showAlert = false;
    supplierForm.resetForm({isActive: true});
    this.model.notes = null;
    this.saved.next('true');
  }

  toggleActive(): void {
    this.model.isActive = !this.model.isActive;
  }

  // save and restore entered values if switching back and forth between countries
  validate(e): void {
    if (e === 'United States') {
      this.canState = this.model.address.state;
      this.canZip = this.model.address.zip;
      this.model.address.state = this.usState || null;
      this.model.address.zip = this.usZip || null;
    } else {
      this.usState = this.model.address.state;
      this.usZip = this.model.address.zip;
      this.model.address.state = this.canState || null;
      this.model.address.zip = this.canZip || null;
    }
    this.model.address.country = e;
  }
  setSupplierType(type) {
    this.model.type = type;
    this.model.category = 'other';
  }
  setCategory(e) {
    this.model.category=e;
  }

}
