export enum UserRole {
	Admin = 0,
	President = 5,
	ProjectManager = 10,
	ShopForeman = 15,
	VicePresident = 20
}

export class LoginModel {
	username: string = null;
	tokens: Object = null;
}

export interface IAuthUser {
	userId: string;
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	locationId: string;
	locationName: string;
  	role: UserRole;
	jobSource: string;
}

export interface IAuthResult {
	user: IAuthUser;
	expires: string;
}
