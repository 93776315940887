import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { PurchaseOrderPayableFilterFunctions, PurchaseOrderPayableSortFunctions } from '@/utils/dataManipulationUtils';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { IPayableModel } from '@/models/payable.models';
import {PurchaseOrderService, PurchaseOrderPayable} from '@/services/purchaseOrder.service';

@Component({
  selector: 'purchase-order-payable-connection',
  templateUrl: './purchaseOrderPayableTab.template.html',
  host: {'class': 'purchase-order-payable-tab-component'}
})
export class PurchaseOrderPayableTabComponent extends BaseSmartComponent implements OnInit {
  public purchaseOrderId: string;
  public attachedPayable: IPayableModel = null;
  public displayedPayables: Array<PurchaseOrderPayable> = [];
  public payablesTotal: number;
  private _customerId: string;
  private _jobId: string;
  private model: Observable<IPurchaseOrderModel>;
  private filterTerm: string = null;
  private payables: Subject<Array<PurchaseOrderPayable>> = new Subject<Array<PurchaseOrderPayable>>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    private dataManipulationService: DataManipulationService<PurchaseOrderPayable>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActivePurchaseOrder();
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this.purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');
    this.dataManipulationService.initialize(PurchaseOrderPayableFilterFunctions, PurchaseOrderPayableSortFunctions);

    const payables$ = this.purchaseOrderService.getPayables(this._customerId, this._jobId, this.purchaseOrderId);
    this.watchSubscription(this.dataManipulationService.connectDataSource(payables$, this.payables));
    this.watchSubscription(this.payables.subscribe((s) => {
      this.displayedPayables = s;
      this.getPayablesTotal();
    }));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private goToPayable(payable: PurchaseOrderPayable): void {
    this.router.navigate([ 'Customers', payable.customerId, 'Job', payable.jobId, 'Payables', payable._id ]);
  }

  public getPayablesTotal(){
    this.payablesTotal = 0;
    this.displayedPayables.forEach(payable => {
      this.payablesTotal = this.payablesTotal + payable.amount;
    });
  }
}
