import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseSmartComponent } from './../base.component';
import { Subject } from 'rxjs';
import { MessageService } from './../../services/message.service';
import { DataManipulationService } from './../../services/dataManipulation.service';
import { IReportJournalTimeEntryResult, IReportJob } from './../../models/report.models';

@Component({
    selector: 'time-entry-list',
    templateUrl: './timeEntryList.template.html',
    host: {'class': 'time-entry-list-component'}
})
export class TimeEntryListComponent extends BaseSmartComponent implements OnInit {
    public entryCount: number = 0;
    public hoursTotal: number = 0;
    public costTotal: number = 0;

    @Input()
    model = new Subject<Array<IReportJournalTimeEntryResult>>();

    @Input()
    dataManipulationService: DataManipulationService<IReportJournalTimeEntryResult>;

    constructor(private router: Router, private route: ActivatedRoute, messageService: MessageService) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;

        self.watchSubscription(self.model.subscribe((s) => {
            self.entryCount = s.length;
            self.hoursTotal = 0;
            self.costTotal = 0;

            for (let i = 0; i < s.length; i += 1) {
                self.hoursTotal += s[i].units;
                self.costTotal += (s[i].units * s[i].rate);
            }
        }));
    }

    private editJob(job: IReportJob): void {
        this.router.navigate(['Customers', job.customerId, 'Job', job._id, 'Edit'], { relativeTo: this.route.root });
    }

    private editWorkOrder(timeEntry: IReportJournalTimeEntryResult): void {
        this.router.navigate(['Customers', timeEntry.job.customerId, 'Job', timeEntry.job._id, 'WorkOrders', timeEntry.workOrder._id], { relativeTo: this.route.root });
    }
}
