<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title">
    Job Summary Report
</div>

<style>
    tr.caption td {
        padding: 1em 8px 8px 0 !important;
        /* padding-top: 1em !important; */
        font-weight: bold;
        border-bottom: 1px solid #b3b3b3;
    }

</style>

<div class="row report-controls print-hide">
    <div class="col-xs-12 col-lg-5">
        <div class="job-selector form-group">
            <label>Job number or job name</label>
            <search #jobSearch [(ngModel)]="_jobNumber" (onAction)="searchJobs()" placeholder="Select a job" keyboardAccelerator="9" (onKeyboardAction)="searchJobs()" [setFocus]="true"></search>

            <div class="job-info">
                <div *ngIf="!_job">
                    No job selected
                </div>

                <div *ngIf="_job">
                    {{ _job.number }} - {{ _job.name }}
                </div>
            </div>

            <div class="job-results" [hidden]="!_jobResults" #jobResultList tabIndex="-1">
                <ng-template [ngIf]="_jobResults?.length < 21">
                    <div class="job-results-item" *ngFor="let jobResult of _jobResults">
                        <div class="job-result">{{ jobResult.number }} - {{ jobResult.name }}</div>
                        <button class="btn btn-default" (click)="selectJob(jobResult)">Select</button>
                    </div>
                </ng-template>

                <div class="job-results-item" *ngIf="_jobResults?.length === 0">
                    No jobs found; please try a different search
                </div>

                <div class="job-results-item" *ngIf="_jobResults?.length > 20">
                    Too many jobs found; please refine your search and try again
                </div>
            </div>

        </div>

    </div>
    <div class="col-xs-12 col-md-6 col-lg-2">
        <div class="form-group">
            <div class="date-selector">
                <label>Start Date</label>
                <input autocomplete="off" class="form-control" name="startDate" [ngModel]="startDate | dateFormat" (ngModelChange)="startDate = sanitizeDate($event); markDirty()" type="date" />
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-2">
        <div class="form-group">
            <div class="date-selector">
                <label>End Date</label>
                <input autocomplete="off" class="form-control" name="endDate" [ngModel]="endDate | dateFormat" (ngModelChange)="endDate = sanitizeDate($event); markDirty()" type="date" />
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-lg-3 actions mt-2 form-group">
        <button class="btn btn-primary mb-0" (click)="runReport()" [disabled]="!dirty || !_job">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <div *ngIf="model" class="titleArea" [@fadeInOut]>
        <span class="title">{{ model?.name }} - {{ model?.number }}</span><br>
        <span class="title">{{ startDate | dateFormatUS }} to {{ endDate | dateFormatUS }}</span><br><br>
    </div>

    <table class="table" *ngIf="model" [@fadeInOut]>
        <caption>
            Inventory Purchases
        </caption>
        <thead>
            <tr>
                <th scope="col">
                    P.O.
                </th>
                <th scope="col" colspan="2">
                    Vendor
                </th>
                <th scope="col" class="currency-display">
                    Invoice Amount
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let inventoryPurchase of model.inventoryPurchases">
                <td scope="row">
                    {{ inventoryPurchase.poNumber }}
                </td>
                <td scope="row" colspan="2">
                    <span *ngIf="inventoryPurchase.supplier">{{ inventoryPurchase.supplier.name }}</span>
                    <span *ngIf="!inventoryPurchase.supplier && inventoryPurchase.poSupplierName">{{ inventoryPurchase.poSupplierName }}</span>
                </td>
                <td scope="row" class="currency-display">
                    {{ inventoryPurchase.cost | currencyFormat }}
                </td>
            </tr>
        </tbody>
        <tbody class="noBorder">
            <tr>
                <td scope="row" colspan="2"></td>
                <td scope="row" class="underline">
                    <strong>Total Inventory Purchases</strong>
                </td>
                <td scope="row" class="currency-display underline total">
                    <strong>{{ model.totalInventoryPurchases | currencyFormat }}</strong>
                </td>
            </tr>
        </tbody>
    </table>

    <hr />

    <table class="table table-select-list" *ngIf="model" [@fadeInOut]>
        <caption>
            OSS Purchases
        </caption>
        <thead>
            <tr>
                <th scope="col">
                    P.O.
                </th>
                <th scope="col" colspan="2">
                    Vendor
                </th>
                <th scope="col" class="currency-display">
                    Invoice Amount
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ossPurchase of model.ossPurchases">
                <td scope="row">
                    {{ ossPurchase.poNumber }}
                </td>
                <td scope="row" colspan="2">
                    <span *ngIf="ossPurchase.supplier">{{ ossPurchase.supplier.name }}</span>
                    <span *ngIf="!ossPurchase.supplier && ossPurchase.poSupplierName">{{ ossPurchase.poSupplierName }}</span>
                </td>
                <td scope="row" class="currency-display">
                    {{ ossPurchase.other | currencyFormat }}
                </td>
            </tr>
        </tbody>
        <tbody class="noBorder">
            <tr>
                <td scope="row" colspan="2"></td>
                <td scope="row" class="underline">
                    <strong>Total OSS Purchases</strong>
                </td>
                <td scope="row" class="currency-display underline total">
                    <strong>{{ model.totalOssPurchases | currencyFormat }}</strong>
                </td>
            </tr>
        </tbody>
    </table>

    <hr />

    <table class="table total" *ngIf="model" [@fadeInOut]>
        <caption class="sr-only">Job totals</caption>
        <thead class="spacer">
            <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody class="noBorder">
            <tr>
                <td scope="row" colspan="2"></td>
                <td scope="row" class="currency-display">
                    Labor Hours
                </td>
                <td scope="row"></td>
            </tr>
            <tr>
                <td scope="row"></td>
                <td scope="row" class="underline">
                    <strong>Glazier Labor</strong>
                </td>
                <td scope="row" class="currency-display underline">
                    {{ model.glazierLabor }} <i>(@{{ model.glazierLaborRate | currencyFormat }})</i>
                </td>
                <td scope="row" class="currency-display underline">
                    {{ model.glazierLaborCosts | currencyFormat }}
                </td>
            </tr>
            <tr>
                <td scope="row"></td>
                <td scope="row" class="underline">
                    <strong>Shop Labor</strong>
                </td>
                <td scope="row" class="currency-display underline">
                    {{ model.shopLabor }} <i>(@{{ model.shopLaborRate | currencyFormat }})</i>
                </td>
                <td scope="row" class="currency-display underline">
                    {{ model.shopLaborCosts | currencyFormat }}
                </td>
            </tr>
            <tr>
                <td scope="row"></td>
                <td scope="row" class="underline">
                    <strong>Total Tax</strong>
                </td>
                <td scope="row" class="currency-display underline">

                </td>
                <td scope="row" class="currency-display underline">
                    {{ model.totalInventoryTax | currencyFormat }}
                </td>
            </tr>
            <tr>
                <td scope="row"></td>
                <td scope="row" class="underline">
                    <strong>Total Costs</strong>
                </td>
                <td scope="row" class="currency-display underline">

                </td>
                <td scope="row" class="currency-display underline">
                    {{ model.totalCosts | currencyFormat }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
