import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import * as routerUtils from '@/utils/routerUtils';
import { PayableSummaryFilterFunctions, PayableSummarySortFunctions } from '@/utils/dataManipulationUtils';
import { IPayableSummary, PayablesSearchResult } from '@/models/payable.models';
import { PayableWorkflow, Workflow, WorkflowState, WorkflowStatus } from '@/models/workflow.models';
import * as Utility from '@/models/utility.models';
import {IAuthUser, UserRole} from '@/models/auth.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { JobService } from '@/services/job.service';
import { WorkflowService } from '@/services/workflow.service';
import { MessageService } from '@/services/message.service';
import { AuthService } from '@/services/auth.service';
import { ListHeaderService } from '@/services/listHeader.service';
import { LocalStorageService } from '@/services/localStorage.service';
import { FilterValue, parseSearchFilterHistory, SortOptions } from '@/models/filter.models';
import { RouterHistoryService } from '@/services/router-history.service';
import { handleError } from '@/utils/errors';

@Component({
	selector: 'payable-search',
	templateUrl: './payableSearch.template.html',
	host: {'class': 'payable-search-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0
			})),
			  transition('void <=> *', animate(300)),
		  ]),
	]
})
export class PayableSearchComponent extends BaseSmartComponent implements OnInit {
	@ViewChild('payableSearch', {static: true})
	payableSearch: Utility.IFocusable;

	@ViewChild('batchStatus', {static: true})
	batchStatus: ElementRef;
	public searchTerm: string = '';
	public showClosed: boolean = false;
	public payableSet = [];
	public sortDirection: number = 1;
	public currentSort: string = 'invoiceNumber';
	public isLoading: boolean = true;
	public statusOptions: Workflow = new PayableWorkflow();
	public statusList: any []; // this one is for populating the list of statuses without 'unassigned'
	public filterOptions: Array<Object>;
	state$: Observable<object>;
	public selectAll: boolean = false;
	public selectedPayables: any = [];
	public updatedStatus: string = '';
	public user: IAuthUser;
	public userSub: Subscription;
	public filters: FilterValue[] = [];
	public showTotal: boolean = false;
	public payablesTotal: number = null;

	private _currentUser: any;
	private model: Subject<Array<IPayableSummary>> = new Subject<Array<IPayableSummary>>();
	private lastSearchTerm: string = '';
	private pageNumber: number = 0;
	private sortField: string = 'invoiceNumber';
	private scrollPosition: number = 0;
	private sortFields: string[] = [
		'invoiceNumber',
		'job.name',
		'supplier.name',
		'status',
		'invoiceDate',
		'invoiceDueDate',
		'invoicePostedDate',
		'amount'
	];
  private locationSub: Subscription;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		private jobService: JobService,
		private dataManipulationService: DataManipulationService<IPayableSummary>,
		messageService: MessageService,
		protected workFlowService: WorkflowService,
		private authService: AuthService,
		private history: RouterHistoryService,
		private listHeaderService: ListHeaderService,
		private localStorageService: LocalStorageService
	) {
		super(messageService);
	}

	ngOnInit(): void {
	this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
	this.history.currentState$.pipe(take(1))
    .subscribe(s => {
        if (s) {
          const result = parseSearchFilterHistory(s);
          this.filters = result.filters;	
          if (result.searchTerm) {
            this.searchTerm = result.searchTerm;
          }

          if (result.showClosed !== undefined) {
            this.showClosed = result.showClosed;
          }
          this.initializeSort(result.sort);
        }
        this.reloadList(this.filters);
      });

    // don't include 'Unassigned' or 'Rejected' as options
    this.statusList = this.statusOptions.states.filter(function( obj ) {
			return obj.value !== null && obj.value !== 'rejected' && obj.value !== 'on_hold';
		});
    // get user's role
    this.userSub = this.authService.currentUser.subscribe(s => {
		if (s) {
			this.user = s;
			// don't include 'posted', 'sent to accounting', 'printed' or 'void' as options for PMs
			if (this.user.role != UserRole.Admin) {
				this.statusList = this.statusList.filter(function( obj ) {
					return obj.value !== 'posted' && obj.value !== 'sent_to_accounting' && obj.value !== 'printed' && obj.value !== 'void';
				});
			}
		}
	});

  this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
    this.payableSet = [];
    this.reloadList(this.filters);
  });

  this.dataManipulationService.initialize(PayableSummaryFilterFunctions, PayableSummarySortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getPayableSummaryList(), this.model));

    this.dataManipulationService.setFilter('text', '');
    if (this.payableSearch) {
      this.payableSearch.focus();
		}
    this.setFilterOptions();
	}

	override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
		this.userSub.unsubscribe();
		super.ngOnDestroy();
	}

	public search(): void {
		if (this.searchTerm === '') {
      this.searchTerm = null;
		}

		if (this.searchTerm !== this.lastSearchTerm) {
      this.resetList();
      this.reloadList(this.filters);
      this.lastSearchTerm = this.searchTerm;
		}
	}

	public sortList(e): void {
	  this.sortField = e.field;
	  this.sortDirection = e.sortDirection;
	  this.currentSort = e.currentSort;
	  this.resetList();
	  this.reloadList(this.filters);
	}

	public filterToggle(value: string): void {
		if (value === 'showClosed') {
		  this.showClosed = !this.showClosed;
		}
		this.resetList();
		this.reloadList(this.filters);
	}

	statusClass(workflowState: WorkflowState): string {
		if (!workflowState) {
			return 'black';
		}
		return this.workFlowService.getStatusClass(workflowState);
	}

	// select/deselect all payables
	public toggleSelectAll(): void {
		this.selectAll = ! this.selectAll;
		this.payableSet.forEach(payable => {
			payable.selected = this.selectAll;
			this.updateSelected(payable);
		});
	}

	public updateSelected(payable): void {
		const indexPosition = this.selectedPayables.indexOf(payable);

		if (indexPosition === -1) {
			this.selectedPayables.push(payable);
		} else if (indexPosition > -1) {
			this.selectedPayables.splice(indexPosition, 1);
		}
	}

	// update all of the selected payables to the status selected
	public updateStatus(): void {
		if (confirm('Are you sure you want to update all selected payables?')) {
			let items = this.selectedPayables.map(payable => {
				payable.selected = false;
				return payable._id;
			});
			this.selectAll = false;

			// clear selected payables
			this.workFlowService.updateWorkflowBatch('payables', { items, status: this.updatedStatus })
				.subscribe(res => {
					if (res === items.length) {
						this.sendMessage({ title: `Successfully updated ${items.length} payable statuses`, type: 'success', options: {}, message: '' });
					} else {
						this.sendMessage({ title: `Updated ${res} of ${items.length} payable statuses`, type: 'error', options: {}, message: '' });
					}
					this.resetList();
					this.reloadList(this.filters);
				});
		}
	}

	public editEntity(payable: IPayableSummary): void {
		let customerId = payable.job.customer._id;
		let jobId = payable.job._id;

		this.router.navigate(
		  ['/Customers', customerId.toString(), 'Job', jobId.toString(), 'Payables', payable._id, 'Edit'],
    { relativeTo: routerUtils.getParentRoute(this.route) });
	}

  public onScroll(e): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadList(this.filters);
      }
    }
  }

  public filterTheList(filters: FilterValue[]): void {
    this.isLoading = true;
    this.filters = filters;
    this.resetList();
    this.reloadList(this.filters);
  }

  protected initializeSort(sort: SortOptions): any {
    if (sort) {
      this.sortDirection = sort.direction;
      if (this.sortFields.includes(sort.field)) {
        this.sortField = sort.field;
      }
    }
    this.currentSort = this.sortField;
  }

	private reloadList(filters: FilterValue[]): void {
		const extendedFilters: any[] = [...filters];
		extendedFilters.push({ filter: 'includeClosedJobsFilter', id: this.showClosed });
		// pre-set pm filter to return correct results
		if(this._currentUser?.role === 10){
			extendedFilters.push({ filter: 'projectManagerFilter', id: this._currentUser?.userId });
		}
		this.history.changeState([
		  { filter: 'searchTerm', id: this.searchTerm },
		  { filter: 'sort', id: this.sortField, direction: this.sortDirection },
      ...extendedFilters
    ]);
		this.jobService.searchPayables(30, this.searchTerm, this.sortField, this.sortDirection, this.pageNumber, extendedFilters)
			.then((result: PayablesSearchResult) => {
				result.payables.forEach(invoice => {
					// @ts-ignore
					const workflowState = this.statusState(invoice.status);
					invoice.css = this.statusClass(workflowState);
					invoice.status = (workflowState) ? workflowState.label : 'Unassigned';
					this.payableSet.push(invoice);
				});
				filters.forEach(filter => {
					if (filter.filter === 'dateFilter' && filter.id) {
						this.showTotal = true;
						this.payablesTotal = result.total;
					} else {
						this.showTotal = false;
						this.payablesTotal = null;
					}
				});
				setTimeout(() => {
					this.listHeaderService.setListTop();
					this.isLoading = false;
				},250);
			})
      .catch(handleError);
	}

	private resetList(): void {
	  this.scrollPosition = 0;
	  this.pageNumber = 0;
	  this.payableSet = [];
	  this.selectedPayables = [];
	  this.selectAll = false;
	  this.isLoading = true;
	}

	private setFilterOptions(): void {
	  this.filterOptions = [
	    {
	      type: 'projectManager',
        label: 'Assigned To',
				options: null,
				preFilter: ''
			},
			{
			  type: 'status',
        label: 'Status',
        options: null,
				preFilter: ''
			},
			{
        type: 'date',
        label: 'Date Posted',
        options: null,
				preFilter: ''
			}];
    }

	private statusState(status: WorkflowStatus): WorkflowState {
		if (!status) {
			return undefined;
		}
		return this.statusOptions.states.find((descr: WorkflowState) => status.status === descr.value);
	}
}
