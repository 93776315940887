import { BaseModel } from './utility.models';
import { IAddress, AddressModel } from './address.models';
import { IStaffModel } from './staff.models';

export interface ITaxRates {
  salesTax: Array<ITaxRate>;
  useTax: Array<ITaxRate>;
}

export interface ITaxRate {
  name: string;
  rate: number;
}

export interface ILocationModel extends BaseModel {
  name: string;
  main: string;
  fax: string;
  address: IAddress;
  staff: Array<IStaffModel>;
  taxRates: Array<ITaxRate>;
  useTaxRates: Array<ITaxRate>;
}

// <editor-fold> Document models

export class LocationModel extends BaseModel implements ILocationModel {
    name: string = null;
    main: string = null;
    fax: string = null;
    address: IAddress = new AddressModel();
    staff: Array<IStaffModel> = new Array<IStaffModel>();
    taxRates: Array<ITaxRate> = new Array<ITaxRate>();
    useTaxRates: Array<ITaxRate> = new Array<ITaxRate>();
}

// </editor-fold>
