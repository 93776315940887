<div class="screen-title">Edit change order</div>

<change-order-edit [model]="model$ | async"
                   (onSave)="saveChangeOrder($event)"
                   (onCancel)="cancel()"
                   (onDelete)="deleteChangeOrder($event)">
</change-order-edit>

<hr>

<div class="row">
	<div class="col-md-8 col-md-offset-2">
		<job-change-order-summary></job-change-order-summary>
	</div>
</div>
