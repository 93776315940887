
<invoice-edit [model]="model | async" 
    [isTaxExempt]="_isTaxExempt" 
    [users]="users | async | active" 
    [autofill]="_autofill" 
    [salesTaxRates]="_taxRates" 
    [useTaxRates]="_useTaxRates" 
    (onSave)="saveInvoice($event)" 
    (onAutofillGet)="retrieveAutofillValues($event)" 
    (formStatus)="setFormStatus($event)" 
    [isNew]="false" 
    [uuid]="_invoiceId"
></invoice-edit>
