import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { IInvoiceModel, IInvoiceAutofillResponse, IInvoiceAutoCalculateTax } from '@/models/invoice.models';
import { UserService } from '@/services/user.service';
import { IUserModel } from '@/models/user.models';
import { LocationService } from '@/services/location.service';
import { PrintService } from '@/services/print.service';
import { handleError } from '@/utils/errors';

@Component({
	selector: 'job-invoice-edit',
	templateUrl: './jobInvoiceEdit.template.html',
	host: {'class': 'job-invoice-edit-component'}
})
export class JobInvoiceEditComponent extends PrintableComponent implements OnInit {
	// todo refactor or rename so public properties aren't prefixed with _
	public _invoiceId: string;
	public _autofill: IInvoiceAutofillResponse = {} as IInvoiceAutofillResponse;
	public _isTaxExempt: boolean = false;
	public _taxRates: IInvoiceAutoCalculateTax = {};
	public _useTaxRates: IInvoiceAutoCalculateTax = {};
	public model: Observable<IInvoiceModel>;
	public users: Observable<Array<IUserModel>>;
	public formChanged: boolean = false;

	private projectManager: any;
  private _customerId: string;
  private _jobId: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		messageService: MessageService,
		private jobService: JobService,
		private userService: UserService,
		private locationService: LocationService,
		private printService: PrintService
	) {
		super(router, printService, messageService);
	}

	ngOnInit(): void {
    this.model = this.state.getActiveInvoice();

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    let editRoute = routerUtils.getRouteParameterObservable(this.route, 'invoiceId');
    this.watchSubscription(editRoute.subscribe((s) => {
      this._invoiceId = s['invoiceId'];
      this.jobService.getInvoice(this._customerId, this._jobId, this._invoiceId)
				.then(invoice => this.retrieveAutofillValues(new Date(invoice.invoiceDate)))
				.catch(handleError);
      this.printUrlBase = '/jobs/' + this._jobId + '/invoices/' + this._invoiceId;
		}));

	this.users = this.state.getUsersList();

    this.watchSubscription(this.state.getActiveJob().subscribe((s) => {
      this._isTaxExempt = s.isTaxExempt;
		}));

    this.watchSubscription(this.state.getActiveCustomer().subscribe((s) => {
			if (!s.location) { return; }

      this.locationService.getTaxRates(s.location as string)
				.then((taxRates) => {
					var processTaxRates = function(taxRates, rateField){
						for (let taxRate of taxRates) {
							let taxRateName = taxRate.name.toLowerCase() + 'Tax';

							rateField[taxRateName] = taxRate.rate !== null ? taxRate.rate * 100 : null;
						}
					}

					processTaxRates(taxRates.salesTax, this._taxRates);
					processTaxRates(taxRates.useTax, this._useTaxRates);
				})
				.catch(handleError);
		}));

    this.printOptions.copies = [
      { index: 'projectManager', label: 'Control', enabled: false },
      { index: 'accounting', label: 'Accounting', enabled: false },
      { index: 'customer', label: 'Customer', enabled: false },
      { index: 'orderAndFile', label: 'Order and File', enabled: false },
      { index: 'woField', label: 'WO Field', enabled: false },
      { index: 'woTaggingAndDelivery', label: 'WO Tagging and Delivery', enabled: false }
    ];
	}

	public saveInvoice(newModel: IInvoiceModel): void {
    // remove the docs for the save and then add them back in after so view is updated
    let docs = newModel.documents;
    delete newModel.documents;
    // copy over static autofill values and purely calculated fields
    newModel.originalContract = this._autofill.originalContract;
    newModel.totalContractToDate = newModel.originalContract + newModel.changeOrdersToDate;
    newModel.amountPreviouslyInvoiced = this._autofill.amountPreviouslyInvoiced;
    newModel.amountRemaining = newModel.totalContractToDate - newModel.amountPreviouslyInvoiced - newModel.amountThisInvoice;

    this.jobService.updateInvoice(this._customerId, this._jobId, newModel)
			.then((result) => {
        this.jobService.getInvoices(this._customerId, this._jobId);
        setTimeout(() => {
          if (docs) {
            newModel.documents = docs;
          }
        }, 0);
			})
			.catch(handleError);
	}

	public retrieveAutofillValues(date: Date): void {
    this.jobService.getInvoiceAutofillValues(this._customerId, this._jobId, this._invoiceId, date)
			.then(result => this._autofill = result)
			.catch(handleError);
	}

	public setFormStatus(e: boolean): void {
		this.formChanged = e;
	}
}
