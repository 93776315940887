<div id="contact-list">
    <table class="table table-select-list" aria-describedby="contactsLabel">
        <thead>
            <tr>
                <th scope="col">
                    <sortable-header field="firstName" label="First Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="lastName" label="Last Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="title" label="Title" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="email" label="Email" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col">
                    Phone
                </th>
                <th scope="col">
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let contact of model | async">
                    <td scope="row" (click)="selectContact(contact)">
                        {{ contact.firstName }}
                    </td>
                    <td scope="row" (click)="selectContact(contact)">
                        {{ contact.lastName }}
                    </td>
                    <td scope="row" (click)="selectContact(contact)">
                        {{ contact.title }}
                    </td>
                    <td scope="row" (click)="selectContact(contact)">
                        <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
                    </td>
                    <td scope="row" (click)="selectContact(contact)">
                        {{ contact.phone | phoneFormat }}
                    </td>
                    <td class="list-item-actions" scope="row">
                        <div *ngIf="contact.isActive && !permissionLock" class="action-link delete mr-1 inline-block" (click)="toggleContactStatus(contact, $event)">Deactivate</div>
                        <div *ngIf="!contact.isActive && !permissionLock" class="action-link mr-1 inline-block" (click)="toggleContactStatus(contact, $event)">Activate</div>
                        <button type="button" class="btn btn-danger" (click)="deleteContact(contact, $event)"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
