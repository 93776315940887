import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { BaseSmartComponent } from './../base.component';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';

import { IJobModel, JobModel } from '@/models/job.models';
@Component({
    selector: 'job-warranty',
    templateUrl: './jobWarranty.template.html',
    host: {'class': 'job-warranty-component'}
})

export class JobWarrantyComponent extends BaseSmartComponent implements OnInit {
    private _id : string;
    private _customerId : string;

    public model : IJobModel = new JobModel();
    private _substantialCompletionDate : moment.Moment;
    // todo refactor or rename so private property isn't prefixed with an _
    public _years = {
        installerDate: null,
        metalDate: null,
        metalFinishDate: null,
        glassIGUDate: null,
        glassLaminatedDate: null,
        glassSpandrelDate: null,
        glassMonolithicDate: null
    };
    public saved : Subject<any> = new Subject();
    
    constructor(private router: Router, private route: ActivatedRoute, private state : StateManager, messageService : MessageService, private jobService : JobService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.watchSubscription(self.state.getActiveJob().subscribe((s) => {
            self.model = Object.assign(new JobModel(), s);

            if(self.model.warranty.substantialCompletionDate) {
                self._substantialCompletionDate = moment(self.model.warranty.substantialCompletionDate);

                for(let year in self._years) {
                    self.recalculateYearsFromBase(year);
                }
            }
        }));

        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._id = routerUtils.getRouteParameter(self.route, 'jobId');
		this.saved.next('false');
    }

    public saveJob() : void {
        this.jobService.updateJob(this._customerId, this.model);
		this.saved.next('true');
    }

    public updateSubstantialCompletionDate() : void {
        this._substantialCompletionDate = moment(this.model.warranty.substantialCompletionDate);

        // repeat for all attached fields
        for(let year in this._years) {
            this.recalculateDateFromBase(year);
        }
    }

    public recalculateDateFromBase(destinationField : string) : void {
        let newDate = moment(this._substantialCompletionDate)
            .add(this._years[destinationField], 'years')
            .toDate();

        this.model.warranty[destinationField] = this.sanitizeDate(newDate);
    }

    public recalculateYearsFromBase(destinationField : string) : void {
        let newDate = moment(this.model.warranty[destinationField]);

        if(!newDate.isValid()) {
            this._years[destinationField] = null;
        }
        else {
            this._years[destinationField] = Number((newDate.diff(this._substantialCompletionDate, 'years', true)).toFixed(2));
        }
    }
};
