import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { PrintService } from '@/services/print.service';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order',
  templateUrl: './workOrder.template.html',
  host: {'class': 'work-order-component'}
})
export class WorkOrderComponent extends PrintableComponent implements OnInit, OnDestroy {
  private _customerId: string;
  private _jobId: string;
  private _workOrderId: string;
  private model: IWorkOrderModel = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private workOrderService: WorkOrderService,
    private filesService: FilesService,
    printService: PrintService
  ) {
    super(router, printService, messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    let editRoute = routerUtils.getRouteParameterObservable(this.route, 'workOrderId');
    if (editRoute) {
      this.watchSubscription(editRoute.subscribe((s) => {
        if (s['workOrderId']) {
          this._workOrderId = s['workOrderId'];
          this.workOrderService.getWorkOrder(this._customerId, this._jobId, this._workOrderId)
            .then(workOrder => {
              this.model = workOrder;
            })
            .catch(handleError);
          this.printUrlBase = '/workOrders/' + this._workOrderId;
        } else {
          // todo reroute to somewhere else
        }
      }));
    }

    this.printOptions.copies = [
      { index: 'job', label: 'Job', enabled: false },
      { index: 'shop', label: 'Shop', enabled: false },
      { index: 'field', label: 'Field', enabled: false },
      { index: 'control', label: 'Control', enabled: false }
    ];
  }

  override ngOnDestroy(): void {
    this.state.setActiveWorkOrder(null);
    super.ngOnDestroy();
  }
}
