<nav>
	<ul class="nav nav-tabs">
		<li><a [routerLink]="['Details']" routerLinkActive="active">Details</a></li>
		<li><a [routerLink]="['Contacts']" routerLinkActive="active" id="contactsLabel">Contacts</a></li>
		<li><a [routerLink]="['Documents']" routerLinkActive="active" id="documentsLabel">Documents</a></li>
	</ul>
</nav>
<div id="content">
	<router-outlet></router-outlet>
</div>
