<div class="screen-title">
    Add Payable Invoice
</div>

<payable-edit [model]="model | async"
    (onSave)="savePayable($event)"
    [showAlert]="showAlert"
    (onGoToPayable)="goToPayable()"
    (onCancel)="cancel()"
    [isNew]="true"
    [suppliers]="suppliers | async" 
    [suppliersCopy]="suppliersCopy" 
    [uuid]="uuid"
></payable-edit>

