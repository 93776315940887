<header>
    <div class="row mb-2">
        <div class="col-xs-12 text-right">
            Remaining to Bill: {{ amountRemaining | currencyFormat }}
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-8">
            <search [(ngModel)]="filterTerm" placeholder="Filter receivables" filterData="text" [filterManager]="dataManager"></search>
        </div>
        <div class="col-xs-12 col-md-4">
            <button type="button" class="btn btn-block btn-default" (click)="addClicked.emit()">Add receivable</button>
        </div>
    </div>
</header>
<!-- pre-loading image here so it displays quickly when printing an invoice -->
<img src="../../../assets/img/kd_logo_print.jpg" alt="Pre-loaded print logo" style="display: none;"/>

<invoice-list [model]="model | async" [showIndicator]="true" (onInvoiceSelect)="itemSelected.emit($event)" [selectedInvoiceId]="selectedInvoiceId"></invoice-list>
