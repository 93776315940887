import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { ITimeEntryModel, TimeEntryModel } from '@/models/timeEntry.models';

export interface ITimeEntryState {
    timeEntries: Array<ITimeEntryModel>,
    activeTimeEntry: ITimeEntryModel
}

const defaultState: ITimeEntryState = {
    timeEntries: new Array<ITimeEntryModel>(),
    activeTimeEntry: new TimeEntryModel()
};

export const TIMEENTRY_ACTIONS = {
    SET_TIMEENTRIES_LIST: 'SET_TIMEENTRIES_LIST',
    SET_ACTIVE_TIMEENTRY: 'SET_ACTIVE_TIMEENTRY'
};

const timeEntryReducer = function(state: ITimeEntryState = defaultState, action: Action) {
    let newState = Object.assign({}, state);

    switch(action.type) {
        case TIMEENTRY_ACTIONS.SET_TIMEENTRIES_LIST:
            newState.timeEntries = action.payload;
            break;
        case TIMEENTRY_ACTIONS.SET_ACTIVE_TIMEENTRY:
            newState.activeTimeEntry = action.payload;
            break;
    }

    return newState;
};

export function getTimeEntriesList() {
    return (state : Store<ITimeEntryState>) => {
        return state.select(s => {
            return s.timeEntries;
        });
    };
}

export function getActiveTimeEntry() {
    return (state : Store<ITimeEntryState>) => {
        return state.select(s => {
            return s.activeTimeEntry;
        });
    };
}

export function reducer(state: ITimeEntryState | undefined, action: Action) {
	return timeEntryReducer(state, action);
}
