import 'reflect-metadata';
import 'zone.js';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import './sentry';
import { AppModule } from './app/app.module';

if (environment.SENTRY_ENVIRONMENT === 'production' || environment.SENTRY_ENVIRONMENT === 'staging') {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.error(err));
