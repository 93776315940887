import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { EnvironmentService } from '@/services/EnvironmentService';

@Injectable()
export class FilesService extends ApiBaseService {
	constructor(private _http: HttpClient, private state: StateManager, messageService: MessageService, private env: EnvironmentService) {
		super(messageService);
		this.baseUrl = 'files';
	}

	// todo create a type for this response
	getFiles(type: string, typeId: string): Promise<{ type: string, typeId: string }> {
		let request = {
			type: type,
			typeId: typeId
		};

		let result = this._http.post<{ type: string, typeId: string }>(this.baseUrl, request).pipe(share());
		return this.extract(result);
	}

	deleteFile(type: string, typeId: string, name: string): Promise<any> {
		let request = {
			type: type,
			typeId: typeId,
			name: name
		};

		// todo why is this not a delete?
		let result = this._http.post(this.baseUrl + '/delete', request).pipe(share());
		return this.extract(result);
	}

	// todo can't test because save file order isn't working
	saveFilesOrder(type: string, typeId: string, files: string[]): Promise<{ type: string, typeId: string, files: string[] }> {
		let request = {
			type: type,
			typeId: typeId,
			files: files
		};

		let result = this._http.post<{ type: string, typeId: string, files: string[] }>(this.baseUrl + '/order', request).pipe(share());
		return this.extract(result);
	}

  getFileUrl(path: string): string {
    const encodedPath = encodeURIComponent(path);
    return this.env.APIBASEURL + '/files/' + encodedPath;
  }
}
