import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import * as routerUtils from '@/utils/routerUtils';
import { JobSortFunctions, JobFilterFunctions } from '@/utils/dataManipulationUtils';
import * as Utility from '@/models/utility.models';
import { IJobModel } from '@/models/job.models';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { RouterHistoryService } from '@/services/router-history.service';
import { ListHeaderService } from '@/services/listHeader.service';
import { LocalStorageService } from '@/services/localStorage.service';
import { FilterDescription, FilterValue, parseSearchFilterHistory, SortOptions } from '@/models/filter.models';
import { handleError } from '@/utils/errors';
import {Subscription} from 'rxjs';

@Component({
  selector: 'job-list',
  templateUrl: './jobList.template.html',
  host: {'class': 'job-list-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class JobListComponent extends BaseSmartComponent implements OnInit {
  @ViewChild('jobSearch', {static: true})
  jobSearch: Utility.IFocusable;

  @ViewChild('jobFilter', {static: true})
  jobFilter: Utility.IFocusable;

  public showClosed: boolean = false;
  public searchTerm: string;
  public jobSet = [];
  public sortDirection: number = 1;
  public currentSort: string = 'number';
  public isLoading: boolean = true;
  public filterOptions: FilterDescription[];
  public filters: FilterValue[] = [];
  public location: string;

	private _currentUser: any;
  private model: Subject<Array<IJobModel>> = new Subject<Array<IJobModel>>();
  private lastSearchTerm: string;
  private filterTerm: string;
  private pageNumber: number = 0;
  private sortField: string = 'number';
  private scrollPosition: number = 0;
  private sortFields: string[] = ['number', 'name', 'job.customer.name' ];

  private locationSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private dataManipulationService: DataManipulationService<IJobModel>,
    messageService: MessageService,
    private history: RouterHistoryService,
    private listHeaderService: ListHeaderService,
		private localStorageService: LocalStorageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    console.info('navigated');
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.history.currentState$.pipe(take(1))
      .subscribe(s => {
        if (s) {
          const result = parseSearchFilterHistory(s);
          this.filters = result.filters;
          if (result.searchTerm) {
            this.searchTerm = result.searchTerm;
          }

          if (result.showClosed !== undefined) {
            this.showClosed = result.showClosed;
          }

          this.initializeSort(result.sort);
        }
        this.reloadList(this.filters);
      });

    this.dataManipulationService.initialize(JobFilterFunctions, JobSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getJobsList(), this.model));
    this.watchSubscription(this.model.subscribe((s) => {
      if ((s.length === 1) && (this.pageNumber === 0) && (!this.filterTerm || (this.filterTerm === ''))) {
        this.editJob(s[0]);
        this.state.setJobsList(new Array<IJobModel>());   // prevent nav back from immediately returning to edit
      } else if ((s.length > 1) && (this.searchTerm)) {
        if (this.jobFilter) {
          setTimeout(() => { this.jobFilter.focus(); }, 0);
        }
      }
    }));

    this.locationSub = this.localStorageService.watchStorage().subscribe(() => {
      this.jobSet = [];
      this.reloadList(this.filters);
    });

    this.dataManipulationService.setFilter('text', '');

    if (this.jobSearch) {
      this.jobSearch.focus();
    }

    this.setFilterOptions();
  }

  override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
    super.ngOnDestroy();
  }

  public search(): void {
    if (this.searchTerm === '') {
      this.searchTerm = null;
    }

    if (this.searchTerm !== this.lastSearchTerm) {
      this.resetList();
      this.reloadList(this.filters);
      this.lastSearchTerm = this.searchTerm;
    }
  }

  public filterToggle(value: string): void {
    if (value === 'showClosed') {
      this.showClosed = !this.showClosed;
    }
    this.resetList();
    this.reloadList(this.filters);
  }

  public sortList(e): void {
    this.sortField = e.field;
    this.sortDirection = e.sortDirection;
    this.currentSort = e.currentSort;
    this.resetList();
    this.reloadList(this.filters);
  }

  public editJob(job: IJobModel): void {
    let customer = job.customer as Utility.IBackReference;
    this.router.navigate(
      ['/Customers', customer._id.toString(), 'Job', job._id, 'Edit'],
      { relativeTo: routerUtils.getParentRoute(this.route) }
      );
  }

  public onScroll(e): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadList(this.filters);
      }
    }
  }

  public filterTheList(filters: FilterValue[]): void {
    this.isLoading = true;
    this.filters = filters;
    this.resetList();
    this.reloadList(this.filters);
  }

  protected initializeSort(sort: SortOptions): any {
    if (sort) {
      this.sortDirection = sort.direction;
      if (this.sortFields.includes(sort.field)) {
        this.sortField = sort.field;
      }
    }
    this.currentSort = this.sortField;
  }

  private reloadList(filters: FilterValue[]): void {
		// pre-set pm filter to return correct results
		if(this._currentUser?.role === 10){
			filters.push({ filter: 'projectManagerFilter', id: this._currentUser?.userId });
		}
    let historyState = [
      { filter: 'searchTerm', id: this.searchTerm },
      { filter: 'includeClosedJobsFilter', id: this.showClosed },
      { filter: 'sort', id: this.sortField, direction: this.sortDirection },
    ];
    if (!filters[0]) {
      filters = null;
    } else {
      historyState.push(...filters);
    }
    this.history.changeState(historyState);
    this.jobService.getJobs(30, this.showClosed, this.searchTerm, this.sortField, this.sortDirection, this.pageNumber, this.filters)
      .then(jobs => {
        for (let i = 0; i < jobs.length; i++) {
          this.jobSet.push(jobs[i]);
        }
        setTimeout(() => {
          this.listHeaderService.setListTop();
          this.isLoading = false;
        },250);
      })
      .catch(handleError);
  }

  private resetList(): void {
    this.scrollPosition = 0;
    this.pageNumber = 0;
    this.jobSet = [];
  }

  private setFilterOptions(): void {
    this.filterOptions = [
      {
        type: 'projectManager',
        label: 'Project Manager',
        options: null,
    }];
  }
}
