import { Routes } from '@angular/router';

import { SupplierComponent } from './../SupplierComponent/supplier.component';
import { SupplierListComponent } from './../SupplierComponent/supplierList.component';
import { SupplierAddComponent } from './../SupplierComponent/supplierAdd.component';
import { SupplierEditComponent } from './../SupplierComponent/supplierEdit.component';
import { SupplierContactListComponent } from './../SupplierComponent/supplierContactList.component';
import { SupplierContactAddComponent } from './../SupplierComponent/supplierContactAdd.component';
import { SupplierContactEditComponent } from './../SupplierComponent/supplierContactEdit.component';
import { MsalGuard } from '@azure/msal-angular';
import {SupplierSummaryComponent} from '@/components/SupplierComponent/supplierSummary.component';
import {SupplierEditTabsComponent} from '@/components/SupplierComponent/supplierEditTabs.component';
import {SupplierDocumentsComponent} from '@/components/SupplierComponent/supplierDocuments.component';

export const SUPPLIER_ROUTES: Routes = [
  {
    path: 'Suppliers',
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        component: SupplierListComponent,
      },
      {
        path: 'Add',
        component: SupplierAddComponent,
      },
      {
        path: ':supplierId',
        children: [
          {
            path: 'Edit',
            component: SupplierComponent,
            children: [
              {
                path: '',
                component: SupplierSummaryComponent,
                outlet: 'sidebar',
                pathMatch: 'prefix',
              },
              {
                path: '',
                component: SupplierEditTabsComponent,
                children: [
                  { path: 'Details', component: SupplierEditComponent },
                  {
                    path: 'Contacts',
                    children: [
                      { path: '', component: SupplierContactListComponent },
                      { path: 'Add', component: SupplierContactAddComponent },
                      {
                        path: ':contactId',
                        component: SupplierContactEditComponent,
                      },
                    ],
                  },
                  { path: 'Documents', component: SupplierDocumentsComponent },
                ],
              }
            ],
          },
        ],
      },
    ],
  },
];
