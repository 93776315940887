<div class="screen-title">
    Add Invoice
</div>

<invoice-edit [model]="model | async"
    [users]="users | async | active"
    [autofill]="_autofill"
    (onSave)="saveInvoice($event)"
    (onCancel)="cancel()"
    [isTaxExempt]="_isTaxExempt"
    [salesTaxRates]="_taxRates"
    [useTaxRates]="_useTaxRates"
    (onAutofillGet)="retrieveAutofillValues($event)"
    [isNew]="true"
    [jobInfo]="jobInfo"
    [uuid]="uuid"
></invoice-edit>
