import { OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import * as dataUtils from '@/utils/dataUtils';
import { MessageService } from '@/services/message.service';
import { IMessageModel } from '@/models/message.models';

@Component({ template: '' })
export abstract class BaseComponent {
	// HACK: quickest semi-reasonable way to let templates for non-smart components have easy access to a global function
	// TODO should be protected but too many templates called it directly so switched to public for now, change back or remove
	public sanitizeDate(source: string | Date = null) : Date {
		return dataUtils.sanitizeDate(source);
	}

    //HACK: another dirty easy-access utility
    protected warnOnDelete(itemType: string) : boolean {
        return confirm('Are you sure you want to delete this ' + itemType + '?');
    }
};

@Component({ template: '' })
export abstract class BaseSmartComponent extends BaseComponent implements OnDestroy {
    private _subscriptions : Array<Subscription>;

    constructor(private messageService : MessageService) {
        super();

        this._subscriptions = new Array<Subscription>();
    }    //ALT: have message mediator handle register / deregister

    // todo change this to a decorator (https://blog.bitsrc.io/6-ways-to-unsubscribe-from-observables-in-angular-ab912819a78f)
    //      composition over inheritance
    ngOnDestroy() : void {
        for(let subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected sendMessage(message: IMessageModel): any {
        let toastMsg = this.messageService.send(message);
        return toastMsg;
    }

    protected clearMessage(messageID: number): void {
        this.messageService.remove(messageID);
    }

    protected watchSubscription(subscription : Subscription) : void {
        this._subscriptions.push(subscription);
    }
}
