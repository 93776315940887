import { Component, Input, OnInit } from '@angular/core';

import { ICustomerModel } from './../../models/customer.models';
import { MessageService } from "../../services/message.service";
import { StateManager } from "../../state/stateManager";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { BaseSmartComponent } from './../base.component';
import { ZipFormatPipe } from './../../utils/zipFormat.pipe';

@Component({
    selector: 'customer-summary',
    templateUrl: './customerSummary.template.html',
    host: {'class': 'customer-summary-component'}
})

export class CustomerSummaryComponent extends BaseSmartComponent implements OnInit {
    @Input()
    public model : ICustomerModel;

    constructor(private router: Router, private route: ActivatedRoute, private state : StateManager, messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.watchSubscription(self.state.getActiveCustomer().subscribe(s => self.model = s));
    }
}
