import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IReceiptModel } from '@/models/receipt.models';

export interface IReceiptState {
    receipts: Array<IReceiptModel>
}

const defaultState = {
    receipts: new Array<IReceiptModel>()
};

export const RECEIPT_ACTIONS = {
    SET_RECEIPTS_LIST: 'SET_RECEIPTS_LIST'
};

const receiptReducer = function(state: IReceiptState = defaultState, action: Action) {
    let newState = Object.assign({}, state);

    switch(action.type) {
        case RECEIPT_ACTIONS.SET_RECEIPTS_LIST:
            newState.receipts = action.payload;
            break;
    }

    return newState;
};

export function getReceiptsList() {
    return (state : Store<IReceiptState>) => {
        return state.select(s => {
            return s.receipts;
        });
    };
}

export function reducer(state: IReceiptState | undefined, action: Action) {
	return receiptReducer(state, action);
}
