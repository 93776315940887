<table class="table table-select-list" [ngClass]="{'with-indicator':showIndicator}" aria-describedby="changeOrdersLabel">
	<thead>
		<tr class="small">
			<th scope="col" class="list-item-date">
				Date
			</th>
			<th scope="col" class="list-item-number">
				Number
			</th>
			<th scope="col" class="currency-display">
				Amount
			</th>
		</tr>
	</thead>
    <tbody>
        <tr *ngFor="let changeOrder of model" (click)="selectChangeOrder(changeOrder)" [ngClass]="{ 'active' : changeOrder._id === selectedChangeOrderId }">
            <td class="list-item-date" scope="row">
                {{ changeOrder.date | dateFormat:'MMM DD, YYYY' }}
            </td>
            <td class="list-item-number" scope="row">
                {{ changeOrder.number }}
            </td>
            <td class="currency-display" scope="row">
                {{ changeOrder.amount | currencyFormat }}
            </td>
        </tr>
    </tbody>
</table>
