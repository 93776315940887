import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LocalStorageService {
  private storageSub: Subject<String> = new Subject<String>();

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any): void {
    localStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
    this.storageSub.next('changed');
  }
}
