<div class="backdrop" [ngStyle]="{'display':display}" [@fadeIn]="display"></div>
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}" [@generate]="display">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span aria-hidden="true">&times;</span></button>
        <div class="modal-title">{{title}}</div>
      </div>
      <div class="modal-body">
        <div *ngIf="type==='supplier'">
          <add-supplier [model]="model" (onSave)="saveModel($event)" (onCancel)="onCloseHandled()" [showAlert]="showAlert"></add-supplier>
        </div>
        <div *ngIf="type==='contact'">
            <contact-edit 
              [model]="model" 
              [isNew]="isNew"
              [showAlert]="showAlert" 
              (onSave)="saveModel($event)" 
              (onCancel)="onCloseHandled()"  
              (goToContact)="goToContact(model)"
            >
            </contact-edit>          
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
