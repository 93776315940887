import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { ICostModel } from '@/models/cost.models';

export interface ICostState {
    costs: Array<ICostModel>
}

const defaultState = {
    costs: new Array<ICostModel>()
};

export const COST_ACTIONS = {
    SET_COSTS_LIST: 'SET_COSTS_LIST'
};

const costReducer = function(state: ICostState = defaultState, action: Action) {
    let newState = Object.assign({}, state);

    switch(action.type) {
        case COST_ACTIONS.SET_COSTS_LIST:
            newState.costs = action.payload;
            break;
    }

    return newState;
};

export function getCostsList() {
    return (state : Store<ICostState>) => {
        return state.select(s => {
            return s.costs;
        });
    };
}

export function reducer(state: ICostState | undefined, action: Action) {
	return costReducer(state, action);
}
