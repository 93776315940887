import * as Utility from './utility.models';
import { IAddress, AddressModel } from './address.models';
import { IContactModel } from './contact.models';

export interface ICustomerModel extends Utility.BaseModel {
    name : string;
    main : string;
    fax : string;
    website : string;
    isActive : boolean;
    notes : string;
    address : IAddress;
    contacts : Array<IContactModel>;
    location : Utility.IBackReference | string;
};

// <editor-fold> Document models

export class CustomerModel extends Utility.BaseModel implements ICustomerModel {
    name : string = null;
    main : string = null;
    fax : string = null;
    website : string = null;
    isActive : boolean = true;
    notes : string = null;
    address : IAddress = new AddressModel();
    contacts : Array<IContactModel> = new Array<IContactModel>();
    location : Utility.IBackReference | string = null;
};

// </editor-fold>

export class CustomerSummaryModel extends Utility.BaseModel {
    name : string = null;
    main : string = null;
    address : IAddress = new AddressModel();
}
