import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilityModule } from './../../utils/utility.module';

import { CostsListComponent } from './costsList.component';
import { FilterEntriesModule } from './../FilterEntriesComponent/filterEntries.module';
import { MoveEntryModule } from './../MoveEntryComponent/moveEntry.module';
import { ModalModule } from './../ModalComponent/modal.module';

@NgModule({
    declarations: [
        CostsListComponent
    ],
    imports: [
        CommonModule,
        // SelectModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UtilityModule,
        FilterEntriesModule,
        MoveEntryModule,
        ModalModule,
        NgSelectModule
    ],
    exports: [
        CostsListComponent
    ]
})
export class CostsModule {}
