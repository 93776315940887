<section class="documents-section col-xs-12 col-md-6">
    <div class="screen-title">Associated Documents</div>
    <document-upload
        type="job"
        [isDraggable]="false"
        [label]="'Add documents'"
        [model]="currentJob"
        [typeId]="_jobId"
        [duplicateRecord]="duplicateRecord"
        (uploaded)="setMessage($event)"
        (duplicateFound)="duplicate()"
    ></document-upload>
</section>
