import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';

import { LOCATION_ROUTES } from './../LocationComponent/location.routes';
import { SUPPLIER_ROUTES } from './../SupplierComponent/supplier.routes';
import { MsalGuard } from '@azure/msal-angular';

export const ADMIN_ROUTES: Routes = [
  {
    path: 'Admin',
    canActivate: [MsalGuard],
    component: AdminComponent,
    children: [...LOCATION_ROUTES, ...SUPPLIER_ROUTES],
  },
];
