<nav class="export">
    <ul class="nav nav-tabs">
        <li ngbDropdown #exportDrop="ngbDropdown" class="d-inline-block export-toggle" autoClose="outside">
            <span href id="exportDropdown" ngbDropdownToggle>Export</span>
            <ul class="print-menu" ngbDropdownMenu aria-labelledby="exportDropdown">
                <li class="print-menu-option" *ngFor="let copy of exportOptions.copies">
                    <button type="button" class="dropdown-item btn btn-default btn-checkbox" [ngClass]="{ 'active btn-primary': copy.enabled }" (click)="copy.enabled = !copy.enabled;  $event.stopPropagation(); exportDrop.open();"><i class="glyphicon glyphicon-ok"></i></button> {{ copy.label }}
                </li>
                <li role="separator" class="divider" style="padding:0;"></li>
                <li class="print-menu-option" ngbDropdownItem>
                    <button type="button" class="dropdown-item btn btn-default btn-checkbox" [ngClass]="{ 'active btn-primary': includeDocs }" (click)="toggleAttachments();  $event.stopPropagation(); exportDrop.open();"><i class="glyphicon glyphicon-ok"></i></button> Include Attachments
                </li>
                <li role="separator" class="divider" style="padding:0;"></li>
                <li class="print-menu-execute" ngbDropdownItem>
                    <button type="button" class="dropdown-item btn btn-primary" (click)="export(); $event.stopPropagation();" ngbDropdownToggle [disabled]="!canExport()">Export</button>
                </li>
            </ul>
        </li>
    </ul>
</nav>
