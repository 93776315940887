import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { IPayableModel, PayableModel } from '@/models/payable.models';
import { PrintService } from '@/services/print.service';
import { handleError } from '@/utils/errors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'payable',
  templateUrl: './payable.template.html',
  host: {'class': 'payable-component'}
})
export class PayableComponent extends PrintableComponent implements OnInit, OnDestroy {
  public stampOnNew: boolean = false;

  private _customerId: string;
  private _jobId: string;
  private _payableId: string;

  private model: IPayableModel = null;
  private routerEvents: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private jobService: JobService,
    private printService: PrintService
  ) {
    super(router, printService, messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this.watchSubscription(this.state.getActiveCustomer().subscribe((s) => {
      this._payableId = s._id;
    }));

    this.printOptions.copies = [
      { index: 'projectManager', label: 'Control', enabled: false },
      { index: 'accounting', label: 'Accounting', enabled: false },
      { index: 'customer', label: 'Customer', enabled: false },
      { index: 'orderAndFile', label: 'Order and File', enabled: false },
      { index: 'woField', label: 'WO Field', enabled: false },
      { index: 'woTaggingAndDelivery', label: 'WO Tagging and Delivery', enabled: false }
    ];

    this.routerEvents = this.route.params.subscribe(value => {
      if (value['payableId']) {
        this.loadPayable(value['payableId']);
      }
    });
  }

  override ngOnDestroy(): void {
    this.state.setActivePayable(new PayableModel());
    this.routerEvents.unsubscribe();
    super.ngOnDestroy();
  }

  private loadPayable(payableId: string): void {
    this._payableId = payableId;
    this.jobService.getPayable(this._customerId, this._jobId, this._payableId)
      .then(payable => { this.model = payable; })
      .catch(handleError);
  }

  public stamp(): void {
    const command = this.stampOnNew ? 'merge' : 'stamp';
    if(this.model){
      this.jobService.exportPayable(this._customerId, this._jobId, this.model, command).subscribe(res => {
        this.stampOnNew = false;
        this.model.exportedKey = res.exportUrl;
      });
    }
  }
}
