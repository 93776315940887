<div class="screen-title">Add Purchase Order</div>

<purchase-order-edit-form 
    [model]="model | async" 
    [users]="users | async | active" 
    [suppliers]="suppliers | async" 
    [suppliersCopy]="suppliersCopy" 
    (onSave)="savePurchaseOrder($event)" 
    (onCancel)="cancel()" 
    showCancel="true" 
    [newPO]="true" 
    [uuid]="_purchaseOrderId"
></purchase-order-edit-form>
