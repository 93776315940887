import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UtilityModule } from '@/utils/utility.module';
import { WorkflowComponent } from '@/components/Workflow/workflow.component';
import { FileUploadModule } from '@/components/FileUploadComponent/file-upload.module';

@NgModule({
	declarations: [
		WorkflowComponent
	],
	imports: [
		CommonModule,
		FileUploadModule,
		FormsModule,
		UtilityModule
	],
	exports: [
		WorkflowComponent
	]
})
export class WorkflowModule {}
