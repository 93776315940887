import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, map, catchError } from 'rxjs';
import { share } from 'rxjs/operators';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { IWorkOrderPrintingModel, IPurchaseOrderPrintingModel, IInvoicePrintingModel } from '@/models/print.models';

@Injectable()
export class PrintService extends ApiBaseService {
  @Output()
  itemPrinted: EventEmitter<any> = new EventEmitter();

  constructor(private _http: HttpClient, messageService: MessageService) {
    super(messageService);
    this.baseUrl = 'print';
  }

  printWorkOrder(workOrderId: string): Promise<IWorkOrderPrintingModel> {
    let result = this._http.get<IWorkOrderPrintingModel>(this.baseUrl + '/workOrders/' + workOrderId).pipe(share());
    return this.extract(result);
  }

  printPurchaseOrder(purchaseOrderId: string): Promise<IPurchaseOrderPrintingModel> {
    let result = this._http.get<IPurchaseOrderPrintingModel>(this.baseUrl + '/purchaseOrders/' + purchaseOrderId).pipe(share());
    return this.extract(result);
  }

  printInvoice(jobId: string, invoiceId: string): Promise<IInvoicePrintingModel> {
    let result = this._http.get<IInvoicePrintingModel>(this.baseUrl + '/jobs/' + jobId + '/invoices/' + invoiceId).pipe(share());
    return this.extract(result);
  }

  print(url: string): Observable<string> {
    return this._http
      .get(this.baseUrl + url, { observe: 'response', responseType: 'blob' })
      .pipe(share())
      .pipe(map(res => {
        if (res.status === 200) {
          const headers = res.headers.keys();
          const filename = res.headers.get('Content-Disposition').split('=')[1].replace(/"/g, '');
          const file = new File([res.body], filename, { type: 'application/pdf' });
          return URL.createObjectURL(file);
        } else {
          // return empty string if not successful
          return '';
        }
      }))
      .pipe(catchError((err, obs) => {
        return throwError(() => new Error(err)); 
      }));
  }
}
