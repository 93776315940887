import { Component, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'pagination-component',
  template: `
    <div *ngIf="totalItems > itemsPerPage" class="row"><div class="col-xs-12 pagination-container">
      <pagination
          [totalItems]="totalItems"
          [maxSize]="5"
          [itemsPerPage]="itemsPerPage"
          [rotate]="false"
          [boundaryLinks]="true"
          [(ngModel)]="currentPage">
      </pagination>
      <div class="go-to-page-container">
          <label>Go to page</label>
          <input class="form-control go-to-page" type="number" min="1" max="goToPageMax"
                 [(ngModel)]="currentPage"
                 (change)="goToPage(currentPage)"/>
      </div>
  </div>
</div>
`,

host: {'class': 'pagination-component'}
})
export class PaginationComponent {
  @Input() totalItems: any;

  @Output() updateList: EventEmitter<Object> = new EventEmitter<Object>();

  public itemsPerPage: number = 50;
  public currentPage: number = 1;

  constructor(private _cd: ChangeDetectorRef) { }

  public goToPage(page: number): void {
    // removed pre-calculation because it was potentially causing errors on a shrinking total edge case
    const max = Math.ceil(this.totalItems / this.itemsPerPage);
    this.currentPage = (page > max) ? max : page;
  }

  public reset(): void {
    this.currentPage = 1;
    // this was necessary to work around a "expression changed after validation" error
    // see: https://github.com/valor-software/ngx-bootstrap/issues/681#issuecomment-272904329
    this._cd.detectChanges();
  }
}
