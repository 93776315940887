import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StateManager } from './../../state/stateManager';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';
import { AuthService } from '@/services/auth.service';
import { UserRole } from '@/models/auth.models';
import { IPayableModel } from './../../models/payable.models';

import { DataManipulationService } from './../../services/dataManipulation.service';

@Component({
    selector: 'job-payable-list',
    templateUrl: './jobPayableList.template.html',
    host: {'class': 'job-payable-list-component'}
})

export class JobPayableListComponent extends BaseSmartComponent implements OnInit {
    public filterTerm: string = '';

    @Input()
    model : BehaviorSubject<Array<IPayableModel>>;

    @Input()
    dataManager : DataManipulationService<IPayableModel>;

    @Input()
    projectManager : string;

    @Output()
    public addClicked = new EventEmitter();

    @Output()
    public itemSelected = new EventEmitter();

    public selectedPayableId : string = null;
	public user: Object;
	public userSub: Subscription;
	public isAdmin: boolean = false;
	public role: number;
	public permissionLock: boolean = false;


    constructor(
        private state : StateManager,
		private authService: AuthService, 
        messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.watchSubscription(self.state.getActivePayable().subscribe((s) => {
            setTimeout(()=>{
                if(s) {
                    self.selectedPayableId = s._id;
                }
                else {
                    self.selectedPayableId = null;
                }
            })
        }));
        // get user's role
        self.userSub = self.authService.currentUser.subscribe(s => {
            if (s) {
                self.user = s;
                self.role = s.role;
                if (self.role === UserRole.Admin) {
                    self.isAdmin = true;
                } else {
                    self.isAdmin = false;
                    self.permissionLock = true;
                }
            }
        });
    }
}
