import {Component} from "@angular/core";
/**
 * Created by rrahlf on 8/3/2016.
 */

@Component({
	selector:'customer-edit-tabs',
	templateUrl:'./customerEditTabs.template.html',
	host: {'class': 'customer-edit-tabs-component'}
})
export class CustomerEditTabsComponent {}
