import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilityModule } from '../../utils/utility.module';
import { FilterListComponent } from './filterList.component';

@NgModule({
    declarations: [
        FilterListComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UtilityModule,
    ],
    exports: [
        FilterListComponent
    ]
})
export class FilterListModule {}
