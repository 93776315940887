import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { PurchaseOrderSortFunctions } from '@/utils/dataManipulationUtils';
import { JobService } from '@/services/job.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { IInvoiceModel } from '@/models/invoice.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'invoice-connection',
  templateUrl: './invoiceConnection.template.html',
  host: {'class': 'invoice-connection-component'}
})
export class InvoiceConnectionComponent extends BaseSmartComponent implements OnInit {
  // todo rename or refactor so public property isn't prefixed with _
  // purchase order attachment
  public _selectedPurchaseOrderId: string = null;
  public purchaseOrders: Array<IPurchaseOrderModel> = Array<IPurchaseOrderModel>();
  public attachedPOs: Array<IPurchaseOrderModel> = Array<IPurchaseOrderModel>();
  public poList: Array<IPurchaseOrderModel> = Array<IPurchaseOrderModel>(); // copy used for updating dropdown

	private invoice: IInvoiceModel = null;
	private _customerId: string;
	private _jobId: string;
	private _invoiceId: string;
	private _jobBaseRoute: ActivatedRoute;
	private model: Subject<Array<IPurchaseOrderModel>> = new Subject<Array<IPurchaseOrderModel>>();
	private filterTerm: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private purchaseOrderService: PurchaseOrderService,
    private dataManipulationService: DataManipulationService<IPurchaseOrderModel>,
    messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(messageService);
    this.model.subscribe(POs => {
      this.sortParts(POs);
    });
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._invoiceId = routerUtils.getRouteParameter(this.route, 'invoiceId');

    this.dataManipulationService.initialize({}, PurchaseOrderSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getPurchaseOrdersList(), this.model));

		// purchase order listing
    this._jobBaseRoute = routerUtils.getParentRoute(this.route, 3);

    this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId)
      .then(pos => {
        this.purchaseOrders = pos;
        this.purchaseOrders.sort((a, b) => (a.number > b.number) ? 1 : -1);
        this.filterPOs();
      })
      .catch(handleError);
  }

	public attachPurchaseOrder(selectedPurchaseOrderId): void {
		this.jobService.connectInvoices(this._customerId, this._jobId, this._invoiceId, selectedPurchaseOrderId)
      .then((res: IPurchaseOrderModel) => this.updatePurchaseOrder(res))
      .catch(handleError);
	}

  public createPurchaseOrder(): void {
    this.router.navigate(['PurchaseOrders', 'Add'], { relativeTo: this._jobBaseRoute });
  }

	private detachPurchaseOrder(purchaseOrder: IPurchaseOrderModel): void {
    this.jobService.disconnectInvoices(this._customerId, this._jobId, this._invoiceId, purchaseOrder._id)
      .then((res: IPurchaseOrderModel) => this.updatePurchaseOrder(res))
      .catch(handleError);
	}

	/**
	 * Finds the purchase order in the array of Purchase Orders and updates it to the newest
	 * information. After, calls filter to create an updated view of the Purchase Order.
	 * @param {IPurchaseOrderModel} purchaseOrder
	 */
	private updatePurchaseOrder(purchaseOrder: IPurchaseOrderModel): void {
		let index = this.purchaseOrders.findIndex((po: IPurchaseOrderModel) => po._id === purchaseOrder._id);
		if (index > -1) {
			this.purchaseOrders.splice(index, 1, purchaseOrder);
			this.filterPOs();
    }
  }

  private goToPurchaseOrder(purchaseOrderId: string): void {
    this.router.navigate(['PurchaseOrders', purchaseOrderId, 'Parts'], { relativeTo: this._jobBaseRoute });
  }

  private sortParts(POs): void {
	  POs.forEach(po => {
      if(po?.parts){
        po?.parts.sort(function(a, b) {
          return (a.timestamp < b.timestamp) ? -1 : ((a.timestamp > b.timestamp) ? 1 : 0);
        });
      }
	  });
  }

	private filterPOs(): void {
    this.poList = Object.assign([], this.purchaseOrders);
    this.attachedPOs = this.poList.filter((po: IPurchaseOrderModel) => po.invoice === this._invoiceId);
    this.poList = this.poList.filter((po: IPurchaseOrderModel) => !po.invoice);
    this.jobService.getInvoice(this._customerId, this._jobId, this._invoiceId)
      .then((invoice) => {
        this.invoice = invoice;
        this._selectedPurchaseOrderId = null;
      })
      .catch(handleError);
  }
}
