import { Pipe, PipeTransform } from '@angular/core';
import * as dataUtils from './dataUtils';

@Pipe({
    name : 'currencyFormat',
    pure : true
})

export class CurrencyFormatPipe implements PipeTransform {
    transform(value : string = '', nullOnZero : boolean = false, zeroOnNull: boolean = true, figureCount:number = 2) : string {
    	let numericValue:number;

    	if(value == null){
    		if(zeroOnNull){
    			numericValue = 0;
				return dataUtils.formatCurrency(numericValue, figureCount);
			}
			return null;
		}else{
			numericValue = Number(value);
			if((numericValue === 0) && nullOnZero) {
				return null;
			}
			return dataUtils.formatCurrency(value, figureCount);
		}
    }
};


@Pipe({
    name : 'currencyEmptyFormat',
    pure : true
})

export class CurrencyEmptyFormatPipe implements PipeTransform {
    transform(value : string = '', nullOnZero : boolean = false, zeroOnNull: boolean = true, figureCount:number = 2) : string {
    	let numericValue:number;

    	if(value == null){
    		if(zeroOnNull){
    			numericValue = 0;
				return "";
			}
			return null;
		}else{
			numericValue = Number(value);
			if((numericValue === 0) && nullOnZero) {
				return null;
			}
			return dataUtils.formatCurrency(value, figureCount);
		}
    }
};
