import { Component, Input, OnInit } from '@angular/core';

import { MessageService } from '@/services/message.service';
import { StateManager } from '@/state/stateManager';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSmartComponent } from './../base.component';
import {ISupplierModel} from '@/models/supplier.models';

@Component({
    selector: 'supplier-summary',
    templateUrl: './supplierSummary.template.html',
    host: {'class': 'supplier-summary-component'}
})

export class SupplierSummaryComponent extends BaseSmartComponent implements OnInit {
    @Input()
    public model: ISupplierModel;

    constructor(private router: Router, private route: ActivatedRoute, private state: StateManager, messageService: MessageService) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self.watchSubscription(self.state.getActiveSupplier().subscribe(s => self.model = s));
    }
}
