import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { BaseSmartComponent } from './../base.component';

import { MessageService } from '@/services/message.service';
import { AuthService } from '@/services/auth.service';
import { LocalStorageService } from '@/services/localStorage.service';
import { DashboardService } from '@/services/dashboard.service';

import * as Utility from '@/models/utility.models';
import { IAuthUser, UserRole } from '@/models/auth.models';
import { DashboardSummary } from '@/models/summary.models';
import { ActionItemsDashboard } from '@/models/actionItems.model';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.template.html',
	host: {'class': 'dashboard-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})
export class DashboardComponent extends BaseSmartComponent implements OnInit {

	@ViewChild('jobSearch', {static: true})
	jobSearch: Utility.IFocusable;

	user: IAuthUser;
	userSub: Subscription;

	public countOptions: {};
	public summary: DashboardSummary = new DashboardSummary();
	public actionItemsDashboard: ActionItemsDashboard = new ActionItemsDashboard();
	public isShopForeman: Boolean = false;
	public showLogout: Boolean = false;
	private timerSubscription: Subscription;
	private locationSub: Subscription;
	public isLoading: boolean = true;
	public isLoggedIn: boolean;
	public isAllowed: boolean;

	constructor(
		messageService: MessageService,
		private authService: AuthService,
		private dashboardService: DashboardService,
		private localStorageService: LocalStorageService
	) {
		super(messageService);
	}

  ngOnInit(): void {
		this.isLoading = true;
		this.authService.isAllowed$.subscribe((isAllowed) => {
			this.isAllowed = isAllowed;
		});
		this.authService.currentUser
			.subscribe((user) => {
				this.user = user;
				this.isLoggedIn = this.authService.isLoggedIn;
				this.isLoading = false;
        this.isShopForeman = this.user?.role === UserRole.ShopForeman || false;
    	});

		this.getAllData();

		if (this.jobSearch) {
      this.jobSearch.focus();
    }

    this.countOptions = {
      startVal: 0,
      decimalPlaces: 0,
      duration: 3
    };

    // update data every 5 minutes
    const updateData = interval(300000);
    this.timerSubscription = updateData.subscribe((val) => {
			this.getAllData();
    });

  	// switch location view
    this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
      this.getAllData();
    });
	}

  override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
    this.timerSubscription.unsubscribe();
    super.ngOnDestroy();
  }

    getAllData(): void {
			this.dashboardService.get().subscribe(summaries => {
				this.summary.set(summaries);
			});
			this.dashboardService.getActionItems().subscribe(actionItems => {
				this.actionItemsDashboard.set(actionItems);
			});
    }

	processLogout(): void {
		this.authService.logout();
	}
}
