import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { ICustomerModel, CustomerModel } from '@/models/customer.models';

export interface ICustomerState {
	customers : Array<ICustomerModel>,
	activeCustomer : ICustomerModel
}

const defaultState : ICustomerState = {
	customers: new Array<ICustomerModel>(),
	activeCustomer: new CustomerModel()
};

export const CUSTOMER_ACTIONS = {
	SET_CUSTOMERS_LIST: 'SET_CUSTOMERS_LIST',
	SET_ACTIVE_CUSTOMER: 'SET_ACTIVE_CUSTOMER'
};

const customerReducer = function(state: ICustomerState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case CUSTOMER_ACTIONS.SET_CUSTOMERS_LIST:
			newState.customers = action.payload;
			break;
		case CUSTOMER_ACTIONS.SET_ACTIVE_CUSTOMER:
			newState.activeCustomer = action.payload;
			break;
	}

	return newState;
};

export function getCustomersList() {
	return (state : Store<ICustomerState>) => {
		return state.select(s => {
			return s.customers;
		});
	};
}

export function getActiveCustomer() {
	return (state : Store<ICustomerState>) => {
		return state.select(s => {
			return s.activeCustomer;
		});
	};
}

export function reducer(state: ICustomerState | undefined, action: Action) {
	return customerReducer(state, action);
}
