<nav>
    <ul class="nav nav-tabs">
        <li>
            <a [routerLink]="['Edit']" routerLinkActive="active">Details</a>
        </li>
		<li>
			<a [routerLink]="['PMNotes']" routerLinkActive="active">Instructions</a>
		</li>
		<li>
            <a [routerLink]="['PurchaseOrders']" routerLinkActive="active">Purchase Orders</a>
        </li>
		<li>
            <a [routerLink]="['Parts']" routerLinkActive="active" id="partsLabel">Parts</a>
        </li>
		<li>
            <a [routerLink]="['Documents']" routerLinkActive="active">Documents</a>
        </li>
    </ul>
</nav>

<print-menu [printOptions]="printOptions" (onPrint)="print()" style="top:0;"></print-menu>

<router-outlet></router-outlet>
