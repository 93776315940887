import { Routes } from '@angular/router';

import { ReportComponent } from './report.component';
import { ReportSidebarComponent } from './reportSidebar.component';
import { ReportWindowComponent } from './reportWindow.component';
import { ReportJobListComponent } from './reportJobList.component'
import { ReportTimeEntryComponent } from './reportTimeEntry.component';
import { ReportJournalCostComponent } from './reportJournalCost.component';
import { ReportJobOutcomeComponent } from './reportJobOutcome.component';
import { ReportMonthlyCostsComponent } from './reportMonthlyCosts.component';
import { ReportJobDetailComponent } from './reportJobDetail.component';
import { ReportJobSummaryComponent } from './reportJobSummary.component';
import { ReportInvoiceSummaryComponent } from './reportInvoiceSummary.component';
import { MsalGuard } from '@azure/msal-angular';

export const REPORT_ROUTES: Routes = [
  {
    path: 'Reports',
    component: ReportComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        component: ReportSidebarComponent,
        outlet: 'sidebar',
      },
      {
        path: '',
        component: ReportWindowComponent,
        children: [
          {
            path: '',
            redirectTo: 'JobList',
            pathMatch: 'prefix',
          },
          {
            path: 'JobList',
            component: ReportJobListComponent,
          },
          {
            path: 'Journal',
            children: [
              {
                path: 'TimeEntry',
                component: ReportTimeEntryComponent,
              },
              {
                path: 'Cost',
                component: ReportJournalCostComponent,
              },
            ],
          },
          {
            path: 'JobOutcome',
            component: ReportJobOutcomeComponent,
          },
          {
            path: 'MonthlyCosts',
            component: ReportMonthlyCostsComponent,
          },
          {
            path: 'JobDetail',
            component: ReportJobDetailComponent,
          },
          {
            path: 'JobSummary',
            component: ReportJobSummaryComponent,
          },
          {
            path: 'InvoiceSummary',
            component: ReportInvoiceSummaryComponent,
          },
        ],
      },
    ],
  },
];
