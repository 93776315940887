import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from './../base.component';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { UUIDService } from '@/services/uuid.service';
import { LocationService } from '@/services/location.service';

import { IInvoiceModel, InvoiceModel, IInvoiceAutofillResponse, IInvoiceAutoCalculateTax } from './../../models/invoice.models';
import { IUserModel } from '@/models/user.models';
import { WorkflowStatus } from '@/models/workflow.models';
@Component({
	selector: 'job-invoice-add',
	templateUrl: './jobInvoiceAdd.template.html',
	host: {'class': 'job-invoice-add-component'}
})

export class JobInvoiceAddComponent extends BaseSmartComponent implements OnInit {
	private _customerId: string;
	private _jobId: string;
	// todo refactor or rename so public properties aren't prefixed with _
	public _autofill: IInvoiceAutofillResponse = {} as IInvoiceAutofillResponse;
	public _isTaxExempt: boolean;
	public _taxRates: IInvoiceAutoCalculateTax = {};
	public _useTaxRates: IInvoiceAutoCalculateTax = {};

	public model: Observable<IInvoiceModel>;
	public users: Observable<Array<IUserModel>>;
	private projectManager: any;
	private contractNumber: any;
	public uuid: string;
	public jobInfo: any = {ordernumber: null};
	state$: Observable<object>;
	private newLifecycle: Array<WorkflowStatus> = [];

	constructor(private router: Router, private route: ActivatedRoute, private state: StateManager, messageService: MessageService, private jobService: JobService, private locationService: LocationService, private uuidService: UUIDService) {
		super(messageService);
	}

	ngOnInit(): void {
		const self = this;

		self.model = self.state.getActiveInvoice();
		self.users = self.state.getUsersList();

		self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
		self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
		self.uuid = self.uuidService.generateUUID();

		self.state$ = this.route.paramMap.pipe(map(() => window.history.state));

		self.watchSubscription(self.state$.subscribe((s) => {
			self.jobInfo = s;
		}));

		let newInvoice = new InvoiceModel();
		newInvoice.dateOfOrder = self.sanitizeDate();

		self.state.setActiveInvoice(newInvoice);

		self.watchSubscription(self.state.getActiveJob().subscribe((s) => {
			self._isTaxExempt = s.isTaxExempt;
		}));

		self.watchSubscription(self.state.getActiveCustomer().subscribe((s) => {
			if (!s.location) { return; }

			self.locationService.getTaxRates(s.location as string).then((taxRates) => {
				var processTaxRates = function(taxRates, rateField){
					for (let taxRate of taxRates) {
						let taxRateName = taxRate.name.toLowerCase() + 'Tax';

						rateField[taxRateName] = taxRate.rate !== null ? taxRate.rate * 100 : null;
					}
				}

				processTaxRates(taxRates.salesTax, self._taxRates);
				processTaxRates(taxRates.useTax, self._useTaxRates);
			});
		}));

		self.retrieveAutofillValues(self.sanitizeDate());
	}

	public cancel(): void {
		this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
	}

	public saveInvoice(newModel: IInvoiceModel): void {
		const self = this;
		// remove the docs for the save and then add them back in after so view is updated
		let docs = newModel.documents;
		delete newModel.documents;

		newModel._id = self.uuid;
		// copy over static autofill values and purely calculated fields
		newModel.originalContract = self._autofill.originalContract;
		newModel.totalContractToDate = newModel.originalContract + newModel.changeOrdersToDate;
		newModel.amountPreviouslyInvoiced = self._autofill.amountPreviouslyInvoiced;
		newModel.amountRemaining = newModel.totalContractToDate - newModel.amountPreviouslyInvoiced - newModel.amountThisInvoice;

		// set initial status for new Invoices
		if(!newModel.lifecycle || !newModel.lifecycle.length) {
			newModel.lifecycle = this.newLifecycle;
			let initialStatus = null;
			switch (newModel.priority) {
				case '1':
					initialStatus = {
						status: 'ready_full',
						note: 'Initial Scheduling Priority: 1',
						timestamp: new Date().toISOString()
					}
				break;
				case '5':
					initialStatus = {
						status: 'pending_supplies',
						note: 'Initial Scheduling Priority: 5',
						timestamp: new Date().toISOString()
					}
				break;
				default:
					initialStatus = {
						status: 'new',
						note: 'Initial Scheduling Priority: None',
						timestamp: new Date().toISOString()
					}
				break;
			}
			newModel.lifecycle.push(initialStatus);
		}
		self.jobService.createInvoice(self._customerId, self._jobId, newModel)
			.then((result) => {
				self.jobService.getInvoices(self._customerId, self._jobId);
                setTimeout(() => {
                    if(docs) {
                        newModel.documents = docs;
                    }
                }, 0);
				self.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(self.route, 1) });
			});
	}

	public retrieveAutofillValues(date: Date) {
		const self = this;

		self.jobService.getInvoiceAutofillValues(self._customerId, self._jobId, null, date)
			.then((result) => {
				self._autofill = result;

				setTimeout(() => {
					// only populate cost taxes and contract number on new invoices
					self.model.subscribe((s) => {
						s.costTax.cityTax = result.costTax.cityTax;
						s.costTax.countyTax = result.costTax.countyTax;
						s.costTax.mkecountyTax = result.costTax.mkecountyTax;
						s.costTax.stateTax = result.costTax.stateTax;
						s.costTax.stadiumTax = result.costTax.stadiumTax;
					})
					.unsubscribe();
				}, 0);
			});
	}

};
