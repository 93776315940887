<div class="">
    <div class="time-entry-job-selector">
        <div class="pull-left mr-2 hidden-xs">
            <div class="screen-subtitle" style="padding-top:6px;">
                Find a Job
            </div>
        </div>
        <div style="position: relative">
            <search #jobSearch 
            [(ngModel)]="jobNumber" 
            large="true" 
            (onAction)="searchJobs()" 
            class="job-search" 
            placeholder="Enter job number or job name" 
            keyboardAccelerator="9" 
            (onKeyboardAction)="searchJobs()" 
            [isLoading]="isLoading"
            [setFocus]="false"></search>

            <div class="time-entry-job-results" [hidden]="!jobResults" #jobResultList tabIndex="-1">
                <ng-template [ngIf]="jobResults?.length < 21">
                    <div *ngFor="let jobResult of jobResults | sort:'number'">
                        <div class="time-entry-job-results-item">
                            <div class="job-result">{{ jobResult.number }} - {{ jobResult.name }} <span *ngIf="jobResult.isClosed">(closed)</span></div>
                            <button class="btn btn-default" (click)="goToJob(jobResult)" type="button">Select</button>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngIf]="jobResults?.length > 20">
                    <div *ngFor="let jobResult of jobResults let i=index">
                        <div class="time-entry-job-results-item" *ngIf="i < 20">
                            <div class="job-result">{{ jobResult.number }} - {{ jobResult.name }} <span *ngIf="jobResult.isClosed">(closed)</span></div>
                            <button class="btn btn-default" (click)="goToJob(jobResult)" type="button">Select</button>
                        </div>
                    </div>
                    <div class="time-entry-job-results-item shaded" style="border-bottom:0;">
                        Please refine your search to view more jobs.
                    </div>
                </ng-template>

                <div class="time-entry-job-results-item" *ngIf="jobResults?.length === 0">
                    No jobs found; please try a different search
                </div>
            </div>
        </div>
    </div>
</div>
