<div id="move-entry" class="form-group">
    <div *ngIf="viewTab && formattedJobs" class="row mb-2 entry-control-tab" style="left:130px;" [@fadeInOut]>
        <div class="col-sm-12 small">
            <span (click)="toggleMoveView()" class="toggle-control" [ngClass]="{'disabled': selectedEntries.length < 1}"><i class="glyphicon glyphicon-circle-arrow-left"></i> Move selected</span>         
        </div>
    </div>
    <div *ngIf="viewMove" class="row entry-control-content mt-1" [@fadeInOut]>
        <div class="col-12 col-md-4 col-lg-3">
            <label>New posting date</label>
            <input type="date" [(ngModel)]="entry.postingDate" class="form-control" [ngClass]="{'error': invalid && !entry.postingDate}"/>
        </div>
        <div class="col-12 col-md-4 col-lg-6">
            <label>Job</label>
            <ng-select 
                class="ng-select-component"
                [clearable]="true"
                [items]="entry.options"
                [disabled]="disabled"
                bindLabel="text"
                (change)="setJob($event)"
                placeholder="Select"
                [ngClass]="{'error': invalid && !jobSelected && jobRequired}"
                #selectField>
            </ng-select>
        </div>
        <div *ngIf="showNotes" class="col-12 col-md-4 col-lg-3 mb-1">
            <label>Notes</label>
            <input type="text" [(ngModel)]="entry.note" class="form-control"/>
        </div>
        <div class="col-sm-12 text-right" [ngClass]="{'col-md-4 col-lg-3 margin-fix-1': !showNotes}">
            <button (click)="saveMove()" class="btn btn-primary">Commit</button><div class="inline-block pseudo-link small ml-2" (click)="cancelMove()">Cancel</div>        
        </div>
    </div>
</div>
    