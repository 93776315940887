import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import * as dataUtils from '@/utils/dataUtils';
import { BaseSmartComponent } from '@/components/base.component';

import { IJobModel } from '@/models/job.models';
import { IReportJobDetailResult, IReportJobDetailCost } from '@/models/report.models';
import { FilterValue } from '@/models/filter.models';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { ReportService } from '@/services/report.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { AppSettingsService } from '@/services/appSettings.service';

import { ReportJobDetailSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'report-job-detail',
  templateUrl: './reportJobDetail.template.html',
  host: {'class': 'report-job-detail-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class ReportJobDetailComponent extends BaseSmartComponent implements OnInit {

  @ViewChild('jobResultList', {static: true})
  jobResultList: ElementRef;

  public totals = {
    gp: 0,
    gpAmount: 0
  };
  // todo refactor or rename this so a public property isn't prefixed with an _
  public _job: IJobModel;
  public _jobNumber: string = null;
  public _lastJobNumber: string = null;
  public _jobResults: Array<IJobModel>;
  public dirty: boolean = true;
  public model: IReportJobDetailResult = null;
  public isTaxExempt: boolean = false;
  public hasStadiumTax: boolean = false;
  public filters: FilterValue[] = [];
	public appSettings: Object;

  private _currentUser: any;
	private _location: string;
  private _model: Observable<IReportJobDetailResult>;
  private _costs: Subject<Array<IReportJobDetailCost>> = new Subject<Array<IReportJobDetailCost>>();
  private costs: BehaviorSubject<Array<IReportJobDetailCost>> = new BehaviorSubject<Array<IReportJobDetailCost>>(null);
  private today: Date = this.sanitizeDate();
  public startDate: Date = this.today;
  public endDate: Date = this.today;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService,
    private dataManipulationService: DataManipulationService<IReportJobDetailCost>,
    private settingsService: AppSettingsService,
    messageService: MessageService
  ) {
    super(messageService);
		this.appSettings = this.settingsService.getSettings();
  }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this._currentUser.locationId;
    this.dataManipulationService.initialize({}, ReportJobDetailSortFunctions);
    this.dataManipulationService.setSort('date', false);
  }

  // todo a minor variation is used in time entry; pull out as modular component
  public searchJobs(): void {
    if (this._jobNumber === '') {
      this._jobNumber = null;
    }

    if (this._jobNumber === this._lastJobNumber) {
      return;
    }

    this._lastJobNumber = this._jobNumber;
    this._job = null;
    this._jobResults = null;
    this.dirty = true;

    // search cleared
    if (!this._jobNumber) {
        return;
    }

    // require most of the job number before executing the search
    if (this._jobNumber.length < 4) { return; }

    this.filters.push({ filter: 'locationFilter', id: this._location });
    this.jobService.getJobs(0, true, this._jobNumber, 'number', 1, 1, this.filters)
      .then((jobs) => {
        if (jobs.length === 1) {
          // found exactly one job - use this
          this.selectJob(jobs[0]);
        } else {
          this._jobResults = jobs;
          if (this.jobResultList) {
            // previous tab-out takes effect before resetting focus
            setTimeout(() => {
              this.jobResultList.nativeElement.focus();
              }, 0);
          }
        }
      })
      .catch(handleError);
  }

  public runReport(): void {
    this.startDate = this.validateDates(this.startDate, true);
    this.endDate = this.validateDates(this.endDate, false);
    this.hasStadiumTax = false;
    let result = this.reportService.getJobDetailReport(this._job._id, this.startDate, this.endDate);

    this.resetTotals();
    this.watchSubscription(this.dataManipulationService.connectDataSource(this._costs, this.costs));

    result.toPromise()
      .then((job) => {
        this.model = job;
        this.isTaxExempt = this.model.isTaxExempt;
        // show stadium tax column for jobs before 4/1/2020
        if (
          this.model.totalsOther.stadiumTax > 0 ||
          this.model.totalsCurrent.stadiumTax > 0 ||
          this.model.totalsToDate.stadiumTax > 0) {
          this.hasStadiumTax = true;
        }

        // labor and costs get saved out of order so rearrange them so they're consistent on the report
        this.reorderLabor(this.model.totalsOther.labor);
        this.reorderLabor(this.model.totalsCurrent.labor);
        this.reorderLabor(this.model.totalsToDate.labor);
        this.reorderCosts(this.model.totalsOther.costs);
        this.reorderCosts(this.model.totalsCurrent.costs);
        this.reorderCosts(this.model.totalsToDate.costs);

        this._costs.next(job.costs);    // hack pull "observable" out from report

        this.totals.gpAmount = job.invoices - job.totalsToDate.subtotal - job.totalsToDate.stateTax - job.totalsToDate.countyTax - job.totalsToDate.mkecountyTax - (job.totalsToDate?.cityTax ? job.totalsToDate?.cityTax : 0) - job.totalsToDate.stadiumTax;
        this.totals.gp = this.totals.gpAmount / job.invoices;

        this.dirty = false;
        this.lookupLaborCodes(this.model);
      })
      .catch(handleError);
  }

  public markDirty(): void {
    this.dirty = true;
  }

  private selectJob(job: IJobModel): void {
    this._job = job;
    this._jobNumber = this._job.number;
    this._jobResults = null;
    if (this._job.date) {
      this.startDate = this.sanitizeDate(this._job.date);
    } else {
      this.startDate = this.sanitizeDate('1970-01-01T00:00:01');
    }
    this.endDate = this.validateDates(this.endDate, false);
  }

  // if date field has been cleared, reset it to a valid date
  private validateDates(date: Date, isStart: boolean): Date {
    if (Object.prototype.toString.call(date) === '[object Date]') {
      if (isNaN(date.getTime())) {
        if (isStart) {
          date = this.sanitizeDate(this._job.date);
        } else {
          date = new Date();
        }
      }
    } else {
      date = new Date();
    }
    return date;
  }

  private lookupLaborCodes(job): void {
    // find labor code by id to get text and total
    job.costs.forEach(cost => {
      job.laborCodes.forEach(code => {
        if (cost.laborCodeId && (cost.laborCodeId === code._id)) {
          cost.laborCodeText = code.id + ' - ' + code.name;
        }
      });
    });
  }

  private reorderLabor(labor): void {
    if (labor['Field Labor']) {
      var field = labor['Field Labor'];
      delete labor['Field Labor'];
    }
    if (labor['Shop Labor']) {
      var shop = labor['Shop Labor'];
      delete labor['Shop Labor'];
    }

    if (field) {
      labor['Field Labor'] = field;
    }

    if (shop) {
      labor['Shop Labor'] = shop;
    }
  }
  private reorderCosts(labor): void {
    if (labor['Materials']) {
      var field = labor['Materials'];
      delete labor['Materials'];
    }
    if (labor['Outside Services']) {
      var shop = labor['Outside Services'];
      delete labor['Outside Services'];
    }

    if (field) {
      labor['Materials'] = field;
    }

    if (shop) {
      labor['Outside Services'] = shop;
    }
  }
  private resetTotals(): void {
    this.totals = {
      gp: 0,
      gpAmount: 0
    };
  }

  private keepEmpty(value: string | number): string {
    if (value === null || value === 0) {
      return '';
    }

    if (typeof value === 'string') {
      return '';
    } else if (typeof value === 'number') {
      return dataUtils.formatCurrency(value);
    }

    return '';
  }

  // hack iterate over object properties
  private getKeys(source: { [key: string]: any }): Array<string> {
    return Object.keys(source);
  }

  public navigate(bookmark): void {
    // account for sticky header when scrolling to bookmarks on desktop/tablet views
    let viewport = window.innerWidth;
    let headerHeight = 0;
    if (viewport > 767) {
      headerHeight = document.getElementById('header').offsetHeight;
    }

    const el = document.getElementById(bookmark);
    const top = el.getBoundingClientRect().top - headerHeight;
    window.scrollTo({ top: top, behavior: 'smooth' });
  }
}
