import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IUserModel, UserModel } from '@/models/user.models';

export interface IUserState {
	users: Array<IUserModel>,
	selectedUser: IUserModel,
	locationUsers: Array<IUserModel>,
	locationSelectedUser: IUserModel,
	locationPMs: Array<IUserModel>
}

const defaultState = {
	users: new Array<IUserModel>(),
	selectedUser: new UserModel(),
	locationUsers: new Array<IUserModel>(),
	locationSelectedUser: new UserModel(),
	locationPMs: new Array<IUserModel>()
};

export const USER_ACTIONS = {
	SET_USERS_LIST: 'SET_USERS_LIST',
	SET_SELECTED_USER: 'SET_SELECTED_USER',
	SET_LOCATION_USERS_LIST: 'SET_LOCATION_USERS_LIST',
	SET_LOCATION_SELECTED_USER: 'SET_LOCATION_SELECTED_USER',
	SET_LOCATION_PROJECT_MANAGERS: 'SET_LOCATION_PROJECT_MANAGERS'
};

const userReducer = function (state: IUserState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case USER_ACTIONS.SET_USERS_LIST:
				newState.users = action.payload;
				break;
		case USER_ACTIONS.SET_SELECTED_USER:
				newState.selectedUser = action.payload;
				break;
		case USER_ACTIONS.SET_LOCATION_USERS_LIST:
				newState.locationUsers = action.payload;
				break;
		case USER_ACTIONS.SET_LOCATION_SELECTED_USER:
				newState.locationSelectedUser = action.payload;
				break;
		case USER_ACTIONS.SET_LOCATION_PROJECT_MANAGERS:
				newState.locationPMs = action.payload;
	}

	return newState;
};

export function getUsersList() {
	return (state : Store<IUserState>) => {
		return state.select(s => {
			return s.users;
		});
	};
}

export function getSelectedUser() {
	return (state : Store<IUserState>) => {
		return state.select(s => {
			return s.selectedUser;
		});
	};
}

export function getLocationUsersList() {
	return (state : Store<IUserState>) => {
		return state.select(s => {
			return s.locationUsers;
		});
	};
}

export function getLocationSelectedUser() {
	return (state : Store<IUserState>) => {
		return state.select(s => {
			return s.locationSelectedUser;
		});
	};
}

export function getPMList() {
	return (state : Store<IUserState>) => {
		return state.select(s => {
			return s.locationPMs;
		})
	}
}

export function reducer(state: IUserState | undefined, action: Action) {
	return userReducer(state, action);
}
