import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DragulaModule } from 'ng2-dragula';
import { IMaskModule } from 'angular-imask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { JobComponent } from './job.component';
import { JobChangeOrderSummaryComponent } from './jobChangeOrderSummary.component';
import { JobChangeOrderAddComponent } from './jobChangeOrderAdd.component';
import { JobChangeOrderEditComponent } from './jobChangeOrderEdit.component';
import { JobChangeOrderListComponent } from './jobChangeOrderList.component';
import { JobAddComponent } from './jobAdd.component';
import { JobEditComponent } from './jobEdit.component';
import { JobEditFormComponent } from './jobEditForm.component';
import { JobInvoiceAddComponent } from './jobInvoiceAdd.component';
import { JobInvoiceEditComponent } from './jobInvoiceEdit.component';
import { JobInvoiceListComponent } from './jobInvoiceList.component';
import { JobPayableAddComponent } from './jobPayableAdd.component';
import { JobPayableEditComponent } from './jobPayableEdit.component';
import { JobPayableListComponent } from './jobPayableList.component';
import { JobTimeEntryComponent } from './jobTimeEntry.component';
import { JobCostsComponent } from './jobCosts.component';
import { JobListComponent } from './jobList.component';
import { JobPurchaseOrderListComponent } from './jobPurchaseOrderList.component';
import { JobSummaryComponent } from './jobSummary.component';
import { JobWarrantyComponent } from './jobWarranty.component';
import { LaborCodesComponent } from './laborCodes.component';
import { JobDocumentsComponent } from './jobDocuments.component';
import { JobWindowComponent } from './jobWindow.component';
import { JobWorkOrderListComponent } from './jobWorkOrderList.component';
import { JobSearchComponent } from './jobSearch.component';

import { AppSettingsService } from '@/services/appSettings.service';

import { ChangeOrderModule } from '@/components/ChangeOrderComponent/changeOrder.module';
import { InvoiceModule } from '@/components/InvoiceComponent/invoice.module';
import { PayableModule } from '@/components/PayableComponent/payable.module';
import { WorkOrderModule } from '@/components/WorkOrderComponent/workOrder.module';
import { PurchaseOrderModule } from '@/components/PurchaseOrderComponent/purchaseOrder.module';
import { CostsModule } from '@/components/CostsComponent/costs.module';
import { FilterEntriesModule } from '@/components/FilterEntriesComponent/filterEntries.module';
import { FilterListModule } from '@/components/FilterListComponent/filterList.module';
import { MoveEntryModule } from '@/components/MoveEntryComponent/moveEntry.module';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { ModalModule } from '@/components/ModalComponent/modal.module';
import { UtilityModule } from '@/utils/utility.module';

@NgModule({
    declarations: [
        JobComponent,
        JobChangeOrderSummaryComponent,
        JobChangeOrderAddComponent,
        JobChangeOrderEditComponent,
        JobChangeOrderListComponent,
        JobAddComponent,
        JobEditComponent,
        JobEditFormComponent,
        JobInvoiceAddComponent,
        JobInvoiceEditComponent,
        JobInvoiceListComponent,
        JobPayableAddComponent,
        JobPayableEditComponent,
        JobPayableListComponent,
        JobTimeEntryComponent,
        JobCostsComponent,
        JobListComponent,
        JobPurchaseOrderListComponent,
        JobSummaryComponent,
        JobWarrantyComponent,
        LaborCodesComponent,
        JobDocumentsComponent,
        JobWindowComponent,
        JobWorkOrderListComponent,
        JobSearchComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        RouterModule,
        PurchaseOrderModule,
        WorkOrderModule,
        ChangeOrderModule,
        InvoiceModule,
        PayableModule,
        CostsModule,
        UtilityModule,
        FilterEntriesModule,
        FilterListModule,
        MoveEntryModule,
        InfiniteScrollModule,
        DocumentUploadModule,
        ModalModule,
        TypeaheadModule.forRoot(),
        DragulaModule.forRoot(),
        IMaskModule
    ],
    exports: [
        JobComponent,
        JobChangeOrderSummaryComponent,
        JobChangeOrderAddComponent,
        JobChangeOrderEditComponent,
        JobAddComponent,
        JobEditComponent,
        JobInvoiceAddComponent,
        JobInvoiceEditComponent,
        JobPayableAddComponent,
        JobPayableEditComponent,
        JobTimeEntryComponent,
        JobCostsComponent,
        JobSummaryComponent,
        JobWarrantyComponent,
        LaborCodesComponent,
        JobDocumentsComponent,
        JobWindowComponent,
        JobWorkOrderListComponent,
        JobSearchComponent
    ],
    providers: [
        AppSettingsService
    ]
})
export class JobModule {}
