import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { IChangeOrderModel } from '@/models/changeOrder.models';

@Component({
    selector: 'change-order-documents',
    templateUrl: './changeOrderDocuments.template.html',
    host: {'class': 'change-order-documents-component'}
})
export class ChangeOrderDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    private _customerId: string;
    private _jobId: string;
    // todo refactor or rename so public property isn't prefixed with _
    public _changeOrderId: string;
    public duplicateRecord: boolean = false;
    public model = new Subject<IChangeOrderModel>();
    private currentChangeOrder: any;
    private filesQueued: boolean = false;

    constructor(
        private route: ActivatedRoute, 
        messageService: MessageService, 
        private filesService: FilesService, 
        private state: StateManager
        ) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
        self._changeOrderId = routerUtils.getRouteParameter(self.route, 'changeOrderId');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public saveOrder(order): void {
        const self = this;
        self.filesService.saveFilesOrder('CO', self._changeOrderId, order);
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }

    public setMessage(e) {
        this.filesQueued = e;
    }
}
