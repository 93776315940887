import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { SupplierService } from '@/services/supplier.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { IPayableModel } from '@/models/payable.models';
import { ISupplierModel } from '@/models/supplier.models';
import { SupplierSortFunctions } from '@/utils/dataManipulationUtils';
import { PrintService } from '@/services/print.service';

@Component({
	selector: 'job-payable-edit',
	templateUrl: './jobPayableEdit.template.html',
	host: {'class': 'job-payable-edit-component'}
})
export class JobPayableEditComponent extends PrintableComponent implements OnInit {
  // todo refactor or rename so public property is not prefixed with _
  public _payableId: string;
  public model: Observable<IPayableModel>;
  public formChanged: boolean = false;
  public suppliers: Subject<Array<ISupplierModel>> = new Subject<Array<ISupplierModel>>();
  // todo validate this type is correct
  public suppliersCopy: Array<ISupplierModel>;
  public showAlert: boolean = false;
  private duplicatePayable: IPayableModel;

	private _customerId: string;
	private _jobId: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private state: StateManager,
		messageService: MessageService,
		private jobService: JobService,
		private supplierService: SupplierService,
		private supplierManager: DataManipulationService<ISupplierModel>,
		private printService: PrintService,
	) {
		super(router, printService, messageService);
	}

	ngOnInit(): void {
    this.model = this.state.getActivePayable().pipe(
      tap(payable => this._payableId = payable ? payable._id : this._payableId));

    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    this.printUrlBase = '/Print/Jobs/' + this._jobId + '/Payables/' + this._payableId;

    this.supplierManager.initialize({}, SupplierSortFunctions);
    this.watchSubscription(this.supplierManager.connectDataSource(this.state.getSuppliersList(), this.suppliers));
    this.supplierManager.setSort('name', false);
    // @ts-ignore
    this.supplierService.getSuppliers(0).then(data =>{ this.suppliersCopy = JSON.parse(JSON.stringify( data )); });

    // todo no action after subscribing?
    this.watchSubscription(this.state.getActiveJob().subscribe((s) => {}));

    this.printOptions.copies = [
      { index: 'projectManager', label: 'Control', enabled: false },
      { index: 'accounting', label: 'Accounting', enabled: false },
      { index: 'customer', label: 'Customer', enabled: false },
      { index: 'orderAndFile', label: 'Order and File', enabled: false },
      { index: 'woField', label: 'WO Field', enabled: false },
      { index: 'woTaggingAndDelivery', label: 'WO Tagging and Delivery', enabled: false }
    ];
  }

	public savePayable(newModel: IPayableModel): void {
    // remove the docs for the save and then add them back in after so view is updated
    let docs = newModel.documents;
    delete newModel.documents;

    this.jobService.updatePayable(this._customerId, this._jobId, newModel)
			.then((result) => {
        this.showAlert = false;
        this.jobService.getPayables(this._customerId, this._jobId);
        setTimeout(() => {
          if (docs) {
            newModel.documents = docs;
          }
        }, 0);
        this.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 2) });
			})
      .catch((err) => {
        let error = err.error;        
        if (error.message == "Duplicate Record") {
          this.duplicatePayable = error.record;
          this.showAlert = true;
        }
      });
    }

  public goToPayable(): void {
    this.router.navigate([this.duplicatePayable._id, 'Edit'], {
      relativeTo: routerUtils.getParentRoute(this.route, 2)
    });
    this.showAlert = false;
  }

	public setFormStatus(e: boolean): void {
		this.formChanged = e;
	}
}
