<div class="sidebar-sidetab-tabs">
	<nav id="job-status-tabs" class="sidebar-tabs-areas">
		<ul class="nav nav-tabs">
			<li>
				<a (click)="setSidebarType('Details')" [ngClass]="{'active':_sidebarType == 'Details' && currentView !== 'TimeEntry' && currentView !== 'Costs'}" title="Details"><i class="glyphicon glyphicon-search"></i></a>
			</li>
			<li *ngIf="isAdmin">
				<a (click)="setSidebarType('Costs')" routerLinkActive="active" title="Job costs"><i class="glyphicon glyphicon-tags"></i></a>
			</li>
			<li *ngIf="isAdmin">
				<a (click)="setSidebarType('TimeEntry')" routerLinkActive="active" title="Time entry"><i class="glyphicon glyphicon-time"></i></a>
			</li>
			<li *ngIf="appSettings?.showLaborCodes && hasLaborCodes">
				<a (click)="setSidebarType('LaborCode')" routerLinkActive="active" title="Labor Codes">&lt;/&gt;</a>
			</li>
			<li>
				<a (click)="setSidebarType('JobDocuments')" routerLinkActive="active" title="Documents"><i class="glyphicon glyphicon-file"></i></a>
			</li>
			<li>
				<a (click)="setSidebarType('Warranty')" routerLinkActive="active" title="Warranty information"><i class="glyphicon glyphicon-certificate"></i></a>
			</li>
		</ul>
	</nav>
</div>
<div class="sidebar-sidetab-main" [ngClass]="_sidebarListType">
	<div id="job-info" *ngIf="job">
		<header>
			<h5 *ngIf="customer._id">
				<a [routerLink]="['/Customers', customer._id, 'Edit', 'Details']">{{ customer.name }}</a>
			</h5>
			<h2 *ngIf="customer._id">
				<a [routerLink]="['/Customers', customer._id, 'Job', job._id, 'Edit']">{{ job.name }} - {{ job.number }}</a>
			</h2>

			<div id="job-flags">
				<div class="white" *ngIf="job.isWhite">
					<i class="glyphicon glyphicon-asterisk" title="White"></i>
				</div>
				<div class="tax-exempt" *ngIf="job.isTaxExempt">
					<i class="glyphicon glyphicon-usd" title="Tax Exempt"></i>
				</div>
				<div class="closed" *ngIf="job.isClosed">
					<i class="glyphicon glyphicon-remove" title="Closed"></i>
				</div>
			</div>

			<div *ngIf="!showDetails" id="job-summary-details">
				<h4>Details</h4>
				<label>Customer Contact</label><span>{{ customerContactName }}</span><br>
				<label>Project Manager</label><span>{{ projectManagerName }}</span><br>
				<label>Contract Amount</label><span>{{job.contractAmount | currencyFormat}}</span><br>
				<label>Start Date</label><span>{{job.startDate | dateFormat:'MMM DD, YYYY' }}</span><br>
				<label>Completed Date</label><span>{{job.completedDate | dateFormat:'MMM DD, YYYY' }}</span><br>

				<hr>

				<div id="bids">
					<h4>Bids</h4>
					<label>Shop Labor</label><span class="ml-3">{{job.bidAmounts.shopLabor | number:'1.2-2'  }}</span><br>
					<label>Field Labor</label><span class="ml-3">{{job.bidAmounts.fieldLabor | number:'1.2-2' }}</span><br>
					<div><label>Materials</label></div>
					<label class="ml-3 text-right">Aluminum</label><span>{{job.bidAmounts.materialCosts.aluminum | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Glass</label><span>{{job.bidAmounts.materialCosts.glass | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Sealants</label><span>{{job.bidAmounts.materialCosts.sealants | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Brake metal</label><span>{{job.bidAmounts.materialCosts.brakeMetal | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Other</label><span>{{job.bidAmounts.materialCosts.other | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Total</label><span>{{((job.bidAmounts.materialCosts.aluminum + job.bidAmounts.materialCosts.glass + job.bidAmounts.materialCosts.sealants + job.bidAmounts.materialCosts.brakeMetal + job.bidAmounts.materialCosts.other) || 0) | currencyFormat}}</span><br>
					<div><label>Outside services</label></div>
					<label class="ml-3 text-right">Equipment</label><span>{{job.bidAmounts.otherCosts.equipment | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Engineering</label><span>{{job.bidAmounts.otherCosts.engineering | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Subcontractor</label><span>{{job.bidAmounts.otherCosts.subcontractor | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Other</label><span>{{job.bidAmounts.otherCosts.other | currencyFormat}}</span><br>
					<label class="ml-3 text-right">Total</label><span>{{((job.bidAmounts.otherCosts.equipment + job.bidAmounts.otherCosts.engineering + job.bidAmounts.otherCosts.subcontractor + job.bidAmounts.otherCosts.other) || 0) | currencyFormat}}</span><br>
				</div>

				<hr>

				<h4>Notes</h4>
				<p class="readonly-multiline">{{job.notes}}</p>
			</div>


		</header>
	</div>

	<section id="job-navi" *ngIf="showDetails && job">
		<section class="sidebar-tabs">
			<nav id="job-tabs" class="sidebar-tabs-main">
				<ul class="nav nav-tabs">
					<li class="col-xs-12 col-md-4 pl-0 pr-0"><a (click)="setSidebarListType('WorkOrders')" [ngClass]="{'active':_sidebarListType == 'WorkOrders'}" title="Work orders" id="workOrdersLabel">Work Orders</a></li>
					<li class="col-xs-12 col-md-4 pl-0 pr-0"><a (click)="setSidebarListType('PurchaseOrders')" [ngClass]="{'active':_sidebarListType == 'PurchaseOrders'}" title="Purchase orders" id="purchaseOrdersLabel">Purchase Orders</a></li>
					<li class="col-xs-12 col-md-4 pl-0 pr-0"><a (click)="setSidebarListType('ChangeOrders')" [ngClass]="{'active':_sidebarListType == 'ChangeOrders'}" title="Change orders" id="changeOrdersLabel">Change Orders</a></li>
					<li class="col-xs-12 col-md-6 pl-0 pr-0"><a (click)="setSidebarListType('Invoices')" [ngClass]="{'active':_sidebarListType == 'Invoices'}" title="Invoices" id="receivablesLabel">Receivables</a></li>
					<li class="col-xs-12 col-md-6 pl-0 pr-0"><a (click)="setSidebarListType('Payables')" [ngClass]="{'active':_sidebarListType == 'Payables'}" title="Payables" id="payablesLabel">Payables</a></li>
				</ul>
			</nav>
		</section>

		<div id="job-lists" [ngSwitch]="_sidebarListType" *ngIf="showDetails">
			<job-work-order-list *ngSwitchCase="'WorkOrders'" [model]="workOrders" [dataManager]="workOrderManager" (addClicked)="addWorkOrder()" (itemSelected)="workOrderSelected($event)" [@fadeInOut]></job-work-order-list>
			<job-purchase-order-list *ngSwitchCase="'PurchaseOrders'" [model]="purchaseOrders" [dataManager]="purchaseOrderManager" (addClicked)="addPurchaseOrder()" (itemSelected)="purchaseOrderSelected($event)" [@fadeInOut]></job-purchase-order-list>
			<job-change-order-list *ngSwitchCase="'ChangeOrders'" [model]="changeOrders" [dataManager]="changeOrderManager" (addClicked)="addChangeOrder()" (itemSelected)="changeOrderSelected($event)" [@fadeInOut]></job-change-order-list>
			<job-invoice-list *ngSwitchCase="'Invoices'" [model]="invoices" [dataManager]="invoiceManager" [projectManager]="job.projectManager" [job]="job" (addClicked)="addInvoice()" (itemSelected)="invoiceSelected($event)" [@fadeInOut]></job-invoice-list>
			<job-payable-list *ngSwitchCase="'Payables'" [model]="payables" [dataManager]="payableManager" (addClicked)="addPayable()" (itemSelected)="payableSelected($event)" [projectManager]="job.projectManager" [@fadeInOut]></job-payable-list>
		</div>
	</section>
</div>
