<div class="work-order-parts-list">
    <table class="table" aria-describedby="partsLabel">
        <thead>
            <tr>
                <th scope="col" class="currency-display" style="width:100px; text-align: left!important;">
                    <sortable-header field="quantity" label="Qty" [sortManager]="dataManipulationService" (onSort)="clearSelected()"></sortable-header>
                </th>
                <th scope="col" style="width:100px;">
                    <sortable-header field="unit" label="Unit" [sortManager]="dataManipulationService" (onSort)="clearSelected()"></sortable-header>
                </th>
                <th scope="col">
                    <sortable-header field="description" label="Description" [sortManager]="dataManipulationService" (onSort)="clearSelected()"></sortable-header>
                </th>
                <th scope="col" style="width:160px;">
                    <sortable-header field="cost" label="Cost" [sortManager]="dataManipulationService" (onSort)="clearSelected()"></sortable-header>
                </th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="no-hover" keyboardAccelerator="13" (onKeyboardAction)="savePart(newPart)" keyboardExcludeId="createPartButton">
                <td scope="row">
                    <input autocomplete="off" class="form-control" type="number" min="0" [(ngModel)]="newPart.quantity" initialFocus #newPartQuantity />
                </td>
                <td scope="row">
                    <input autocomplete="off" class="form-control" type="text" [(ngModel)]="newPart.unit" />
                </td>
                <td scope="row">
                    <textarea rows="3" class="form-control" #description="ngModel" [(ngModel)]="newPart.description" required="required" data-keyboard-exclude></textarea>
                </td>
                <td scope="row">
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input autocomplete="off" class="form-control" type="text" [(ngModel)]="newPart.cost" enforceDecimal="true" placeholder="0.00" />
                    </div>
                </td>
                <td scope="row" class="actions">
                    <button type="button" class="btn btn-primary" [disabled]="!description.valid" (click)="savePart(newPart)" data-keyboard-exclude>Add Part</button>
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="model.getValue().length" class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">List of parts</caption>
            <thead>
                <tr>
                    <th scope="col" style="width:85px;"></th>
                    <th scope="col" style="width:80px;"></th>
                    <th scope="col" style="width:100px;"></th>
                    <th scope="col" style="width:160px;"></th>
                    <th scope="col"></th>
                    <th scope="col" style="width:200px;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="model.value.length" class="select-all">
                    <td scope="row" colspan="6">
                        <button type="button" class="btn btn-default btn-checkbox mr-1" (click)="toggleSelectAll(); $event.stopPropagation()" [ngClass]="{ 'active btn-primary': selectAll }"><span [ngClass]="{ 'hidden': selectAll }">Select All</span><span [ngClass]="{ 'hidden': !selectAll }">Unselect All</span></button>
                        <button type="button" class="btn btn-default" (click)="createPurchaseOrder()" [disabled]="_selectedParts.length < 1" [ngClass]="{ 'active btn-primary': _selectedParts.length > 0 }" title="Create purchase order from selected parts">+PO</button>
                    </td>
                </tr>
                <tr *ngFor="let part of model | async; let partIndex = index" (click)="editPart(part, $event)" [ngClass]="{'editPart': part._id === editingPartId }" #editPartRow keyboardAccelerator="13" (onKeyboardAction)="savePart(part)">
                    <ng-template [ngIf]="part._id === editingPartId">
                        <td scope="row" style="width:85px;"></td>
                        <td scope="row">
                            <input autocomplete="off" id="editPartQuantity" class="form-control" type="number" min="0" [(ngModel)]="part.quantity" />
                        </td>
                        <td scope="row">
                            <input autocomplete="off" class="form-control" type="text" [(ngModel)]="part.unit" />
                        </td>
                        <td scope="row">
                            <div class="input-group">
                                <span class="input-group-addon">$</span>
                                <input autocomplete="off" class="form-control" type="text" [(ngModel)]="part.cost" enforceDecimal="true"/>
                            </div>
                        </td>
                        <td scope="row">
                            <textarea rows="3" autocomplete="off" class="form-control" #editDescription="ngModel" [(ngModel)]="part.description" required="required" data-keyboard-exclude></textarea>
                        </td>
                        <td scope="row" class="actions top-vertical">
                            <button type="button" class="btn btn-danger mb-1" (click)="deletePart(part, $event)" data-keyboard-exclude><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
                            <button type="button" class="btn btn-default ml-1 mb-1" (click)="cancel($event)" data-keyboard-exclude>Cancel</button>
                            <button type="button" class="btn btn-primary ml-1 mb-1" [disabled]="!editDescription.valid" (click)="savePart(part)" data-keyboard-exclude>Save</button>
                        </td>
                    </ng-template>
                    <ng-template [ngIf]="part._id !== editingPartId">
                        <td scope="row" class="center-vertical">
                            <button type="button" class="btn btn-default btn-checkbox" (click)="updateSelected(part._id); $event.stopPropagation()" [ngClass]="{ 'active btn-primary': _selectedParts.indexOf(part._id) > -1 }"><i class="glyphicon glyphicon-ok"></i></button>
                        </td>
                        <td scope="row" class="center-vertical">
                            {{ part.quantity }}
                        </td>
                        <td scope="row" class="center-vertical">
                            {{ part.unit }}
                        </td>
                        <td scope="row" class="currency-display center-vertical">
                            <span *ngIf="part.cost">{{ part.cost | currencyFormat:true }}</span>
                        </td>
                        <td scope="row" class="center-vertical">
                            <div class="readonly-multiline">{{ part.description }}</div>
                        </td>
                        <td scope="row" class="actions center-vertical">
                            <!-- <button type="button" class="btn btn-default mr-1" (click)="copyPart(part, $event)"><i class="glyphicon glyphicon-duplicate" title="Copy part"></i></button> -->
                            <button type="button" class="btn btn-default" (click)="editPart(part, $event)">Edit</button>
                        </td>
                    </ng-template>
                </tr>
            </tbody>
        </table>
    </div>

</div>
