/** A filter value stores a filter name and a value for that filter. */
export interface FilterValue {
  filter: string;
  id: string;
}

/** Description of the filter that is to be added to the list of filters */
export interface FilterDescription {
  type: string;
  label: string;
  options: FilterOption[];
  preFilter?: any;
}

/** A filter option is */
export interface FilterOption {
  text: string;
  id: string;
}

export type SortOptions = { direction: number; field: string; };
export type ParsedHistory = {
  filters: any[];
  searchTerm?: string;
  showClosed?: boolean;
  sort?: SortOptions;
  location?: string;
};

export function parseSearchFilterHistory(history: any): ParsedHistory {
  let result: ParsedHistory = { filters: [] };
  result.filters = Object.keys(history)
    .filter(k => k !== 'navigationId')
    .map(i => history[i])
    .filter(f => f.id)
    .filter(f => {
      if (f.filter === 'includeClosedJobsFilter') {
        result.showClosed = f.id;
        return false;
      }

      if (f.filter === 'locationFilter') {
        result.location = f.id;
        return false;
      }

      if (f.filter === 'searchTerm') {
        result.searchTerm = f.id;
        return false;
      }

      if (f.filter === 'sort') {
        result.sort = {
          field: f.id,
          direction: f.direction || 1,
        };
        return false;
      }
      return true;
    });
  return result;
}
