import * as Utility from './utility.models';
import * as dataUtils from './../utils/dataUtils';

export interface IReceiptModel extends Utility.BaseModel {
    date : Date;
    quantity : number;
    location : string;
    imageUrl : string;
    isDamaged : boolean;
    notes : string;
    part : Utility.IBackReference | string;
};

export class ReceiptModel extends Utility.BaseModel implements IReceiptModel {
    date : Date = dataUtils.sanitizeDate();
    quantity : number = null;
    location : string = null;
    imageUrl : string = null;
    isDamaged : boolean = false;
    notes : string = null;
    part : Utility.IBackReference | string = null;
};

// for printing WO, PO summaries on WO
export interface IReceiptAggregateModel {
    quantityOrdered: number;
    unit: string;
    quantityReceived: number;   // total number received
    description: string;
    date: Date;                 // date of the last receipt
    location: string;           // location of the last receipt
};

// for PO receipts
export interface IReceiptPartModel {
    _id: string;
    quantityOrdered: number;
    quantityBackorder: number;
    unit: string;
    description: string;
    quantityReceived: number;
    isDamaged: boolean;
    location: string;
    notes: string;
    timestamp: Date;
};
