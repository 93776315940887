<header>
    <div class="row">
        <div class="col-xs-12 col-md-8">
            <search [(ngModel)]="filterTerm" placeholder="Filter payables" filterData="text" [filterManager]="dataManager"></search>
        </div>
        <div class="col-xs-12 col-md-4">
            <button *ngIf="!permissionLock" type="button" class="btn btn-block btn-default" (click)="addClicked.emit()">Add payable</button>
        </div>
    </div>
</header>
<payable-list [model]="model | async" [showIndicator]="true" (onPayableSelect)="itemSelected.emit($event)" [selectedPayableId]="selectedPayableId"></payable-list>
