import { Routes } from '@angular/router';

import { PurchaseOrderComponent } from './purchaseOrder.component';
import { PurchaseOrderAddComponent } from './purchaseOrderAdd.component';
import { PurchaseOrderEditComponent } from './purchaseOrderEdit.component';
import { PurchaseOrderConnectionComponent } from './purchaseOrderConnection.component';
import { PurchaseOrderInvoiceConnectionComponent } from './purchaseOrderInvoiceConnection.component';
import { PurchaseOrderPayableTabComponent } from './purchaseOrderPayableTab.component';
import { PurchaseOrderPartsComponent } from './purchaseOrderParts.component';
import { PurchaseOrderReceiptsComponent } from './purchaseOrderReceipts.component';
import { PurchaseOrderDocumentsComponent } from './purchaseOrderDocuments.component';
import { MsalGuard } from '@azure/msal-angular';

export const PURCHASEORDER_ROUTES: Routes = [
  {
    path: 'Add',
    component: PurchaseOrderAddComponent,
  },
  {
    path: ':purchaseOrderId',
    canActivate: [MsalGuard],
    component: PurchaseOrderComponent,
    children: [
      {
        path: '',
        redirectTo: 'Edit',
        pathMatch: 'prefix',
      },
      {
        path: 'Edit',
        component: PurchaseOrderEditComponent,
      },
      {
        path: 'WorkOrder',
        component: PurchaseOrderConnectionComponent,
      },
      {
        path: 'Invoice',
        component: PurchaseOrderInvoiceConnectionComponent,
      },
      {
        path: 'Payables',
        component: PurchaseOrderPayableTabComponent,
      },
      {
        path: 'Parts',
        component: PurchaseOrderPartsComponent,
      },
      {
        path: 'Receipts',
        children: [
          {
            path: '',
            component: PurchaseOrderReceiptsComponent,
          },
          {
            path: ':receiptId',
            component: PurchaseOrderReceiptsComponent,
          },
        ],
      },
      {
        path: 'Documents',
        component: PurchaseOrderDocumentsComponent,
      },
    ],
  },
];
