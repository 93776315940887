<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title">
    Daily Cost Journal
</div>

<div class="print-hide report-controls pb-1">
    <div class="col-xs-12 col-md-9">
        <input autocomplete="off" class="form-control mr-1 mb-1" type="date" [ngModel]="date | dateFormat" (ngModelChange)="date = sanitizeDate($event); markDirty()" required="required" #reportDate="ngModel" style="display: inline-block;" initialFocus />

        <div class="btn-group mr-1">
            <a [routerLink]="['.']" [queryParams]="{ reportType: 'materials' }" class="btn btn-default" [ngClass]="{ 'active btn-primary': reportType === 'materials' }"><i class="glyphicon glyphicon-align-justify"></i> Materials</a>
            <a [routerLink]="['.']" [queryParams]="{ reportType: 'outsideServices' }" class="btn btn-default" [ngClass]="{ 'active btn-primary': reportType === 'outsideServices' }"><i class="glyphicon glyphicon-user"></i> Outside Services</a>
        </div>

        <button class="btn btn-default mt-0" [ngClass]="{ 'active btn-primary': includeClosed }" (click)="toggleIncludeClosed()"><i class="glyphicon glyphicon-lock"></i> Include closed jobs</button>
    </div>
    <div class="col-xs-12 col-md-3 actions">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty && reportDate">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <table class="table table-select-list mb-0">
        <caption>
            Daily cost entries for {{ date | dateFormat:'MMM DD, YYYY' }}

            <span *ngIf="reportType === 'materials'">
                - materials
            </span>

            <span *ngIf="reportType === 'outsideServices'">
                - outside services
            </span>

            <span *ngIf="includeClosed">
                - including closed jobs
            </span>
        </caption>
        <thead>
            <tr>
                <th scope="col" class="list-item-flag"></th>
                <th scope="col">
                    <sortable-header field="jobNumber" label="Job #" [sortManager]="dataManipulationService"></sortable-header><span style="padding:0 17px;">/</span>
                    <sortable-header field="jobName" label="Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:110px;">
                    <sortable-header field="cost" label="Cost" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:110px;">
                    <sortable-header field="countyTax" label="County Tax" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:110px;">
                    <sortable-header field="mkecountyTax" label="Milwaukee County Tax" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:110px;">
                    <sortable-header field="stateTax" label="State Tax" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:110px;">
                    <sortable-header field="cityTax" label="City Tax" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" *ngIf="hasStadiumTax" class="currency-display" style="width:110px;">
                    <sortable-header field="stadiumTax" label="Stadium Tax" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="currency-display" style="width:150px;">
                    <sortable-header field="total" label="Total" [sortManager]="dataManipulationService"></sortable-header>
                </th>
            </tr>
        </thead>
    </table>

    <div class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">List of costs grouped by project manager</caption>
            <thead class="column-spacer">
                <tr>
                    <th scope="col" class="list-item-flag"></th>
                    <th scope="col"></th>
                    <th scope="col" style="width:110px;"></th>
                    <th scope="col" style="width:110px;"></th>
                    <th scope="col" style="width:110px;"></th>
                    <th scope="col" style="width:110px;"></th>
                    <th scope="col" style="width:110px;"></th>
                    <th scope="col" *ngIf="hasStadiumTax" style="width:110px;"></th>
                    <th scope="col" style="width:150px;"></th>
                </tr>
            </thead>
            <tbody *ngFor="let cost of model | async">
                <tr>
                    <td scope="row" class="list-item-flag">
                        <span [innerHtml]="cost.jobIsClosed | booleanIcon:'lock'" title="Closed"></span>
                    </td>
                    <td scope="row">
                        <a (click)="editJob(cost)">{{ cost.jobNumber }} - {{ cost.jobName }}</a>
                    </td>
                    <td scope="row" class="currency-display">
                        {{ cost.cost | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ cost.countyTax | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ cost.mkecountyTax | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ cost.stateTax | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ cost.cityTax | currencyFormat }}
                    </td>
                    <td scope="row" *ngIf="hasStadiumTax" class="currency-display">
                        {{ cost.stadiumTax | currencyFormat }}
                    </td>
                    <td scope="row" class="currency-display">
                        {{ (cost.cost + (cost.cityTax ? cost.cityTax : 0) + cost.countyTax + (cost.mkecountyTax ? cost.mkecountyTax : 0) + cost.stadiumTax + cost.stateTax) | currencyFormat }}
                    </td>
                </tr>
                <tr>
                    <td scope="row" colspan="4" style="border-top:none;">
                        <div *ngIf="cost.purchaseOrderId" class="small text-uppercase">Purchase Order</div>
                        <a (click)="editPurchaseOrder(cost)">
                            {{ cost.purchaseOrderId ? (cost.purchaseOrderNumber + (cost.purchaseOrderName ? ' - ' + cost.purchaseOrderName : '' )) : 'No PO' }}
                        </a> - {{ cost.supplier ? cost.supplier : 'no supplier' }} - {{ cost.invoiceNumber ? 'Invoice: ' + cost.invoiceNumber : 'No invoice' }}
                    </td>
                    <td scope="row" *ngIf="hasStadiumTax" style="border-top:0"></td>
                    <td scope="row" colspan="4" style="border-top:none;">
                        <div class="readonly-multiline">{{ cost.notes }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <table class="table select-list report-total" style="margin-top:.5rem;">
        <caption class="sr-only">Report totals</caption>
        <thead class="spacer">
            <tr>
                <th scope="col"></th>
                <th scope="col" style="width:110px;"></th>
                <th scope="col" style="width:110px;"></th>
                <th scope="col" style="width:110px;"></th>
                <th scope="col" style="width:110px;"></th>
                <th scope="col" style="width:110px;"></th>
                <th scope="col" *ngIf="hasStadiumTax" style="width:110px;"></th>
                <th scope="col" style="width:150px;"></th>
            </tr>
        </thead>
        <tfoot>
            <tr class="list-item-group">
                <th scope="col" class="no-wrap">
                    {{ entryCount }} jobs
                </th>
                <th scope="col" class="currency-display">
                    {{ costTotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ countyTaxTotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ mkecountyTaxTotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ stateTaxTotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ cityTaxTotal | currencyFormat }}
                </th>
                <th scope="col" *ngIf="hasStadiumTax" class="currency-display">
                    {{ stadiumTaxTotal | currencyFormat }}
                </th>
                <th scope="col" class="currency-display">
                    {{ grandTotal | currencyFormat }}
                </th>
            </tr>
        </tfoot>
    </table>
</div>
