import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'monthpicker',
  template: `<div>
  <input class="form-control combo-date" name="month" type="text" [(ngModel)]="selectedDate" [disabled]="disabled" tabindex="25"/>
  </div>
  <div class="date-selector-overlay" style="position: relative;">
  <div class="month-selector" (click)="toggleCalendar()" [ngClass]="{'disabled': disabled}"><i class="glyphicon glyphicon-calendar"></i></div>
  </div>

  
  <div *ngIf="showCalendar" class="month-picker">
    <div class="">
      <div class="header">
          <div class="d-flex align-items-center">
              
            <button type="button" class="btn mr-auto" (click)="decrement()">
                <i class="glyphicon glyphicon-chevron-left"></i>
                </button>
                    
            <button type="button" class="btn" (click)="toggleShowYears()">
                {{model.selectedYearText}}
            </button>

            <button type="button" class="btn ml-auto" (click)="increment()">
                <i class="glyphicon glyphicon-chevron-right"></i>
            </button>

          </div> 
      </div>

      <div class="body">
          <div class="month-picker-body">

              <div class="" *ngIf="!isShowYears">
                  <div class="col col-4" *ngFor="let month of model.months; let i = index">
                      <div class="month-picker-cell">
                        <button type="button" class="btn btn-block" [ngClass]="{'btn-primary': isSelectedMonth(i)}" [disabled]="isDisabled(i)" (click)="selectMonth(i)">
                            <small>{{month}}</small>
                        </button>
                      </div>
                  </div>
              </div>

                <div class="" *ngIf="isShowYears">
                  <div class="col col-4" *ngFor="let year of years; let i = index">
                      <div class="month-picker-cell">
                        <button type="button" class="btn btn-block" [ngClass]="{'btn-primary': isSelectedYear(year)}" [disabled]="isDisabledYear(year)" (click)="selectYear(year)">
                            <small>{{year}}</small>
                        </button>
                      </div>
                  </div>
              </div>

          </div>
      </div>

  </div>
</div>`,
host: {'class': 'month-picker-component'}
})
export class MonthpickerComponent implements OnInit {

  @Input() locale: string;
  @Input() year: number;
  @Input() month: number;

  @Input() enabledMonths: Array<number> = [];
  @Input() disabledMonths: Array<number> = [];

  @Input() enabledYears: Array<number> = [];
  @Input() disabledYears: Array<number> = [];
 
  @Input() multiple: boolean; // TODO
    @Input()
    id: string;
  @Input()
  disabled: boolean;
  
  @Output() setMonth = new EventEmitter<{ monthIndex: number, year: number }>();

  model: MonthPickerModel;
  showCalendar = false;
  selectedDate: any = null;

  ngOnInit() {
    if (this.locale) {
      moment.locale(this.locale);
    } else {
      moment.locale('us');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['id']) {

        if(!this.model){
            this.model = new MonthPickerModel();
        }
        if (this.year) {
            this.model.selectedYearMoment = moment().year(this.year);
            this.model.updateYearText();
        } else {
            this.year = null;
        }

        if (this.month !== null) {
            this.model.selectedMonthIndex = this.month;
            this.model.selectedMonthMoment = moment().month(this.month);
            if (this.year) this.model.selectedMonthYear = this.year;
        } else {
            this.month = null;
        }
        this.onChange(this.model.selectedMonthIndex,this.model.selectedMonthYear);
    }
  }
  decrement() {
    this.model.decrementYear();
    if (this.isShowYears) {
      this.renderYears();
    }
  }

  increment() {
    this.model.incrementYear();
    if (this.isShowYears) {
      this.renderYears();
    }
  }

  selectMonth(index: number) {
    this.model.selectMonth(index);
    this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
  }

  isSelectedMonth(monthIndex: number) {
    return this.model.selectedMonthIndex == monthIndex && this.model.selectedMonthYear == this.model.selectedYearMoment.year();
  }

  onChange(monthIndex: number, year: number) {
    this.showCalendar = false;
    this.selectedDate = moment().month(monthIndex).year(year).format('MMM, YYYY');
    this.setMonth.emit(this.selectedDate);
  }

  isDisabled(index: number) {
    let disabled = false;
    if (this.enabledMonths && this.enabledMonths.length > 0) {
      disabled = this.enabledMonths.indexOf(index) < 0;
    }
    if (this.disabledMonths && this.disabledMonths.length > 0) {
      disabled = this.disabledMonths.indexOf(index) >= 0;
    }
    return disabled;
  }

  isShowYears: boolean;
  years: Array<number> = [];
  toggleShowYears() {
    this.isShowYears = !this.isShowYears;
    this.renderYears();
  }

  renderYears() {
    this.years = [];
    for (let i = 5; i > 0; i--) {
      this.years.push(this.model.selectedYearMoment.year() - i);
    }
    for (let i = 0; i <= 6; i++) {
      this.years.push(this.model.selectedYearMoment.year() + i);
    }
  }

  selectYear(year: number) {
    this.isShowYears = false;
    this.model.selectedYearMoment = moment().year(year);
    this.model.updateYearText(); // in set get aendern
  }

  isSelectedYear(year: number){
    return this.model.selectedYearMoment.year() === year;
  }

  isDisabledYear(year: number) {
    // console.warn(year)
     let disabled = false;
    if (this.enabledYears && this.enabledYears.length > 0) {
      disabled = this.enabledYears.findIndex(y => y === year) < 0;
    }
    if (this.disabledYears && this.disabledYears.length > 0) {
      disabled = this.disabledYears.findIndex(y => y === year) >= 0;
    }
    return disabled;
  }

  public toggleCalendar(){
    this.showCalendar = !this.showCalendar;
  }

}

export class MonthPickerModel {
  constructor() {
    this.selectedYearMoment = moment();
    this.updateYearText();

    this.selectedMonthMoment = moment();

    this.months = moment.months();

    this.selectedMonthIndex = this.selectedMonthMoment.month();
    this.selectedMonthYear = this.selectedYearMoment.year();
  }

  selectedYearMoment: moment.Moment;
  selectedYearText: string;

  selectedMonthMoment: moment.Moment;
  selectedMonthIndex: number;
  selectedMonthYear: number;

  months: Array<string> = [];

  updateYearText() {
    this.selectedYearText = moment(this.selectedYearMoment).format('YYYY');
  }

  selectMonth(index: number) {
    this.selectedMonthMoment = moment().month(index);
    this.selectedMonthIndex = this.selectedMonthMoment.month();
    this.selectedMonthYear = this.selectedYearMoment.year();
  }

  incrementYear() {
    this.selectedYearMoment = this.selectedYearMoment.add(1, "year")
    this.updateYearText();
  }

  decrementYear() {
    this.selectedYearMoment = this.selectedYearMoment.subtract(1, "year")
    this.updateYearText();
  }

}
