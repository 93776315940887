<nav>
    <ul class="nav nav-tabs"s>
        <li>
            <a [routerLink]="['Edit']" routerLinkActive="active">Details</a>
        </li>
        <li>
            <a [routerLink]="['Parts']" routerLinkActive="active">Parts</a>
        </li>
		<li>
            <a [routerLink]="['Receipts']" routerLinkActive="active">Receipts</a>
        </li>
		<li>
			<a [routerLink]="['WorkOrder']" routerLinkActive="active">Work Order</a>
		</li>
		<li>
			<a [routerLink]="['Invoice']" routerLinkActive="active">Invoice</a>
		</li>
		<li>
			<a [routerLink]="['Payables']" routerLinkActive="active">Payables</a>
		</li>
		<li>
            <a [routerLink]="['Documents']" routerLinkActive="active">Documents</a>
        </li>
        <li>
            <export [exportOptions]="exportOptions"></export>
        </li>
    </ul>
</nav>

<print-menu [printOptions]="printOptions" (onPrint)="print()" (setPrintedFlag)="setPrintedFlag()"></print-menu>

<router-outlet></router-outlet>
