import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPurchaseOrderPartModel, PurchaseOrderPartModel } from '@/models/part.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { PurchaseOrderPartSortFunctions } from '@/utils/dataManipulationUtils';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order-parts',
  templateUrl: './purchaseOrderParts.template.html',
  host: {'class': 'work-order-parts-component'}
})
export class PurchaseOrderPartsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
  public model: BehaviorSubject<Array<IPurchaseOrderPartModel>> = new BehaviorSubject<Array<IPurchaseOrderPartModel>>(null);
  public purchaseOrder: IPurchaseOrderModel;
  public newPart: IPurchaseOrderPartModel = new PurchaseOrderPartModel();

  private _customerId: string;
  private _jobId: string;
  private _purchaseOrderId: string;
  private _purchaseOrderBaseRoute: ActivatedRoute;
  private editingPartId: string;
	private saved: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    public dataManipulationService: DataManipulationService<IPurchaseOrderPartModel>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');
    this._purchaseOrderBaseRoute = routerUtils.getParentRoute(this.route);

    this.state.getActivePurchaseOrder().subscribe((po) => {
      this.purchaseOrder = po;
    });

    this.dataManipulationService.initialize({}, PurchaseOrderPartSortFunctions);
    this.dataManipulationService.setSort('sortOrder');
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getPurchaseOrderPartsList(), this.model));
    this.reloadParts();
    this.saved.next('false');
  }

  override ngOnDestroy(): void {
    this.state.setPurchaseOrderPartsList(new Array<IPurchaseOrderPartModel>());
    super.ngOnDestroy();
  }

  public savePart(part: IPurchaseOrderPartModel): void {
    // prevent non UI saves (i.e. keyboard shortcut) from skipping validation
    if (!part.description || (part.description.length < 1)) {
        return;
    }

    this.purchaseOrderService.updatePart(this._customerId, this._jobId, this._purchaseOrderId, part)
      .then(() => {
        this.reloadParts();
        if (part === this.newPart) {
          this.newPart = new PurchaseOrderPartModel();
        }
      })
      .catch(handleError);
  }

  private reloadParts(): void {
    this.editingPartId = null;
    this.purchaseOrderService.getParts(this._customerId, this._jobId, this._purchaseOrderId);
  }

  private cancel(event: Event): void {
    this.reloadParts();
    event.stopPropagation();
  }

  private editPart(part: IPurchaseOrderPartModel, event: Event): void {
    // todo are parts in an active purchase order editable?
    this.editingPartId = part._id;
    if (event) {
      event.stopPropagation();
    }
  }

  private deletePart(part: IPurchaseOrderPartModel, event: Event): void {
    if (this.warnOnDelete('part')) {
      this.purchaseOrderService.deletePart(this._customerId, this._jobId, this._purchaseOrderId, part._id)
        .then(() => this.reloadParts())
        .catch(handleError);
    }
    event.stopPropagation();
  }

  private saveNotes(notes: string, event: Event): void {
    delete this.purchaseOrder['documents'];
		// remove if they exist on old POs
		// @ts-ignore
		delete this.purchaseOrder.payable;
		// @ts-ignore
		delete this.purchaseOrder.payables;
    this.purchaseOrderService.updatePurchaseOrder(this._customerId, this._jobId, this.purchaseOrder);
    this.saved.next('true');
  }
}
