import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilityModule } from '@/utils/utility.module';
import { DocumentUploadComponent } from './documentUpload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
    declarations: [
        DocumentUploadComponent
    ],
    imports: [
        CommonModule,
        UtilityModule,
		FileUploadModule,
        DragulaModule.forRoot()
    ],
    exports: [
        DocumentUploadComponent
    ]
})
export class DocumentUploadModule {}
