import * as Part from './part.models';
import * as PMNote from './pmNote.models';
import { IJobBackref } from './purchaseOrder.models';
import { BaseModel, EmptyBackReference, IBackReference, ISearchRequest } from './utility.models';
import { WorkflowStatus } from '@/models/workflow.models';

export interface IWorkOrderModel extends BaseModel {
	_id: string;
	name: string;
	number: string;
	date: Date;
	datePromised: Date;
	notes: string;
	projectManager: IBackReference | string;
	job: IJobBackref | string;
	customer: IBackReference | string;
	parts: Array<Part.IWorkOrderPartModel>;
	pmNotes: Array<PMNote.IPMNoteModel>;
	lifecycle: Array<WorkflowStatus>;
	priority: string;
	documents: Object;
	status: WorkflowStatus;
	css: string;
}

export interface IWorkOrderSearchRequest extends ISearchRequest {
	locationId: string;
}

// <editor-fold> Document models

export class WorkOrderModel extends BaseModel implements IWorkOrderModel {
	override _id: string = null;
	name: string = null;
	number: string = null;
	date: Date = null;
	datePromised: Date = null;
	notes: string = null;
	projectManager: IBackReference = EmptyBackReference;
	job: IJobBackref = { _id: null, name: null, customer: null };
	customer: IBackReference | string = null;
	parts: Array<Part.IWorkOrderPartModel> = new Array<Part.IWorkOrderPartModel>();
	pmNotes: Array<PMNote.IPMNoteModel> = new Array<PMNote.IPMNoteModel>();
	priority: string = 'None';
	documents: Object;
	lifecycle: Array<WorkflowStatus>;
	status: WorkflowStatus;
	css: string;

	constructor() {
		super();

		// trim read-only values
		delete this.customer;
	}
}

export function isIWorkOrder(obj: any): obj is IWorkOrderModel {
	return 'projectManager' in obj && 'pmNotes' in obj;
}

// </editor-fold>
