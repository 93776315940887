import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';
import { AppSettingsService } from '@/services/appSettings.service';

import { IContactModel } from '@/models/contact.models';
import { IJobModel } from '@/models/job.models';
import { IUserModel } from '@/models/user.models';

@Component({
    selector: 'job-edit',
    templateUrl: './jobEdit.template.html',
    host: {'class': 'job-edit-component'}
})

export class JobEditComponent extends BaseSmartComponent implements OnInit {
    private _id : string;
    private _customerId : string;

    public model : Observable<IJobModel>;
    public contacts : Array<IContactModel>;
    public users : Observable<Array<IUserModel>>;
    public requireAmount: boolean;
	public appSettings: Object;
    public view: string = 'edit';

    constructor(
        private route: ActivatedRoute, 
        private state : StateManager, 
        messageService : MessageService, 
        private jobService : JobService, 
        private settingsService: AppSettingsService,
        ) {
        super(messageService);
		this.appSettings = this.settingsService.getSettings();
    }

    ngOnInit() : void {
        const self = this;

        self.model = self.state.getActiveJob();
        self.users = self.state.getUsersList();
        self._id = routerUtils.getRouteParameter(self.route, 'jobId');
    }
    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public saveJob(newModel: IJobModel) : void {
        const self = this;

        self.jobService.updateJob(self._customerId, newModel);
    }
}
