import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Aggregate, Summary } from '@/models/summary.models';
import { IAuthUser, UserRole } from '@/models/auth.models';

@Component({
  selector: 'entity-summary',
  template: `
  <div *ngIf="user" class="card entity-summary">
    <div class="row">
      <div class="col-xs-9">
        <div class="title">
          <span *ngIf="user.role == 5 || user.role == 10 || user.role == 20">My active </span>
          <span *ngIf="user.role == 0">All </span>
          <span class="entity-label">{{summary.label}}</span>
        </div>
      </div>
      <div class="col-xs-3 text-right">
        <button type="button" class="btn btn-default" (click)="routeToList()">Go</button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-xs-12">
        <div *ngFor="let agg of summary.data | statusFilter:'void' : 'finalized'" class="item">
              <span class="pseudo-link" (click)="routeToList(agg)">{{agg.count}}
                <span>{{agg.key | lowercase}}</span>
              </span>
        </div>
      </div>
    </div>
  </div>
  `,
  host: {'class': 'entity-summary-component'}
})
export class EntitySummaryComponent {
	@Input()
	user: IAuthUser;

	@Input()
	summary: Summary;

	constructor(private router: Router) {   console.log(this.summary)
  }

  routeToList(agg?: Aggregate): void {
		let filter = [];
		if (this.user.role !== UserRole.Admin && this.user.role !== UserRole.ShopForeman) {
      filter.push({filter: 'projectManagerFilter', id: this.user.userId });
		}

		if (agg) {
			filter.push({ filter: 'statusFilter', id: agg.label || agg.key.toLowerCase() });
		}

		this.router.navigateByUrl('/' + this.summary.route, {state: filter});
	}
}
