import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { ILocationModel, ITaxRates, LocationModel } from '@/models/location.models';
import { IStaffModel, StaffModel } from '@/models/staff.models';
import { IUserModel, UserModel } from '@/models/user.models';
import { UserService } from './user.service';

@Injectable()
export class LocationService extends ApiBaseService {

	constructor(private _http: HttpClient, private stateManager: StateManager, messageService: MessageService, private userService: UserService) {
		super(messageService);
		this.baseUrl = 'locations';
	}

	getLocations(): Promise<Array<ILocationModel>> {
		let result = this._http.get<Array<LocationModel>>(this.baseUrl).pipe(share());
		return this.extract<Array<LocationModel>>(result, (s) => {
			this.stateManager.setLocationsList(s);
		});
	}

	getLocation(id: string): Promise<ILocationModel> {
		let result = this._http.get<LocationModel>(this.baseUrl + '/' + id).pipe(share());
		return this.extract<ILocationModel>(result, (s) => {
			this.stateManager.setActiveLocation(s);
		});
	}

	notifyLocationChanged(message: string) {
		this.notify(message);
	}

	updateLocation(location: ILocationModel): Promise<ILocationModel> {
		let result: Observable<ILocationModel>;

		if (location._id) {
			result = this._http.put<ILocationModel>(this.baseUrl + '/' + location._id, location).pipe(share());
		} else {
			result = this._http.post<ILocationModel>(this.baseUrl, location).pipe(share());
		}

		let promise = this.extract<ILocationModel>(result, (s) => {
				this.stateManager.setActiveLocation(s);
		}, (error) => {
				this.notify('An error occurred when updating the location', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Location updated.');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// <editor-fold> Staff

	getAllStaff(id: string): Promise<Array<IStaffModel>> {
		let result = this._http.get<Array<StaffModel>>(this.baseUrl + '/' + id + '/staff').pipe(share());

		return this.extract(result, (s) => {
			this.stateManager.setStaffList(s);
		});
	}

	getStaff(id: string, staffId: string): Promise<IStaffModel> {
		let result = this._http.get<StaffModel>(this.baseUrl + '/' + id + '/staff/' + staffId).pipe(share());
		return this.extract(result, (s) => {
			this.stateManager.setActiveStaff(s);
		});
	}

	updateStaff(id: string, staff: IStaffModel): Promise<IStaffModel> {
		let result: Observable<IStaffModel>;

		if (staff._id) {
			result = this._http.put<IStaffModel>(this.baseUrl + '/' + id + '/staff/' + staff._id, staff).pipe(share());
		} else {
			result = this._http.post<IStaffModel>(this.baseUrl + '/' + id + '/staff/', staff).pipe(share());
		}

		let promise = this.extract(result, (s) => {
			this.stateManager.setActiveStaff(s);
		}, (error) => {
			this.notify('An error occurred when updating the staff member', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Staff updated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	deleteStaff(id: string, staffId: string): Promise<Object> {
		let result = this._http.delete(this.baseUrl + '/' + id + '/staff/' + staffId).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Staff deleted');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	deactivateStaff(id: string, staffId: string): Promise<IStaffModel> {
		const url = this.baseUrl + '/' + id + '/staff/' + staffId + '/deactivate';
		let result: Observable<IStaffModel> = this._http.put<IStaffModel>(url, new StaffModel()).pipe(share());
		let promise = this.extract<IStaffModel>(result);

		promise.then(() => {
			this.notify('Staff deactivated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	activateStaff(id: string, staffId: string): Promise<IStaffModel> {
		const url = this.baseUrl + '/' + id + '/staff/' + staffId + '/activate';
		let result: Observable<IStaffModel> = this._http.put<IStaffModel>(url, new StaffModel()).pipe(share());
		let promise = this.extract<IStaffModel>(result);

		promise.then(() => {
			this.notify('Staff activated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// </editor-fold> Staff

	// <editor-fold> Users

	getUsers(id: string): Promise<Array<IUserModel>> {
		let result = this._http.get<Array<UserModel>>(this.baseUrl + '/' + id + '/users').pipe(share());

		return this.extract(result, (s) => {
			this.stateManager.setLocationUsersList(s);
		});
	}

	getUser(id: string, userId: string): Promise<IUserModel> {
		let result = this._http.get<UserModel>(this.baseUrl + '/' + id + '/users/' + userId).pipe(share());
		return this.extract(result, (s) => {
			this.stateManager.setLocationSelectedUser(s);
		});
	}

	updateUser(id: string, user: IUserModel): Promise<IUserModel> {
		let result: Observable<IUserModel>;

		if (user._id) {
			result = this._http.put<IUserModel>(this.baseUrl + '/' + id + '/users/' + user._id, user).pipe(share());
		} else {
			result = this._http.post<IUserModel>(this.baseUrl + '/' + id + '/users/', user).pipe(share());
		}

		let promise = this.extract(result, (s) => {
			this.stateManager.setLocationSelectedUser(s);
		}, (error) => {
			this.notify('An error occurred when updating the user', { type: 'error' });
		});

		promise.then(() => {
			this.userService.getUsers();
			this.notify('User updated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	deleteUser(id: string, userId: string): Promise<Object> {
		let result = this._http.delete(this.baseUrl + '/' + id + '/users/' + userId).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('User deleted');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	deactivateUser(id: string, userId: string): Promise<IUserModel> {
		const url = this.baseUrl + '/' + id + '/users/' + userId + '/deactivate';
		let result: Observable<IUserModel> = this._http.put<IUserModel>(url, new UserModel()).pipe(share());
		let promise = this.extract<IUserModel>(result);

		promise.then(() => {
			this.notify('User deactivated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	activateUser(id: string, userId: string): Promise<IUserModel> {
		const url = this.baseUrl + '/' + id + '/users/' + userId + '/activate';
		let result: Observable<IUserModel> = this._http.put<IUserModel>(url, new UserModel()).pipe(share());
		let promise = this.extract<IUserModel>(result);

		promise.then(() => {
			this.notify('User activated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// </editor-fold> Users

	// <editor-fold> Tax rates

	// todo preliminary support for modifiable tax rates per location
	getTaxRates(id: string): Promise<ITaxRates> {
		let result = this._http.get<ITaxRates>(this.baseUrl + '/' + id + '/taxRates').pipe(share());
		return this.extract(result);
	}

	// </editor-fold>

	
}
