import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { ISupplierModel, SupplierModel } from '@/models/supplier.models';

export interface ISupplierState {
	suppliers: Array<ISupplierModel>,
	activeSupplier: ISupplierModel
}

const defaultState = {
	suppliers: new Array<ISupplierModel>(),
	activeSupplier: new SupplierModel()
};

export const SUPPLIER_ACTIONS = {
	SET_SUPPLIERS_LIST: 'SET_SUPPLIERS_LIST',
	SET_ACTIVE_SUPPLIER: 'SET_ACTIVE_SUPPLIER'
};

const supplierReducer = function(state: ISupplierState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case SUPPLIER_ACTIONS.SET_SUPPLIERS_LIST:
			newState.suppliers = action.payload;
			break;
		case SUPPLIER_ACTIONS.SET_ACTIVE_SUPPLIER:
			newState.activeSupplier = action.payload;
			break;
	}

	return newState;
};

export function getSuppliersList() {
	return (state : Store<ISupplierState>) => {
		return state.select(s => {
			return s.suppliers;
		});
	};
}

export function getActiveSupplier() {
	return (state : Store<ISupplierState>) => {
		return state.select(s => s.activeSupplier)
	};
}

export function reducer(state: ISupplierState | undefined, action: Action) {
	return supplierReducer(state, action);
}
