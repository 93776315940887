<header>
	<div class="row">
	    <div class="col-xs-12 col-md-8">
		    <search [(ngModel)]="filterTerm" placeholder="Filter change orders" filterData="text" [filterManager]="dataManager"></search>
	    </div>
	    <div class="col-xs-12 col-md-4">
	        <button type="button" class="btn btn-block btn-default" (click)="addClicked.emit()">Add change order</button>
	    </div>
	</div>
</header>

<change-order-list [model]="model | async" [showIndicator]="true" (onChangeOrderSelect)="itemSelected.emit($event)" [selectedChangeOrderId]="selectedChangeOrderId"></change-order-list>
