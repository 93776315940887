import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject, Subscription, Observable } from 'rxjs';
import { BaseSmartComponent } from '@/components/base.component';
import { StateManager } from '@/state/stateManager';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { JobService } from '@/services/job.service';
import { AuthService } from '@/services/auth.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPayableModel, PayableModel, IPayableCategory} from '@/models/payable.models';
import { ISupplierModel, SupplierModel } from '@/models/supplier.models';
import { IAuthUser, UserRole } from '@/models/auth.models';
import { IUserModel } from '@/models/user.models';
import { IBackReference } from '@/models/utility.models';
import { SupplierService } from '@/services/supplier.service';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { ExportDocumentService } from '@/services/exportDocument.service';
@Component({
  selector: 'payable-edit',
  templateUrl: './payableEdit.template.html',
  host: { class: 'payable-edit-component' },
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(300)),
    ]),
  ],
})
export class PayableEditComponent
  extends BaseSmartComponent
  implements OnDestroy, OnInit
{
  @ViewChild('payableForm', { static: false }) payableForm: NgForm;
  @ViewChild('selectField') selectField;
  @ViewChild('newCategoryField') newCategoryField;
  @Input()
  model: IPayableModel = new PayableModel();

  @Input()
  isNew: boolean;

  @Input()
  jobId: string;

  @Input()
  customerId: string;

  @Input()
  suppliers: Array<ISupplierModel> = new Array<ISupplierModel>();

  // making a copy of suppliers so typed input is not automatically added to suppliers list - breaks validation
  @Input()
  suppliersCopy: Array<ISupplierModel>;

  @Input()
  showAlert: boolean = false;

  @Output()
  onSave: EventEmitter<IPayableModel> = new EventEmitter<IPayableModel>();

  @Output()
  onCancel: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onGoToPayable = new EventEmitter<void>();

  @Input()
  uuid: string = '';

  public duplicateRecord: boolean = false;
  public supplierModel: ISupplierModel = new SupplierModel();
  public display: string = 'none';
  public purchaseOrderName: string = '';
  public showPO: boolean = false;
  public newPO: any = {};
  public purchaseOrdersFormatted: any = [];
  public catOpen: boolean = false;
  public categoryList: any = [];
  public selectedCategories: any = [];
  public newCategory = '';
  public fileUploaded: boolean = false;
  public validPO: boolean = true;
  public user: IAuthUser;
  public userSub: Subscription;
  public isAdmin: boolean = false;
  public role: number;
  public poCopy: any = {};
  public showEditPo: boolean = false;
  public validTotals: boolean = true;
  public invalidSupplier: boolean = false;
  public month: any = null;
  public year: any = null;
  public permissionLock: boolean = false;
  public disableSubmit: boolean = false;
  public assignedPM: string;
  public users: Observable<Array<IUserModel>>;
  public usersFormatted: Array<Object>;
  public textRequired = false;
  public tempSupplierName: String = null;

  public get exportedPdf(): string | null {
    if (this.model && this.model.exportedKey) {
      return this.model.exportedKey.replace('Payable/exports/', '');
    }
    return null;
  }

  private saved: Subject<any> = new Subject();
  private filesQueued: boolean = false;
  private payables: IPayableModel[] = [];
  private jobPM: string | IBackReference;
  private addingCategory: boolean = false;

  constructor(
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    private filesService: FilesService,
    messageService: MessageService,
    private authService: AuthService,
    private jobService: JobService,
    private supplierService: SupplierService,
    public fb: FormBuilder,
		public exportService: ExportDocumentService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    const self = this;
    self.users = self.state.getUsersList();
    self.users.subscribe((users) => {
      self.formatUsers(users);
    });
    // get user's role
    self.userSub = self.authService.currentUser.subscribe((s) => {
      if (s) {
        self.user = s;
        self.role = s.role;
        if (
          self.role === UserRole.ProjectManager ||
          self.role === UserRole.ShopForeman
        )
          self.permissionLock = true;
        if (self.role === UserRole.Admin) {
          self.isAdmin = true;
        } else {
          self.isAdmin = false;
        }
      }
    });

    this.state
      .getPayablesList()
      .subscribe(
        (payableList: IPayableModel[]) => (this.payables = payableList)
      );
    this.validPO = true;
    this.invalidSupplier = false;
    this.saved.next('false');
    this.newPO = { _id: null, name: null, total: null };
    this.purchaseOrdersFormatted = [];

		this.purchaseOrderService.fetch()
			.then(items => {
				items.forEach(PO => {
					let poName = PO.name || '';
					this.purchaseOrdersFormatted.push({
						_id: PO._id,
						name: PO.number + (poName ? ' - ' + poName : ''),
					});
				});
			})
			.catch(err => console.error('There was an error loading purchase orders.'));

    this.categoryList = [
      { text: 'OSS/Freight', id: 1, total: null },
      { text: 'Inventory (Stock)', id: 2, total: null },
      { text: 'Inventory (Job)', id: 3, total: null },
      { text: 'Office Expense', id: 4, total: null },
      { text: 'Warehouse Expense', id: 5, total: null },
      { text: 'Admin Expense', id: 6, total: null },
    ];
    this.getJobPM();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      if (this.model && this.model.supplier) {
        this.tempSupplierName = this.model.supplier.name;
      }
      if (this.model && !this.model.projectManager) {
        this.getJobPM();
        this.model.projectManager = this.jobPM;
      }
      if (this.model && !this.model.supplier) {
        this.model.supplier = {
          _id: null,
          name: null,
          status: null,
          type: null,
          category: null,
          location: null,
        };
      }
      this.showPO = this.model && !this.model.purchaseOrders.length;
      this.showEditPo = false;
      if (this.model && this.model.monthCosted) {
        let date = new Date(this.model.monthCosted);
        this.month = date.getMonth();
        this.year = date.getFullYear();
      }
      if (this.model) {
        if (this.model.categories) {
          this.selectedCategories = this.model.categories;
        }
      }
    }
  }

  override ngOnDestroy(): void {
    // removing for now because navigating from Docs tab back to Details is not working like other sections
    // this.state.setActivePayable(null);
  }

  cancel(): void {
    this.onCancel.emit();
  }

  savePayable(): void {
    this.disableSubmit = true;
    // validate fields
    this.invalidSupplier = this.validateSupplier(this.model.supplier);
    if (this.invalidSupplier) {
      this.jobService.notify('Please select a valid supplier from the list', {
        type: 'error',
      });
      return;
    }

    this.onSave.emit(this.model);
    // todo fix the need for resetting
    // simply resetting the form causes dates to not be displayed correctly
    Object.keys(this.payableForm.controls).forEach((control) => {
      this.payableForm.controls[control].markAsPristine();
    });
    this.formStatus.emit(false);
    this.isNew = false;
    this.saved.next('true');
    this.invalidSupplier = false;
    setTimeout(() => {
      this.disableSubmit = false;
    }, 1000);
  }

  public setMessage(e): void {
    this.filesQueued = e;
  }

  // add a supplier
  public openModal(): void {
    this.display = 'block';
  }

  public showPOFields(): void {
    this.showPO = true;
  }

  public addPO(): void {
    this.purchaseOrderName = '';

    if (this.validatePO(this.newPO)) {
      this.model.purchaseOrders.push(this.newPO);
      this.showPO = false;
      this.validPO = true;
      this.newPO = { _id: null, total: null };
    } else {
      this.validPO = false;
      this.newPO = { _id: null, total: null };
    }
  }

  public cancelPO(): void {
    this.purchaseOrderName = '';
    this.showPO = false;
    this.newPO = { _id: null, total: null };
  }

  public editPO(): void {
    this.model.purchaseOrders.forEach(
      (po) => (po.name = this.getPoName(po._id))
    );
    this.poCopy = this.model.purchaseOrders.map((x) => Object.assign({}, x));
    this.showEditPo = true;
  }

  public saveEditedPO(): void {
    this.showEditPo = false;
  }

  public cancelPOEdit(po): void {
    this.model.purchaseOrders = this.poCopy;
    this.poCopy = [];
    this.showEditPo = false;
  }

  public setNewSupplier(newSupplier): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    newSupplier.location = currentUser.locationId;

    if (newSupplier.isActive) {
      this.model.supplier = {
        _id: newSupplier._id,
        name: newSupplier.name,
        status: newSupplier.status,
        type: newSupplier.type,
        category: newSupplier.category,
        location: newSupplier.location,
      };

      let supplier = Object.assign({}, newSupplier);
      supplier.insensitive = newSupplier.name.toLowerCase();
      this.suppliers.push(newSupplier);
      this.suppliersCopy.push(supplier);
      this.supplierService.updateSupplier(newSupplier);
      this.tempSupplierName = supplier.name;
    }
  }
  public addNewCat(value) {
    if (!value) {
      this.textRequired = true;
      return;
    } else {
      this.textRequired = false;
      let catId = 0;
      if (this.model.categories && this.model.categories.length) {
        catId = parseInt(
          this.categoryList.length +
            this.model.categories[this.model.categories.length - 1].id +
            1
        );
      }
      let newcat = {
        text: value,
        id: catId,
        total: null,
      };
      // check for dupes
      let duplicate = false;
      if (this.model.categories) {
        this.model.categories.forEach((cat) => {
          if (cat.text.toLowerCase() === newcat.text.toLowerCase()) {
            duplicate = true;
          }
        });
      }
      if (duplicate) {
        this.jobService.notify('A category with that name already exists.', {
          type: 'error',
        });
        return;
      } else {
        this.addingCategory = true;
        this.categoryList = [...this.categoryList, newcat];
        this.selectCategory(newcat);
      }
      this.newCategoryField.nativeElement.value = null;
    }
  }

  public selectCategory(value: any): void {
    if (!this.addingCategory) {
      if (!this.model.categories) {
        this.model.categories = [];
      }
      this.resetCategoryTotals();
      this.model.categories = this.selectedCategories;
      this.newCategory = '';
      this.getInvoiceTotal();
    } else {
      setTimeout(() => {
        this.selectedCategories.push(value);
        this.selectedCategories = this.selectedCategories.slice();
        this.model.categories = this.selectedCategories;
      }, 0);
      this.addingCategory = false;
    }
  }

  public removeCategory(value): void {
    this.resetCategoryTotals();
    this.model.categories = this.selectedCategories;
    this.getInvoiceTotal();
  }

  private resetCategoryTotals(): void {
    // ng-select resets the total, so get it from the model
    this.model.categories.forEach((cat) => {
      if (this.selectedCategories) {
        this.selectedCategories.forEach((selCat) => {
          if (cat.id === selCat.id) {
            selCat.total = cat.total;
          }
        });
      }
    });
  }

  public getInvoiceTotal(): number {
    this.validTotals = true;
    if (this.model.categories) {
      this.model.amount = 0;
      this.model.categories.forEach((cat) => {
        if (!cat.total) {
          this.validTotals = false;
        } else {
          this.model.amount = this.model.amount + cat.total;
        }
      });
      return this.model.amount;
    }
    return 0;
  }

  public validateTotals(): boolean {
    return !this.model.categories.find((cat) => !cat.total);
  }

  public getPoName(id: string): string {
    if (this.purchaseOrdersFormatted.length > 0) {
      const pof = this.purchaseOrdersFormatted.find((x) => x._id === id);
      if (pof && pof.name) {
        return pof.name;
      }
    }
    return '';
  }

  public validatePO(po: any) {
    let validPO = null;
    validPO = this.purchaseOrdersFormatted.find((PO) => {
      return PO._id === po._id;
    });
    return validPO;
  }

  public setPostedDate(status): void {
    if (status === 'posted') {
      this.model.invoicePostedDate = new Date();
      this.savePayable();
    }
  }

  public selectSupplier(e): void {
    this.invalidSupplier = this.validateSupplier(e.item);
  }

  public validateSupplier(event): boolean {
    let invalidSupplier = true;
    if (event.name && this.suppliersCopy) {
      const nameLc = event.name.toLowerCase();
      for (let i = 0; i < this.suppliersCopy.length; i++) {
        if (this.suppliersCopy[i].name.toLowerCase() === nameLc) {
          invalidSupplier = false;
          // set selected supplier to the matching supplier in case the name was typed in and not selected from the list - id and status would be missing
          this.model.supplier = this.suppliersCopy[i];
          this.tempSupplierName = this.model.supplier.name;
          break;
        }
      }
    }
    return invalidSupplier;
  }

  public setMonth(e): void {
    this.model.monthCosted = new Date(e);
  }

  public async openExportedPdf(): Promise<void> {
		this.exportService.getExportedFile(this.filesService.getFileUrl(this.model.exportedKey))
			.subscribe((res) => {
        if (res.status === 200) {
          const file = new File([res.body], this.exportedPdf, { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          window.open(fileUrl, '_blank');
        }
      });
	}

  private checkDocuments(): void {
    const self = this;
    setTimeout(() => {
      if (
        self.model.documents != null &&
        self.model.documents != undefined &&
        Object.keys(self.model.documents).length
      ) {
        this.fileUploaded = true;
      } else {
        this.fileUploaded = false;
      }
    }, 0);
  }

  private formatUsers(users): void {
    this.usersFormatted = [];
    users.forEach((user) => {
      this.usersFormatted.push({
        _id: user._id,
        name: user.firstName + ' ' + user.lastName,
      });
    });
  }

  private getJobPM() {
    this.state.getActiveJob().subscribe((job) => {
      this.jobPM = job.projectManager;
    });
  }

  public resetPM() {
    this.model.projectManager = this.jobPM;
  }

  goToPayable() {
    this.onGoToPayable.emit(null);
  }
}

