import { Injectable } from '@angular/core';

export interface Filter {
  type: string;
  term?: string;

  // date range
  startDate?: string;
  endDate?: string;
}

type DatedItem = { date: string };

/**
 * Builds a filter that accepts items if its date field is between the start and end
 * @param start The start date
 * @param end The end date
 */
function filterByDate(start: string, end: string): (entry: DatedItem) => boolean {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return (entry: DatedItem) => {
        const date = new Date(entry.date);
        return (date >= startDate) && (date <= endDate);
    };
}

export class FilterListService<T> {

  constructor(private filterMap: Map<string, (term: string) => (item: T) => boolean>) {}

  /**
   * Converts the type of filters that were used prior to the partial refactor into the new Filter
   * @param filters
   */
  convertFilters(filters: any): Filter[] {
    if (!filters.hasFilter) {
      return [];
    }

    return filters.map((partial: any) => {
      let filter: Filter = { type: partial.type };

      if (partial.term) {
        filter.term = partial.term;
      } else if (partial.startDate && partial.endDate) {
        filter.startDate = partial.startDate;
        filter.endDate = partial.endDate;
      }

      return filter;
    });
  }

  /**
   * Creates a filter function that matches items that satisfy ALL of the filters. If filters is
   * empty, all items match.
   * @param filters
   */
  and(filters: Filter[]): (item: T) => boolean {
    // invalid is true so it doesn't fail all matches
    const conj = this.buildFilterGroup(filters, true);
    return (item: T) => {
      return !conj.length || conj.every((filter: (item: T) => boolean) => filter(item));
    };
  }

  /**
   * Creates a filter function that matches items that satisfy ANY of the filters. If filters is
   * empty, all items match.
   * @param filters
   */
  or(filters: Filter[]): (item: T) => boolean {
    // invalid is false so it doesn't accept everything
    const disj = this.buildFilterGroup(filters, false);
    return (item: T) => {
      return !disj.length || disj.findIndex((filter: (item: T) => boolean) => filter(item)) >= 0;
    };
  }

  private buildFilterGroup(filters: Filter[], invalidValue: boolean): (((entry: DatedItem) => boolean) | ((item: T) => boolean))[] {
    return filters
      .filter((filter: Filter) => (filter.type === 'dateRange') || filter.term)
      .map((filter: Filter) => {
        if (filter.type === 'dateRange') {
          return filterByDate(filter.startDate, filter.endDate);
        } else {
          const typeFilter = this.filterMap.get(filter.type);
          if (typeFilter) {
            return typeFilter(filter.term);
          }
        }
        return (item: T) => invalidValue;
      });
  }
}
