import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {IMaskModule} from 'angular-imask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


import { CustomerComponent } from './customer.component';
import { CustomerAddComponent } from './customerAdd.component';
import { CustomerContactAddComponent } from './customerContactAdd.component';
import { CustomerContactEditComponent } from './customerContactEdit.component';
import { CustomerContactListComponent } from './customerContactList.component';
import { CustomerEditDetailsComponent } from './customerEditDetails.component';
import { CustomerEditFormComponent } from './customerEditForm.component';
import { CustomerEditTabsComponent } from './customerEditTabs.component';
import { CustomerJobListComponent } from './customerJobList.component';
import { CustomerListComponent } from './customerList.component';
import { CustomerSummaryComponent } from './customerSummary.component';
import { CustomerDocumentsComponent } from './customerDocuments.component';

import { UtilityModule } from '@/utils/utility.module';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { ContactModule } from '@/components/ContactComponent/contact.module';
import {JobModule} from '@/components/JobComponent/job.module';

@NgModule({
    declarations: [
        CustomerComponent,
        CustomerAddComponent,
        CustomerContactAddComponent,
        CustomerContactEditComponent,
        CustomerContactListComponent,
        CustomerEditDetailsComponent,
        CustomerEditFormComponent,
        CustomerEditTabsComponent,
        CustomerJobListComponent,
        CustomerListComponent,
        CustomerSummaryComponent,
        CustomerDocumentsComponent
    ],
            imports: [
              CommonModule,
              FormsModule,
              RouterModule,
              UtilityModule,
              ContactModule,
              InfiniteScrollModule,
              DocumentUploadModule,
              IMaskModule,
              JobModule
            ],
    exports: [
        CustomerComponent,
        CustomerAddComponent,
        CustomerContactAddComponent,
        CustomerContactEditComponent,
        CustomerContactListComponent,
        CustomerEditDetailsComponent,
        CustomerEditFormComponent,
        CustomerEditTabsComponent,
        CustomerJobListComponent,
        CustomerListComponent,
        CustomerSummaryComponent,
        CustomerDocumentsComponent
    ]
})
export class CustomerModule { }
