import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationExtras} from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BehaviorSubject, Subscription, take } from 'rxjs';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { AuthService } from '@/services/auth.service';
import { UserRole } from '@/models/auth.models';
import { IJobModel } from '@/models/job.models';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { IPurchaseOrderModel} from '@/models/purchaseOrder.models';
import { ICustomerModel } from '@/models/customer.models';
import { PurchaseOrderService} from '@/services/purchaseOrder.service';
import { WorkOrderService} from '@/services/workOrder.service';
import { IInvoiceModel, InvoiceModel } from '@/models/invoice.models';
import { IPayableModel, PayableModel } from '@/models/payable.models';
import { IChangeOrderModel, ChangeOrderModel } from '@/models/changeOrder.models';
import * as routerUtils from '@/utils/routerUtils';
import { CustomerService } from '@/services/customer.service';
import { UserService } from '@/services/user.service';
import { JobService } from '@/services/job.service';
import { DataManipulationService, DataManipulationServiceFactory } from '@/services/dataManipulation.service';
import { AppSettingsService } from '@/services/appSettings.service';
import {
  WorkOrderFilterFunctions,
  WorkOrderSortFunctions,
  PurchaseOrderFilterFunctions,
  PurchaseOrderSortFunctions,
  ChangeOrderFilterFunctions,
  ChangeOrderSortFunctions,
  InvoiceFilterFunctions,
  InvoiceSortFunctions,
  PayableFilterFunctions,
  PayableSortFunctions
} from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';
import { IUserModel } from '@/models/user.models';

@Component({
	selector: 'job-summary',
	templateUrl: './jobSummary.template.html',
	host: {'class': 'job-summary-component'},
	animations: [
	  trigger('fadeInOut', [
	    state('void', style({
				opacity: 0
			})),
      transition('void <=> *', animate(300)),
    ]),
	]
})
export class JobSummaryComponent extends BaseSmartComponent implements OnInit {
	// todo refactor or rename so public properties aren't prefixed with _
	public _sidebarType: string;
	public _sidebarListType: string;
	public job: IJobModel;
	public showDetails: boolean = true;
	public isAdmin: boolean = false;
	public showJobCosts: boolean = false;
	public hasLaborCodes: boolean = false;
	public currentView: string;
	navigationSubscription;
	public userSub: Subscription;
  	public appSettings: any;

	private _customerId: string;
	private _jobId: string;

	private customer: ICustomerModel;
	private customerContactName: string = null;
	private projectManagerName: string = null;
	private users: IUserModel[];
	private workOrderManager: DataManipulationService<IWorkOrderModel>;
	private purchaseOrderManager: DataManipulationService<IPurchaseOrderModel>;
	private changeOrderManager: DataManipulationService<IChangeOrderModel>;
	private invoiceManager: DataManipulationService<IInvoiceModel>;
	private payableManager: DataManipulationService<IPayableModel>;
	private workOrders: BehaviorSubject<Array<IWorkOrderModel>> = new BehaviorSubject<Array<IWorkOrderModel>>(null);
	private purchaseOrders: BehaviorSubject<Array<IPurchaseOrderModel>> = new BehaviorSubject<Array<IPurchaseOrderModel>>(null);
	private changeOrders: BehaviorSubject<Array<IChangeOrderModel>> = new BehaviorSubject<Array<IChangeOrderModel>>(null);
	private invoices: BehaviorSubject<Array<IInvoiceModel>> = new BehaviorSubject<Array<IInvoiceModel>>(null);
	private payables: BehaviorSubject<Array<IPayableModel>> = new BehaviorSubject<Array<IPayableModel>>(null);
	private jobUrl: String = '';

	constructor(
    private router: Router,
    private state: StateManager,
    private jobService: JobService,
	private workOrderService: WorkOrderService,
    private purchaseOrderService: PurchaseOrderService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private customerService: CustomerService,
    private userService: UserService, 
    messageService: MessageService,
	private settingsService: AppSettingsService,
	dataManipulationServiceFactory: DataManipulationServiceFactory,
	) {
		super(messageService);
		this.appSettings = this.settingsService.getSettings();
		this.workOrderManager = dataManipulationServiceFactory.create<IWorkOrderModel>();
		this.purchaseOrderManager = dataManipulationServiceFactory.create<IPurchaseOrderModel>();
		this.changeOrderManager = dataManipulationServiceFactory.create<IChangeOrderModel>();
		this.invoiceManager = dataManipulationServiceFactory.create<IInvoiceModel>();
		this.payableManager = dataManipulationServiceFactory.create<IPayableModel>();

		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				if (!!this._jobId && this._jobId !== routerUtils.getRouteParameter(this.route, 'jobId')) {
					this.ngOnInit();
				}
			}
		});
	}

	ngOnInit(): void {
		//@ts-ignore
		this.jobUrl = 'Customers/' + this.route.snapshot.params.customerId + '/Job/' + this.route.snapshot.params.jobId;
		this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
		this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
			// set correct active tab when coming from TimeEntry view
		this.currentView = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
		if (this.currentView === 'TimeEntry') {
			this.setSidebarType('TimeEntry');
		} else if (this.currentView === 'Costs') {
			this.setSidebarType('Costs');
		}
		this.setSidebarListType('WorkOrders');
		this.jobService.getJob(this._customerId, this._jobId)
			.then(job => {
        this.job = job;
		this.jobService.saveSidebarData(this.job.bidAmounts, this.job.notes);
		// show/hide labor codes nav item only when job has been saved
        this.hasLaborCodes = JSON.parse(JSON.stringify(this.job.hasLaborCodes));

        if (this.job && (typeof this.job.customerContact === 'string')) {
          this.customerService.getContact(this._customerId, this.job.customerContact)
						.then(contact => {
              this.customerContactName = contact.firstName + ' ' + contact.lastName;
						})
						.catch(handleError);
				}
		
        if (this.job && this.job.projectManager) {
			this.state.getUsersList().subscribe(users => this.users = users);
              let projectManager = this.users.find(user => {
								return user._id === (this.job.projectManager as string);
							});

              if (projectManager) {
                this.projectManagerName = projectManager.firstName + ' ' + projectManager.lastName;
							}
				}
			})
			.catch(handleError);

    this.watchSubscription(this.state.getActiveCustomer().subscribe(s => {
      this.customer = s;
		}));

		// sort / filter setups
    this.workOrderManager.initialize(WorkOrderFilterFunctions, WorkOrderSortFunctions);
    this.watchSubscription(this.workOrderManager.connectDataSource(this.state.getWorkOrdersList(), this.workOrders));
    this.workOrderManager.setSort('date', true);
    this.workOrderManager.setFilter('text', '');

    this.purchaseOrderManager.initialize(PurchaseOrderFilterFunctions, PurchaseOrderSortFunctions);
    this.watchSubscription(this.purchaseOrderManager.connectDataSource(this.state.getPurchaseOrdersList(), this.purchaseOrders));
    this.purchaseOrderManager.setSort('date', false);
    this.purchaseOrderManager.setFilter('text', '');

    this.changeOrderManager.initialize(ChangeOrderFilterFunctions, ChangeOrderSortFunctions);
    this.watchSubscription(this.changeOrderManager.connectDataSource(this.state.getChangeOrdersList(), this.changeOrders));
    this.changeOrderManager.setSort('date', true);
    this.changeOrderManager.setFilter('text', '');

    this.invoiceManager.initialize(InvoiceFilterFunctions, InvoiceSortFunctions);
    this.watchSubscription(this.invoiceManager.connectDataSource(this.state.getInvoicesList(), this.invoices));
    this.invoiceManager.setSort('invoiceDate', true);
    this.invoiceManager.setFilter('text', '');

    this.payableManager.initialize(PayableFilterFunctions, PayableSortFunctions);
    this.watchSubscription(this.payableManager.connectDataSource(this.state.getPayablesList(), this.payables));
    this.payableManager.setSort('invoiceDate', true);
    this.payableManager.setFilter('text', '');

		// watch which subentities are selected
    this.watchSubscription(this.state.getActivePurchaseOrder().subscribe((s) => {
			if (s != null) {
				this.setSidebarListType('PurchaseOrders'); 
			}
		}));

    this.watchSubscription(this.state.getActiveChangeOrder().subscribe((s) => {
			if (s != null) { this.setSidebarListType('ChangeOrders'); }
		}));

    this.watchSubscription(this.state.getActiveInvoice().subscribe((s) => {
			if (s != null) { this.setSidebarListType('Invoices'); }
		}));

    this.watchSubscription(this.state.getActivePayable().subscribe((s) => {
			if (s != null) { this.setSidebarListType('Payables'); }
		}));

    this.watchSubscription(this.state.getActiveWorkOrder().subscribe((s) => {
			if (s != null) { this.setSidebarListType('WorkOrders'); }
		}));

	// get user's role
	this.userSub = this.authService.currentUser.subscribe(s => {
    this.isAdmin = (s && s.role === UserRole.Admin);
	});
	}

	override ngOnDestroy(): void {
	  if (this.navigationSubscription) {
	    this.navigationSubscription.unsubscribe();
		}
	  super.ngOnDestroy();
	}

	public setSidebarType(type: string): void {
		if(this.job){
			let sidebarData = this.jobService.getSidebarData();
			//@ts-ignore
			this.job.bidAmounts = sidebarData.bidAmounts;
			//@ts-ignore
			this.job.notes = sidebarData.notes;
		}
		this._sidebarType = type;
		this._sidebarType === 'Details' ? this.showDetails = true : this.showDetails = false;
		this.currentView = null;
		this.router.navigate([this.jobUrl, type === 'Details' ? 'Edit' : type]);
	}

	// relativeTo NavigationExtra stopped working with Angular 16, so hacking together the full url with jobUrl.
	addWorkOrder(): void {
		this.router.navigate([this.jobUrl, 'WorkOrders', 'Add']);
	}

	workOrderSelected(workOrder: IWorkOrderModel): void {
		this.router.navigate([this.jobUrl, 'WorkOrders', workOrder._id, 'Edit']);
	}

	addPurchaseOrder(): void {
		this.router.navigate([this.jobUrl, 'PurchaseOrders', 'Add']);
	}

	purchaseOrderSelected(purchaseOrder: IPurchaseOrderModel): void {
		this.router.navigate([this.jobUrl, 'PurchaseOrders', purchaseOrder._id, 'Edit']);
	}

	addChangeOrder(): void {
		this.state.setActiveChangeOrder(new ChangeOrderModel());
		this.router.navigate([this.jobUrl, 'ChangeOrders', 'Add']);
	}

	changeOrderSelected(changeOrder: IChangeOrderModel): void {
		this.router.navigate([this.jobUrl, 'ChangeOrders', changeOrder._id, 'Edit']);
	}

	addInvoice(): void {
		// todo all of these type of initial entity preparation operations should be moved to a BLL-type service.
		// we need to copy data from the current job into the new invoice
	    this.watchSubscription(
    	  this.state.getActiveJob().pipe(take(1))
			.subscribe(job => {
				let invoice = new InvoiceModel();
				invoice.orderNumber = job.contractNumber;

				this.state.setActiveInvoice(invoice);
				this.router.navigate([this.jobUrl, 'Invoices', 'Add'], { state: {orderNumber: job.contractNumber} });
			})
		);
	}

	invoiceSelected(invoice: IInvoiceModel): void {
		this.router.navigate([this.jobUrl, 'Invoices', invoice._id, 'Edit']);
	}

	addPayable(): void {
		this.state.setActivePayable(new PayableModel());
		this.router.navigate([this.jobUrl, 'Payables', 'Add']);
	}

	payableSelected(payable: IPayableModel): void {
		this.router.navigate([this.jobUrl, 'Payables', payable._id, 'Edit']);
	}

  private setSidebarListType(type: string): void {

	switch(type) {
		case 'WorkOrders':
			this.workOrderService.getWorkOrders(this._customerId, this._jobId);
			break;
		case 'PurchaseOrders':
			this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId);
			break;
		case 'Invoices':
			this.jobService.getInvoices(this._customerId, this._jobId);
			break;
		case 'ChangeOrders':
			this.jobService.getChangeOrders(this._customerId, this._jobId);
			break;
		case 'Payables':
			this.jobService.getPayables(this._customerId, this._jobId);
			break;
	}

    setTimeout(() => {
      this._sidebarListType = type;
    });
  }
}
