import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { IDataSortProvider, IDataFilterProvider, IFilterFunction, IFilterFunctionHash, ISortFunction, ISortFunctionHash } from './../utils/dataManipulationUtils';

//HACK: allow multiple data manipulation service instances
@Injectable()
export class DataManipulationServiceFactory {
    public create<T>() : DataManipulationService<T> {
        return new DataManipulationService<T>();
    }
};

@Injectable()
export class DataManipulationService<T> implements IDataSortProvider, IDataFilterProvider {
    private subscription: Subscription;

    private sorter = new BehaviorSubject<ISortFunction<T>>(null);
    private sortFunctions: ISortFunctionHash<T>;
    currentSort: string;
    descending: boolean;

    private filter = new BehaviorSubject<IFilterFunction<T>>(null);
    private filterFunctions: IFilterFunctionHash<T>;
    filterTerm: string;

    initialize(filters: IFilterFunctionHash<T>, sorts: ISortFunctionHash<T>) : void {
        const self = this;

        self.filterFunctions = filters;
        self.sortFunctions = sorts;
    }

    connectDataSource(data: Observable<Array<T>>, destination: Subject<Array<T>>) : Subscription {
        const self = this;

        // unsubscribe from existing data watcher
        if(self.subscription && !self.subscription.closed) {
            self.subscription.unsubscribe();
        }

        //TODO: return only the observable?
        self.subscription = combineLatest(data, self.filter, self.sorter, (source, filter, sort) => {
            let processed: Array<T> = null;

            if(filter && self.filterTerm && (self.filterTerm.length > 0)) {
                processed = filter(source, self.filterTerm);
            }
            else {
                // ensure that sorting unfiltered results doesn't leak back to the source data
                processed = Object.assign(new Array<T>(), source);
            }

            if(sort) {
                processed = sort(processed, self.descending === true ? -1 : 1);
            }

            return processed;
        })
        .subscribe((s) => {
            // async pipe won't connect directly to a combined observable
            destination.next(s);
        });

        return self.subscription;
    }

    setSort(type: string, descending?: boolean) : void {
        // allow an override sort
        if(descending !== undefined) {
            this.descending = descending;
        }
        else {
            // otherwise, toggle current sort order
            if(type !== this.currentSort) {
                this.descending = false;
            }
            else {
                this.descending = !this.descending;
            }
        }

        this.currentSort = type;
        this.sorter.next(this.sortFunctions[type]);
    }

    setFilter(type : string, term: string) : void {
        this.filterTerm = term ? term.toLowerCase() : null;
        this.filter.next(this.filterFunctions[type]);
    }


};
