import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Subject } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
	selector: 'notes-field',
	template: `
		<textarea rows="3" class="form-control" [(ngModel)]="model" (keyup)="checkNotes()" name="notes" #notes="ngModel" [disabled]="disabled" tabindex="{{tabindex}}" required="{{required}}"></textarea>
		<div *ngIf="unsaved" class="unsaved-change" [@fadeInOut]><i class="glyphicon glyphicon-exclamation-sign" title="Unsaved"></i>unsaved changes</div>
	`,
    host: {'class': 'notes-field-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
					opacity: 0,
					height: 0,
					overflow: 'hidden'
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})
export class NotesFieldComponent {
	@Input()
	set model(note: string) {
		this._note = note;
		this.modelChange.emit(this._note);
	};

	get model(): string { return this._note; }

	// enables two-way binding on the model
	@Output() modelChange = new EventEmitter<string>();

	@Input()
	saved: Subject<any>;

	@Input()
	disabled: boolean;

	@Input()
	tabindex: number;

	@Input()
	required: boolean = false;

	private _note: string;

	private notesCopy: string;
	public unsaved: boolean = false;

	ngOnInit() {
			this.saved.subscribe(event => {
					this.unsaved = false;
			});
	}

	ngAfterViewInit() {
		this.notesCopy = this.model;
	}

	checkNotes() {
		if (this.model !== this.notesCopy) {
			this.unsaved = true;
		}
	}
}
