import { Component } from '@angular/core';

@Component({
    selector:'admin-component',
   templateUrl: './admin.template.html',
   host: {'class': 'admin-component'}
})

export class AdminComponent {
}
