<div class="container fixed">
	<div class="row">
		<div class="col-md-10 col-md-offset-1 searchbox" [ngClass]="{'disabled': isLoading}">
			<search [(ngModel)]="searchTerm" large="true" (onAction)="search()" placeholder="Search Purchase Orders"
				#purchaseOrderSearch keyboardAccelerator="9" (onKeyboardAction)="search()" [isLoading]="isLoading">
			</search>
		</div>
	</div>
	<div class="row filter-row">
		<div class="col-sm-9" [ngClass]="{'col-sm-6 col-md-7': isAdmin}">
			<filter-list *ngIf="filterOptions.length" [itemType]="'purchaseOrder'" [activeFilters]="filters"
				[filterOptions]="filterOptions" (filterValue)="filterTheList($event)"></filter-list>
		</div>
		<div *ngIf="isAdmin" class="col-sm-4 col-md-3 border-left-md">
			<div class="form-group mb-0">
				<label>Update Status</label>
				<div class="input-group">
					<select class="form-control" [(ngModel)]="updatedStatus">
						<option value='' selected disabled>-- Select --</option>
						<option *ngFor="let option of statusList" [value]="option.value" #batchStatus>{{option.label}}
						</option>
					</select>
					<span class="input-group-btn">
						<button type="button" class="btn btn-default" (click)="updateStatus()"
							[disabled]="!selectedPOs.length || !updatedStatus">Apply</button>
					</span>
				</div>
			</div>
		</div>
		<div class="col-sm-3" [ngClass]="{'col-sm-2': isAdmin}">
			<div class="list-actions mt-2">
				<div class="list-filters">
					<button type="button" class="btn btn-default" (click)="filterToggle()"
						[ngClass]="{'active btn-primary':showClosed}">
						<i class="glyphicon glyphicon-lock"></i><span *ngIf="showClosed"> Hide</span><span
							*ngIf="!showClosed"> Show</span> Closed
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12 wo-list-column mt-1" id="infinite-scroll-list">
			<table id="wo-list" class="table table-select-list mb-0" [ngClass]="{'is-admin':isAdmin}">
				<caption class="sr-only">Sort list links</caption>
				<thead>
					<tr>
						<th *ngIf="isAdmin" scope="col" class="batch-check">
							<label class="custom-checkbox large" style="position: relative; top: -6px;">
								<input type="checkbox" [ngModel]="selectAll" (change)="toggleSelectAll()">
								<span class="checkmark"></span>
							</label>
						</th>
						<th scope="col" class="po-col">
							<infinite-scroll-header class="sortable" label="PO#" field="number"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="customer-col">
							<infinite-scroll-header class="sortable" label="Customer" field="job.customer.name"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="pm-col">
							<infinite-scroll-header class="sortable" label="Job / Project Manager" field="job.name"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="name-col">
							<infinite-scroll-header class="sortable" label="Name" field="name"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="supplier-col">
							<infinite-scroll-header class="sortable" label="Supplier" field="supplier.name"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="status-col">
							<infinite-scroll-header class="sortable" label="Status" field="status.status"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="date-col">
							<infinite-scroll-header class="sortable" label="Date" field="date"
								[currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)">
							</infinite-scroll-header>
						</th>
						<th scope="col" class="list-actions">&nbsp;</th>
					</tr>
				</thead>
			</table>
			<div id="top-of-list-marker"></div>
		</div>
	</div>
</div>
<div class="container list-container">
	<div class="attached" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
		[scrollWindow]="true" (scrolled)="onScroll($event)">
		<div class="attached infinite-scroll-list" *ngIf="!isLoading" [@fadeInOut]>
			<table class="table table-select-list" [ngClass]="{'is-admin':isAdmin}">
				<caption class="sr-only">List of Purchase Orders</caption>
				<thead class="spacer">
					<th *ngIf="isAdmin" scope="col" class="batch-check"></th>
					<th scope="col" class="po-col"></th>
					<th scope="col" class="customer-col"></th>
					<th scope="col" class="pm-col"></th>
					<th scope="col" class="name-col"></th>
					<th scope="col" class="suppler-col"></th>
					<th scope="col" class="status-col"></th>
					<th scope="col" class="date-col"></th>
					<th scope="col" class="list-actions"></th>
				</thead>
				<tbody>
					<tr class="wo-list-item" *ngFor="let po of poSet">
						<td *ngIf="isAdmin" scope="row">
							<label class="custom-checkbox">
								<input type="checkbox" [(ngModel)]="po.selected" [checked]="po.selected"
									(change)="updateSelected(po)">
								<span class="checkmark"></span>
							</label>
						</td>
						<td scope="row" (click)="editEntity(po)">
							{{ po.number }}
						</td>
						<td scope="row" (click)="editEntity(po)">
							{{ po.job.customer.name }}
						</td>
						<td scope="row" (click)="editEntity(po)">
							<div>{{ po.job.name }}</div>
							<div class="pm-name">
								{{ po.job.projectManager ? po.job.projectManager.firstName + ' ' + po.job.projectManager.lastName : '' }}
							</div>
						</td>
						<td scope="row" (click)="editEntity(po)">
							{{ po.name }}
						</td>
						<td scope="row" (click)="editEntity(po)">
							{{ po.supplier.name }}
						</td>
						<td scope="row" (click)="editEntity(po)">
							<div class="status-options {{po.css}}">{{ po.status }}</div>
						</td>
						<td scope="row" (click)="editEntity(po)">
							{{ po.date | dateFormat: 'MM/DD/YYYY' }}
						</td>
						<td scope="row" class="list-actions">
							<button class="btn btn-default" type="button" (click)="editEntity(po)">Edit</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
		<div *ngIf="!isLoading && !poSet.length" id="loading">No results</div>
	</div>
</div>