<div class="container fixed">
	<div class="row">
		<div class="col-md-10 col-md-offset-1 searchbox">
			<search [(ngModel)]="searchTerm" large="true" (onAction)="search()" placeholder="Search Work Orders" #workOrderSearch keyboardAccelerator="9" (onKeyboardAction)="search()" [isLoading]="isLoading"></search>
		</div>
	</div>
	<div class="row filter-row">
		<div class="col-sm-9 col-md-9">
			<filter-list *ngIf="filterOptions.length" [itemType]="'workOrder'" [activeFilters]="filters" [filterOptions]="filterOptions" (filterValue)="filterTheList($event)"></filter-list>
		</div>
		<div class="col-sm-3 col-md-3">
			<div class="list-actions mt-2">
				<div class="list-filters">
					<button type="button" class="btn btn-default" (click)="filterToggle()" [ngClass]="{'active btn-primary':showClosed}">
						<i class="glyphicon glyphicon-lock"></i><span *ngIf="showClosed"> Hide</span><span *ngIf="!showClosed"> Show</span> Closed
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12 wo-list-column mt-1" id="infinite-scroll-list">
			<table id="wo-list" class="table table-select-list mb-0">
				<caption class="sr-only">Sort list links</caption>
				<thead>
				<tr>
					<th scope="col" class="wo-col">
						<infinite-scroll-header class="sortable" label="WO#" field="number" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="pm-col">
						<infinite-scroll-header class="sortable" label="Job / Project Manager" field="job.number" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="name-col">
						<infinite-scroll-header class="sortable" label="Job Name" field="job.name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="cust-col">
						<infinite-scroll-header class="sortable" label="Customer" field="job.customer.name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="date-col">
						<infinite-scroll-header class="sortable" label="Date Promised" field="datePromised" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="priority-col">
						<infinite-scroll-header class="sortable" label="Priority" field="priority" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="status-col">
						<infinite-scroll-header class="sortable" label="Status" field="status.status" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
					</th>
					<th scope="col" class="list-actions"></th>
				</tr>
				</thead>
			</table>
			<div id="top-of-list-marker"></div>
		</div>
	</div>
</div>
<div class="container list-container">
	<div class="attached infinite-scroll-list"
		infiniteScroll
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		[scrollWindow]="true"
		(scrolled)="onScroll($event)" 
		*ngIf="!isLoading" [@fadeInOut]>
		<table class="table table-select-list">
			<caption class="sr-only">List of work orders</caption>
			<thead class="spacer">
			<th scope="col" class="wo-col"></th>
			<th scope="col" class="pm-col"></th>
			<th scope="col" class="name-col"></th>
			<th scope="col" class="cust-col"></th>
			<th scope="col" class="date-col"></th>
			<th scope="col" class="priority-col"></th>
			<th scope="col" class="status-col"></th>
			<th scope="col" class="list-actions"></th>
			</thead>
			<tbody>
			<tr class="wo-list-item" *ngFor="let wo of workOrderSet" (click)="editEntity(wo)">
				<td scope="row">
					{{ wo.number }}
				</td>
				<td scope="row">
					<div>{{ wo.job.number }}</div>
					<div class="pm-name">{{ wo.job.projectManager ? wo.job.projectManager.firstName + ' ' + wo.job.projectManager.lastName : '' }}</div>
				</td>
				<td scope="row">
					<div>{{ wo.job.name }}</div>
				</td>
				<td scope="row">
					<div>{{ wo.job.customer.name }}</div>
				</td>
				<td scope="row">
					{{ wo.datePromised | dateFormat: 'MM/DD/YYYY' }}
				</td>
				<td scope="row">
         			 {{ wo.priority || ''  }}
				</td>
				<td scope="row">
					<div class="status-options {{wo.css}}">{{ wo.status.status }}</div>
				</td>
				<td scope="row" class="list-actions">
					<button class="btn btn-default" type="button" (click)="editEntity(wo)">Edit</button>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
	<div *ngIf="!isLoading && !workOrderSet.length" id="loading">No results</div>
</div>
