import { Routes } from '@angular/router';

import { LocationComponent } from './../LocationComponent/location.component';
import { LocationListComponent } from './../LocationComponent/locationList.component';
import { LocationEditComponent } from './../LocationComponent/locationEdit.component';

import { StaffListComponent } from './../StaffComponent/staffList.component';
import { StaffAddComponent } from './../StaffComponent/staffAdd.component'
import { StaffEditComponent } from './../StaffComponent/staffEdit.component';

import { UserListComponent } from '../UsersComponent/userList.component';
import { UserAddComponent } from '../UsersComponent/userAdd.component'
import { UserEditComponent } from '../UsersComponent/userEdit.component';
import { MsalGuard } from '@azure/msal-angular';

export const LOCATION_ROUTES: Routes = [
  {
    path: 'Locations',
    canActivate: [MsalGuard],
    component: LocationComponent,
    children: [
      {
        path: '',
        component: LocationListComponent,
      },
      {
        path: 'Add',
        component: LocationEditComponent,
      },
      {
        path: ':locationId/Edit',
        component: LocationEditComponent,
        children: [
          {
            path: '',
            component: StaffListComponent,
          },
          {
            path: 'Staff/Add',
            component: StaffAddComponent,
          },
          {
            path: 'Staff/:staffId',
            component: StaffEditComponent,
          },
          {
            path: '',
            component: UserListComponent,
            outlet: 'users',
          },
          {
            path: 'User/Add',
            component: UserAddComponent,
            outlet: 'users',
          },
          {
            path: 'User/:userId',
            component: UserEditComponent,
            outlet: 'users',
          },
        ],
      },
    ],
  },
];
