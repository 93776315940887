import { Pipe, PipeTransform } from '@angular/core';
import { IPurchaseOrderPartModel } from './../models/part.models';
import { IReceiptModel, IReceiptAggregateModel } from './../models/receipt.models';

@Pipe({
    name: 'receiptAggregate',
    pure: true
})

export class ReceiptAggregatePipe implements PipeTransform {
    transform(value: Array<IPurchaseOrderPartModel>, receipts: Array<IReceiptModel>): Array<IReceiptAggregateModel> {
        let aggregates = new Array<IReceiptAggregateModel>();

        for(let part of value) {
            let aggregate = {
                quantityOrdered: part.quantity,
                unit: part.unit,
                quantityReceived: 0,
                description: part.description,
                date: null,
                location: null
            } as IReceiptAggregateModel;

            let matching = receipts.filter((receipt) => {
                return receipt.part === part._id;
            });

            for(let receipt of matching) {
                // use most-recent to determine date received
                let receiptDate = new Date(receipt.date);
                if(!aggregate.date || (receiptDate > aggregate.date)) {
                    aggregate.date = receiptDate;
                    aggregate.location = receipt.location;
                }

                aggregate.quantityReceived += receipt.quantity;
            }

            aggregates.push(aggregate);
        }

        return aggregates;
    }
};
