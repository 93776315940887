import * as Utility from './utility.models';
import * as dataUtils from './../utils/dataUtils';

export const CostTypes = {
    Materials: 'Materials',
    OutsideServices: 'Outside Services'
};

export interface ICostModel extends Utility.BaseModel {
    date: Date;
    type: string;
    category: string;
    cityTax: number;
    countyTax: number;
    mkecountyTax: number;
    stateTax: number;
    stadiumTax: number;
    cost: number;
    notes: string;
    poNumber: string;
    poId: string;
    supplier: Utility.ISupplierReference;
    invoiceNumber: string;
    timestamp: Date;
    hideMessage: boolean;
    disableCat: boolean;
};

export interface IMoveCost {
    date: Date,
    jobId: string;
    notes: string;
    costs: Array<string>;
}

// <editor-fold> Document models

export class CostModel extends Utility.BaseModel {
    date: Date = dataUtils.sanitizeDate();
    type: string = null;
    category: string = 'other';
    cityTax: number = 0;
    countyTax: number = 0;
    mkecountyTax: number;
    stateTax: number = 0;
    stadiumTax: number = 0;
    cost: number = 0;
    poNumber: string;
    poId: string;
    notes: string = null;
    supplier: Utility.ISupplierReference = null;
    invoiceNumber: string = null;
    override timestamp: Date = null;
    hideMessage: boolean = false;
    disableCat: boolean = false;
};

// </editor-fold>
