/**
 * Created by rrahlf on 7/21/2016.
 */

import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';

import { LoginModel } from '../../models/auth.models';
import { AuthService } from '../../services/auth.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
	selector: 'login',
	templateUrl: './login.template.html',
	host: {'class': 'login-component'}
})
export class LoginComponent extends BaseSmartComponent {
	redirect:string = '/';
	model:LoginModel = new LoginModel();
	public isAuthenticated = false;
	private readonly _destroying$ = new Subject<void>();
	private tokens = {};

	constructor(
		private route : ActivatedRoute, 
		private router: Router,
		private authService : AuthService, 
		messageService : MessageService
		) {
		super(messageService);
	}

	public processLogin() : void {
		this.authService.login();
	}
}
