import { BaseSmartComponent } from './../components/base.component';
import { Router } from '@angular/router';
import { MessageService } from './../services/message.service';
import { PrintService } from '../services/print.service';

export interface IPrintOptions {
    copies: Array<IPrintOptionCopy>;
}

interface IPrintOptionCopy {
    index: string;
    label: string;
    enabled: boolean;
}


export class PrintableComponent extends BaseSmartComponent {
    protected printUrlBase = '/Print/';
    // todo should probably be protected but changed to public because it is referenced directly in templates
    public printOptions : IPrintOptions = {
        copies: new Array<IPrintOptionCopy>()
    };

    constructor(private printRouter: Router, private service: PrintService, messageService: MessageService) {
        super(messageService);
    }

    public print(queryParameters?: Object): void {
        let printUrl = this.printUrlBase + '?';

        if(queryParameters) {
            for(let index in queryParameters) {
                printUrl += '&' + index + '=' + queryParameters[index];
            }
        }

        const copies = this.printOptions.copies.filter(c => c.enabled).map(c => c.label).join(',');
        printUrl += '&copies=' + encodeURIComponent(copies);
        this.service.print(printUrl).subscribe(pdfUrl => {
            if (pdfUrl) {
                window.open(pdfUrl, '_blank');
            }
        });
    }
}
