import { Pipe, PipeTransform } from '@angular/core';

interface IHasWorkOrder {
    workOrder : string
};

@Pipe({
    name: 'attachedToWorkOrder',
    pure: false
})

export class AttachedToWorkOrderPipe implements PipeTransform {
    transform(value : Array<IHasWorkOrder>, workOrderId : string, invert : boolean = false) : Array<IHasWorkOrder> {
        let result = new Array<IHasWorkOrder>();

        if(value && value.length > 0) {
            for(let i = value.length - 1; i >= 0; i -= 1) {
                let include = false;

                if(!invert) {
                    include = (value[i].workOrder === workOrderId);
                }
                else {
                    include = !value[i].workOrder;
                }

                if(include) {
                    result.push(value[i]);
                }
            }
        }

        return result;
    }
};
