import { Injectable } from '@angular/core';
import { StateManager } from './../state/stateManager';
import * as objectUtils from './../utils/objectUtils';
import { take } from 'rxjs';
import { LocationService } from './../services/location.service';

import { IAddress } from './../models/address.models';

@Injectable()
export class DataCopyService {
    constructor(private state : StateManager, private locationService : LocationService) {}

    copyActiveLocationAddress(target : IAddress) : void {
        // location under which active customer is being served
        const self = this;

        self.state.getActiveCustomer().pipe(take(1)).subscribe((s) => {
            if(!s.location) { return; }

            self.locationService.getLocation(s.location as string)
            .then((location) => {
                objectUtils.copyToExisting(location.address, target);
            });
        })
        .unsubscribe();
    }

    copyActiveCustomerAddress(target : IAddress) : void {
        const self = this;

        self.state.getActiveCustomer().pipe(take(1)).subscribe((customer) => {
            objectUtils.copyToExisting(customer.address, target);
        })
        .unsubscribe();
    }

    copyActiveJobAddress(target : IAddress) : void {
        const self = this;

        self.state.getActiveJob().pipe(take(1)).subscribe((job) => {
            objectUtils.copyToExisting(job.address, target);
        })
        .unsubscribe();
    }

}
