import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IInvoiceModel } from './../../models/invoice.models';

@Component({
    selector: 'invoice-list',
    templateUrl: './invoiceList.template.html',
    host: {'class': 'invoice-list-component', id: 'sidebar-content'}
})

export class InvoiceListComponent {
    @Input()
    model = new Array<IInvoiceModel>();

    @Input()
    selectedInvoiceId : string = null;

    @Input()
	showIndicator: boolean = false;

    @Output()
    onInvoiceSelect = new EventEmitter<IInvoiceModel>();

    selectInvoice(invoice: IInvoiceModel) {
        this.onInvoiceSelect.emit(invoice);
    }
}
