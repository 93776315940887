import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UI_MASKS } from './../../utils/uiMasks';

import { ICustomerModel } from './../../models/customer.models';
import { Subject } from 'rxjs';

@Component({
    selector: 'customer-edit-form',
    templateUrl:'./customerEditForm.template.html',
    host: {'class': 'customer-edit-form-component'}
})

export class CustomerEditFormComponent {
    
	@Input()
	model : ICustomerModel;

	@Input()
	showCancel : boolean = false;

	@Input()
	showAlert : boolean = false;

	@Output()
	onSave = new EventEmitter<ICustomerModel>();

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onGoToCust = new EventEmitter<void>();

	public saved : Subject<any> = new Subject();

	constructor(public uiMasks : UI_MASKS) {}

	ngOnInit() {
		this.saved.next('false');
	}

	saveCustomer() : void {
		this.onSave.emit(this.model);
		this.saved.next('true');
	}

	cancel() : void {
		this.onCancel.emit(null);
		this.saved.next('true');
	}

    toggleActive():void{
        this.model.isActive = !this.model.isActive;
    }

    goToCustomer() {
		this.showAlert = false;
        this.onGoToCust.emit(null);
    }
}
