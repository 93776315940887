
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.SENTRY_ENVIRONMENT === 'production' || environment.SENTRY_ENVIRONMENT === 'staging') {
  Sentry.init({
    environment: environment.SENTRY_ENVIRONMENT,
    release: `${environment.PACKAGE_NAME}@${environment.PACKAGE_VERSION}`,
    dsn: environment.DSN_KEY,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.APIBASEURL, /^\//],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.SENTRY_ENVIRONMENT === 'production' ? 0.2 : 1,
  });

  enableProdMode();
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log(`Bootstrap success`))
    .catch((err) => console.error(err));
}