import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';

import { BaseSmartComponent } from './../base.component';

import { MessageService } from '@/services/message.service';
import { JobService } from '@/services/job.service';

import { IContactModel } from '@/models/contact.models';
import { IJobModel } from '@/models/job.models';
import { IUserModel } from '@/models/user.models';
@Component({
    selector: 'job-add-component',
    templateUrl: './jobAdd.template.html',
    host: {'class': 'job-add-component'}
})

export class JobAddComponent extends BaseSmartComponent implements OnInit {
    private _id : string;
    private _customerId : string;
    private _customerLocation : string;
    public model : Observable<IJobModel>;
    public contacts : Array<IContactModel>;
    public users : Observable<Array<IUserModel>>;
    public requireAmount: boolean;
    public view: string = 'add';

    constructor(
        private router: Router, 
        private route: ActivatedRoute, 
        private state : StateManager, 
        messageService : MessageService, 
        private jobService : JobService
    ) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;
        
        self.model = self.state.getActiveJob();
        self.users = self.state.getUsersList();
        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
    }

    public saveJob(newModel: IJobModel) : void {
        const self = this;
        newModel.location = self._customerLocation;
        self.jobService.updateJob(self._customerId, newModel)
            .then((result) => {
                // creating new - actually go to the new job in edit mode
                self.router.navigate(['Job', result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(self.route, 4) });
            });
    }

    public cancel() : void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }
}
