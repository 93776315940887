import { Injectable } from '@angular/core';


@Injectable()
export class AppSettingsService {
	constructor() {}

	getSettings() {
		const showLaborCodes = false;
        const appSettings = {
            showLaborCodes: showLaborCodes,
            // additionalTaxes: [
            //     {type: 'City', location: 'Milwaukee', value: .02}
            // ]
        }
        return appSettings;
	}
}
