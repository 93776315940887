import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadDirective, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {IMaskModule} from 'angular-imask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PurchaseOrderSearchComponent } from './purchaseOrderSearch.component';
import { PurchaseOrderComponent } from './purchaseOrder.component';
import { PurchaseOrderAddComponent } from './purchaseOrderAdd.component';
import { PurchaseOrderEditComponent } from './purchaseOrderEdit.component';
import { PurchaseOrderEditFormComponent } from './purchaseOrderEditForm.component';
import { PurchaseOrderConnectionComponent } from './purchaseOrderConnection.component';
import { PurchaseOrderInvoiceConnectionComponent } from './purchaseOrderInvoiceConnection.component';
import { PurchaseOrderPayableTabComponent } from './purchaseOrderPayableTab.component';
import { PurchaseOrderListComponent } from './purchaseOrderList.component';
import { PurchaseOrderPartsComponent } from './purchaseOrderParts.component';
import { PurchaseOrderReceiptsComponent } from './purchaseOrderReceipts.component';
import { PurchaseOrderDocumentsComponent } from './purchaseOrderDocuments.component';

import { CostsModule } from '@/components/CostsComponent/costs.module';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { WorkflowModule } from '@/components/Workflow/workflow.module';
import { FileUploadModule } from '@/components/FileUploadComponent/file-upload.module';
import { FilterListModule } from '@/components/FilterListComponent/filterList.module';
import { ExportModule } from '@/components/ExportComponent/export.module';
import { ModalModule } from '@/components/ModalComponent/modal.module';
import { UtilityModule } from '@/utils/utility.module';

@NgModule({
	declarations: [
		PurchaseOrderSearchComponent,
		PurchaseOrderComponent,
		PurchaseOrderAddComponent,
		PurchaseOrderEditComponent,
		PurchaseOrderEditFormComponent,
		PurchaseOrderConnectionComponent,
		PurchaseOrderInvoiceConnectionComponent,
		PurchaseOrderPayableTabComponent,
		PurchaseOrderListComponent,
		PurchaseOrderPartsComponent,
		PurchaseOrderReceiptsComponent,
		PurchaseOrderDocumentsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		InfiniteScrollModule,
		NgbModule,
        TypeaheadModule.forRoot(),
		IMaskModule,
		UtilityModule,
		CostsModule,
		DocumentUploadModule,
		WorkflowModule,
		FileUploadModule,
		FilterListModule,
		ExportModule,
        ModalModule
	],
	exports: [
		PurchaseOrderSearchComponent,
		PurchaseOrderComponent,
		PurchaseOrderAddComponent,
		PurchaseOrderEditComponent,
		PurchaseOrderConnectionComponent,
		PurchaseOrderInvoiceConnectionComponent,
		PurchaseOrderPayableTabComponent,
		PurchaseOrderListComponent,
		PurchaseOrderPartsComponent,
		PurchaseOrderReceiptsComponent,
		PurchaseOrderDocumentsComponent
	]
})
export class PurchaseOrderModule {}
