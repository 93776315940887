<div id="supplier-summary">
<h2 class="supplier-name">{{ model.name }}</h2>

<dl class="supplier-summary-info">
	<dt>Phone</dt>
	<dd>{{ model.main | phoneFormat }}</dd>

	<dt>Address</dt>
	<dd>
		<address>
			<span class="street1">{{ model.address.address1 }}</span>
			<span class="street2">{{ model.address.address2 }}</span>
			<span class="city">{{ model.address.city }}</span>,
			<span class="state">{{ model.address.state }}</span>
			<span class="zip">{{ model.address.zip | zipFormat }}</span>
		</address>
	</dd>
</dl>
</div>
