import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadDirective, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BooleanIconPipe } from './booleanIcon.pipe';
import { CurrencyFormatPipe, CurrencyEmptyFormatPipe } from './currencyFormat.pipe';
import { DatePipe } from '@angular/common';
import { DateFormatPipe } from './dateFormat.pipe';
import { DateFormatUSPipe } from './dateFormatUS.pipe';
import { PhoneFormatPipe } from './phoneFormat.pipe';
import { ZipFormatPipe } from './zipFormat.pipe';
import { ZipFormatCANPipe } from './zipFormatCAN.pipe';
import { FilterPipe } from './filter.pipe';
import { ReceiptAggregatePipe } from './receiptAggregate.pipe';
import { GroupByPipe } from './groupBy.pipe';
import { ActivePipe } from './active.pipe';
import { InactiveUserPipe } from './inactiveUser.pipe';
import { LookupPipe } from './lookup.pipe';
import { NotEmptyPipe } from './notEmpty.pipe';
import { ArraySortPipe } from './sort.pipe';
import { EnabledPagesPipe } from './enabledPages.pipe';
import { StatusFilterPipe } from './statusFilter.pipe';
import { UI_MASKS } from './uiMasks';

import { EnforceDecimalDirective } from './enforceDecimal.directive';
import { ValidatePhoneDirective } from './validatePhone.directive';
import { FilterDataDirective } from './filterData.directive';
import { InitialFocusDirective } from './initialFocus.directive';
import { KeyboardAcceleratorDirective } from './keyboardAccelerator.directive';

import { PrintMenuComponent } from './printMenu.component';
import { PrintButtonComponent } from './printButton.component';
import { PriorityComponent } from './priority.component';
import { StateSelectorComponent } from './stateSelector.component';
import { SortableHeaderComponent } from './sortableHeader.component';
import { SearchComponent } from './search.component';
import { InfiniteScrollHeaderComponent } from './infiniteScrollHeader.component';
import { NotesFieldComponent } from './notesField.component';
import { StatusLogComponent } from './statusLog.component';
import { MonthpickerComponent } from './monthPicker.component';
import { PaginationComponent } from './pagination.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
@NgModule({
	declarations: [
		BooleanIconPipe,
		CurrencyFormatPipe,
		CurrencyEmptyFormatPipe,
		DateFormatPipe,
		DateFormatUSPipe,
		PhoneFormatPipe,
		ZipFormatPipe,
		ZipFormatCANPipe,
		FilterPipe,
		ReceiptAggregatePipe,
		GroupByPipe,
		ActivePipe,
		InactiveUserPipe,
		LookupPipe,
		NotEmptyPipe,
		ArraySortPipe,
		EnabledPagesPipe,
		StatusFilterPipe,
		EnforceDecimalDirective,
		ValidatePhoneDirective,
		FilterDataDirective,
		InitialFocusDirective,
		KeyboardAcceleratorDirective,
		PrintMenuComponent,
		PrintButtonComponent,
		PriorityComponent,
		StateSelectorComponent,
		SortableHeaderComponent,
		SearchComponent,
		InfiniteScrollHeaderComponent,
		NotesFieldComponent,
		StatusLogComponent,
		MonthpickerComponent,
		PaginationComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TypeaheadModule,
		NgbModule,
		BsDropdownModule,
		PaginationModule
	],
	exports: [
		BooleanIconPipe,
		CurrencyFormatPipe,
		CurrencyEmptyFormatPipe,
		DateFormatPipe,
		DateFormatUSPipe,
		PhoneFormatPipe,
		ZipFormatPipe,
		ZipFormatCANPipe,
		FilterPipe,
		ReceiptAggregatePipe,
		GroupByPipe,
		ActivePipe,
		InactiveUserPipe,
		LookupPipe,
		NotEmptyPipe,
		ArraySortPipe,
		EnabledPagesPipe,
		StatusFilterPipe,
		EnforceDecimalDirective,
		ValidatePhoneDirective,
		FilterDataDirective,
		InitialFocusDirective,
		KeyboardAcceleratorDirective,
		PrintMenuComponent,
		PrintButtonComponent,
		PriorityComponent,
		StateSelectorComponent,
		SortableHeaderComponent,
		SearchComponent,
		TypeaheadDirective,
		InfiniteScrollHeaderComponent,
		NotesFieldComponent,
		StatusLogComponent,
		MonthpickerComponent,
		PaginationComponent
	],
	providers: [
		UI_MASKS, DatePipe
	]
})
export class UtilityModule { }
