/**
 * Created by rrahlf on 8/3/2016.
 */

import { Component, Input, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@/services/auth.service';
import { Subscription, filter } from 'rxjs';
import { IAuthUser, UserRole } from '@/models/auth.models';

@Component({
	selector: 'site-area',
	templateUrl: './siteArea.template.html',
	host: {'class': 'site-area-component'}
})
export class SiteAreaComponent {
	@Input()
	user: IAuthUser;

	area: string;
	pathSub: Subscription;
	showTimeEntry: boolean = false;
	showCosts: boolean = false;
	showReports: boolean = false;
	viewMenu: boolean = false;
	constructor(private authService: AuthService, private router: Router) { }

	ngOnInit(): void {
		this.area = '';
		this.pathSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
			let url = (<NavigationEnd>event).urlAfterRedirects;
			if (url.indexOf('Admin/Locations') > -1) {
				this.area = 'Locations';
				return;
			}

			if (url.indexOf('Admin/Suppliers') > -1) {
				this.area = 'Suppliers';
				return;
			}

			if (url.indexOf('Customers') > -1) {
				this.area = 'Customers';
				return;
			}

			if (url.indexOf('PurchaseOrders') > -1) {
				this.area = 'PurchaseOrders';
				return;
			}

			if (url.indexOf('Jobs') > -1) {
				this.area = 'Jobs';
				return;
			}

			if (url.indexOf('WorkOrders') > -1) {
				this.area = 'WorkOrders';
				return;
			}

			if (url.indexOf('Invoices') > -1) {
				this.area = 'Invoices';
				return;
			}

			if (url.indexOf('Payables') > -1) {
				this.area = 'Payables';
				return;
			}
			if (url.indexOf('Reports') > -1) {
				this.area = 'Reports';
				return;
			}
		});
		this.setUserRole();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['user']) {
			this.setUserRole();
		}
	}

	toggleMenu(): void {
		this.viewMenu = !this.viewMenu;
	}

	setUserRole(): void {
		this.showReports = (this.user && (this.user.role != UserRole.ShopForeman));
	}
}
