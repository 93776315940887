<print-button *ngIf="!dirty" class="print-hide"></print-button>

<div class="screen-title">
    Receivables Summary Report
</div>

<style>
    @media screen {
        .ascii {
            display: none;
        }
    }

</style>

<div class="row report-controls print-hide">
    <div class="col-md-3">
        <div class="form-group">
            <div class="date-selector">
                <label>Start Date</label>
                <input autocomplete="off" class="form-control" name="startDate" [ngModel]="startDate | dateFormat" (ngModelChange)="startDate = sanitizeDate($event); markDirty()" type="date" initialFocus />
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <div class="date-selector">
                <label>End Date</label>
                <input autocomplete="off" class="form-control" name="endDate" [ngModel]="endDate | dateFormat" (ngModelChange)="endDate = sanitizeDate($event); markDirty()" type="date" />
            </div>
        </div>
    </div>
    <div class="col-md-6 actions mt-2">
        <button class="btn btn-primary" (click)="runReport()" [disabled]="!dirty || !startDate || !endDate">Run Report</button>
    </div>
</div>

<div id="reportPane" [ngClass]="{'dirty': dirty}">
    <table class="table table-select-list mb-0">
        <caption>
            Report for {{ startDate | dateFormat:'MMM DD, YYYY' }} to {{ endDate | dateFormat:'MMM DD, YYYY' }}
        </caption>
        <thead>
            <tr>
                <th scope="col" style="width:15%;">
                    <sortable-header field="date" label="Date" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" style="width:15%;">
                    <sortable-header field="number" label="Number" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" style="width:25%;">
                    <sortable-header field="customerName" label="Customer" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" style="width:30%;">
                    <sortable-header field="jobNumber" label="Job #" [sortManager]="dataManipulationService"></sortable-header><span style="padding:0 17px;">/</span>
                    <sortable-header field="jobName" label="Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col"  style="width:15%; text-align:right;padding-right:1rem;">
                    <sortable-header field="total" label="Total Sell" [sortManager]="dataManipulationService"></sortable-header>
                </th>
            </tr>
        </thead>
    </table>

    <div class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">List of receivables</caption>
            <thead class="column-spacer">
                <tr>
                    <th scope="col" style="width:15%;"></th>
                    <th scope="col" style="width:15%;"></th>
                    <th scope="col" style="width:25%;"></th>
                    <th scope="col" style="width:30%;"></th>
                    <th scope="col" style="width:15%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of model | async" [@fadeInOut]>
                    <td scope="row">
                        {{ invoice.date | dateFormat:'MMM DD, YYYY' }}
                    </td>
                    <td scope="row">
                        <a (click)="editInvoice(invoice)">{{ invoice.number }}</a>
                    </td>
                    <td scope="row">
                        <a (click)="editCustomer(invoice)">{{ invoice.customer.name }}</a>
                    </td>
                    <td scope="row">
                        <a (click)="editJob(invoice)">{{ invoice.job.number }} - {{ invoice.job.name }}</a>
                    </td>
                    <td scope="row" class="currency-display" style="padding-right:1rem;">
                        {{ invoice.total | currencyFormat }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <table class="table select-list">
        <caption class="sr-only">Receivable totals</caption>
        <thead class="spacer">
            <tr>
                <th scope="col" colspan="4"></th>
                <th scope="col" style="width:140px;"></th>
            </tr>
        </thead>
        <tfoot>
            <tr class="list-item-group">
                <th scope="row" colspan="4" class="currency-display">
                    Grand total:
                </th>
                <th scope="row" class="currency-display" style="padding-right:1rem;width:120px;">
                    {{ grandTotal | currencyFormat }}
                </th>
            </tr>
        </tfoot>
    </table>
</div>
