import { Injectable, ApplicationRef, EventEmitter, Output } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

interface UpdateNotification {
	needsUpdate: boolean;
	previousHash: string;
	currentHash: string;
}

@Injectable()
export class AppVersionService {
	@Output()
	updateNotification: EventEmitter<UpdateNotification> = new EventEmitter<UpdateNotification>();

	public needsUpdate: boolean = false;

	constructor(
		private appRef: ApplicationRef,
		private update: SwUpdate
	) {}

	enableUpdates() {
		if ( !this.update.isEnabled ) {
			// Service Worker is not enabled:
			// could be because the app is in dev mode or because it's being
			// served with ng-serve or because the browser doesn't support it
			return;
		}

		this.updateClient();
		this.checkUpdate();
	}

	updateClient() {
		// subscribe to update available event
		this.update.versionUpdates.subscribe( event => {
			switch( event.type ) {
				case 'VERSION_DETECTED':
					console.log( `Downloading new app version: ${ event.version.hash }` );
					break;

				case 'VERSION_READY':
					console.log( `Current app version ${ event.currentVersion.hash }` );
					console.log( `New app version ready for use: ${ event.latestVersion.hash }` );

					this.updateNotification.emit({
						needsUpdate: true,
						previousHash: event.currentVersion.hash,
						currentHash: event.latestVersion.hash
					});

					break;

				case 'VERSION_INSTALLATION_FAILED':
					console.log( `Failed to install app version ${ event.version.hash }` );
					break;
			}
		});
	}

	checkUpdate() {
		const appIsStable$ = this.appRef.isStable.pipe( first( isStable => isStable === true ) );
		const minutes = 10;
		const timeInterval$ = interval( 1000 * 60 * minutes );
		const intervalAfterStable$ = concat( appIsStable$, timeInterval$ );

		intervalAfterStable$.subscribe( () => {
			// App is stable and it's been 10 minutes

			this.update.checkForUpdate().then( () => {
				// service worker has checked for update
			});
		});
	}
}
