import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import * as objectUtils from '@/utils/objectUtils';

import { BaseSmartComponent } from '@/components/base.component';

import { CustomerModel } from '@/models/customer.models';
import { JobModel, JobSummaryModel } from '@/models/job.models';

import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { CustomerService } from '@/services/customer.service';
import { JobService } from '@/services/job.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { AppSettingsService } from '@/services/appSettings.service';

@Component({
    selector: 'job',
    templateUrl: '../Layout/layout-sidebar-wide.template.html',
    host: {'class': 'job-component', id: 'jobComponent'}
})

export class JobComponent extends BaseSmartComponent implements OnInit {
    private _jobId : string;
    private _customerId : string;

    private model = new JobSummaryModel();
    public isLoading: boolean = false;
	public appSettings: Object;
	navigationSubscription;

    constructor(
    	    private state : StateManager,
	        private router : Router,
	        private route : ActivatedRoute,
            messageService : MessageService,
            private customerService : CustomerService,
	        private jobService : JobService,
            private workOrderService: WorkOrderService,
            private purchaseOrderService: PurchaseOrderService,
            private filesService: FilesService,
            private settingsService: AppSettingsService,
            )
    {
        super(messageService);
        // todo this should be refactored because we shouldn't call ngOnInit directly
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd && !!this._jobId && this._jobId != routerUtils.getRouteParameter(this.route, 'jobId')) {
                this.ngOnInit();
			}
		});
		this.appSettings = this.settingsService.getSettings();
    }

    ngOnInit() : void {
	    //grab entity query data
	    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
	    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

	    //start queries
        this.customerService.getCustomer(this._customerId);
	    this.jobService.getJob(this._customerId, this._jobId);
	    this.workOrderService.getWorkOrders(this._customerId, this._jobId);

	    //watch for query results
        this.watchSubscription(this.state.getActiveJob().subscribe((s) => {
            this.isLoading = true;
            this.model.job = objectUtils.copyToExisting(s, new JobModel());
            // adding a small delay so another search cannot be run while job data is loading
            setTimeout(() => {
                this.isLoading = false;
            }, 1500);
        }));

        this.watchSubscription(this.state.getActiveCustomer().subscribe((s) => {
            this.model.customer = objectUtils.copyToExisting(s, new CustomerModel());
        }));
    }

    override ngOnDestroy() : void {
        this.state.setActiveJob(new JobModel());
        super.ngOnDestroy();
		if (this.navigationSubscription) {  
            this.navigationSubscription.unsubscribe();
         }
     }
}
