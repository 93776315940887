import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { IReportInvoiceSummaryResult } from '@/models/report.models';
import { ReportService } from '@/services/report.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ReportInvoiceSummarySortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
	selector: 'report-invoice-summary',
	templateUrl: './reportInvoiceSummary.template.html',
  host: {'class': 'report-invoice-summary-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class ReportInvoiceSummaryComponent extends BaseSmartComponent implements OnInit {
	public startDate: Date = this.sanitizeDate();
	public endDate: Date = this.sanitizeDate();
	public dirty: boolean = true;
	public model: Subject<Array<IReportInvoiceSummaryResult>> = new Subject<Array<IReportInvoiceSummaryResult>>();
	public grandTotal: number = 0;

	private _location: string;

	constructor(
		private reportService: ReportService,
		private route: ActivatedRoute,
		private router: Router,
		public dataManipulationService: DataManipulationService<IReportInvoiceSummaryResult>,
		messageService: MessageService
	) {
		super(messageService);
	}

	ngOnInit(): void {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this._location = currentUser?.locationId; // TODO: if there's no currentUser, that means the user is logged out and should get booted to login screen
		this.dataManipulationService.initialize({}, ReportInvoiceSummarySortFunctions);
		this.dataManipulationService.setSort('date', false);
	}

	public runReport(): void {
    this.grandTotal = 0;
    let results = this.reportService.getInvoiceSummaryReport(new Date(this.startDate), new Date(this.endDate), this._location);
    this.watchSubscription(this.dataManipulationService.connectDataSource(results, this.model));

    results.toPromise()
			.then(reportResults => {
				for (let result of reportResults) {
          this.grandTotal += result.total;
				}
				this.dirty = false;
			})
			.catch(handleError);
	}

	public markDirty(): void {
		this.dirty = true;
	}

	private editInvoice(invoice: IReportInvoiceSummaryResult): void {
		this.router.navigate(
		  ['/Customers', invoice.customer._id, 'Job', invoice.job._id, 'Invoices', invoice._id, 'Edit'],
    { relativeTo: routerUtils.getParentRoute(this.route) }
      );
	}

	private editCustomer(invoice: IReportInvoiceSummaryResult): void {
    this.router.navigate(
      ['/Customers', invoice.customer._id, 'Edit', 'Details'],
      { relativeTo: routerUtils.getParentRoute(this.route) }
      );
	}

	private editJob(invoice: IReportInvoiceSummaryResult): void {
		this.router.navigate(
		  ['/Customers', invoice.customer._id, 'Job', invoice.job._id, 'Edit'],
    { relativeTo: routerUtils.getParentRoute(this.route) }
      );
	}
}
