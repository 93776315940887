<div id="loading" class="data-loading" *ngIf="!model">Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>

<form class="" (ngSubmit)="startSave()" #invoiceForm="ngForm" *ngIf="model" autocomplete="off" [@fadeInOut]>
    <div *ngIf="!isNew" [@fadeInOut]>
        <div class="row">
            <div class="col-xs-12">
                <div class="screen-title">Invoice #{{ model._id ? model.invoiceNumber : 'Automatic' }}</div>
            </div>
        </div>
        <workflow [item]="model" [type]="3" [role]="role" [priority]="model.priority" [updatePriority]="updatePriority" (onUpdate)="saveInvoice()"></workflow>
    </div>
    <div class="row mb-2 pb-1 border-bottom">
        <div class="col-xs-12 col-md-6">
            <fieldset>
                <legend class="mb-0">Customer Information</legend>
                <div *ngIf="isNew" class="form-group">
                    <label>ID #</label>
                    <input type="text" class="form-control" name="ID" disabled placeholder="Auto-generated" />
                </div>
                <div class="form-group">
                    <label>Date Created</label>
                    <input class="form-control width-auto" type="date" [ngModel]="model.invoiceDate | dateFormat" (ngModelChange)="model.invoiceDate = sanitizeDate($event); updateAutofillValues()"
                    name="invoiceDate" required="required" #invoiceDate="ngModel" tabindex="1"
                    />
                    <div class="alert alert-danger" [hidden]="(!invoiceForm.form.submitted && invoiceDate.pristine) || invoiceDate.valid">
                        Invoice date is required
                    </div>
                </div>
                <div class="form-group">
                    <label>Date Promised</label>
                    <div class="date-button">
                        <div class="input-group">
                            <div class="input-group-btn">
                                <button class="btn btn-outline" type="button" (click)="model.datePromised = 'ASAP'" [ngClass]="{ 'btn-warning active': model.datePromised === 'ASAP' }" tabindex="7" style="margin-right:-1px;">ASAP</button>
                            </div>
                            <input class="form-control combo-date" type="text" [(ngModel)]="model.datePromised" name="datePromised" #datePromised="ngModel" autocomplete="off" tabindex="8" />
                        </div>
                        <div class="date-selector-overlay" style="position: relative;">
                            <input class="form-control" type="date" [ngModel]="datePromised" (ngModelChange)="setDatePromised($event)" name="date" autocomplete="off" tabindex="9" />
                            <i class="glyphicon glyphicon-calendar"></i>
                        </div>
                    </div>
                </div>
                <div class="form-group" style="position: relative;">
                    <priority-component [model]="model" [isNew]="isNew" (changedPriority)="setPriority($event)"></priority-component>
                </div>
    
                <div class="form-group" style="margin-top:36px;">
                    <label>Billing #</label>
                    <div class="">
                        <div class="input-group full-width">
                            <div class="form-control read-only" style="width:auto">
                                {{ model.notBilled ? 'automatic numbering' : (model.billingNumber ? model.billingNumber : 'automatic numbering') }}
                            </div>

                            <button class="btn btn-default" [ngClass]="{active:!model.notBilled}" type="button" (click)="toggleBilled()" tabindex="2">{{ model.notBilled ? 'Off' : 'On' }}</button>

                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Order #</label>
                    <input class="form-control" type="text" [(ngModel)]="model.orderNumber" name="orderNumber" #orderNumber="ngModel" tabindex="3"/>
                </div>
                <div class="form-group">
                    <label>Date Ordered</label>
                    <input class="form-control width-auto" type="date" [ngModel]="model.dateOfOrder | dateFormat" (ngModelChange)="model.dateOfOrder = sanitizeDate($event)"
                        name="dateOfOrder" #dateOfOrder="ngModel" tabindex="4" />
                </div>

                <div class="form-group">
                    <label>Sold By</label>
                    <div class="">
                        <select class="form-control" [(ngModel)]="model.soldBy" name="soldBy" #soldBy="ngModel" tabindex="5">
                            <option *ngFor="let user of users" [value]="user._id">
                                {{ user.firstName }} {{ user.lastName }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label>Pay Type</label>
                    <div class="">
                        <select class="form-control" [(ngModel)]="model.payType" name="payType" #payType="ngModel" tabindex="6">
                            <option *ngFor="let payType of payTypes" [value]="payType">
                                {{ payType }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label>Bill To</label>
                    <div class="">
                        <select class="form-control" [(ngModel)]="model.billTo" name="billTo" #billTo="ngModel" tabindex="10">
                            <option *ngFor="let billTo of billingOptions" [value]="billTo.id">
                                {{ billTo.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label>VIA</label>
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="input-group">
                                <div class="input-group-btn">
                                    <button type="button" (click)="toggleVia()" class="btn btn-outline dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabindex="11" style="margin-right:-1px; z-index:5;">Select <span class="caret"></span></button>
                                    <ul class="dropdown-menu" [ngClass]="{openDropdown:viaOpen}">
                                        <li (click)="selectVia('Delivery')">Delivery</li>
                                        <li (click)="selectVia('Will Call')">Will Call</li>
                                        <li (click)="selectVia('Glazier')">Glazier</li>
                                        <li (click)="selectVia('Other')">Other</li>
                                    </ul>
                                </div>
                                <input type="text" class="form-control" aria-label="Enter custom option" [(ngModel)]="model.via" name="via" tabindex="12"/>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xs-12 col-md-6">
            <fieldset>
                <legend style="margin-bottom:25px;">Delivery / Work Location</legend>

                <div class="form-group row">
                    <div class="col-xs-6 col-md-5">
                        <button class="btn btn-default" type="button" (click)="copyJobAddress()" tabindex="13" style="white-space: normal">Copy from job address</button>
                    </div>
                    <div class="col-xs-6 col-md-7">
                        <button class="btn btn-default" type="button" (click)="copyCustomerAddress()" tabindex="14" style="white-space: normal">Copy from customer address</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-xs-12" *ngIf="model">
                        <div class="invoice-flag">
                            <label class="custom-radio"><span class="custom-label">Deliver To</span>
                                <input type="radio" name="deliverTo" value="deliverTo" [(ngModel)]="deliveryType" (ngModelChange)="setDeliveryType()" tabindex="15">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                        <div class="invoice-flag">
                            <label class="custom-radio"><span class="custom-label">Work Location</span>
                                <input type="radio" name="workLocation" value="workLocation" [(ngModel)]="deliveryType" (ngModelChange)="setDeliveryType()" tabindex="16">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                        <div class="invoice-flag">
                            <label class="custom-radio"><span class="custom-label">Will Call</span>
                                <input type="radio" name="willCall" value="willCall" [(ngModel)]="deliveryType" (ngModelChange)="setDeliveryType()" tabindex="17">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div *ngIf="deliveryType != 'willCall'" class="form-group">
                    <label>Address 1</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.deliveryAddress.address1" name="address1" [required]="!model.willCall"
                            #address1="ngModel" tabindex="18" />
                        <div class="alert alert-danger" [hidden]="(!invoiceForm.form.submitted && address1.pristine) || address1.valid">
                            Address 1 is required
                        </div>
                    </div>
                </div>

                <div *ngIf="deliveryType != 'willCall'" class="form-group">
                    <label>Address 2</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.deliveryAddress.address2" name="address2" tabindex="19" />
                    </div>
                </div>

                <div *ngIf="deliveryType != 'willCall'" class="form-group">
                    <label class="control-label city-st-zip"><div class="city">City</div><div class="state">State</div><div class="zip">Zip</div></label>
                    <div class="">
                        <div class="input-group full-width">
                            <input type="text" class="form-control city" [(ngModel)]="model.deliveryAddress.city" name="city" [required]="!model.willCall" #city="ngModel" tabindex="20"/>
                            <state-selector [(ngModel)]="model.deliveryAddress.state" name="state" [required]="!model.willCall" #state="ngModel" tabindex="21"></state-selector>
                            <input type="text" class="form-control zip" [(ngModel)]="model.deliveryAddress.zip" name="zip" [required]="!model.willCall" #zip="ngModel" [imask]="{ mask: '00000-0000' }" tabindex="22" />
                        </div>

                        <div class="alert alert-danger" [hidden]="(!invoiceForm.form.submitted && city.pristine) || city.valid">
                            City is required
                        </div>

                        <div class="alert alert-danger" [hidden]="(!invoiceForm.form.submitted && state.pristine) || state.valid">
                            State is required
                        </div>

                        <div class="alert alert-danger" [hidden]="(!invoiceForm.form.submitted && zip.pristine) || zip.valid">
                            Zip is required
                        </div>
                    </div>
                </div>

                <div *ngIf="deliveryType != 'willCall'" class="form-group">
                    <label>County</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.deliveryAddress.county" name="deliveryCounty" tabindex="23" />
                    </div>
                </div>
                <div class="form-group">
                    <label>Phone</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.deliveryWorkPhone" name="deliveryWorkPhone" #deliveryWorkPhone="ngModel"
                        [imask]="{ mask: '(000) 000-0000 x00000' }" [required]="model.willCall" tabindex="24"/>
                        <div class="alert alert-danger" [hidden]="(!invoiceForm.form.submitted && deliveryWorkPhone.pristine) || deliveryWorkPhone.valid">
                                Phone is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Attn</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.deliveryAddress.attn" name="deliveryAttn" tabindex="25" />
                    </div>
                </div>
            </fieldset>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-lg-6">
            <fieldset>
                <legend id="invoiceDetailsLabel">Invoice Details</legend>

                <table class="line-items" aria-describedby="invoiceDetailsLabel">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" class="currency-display">Calculated</th>
                            <th scope="col"></th>
                            <th scope="col" class="invoice-amount currency-display">This invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">
                                <label>Original Contract</label>
                            </td>
                            <td scope="row" colspan="3" class="text-right">
                                {{ autofill.originalContract | currencyFormat }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Change Orders to Date</label>
                            </td>
                            <td scope="row" class="text-right">
                                {{ autofill.changeOrdersToDate | currencyFormat }}
                            </td>
                            <td scope="row" class="copy">
                                <!-- <button class="btn btn-default" type="button" (click)="copyAutofillValue('changeOrdersToDate')">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button> -->
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.changeOrdersToDate" name="changeOrdersToDate" #changeOrdersToDate="ngModel"
                                        enforceDecimal [comma]="true" placeholder="0.00" tabindex="36"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Total Contract to Date</label>
                            </td>
                            <td scope="row" colspan="3" class="text-right">
                                {{ getTotalContractAmount() | currencyFormat }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Amount Previously Invoiced</label>
                            </td>
                            <td scope="row" colspan="3" class="text-right">
                                {{ autofill.amountPreviouslyInvoiced | currencyFormat }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Amount on This Invoice</label>
                            </td>
                            <td scope="row"></td>
                            <td scope="row"></td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.amountThisInvoice" name="amountThisInvoice"
                                        #amountThisInvoice="ngModel" enforceDecimal [comma]="true" placeholder="0.00"  tabindex="37"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Amount Remaining</label>
                            </td>
                            <td scope="row" colspan="3" class="text-right">
                                {{ getAmountRemaining() | currencyFormat }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>County Sales Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="input-group tax-rate" *ngIf="!isTaxExempt">
                                    <input class="form-control" type="text" [ngModel]="salesTaxRates.countyTax" (ngModelChange)="salesTaxRates.countyTax = $event; updateUseTax()"
                                        name="countyTaxRate" enforceDecimal [comma]="true" placeholder="0.00" tabindex="38" />
                                    <span class="input-group-addon">%</span>
                                </span>
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getSellTaxAmount('countyTax') | currencyFormat }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copySellTaxAmount('countyTax')" *ngIf="!isTaxExempt" tabindex="39">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.sellTax.countyTax" name="countyTax" #countyTax="ngModel" enforceDecimal
                                        [comma]="true" [disabled]="isTaxExempt" placeholder="0.00" tabindex="40" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Milwaukee County Sales Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="input-group tax-rate" *ngIf="!isTaxExempt">
                                    <input class="form-control" type="text" [ngModel]="salesTaxRates.mkecountyTax" (ngModelChange)="salesTaxRates.mkecountyTax = $event; updateUseTax()"
                                        name="mkecountyTaxRate" enforceDecimal [comma]="true" placeholder="0.00" tabindex="40" />
                                    <span class="input-group-addon">%</span>
                                </span>
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getSellTaxAmount('mkecountyTax') | currencyFormat }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copySellTaxAmount('mkecountyTax')" *ngIf="!isTaxExempt" tabindex="41">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.sellTax.mkecountyTax" name="mkecountyTax" #mkecountyTax="ngModel" enforceDecimal
                                        [comma]="true" [disabled]="isTaxExempt" placeholder="0.00" tabindex="42" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>State Sales Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="input-group tax-rate" *ngIf="!isTaxExempt">
                                    <input class="form-control" type="text" [ngModel]="salesTaxRates.stateTax" (ngModelChange)="salesTaxRates.stateTax = $event; updateUseTax()"
                                        name="stateTaxRate" enforceDecimal [comma]="true" placeholder="0.00" tabindex="45" />
                                    <span class="input-group-addon">%</span>
                                </span>
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getSellTaxAmount('stateTax') | currencyFormat }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copySellTaxAmount('stateTax')" *ngIf="!isTaxExempt" tabindex="46">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.sellTax.stateTax" name="stateTax" #stateTax="ngModel" enforceDecimal
                                        [comma]="true" [disabled]="isTaxExempt" placeholder="0.00" tabindex="47" />
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <td scope="row">
                                <label>City Sales Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="input-group tax-rate" *ngIf="!isTaxExempt">
                                    <input class="form-control" type="text" [ngModel]="salesTaxRates.cityTax" (ngModelChange)="salesTaxRates.cityTax = $event; updateUseTax()"
                                        name="cityTaxRate" enforceDecimal [comma]="true" placeholder="0.00" tabindex="50" />
                                    <span class="input-group-addon">%</span>
                                </span>
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getSellTaxAmount('cityTax') | currencyFormat }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copySellTaxAmount('cityTax')" *ngIf="!isTaxExempt" tabindex="51">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.sellTax.cityTax" name="cityTax" #cityTax="ngModel" enforceDecimal
                                        [comma]="true" [disabled]="isTaxExempt" placeholder="0.00" tabindex="52" />
                                </div>
                            </td>
                        </tr>
                        <!-- future appsettings version
                        <tr *ngFor="let tax of appSettings.additionalTaxes">
                            <td scope="row">
                                <label>{{tax.type}} Sales Tax</label>
                            </td>
                            <td scope="row" colspan="3">
                                <div class="text-right">
                                    {{tax.value | currencyFormat}}
                                </div>
                            </td>
                        </tr> -->
                        <tr *ngIf="model.sellTax.stadiumTax && (taxRates.stadiumTax !== null) && !isTaxExempt">
                            <td scope="row">
                                <label>Stadium Sales Tax</label>
                            </td>
                            <td scope="row" colspan="3">
                                <div class="text-right">
                                    {{model.sellTax.stadiumTax | currencyFormat}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
        </div>
        <div class="col-xs-12 col-lg-6">
            <fieldset>
                <legend id="costInfoLabel">Cost Info</legend>

                <table class="line-items" aria-describedby="costInfoLabel">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" class="currency-display">Calculated</th>
                            <th scope="col"></th>
                            <th scope="col" class="currency-display invoice-amount">This invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">
                                <label>Labor - Field</label>
                            </td>
                            <td scope="row" class="currency-display">
                                {{ autofill.laborDirect | currencyFormat }}
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyAutofillValue('laborDirect')" tabindex="60">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.laborDirect" enforceDecimal [comma]="true" name="laborDirect"
                                        #laborDirect="ngModel" placeholder="0.00" tabindex="61" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Labor - Shop</label>
                            </td>
                            <td scope="row" class="currency-display">
                                {{ autofill.laborShop | currencyFormat }}
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyAutofillValue('laborShop')" tabindex="62">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.laborShop" name="laborShop" #laborShop="ngModel" enforceDecimal
                                        [comma]="true" placeholder="0.00" tabindex="63" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Materials</label>
                            </td>
                            <td scope="row" class="currency-display">
                                {{ autofill.materials | currencyFormat }}
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyAutofillValue('materials')" tabindex="71">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [ngModel]="model.materials" (ngModelChange)="model.materials = $event; updateUseTax($event)"
                                        name="materials" #materials="ngModel" enforceDecimal [comma]="true" placeholder="0.00" tabindex="72" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Outside Services</label>
                            </td>
                            <td scope="row" class="currency-display">
                                {{ autofill.outsideServices | currencyFormat }}
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyAutofillValue('outsideServices')" tabindex="73">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row">
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input class="form-control" type="text" [(ngModel)]="model.outsideServices" name="outsideServices" #outsideServices="ngModel"
                                        enforceDecimal [comma]="true" placeholder="0.00" tabindex="74" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Subtotal</label>
                            </td>
                            <td scope="row"></td>
                            <td scope="row"></td>
                            <td scope="row" class="currency-display">
                                {{ getSubtotal() | currencyFormat }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>County Use Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getUseTaxAmount('countyTax') | currencyFormat:false:true:4 }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyUseTaxAmount('countyTax')" *ngIf="!isTaxExempt" tabindex="75">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row" class="input-group">
                                <span class="input-group-addon">$</span>
                                <input class="form-control" type="text" [(ngModel)]="model.costTax.countyTax" name="costTax.countyTax" enforceDecimal [comma]="true"
                                    figureCount="4" [disabled]="isTaxExempt" placeholder="0.00" tabindex="76" />
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Milwaukee County Use Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getUseTaxAmount('mkecountyTax') | currencyFormat:false:true:4 }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyUseTaxAmount('mkecountyTax')" *ngIf="!isTaxExempt" tabindex="77">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row" class="input-group">
                                <span class="input-group-addon">$</span>
                                <input class="form-control" type="text" [(ngModel)]="model.costTax.mkecountyTax" name="costTax.mkecountyTax" enforceDecimal [comma]="true"
                                    figureCount="4" [disabled]="isTaxExempt" placeholder="0.00" tabindex="78" />
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>State Use Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getUseTaxAmount('stateTax') | currencyFormat:false:true:4 }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyUseTaxAmount('stateTax')" *ngIf="!isTaxExempt" tabindex="79">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row" class="input-group">
                                <span class="input-group-addon">$</span>
                                <input class="form-control" type="text" [(ngModel)]="model.costTax.stateTax" name="costTax.stateTax" enforceDecimal [comma]="true"
                                    figureCount="4" [disabled]="isTaxExempt" placeholder="0.00" tabindex="80" />
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>City Use Tax</label>
                            </td>
                            <td scope="row" class="currency-display">
                                <span class="tax-amount" *ngIf="!isTaxExempt">
                                    {{ getUseTaxAmount('cityTax') | currencyFormat:false:true:4 }}
                                </span>
                            </td>
                            <td scope="row" class="copy">
                                <button class="btn btn-default" type="button" (click)="copyUseTaxAmount('cityTax')" *ngIf="!isTaxExempt" tabindex="90">
                                    <i class="glyphicon glyphicon-arrow-right" title="Copy value"></i>
                                </button>
                            </td>
                            <td scope="row" class="input-group">
                                <span class="input-group-addon">$</span>
                                <input class="form-control" type="text" [(ngModel)]="model.costTax.cityTax" name="costTax.cityTax" enforceDecimal [comma]="true"
                                    figureCount="4" [disabled]="isTaxExempt" placeholder="0.00" tabindex="91" />
                            </td>
                        </tr>
                        <tr *ngIf="model.costTax.stadiumTax && (taxRates.stadiumTax !== null) && !isTaxExempt">
                            <td>
                                <label>Stadium Tax</label>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td class="input-group text-right" style="display:table-cell">
                                <div class="pr-1">{{model.costTax.stadiumTax | currencyFormat:false:true:4}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                <label>Total</label>
                            </td>
                            <td colspan="3" class="currency-display">
                                {{ getCostTotal() | currencyFormat }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="checkbox invoice-flag">
                <label class="custom-checkbox">
                    <input type="checkbox" name="nonContract" [ngModel]="model.nonContract" (ngModelChange)="toggleParts()" tabindex="99"><span class="custom-label">Check here for Non-Contract Invoice</span>
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>

    <div *ngIf="model.nonContract" class="row mt-2">
        <fieldset>
            <div class="col-xs-12">
                <legend id="nonContractPartsLabel">
                    Non Contract Parts
                </legend>
                <table class="table table-select-list scrollable no-hover mb-1" *ngIf="model.parts" aria-describedby="nonContractPartsLabel">
                    <thead>
                        <th scope="col" style="width: 10%; padding:10px;">Qty</th>
                        <th scope="col" style="width: 10%; padding:10px;">Unit</th>
                        <th scope="col" style="width: 70%; padding:10px;">Description</th>
                        <th scope="col" style="width: 10%; padding:10px;" class="currency-display">Price</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let part of model.parts; let i=index" [@fadeInOut]>
                            <td scope="row">
                                <input [attr.id]="'part' + i" class="form-control" type="number" [(ngModel)]="part.quantity" min="0" name="quantity-{{i}}" tabindex="100" />
                            </td>
                            <td scope="row">
                                <input class="form-control" type="text" [(ngModel)]="part.unit" name="unit-{{i}}" tabindex="100">
                            </td>
                            <td scope="row">
                                <input class="form-control" type="text" [(ngModel)]="part.description" name="description-{{i}}" tabindex="100">
                            </td>
                            <td scope="row">
                                <input class="form-control currency-display" type="text" [(ngModel)]="part.cost" name="cost-{{i}}" tabindex="100">
                            </td>
                            <td scope="row">
                                <button type="button" class="btn btn-danger" (click)="deletePart(part)" tabindex="100" (keydown.space)="deletePart(part)"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" (click)="addPart($event)" class="btn btn-link add-on mb-3 ml-1 pl-0" tabindex="105" (keydown.space)="addPart($event)" >
                    <div class="add-icon">+</div><span>Add Part</span>
                </button>
            </div>
        </fieldset>
    </div>

    <div class="row mt-2">
        <div class="col-xs-12 col-md-10">
            <fieldset>
                <legend>
                    Invoice Notes
                </legend>

                <div class="form-group row">
                    <div class="col-xs-12">
                        <notes-field [(model)]="model.notes" [saved]="saved" [tabindex]="106"></notes-field>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-6">
            <div class="form-group mb-2">
                <label class="control-label">Associated Documents</label>
                <document-upload 
                    type="Invoice" 
                    [isDraggable]="false"
                    [label]="'Add documents'" 
                    [model]="model" 
                    [typeId]="uuid"
                    [duplicateRecord]="duplicateRecord" 
                    (queued)="setMessage($event)" 
                    (duplicateFound)="duplicate()" 
                    (saveModel)="saveInvoice()"
                ></document-upload>
            </div>
        </div>
    </div>

    <div class="">
        <p *ngIf="filesQueued" class="error-msg small">Please upload files before saving.</p>
        <button class="btn btn-default mr-1" type="button" (click)="copyAllAutofillValues()" tabindex="107">Copy All</button>
        <button class="btn btn-primary mr-1" type="submit" [disabled]="!invoiceForm.form.valid || filesQueued || disableSubmit" tabindex="108"><span *ngIf="isNew">Create</span><span *ngIf="!isNew">Save Changes</span></button>
        <div class="inline-block pseudo-link small capitalize" *ngIf="isNew" (click)="cancel()" tabindex="109">Cancel</div>
    </div>
</form>
