<div class="screen-title">
    Current Change Orders
</div>

<div class="row entry">
    <div class="col-sm-6">
        Original contract amount:
    </div>
    <div class="col-sm-6 currency-display">
        {{ model.originalContract | currencyFormat }}
    </div>
</div>

<div class="row entry">
    <div class="col-sm-6">
        Total change orders:
    </div>
    <div class="col-sm-6 currency-display">
        {{ model.changeOrderAmount | currencyFormat }}
    </div>
</div>

<div class="row entry">
    <div class="col-sm-6">
        Adjusted contract amount:
    </div>
    <div class="col-sm-6 currency-display total">
        {{ model.originalContract + model.changeOrderAmount | currencyFormat }}
    </div>
</div>
