import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, map } from 'rxjs';
import { share, tap, first } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { ToastrService } from 'ngx-toastr';
import { IJobSearchRequest, IJobModel, JobOrderConnectionModel, JobInvoiceConnectionModel, JobPayableConnectionModel, IJobBids } from '@/models/job.models';
import { ChangeOrderModel, IChangeOrderModel } from '@/models/changeOrder.models';
import {
	InvoiceModel,
	IInvoiceModel,
	IInvoiceAutofillResponse,
	IInvoiceSummary,
	IInvoiceSearchRequest
} from '@/models/invoice.models';
import { PayableModel, IPayableModel, IPayableSearchRequest, PayablesSearchResult } from '@/models/payable.models';
import { ITimeEntryModel, IMoveTimeEntry } from '@/models/timeEntry.models';
import { ICostModel, IMoveCost } from '@/models/cost.models';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';


@Injectable()
export class JobService extends ApiBaseService {
	constructor(private _http: HttpClient, private state: StateManager, messageService: MessageService, private toastrService: ToastrService) {
		super(messageService);
		this.baseUrl = 'customers';
	}
	private sidebarData: Object = null

	getJobs(
		limit: number = null,
		includeClosed: boolean = false,
		searchTerm: string = null,
		sortField: string = null,
		sortDirection: number = null,
		pageNumber: number = null,
		filters: any[] = null
	): Promise<Array<IJobModel>> {
		let request = {
			limit: limit || 0,
			includeClosed: includeClosed,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			pageNumber: pageNumber,
			filters: filters
		} as IJobSearchRequest;

		let result = this._http.post<Array<IJobModel>>('jobs', request).pipe(share());
		return this.extract(result, (s) => {
			this.state.setJobsList(s);
		});
	}

	getCustomerJobs(customerId: string, includeClosed: boolean = false): Promise<Array<IJobModel>> {
		let queryUrl = this.baseUrl + '/' + customerId + '/jobs/?';
		if (includeClosed) {
			queryUrl += 'includeClosed=' + includeClosed;
		}

		let result = this._http.get<Array<IJobModel>>(queryUrl).pipe(share());
		return this.extract(result, (s) => {
			this.state.setJobsList(s);
		});
	}

	getJob(customerId: string, jobId: string, setActiveJob: boolean = true): Promise<IJobModel> {
		let result = this._http.get<IJobModel>(this.baseUrl + '/' + customerId + '/jobs/' + jobId).pipe(share());
		return this.extract(result, (s) => {
			if (setActiveJob) {
				this.state.setActiveJob(s);
			}
		});
	}

	updateJob(customerId: string, job: IJobModel, setActiveJob: boolean = true): Promise<IJobModel> {
		let result: Observable<IJobModel>;

		if (job._id) {
			result = this._http.put<IJobModel>(this.baseUrl + '/' + customerId + '/jobs/' + job._id, job).pipe(share());
		} else {
			result = this._http.post<IJobModel>(this.baseUrl + '/' + customerId + '/jobs', job).pipe(share());
		}

		let promise = this.extract(result, (s) => {
			if (setActiveJob) {
				this.state.setActiveJob(s);
			}
		}, (error) => {
			this.notify('An error occurred when updating the job', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Job updated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// deleteJob(id: string) : void

	// <editor-fold> Change orders

	getChangeOrders(customerId: string, jobId: string): Promise<Array<IChangeOrderModel>> {
		let result = this._http.get<Array<ChangeOrderModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/changeOrders').pipe(share());
		return this.extract(result, (s) => {
			this.state.setChangeOrdersList(s);
		});
	}

	getChangeOrder(customerId: string, jobId: string, changeOrderId: string): Promise<IChangeOrderModel> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/changeOrders/' + changeOrderId;
		let result = this._http.get<ChangeOrderModel>(url).pipe(share());
		return this.extract(result, (s) => {
			this.state.setActiveChangeOrder(s);
		});
	}

	createChangeOrder(customerId: string, jobId: string, changeOrder: IChangeOrderModel): Promise<IChangeOrderModel> {
		const changeOrderBaseUrl = this.getChangeOrderUrl(customerId, jobId, changeOrder);
		const req = this._http.post<IChangeOrderModel>(changeOrderBaseUrl, changeOrder).pipe(share());
		return this.saveChangeOrder(req);
	}

	// todo Isn't accessible because edit option isn't displayed
	updateChangeOrder(customerId: string, jobId: string, changeOrder: IChangeOrderModel): Promise<IChangeOrderModel> {
		const changeOrderBaseUrl = this.getChangeOrderUrl(customerId, jobId, changeOrder);
		const req = this._http.put<IChangeOrderModel>(changeOrderBaseUrl, changeOrder).pipe(share());
		return this.saveChangeOrder(req);
	}

	// todo Isn't accessible because delete option isn't displayed
	deleteChangeOrder(customerId: string, jobId: string, changeOrderId: string): Promise<any> {
		let result = this._http.delete(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/changeOrders/' + changeOrderId).pipe(share());

		let promise = this.extract(result, (s) => { /* DO NOTHING */ }, (error) => {
			this.notify('An error occurred when deleting the change order', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Change order deleted');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// </editor-fold>

	// <editor-fold> Invoices

	searchInvoices(
		limit: number = null,
		searchTerm: string = null,
		sortField: string = null,
		sortDirection: number = null,
		pageNumber: number = null,
		filters: any[] = null
	): Promise<Array<IInvoiceSummary>> {
		let queryUrl = 'invoices';
		let request = {
			limit: limit,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			pageNumber: pageNumber,
			filters: filters
		} as IInvoiceSearchRequest;

		let result = this._http.post<Array<IInvoiceSummary>>(queryUrl, request).pipe(share());
		return this.extract(result, (s) => {
			this.state.setInvoiceSummaryList(s);
		});
	}

	getInvoices(customerId: string, jobId: string): Promise<Array<IInvoiceModel>> {
		let result = this._http.get<Array<InvoiceModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/invoices').pipe(share());
		return this.extract(result, (s) => {
			this.state.setInvoicesList(s);
		});
	}

	getInvoice(customerId: string, jobId: string, invoiceId: string, loadOnly: boolean = false): Promise<IInvoiceModel> {
		let result = this._http.get<InvoiceModel>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/invoices/' + invoiceId).pipe(share());
		return this.extract(result, (s) => {
			if (!loadOnly) {
				this.state.setActiveInvoice(s);
			}
		});
	}

	createInvoice(customerId: string, jobId: string, invoice: IInvoiceModel): Promise<IInvoiceModel> {
		const url = this.getInvoiceUrl(customerId, jobId, invoice);
		const req = this._http.post<IInvoiceModel>(url, invoice).pipe(share());
		return this.saveInvoice(req);
	}

	updateInvoice(customerId: string, jobId: string, invoice: IInvoiceModel): Promise<IInvoiceModel> {
		const url = this.getInvoiceUrl(customerId, jobId, invoice);
		const req = this._http.put<IInvoiceModel>(url, invoice).pipe(share());
		return this.saveInvoice(req);
	}

	getInvoiceAutofillValues(customerId: string, jobId: string, invoiceId: string, date: Date): Promise<IInvoiceAutofillResponse> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/invoices/autofill';
		let result = this._http.post<IInvoiceAutofillResponse>(url, { date: date, invoiceId: invoiceId });

		return this.extract(result, (s) => {
			// nothing set to state - uses promise to return values, as a one-time snapshot calculation
		}, (error) => {
			this.notify('An error occurred when retrieving autofill values for this invoice', { type: 'error' });
		});
	}

	// </editor-fold>

	// <editor-fold> Time entries

	getTimeEntries(customerId: string, jobId: string): Promise<Array<ITimeEntryModel>> {
		let result = this._http.get<Array<ITimeEntryModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/timeEntries').pipe(share());
		return this.extract(result, (s) => {
			this.state.setTimeEntriesList(s);
		});
	}

	moveTimeEntries(customerId: string, jobId: string, moves: IMoveTimeEntry): Promise<IMoveTimeEntry> {
		let moveTimeEntryBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/moveTimeEntries';
		let timeEntryResult = this._http.post<IMoveTimeEntry>(moveTimeEntryBaseUrl, moves).pipe(share());
		let promise = this.extract(timeEntryResult);

		promise.then(() => {
			this.notify('Time entry updated');
		}).catch((err) => {
			if (err.status === 500) {
				this.notify('An error occurred when updating the time entry', { type: 'error' });
			}
		});

		return promise;
	}

	updateTimeEntry(customerId: string, jobId: string, timeEntry: ITimeEntryModel): Promise<ITimeEntryModel> {
		let result: Observable<ITimeEntryModel>;
		let timeEntryBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/timeEntries';

		if (timeEntry._id) {
			result = this._http.put<ITimeEntryModel>(timeEntryBaseUrl + '/' + timeEntry._id, timeEntry).pipe(share());
		} else {
			result = this._http.post<ITimeEntryModel>(timeEntryBaseUrl, timeEntry).pipe(share());
		}

		let promise = this.extract(result, (s) => {
			// self.state.setActiveTimeEntry(s);
		}, (error) => {
			let errorMessage = 'An error occurred when updating the Time entry';
			if(timeEntry.rate < 0){
				errorMessage = "An error occurred, labor rate must be greater than 0"
			}
			this.notify(errorMessage, { type: 'error' });
		});

		promise.then(() => {
			this.notify('Time entry updated');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	deleteTimeEntry(customerId: string, jobId: string, timeEntryId: string): Promise<any> {
		let result = this._http.delete(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/timeEntries/' + timeEntryId).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Time entry deleted');
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// </editor-fold> Time entries

	// <editor-fold> Costs

	getCosts(customerId: string, jobId: string): Promise<Array<ICostModel>> {
		let result = this._http.get<Array<ICostModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/costs').pipe(share());
		return this.extract(result, (s) => {
			this.state.setCostList(s);
		});
	}

	getCostsAndPOCosts(customerId: string, jobId: string): void {
		const costsUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/costs';
		const poUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/purchaseOrders/';

		let costResult: Observable<ICostModel[]> = this._http.get<ICostModel[]>(costsUrl).pipe(share());
		let POResult: Observable<IPurchaseOrderModel[]> = this._http.get<IPurchaseOrderModel[]>(poUrl).pipe(share());
		let requests: Observable<any[]>[] = [costResult, POResult];
		forkJoin(requests).subscribe(([combinedCosts, costGroups]: [ICostModel[], IPurchaseOrderModel[]]) => {
			costGroups.forEach((costGroup: any) => {
					costGroup.costs.forEach((cost: ICostModel) => {
						cost.supplier = costGroup.supplier;
						cost.poNumber = costGroup.number;
						cost.poId = costGroup._id;
						combinedCosts.push(cost);
					});
			});

			this.state.setCostList(combinedCosts);
		});
	}

	moveCosts(customerId: string, jobId: string, moves: IMoveCost): Promise<IMoveCost> {
		let moveCostBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/moveCosts';
		let costResult = this._http.post<IMoveCost>(moveCostBaseUrl, moves).pipe(share());

		let promise = this.extract(costResult);
		promise.then(() => {
			this.notify('Cost updated');
		}).catch((err) => {
			if (err.status === 500) {
				this.notify('An error occurred when updating the cost', { type: 'error' });
			}
		});

		return promise;
	}

	updateCost(customerId: string, jobId: string, cost: ICostModel): Promise<ICostModel> {
		let result: Observable<ICostModel>;
		let costBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/costs';

		if (cost._id) {
			result = this._http.put<ICostModel>(costBaseUrl + '/' + cost._id, cost).pipe(share());
		} else {
			result = this._http.post<ICostModel>(costBaseUrl, cost).pipe(share());
		}

		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Cost updated');
		}).catch((err) => {
			// todo shouldn't be accessing document directly or managing focus from service
			if (err.status === 409) {
				try {
					let error = err.error;
					if (error.message === 'Duplicate Record') {
						document.getElementById('invoiceNumber').focus();
					}
				} catch (e) {
					document.getElementById('invoiceNumber').focus();
				}
				this.notify('An error occurred when updating the cost', { type: 'error' });
			}
		});

		return promise;
	}

	deleteCost(customerId: string, jobId: string, costId: string, hideMessage: boolean): Promise<any> {
		let result = this._http.delete(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/costs/' + costId).pipe(share());
		let promise = this.extract(result);
			promise.then(() => {
				if(!hideMessage){
					this.notify('Cost deleted');
				}
			}).catch(() => { /* DO NOTHING */ });

		return promise;
	}

	// </editor-fold>

	// <editor-fold> Work order / purchase order connection

	connectOrders(customerId: string, jobId: string, workOrderId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		let connection = { workOrderId: workOrderId, purchaseOrderId: purchaseOrderId } as JobOrderConnectionModel;
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/connectOrders';
		let result = this._http.post<IPurchaseOrderModel>(url, connection)
      .pipe(map(purchaseOrder => this.state.setActivePurchaseOrder(purchaseOrder)))
      .pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Orders connected');
		}).catch(() => { /* DO NOTHING */ });
		//@ts-ignore
		return promise;
	}

	disconnectOrders(customerId: string, jobId: string, workOrderId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		// todo work order id not strictly necessary here

		let connection = { workOrderId: workOrderId, purchaseOrderId: purchaseOrderId } as JobOrderConnectionModel;
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/disconnectOrders';
		let result = this._http.post<IPurchaseOrderModel>(url, connection)
      .pipe(map(purchaseOrder => this.state.setActivePurchaseOrder(purchaseOrder)))
      .pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Orders disconnected');
		}).catch(() => { /* DO NOTHING */ });
		//@ts-ignore
		return promise;
	}

	connectInvoices(customerId: string, jobId: string, invoiceId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		let connection = { invoiceId: invoiceId, purchaseOrderId: purchaseOrderId } as JobInvoiceConnectionModel;
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/connectInvoices';
		let result = this._http.post<IPurchaseOrderModel>(url, connection).pipe(share());
		let promise = this.extract(result);

		promise.then((res: any) => {
			this.notify('Invoice connected');
			return res as IPurchaseOrderModel;
		}).catch(() => { /* DO NOTHING */ });

		return (promise as any) as Promise<IPurchaseOrderModel>;
	}

	disconnectInvoices(customerId: string, jobId: string, invoiceId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		let connection = { invoiceId: invoiceId, purchaseOrderId: purchaseOrderId } as JobInvoiceConnectionModel;
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/disconnectInvoices';
		let result = this._http.post<IPurchaseOrderModel>(url, connection).pipe(share());
		let promise = this.extract(result);

		promise.then((res: any) => {
			this.notify('Invoice disconnected');
			return res as IPurchaseOrderModel;
		}).catch(() => { /* DO NOTHING */ });

		return promise;
	}
	// </editor-fold>

	private getInvoiceUrl(customerId: string, jobId: string, invoice: IInvoiceModel): string {
		let invoiceBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/invoices';
		if (invoice._id) {
			invoiceBaseUrl += '/' + invoice._id;
		}
		return invoiceBaseUrl;
	}

	private saveInvoice(req: Observable<IInvoiceModel>): Promise<IInvoiceModel> {
		let promise = this.extract(req, (s) => {
			this.state.setActiveInvoice(s);
		}, (error) => {
			this.notify('An error occurred when updating the invoice', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Invoice updated');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	searchPayables(
		limit: number = null,
		searchTerm: string = null,
		sortField: string = null,
		sortDirection: number = null,
		pageNumber: number = null,
		filters: any[] = null
	): Promise<PayablesSearchResult> {
		let queryUrl = 'payables';
		let request = {
			limit: limit,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			pageNumber: pageNumber,
			filters: filters,
		} as IPayableSearchRequest;

		let result = this._http.post<PayablesSearchResult>(queryUrl, request).pipe(share());
		return this.extract(result, (s) => {
			this.state.setPayableSummaryList(s.payables);
		});
	}

	getPayables(customerId: string, jobId: string): Promise<Array<IPayableModel>> {
		let result = this._http.get<Array<IPayableModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/payables').pipe(share());
		return this.extract(result, (s) => {
			this.state.setPayablesList(s);
		});
	}

	getPayable(customerId: string, jobId: string, payableId: string, loadOnly: boolean = false): Promise<IPayableModel> {
		let result = this._http.get<PayableModel>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/payables/' + payableId).pipe(share());
		return this.extract(result, (s) => {
			if (!loadOnly) {
				this.state.setActivePayable(s);
			}
		});
	}

	createPayable(customerId: string, jobId: string, payable: IPayableModel): Promise<IPayableModel> {
		const url = this.getPayableUrl(customerId, jobId, payable);
		const req = this._http.post<IPayableModel>(url, payable).pipe(share());
		return this.savePayable(req);
	}

	updatePayable(customerId: string, jobId: string, payable: IPayableModel): Promise<IPayableModel> {
		const url = this.getPayableUrl(customerId, jobId, payable);
		const req = this._http.put<IPayableModel>(url, payable).pipe(share());
		return this.savePayable(req);
	}

	exportPayable(customerId: string, jobId: string, payable: IPayableModel, command: string): Observable<{ exportUrl: string }> {
    const url = 'payables/' + payable._id + '/export';
    return this._http.post<{ exportUrl: string }>(url, { command: command }).pipe(tap(async res => {
      let currentPayable = await this.state.getActivePayable().pipe(first()).toPromise();
      currentPayable.exportedKey = res.exportUrl;
      this.state.setActivePayable(currentPayable);
      this.messageService.send({ options: {}, type: 'success', title: '', message: 'Successfully exported PDF'});
    },                                                             () => {
      this.messageService.send({ options: {}, type: 'error', title: '', message: 'Failed to export PDF' });
    }));
  }

	private getPayableUrl(customerId: string, jobId: string, payable: IPayableModel): string {
		let payableBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/payables';
		if (payable._id) {
			payableBaseUrl += '/' + payable._id;
		}
		return payableBaseUrl;
	}

	private savePayable(req: Observable<IPayableModel>): Promise<IPayableModel> {
		let promise = this.extract(req, (s) => {
			this.state.setActivePayable(s);
		}, (error) => {
			this.notify('An error occurred when updating the payable', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Payable updated');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	async connectPayables(customerId: string, jobId: string, payableId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		let connection = { payableId: payableId, purchaseOrderId: purchaseOrderId } as JobPayableConnectionModel;
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/connectPayables';
		let result = this._http.post<IPurchaseOrderModel>(url, connection)
      .pipe(map(purchaseOrder => this.state.setActivePurchaseOrder(purchaseOrder)))
      .pipe(share());
		let promise = this.extract(result);

		promise.then((res: any) => {
			this.notify('Payable connected');
			return res as IPurchaseOrderModel;
		}).catch(() => { /* DO NOTHING */ });

		return (promise as any) as Promise<IPurchaseOrderModel>;
	}

	disconnectPayables(customerId: string, jobId: string, payableId: string, purchaseOrderId: string): Promise<IPurchaseOrderModel> {
		let connection = { payableId: payableId, purchaseOrderId: purchaseOrderId } as JobPayableConnectionModel;
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/disconnectPayables';
		let result = this._http.post<IPurchaseOrderModel>(url, connection)
      .pipe(map(purchaseOrder => this.state.setActivePurchaseOrder(purchaseOrder)))
      .pipe(share());
		let promise = this.extract(result);

		promise.then((res: any) => {
			this.notify('Payable disconnected');
			return res as IPurchaseOrderModel;
		}).catch(() => { /* DO NOTHING */ });
		//@ts-ignore
		return promise;
	}

	private getChangeOrderUrl(customerId: string, jobId: string, changeOrder: IChangeOrderModel): string {
		let changeOrderBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/changeOrders';
		if (changeOrder._id) {
			changeOrderBaseUrl += '/' + changeOrder._id;
		}
		return changeOrderBaseUrl;
	}

	private saveChangeOrder(req: Observable<IChangeOrderModel>): Promise<IChangeOrderModel> {
		const promise = this.extract(req, (s) => {
			this.state.setActiveChangeOrder(s);
		}, (error) => {
			this.notify('An error occurred when updating the change order', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Change order updated');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	// Update the content of the sidebar when job data changes
	public saveSidebarData(amounts: IJobBids, notes: String) {
		this.sidebarData = {bidAmounts: amounts,
			notes: notes};
	}

	public getSidebarData(): Object {
		return this.sidebarData;
	}
}
