import { Component, OnInit, ViewChild, ViewChildren, ElementRef, QueryList, AfterViewInit, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { WorkOrderPartSortFunctions } from '@/utils/dataManipulationUtils';
import { IWorkOrderPartModel, WorkOrderPartModel } from '@/models/part.models';
import { MessageService } from '@/services/message.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { UUIDService } from '@/services/uuid.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'work-order-parts',
  templateUrl: './workOrderParts.template.html',
  host: {'class': 'work-order-parts-component'}
})
export class WorkOrderPartsComponent extends BaseSmartComponent implements OnInit, AfterViewInit {
  @ViewChild('newPartQuantity', {static: true})
  newPartQuantity: ElementRef;

  @ViewChildren('editPartRow')
  editPartRows: QueryList<ElementRef>;

  public model: BehaviorSubject<Array<IWorkOrderPartModel>> = new BehaviorSubject<Array<IWorkOrderPartModel>>(null);
  public newPart: IWorkOrderPartModel = new WorkOrderPartModel();

  private _customerId: string;
  private _jobId: string;
  private _workOrderId: string;
  private _selectedParts: Array<string> = new Array<string>();
  private _purchaseOrderBaseRoute: ActivatedRoute;
  private editingPartId: string;
  private selectAll: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private workOrderService: WorkOrderService,
    private purchaseOrderService: PurchaseOrderService,
    public dataManipulationService: DataManipulationService<IWorkOrderPartModel>,
    private renderer: Renderer2,
    private uuidService: UUIDService,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._workOrderId = routerUtils.getRouteParameter(this.route, 'workOrderId');
    this.dataManipulationService.initialize({}, WorkOrderPartSortFunctions);
    this.dataManipulationService.setSort('sortOrder');
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getWorkOrderPartsList(), this.model));
    this._purchaseOrderBaseRoute = routerUtils.getParentRoute(this.route, 3);
    this.reloadParts();
  }

  ngAfterViewInit(): void {
    // hack: select quantity input element of the editing row when copying from existing part
    this.watchSubscription(this.editPartRows.changes.subscribe((s) => {
      let editRows = s.filter((row) => {
        return row.nativeElement.className === 'editPart';
      });

      if (editRows.length === 1) {
        setTimeout(() => {
          (editRows[0].nativeElement.children[1].children[0] as any)['dispatchEvent'].apply(editRows[0].nativeElement.children[1].children[0], [event]);
          // this.renderer.invokeElementMethod(editRows[0].nativeElement.children[1].children[0], 'select', []);
        }, 0);
      }
    }));
  }

  private reloadParts(): void {
    this.editingPartId = null;
    this.workOrderService.getParts(this._customerId, this._jobId, this._workOrderId);
  }

  private cancel(event: Event): void {
    this.reloadParts();
    event.stopPropagation();
  }

  private editPart(part: IWorkOrderPartModel, event: Event): void {
    // todo are parts in an active purchase order editable?
    this.editingPartId = part._id;

    if (event) {
      event.stopPropagation();
    }
  }

  public savePart(part: IWorkOrderPartModel): void {
    // prevent non UI saves (i.e. keyboard shortcut) from skipping validation
    if (!part.description || (part.description.length < 1)) {
      return;
    }

    this.workOrderService.updatePart(this._customerId, this._jobId, this._workOrderId, part)
      .then(() => {
        this.reloadParts();
        if (part === this.newPart) {
          this.newPart = new WorkOrderPartModel();
          if (this.newPartQuantity) {
            (this.newPartQuantity.nativeElement as any)['dispatchEvent'].apply(this.newPartQuantity.nativeElement, [event]);
            // this.renderer.invokeElementMethod(this.newPartQuantity.nativeElement, 'focus', []);
          }
        }
      })
      .catch(handleError);
  }

  private deletePart(part: IWorkOrderPartModel, event: Event): void {
    if (this.warnOnDelete('part')) {
      this.workOrderService.deletePart(this._customerId, this._jobId, this._workOrderId, part._id)
        .then(() => this.reloadParts())
        .catch(handleError);
    }
    event.stopPropagation();
  }

  private createPurchaseOrder(): void {
    this.purchaseOrderService.createPurchaseOrderFromParts(this._customerId, this._jobId, this._workOrderId, this._selectedParts)
      .then(purchaseOrder => {
        // reload purchase order list for this job
        this.purchaseOrderService.getPurchaseOrders(this._customerId, this._jobId);

        // redirect to newly created purchase order
        this.router.navigate(['PurchaseOrders', purchaseOrder._id], { relativeTo: routerUtils.getParentRoute(this.route, 3) });
      })
      .catch(handleError);
  }

  public updateSelected(id: string): void {
    const indexPosition = this._selectedParts.indexOf(id);

    if (indexPosition === -1) {
      this._selectedParts.push(id);
    } else if (indexPosition > -1) {
      this._selectedParts.splice(indexPosition, 1);
    }

    this.selectAll = (this._selectedParts.length === this.model.value.length) ? true : false;
  }

  public clearSelected(): void {
    this._selectedParts = new Array<string>();
    this.selectAll = (this._selectedParts.length === this.model.value.length) ? true : false;
  }

  private copyPart(part: IWorkOrderPartModel, event: Event): void {
    // force creating a new part entry
    let newPart = Object.assign(new WorkOrderPartModel(), part);
    delete newPart._id;

    this.workOrderService.updatePart(this._customerId, this._jobId, this._workOrderId, newPart)
      .then(result => {
        this.reloadParts();
        this.editingPartId = result._id;
      })
      .catch(handleError);
    event.stopPropagation();
  }

  private goToPurchaseOrder(purchaseOrderId: string, event: Event): void {
    this.router.navigate(['PurchaseOrders', purchaseOrderId], { relativeTo: this._purchaseOrderBaseRoute });
    event.stopPropagation();
  }

  private toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    this._selectedParts = new Array<string>();
    if (this.selectAll) {
      for (let i = 0; i < this.model.value.length; i++) {
        this.updateSelected(this.model.value[i]._id);
      }
    }
  }
}
