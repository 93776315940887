import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { JobService } from '@/services/job.service';
import { UUIDService } from '@/services/uuid.service';
import { IChangeOrderModel, ChangeOrderModel } from '@/models/changeOrder.models';

@Component({
  selector: 'job-change-order-add',
  templateUrl: './jobChangeOrderAdd.template.html',
  host: {'class': 'job-change-order-add-component'}
})
export class JobChangeOrderAddComponent implements OnInit {
  public model$: Observable<IChangeOrderModel>;

  private _customerId: string;
  private _jobId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private jobService: JobService,
    private uuidService: UUIDService
  ) { }

  ngOnInit(): void {
    this.model$ = this.state.getActiveChangeOrder();
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    const newChangeOrder = new ChangeOrderModel();
    newChangeOrder._id = this.uuidService.generateUUID();
    this.state.setActiveChangeOrder(newChangeOrder);
  }

  public saveChangeOrder(newModel: IChangeOrderModel): void {
		// remove the docs for the save and then add them back in after so view is updated
		const docs = newModel.documents;
		delete newModel.documents;

		this.jobService.createChangeOrder(this._customerId, this._jobId, newModel)
      .then((result) => {
        this.jobService.getJob(this._customerId, this._jobId);
        this.jobService.getChangeOrders(this._customerId, this._jobId);
        setTimeout(() => {
          if (docs) {
            newModel.documents = docs;
          }
        }, 0);
        this.router.navigate([result._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 1) });
      });
  }

  public cancel(): void {
    this.router.navigate(['Edit'], { relativeTo: routerUtils.getParentRoute(this.route, 3) });
  }
}
