<div class="row">
    <div class="col-xs-6">
        <div class="screen-subtitle pointer" (click)="toggleCreateEntry()">Create a cost entry</div>
    </div>
    <div class="col-xs-6 text-right">
        <div class="toggle" (click)="toggleCreateEntry()">
            <div *ngIf="!showCreateEntry">
                show section <div class="toggle-icon">+</div>
            </div>
            <div *ngIf="showCreateEntry">
                hide section <div class="toggle-icon">-</div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showCreateEntry" [@fadeInOut]>
    <div class="row mb-2">
        <div class="col-xs-12">
            <div class="inline-block type-controls" [ngClass]="{'error': !newEntryValidForm && !newCost.type}">
                <button id="setFocus" class="btn btn-default mr-1" (click)="setCostType(newCost, 'Materials', true)" [ngClass]="{'btn-primary active': newCost.type === 'Materials'}" title="Materials" tabindex="1" autofocus>Materials</button>
                <button class="btn btn-default" (click)="setCostType(newCost, 'Outside Services', true)" [ngClass]="{'btn-primary active': newCost.type === 'Outside Services'}" title="Outside Services" tabindex="2">Outside Services</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-6">
            <div class="">
                <div class="row mb-1">
                    <div class="col-xs-12">
                        <label>Posting date</label>
                        <input autocomplete="off" class="form-control" [ngClass]="{'error': !newEntryValidForm && !date.valid}" type="date" [ngModel]="newCost.date | dateFormat" (ngModelChange)="newCost.date = sanitizeDate($event)" required="required" #date="ngModel" tabindex="3" />
                    </div>
                </div>        
                <div class="row mb-1">
                    <div class="col-xs-5">
                    </div>
                    <div class="col-xs-7">
                        <label>Cost</label>
                        <div class="input-group tax-entry-amount">
                            <span class="input-group-addon">$</span>
                            <input autocomplete="off" class="form-control" type="text" [ngModel]="newCost.cost" (ngModelChange)="newCost.cost = $event; calculateAllTaxAmounts(newCost, false)" #cost="ngModel" enforceDecimal [comma]="true" placeholder="0.00" tabindex="6" />
                        </div>
                    </div>
                </div>        
                <div class="row mb-1">
                    <div class="col-xs-5">
                        <label>County tax</label>
                        <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                            <input autocomplete="off" class="form-control" type="text" [ngModel]="taxRates.countyTax" (ngModelChange)="taxRates.countyTax = $event; calculateTaxAmount('countyTax', newCost)" enforceDecimal placeholder="0.00" />
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>
                    <div class="col-xs-7">
                        <label>&nbsp;</label>
                        <div class="input-group tax-entry-amount">
                            <span class="input-group-addon">$</span>
                            <input autocomplete="off" class="form-control" type="text" [(ngModel)]="newCost.countyTax" [disabled]="isTaxExempt" #countyTax="ngModel" enforceDecimal [comma]="true" placeholder="0.00" tabindex="7" />
                        </div>
                    </div>
                </div>        
                <div class="row mb-1">
                    <div class="col-xs-5">
                        <label>Milwaukee County tax</label>
                        <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                            <input autocomplete="off" class="form-control" type="text" [ngModel]="taxRates.mkecountyTax" (ngModelChange)="taxRates.mkecountyTax = $event; calculateTaxAmount('mkecountyTax', newCost)" enforceDecimal placeholder="0.00" />
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>
                    <div class="col-xs-7">
                        <label>&nbsp;</label>
                        <div class="input-group tax-entry-amount">
                            <span class="input-group-addon">$</span>
                            <input autocomplete="off" class="form-control" type="text" [(ngModel)]="newCost.mkecountyTax" [disabled]="isTaxExempt" #mkecountyTax="ngModel" enforceDecimal [comma]="true" placeholder="0.00" tabindex="7" />
                        </div>
                    </div>
                </div>        
                <div class="row mb-1">
                    <div class="col-xs-5">
                        <label>State tax</label>
                        <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                            <input autocomplete="off" class="form-control" type="text" [ngModel]="taxRates.stateTax" (ngModelChange)="taxRates.stateTax = $event; calculateTaxAmount('stateTax', newCost)" enforceDecimal placeholder="0.00" />
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>
                    <div class="col-xs-7">
                        <label>&nbsp;</label>
                        <div class="input-group tax-entry-amount">
                            <span class="input-group-addon">$</span>
                            <input autocomplete="off" class="form-control" type="text" [(ngModel)]="newCost.stateTax" [disabled]="isTaxExempt" #stateTax="ngModel" enforceDecimal [comma]="true" placeholder="0.00" tabindex="8" />
                        </div>
                    </div>
                </div>        
                <div class="row mb-1">
                    <div class="col-xs-5">
                        <label>City tax</label>
                        <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                            <input autocomplete="off" class="form-control" type="text" [ngModel]="taxRates.cityTax" (ngModelChange)="taxRates.cityTax = $event; calculateTaxAmount('cityTax', newCost)" enforceDecimal placeholder="0.00" />
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>
                    <div class="col-xs-7">
                        <label>&nbsp;</label>
                        <div class="input-group tax-entry-amount">
                            <span class="input-group-addon">$</span>
                            <input autocomplete="off" class="form-control" type="text" [(ngModel)]="newCost.cityTax" [disabled]="isTaxExempt" #cityTax="ngModel" enforceDecimal [comma]="true" placeholder="0.00" tabindex="8" />
                        </div>
                    </div>
                </div>        
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="col-xs-12 col-md-6">
            <div class="row mb-1 hide-for-po">
                <div class="col-xs-12">
                    <label>Attach to purchase order</label>
                    <ng-select 
                        class="ng-select-component"
                        [clearable]="true"
                        [items]="_purchaseOrdersFormatted"
                        bindLabel="text"
                        [(ngModel)]="selectedPO"
                        (change)="selectPO($event, newCost)"
                        (remove)="removePO($event)"
                        (keydown.space)="$event.stopImmediatePropagation(); openDropdown(0)"
                        placeholder="None - post cost to job"
                        #selectField 
                        id="attachPODropdown"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="row mb-1" *ngIf="showSuppliers === true">
                <div class="col-xs-12">
                    <label>Supplier</label>
                    <ng-select 
                        class="ng-select-component"
                        *ngIf="showSuppliers === true"
                        [clearable]="true"
                        [items]="_suppliersFormatted"
                        bindLabel="text"
                        (change)="selectSupplier($event)"
                        (remove)="removeSupplier($event)"
                        (keydown.space)="$event.stopImmediatePropagation(); openDropdown(1)"
                        placeholder="-"
                        [(ngModel)]="selectedSupplier"
                        #supplierField
                        id="supplierDropdown" 
                    >
                    </ng-select>
                </div>
                <div class="col-xs-12">
                    <div (click)="openModal()" class="add-on" style="margin-top:5px;">
                        <div class="add-icon">+</div><span>Add a new supplier</span>
                    </div>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-xs-12">
                    <label>Category</label>
                    <div *ngIf="newCost.type!='Outside Services'">
                        <select class="form-control" [(ngModel)]="newCost.category" name="category" #category="ngModel" [ngClass]="{'disabled':newCost.disableCat}" tabindex="11">
                            <option value="other" selected>Other</option>
                            <option value="aluminum">Aluminum</option>
                            <option value="glass">Glass</option>
                            <option value="sealants">Sealants</option>
                            <option value="brakemetal">Brake Metal</option>
                        </select>
                    </div>
                    <div *ngIf="newCost.type==='Outside Services'">
                        <select class="form-control" [(ngModel)]="newCost.category" name="category" #category="ngModel" [ngClass]="{'disabled':newCost.disableCat}" tabindex="12">
                            <option value="equipment">Equipment</option>
                            <option value="engineering">Engineering</option>
                            <option value="subcontractors">Subcontractors</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-xs-12">
                    <label>Invoice number</label>
                    <input id="invoiceNumber" autocomplete="off" class="form-control" [(ngModel)]="newCost.invoiceNumber" name="invoiceNumber" tabindex="14" />
                </div>
            </div>
            <div class="row mb-1" *ngIf="showAlert">
                <div class="col-xs-12">
                    <div class="alert alert-danger text-center">
                        That invoice already exists.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <label>Notes</label>
                    <notes-field [(model)]="newCost.notes" [saved]="saved" [tabindex]="15"></notes-field>
                </div>
            </div>
        </div>
    </div>
    <div class="hr mt-1 mb-1"></div>
    <div class="row mb-1">
        <div class="col-xs-6 costs-total">
            <span class="bold">Total cost</span><span class="bold pull-right">{{ (newCost.cost + (newCost.cityTax ? newCost.cityTax : 0) + newCost.countyTax + newCost.mkecountyTax + newCost.stateTax) | currencyFormat }}</span>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-xs-6">
            <button type="button" class="btn btn-primary mr-1" (click)="saveCost(newCost)" data-keyboard-exclude tabindex="16">Post entry</button><div class="inline-block pseudo-link small capitalize" (click)="clearEntryFields(newCost)" tabindex="17">Clear fields</div>
        </div>
    </div>
</div>

<div class="hr mt-1 mb-2"></div>
<div class="sticky-header">
    <div class="screen-subtitle" id="pastEntriesLabel">Past Entries</div>
    <div class="entry-controls">
        <div class="entry-control">
            <filter-entries *ngIf="filterOptions.length" [filterOptions]="filterOptions" (filterValue)="updateFilter($event)" (clearAll)="clearFilters()"></filter-entries>
        </div>
        <div class="entry-control">
            <move-entry (save)="moveEntries($event)" [selectedEntries]="entriesCopy" [_customerId]="_customerId" [_jobId]="_jobId" [entryType]="'cost'"></move-entry>
        </div>
        <div class="clearfix"></div>
    </div>
    <pagination-component [totalItems]="totalEntries"></pagination-component>
    <table class="table table-select-list no-border mb-0" aria-describedby="pastEntriesLabel">
        <thead>
        <tr class="shaded">
            <th style="width:3%;" scope="col">
                <label class="custom-checkbox large" style="position: relative; top: -6px;">
                    <input type="checkbox" [ngModel]="selectAll" (change)="toggleSelectAll()">
                    <span class="checkmark"></span>
                </label>
            </th>
            <th style="width:30%;" scope="col">
                <sortable-header field="type" label="Type" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
            </th>
            <th style="width:25%;" scope="col">
                <sortable-header field="date" label="Posting Date" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
            </th>
            <th style="width:20%;" scope="col">
                <sortable-header field="cost" label="Costs" [sortManager]="dataManipulationService" (onSort)="filterEntries(storedFilter)"></sortable-header>
            </th>
            <th style="width:25%;" scope="col"></th>
        </tr>
        </thead>
    </table>
</div>
<div class="attached po-cost-list-container">
    <table class="table no-border">
        <caption class="sr-only">List of costs</caption>
        <thead class="spacer">
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
        </thead>
        <tbody *ngFor="let cost of filteredEntries | slice: (pager.currentPage - 1) * pager.itemsPerPage : (pager.currentPage - 1) * pager.itemsPerPage + pager.itemsPerPage; trackBy: trackById; let i = index">
            <ng-template [ngIf]="cost._id === editingCostId">
                <tr>
                    <td colspan="5" scope="row">
                        <div class="pull-left">
                            <label class="custom-checkbox">
                                <input type="checkbox" [(ngModel)]="cost.selected" [checked]="cost.selected" (change)="updateSelected(cost)">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="pl-2">
                            <div class="row mb-2 inline-block">
                                <div class="col-xs-12 type-controls">
                                    <button id="setEditFocus" tabindex="21" class="btn btn-default mr-1" (click)="setCostType(cost, 'Materials', false)" [ngClass]="{'btn-primary active': cost.type === 'Materials'}" title="Materials">Materials</button>
                                    <button tabindex="22" class="btn btn-default" (click)="setCostType(cost, 'Outside Services', false)" [ngClass]="{'btn-primary active': cost.type === 'Outside Services'}" title="Outside Services">Outside Services</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="">
                                        <div class="row mb-1">
                                            <div class="col-xs-12">
                                                <label>Posting date</label>
                                                <input tabindex="23" autocomplete="off" class="form-control" type="date" [ngModel]="cost.date | dateFormat" (ngModelChange)="cost.date = sanitizeDate($event)" required="required" #editDate="ngModel" />
                                            </div>
                                        </div>        
                                        <div class="row mb-1">
                                            <div class="col-xs-5">
                                            </div>
                                            <div class="col-xs-7">
                                                <label>Cost</label>
                                                <div class="input-group tax-entry-amount">
                                                    <span class="input-group-addon">$</span>
                                                    <input tabindex="26" autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.cost" (keyup)="calculateAllTaxAmounts(cost, true)" required="required" #editCost="ngModel" enforceDecimal placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>        
                                        <div class="row mb-1">
                                            <div class="col-xs-5">
                                                <label>County tax</label>
                                                <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                                                    <input autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.countyTaxRate" (keyup)="calculateTaxAmount('countyTax', cost, 'countyTaxRate')" enforceDecimal placeholder="0.00" />
                                                    <span class="input-group-addon">%</span>
                                                </div>
                                            </div>
                                            <div class="col-xs-7">
                                                <label>&nbsp;</label>
                                                <div class="input-group tax-entry-amount">
                                                    <span class="input-group-addon">$</span>
                                                    <input tabindex="27" autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.countyTax" [disabled]="isTaxExempt" enforceDecimal placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>        
                                        <div class="row mb-1">
                                            <div class="col-xs-5">
                                                <label>Milwaukee County tax</label>
                                                <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                                                    <input autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.mkecountyTaxRate" (keyup)="calculateTaxAmount('mkecountyTax', cost, 'mkecountyTaxRate')" enforceDecimal placeholder="0.00" />
                                                    <span class="input-group-addon">%</span>
                                                </div>
                                            </div>
                                            <div class="col-xs-7">
                                                <label>&nbsp;</label>
                                                <div class="input-group tax-entry-amount">
                                                    <span class="input-group-addon">$</span>
                                                    <input tabindex="27" autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.mkecountyTax" [disabled]="isTaxExempt" enforceDecimal placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>        
                                        <div class="row mb-1">
                                            <div class="col-xs-5">
                                                <label>State tax</label>
                                                <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                                                    <input autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.stateTaxRate" (keyup)="calculateTaxAmount('stateTax', cost, 'stateTaxRate')" enforceDecimal placeholder="0.00" />
                                                    <span class="input-group-addon">%</span>
                                                </div>
                                            </div>
                                            <div class="col-xs-7">
                                                <label>&nbsp;</label>
                                                <div class="input-group tax-entry-amount">
                                                    <span class="input-group-addon">$</span>
                                                    <input tabindex="28" autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.stateTax" [disabled]="isTaxExempt" enforceDecimal placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>        
                                        <div class="row mb-1">
                                            <div class="col-xs-5">
                                                <label>City tax</label>
                                                <div class="input-group tax-entry-rate" *ngIf="!isTaxExempt">
                                                    <input autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.cityTaxRate" (keyup)="calculateTaxAmount('cityTax', cost, 'cityTaxRate')" enforceDecimal placeholder="0.00" />
                                                    <span class="input-group-addon">%</span>
                                                </div>
                                            </div>
                                            <div class="col-xs-7">
                                                <label>&nbsp;</label>
                                                <div class="input-group tax-entry-amount">
                                                    <span class="input-group-addon">$</span>
                                                    <input tabindex="29" autocomplete="off" class="form-control" type="text" [(ngModel)]="cost.cityTax" [disabled]="isTaxExempt" enforceDecimal placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>        
                                        <div class="row mb-1" *ngIf="cost.stadiumTax">
                                            <div class="col-xs-5">
                                                <label>Stadium tax</label>
                                                <div *ngIf="cost.stadiumTaxRate">
                                                    {{cost.stadiumTaxRate}}%
                                                </div>
                                            </div>
                                            <div class="col-xs-7">
                                                <label>&nbsp;</label>
                                                <div class="text-right mr-1">
                                                    {{cost.stadiumTax | currencyFormat}}
                                                </div>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="row mb-1 hide-for-po">
                                        <div class="col-xs-12">
                                            <label>Attach to purchase order</label>
                                            <ng-select 
                                                class="ng-select-component"
                                                [clearable]="true"
                                                [items]="_purchaseOrdersFormatted"
                                                bindLabel="text"
                                                (change)="editPO($event, cost)"
                                                (remove)="removePO($event)"
                                                (keydown.space)="$event.stopImmediatePropagation(); openDropdown(2)"
                                                placeholder="None - post cost to job"
                                                [(ngModel)]="formattedPo"
                                                #selectFieldEdit
                                                id="poDropdownEdit" 
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row mb-1" *ngIf="showSuppliers === true">
                                        <div class="col-xs-12">
                                            <label>Supplier</label>
                                            <ng-select 
                                                class="ng-select-component"
                                                *ngIf="showSuppliers === true"
                                                [clearable]="true"
                                                [items]="_suppliersFormatted"
                                                bindLabel="text"
                                                (change)="editSupplier($event, cost)"
                                                (remove)="removeSupplier($event, cost)"
                                                (keydown.space)="$event.stopImmediatePropagation(); openDropdown(3)"
                                                placeholder="-"
                                                [(ngModel)]="selectedSupplierEdit"
                                                #supplierFieldEdit
                                                id="supplierDropdownEdit" 
                                                style="margin-bottom:0.2rem;"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-xs-12">
                                            <label>Category</label>
                                            <div *ngIf="cost.type!='Outside Services'">
                                                <select class="form-control" [(ngModel)]="cost.category" name="category" #category="ngModel" [ngClass]="{'disabled':cost.disableCat}" tabindex="32">
                                                    <option value="other" selected>Other</option>
                                                    <option value="aluminum">Aluminum</option>
                                                    <option value="glass">Glass</option>
                                                    <option value="sealants">Sealants</option>
                                                    <option value="brakemetal">Brake Metal</option>
                                                </select>
                                            </div>
                                            <div *ngIf="cost.type==='Outside Services'">
                                                <select class="form-control" [(ngModel)]="cost.category" name="category" #category="ngModel" [ngClass]="{'disabled':cost.disableCat}" tabindex="32">
                                                    <option value="equipment">Equipment</option>
                                                    <option value="engineering">Engineering</option>
                                                    <option value="subcontractors">Subcontractors</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-xs-12">
                                            <label>Invoice number </label>
                                            <input id="invoiceNumber" autocomplete="off" class="form-control" [(ngModel)]="cost.invoiceNumber" name="invoiceNumber" tabindex="33"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <label>Notes</label>
                                            <notes-field [(model)]="cost.notes" [saved]="saved" [tabindex]="34"></notes-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hr mt-1 mb-1"></div>
                            <div class="row mb-1">
                                <div class="col-xs-6 costs-total">
                                    <span class="bold">Total</span><span class="bold pull-right">{{ (cost.cost + (cost.cityTax ? cost.cityTax : 0) + cost.countyTax + (cost.mkecountyTax ? cost.mkecountyTax : 0) + cost.stateTax + cost.stadiumTax) | currencyFormat }}</span>
                                </div>
                            </div>
                            <div class="row mb-2 align-center">
                                <div class="col-xs-6">
                                    <button tabindex="34" type="button" class="btn btn-primary mr-1" [disabled]="!editDate.valid || !editCost.valid || !cost.type" (click)="saveCost(cost)" data-keyboard-exclude>Save entry</button>
                                    <div tabindex="35" class="inline-block pseudo-link small capitalize" (click)="cancel($event)">Cancel</div>
                                </div>
                                <div class="col-xs-6 text-right">
                                    <button type="button" class="btn btn-danger" (click)="deleteCost(cost, $event)" tabindex="36"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template [ngIf]="cost._id !== editingCostId">
                <tr>
                    <td style="width:3%;" scope="row">
                        <label class="custom-checkbox">
                            <input type="checkbox" [(ngModel)]="cost.selected" [checked]="cost.selected" (change)="updateSelected(cost)">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                    <td style="width:30%;" (click)="editCost(cost)" scope="row">
                        {{ cost.type }}
                    </td>
                    <td style="width:25%;" (click)="editCost(cost)" scope="row">
                        {{ cost.date | dateFormat:'MMM DD, YYYY' }}
                    </td>
                    <td style="width:20%;" (click)="editCost(cost)" scope="row">
                        {{ (cost.cost + (cost.cityTax ? cost.cityTax : 0) + cost.countyTax + (cost.mkecountyTax ? cost.mkecountyTax : 0) + cost.stateTax + cost.stadiumTax) | currencyFormat }}
                    </td>
                    <td style="width:25%;" class="list-item-actions" scope="row">
                        <button type="button" class="btn btn-default" (click)="editCost(cost)">Edit</button>
                    </td>
                </tr>
                <tr (click)="editCost(cost)">
                    <td scope="row"></td>
                    <td scope="row">
                        <div class="entry-label">Supplier</div>
                        <span *ngIf="cost.supplier">{{ cost.supplier.name }}</span>
                    </td>
                    <td scope="row">
                        <div class="entry-label">Invoice #</div>
                        {{ cost.invoiceNumber }}
                    </td>
                    <td scope="row">
                        <div class="entry-label hide-for-po">Purchase Order</div>
                        <span class=" hide-for-po">{{ cost.poNumber }}</span>
                    </td>
                    <td scope="row">
                        <div class="entry-label" *ngIf="cost.notes">Notes</div>
                        <div class="readonly-multiline">{{cost.notes}}</div>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <div *ngIf="!filteredEntries || filteredEntries.length < 1" class="text-center large-text light mt-3">
        No results
    </div>
</div>
<shared-modal [@fadeInOut] [display]="display" [title]="'Add a new supplier'" (onClose)="display='none'" (onSave)="setNewSupplier($event)" [type]="'supplier'" [model]="supplierModel" [showAlert]="showAlert"></shared-modal>
