import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { BaseComponent } from '@/components/base.component';
import { StateManager } from '@/state/stateManager';
import { IChangeOrderModel } from '@/models/changeOrder.models';
import { Subscription } from 'rxjs';
import { AuthService } from '@/services/auth.service';
import { UserRole } from '@/models/auth.models';

@Component({
  selector: 'change-order-edit',
  templateUrl: './changeOrderEdit.template.html',
  host: {'class': 'change-order-edit-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300))
    ]),
  ]
})
export class ChangeOrderEditComponent extends BaseComponent implements OnDestroy {
  @Input()
	model: IChangeOrderModel;

  @Input()
  showCancel: boolean = false;

  @Output()
  onSave: EventEmitter<IChangeOrderModel> = new EventEmitter<IChangeOrderModel>();

  @Output()
  onCancel: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  onDelete: EventEmitter<string> = new EventEmitter<string>();

  public duplicateRecord: boolean = false;
	public user: Object;
	public userSub: Subscription;
	public isAdmin: boolean = false;
	public role: number;
	public permissionLock: boolean = false;

  constructor(
    private stateManager: StateManager,
		private authService: AuthService
    ) {
    super();
  }
  ngOnInit(): void {
		const self = this;
    // get user's role
		self.userSub = self.authService.currentUser.subscribe(s => {
			if (s) {
				self.user = s;
				self.role = s.role;
				if (self.role === UserRole.ShopForeman) self.permissionLock = true;
				if (self.role === UserRole.Admin) {
					self.isAdmin = true;
				} else {
					self.isAdmin = false;
				}
			}
		});
  }

  ngOnDestroy(): void {
    this.stateManager.setActiveChangeOrder(null);
  }

  saveChangeOrder(): void {
    if (!this.model.amount) {
      this.model.amount = 0;
    }
    this.onSave.emit(this.model);
  }

  cancel(): void {
    this.onCancel.emit(null);
  }

  deleteChangeOrder(id: string): void {
    if (this.warnOnDelete('change order')) {
      this.onDelete.emit(id);
    }

    if (event) {
      event.stopPropagation();
    }
  }
}
