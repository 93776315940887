export interface IBackReference {
	_id: string;
	name: string;
}

export interface ISupplierReference {
	_id: string;
	name: string;
	status: Object;
	type: string;
	category: string;
	location: string;
}

export interface IFileReference {
	name: string;
	url: string;
}

export interface IActivateable {
	isActive: boolean;
}

// text searches of fields
export interface ISearchRequest {
	searchTerm: string;
}

export interface ITaxes {
	stateTax: number;
	countyTax: number;
	mkecountyTax: number;
	stadiumTax: number;
	cityTax: number;
}

export const EmptyBackReference: IBackReference = {
	_id: null,
	name: null
};

export abstract class BaseModel {
	_id: string;
	timestamp: Date = new Date();
}

export interface IFocusable {
	focus: () => void;
}

// todo This shouldn't be necessary anymore with the latest version of typescript
export interface Enum<T> {
	[key: string]: T;
}

export function enumToArray<T>(values: Object): Array<T> {
	let _array = new Array<T>();

	for (let key in values) {
		_array.push(values[key] as T);
	}

	return _array;
}
