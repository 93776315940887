import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DocumentUploadModule } from '@/components/DocumentUploadComponent/documentUpload.module';
import { UtilityModule } from '@/utils/utility.module';
import { ContactModule } from '@/components/ContactComponent/contact.module';
import { SupplierComponent } from './supplier.component';
import { SupplierAddComponent } from './supplierAdd.component';
import { SupplierContactAddComponent } from './supplierContactAdd.component';
import { SupplierContactEditComponent } from './supplierContactEdit.component';
import { SupplierContactListComponent } from './supplierContactList.component';
import { SupplierEditComponent } from './supplierEdit.component';
import { SupplierEditFormComponent } from './supplierEditForm.component';
import { SupplierListComponent } from './supplierList.component';
import { FilterListModule } from './../FilterListComponent/filterList.module';
import {IMaskModule} from 'angular-imask';
import {JobModule} from '@/components/JobComponent/job.module';
import {SupplierSummaryComponent} from '@/components/SupplierComponent/supplierSummary.component';
import {SupplierEditTabsComponent} from '@/components/SupplierComponent/supplierEditTabs.component';
import {SupplierDocumentsComponent} from '@/components/SupplierComponent/supplierDocuments.component';
@NgModule({
  declarations: [
    SupplierComponent,
    SupplierAddComponent,
    SupplierContactAddComponent,
    SupplierContactEditComponent,
    SupplierContactListComponent,
    SupplierEditComponent,
    SupplierEditFormComponent,
    SupplierListComponent,
    SupplierSummaryComponent,
    SupplierEditTabsComponent,
    SupplierDocumentsComponent
  ],
              imports: [
                  CommonModule,
                  FormsModule,
                  RouterModule,
                  UtilityModule,
                  ContactModule,
                  InfiniteScrollModule,
                  FilterListModule,
                  IMaskModule,
                  JobModule,
                  DocumentUploadModule
              ],
  exports: [
    SupplierComponent,
    SupplierAddComponent,
    SupplierContactAddComponent,
    SupplierContactEditComponent,
    SupplierContactListComponent,
    SupplierEditComponent,
    SupplierListComponent,
    SupplierSummaryComponent,
    SupplierEditTabsComponent,
    SupplierDocumentsComponent
  ]
})
export class SupplierModule {}
