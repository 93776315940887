<form (ngSubmit)="saveJob()" #jobForm="ngForm" class="" autocomplete="off">
    <input name="id" type="hidden" value="{{ model._id }}" />

    <div class="row">
        <div class="col-sm-12 col-lg-7">
            <fieldset>
                <legend>
                    General Information
                </legend>
                <div class="form-group">
                    <label class="control-label">Number</label>
                    <div class="full-width">
                        <input type="text" class="form-control" [(ngModel)]="model.number" name="number" #name="ngModel" placeholder="Auto-generated" disabled/>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Job Name</label>
                    <div class="full-width">
                        <input id="jobName" type="text" class="form-control" [(ngModel)]="model.name" name="name" required="required" #name="ngModel" initialFocus />
                    </div>

                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && name.pristine) || name.valid">
                        Name is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Contract Number</label>
                    <div class="full-width">
                        <input type="text" class="form-control" [(ngModel)]="model.contractNumber" name="contractNumber" required="required" #contractNumber="ngModel" />
                    </div>
                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && contractNumber.pristine) || contractNumber.valid">
                        Contract Number is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Contract Date</label>
                    <div class="full-width">
                        <input type="date" class="form-control" [ngModel]="model.date | dateFormat" (ngModelChange)="model.date = sanitizeDate($event)" name="date" required="required" #date="ngModel" style="display:inline-block;width:30%; min-width:170px;" />
                    </div>
                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && date.pristine) || date.valid">
                        Contract Date is required
                    </div>
                </div>
                <div *ngIf="displayedContacts" class="form-group">
                    <label class="control-label">Customer Contact</label>
                    <select class="form-control filtered-select" [(ngModel)]="model.customerContact" name="customerContact" #customerContact="ngModel" required="required">
                        <option [selected]="!model.customerContact" [ngValue]="null" disabled>-- Select --</option>
                        <option *ngFor="let contact of displayedContacts" 
                            [ngValue]="contact._id" 
                            [disabled]="!contact.isActive" 
                        >
                        {{ contact.firstName }} {{ contact.lastName }}
                    </option>
                    </select>
                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && customerContact.pristine) || customerContact.valid">
                        Customer Contact is required
                    </div>
                </div>
                <div class="form-group">
                    <div (click)="openModal()" class="add-on" style="margin-top:5px;">
                        <div class="add-icon">+</div><span>Add a new customer contact</span>
                    </div>
                </div>
                <div *ngIf="users" class="form-group">
                    <label class="control-label">Project Manager</label>
                    <select class="form-control" [(ngModel)]="model.projectManager" name="projectManager" #projectManager="ngModel" required="required">
                        <option selected disabled>-- Select --</option>
                        <option *ngFor="let user of users" [value]="user._id">
                            {{ user.firstName }} {{ user.lastName }}
                        </option>
                    </select>
                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && projectManager.pristine) || projectManager.valid">
                        Project Manager is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Contract Amount</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" min="0" step="any" class="form-control" [(ngModel)]="model.contractAmount" name="contractAmount" #contractAmount="ngModel" enforceDecimal comma="true" placeholder="0.00" [required]="requireAmount" />
                    </div>
                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && contractAmount.pristine) || contractAmount.valid">
                        Contract Amount is required
                    </div>
                </div>
                <div *ngIf="appSettings?.showLaborCodes" class="form-group">
                    <label class="custom-checkbox">This job will have labor codes
                        <input type="checkbox" [(ngModel)]="model.hasLaborCodes" name="hasLaborCodes" #hasLaborCodes="ngModel">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </fieldset>
            <fieldset class="mt-3">
                <legend>
                    Job Address
                </legend>

                <div class="form-group">
                    <button class="btn btn-default" type="button" (click)="copyCustomerAddress()">Copy from customer address</button>
                </div>

                <div class="form-group">
                    <label class="control-label">Address 1</label>
                    <input type="text" class="form-control" [(ngModel)]="model.address.address1" name="address1" required="required" #address1="ngModel" />
                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && address1.pristine) || address1.valid">
                        Address 1 is required
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label pull-left">Address 2</label>
                    <input class="alert alert-danger" type="text" class="form-control" [(ngModel)]="model.address.address2" name="address2" />
                </div>

                <div class="form-group">
                    <label class="control-label city-st-zip"><div class="city">City</div><div class="state">State</div><div class="zip">Zip</div></label>
                    <div class="input-group full-width">
                        <input type="text" class="form-control city" [(ngModel)]="model.address.city" name="city" required="required" #city="ngModel" />
                        <state-selector [(ngModel)]="model.address.state" name="state" required="true" #state="ngModel"></state-selector>
                        <input type="text" class="form-control zip" [(ngModel)]="model.address.zip" name="zip" required="required" #zip="ngModel" [imask]="{ mask: '00000-0000' }" />
                    </div>

                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && city.pristine) || city.valid">
                        City is required
                    </div>

                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && state.pristine) || state.valid">
                        State is required
                    </div>

                    <div class="alert alert-danger" [hidden]="(!jobForm.form.submitted && zip.pristine) || zip.valid">
                        Zip is required
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">County</label>
                    <input class="form-control" type="text" class="form-control" [(ngModel)]="model.address.county" name="county" required="required" #county="ngModel" />
                </div>
            </fieldset>
        </div>
        <div class="col-sm-12 col-lg-5">
            <fieldset>
                <legend>
                    Flags
                </legend>
                <div class="form-group flags-container">
                    <div class="btn-group">
                        <button type="button" class="btn btn-default" (click)="flagToggle('isWhite')" [ngClass]="{'active btn-warning':model.isWhite}">White</button>
                        <button type="button" class="btn btn-default" (click)="flagToggle('isTaxExempt')" [ngClass]="{'active btn-warning':model.isTaxExempt}">Tax Exempt</button>
                        <button type="button" class="btn btn-default" (click)="flagToggle('isClosed')" [ngClass]="{'active btn-danger':model.isClosed}" style="border-radius: 0 4px 4px 0">Closed</button>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="bid-container">
                <legend class="pull=left">
                    Bid Numbers
                </legend>

                <div class="form-group">
                    <label class="control-label">Shop Labor</label>
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.shopLabor" name="shopLabor" #shopLabor="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                        <span class="input-group-addon">hours</span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Field Labor</label>
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.fieldLabor" name="fieldLabor" #fieldLabor="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                        <span class="input-group-addon">hours</span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Aluminum Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.materialCosts.aluminum" name="aluminumCosts" #aluminumCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Glass Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.materialCosts.glass" name="glassCosts" #glassCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Sealant Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.materialCosts.sealants" name="sealantCosts" #sealantCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Brake Metal Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.materialCosts.brakeMetal" name="brakeMetalCosts" #brakeMetalCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Other Material Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.materialCosts.other" name="otherMaterialCosts" #otherMaterialCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Equipment Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.otherCosts.equipment" name="equipmentCosts" #equipmentCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Engineering Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.otherCosts.engineering" name="engineeringCosts" #engineeringCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Subcontractor Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.otherCosts.subcontractor" name="subcontractorCosts" #subcontractorCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label">Other Outside Service Costs</label>
                    <div class="input-group">
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [(ngModel)]="model.bidAmounts.otherCosts.other" name="otherServiceCosts" #otherServiceCosts="ngModel" enforceDecimal comma="true" placeholder="0.00" />
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <legend class="pull-left mt-3">
                    Notes
                </legend>
                <div class="form-group">
                    <notes-field [(model)]="model.notes" [saved]="saved"></notes-field>
                </div>
            </fieldset>
        </div>
    </div>

    <div class="">
        <button type="submit" class="btn btn-primary mr-1" [disabled]="!jobForm.form.valid">Save</button>
        <div class="inline-block pseudo-link small capitalize" *ngIf="showCancel" (click)="cancel()">Cancel</div>
    </div>
</form>
<shared-modal 
    [display]="display" 
    [title]="'Add a new customer contact'"
    [showAlert]="showAlert" 
    (onClose)="resetContact()" 
    (onSave)="setNewContact($event)" 
    (onNavigate)="goToExistingContact()" 
    [type]="'contact'" 
    [model]="contactModel">
</shared-modal>
