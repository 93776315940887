import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import * as dataUtils from '@/utils/dataUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { IJobModel } from '@/models/job.models';
import { JobService } from '@/services/job.service';
import { IReportJobDetailResult, IReportJobDetailCost } from '@/models/report.models';
import { ReportService } from '@/services/report.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ReportJobDetailSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';
import { FilterValue } from '@/models/filter.models';

// todo lots of duplication with reportJobDetail
@Component({
  selector: 'report-job-summary',
  templateUrl: './reportJobSummary.template.html',
  host: {'class': 'report-job-summary-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class ReportJobSummaryComponent extends BaseSmartComponent implements OnInit {

  @ViewChild('jobResultList', {static: true})
  jobResultList: ElementRef;

  public totals = {
    gp: 0,
    gpAmount: 0
  };
  // todo rename or refactor so public objects are using an _ prefix
  public _job: IJobModel;
  public _jobNumber: string = null;
  public _lastJobNumber: string = null;
  public _jobResults: Array<IJobModel>;
  public dirty: boolean = true;
  public model: IReportJobDetailResult = null;
  public filters: FilterValue[] = [];

  private _model: Observable<IReportJobDetailResult>;
  private today: Date = this.sanitizeDate();
  public startDate: Date = this.today;
  public endDate: Date = this.today;

  private _currentUser: any;
	private _location: string;
  private _costs: Subject<Array<IReportJobDetailCost>> = new Subject<Array<IReportJobDetailCost>>();
  private costs: BehaviorSubject<Array<IReportJobDetailCost>> = new BehaviorSubject<Array<IReportJobDetailCost>>(null);

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService,
    private dataManipulationService: DataManipulationService<IReportJobDetailCost>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this._currentUser.locationId;
    this.dataManipulationService.initialize({}, ReportJobDetailSortFunctions);
    this.dataManipulationService.setSort('date', false);
  }

  // todo a minor variation is used in time entry; pull out as modular component
  public searchJobs(): void {
    if (this._jobNumber === '') {
      this._jobNumber = null;
    }

    if (this._jobNumber === this._lastJobNumber) {
      return;
    }

    this._lastJobNumber = this._jobNumber;
    this._job = null;
    this._jobResults = null;
    this.dirty = true;

    // search cleared
    if (!this._jobNumber) {
      return;
    }

    // require most of the job number before executing the search
    if (this._jobNumber.length < 4) { return; }
    this.filters.push({ filter: 'locationFilter', id: this._location });

    this.jobService.getJobs(0, true, this._jobNumber, 'number', 1, 1, this.filters)
      .then(jobs => {
        if (jobs.length === 1) {
          // found exactly one job - use this
          this.selectJob(jobs[0]);
        } else {
          this._jobResults = jobs;
          if (this.jobResultList) {
            // previous tab-out takes effect before resetting focus
            setTimeout(() => {
              this.jobResultList.nativeElement.focus();
            }, 0);
          }
        }
      })
      .catch(handleError);
  }

  public runReport(): void {
    let result = this.reportService.getJobDetailReport(this._job._id, this.startDate, this.endDate);

    this.resetTotals();
    this.watchSubscription(this.dataManipulationService.connectDataSource(this._costs, this.costs));

    result.toPromise()
      .then((job) => {
        this.model = job;
        this._costs.next(job.costs);    //HACK: pull "observable" out from report

        this.model.inventoryPurchases = [];
        this.model.totalInventoryPurchases = 0;
        this.model.totalInventoryTax = 0;
        this.model.totalInventoryCosts = 0;

        this.model.ossPurchases = [];
        this.model.totalOssPurchases = 0;

        this.model.glazierLabor = 0;
        this.model.glazierLaborCosts = 0;
        this.model.shopLabor = 0;
        this.model.shopLaborCosts = 0;

        this.model.totalCosts = 0;

        job.costs.forEach(jobCost => {
          if (jobCost.cost || jobCost.cost === 0) {
            this.model.inventoryPurchases.push(jobCost);
            this.model.totalInventoryTax += jobCost.tax;
            this.model.totalInventoryPurchases += jobCost.cost;
          } else if (jobCost.other || jobCost.other === 0) {
            this.model.ossPurchases.push(jobCost);
            this.model.totalOssPurchases += jobCost.other;
          } else {
            if (jobCost.description.indexOf('Field') > -1) {
              this.model.glazierLabor += jobCost.billed;
              this.model.glazierLaborCosts += jobCost.labor;
            } else {
              this.model.shopLabor += jobCost.billed;
              this.model.shopLaborCosts += jobCost.labor;
            }
          }
        });
        this.model.glazierLaborRate = (this.model.glazierLaborCosts > 0 ? this.model.glazierLaborCosts / this.model.glazierLabor : 0);
        this.model.shopLaborRate = (this.model.shopLaborCosts > 0 ? this.model.shopLaborCosts / this.model.shopLabor : 0);
        this.model.totalInventoryCosts = this.model.totalInventoryPurchases + this.model.totalInventoryTax;
        this.model.totalCosts = this.model.totalInventoryPurchases + this.model.totalOssPurchases + this.model.glazierLaborCosts + this.model.shopLaborCosts;
        this.totals.gpAmount = job.invoices - job.totalsToDate.subtotal - job.totalsToDate.stateTax - job.totalsToDate.countyTax - job.totalsToDate.mkecountyTax - (job.totalsToDate.cityTax ? job.totalsToDate.cityTax : 0) - job.totalsToDate.stadiumTax;
        this.totals.gp = this.totals.gpAmount / job.invoices;

        this.dirty = false;
      })
      .catch(handleError);
  }

  public markDirty(): void {
    this.dirty = true;
  }

  private selectJob(job: IJobModel): void {
    this._job = job;
    this._jobNumber = this._job.number;
    this._jobResults = null;
    if (this._job.date) {
      this.startDate = this.sanitizeDate(this._job.date);
    } else {
      this.startDate = this.sanitizeDate('1970-01-01T00:00:01');
    }
  }

  private resetTotals(): void {
    this.totals = {
      gp: 0,
      gpAmount: 0
    };
  }

  private keepEmpty(value: string | number): string {
    if (value === null || value === 0) {
      return '';
    }

    if (typeof value === 'string') {
      return '';
    } else if (typeof value === 'number') {
      return dataUtils.formatCurrency(value);
    }
    return '';
  }

  // hack iterate over object properties
  private getKeys(source: { [key: string]: any }): Array<string> {
    return Object.keys(source);
  }
}
