
<table class="table table-select-list" [ngClass]="{'with-indicator':showIndicator}" aria-describedby="payablesLabel">
	<thead>
		<tr class="small">
			<th scope="col" class="list-item-date">
				Date
			</th>
			<th scope="col" class="list-item-number">
				Invoice #
			</th>
			<th scope="col" class="list-item-number">
				P.O. #
			</th>
			<th scope="col" class="currency-display">
				Amount
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let payable of model" (click)="selectPayable(payable)" [ngClass]="{ 'active': payable._id === selectedPayableId }">
			<td scope="row" class="list-item-date">
				{{ payable.invoiceDate | dateFormat:'MMM DD, YYYY' }}
			</td>
			<td scope="row" class="list-item-number">
				<div>{{ payable.invoiceNumber }}</div>
				<div class="small-label" style="margin:3px 0;opacity:.85;">Supplier</div>
				<div>{{ payable.supplier.name }}</div>
			</td>
			<td scope="row" class="list-item-number">
				<div *ngFor="let po of payable.purchaseOrders">{{po.number}}</div>
			</td>
			<td scope="row" class="currency-display">
				{{ payable.amount | currencyFormat }}
			</td>
		</tr>
	</tbody>
</table>
