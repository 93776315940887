import { BaseModel } from './utility.models';

export interface IChangeOrderModel extends BaseModel {
    _id : string;
    number : string;
    description : string;
    date : string;
    amount : number;
	documents: Object;
};

// <editor-fold> Document models

export class ChangeOrderModel extends BaseModel implements IChangeOrderModel {
    override _id : string = null;
    number : string = null;
    description : string = null;
    date : string = null;
    amount : number = null;
	documents: Object;
};

// </editor-fold>
