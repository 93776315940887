import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPrintOptions } from './printable.component';

@Component({
    selector: 'print-menu',
	template: `
	<nav>
	<ul class="nav nav-tabs">
		<li class="pull-right">
			<a href="javascript:;" (click)="toggleMenu()">
				Print
			</a>
			<ul class="print-menu dropdown-menu" role="menu" [ngClass]="{'show':showMenu}">
				<li class="print-menu-option" role="menuitem" *ngFor="let copy of printOptions.copies">
                    <button type="button" class="dropdown-item btn btn-default btn-checkbox" [ngClass]="{ 'active btn-primary': copy.enabled }" (click)="copy.enabled = !copy.enabled; $event.stopPropagation();">
                        <i class="glyphicon glyphicon-ok"></i>
                    </button> {{ copy.label }}
				</li>
				<li role="separator" class="divider"></li>
				<li class="print-menu-execute" role="menuitem">
					<button type="button" class="dropdown-item btn btn-primary" (click)="print(); $event.stopPropagation();" [disabled]="!canPrint()">Print</button>
				</li>
			</ul>
		</li>
	</ul>
</nav>
    `,
    host: {'class': 'print-menu-component'}
})
export class PrintMenuComponent {
    @Input()
    printOptions : IPrintOptions;

    @Input()
    formChanged : boolean;

    @Output()
    onPrint = new EventEmitter<void>();

    @Output()
    setPrintedFlag = new EventEmitter<boolean>();

    public showMenu = false;

    public print() {
        if(this.formChanged) {
            alert('You have unsaved changes. Please save before printing.')
        } else {
            this.toggleMenu();
            this.onPrint.emit(null);
            this.setPrintedFlag.emit(true);
            this.formChanged = false;
        }
    }

    public canPrint() : boolean {
        for(let copy of this.printOptions.copies) {
            if(copy.enabled) {
                return true;
            }
        }

        return false;
    }

    public toggleMenu() {
        this.showMenu = !this.showMenu;
    }
}
