import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { CustomerService } from '@/services/customer.service';
import { IContactModel } from '@/models/contact.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'customer-contact-edit',
  templateUrl: './customerContactEdit.template.html',
  host: {'class': 'customer-contact-edit-component'}
})
export class CustomerContactEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IContactModel>;
  public isNew = false;

  private _customerId: string;
  private _contactId: string;
  private _customerRoute: ActivatedRoute;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private customerService: CustomerService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveContact();
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._contactId = routerUtils.getRouteParameter(this.route, 'contactId');
    this._customerRoute = routerUtils.getParentRoute(this.route);

    if (this._contactId) {
      this.customerService.getContact(this._customerId, this._contactId)
        .catch(handleError);
    }
  }

  public saveContact(newModel: IContactModel): void {
    this.customerService.updateContact(this._customerId, newModel)
      .then(() => {
          this.router.navigate(['.'], { relativeTo: this._customerRoute });
      })
      .catch(handleError);
  }

  public cancel(): void {
    this.router.navigate(['.'], { relativeTo: this._customerRoute });
  }
}
