import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDataSortProvider } from './dataManipulationUtils';

@Component({
    selector: 'sortable-header',
    template: `
    <div (click)="sort(field)" [ngClass]="{ 'active': sortManager.currentSort === field }">
        {{ label }}
        <span *ngIf="sortManager.currentSort === field">
            <i *ngIf="sortManager.descending !== true" class="glyphicon glyphicon-chevron-up"></i>
            <i *ngIf="sortManager.descending === true" class="glyphicon glyphicon-chevron-down"></i>
        </span>
    </div>
    `,
    host: {'class': 'sortable-header-component'}
})
export class SortableHeaderComponent {
    @Input()
    label: string = '';

    @Input()
    field: string = '';

    @Input()
    sortManager!: IDataSortProvider;

    @Input()
    useLocalSort!: boolean;

    @Input()
    sortDescending!: boolean;

    @Output()
    onSort = new EventEmitter<string>();

    public sort(field: string) : void {
        if(this.useLocalSort) {
            this.sortManager.descending = this.sortDescending;
            this.sortManager.currentSort = this.field;
        } else {
            this.sortManager.setSort(field);
        }
        this.onSort.emit(field);
    }
};
