import * as Sentry from '@sentry/angular-ivy';
// import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { ErrorHandler, Injectable, Provider } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	handleError(error: any): void {
		if (error instanceof HttpErrorResponse && error?.error?.code === 401) {
			// don't track unauthorized http responses
			return;
		}
		let errorMessage = error.originalError || error?.error || error;
		Sentry.captureException(errorMessage);
		// Send current user for debugging purposes
		Sentry.captureMessage(localStorage.getItem('currentUser') + ": " + errorMessage || 'no user captured');
		throw error;
	}
}

export const ErrorHandlerFactory = (): ErrorHandler => {
	if (environment.DSN_KEY) {
		Sentry.init({
			dsn: environment.DSN_KEY,
			environment: environment.SENTRY_ENVIRONMENT === 'production' ? 'Production' : 'Development',
			release: `${environment.PACKAGE_NAME}@${environment.PACKAGE_VERSION}`,
			ignoreErrors: ['ResizeObserver loop limit exceeded'],
			// integrations: [new TracingIntegrations.BrowserTracing()],
			// tracesSampleRate: 0.2,
		});
		return new SentryErrorHandler();
	}
	return new ErrorHandler();
};

export const ErrorHandlerProvider: Provider = {
	provide: ErrorHandler,
	useFactory: ErrorHandlerFactory,
	deps: []
};
