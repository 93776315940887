import * as moment from 'moment';

//TODO: guard on bad parameter types

export function sanitizeDate(value: string | Date = null): Date {
    // sanitize today's date by default
    if (value === null) {
        value = new Date();
    }

    return new Date(moment(value).format("YYYY-MM-DD"));
};

export function sanitizeNumber(value: string | number, places: number = null): number {
    if (value === null) {
        return null;
    }

    let numeric = Number(value);
    if (isNaN(numeric)) {
        return null;
    }

    return (places !== null ? Number(numeric.toFixed(places)) : numeric);
}

export function formatDate(value: string | Date, format: string = 'YYYY-MM-DD'): string {
    let date = moment(value);

    return date.utc().format(format);
};

export function formatCurrency(value: string | number, figureCount: number = 2): string {
    let result = Number(value);

    return result.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: figureCount, useGrouping: true });
};

export function formatDecimal(value: string | number, sigFig: number = 2, comma: boolean = false): string {
	let result = Number(value);

	if (comma) {
        let regex = new RegExp('(\\d)(?=(\\d{3})+\\.\\d{' + sigFig + '}$)', 'g');

        return result.toFixed(sigFig).replace(regex, '$1,');
	} else {
		return result.toFixed(sigFig);
	}
}

export function validDate(value: string): boolean {
    let date = moment(value, 'DD/MM/YYYY', true);
    return date.isValid();
}
