<header id="header">
    <div class="container pr-0">
        <div class="logo-container" style="float:left; margin-top:-3px;">
            <a [routerLink]="['Dashboard']" [ngClass]="{'disabled':!isLoggedIn}"><img src="../assets/img/KD_logo.png" alt="Go to Dashboard"/></a>
        </div>
        <div class="flex-container" style="float:right;">
            <div class="nav-container">
                <site-area [user]="user"></site-area>
            </div>
        </div>
    </div>
</header>
<main id="main-content">
    <router-outlet></router-outlet>
</main>
<footer>
    <app-version id="app-version"></app-version>
</footer>
<div id="toast">
    <div toastContainer class="toaster-container-component"></div>
</div>
