import { Pipe, PipeTransform } from '@angular/core';
import * as dataUtils from './dataUtils';

@Pipe({
    name: 'dateFormatUS',
    pure: true
})

export class DateFormatUSPipe implements PipeTransform {
    transform(value: string, format: string = 'MM/DD/YYYY'): string {
        if (!value) {
            return null;
        }

        return dataUtils.formatDate(value, format);
    }
};
