import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { AuthService } from '@/services/auth.service';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { PrintService } from '@/services/print.service';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'purchase-order',
  templateUrl: './purchaseOrder.template.html',
  host: {'class': 'purchase-order-component'}
})
export class PurchaseOrderComponent extends PrintableComponent implements OnInit, OnDestroy {
  
  public exportOptions = { copies: null };

  private _customerId: string;
  private _jobId: string;
  private _purchaseOrderId: string;
  private model: IPurchaseOrderModel = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    private authService: AuthService,
    messageService: MessageService,
    private filesService: FilesService,
    private printService: PrintService
  ) {
      super(router, printService, messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');

    let editRoute = routerUtils.getRouteParameterObservable(this.route, 'purchaseOrderId');
    if (editRoute) {
      this.watchSubscription(editRoute.subscribe((s) => {
        if (s['purchaseOrderId']) {
          this._purchaseOrderId = s['purchaseOrderId'];
          this.purchaseOrderService.getPurchaseOrder(this._customerId, this._jobId, this._purchaseOrderId)
            .then(purchaseOrder => {
              this.model = purchaseOrder;
            })
            .catch(handleError);
          this.printUrlBase = '/purchaseorders/' + this._purchaseOrderId;
        } else {
          // todo reroute to somewhere else
        }
      }));
    }

    this.printOptions.copies = [
      { index: 'supplier', label: 'Supplier', enabled: false },
      { index: 'accounting', label: 'Accounting', enabled: false },
      { index: 'shop', label: 'Shop', enabled: false },
      { index: 'job', label: 'Job', enabled: false }
    ];

    this.exportOptions.copies = [
      { index: 'supplier', label: 'Supplier', enabled: false },
      { index: 'accounting', label: 'Accounting', enabled: false },
      { index: 'shop', label: 'Shop', enabled: false },
      { index: 'job', label: 'Job', enabled: false }
    ];
  }

  override ngOnDestroy(): void {
    this.state.setActivePurchaseOrder(null);
    super.ngOnDestroy();
  }

  public setPrintedFlag(): void {
    this.printService.itemPrinted.emit(true);
  }

}
