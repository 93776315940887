import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StateManager } from './../../state/stateManager';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';
import { IWorkOrderModel } from './../../models/workOrder.models';
import { DataManipulationService } from './../../services/dataManipulation.service';

@Component({
    selector: 'job-work-order-list',
    templateUrl: './jobWorkOrderList.template.html',
    host: {'class': 'job-work-order-list-component'}
})

export class JobWorkOrderListComponent extends BaseSmartComponent implements OnInit {
    public filterTerm : string = '';

    @Input()
    model : BehaviorSubject<Array<IWorkOrderModel>>;

    @Input()
    dataManager : DataManipulationService<IWorkOrderModel>;

    @Output()
    public addClicked = new EventEmitter();

    @Output()
    public itemSelected = new EventEmitter();

    public selectedWorkOrderId : string = null;

    constructor(private state : StateManager, messageService : MessageService) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.watchSubscription(self.state.getActiveWorkOrder().subscribe((s) => {
			setTimeout(()=> {
                if(s) {
                    self.selectedWorkOrderId = s._id;
                }
                else {
                    self.selectedWorkOrderId = null;
                }
            })
        }));
    }
};
