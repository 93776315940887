/* Barrel of Interceptors */

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DomainInterceptor } from './domain.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	// { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: DomainInterceptor, multi: true },
	// { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];
