import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilityModule } from './../../utils/utility.module';
import { TimeEntryModule } from './../TimeEntryComponent/timeEntry.module';

import { ReportComponent } from './report.component';
import { ReportSidebarComponent } from './reportSidebar.component';
import { ReportWindowComponent } from './reportWindow.component';
import { ReportJobListComponent } from './reportJobList.component';
import { ReportTimeEntryComponent } from './reportTimeEntry.component';
import { ReportJournalCostComponent } from './reportJournalCost.component';
import { ReportJobOutcomeComponent } from './reportJobOutcome.component';
import { ReportMonthlyCostsComponent } from './reportMonthlyCosts.component';
import { ReportJobDetailComponent } from './reportJobDetail.component';
import { ReportJobSummaryComponent } from './reportJobSummary.component';
import { ReportInvoiceSummaryComponent } from './reportInvoiceSummary.component';

@NgModule({
	declarations: [
		ReportComponent,
		ReportSidebarComponent,
		ReportWindowComponent,
		ReportJobListComponent,
		ReportTimeEntryComponent,
		ReportJournalCostComponent,
		ReportJobOutcomeComponent,
		ReportMonthlyCostsComponent,
		ReportJobDetailComponent,
		ReportJobSummaryComponent,
		ReportInvoiceSummaryComponent		
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		UtilityModule,
		TimeEntryModule
	],
	exports: [
		ReportJobListComponent,
		ReportTimeEntryComponent,
		ReportJournalCostComponent,
		ReportJobOutcomeComponent,
		ReportMonthlyCostsComponent,
		ReportJobDetailComponent,
		ReportJobSummaryComponent,
		ReportInvoiceSummaryComponent
	]
})
export class ReportModule { };
