import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { Subscription, filter } from 'rxjs';

import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { CustomerService } from '@/services/customer.service';
import { CustomerModel } from '@/models/customer.models';

@Component({
  selector: 'customer',
  templateUrl: '../Layout/layout-sidebar.template.html',
  host: {'class': 'customer-component'}
})
export class CustomerComponent extends BaseSmartComponent implements OnInit, OnDestroy {
  private _id: string;
  private name: string;

  private routerEvents: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private customerService: CustomerService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.watchSubscription(this.state.getActiveCustomer().subscribe((s) => {
      this.name = s.name;
    }));

    this.routerEvents = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.loadCustomer());

    this.loadCustomer();
  }

  override ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
    this.state.setActiveCustomer(new CustomerModel());
    super.ngOnDestroy();
  }

  private loadCustomer(): void {
    this._id = routerUtils.getRouteParameter(this.route, 'customerId');
    if (this._id) {
      this.customerService.getCustomer(this._id);
    } else {
      this.router.navigate(['Customers']);
    }
  }
}
