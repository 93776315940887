import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CustomerComponent } from './../CustomerComponent/customer.component';
import { CustomerListComponent } from './../CustomerComponent/customerList.component';
import { CustomerAddComponent } from './../CustomerComponent/customerAdd.component';
import { CustomerEditTabsComponent } from './../CustomerComponent/customerEditTabs.component';
import { CustomerEditDetailsComponent } from './../CustomerComponent/customerEditDetails.component';
import { CustomerContactListComponent } from './../CustomerComponent/customerContactList.component';
import { CustomerContactAddComponent } from './../CustomerComponent/customerContactAdd.component';
import { CustomerContactEditComponent } from './../CustomerComponent/customerContactEdit.component';
import { CustomerJobListComponent } from './../CustomerComponent/customerJobList.component';
import { CustomerDocumentsComponent } from './../CustomerComponent/customerDocuments.component';

import { JOB_ROUTES } from './../JobComponent/job.routes';
import { JobAddComponent } from './../JobComponent/jobAdd.component';
import { CustomerSummaryComponent } from "./customerSummary.component";

export const CUSTOMER_ROUTES: Routes = [
  {
    path: 'Customers',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: CustomerListComponent },
      { path: 'Add', component: CustomerAddComponent },
      {
        path: ':customerId',
        children: [
          {
            path: 'Edit',
            component: CustomerComponent,
            children: [
              {
                path: '',
                component: CustomerSummaryComponent,
                outlet: 'sidebar',
                pathMatch: 'prefix',
              },
              {
                path: '',
                component: CustomerEditTabsComponent,
                children: [
                  { path: 'Details', component: CustomerEditDetailsComponent },
                  {
                    path: 'Contacts',
                    children: [
                      { path: '', component: CustomerContactListComponent },
                      { path: 'Add', component: CustomerContactAddComponent },
                      {
                        path: ':contactId',
                        component: CustomerContactEditComponent,
                      },
                    ],
                  },
                  {
                    path: 'Jobs',
                    children: [
                      { path: '', component: CustomerJobListComponent },
                      { path: 'Add', component: JobAddComponent },
                    ],
                  },
                  { path: 'Documents', component: CustomerDocumentsComponent },
                ],
              },
            ],
          },
          { path: 'Job', children: JOB_ROUTES },
        ],
      },
    ],
  },
];
