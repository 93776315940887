import * as Utility from './utility.models';

export interface IStaffRate {
    name: string;
    rate: number;
};

export interface IStaffModel extends Utility.BaseModel, Utility.IActivateable {
    firstName : string;
    lastName : string;
    position : string;
    rates : Array<IStaffRate>;
    isActive: boolean;
    tradeLevel: string;
};

export interface IStaffTypeaheadModel {
    _id: string;
    name: string;
    rates: Array<IStaffRate>;
};

// <editor-fold> Document models

export class StaffModel extends Utility.BaseModel implements IStaffModel {
    firstName : string = null;
    lastName : string = null;
    position : string = null;
    rates : Array<IStaffRate> = new Array<IStaffRate>();
    isActive: boolean = true;
    tradeLevel: string;
};

// </editor-fold>
