import { Attribute, Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { IValidationResult } from './validationUtils';

@Directive({
    selector: 'input[validatePhone][ngModel]',
    providers: [ { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidatePhoneDirective), multi: true} ]
})

export class ValidatePhoneDirective implements Validator {
    private _required : boolean = false;

    constructor(@Attribute('required') required : string) {
        if(required) {
            this._required = true;
        }
    }

    validate(control : AbstractControl) : IValidationResult {
        //HACK: the UI masking directive includes placeholder characters in input value (but not backing model)
        let value = control.value ? control.value.replace(/_|\(|\)|-|\s|x/g, '') : null;

        if(!this._required && (!value || value.length === 0)) {
            return null;
        }

        if(value.length < 10) {
            return { 'message': 'Invalid phone number' };
        }

        return null;
    }
};
