import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '../base.component';
import { MessageService } from '@/services/message.service';
import { FilesService } from '@/services/files.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { IPurchaseOrderModel } from '@/models/purchaseOrder.models';

@Component({
    selector: 'purchase-order-documents',
    templateUrl: './purchaseOrderDocuments.template.html',
    host: {'class': 'purchase-order-documents-component'}
})
export class PurchaseOrderDocumentsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
    private _customerId: string;
    private _jobId: string;
    // todo refactor or rename so public property isn't prefixed with _
    public _purchaseOrderId: string;
    public duplicateRecord: boolean = false;
    public model = new Subject<IPurchaseOrderModel>();
    private currentPO: any;
    private filesQueued: boolean = false;

    constructor(private route: ActivatedRoute, private purchaseOrderService: PurchaseOrderService, messageService: MessageService, private filesService: FilesService, private state: StateManager) {
        super(messageService);
    }

    ngOnInit(): void {
        const self = this;
        self._customerId = routerUtils.getRouteParameter(self.route, 'customerId');
        self._jobId = routerUtils.getRouteParameter(self.route, 'jobId');
        self._purchaseOrderId = routerUtils.getRouteParameter(self.route, 'purchaseOrderId');
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    public saveOrder(order): void {
        const self = this;
        self.filesService.saveFilesOrder('PO', self._purchaseOrderId, order);
    }

    public duplicate(): void {
        this.duplicateRecord = true;
    }

    public setMessage(e) {
        this.filesQueued = e;
    }
}
