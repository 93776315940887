import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilityModule } from '../../utils/utility.module';
import { AngularMyDatePickerModule } from 'angular-mydatepicker-ivy';
import { FilterEntriesComponent } from './filterEntries.component';

@NgModule({
    declarations: [
        FilterEntriesComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UtilityModule,
        AngularMyDatePickerModule
    ],
    exports: [
        FilterEntriesComponent
    ]
})
export class FilterEntriesModule {}
