import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { IStaffModel } from '@/models/staff.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'staff-edit',
  templateUrl: './staffEdit.template.html',
  host: {'class': 'staff-edit-component'}
})
export class StaffEditComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IStaffModel>;

  private _id: string;
  private _locationId: string;
  private _parentRoute: ActivatedRoute;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private state: StateManager,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveStaff();
    this._id = routerUtils.getRouteParameter(this.route, 'staffId');
    this._locationId = routerUtils.getRouteParameter(this.route, 'locationId');
    this._parentRoute = routerUtils.getParentRoute(this.route, 1);

    if (this._id) {
      this.locationService.getStaff(this._locationId, this._id);
    }
  }

  public saveStaff(newModel: IStaffModel): void {
    this.locationService.updateStaff(this._locationId, newModel)
        .then(() => this.router.navigate(['.'], { relativeTo: this._parentRoute }))
        .catch(handleError);
  }

  public cancel(): void {
    this.router.navigate(['.'], { relativeTo: this._parentRoute });
  }
}
