import { Routes } from '@angular/router';
import { JobComponent } from './job.component';

import { JobEditComponent } from './jobEdit.component';
import { JobChangeOrderAddComponent } from './jobChangeOrderAdd.component';
import { JobChangeOrderEditComponent } from './jobChangeOrderEdit.component';
import { JobInvoiceAddComponent } from './jobInvoiceAdd.component';
import { JobInvoiceEditComponent } from './jobInvoiceEdit.component';
import { JobTimeEntryComponent } from './jobTimeEntry.component';
import { JobCostsComponent } from './jobCosts.component';
import { JobWarrantyComponent } from './jobWarranty.component';
import { LaborCodesComponent } from './laborCodes.component';
import { JobDocumentsComponent } from './jobDocuments.component';

import { WORKORDER_ROUTES } from './../WorkOrderComponent/workOrder.routes';
import { PURCHASEORDER_ROUTES } from './../PurchaseOrderComponent/purchaseOrder.routes';
import { JobSummaryComponent } from "./jobSummary.component";
import { JobWindowComponent } from "./jobWindow.component";
import { INVOICE_ROUTES } from '../InvoiceComponent/invoice.routes';
import { PAYABLE_ROUTES } from '../PayableComponent/payable.routes';
import { CHANGEORDER_ROUTES } from './../ChangeOrderComponent/changeOrder.routes';

export const JOB_ROUTES : Routes = [
	{ path: ':jobId', component:JobComponent, children: [
		{ path: '', component: JobSummaryComponent, outlet:'sidebar', pathMatch:'prefix'},
		{ path: '', component: JobWindowComponent, children: [

	        { path: 'WorkOrders', children: WORKORDER_ROUTES },
	        { path: 'PurchaseOrders', children: PURCHASEORDER_ROUTES },
	        { path: 'ChangeOrders', children: CHANGEORDER_ROUTES},
	        { path: 'Invoices', children: INVOICE_ROUTES},
			{ path: 'Costs', component: JobCostsComponent },
			{ path: 'TimeEntry', component: JobTimeEntryComponent },
	        { path: 'Payables', children: PAYABLE_ROUTES},
			{ path: 'LaborCodes', component: LaborCodesComponent },
			{ path: 'JobDocuments', component: JobDocumentsComponent },
	        { path: 'Warranty', children:[
		        { path:'', component: JobWarrantyComponent }
	        ]},
			{ path: 'Edit', children:[
				{ path: '', component: JobEditComponent }
			]}
		]}
	]}
];
