<div class="connection-section" *ngIf="!attachedWorkOrder && _purchaseOrderId">
    <div class="screen-subtitle">Attach to work order</div>

    <table class="table table-select-list mb-0">
        <caption>
            <div class="searchbox searchbox-fixed">
                <search placeholder="Filter work orders" [(ngModel)]="filterTerm" filterData="text" [filterManager]="dataManipulationService"></search>
            </div>
        </caption>
        <thead>
            <tr>
                <th scope="col" class="date">
                    <sortable-header field="date" label="Date" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="number">
                    <sortable-header field="number" label="Number" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="name">
                    <sortable-header field="name" label="Name" [sortManager]="dataManipulationService"></sortable-header>
                </th>
                <th scope="col" class="connection-actions"></th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>

    <div class="attached">
        <table class="table table-select-list">
            <caption class="sr-only">List of work orders</caption>
            <thead class="column-spacer">
                <tr>
                    <th scope="col" class="date"></th>
                    <th scope="col" class="number"></th>
                    <th scope="col" class="name"></th>
                    <th scope="col" class="connection-actions"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let workOrder of (workOrders | async)" (click)="goToWorkOrder(workOrder._id)">
                    <td scope="row" class="date">
                        {{ workOrder.date | dateFormat:'MMM DD, YYYY' }}
                    </td>
                    <td scope="row" class="number">
                        {{ workOrder.number }}
                    </td>
                    <td scope="row" class="name">
                        {{ workOrder.name }}
                    </td>
                    <td scope="row" class="connection-actions">
                        <button type="button" class="btn btn-default" (click)="attachToWorkOrder(workOrder, $event)">Attach</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="connection-section" *ngIf="attachedWorkOrder && _purchaseOrderId">

	<div>
		<div class="screen-subtitle">Attached to work order</div>

		<div class="row">
			<div class="col-sm-4 col-md-3 col-lg-2">
				<i class="glyphicon glyphicon-file" style="font-size:7rem;color:#dbdbe8"></i>
			</div>
			<div class="col-sm-8 col-md-9 col-lg-10">
				<div class="wo-attachment-summary">
					<p class="number" (click)="goToWorkOrder(attachedWorkOrder._id)" style="text-decoration:underline;cursor:pointer;">
						WO# {{ attachedWorkOrder.number }}
					</p>
					<p class="name">
						{{ attachedWorkOrder.name }}
					</p>
					<p class="date">
						{{ attachedWorkOrder.date | dateFormat:'MMM DD, YYYY' }}
					</p>
				</div>

				<button type="button" class="btn btn-default" (click)="detachFromWorkOrder($event)">Detach</button>
			</div>
		</div>



	</div>


</div>
