import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { SupplierService } from '@/services/supplier.service';
import { IContactModel, ContactModel } from '@/models/contact.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'supplier-contact-list',
  templateUrl: './supplierContactList.template.html',
  host: {'class': 'supplier-contact-list-component'}
})
export class SupplierContactListComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<Array<IContactModel>>;
  private _id: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    messageService: MessageService,
    private supplierService: SupplierService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getContactsList();
    this._id = routerUtils.getRouteParameter(this.route, 'supplierId');
    this.supplierService.getContacts(this._id);
  }

  addContact(): void {
    this.state.setActiveContact(new ContactModel());
    this.router.navigate(['Add'], { relativeTo: this.route });
  }

  editContact(contact: IContactModel): void {
    this.router.navigate([contact._id], { relativeTo: this.route });
  }

  deleteContact(contact: IContactModel): void {
    this.supplierService.deleteContact(this._id, contact._id)
      .then(() => this.supplierService.getContacts(this._id))
      .catch(handleError);
  }

  toggleContactStatus(contact: IContactModel): void {
    this.supplierService.updateContact(this._id, contact)
      // tslint:disable-next-line:no-empty
      .then(() => {})
      .catch(handleError);
  }
}
