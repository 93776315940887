import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from './modal.component';
import { AddSupplierModule } from './../AddSupplierComponent/addSupplier.module';
import { ContactModule } from './../ContactComponent/contact.module';

@NgModule({
    declarations: [
        ModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        AddSupplierModule,
        ContactModule
    ],
    exports: [
        ModalComponent,
    ]
})
export class ModalModule {};
