import { Component, OnInit, ViewChildren, Input, Output, EventEmitter, QueryList, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription} from 'rxjs';

import { BaseSmartComponent } from '@/components/base.component';
import { handleError } from '@/utils/errors';

import { MessageService } from '@/services/message.service';
import { ToggleViewService } from '@/services/toggleView.service';
import { JobService } from '@/services/job.service';

import { FilterValue } from '@/models/filter.models';
@Component({
  selector: 'move-entry',
  templateUrl: './moveEntry.template.html',
  host: {'class': 'move-entry-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0,
        overflow: 'hidden'
       })),
      transition('void <=> *', animate(250)),
    ]),
  ]
})
export class MoveEntryComponent extends BaseSmartComponent implements OnInit {
  @ViewChildren('selectField') selectComponents: QueryList<any>;

  @Input()
  selectedEntries: Array<Object>;

  @Input()
  _customerId;

  @Input()
  _jobId;

  @Input()
  entryType;

  @Output()
  save: EventEmitter<Object> = new EventEmitter<Object>();

  public viewMove: boolean = false;
  public viewTab: boolean = true;
  public entry: any;
  public formattedJobs: Array<Object> = [];
  public filters: FilterValue[] = [];
  public invalid: boolean = null;
  public showNotes: boolean = false;
  public jobRequired: boolean = false;

  private viewSubscription: Subscription;
  private viewState: any;
  private jobSelected: string;
  private _currentUser: any;
	private _location: string;

  constructor(messageService: MessageService, private toggleView: ToggleViewService, private jobService: JobService, private router : Router, private route : ActivatedRoute,) {
    super(messageService);
  }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this._currentUser.locationId;
    if (this.entryType === 'cost') {
      this.showNotes = true;
      this.jobRequired = false;
    } else if (this.entryType === 'labor') {
      this.showNotes = true;
      this.jobRequired = false;
    }
    this.initFields();
    this.getJobs();
    this.toggleView.updateView(false, false);

    // watch for changes to filter view
    this.viewSubscription = this.toggleView.viewState
      .subscribe(item => {
        this.viewState = item;
        if (this.viewState.filter) {
          this.viewMove = false;
          this.viewTab = false;
        } else {
          this.viewTab = true;
        }
      });
  }

  override ngOnDestroy(): void {
    this.viewSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedEntries']) {
      if (this.selectedEntries && this.selectedEntries.length < 1 && this.viewMove) {
        this.toggleMoveView();
      }
    }
  }

  public toggleMoveView(): void {
    this.viewMove = !this.viewMove;
    this.toggleView.updateView(false, this.viewMove);
  }

  public saveMove(): void {
    if ((this.jobRequired && this.entry._jobId) || !this.jobRequired) {
        if (this.entry.postingDate) {
            this.entry.postingDate = new Date(this.entry.postingDate);
            this.invalid = null;
            this.save.emit(this.entry);
            this.cancelMove();
        } else {
            this.invalid = true;
        }
    } else {
        this.invalid = true;
    }
  }

  public cancelMove(): void {
    this.toggleMoveView();
    this.initFields();
  }

  public setJob(e): void {
    this.entry._jobId = e.id;
  }

  private initFields(): void {
    this.entry = {
      postingDate: '',
      options: this.formattedJobs,
      note: '',
      _jobId: ''
    };
  }

  private getJobs(): void {
    this.filters.push({ filter: 'locationFilter', id: this._location });
    this.jobService.getJobs(0, true, null, 'number', 1, 1, this.filters)
      .then(jobs => {
        jobs.forEach(job => {
          let status = '';
          if (job.isClosed) {
            status = ' (closed)';
          }
          let option = { id: job._id, text: job.number + ' - ' + job.name + status };
          this.formattedJobs.push(option);
        });
      })
      .catch(handleError);
  }
}

