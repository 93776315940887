import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { PurchaseOrderService } from '@/services/purchaseOrder.service';
import { DataManipulationService, DataManipulationServiceFactory } from '@/services/dataManipulation.service';
import { WorkOrderService } from '@/services/workOrder.service';
import { WorkflowService } from '@/services/workflow.service';
import { IReceiptModel, IReceiptPartModel } from '@/models/receipt.models';
import { updateLifecycle, WorkflowableItem } from '@/models/workflow.models';
import { ReceiptSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'purchase-order-receipts',
  templateUrl: './purchaseOrderReceipts.template.html',
  host: {'class': 'purchase-order-receipts-component'}
})
export class PurchaseOrderReceiptsComponent extends BaseSmartComponent implements OnInit, OnDestroy {
  public receiptsLoaded: boolean = false;

  private _customerId: string;
  private _jobId: string;
  private _purchaseOrderId: string;
  private _purchaseOrderBaseRoute: ActivatedRoute;
  private receipts: BehaviorSubject<Array<IReceiptModel>> = new BehaviorSubject<Array<IReceiptModel>>(null);
  private parts: Array<IReceiptPartModel> = new Array<IReceiptPartModel>();
  private receiptManager: DataManipulationService<IReceiptModel>;
  private editingReceiptId: string;
  private receiptDate: Date = this.sanitizeDate();
  private saved: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private purchaseOrderService: PurchaseOrderService,
    private dataManipulationServiceFactory: DataManipulationServiceFactory,
    messageService: MessageService,
    private workflowService: WorkflowService,
    private workOrderService: WorkOrderService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._customerId = routerUtils.getRouteParameter(this.route, 'customerId');
    this._jobId = routerUtils.getRouteParameter(this.route, 'jobId');
    this._purchaseOrderId = routerUtils.getRouteParameter(this.route, 'purchaseOrderId');
    this._purchaseOrderBaseRoute = routerUtils.getParentRoute(this.route, 3);

    this.receiptManager = this.dataManipulationServiceFactory.create<IReceiptModel>();
    this.receiptManager.initialize({}, ReceiptSortFunctions);
    this.watchSubscription(this.receiptManager.connectDataSource(this.state.getReceiptsList(), this.receipts));
    this.receiptManager.setSort('date', true);

    this.watchSubscription(this.receipts.subscribe((s) => {
      let partTotals = {};

      for (let receipt of s) {
        if (!receipt.part) { continue; }
        let partId = receipt.part.toString();

        if (!partTotals[partId]) {
          partTotals[partId] = 0;
        }

        partTotals[partId] += receipt.quantity;
      }

      for (let part of this.parts) {
        if (partTotals[part._id]) {
          part.quantityBackorder = part.quantityOrdered - partTotals[part._id];
        } else {
          part.quantityBackorder = part.quantityOrdered;
        }

        part.quantityReceived = part.quantityBackorder; // default received amount to backorder
      }
    }));

    this.watchSubscription(this.state.getPurchaseOrderPartsList().subscribe((s) => {
      // convert to whatever here
      this.parts = new Array<IReceiptPartModel>();

      for (let part of s) {
        let receiptPart = {
          _id: part._id,
          quantityOrdered: part.quantity,
          unit: part.unit,
          description: part.description,
          quantityReceived: part.quantity,    // default to number ordered
          isDamaged: false,
          location: null,
          notes: null,
          timestamp: part.timestamp
        } as IReceiptPartModel;

        this.parts.push(receiptPart);
        this.sortParts(this.parts);
      }
    }));

    this.purchaseOrderService.getParts(this._customerId, this._jobId, this._purchaseOrderId)
      .then(() => {
        this.reloadReceipts();
      });
    this.saved.next('false');
  }

  override ngOnDestroy(): void {
    this.state.setReceiptsList(new Array<IReceiptModel>());
    super.ngOnDestroy();
  }

  private reloadReceipts(): void {
    this.editingReceiptId = null;
    this.purchaseOrderService.getReceipts(this._customerId, this._jobId, this._purchaseOrderId)
      .then(() => {
        this.receiptsLoaded = true;
      });
  }

  private cancel(event: Event): void {
    this.reloadReceipts();
    this.saved.next('true');
    event.stopPropagation();
  }

  private createReceipts(): void {
    let receipts = new Array<IReceiptModel>();

    for (let receiptPart of this.parts) {
      if (receiptPart.quantityReceived < 1) { continue; }
      receipts.push({
        date: this.receiptDate,
        quantity: receiptPart.quantityReceived,
        location: receiptPart.location,
        isDamaged: receiptPart.isDamaged,
        notes: receiptPart.notes,
        part: receiptPart._id
      } as IReceiptModel);
    }

    if (receipts.length < 1) { return; }

    // todo For future auto-trigger of status change when all parts are received
    // self.updateWorkOrder(self.parts);

    this.purchaseOrderService.createReceiptBatch(this._customerId, this._jobId, this._purchaseOrderId, receipts)
      .then(() => {
        this.reloadReceipts();
        this.saved.next('true');

        // reset entry for new receipts
        for (let receiptPart of this.parts) {
          // receiptPart.quantityReceived = 0;
          receiptPart.location = null;
          receiptPart.isDamaged = false;
          receiptPart.notes = null;
        }
      })
      .catch(handleError);
  }

  private editReceipt(receipt: IReceiptModel, event: Event): void {
    this.editingReceiptId = receipt._id;
    if (event) {
      event.stopPropagation();
    }
  }

  private saveReceipt(receipt: IReceiptModel): void {
    this.purchaseOrderService.updateReceipt(this._customerId, this._jobId, this._purchaseOrderId, receipt)
      .then(() => {
        this.reloadReceipts();
        this.saved.next('true');
      })
      .catch(handleError);
  }

  private getPartById(partId: string): IReceiptPartModel {
    let result = this.parts.find((item) => {
        return item._id === partId;
    });
    return (result ? result : {} as IReceiptPartModel);
  }

  private deleteReceipt(receipt: IReceiptModel, event: Event): void {
    if (this.warnOnDelete('receipt')) {
      this.purchaseOrderService.deleteReceipt(this._customerId, this._jobId, this._purchaseOrderId, receipt._id)
        .then(() => this.reloadReceipts())
        .catch(handleError);
    }
    event.stopPropagation();
  }

  private sortParts(parts): void {
    parts.sort(function(a, b) {
      return (a.timestamp < b.timestamp) ? -1 : ((a.timestamp > b.timestamp) ? 1 : 0);
    });
  }

  // if all parts are received at once, update the work order status to "Ready to Schedule"
  private updateWorkOrder(parts): void {
    let allPartsReceived = true;
    parts.forEach(part => {
      if (part.quantityReceived !== part.quantityOrdered) {
        allPartsReceived = false;
      }
    });

    if (allPartsReceived) {
      this.purchaseOrderService.getPurchaseOrder(this._customerId, this._jobId, this._purchaseOrderId)
        .then(purchaseOrder => {
          if (purchaseOrder.workOrder) {
            // set status of work order
            let newStatus = {
              status: 'ready_full',
              note: 'Parts received on PO',
              timestamp: new Date().toISOString(),
              attachments: null
            };
            this.workOrderService.getWorkOrder(this._customerId, this._jobId, purchaseOrder.workOrder.toString(), true)
              .then((wo) => {
                const workOrder: WorkflowableItem = wo;
                if (workOrder.lifecycle[workOrder.lifecycle.length - 1].status !== 'ready_full') {
                  this.workflowService.updateWorkflow(workOrder, newStatus)
                    .subscribe((response: WorkflowableItem)  => {
                      if (response.lifecycle.length > 0) {
                        const savedResponse = response.lifecycle[response.lifecycle.length - 1];
                        if (savedResponse.status === newStatus.status) {
                          updateLifecycle(workOrder, savedResponse);
                        }
                      }
                    }, (err: any) => handleError(err));
                }
              });
          }
        })
        .catch(handleError);
    }
  }
}
