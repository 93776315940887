/**
 * Created by rrahlf on 4/10/2017.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IUserModel } from './../../models/user.models';

@Component({
	selector: 'profile-edit-form',
	templateUrl: './profileEditForm.template.html',
    host: {'class': 'profile-edit-form-component'}
})

export class ProfileEditFormComponent {
	@Input()
	model : IUserModel;

	@Output()
	onSave = new EventEmitter<IUserModel>();

	@Output()
	onCancel = new EventEmitter();

	saveUser() : void {
		this.onSave.emit(this.model);
	}

	cancel() : void {
		this.onCancel.emit(null);
	}
}