import { Injectable } from '@angular/core';

// use with angura2-text-mask directive:
// [textMask]="{ mask: maskconst }"
import createNumberMask from "text-mask-addons/dist/createNumberMask";

@Injectable()
export class UI_MASKS {
    public PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];
    public PHONE_WITH_EXTENSION_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', /\d/, /\d/, /\d/, /\d/, /\d/];
    public ZIP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    private ZIP_MASK_CAN = [ /[^AZaz09]+/, /[^AZaz09]+/, /[^AZaz09]+/, ' ', /[^AZaz09]+/, /[^AZaz09]+/, /[^AZaz09]+/];
    private TAX_MASK = createNumberMask({
		prefix:'',
		includeThousandsSeparator:true,
		thousandsSeparatorSymbol:',',
		allowDecimal:true,
		decimalSymbol:'.',
		decimalLimit:4,
		requireDecimal:true,
		allowNegative:false,
		allowLeadingZeroes:false
    })
};
