<div *ngIf="model.documents && model.documents.length" [@fadeInOut]>
    <ul *ngIf="isDraggable" class="docs-list unstyled draggable" [dragula]="documentsSorted" [(dragulaModel)]="model.documents">
        <li *ngFor="let document of model.documents" class="">
            <div class="col-xs-1"><i class="glyphicon glyphicon-option-vertical"></i></div>
            <div class="col-sm-8">
                <a href="{{document.url}}" target="_blank">{{document.name}}</a><span *ngIf="!isDeletable(document)" class="afs">(AFS)</span>
            </div>
            <div class="col-sm-3 text-right">
              <button *ngIf="isDeletable(document) && !disabled; else acknowledgement" type="button" class="btn btn-danger" (click)="removeDocument(document)"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
              <ng-template #acknowledgement><i class="glyphicon glyphicon-lock"></i></ng-template>
            </div>
        </li>
    </ul>
    <ul *ngIf="!isDraggable" class="docs-list unstyled">
        <li *ngFor="let document of model.documents" class="">
            <div class="col-sm-9">
                <a href="{{document.url}}" target="_blank">{{document.name}}</a>
            </div>
            <div class="col-sm-3 text-right">
                <button *ngIf="!disabled; else acknowledgement" type="button" class="btn btn-danger" (click)="removeDocument(document)"><i class="glyphicon glyphicon-trash" title="Delete"></i></button>
                <ng-template #acknowledgement><i class="glyphicon glyphicon-lock"></i></ng-template>
            </div>
        </li>
    </ul>
</div>
<div id="document-uploader">
    <div class="row">
        <div class="col-xs-12">
            <div ng2FileDrop
                    [ngClass]="{'nv-file-over': hasBaseDropZoneOver, 'disabled': !canUpload}"
                    [uploader]="uploader"
                    (fileOver)="fileOverBase($event)"
                    (onFileDrop)="onFileSelected($event)"
                    class="well my-drop-zone">
                <div>Drop files to upload</div>
                <div class="small">or</div>
                <button type="button" class="btn btn-outline" style="margin-top:6px;">
                    <label for="fileUploader">
                        Select Files
                        <input 
                            id="fileUploader" 
                            type="file" 
                            ng2FileSelect 
                            [uploader]="uploader" 
                            (onFileSelected)="onFileSelected($event)"
                            [multiple]="(limit != 1)"
                            style="display: none;"
                        />
                    </label>
                </button>
            </div>
            <div *ngIf="duplicateRecord" class="alert alert-danger text-center">A file with that name already exists.</div>
            <div *ngIf="fileSizeError" class="alert alert-danger text-center">File exceeds size limit ({{maxFileMBs}}MB).</div>
            <div *ngIf="fileUploadError" class="alert alert-danger text-center">There was an error uploading the file.</div>
        </div>
    </div>
</div>
