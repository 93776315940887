import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { PrintableComponent } from '@/utils/printable.component';
import { MessageService } from '@/services/message.service';
import { IReportJob, IReportJobListResult } from '@/models/report.models';
import { ReportService } from '@/services/report.service';
import { IUserModel } from '@/models/user.models';
import { UserService } from '@/services/user.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ReportJobListSortFunctions } from '@/utils/dataManipulationUtils';
import { PrintService } from '@/services/print.service';
import { handleError } from '@/utils/errors';
import {UserRole} from '@/models/auth.models';
import { AnyPtrRecord } from 'dns';

@Component({
  selector: 'report-job-list',
  templateUrl: './reportJobList.template.html',
  host: {'class': 'report-job-list-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class ReportJobListComponent extends PrintableComponent implements OnInit {
  public reportType: string = 'all';
  public includeClosed: boolean = false;
  public includeInvoiced: boolean = false;
  public groupByPM: boolean = false;    // client-side grouping mechanism
  public dirty: boolean = true;
  public pmReportListUsers: Array<IUserModel>;
  public pm: any;
  public model: Subject<Array<IReportJobListResult>> = new Subject<Array<IReportJobListResult>>();

  private today: Date = this.sanitizeDate();
  private year: number = this.today.getFullYear();
  private month: number = this.today.getMonth() + 1; // months are zero-indexed?

  private _rootRoute: ActivatedRoute;
	private _location: string;
  public currentUser: any;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    public dataManipulationService: DataManipulationService<IReportJobListResult>,
    private userService: UserService,
    private state: StateManager,
    messageService: MessageService,
    printService: PrintService
  ) {
    super(router, printService, messageService);
  }

  ngOnInit(): void {
    this.watchSubscription(this.route.queryParams.subscribe((s) => {
      if (s['reportType']) {
        switch (s['reportType']) {
          case 'year':
            this.reportType = 'year';
            break;
          case 'month':
            this.reportType = 'month';
            break;
          // todo: custom range option?
          default:
            this.reportType = 'all';
            break;
          }

          this.markDirty();
        }
    }));

    // todo: clean this up
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._location = this.currentUser.locationId;
    if(this.currentUser?.role === 10 || this.currentUser?.role === 20){
      this.pm = this.currentUser;
    } else {
      this.pm = {userId:null};
    }

    this.userService.getUsers().then(user => {
      this.pmReportListUsers = user;
      for (var i = 0; i < this.pmReportListUsers.length; i++){ 
        if (this.pmReportListUsers[i].role !== UserRole.President && this.pmReportListUsers[i].role !== UserRole.Admin && this.pmReportListUsers[i].role !== UserRole.ProjectManager && this.pmReportListUsers[i].role !== UserRole.VicePresident)  { 
            this.pmReportListUsers.splice(i, 1); 
            i--; 
        }
      }
      this.pmReportListUsers.sort((a, b) => {
        if (a.lastName > b.lastName) { return 1; }
        if (a.lastName < b.lastName) { return -1; }
        return 0;
      });
    })
    .catch(handleError);

    this._rootRoute = routerUtils.getParentRoute(this.route, 3);

    // todo: manage printed copies with / without costs attached, grouped by project manager, etc
    this.printOptions.copies = [
      { index: 'full', label: 'Full report', enabled: true },
      { index: 'noTotals', label: 'No totals', enabled: false }
    ];

    this.printUrlBase = '/report/joblist';
    this.dataManipulationService.initialize({}, ReportJobListSortFunctions);
    this.dataManipulationService.setSort('number', false);
  }

  public runReport(): void {
    let results = this.reportService.getJobListReport(this.getStartDate(), this.getEndDate(), this.includeClosed, this.pm?.userId || 'all', this._location);
    this.watchSubscription(this.dataManipulationService.connectDataSource(results, this.model));
    results.toPromise()
      .then(() => this.dirty = false)
      .catch(handleError);
  }

  public toggleIncludeClosed(): void {
    this.includeClosed = !this.includeClosed;
    this.markDirty();
  }

  public markDirty(): void {
    this.dirty = true;
  }

  public override print(): void {
    let queryParameters = {
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      includeClosed: this.includeClosed,
      includeInvoiced: this.includeInvoiced,
      reportType : this.reportType,
      sortField : this.dataManipulationService.currentSort,
      descending : this.dataManipulationService.descending,
      projectManager : this.pm?.userId,
      location : this._location
    };
    super.print(queryParameters);
  }

  private editJob(job: IReportJob): void {
    this.router.navigate(['/Customers', job.customerId, 'Job', job._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route) });
  }

  private toggleIncludeInvoiced(): void {
    // not marked dirty, becouse just showing / hiding existing report data
    this.includeInvoiced = !this.includeInvoiced;
  }

  private getStartDate(): Date {
    let startDate: Date = null;
    let dateString: string = null;

    switch (this.reportType) {
      case 'year':
        dateString = this.year.toString() + '/01/01';
        break;
      case 'month':
        dateString = this.year.toString() + '/' + this.month.toString() + '/01';
        break;
      default:
        break;
    }

    if (dateString) {
      startDate = new Date(dateString);
    }

    return startDate;
  }

  // backend treats end date as non-inclusive
  private getEndDate(): Date {
    let endDate: Date = null;
    let dateString: string = null;

    switch (this.reportType) {
      case 'year':
        dateString = (this.year + 1).toString() + '/01/01';
        break;
      case 'month':
        if (this.month === 12) {
            dateString = (this.year + 1).toString() + '/01/01';
        } else {
            dateString = this.year.toString() + '/' + (this.month + 1).toString() + '/01';
        }
        break;
      default:
        break;
    }

    if (dateString) {
      endDate = new Date(dateString);
    }

    return endDate;
  }
}
