import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { AuthService } from '@/services/auth.service';
import { CustomerService } from '@/services/customer.service';
import { ICustomerModel } from '@/models/customer.models';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { CustomerSortFunctions } from '@/utils/dataManipulationUtils';
import * as Utility from '@/models/utility.models';
import { handleError } from '@/utils/errors';
import { LocalStorageService } from '@/services/localStorage.service';

@Component({
  selector: 'customer-list',
  templateUrl: './customerList.template.html',
  host: {'class': 'customer-list-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class CustomerListComponent extends BaseSmartComponent implements OnInit, OnDestroy {
  @ViewChild('customerSearch', {static: true})
  customerSearch: Utility.IFocusable;

  public showGlobal: boolean = false;
  public showInactive: boolean = false;
  public searchTerm: string = null;
  public sortDirection: number = 1;
  public currentSort: string = 'name';
  public isLoading: boolean = true;

  private _locationId: string = null;
  private model: Subject<Array<ICustomerModel>> = new Subject<Array<ICustomerModel>>();
  private hasResults: boolean = null;
  private customerSet = [];
  private pageNumber: number = 0;
  private sortField: string = 'name';
  private scrollPosition: number = 0;
  private _currentUser: any;
  private locationSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private customerService: CustomerService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private dataManipulationService: DataManipulationService<ICustomerModel>,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.dataManipulationService.initialize({}, CustomerSortFunctions);
    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getCustomersList(), this.model));

    this.authService.currentUser.subscribe((s) => {
      this._locationId = s ? s.locationId : null;
    }).unsubscribe();

    // needed to reload list after location change
    this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
      this.resetList();
      this.reloadCustomerList();
    });

    this.watchSubscription(this.state.getCustomersList().subscribe((s) => {
      if (!s) {
        this.hasResults = false;
        return;
      }
      this.hasResults = (s.length > 0);
    }));

    this.reloadCustomerList();

    if (this.customerSearch) {
        this.customerSearch.focus();
    }
  }

  override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
    super.ngOnDestroy();
  }

  public addCustomer(): void {
    this.router.navigate(['Add'], { relativeTo: this.route });
  }

  public search(): void {
    this.resetList();
    this.reloadCustomerList();
  }

  filterToggle(value: string): void {
    switch (value) {
      case 'showGlobal':
        this.showGlobal = !this.showGlobal;
        this.resetList();
        this.reloadCustomerList();
        break;
      case 'showInactive':
        this.showInactive = !this.showInactive;
        this.resetList();
        this.reloadCustomerList();
        break;
      default:
        this.resetList();
        this.reloadCustomerList();
        break;
    }
  }

  public sortList(e): void {
    this.sortField = e.field;
    this.sortDirection = e.sortDirection;
    this.currentSort = e.currentSort;
    this.resetList();
    this.reloadCustomerList();
  }

  private editCustomer(id: string): void {
    this.router.navigate([id, 'Edit', 'Details'], { relativeTo: this.route });
  }

  private reloadCustomerList(): void {
    this.customerService.getCustomers(
      this.showInactive,
      this.showGlobal,
      this.searchTerm,
      this.sortField,
      this.sortDirection,
      this.pageNumber
    ).then((customers) => {
      for (let i = 0; i < customers.length; i++) {
        this.customerSet.push(customers[i]);
      }
      this.isLoading = false;
    })
    .catch(handleError);
  }

  private resetList(): void {
    this.scrollPosition = 0;
    this.pageNumber = 0;
    this.customerSet = [];
  }

  private onScroll(e): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadCustomerList();
      }
    }
  }
}
