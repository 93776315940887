import { Injectable, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IAuthUser } from '@/models/auth.models';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptor,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventType,
  RedirectRequest,
  EventMessage,
  SilentRequest,
} from '@azure/msal-browser';
import { UserService } from './user.service';
import { LocalStorageService } from './localStorage.service';
@Injectable()
export class AuthService implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  public currentUser = new BehaviorSubject<IAuthUser>(null);
  public accessToken = new BehaviorSubject<string>(null);
  public isAuthenticating = new BehaviorSubject<boolean>(true);
  public isAllowed$ = new BehaviorSubject<boolean>(true);
  public userPollingInterval: NodeJS.Timeout;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const msalInstance = this.msalAuthService.instance;
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts.length > 0
    ) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.enableAccountStorageEvents();
    this.isAuthenticating.next(false);
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if(user){
      this.currentUser.next(user);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  public initialize() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((event) => {
        this.accessToken.next(
          (event.payload as AuthenticationResult).accessToken
        );
        if (!this.currentUser.value) {
          this.setUser(event);
        }
      });

    this.currentUser.subscribe((user) => {
      if (user)
        this.localStorageService.setItem('currentUser', JSON.stringify(user));
    });
  }

  public pollForCurrentUser() {
    if (this.userPollingInterval) 
      return;
    
    this.userPollingInterval = setInterval(() => this.userService.getCurrentUser(), 5 * 60 * 1000);
  }

  public setUser(event: EventMessage) {
    const account = (event.payload as AuthenticationResult).account;
    const msalInstance = this.msalAuthService.instance;

    msalInstance.setActiveAccount(account);

    this.userService
      .getCurrentUser()
      .then((user) => {
        this.currentUser.next(user)
        this.pollForCurrentUser();
      })
      .catch((err) => this.isAllowed$.next(false));
  }
  
  public async switchLocation(locationId: string): Promise<void> {
    const updatedUser = await this.userService.switchLocation(locationId);
    this.currentUser.next(updatedUser);
  }

  get isLoggedIn(): boolean {
    return this.msalAuthService.instance.getActiveAccount() !== null;
  }

  get isAllowed(): boolean {
    return this.isAllowed$.getValue();
  }

  public login(): void {
    this.msalAuthService.loginRedirect({
      ...this.msalGuardConfig.authRequest,
    } as RedirectRequest);
  }

  public logout(): void {
    clearInterval(this.userPollingInterval);
    this.userPollingInterval = null;
    this.msalAuthService.logout({ postLogoutRedirectUri: '/login' });
    this.currentUser.next(null);
    this.localStorageService.removeItem('currentUser');
  }

  public userIsLocation(location: string): boolean {
    let user = this.currentUser.getValue();
    return user && user.locationName.toLowerCase() === location.toLowerCase();
  }
}
