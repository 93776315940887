<div class="container fixed">
	<div class="row">
		<div class="col-md-10 col-md-offset-1 searchbox" [ngClass]="{'disabled': isLoading}">
			<search [(ngModel)]="searchTerm" large="true" #customerSearch (onAction)="search()" placeholder="Search Customers" [isLoading]="isLoading"></search>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12">
			<div class="list-actions">
				<div class="btn-group list-filters">
					<button type="button" class="btn btn-default" (click)="filterToggle('showGlobal')" [ngClass]="{'active btn-primary':showGlobal}"><span *ngIf="showGlobal"> Hide other</span><span *ngIf="!showGlobal"> Show all</span> locations</button>
					<button type="button" class="btn btn-default" (click)="filterToggle('showInactive')" [ngClass]="{'active btn-primary':showInactive}"><i class="glyphicon glyphicon-lock"></i><span *ngIf="showInactive"> Hide</span><span *ngIf="!showInactive"> Show</span> inactive</button>
				</div>
				<button type="button" class="btn btn-cta ml-1" (click)="addCustomer()">Add customer</button>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12 mt-1" id="infinite-scroll-list">
			<table id="customer-list" class="table table-select-list mb-0">
				<caption class="sr-only">Sort list links</caption>
				<thead>
					<tr>
						<th scope="col">
							<infinite-scroll-header class="sortable" label="Name" field="name" [currentSort]="currentSort" [sortDirection]="sortDirection" (onSort)="sortList($event)"></infinite-scroll-header>
						</th>
						<th style="width:180px;" scope="col">Phone</th>
						<th class="list-actions" style="width:140px;" scope="col"></th>
					</tr>
				</thead>
			</table>
			<div id="top-of-list-marker"></div>
		</div>
	</div>
</div>
<div class="container">
	<div class="row customer-list-container">
		<div class="col-md-12 customer-list-column">
			<div class="attached infinite-scroll-list"
			infiniteScroll
			[infiniteScrollDistance]="1"
			[infiniteScrollThrottle]="50"
			[scrollWindow]="true"
			(scrolled)="onScroll($event)"
			*ngIf="!isLoading" [@fadeInOut]>
				<div *ngIf="!hasResults" class="no-results">
					No customers found for given search
				</div>

				<table class="table table-select-list">
					<caption class="sr-only">List of customers</caption>
					<thead class="spacer">
						<tr>
							<th scope="col"></th>
							<th style="width:180px;" scope="col"></th>
							<th style="width:140px;" scope="col"></th>
						</tr>
					</thead>
					<tbody>
						<tr class="customer-list-item" *ngFor="let customer of customerSet" (click)="editCustomer(customer._id)">
							<td class="name" scope="row">
								<span [innerHtml]="!customer.isActive | booleanIcon:'lock'"></span> {{ customer.name }}
							</td>
							<td class="phone" scope="row">
								{{ customer.main | phoneFormat }}
							</td>
							<td class="list-actions" scope="row">
								<button class="btn btn-default" type="button" (click)="editCustomer(customer._id)">Edit</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="isLoading" id="loading" [@fadeInOut]>Loading<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
		</div>
	</div>
</div>
