<div *ngIf="!model">
	<a id="login-btn" [routerLink]="['login']">Login</a>
</div>
<div *ngIf="model && (isAdmin || isPresident || isVicePresident)" dropdown class="location-select" >
	<div dropdownToggle>
		<a id="location-select" href (click)="false">{{ jobSource }}</a>
	</div>
	<ul *dropdownMenu class="dropdown-menu" role="menu">
		<li>
			<a (click)="changeJobSource('Madison')">Madison</a>
		</li>
		<li>
			<a (click)="changeJobSource('Pewaukee')">Pewaukee</a>
		</li>
	</ul>
</div>
<div *ngIf="model" dropdown class="account-dropdown">
	<div dropdownToggle>
		<a id="account-actions" href (click)="false">{{ model.username }}</a>
	</div>
	<ul *dropdownMenu class="dropdown-menu" role="menu">
		<li>
			<a [routerLink]="['Dashboard']">My Dashboard</a>
		</li>
		<li>
			<a [routerLink]="['Profile']">Profile</a>
		</li>
		<li>
			<a id="logout" (click)="logout()">Logout</a>
		</li>
		<li class="app-version-menu-item">    
			<app-version></app-version>
		</li>
	</ul>
</div>
