import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import * as routerUtils from '@/utils/routerUtils';
import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { IStaffModel, StaffModel } from '@/models/staff.models';
import { TimeEntryRateTypes } from '@/models/timeEntry.models';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'staff-add',
  templateUrl: './staffAdd.template.html',
  host: {'class': 'staff-add-component'}
})
export class StaffAddComponent extends BaseSmartComponent implements OnInit {
  public model: Observable<IStaffModel>;

  private _locationId: string;
  private _parentRoute: ActivatedRoute;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private state: StateManager,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.model = this.state.getActiveStaff();
    this._locationId = routerUtils.getRouteParameter(this.route, 'locationId');
    this._parentRoute = routerUtils.getParentRoute(this.route, 1);

    let newStaff = new StaffModel();
    newStaff.rates.push({ name: TimeEntryRateTypes['Regular'], rate: 0 });
    newStaff.rates.push({ name: TimeEntryRateTypes['Overtime'], rate: 0 });
    newStaff.rates.push({ name: TimeEntryRateTypes['RideTime'], rate: 0 });
    newStaff.rates.push({ name: TimeEntryRateTypes['DoubleTime'], rate: 0 });
    this.state.setActiveStaff(newStaff);
  }

  public saveStaff(newModel: IStaffModel): void {
    this.locationService.updateStaff(this._locationId, newModel)
        .then(() => this.router.navigate(['.'], { relativeTo: this._parentRoute }))
        .catch(handleError);
  }

  public cancel(): void {
    this.router.navigate(['.'], { relativeTo: this._parentRoute });
  }
}
