import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IWorkOrderModel } from '@/models/workOrder.models';

@Component({
    selector: 'work-order-list',
    templateUrl: './workOrderList.template.html',
    host: {'class': 'work-order-list-component', id: 'sidebar-content'}
})

export class WorkOrderListComponent {
    @Input()
    model = new Array<IWorkOrderModel>();

    @Input()
    selectedWorkOrderId : string = '';

    @Input()
	showIndicator : boolean = false;

    @Output()
    onWorkOrderSelect = new EventEmitter<IWorkOrderModel>();


    selectWorkOrder(workOrder: IWorkOrderModel) {
        this.onWorkOrderSelect.emit(workOrder);
    }
}
