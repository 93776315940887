import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';
import { BaseComponent } from '@/components/base.component';
import { IWorkOrderModel } from '@/models/workOrder.models';
import { IUserModel } from '@/models/user.models';

@Component({
	selector: 'work-order-edit-form',
	templateUrl: './workOrderEditForm.template.html',
  	host: {'class': 'work-order-edit-form-component'},
	animations: [
		trigger('fadeInOut', [
			state('void', style({
				opacity: 0,
				height: 0,
				overflow: 'hidden'
			})),
			transition('void <=> *', animate(300)),
		]),
	]
})
export class WorkOrderEditFormComponent extends BaseComponent {
	@Input()
	model: IWorkOrderModel;

	@Input()
	users: Array<IUserModel> = new Array<IUserModel>();

	@Input()
	isNew: boolean;

	@Output()
	onSave: EventEmitter<IWorkOrderModel> = new EventEmitter<IWorkOrderModel>();

	@Output()
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	public duplicateRecord: boolean = false;
	public updatePriority: boolean = false;
	public priorityChanged: boolean = false;
	public initialPriority: string = '';

	private saved: Subject<any> = new Subject();
	private filesQueued: boolean = false;

	ngOnInit(): void {
		this.saved.next('false');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['model']) {
			if (this.model) {
				this.initialPriority = this.model.priority;
			}
			this.saved.next('true');
		}
	}
	startSave() {
		if(this.isNew){
			// status will be set for new work order when it is saved
			this.saveWorkOrder();
		} else {
			// update status log if priority is changed to 1 or 5 but don't add to the status log if priority was changed back and forth before saving
			if (this.priorityChanged && (this.model.priority !== this.initialPriority)) {
				this.updatePriority = true;
			} else {
				// priority was changed to 'None' so don't update the status log
				this.saveWorkOrder();
			}
		}
	}

	saveWorkOrder(): void {
    // don't add to the status log if priority was changed back and forth before saving
	  if (this.priorityChanged && (this.model.priority !== this.initialPriority)) {
			this.updatePriority = true;
		}
	  this.onSave.emit(this.model);
	  this.saved.next('true');
	  this.priorityChanged = false;
	  this.initialPriority = this.model.priority;
	  this.updatePriority = false;
	}

	cancel(): void {
		this.onCancel.emit(null);
		this.saved.next('true');
	}

	setPriority(priority: string): void {
		if (this.model) {
			this.model.priority = priority;
			if (priority !== 'None') {
				this.priorityChanged = true;
				this.updatePriority = false;
			}
		}
	}

	public setMessage(e): void {
		this.filesQueued = e;
	}

	public duplicate(): void {
		this.duplicateRecord = true;
	}
}
