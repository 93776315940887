<div class="row">
    <div class="col-xs-12 col-sm-6">
        <div class="connection-section" *ngIf="purchaseOrderId">
            <div *ngIf="displayedPayables && displayedPayables.length > 0" class="screen-subtitle">Attached to payable<span *ngIf="displayedPayables && displayedPayables.length != 1">s</span></div>
            <div *ngIf="!displayedPayables || displayedPayables.length == 0" class="screen-subtitle">No payables attached</div>
        
            <table *ngIf="displayedPayables && displayedPayables.length > 0" class="table table-select-list mb-0">
                <caption>
                    <div class="searchbox searchbox-fixed">
                        <search placeholder="Filter payables" [(ngModel)]="filterTerm" filterData="text" [filterManager]="dataManipulationService"></search>
                    </div>
                </caption>
                <thead>
                    <tr>
                        <th scope="col" class="number">
                            <sortable-header field="invoiceNumber" label="Number" [sortManager]="dataManipulationService"></sortable-header>
                        </th>
                        <th scope="col" class="date">
                            <sortable-header field="invoiceDate" label="Date" [sortManager]="dataManipulationService"></sortable-header>
                        </th>
                        <th scope="col" class="amount">
                            <sortable-header field="amount" label="Amount" [sortManager]="dataManipulationService"></sortable-header>
                        </th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        
            <div *ngIf="displayedPayables && displayedPayables.length > 0" class="attached">
                <table class="table table-select-list">
                    <caption class="sr-only">List of payables</caption>
                    <thead class="column-spacer">
                        <tr>
                            <th scope="col" class="number"></th>
                            <th scope="col" class="date"></th>
                            <th scope="col" class="amount"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payable of displayedPayables" (click)="goToPayable(payable)">
                            <td scope="row" class="number">
                                {{ payable.invoiceNumber }}
                            </td>
                            <td scope="row" class="date">
                                {{ payable.invoiceDate | dateFormat:'MMM DD, YYYY' }}
                            </td>
                            <td scope="row" class="amount">
                                {{ payable.amount | currencyFormat }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th scope="col" class="number"></th>
                            <th scope="col" class="date"></th>
                            <th scope="col" class="amount">Total: {{ payablesTotal | currencyFormat }}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
