<div style="margin-left:8px;">
    <h3>Users</h3>
    <div *ngIf="!permissionLock" (click)="addUser()" class="add-on mt-1"><div class="add-icon">+</div><span class="ng-tns-c4-3">Add user</span></div>
</div>
<table class="table table-select-list" id="staff-list">
    <caption class="sr-only">List of Users</caption>
    <thead>
        <tr>
            <th scope="col">
                <sortable-header field="firstName" label="First Name" [sortManager]="userManager"></sortable-header>
            </th>
            <th scope="col">
                <sortable-header field="lastName" label="Last Name" [sortManager]="userManager"></sortable-header>
            </th>
            <th scope="col">
                <sortable-header field="roles" label="Role" [sortManager]="userManager"></sortable-header>
            </th>
            <th scope="col">
                <sortable-header field="isActive" label="Active" [sortManager]="userManager"></sortable-header>
            </th>
            <th scope="col" class="text-right">
                <button type="button" class="btn btn-default" (click)="filterToggle()" [ngClass]="{'active btn-primary':showInactive}">
                    <span *ngIf="showInactive"> Hide</span><span *ngIf="!showInactive"> Show</span> Inactive
                </button>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr [ngClass]="{'disabled':permissionLock}" class="staff-list-item" *ngFor="let user of model | async | inactiveUser : showInactive" (click)="editUser(user._id)">
            <td scope="row" [ngClass]="{'muted': !user.isActive}">
                {{ user.firstName }}
            </td>
            <td scope="row" [ngClass]="{'muted': !user.isActive}">
                {{ user.lastName }}
            </td>
            <td scope="row" [ngClass]="{'muted': !user.isActive}">
                {{ roleDisplayName(user.role) }}
            </td>
            <td scope="row" [ngClass]="{'muted': !user.isActive}">
                <span class="custom-checkbox disabled">
                    <input type="checkbox" disabled="true" [checked]="user.isActive"/>
                    <span class="checkmark"></span>
                </span>
            </td>
            <td scope="row" class="currency-display">
                <div class="action-link delete" (click)="editUser(user._id)">Edit</div>
            </td>
        </tr>
    </tbody>
</table>

