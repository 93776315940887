import { BaseModel } from './utility.models';
import {AddressModel, IAddress, ISupplierAddress, SupplierAddressModel} from './address.models';
import * as Utility from '@/models/utility.models';

export type SupplierStatus = 'neutral'|'onwatch'|'preferred';

export interface ISupplierModel extends BaseModel {
    name: string;
    address: ISupplierAddress;
    main: string;
    fax: string;
    website: string;
    notes: string;
    isActive: boolean;
    status: SupplierStatus;
    type: string;
    category: string;
    location: string;
}

export class SupplierModel extends BaseModel implements ISupplierModel {
    name: string = null;
    address: ISupplierAddress = new SupplierAddressModel();
    main: string = null;
    fax: string = null;
    website: string = null;
    notes: string = null;
    isActive: boolean = true;
    status: SupplierStatus = 'neutral';
    type: string = null;
    category: string = null;
    location: string = null;
}

export class SupplierSummaryModel extends Utility.BaseModel {
  name: string = null;
  main: string = null;
  address: IAddress = new AddressModel();
}

export const supplierLabel = (value: SupplierStatus): string => {
  if (!value) {
    return '';
  }

  let label: string;
  switch (value) {
    case 'preferred':
      label = 'Preferred'; break;
    case 'neutral':
      label = 'Neutral'; break;
    case 'onwatch':
      label = 'On Watch'; break;
    default: /* do nothing */ break;
  }
  return label;
};
