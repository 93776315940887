
<payable-edit [model]="model | async" 
    (onSave)="savePayable($event)" 
    [showAlert]="showAlert"
    (onGoToPayable)="goToPayable()"
    (formStatus)="setFormStatus($event)" 
    [isNew]="false" 
    [suppliers]="suppliers | async" 
    [suppliersCopy]="suppliersCopy" 
    [uuid]="_payableId"
></payable-edit>
