import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import {
  IReportJobListResult,
  IReportJournalTimeEntryResult,
  IReportJournalCostResult,
  IReportMonthlyCostsResult,
  IReportJobDetailResult,
  IReportInvoiceSummaryResult, JobOutcomeReport
} from '@/models/report.models';

@Injectable()
export class ReportService {
	protected baseUrl: string;

	constructor(private _http: HttpClient) {
		this.baseUrl = 'reports';
	}

	getJobListReport(
		startDate: Date,
		endDate: Date,
		includeClosed: boolean,
		projectManagerId: string,
		location: string,
	): Observable<Array<IReportJobListResult>> {
		let queryString = `?startDate=${startDate}&endDate=${endDate}&includeClosed=${includeClosed}&projectManager=${projectManagerId}&location=${location}`;
		return this._http.get<Array<IReportJobListResult>>(this.baseUrl + '/jobList' + queryString).pipe(share());
	}

	getTimeEntryReport(date: Date, includeClosed: boolean, location: string): Observable<Array<IReportJournalTimeEntryResult>> {
		const payload = { date: date, includeClosed: includeClosed, location: location };
		return this._http.post<Array<IReportJournalTimeEntryResult>>(this.baseUrl + '/journal/timeEntry', payload).pipe(share());
	}

	getJournalCostReport(type: string, date: Date, includeClosed: boolean, location: string): Observable<Array<IReportJournalCostResult>> {
		const payload = { date: date, includeClosed: includeClosed, location: location };
		return this._http.post<Array<IReportJournalCostResult>>(this.baseUrl + '/journal/' + type, payload).pipe(share());
	}

	getJobOutcomeReport(year: number, month: number, pm: string, location: string): Observable<JobOutcomeReport> {
		const payload = { year: year, month: month, projectManager: pm, location: location };
		return this._http.post<JobOutcomeReport>(this.baseUrl + '/jobOutcome', payload).pipe(share());
	}

	getMonthlyCostsReport(year: number, month: number, location: string): Observable<Array<IReportMonthlyCostsResult>> {
		const payload = { year: year, month: month, location: location };
		return this._http.post<Array<IReportMonthlyCostsResult>>(this.baseUrl + '/monthlyCosts', payload).pipe(share());
	}

	getInvoiceSummaryReport(startDate: Date, endDate: Date, location: string): Observable<Array<IReportInvoiceSummaryResult>> {
		const payload = { startDate: startDate, endDate: endDate, location: location };
		return this._http.post<Array<IReportInvoiceSummaryResult>>(this.baseUrl + '/invoiceSummary', payload).pipe(share());
	}

	getJobDetailReport(jobId: string, startDate: Date, endDate: Date): Observable<IReportJobDetailResult> {
		const payload = { jobId: jobId, startDate: startDate, endDate: endDate };
		return this._http.post<IReportJobDetailResult>(this.baseUrl + '/jobDetail', payload).pipe(share());
	}
}
