import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IChangeOrderModel } from '@/models/changeOrder.models';

export interface IChangeOrderState {
    changeOrders: Array<IChangeOrderModel>,
    activeChangeOrder: IChangeOrderModel
}

const defaultState = {
    changeOrders: new Array<IChangeOrderModel>(),
    activeChangeOrder: null
};

export const CHANGEORDER_ACTIONS = {
    SET_CHANGEORDERS_LIST: 'SET_CHANGEORDERS_LIST',
    SET_ACTIVE_CHANGEORDER: 'SET_ACTIVE_CHANGEORDER'
};

const changeOrderReducer = function(state: IChangeOrderState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case CHANGEORDER_ACTIONS.SET_CHANGEORDERS_LIST:
			newState.changeOrders = action.payload;
			break;
		case CHANGEORDER_ACTIONS.SET_ACTIVE_CHANGEORDER:
			newState.activeChangeOrder = action.payload;
			break;
	}

	return newState;
};

export function getChangeOrdersList() {
	return (state : Store<IChangeOrderState>) => {
		return state.select(s => {
			return s.changeOrders;
		});
	};
}

export function getActiveChangeOrder() {
	return (state : Store<IChangeOrderState>) => {
		return state.select(s => {
			return s.activeChangeOrder;
		});
	};
}

export function reducer(state: IChangeOrderState | undefined, action: Action) {
	return changeOrderReducer(state, action);
}
