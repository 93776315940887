import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import { LocationService } from '@/services/location.service';
import { AuthService } from '@/services/auth.service';
import { UserRole } from '@/models/auth.models';
import { IStaffModel, StaffModel } from '@/models/staff.models';
import { DataManipulationService, DataManipulationServiceFactory } from '@/services/dataManipulation.service';
import { StaffSortFunctions } from '@/utils/dataManipulationUtils';
import { handleError } from '@/utils/errors';

@Component({
  selector: 'staff-list',
  templateUrl: './staffList.template.html',
  host: {'class': 'staff-list-component'}
})
export class StaffListComponent extends BaseSmartComponent implements OnInit {
  public model: Subject<Array<IStaffModel>> = new Subject<Array<IStaffModel>>();
  public staffManager: DataManipulationService<IStaffModel>;
	public showInactive: boolean = false;
	public user: Object;
	public userSub: Subscription;
	public isAdmin: boolean = false;
	public role: number;
	public permissionLock: boolean = false;

  private _locationId: string;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private locationService: LocationService,
    dataManipulationServiceFactory: DataManipulationServiceFactory,
		private authService: AuthService,
    messageService: MessageService
  ) {
    super(messageService);
    this.staffManager = dataManipulationServiceFactory.create<IStaffModel>();
  }

  ngOnInit(): void {
    this.staffManager.initialize({}, StaffSortFunctions);
    this.staffManager.setSort('lastName', false);
    this.watchSubscription(this.staffManager.connectDataSource(this.state.getStaffList(), this.model));
    this._locationId = routerUtils.getRouteParameter(this.route, 'locationId');
    this.locationService.getAllStaff(this._locationId);
    // get user's role
    this.userSub = this.authService.currentUser.subscribe(s => {
      if (s) {
        this.user = s;
        this.role = s.role;
          if (this.role === UserRole.Admin) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
            this.permissionLock = true;
          }
      }
    });
  }

  public addStaff(): void {
    this.state.setActiveStaff(new StaffModel());
    this.router.navigate(['Staff', 'Add'], { relativeTo: this.route });
  }

  private editStaff(id: string): void {
    this.router.navigate(['Staff', id], { relativeTo: this.route });
  }

  private deleteStaff(staff: IStaffModel, event: Event): void {
    if (this.warnOnDelete('staff member')) {
      this.locationService.deleteStaff(this._locationId, staff._id)
        .then(() => this.locationService.getAllStaff(this._locationId))
        .catch(handleError);
    }

    event.stopPropagation();
  }

  private deactivateStaff(staff: IStaffModel, event: Event): void {
    this.locationService.deactivateStaff(this._locationId, staff._id)
      .then(() => this.locationService.getAllStaff(this._locationId))
      .catch(handleError);
    event.stopPropagation();
  }

  private activateStaff(staff: IStaffModel, event: Event): void {
    this.locationService.activateStaff(this._locationId, staff._id)
      .then(() => this.locationService.getAllStaff(this._locationId))
      .catch(handleError);
    event.stopPropagation();
  }

  public filterToggle(){
    this.showInactive = !this.showInactive;
  }
}
