import { Component } from '@angular/core';


@Component({
    selector: 'report',
    templateUrl: '../Layout/layout-sidebar-narrow.template.html',
    host: {'class': 'report-component'}
})
export class ReportComponent {
    public hideSidebar: boolean = false;

    toggleSidebar(): void {
        this.hideSidebar = !this.hideSidebar;
    }
};
