import { Store } from '@ngrx/store';
import { PayloadAction as Action } from '@/utils/storeUtils';

import { IPayableModel, IPayableSummary } from '@/models/payable.models';

export interface IPayableState {
	payables: Array<IPayableModel>,
	activePayable: IPayableModel,
	payableSummaries: Array<IPayableSummary>
}

const defaultState : IPayableState = {
	payables: new Array<IPayableModel>(),
	activePayable: null,
	payableSummaries: new Array<IPayableSummary>()
};

export const PAYABLE_ACTIONS = {
	SET_PAYABLES_LIST: 'SET_PAYABLES_LIST',
	SET_ACTIVE_PAYABLE: 'SET_ACTIVE_PAYABLE',
	SET_PAYABLE_SUMMARY_LIST: 'SET_PAYABLE_SUMMARY_LIST'
};

const payableReducer = function(state: IPayableState = defaultState, action: Action) {
	let newState = Object.assign({}, state);

	switch(action.type) {
		case PAYABLE_ACTIONS.SET_PAYABLES_LIST:
				newState.payables = action.payload;
				break;
		case PAYABLE_ACTIONS.SET_ACTIVE_PAYABLE:
				newState.activePayable = action.payload;
				break;
		case PAYABLE_ACTIONS.SET_PAYABLE_SUMMARY_LIST:
			newState.payableSummaries = action.payload;
			break;
	}

	return newState;
};

export function getPayablesList() {
	return (state : Store<IPayableState>) => {
		return state.select(s => {
			return s.payables;
		});
	};
}

export function getActivePayable() {
	return (state : Store<IPayableState>) => {
		return state.select(s => {
			return s.activePayable;
		});
	};
}

export function getPayableSummaryList() {
	return (state : Store<IPayableState>) => {
		return state.select(s => {
			return s.payableSummaries;
		})
	}
}

export function reducer(state: IPayableState | undefined, action: Action) {
	return payableReducer(state, action);
}
