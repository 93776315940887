import { Injectable } from '@angular/core';
import { uuid } from 'uuidv4';

@Injectable()
export class UUIDService {
    constructor(){}

    generateUUID() {
        return uuid()
    }

}
