import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { StateManager } from '@/state/stateManager';
import { ApiBaseService } from './apiBase.service';
import { MessageService } from './message.service';
import { IWorkOrderModel, WorkOrderModel, IWorkOrderSearchRequest } from '@/models/workOrder.models';
import { IPMNoteModel } from '@/models/pmNote.models';
import { IWorkOrderPartModel } from '@/models/part.models';
import { IJobBackref } from '@/models/purchaseOrder.models';

@Injectable()
export class WorkOrderService extends ApiBaseService {
	constructor(private _http: HttpClient, private state: StateManager, messageService: MessageService) {
		super(messageService);
		this.baseUrl = 'customers';
	}

	search(
		limit: number = null,
		searchTerm: string = null,
		sortField: string = null,
		sortDirection: number = null,
		pageNumber: number = null,
		filters: any[] = null
	): Promise<Array<IWorkOrderModel>> {
		let queryUrl = 'workOrders';
		let request = {
			limit: limit || 0,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			pageNumber: pageNumber,
			locationId: '',
			filters: filters
		} as IWorkOrderSearchRequest;

		// todo remove share and convert transform into map
		let result = this._http.post<Array<IWorkOrderModel>>(queryUrl, request).pipe(share());
		return this.extract(result, (s) => {
			// we need to lift the customer out of the job
			for (let i = 0, len = s.length; i < len; i++) {
				let p = s[i];
				let job = p.job as IJobBackref;
				p.customer = job.customer;
			}
			this.state.setWorkOrdersList(s);
		});
	}

	getWorkOrders(customerId: string, jobId: string): Promise<Array<IWorkOrderModel>> {
		let result = this._http.get<Array<WorkOrderModel>>(this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders').pipe(share());
		return this.extract(result, (s) => {
			this.state.setWorkOrdersList(s);
		});
	}

	getWorkOrder(customerId: string, jobId: string, workOrderId: string, loadOnly: boolean = false): Promise<IWorkOrderModel> {
		const path = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId;
		let result = this._http.get<WorkOrderModel>(path).pipe(share());
		return this.extract(result, (s) => {
			if (!loadOnly) {
				this.state.setActiveWorkOrder(s);
			}
		});
	}

	createWorkOrder(customerId: string, jobId: string, workOrder: IWorkOrderModel): Promise<IWorkOrderModel> {
		const workOrderBaseUrl = this.getWorkOrderUrl(customerId, jobId, workOrder);
		const req = this._http.post<IWorkOrderModel>(workOrderBaseUrl, workOrder).pipe(share());
		return this.saveWorkOrder(req);
	}

	updateWorkOrder(customerId: string, jobId: string, workOrder: IWorkOrderModel): Promise<IWorkOrderModel> {
		const workOrderBaseUrl = this.getWorkOrderUrl(customerId, jobId, workOrder);
		const req = this._http.put<IWorkOrderModel>(workOrderBaseUrl, workOrder).pipe(share());
		return this.saveWorkOrder(req);
	}

	// <editor-fold> PM Notes

	getPMNotes(customerId: string, jobId: string, workOrderId: string): Promise<Array<IPMNoteModel>> {
		let url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/pmNotes';
		let result = this._http.get<Array<IPMNoteModel>>(url).pipe(share());
		return this.extract(result, (s) => {
				this.state.setPMNotesList(s);
		});
	}

	updatePMNote(customerId: string, jobId: string, workOrderId: string, pmNote: IPMNoteModel): Promise<IPMNoteModel> {
		let result: Observable<IPMNoteModel>;
		let pmNoteBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/pmNotes';

		if (pmNote._id) {
			result = this._http.put<IPMNoteModel>(pmNoteBaseUrl + '/' + pmNote._id, pmNote).pipe(share());
		} else {
			result = this._http.post<IPMNoteModel>(pmNoteBaseUrl, pmNote).pipe(share());
		}

		let promise = this.extract(result, (s) => {
				// self.state.setActivePMNote(s);
		}, (error) => {
			this.notify('An error occurred when updating the PM note', { type: 'error' });
		});

		promise.then(() => {
			this.notify('PM note updated');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	deletePMNote(customerId: string, jobId: string, workOrderId: string, pmNoteId: string): Promise<any> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/pmNotes/' + pmNoteId;
		let result = this._http.delete(url).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('PM note deleted');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	// </editor-fold> PM Notes

	// <editor-fold> Parts

	getParts(customerId: string, jobId: string, workOrderId: string): Promise<Array<IWorkOrderPartModel>> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/parts';
		let result = this._http.get<Array<IWorkOrderPartModel>>(url).pipe(share());
		return this.extract(result, (s) => {
			this.state.setWorkOrderPartsList(s);
		});
	}

	updatePart(customerId: string, jobId: string, workOrderId: string, part: IWorkOrderPartModel): Promise<IWorkOrderPartModel> {
		let result: Observable<IWorkOrderPartModel>;
		let partBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/parts';

		if (part._id) {
			result = this._http.put<IWorkOrderPartModel>(partBaseUrl + '/' + part._id, part).pipe(share());
		} else {
			result = this._http.post<IWorkOrderPartModel>(partBaseUrl, part).pipe(share());
		}

		let promise = this.extract(result, (s) => {
				// self.state.setActiveWorkOrderPart(s);
		}, (error) => {
				this.notify('An error occurred when updating the part', { type: 'error' });
		});

		promise.then(() => {
			this.notify('Part updated');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	deletePart(customerId: string, jobId: string, workOrderId: string, partId: string): Promise<any> {
		const url = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders/' + workOrderId + '/parts/' + partId;
		let result = this._http.delete(url).pipe(share());
		let promise = this.extract(result);

		promise.then(() => {
			this.notify('Part deleted');
		}).catch(() => { /* do nothing */ });

		return promise;
	}

	// </editor-fold> Parts

	private getWorkOrderUrl(customerId: string, jobId: string, workOrder: IWorkOrderModel): string {
		let workOrderBaseUrl = this.baseUrl + '/' + customerId + '/jobs/' + jobId + '/workOrders';
		if (workOrder._id) {
			workOrderBaseUrl += '/' + workOrder._id;
		}
		return workOrderBaseUrl;
	}

	private saveWorkOrder(req: Observable<IWorkOrderModel>): Promise<IWorkOrderModel> {
		const self = this;
		let promise = self.extract(req, (s) => {
			self.state.setActiveWorkOrder(s);
		}, (error) => {
			self.notify('An error occurred when updating the work order', { type: 'error' });
		});

		promise.then(() => {
			self.notify('Work order updated');
		}).catch(() => { /* do nothing */ });

		return promise;
	}
}
